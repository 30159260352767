export const RouterNames = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  MAIN: '/',
  CHANGE_PASSWORD: '/change-password/:token',
  UNAUTHORIZED: '/unauthorized',
  PRIVACY: '/privacy',
  FORBIDDEN: '/forbidden',
  PDV_USUARIO: '/pdv',
  ADMIN_USUARIOS_CADASTRO: '/usuarios/novo',
  ADMIN_USUARIOS_EDITAR: '/usuarios/edit',
  ADMIN_USUARIOS: '/usuarios',
  ADMIN_NOTASFISCAIS: '/notas-fiscais',
  ADMIN_NOTASFISCAIS_ENTRADA: '/notas-fiscais/entrada',
  ADMIN_NOTASFISCAIS_SAIDA: '/notas-fiscais/saida',
  ADMIN_NOTASFISCAIS_DETALHES_ENTRADA: '/notas-fiscais/entrada/detalhes',
  ADMIN_NOTASFISCAIS_DETALHES_SAIDA: '/notas-fiscais/saida/detalhes',
  ADMIN_LOJAS: '/lojas',
  ADMIN_LOJAS_CADASTRO: '/lojas/novo',
  ADMIN_LOJAS_EDITAR: '/lojas/edit',
  ADMIN_LOJAS_BANNER: '/lojas/banners',
  ADMIN_MINILOJAS: '/mini-lojas',
  ADMIN_RELATORIOS: '/relatorios',
  ADMIN_ATUALIZACOES: '/atualizacoes',
  ADMIN_ATUALIZACOES_MENSAGEM: '/atualizacoes/mensagem',
  ADMIN_ATUALIZACOES_SHOPKEEPER: '/atualizacoes/mensagem/visualizar',
  ADMIN_PRODUTOS: '/produtos',
  ADMIN_PRODUTOS_NOVO: '/produtos/novo',
  ADMIN_PRODUTOS_PROMOCOES: '/produtos/promocoes',
  ADMIN_PRODUTOS_PROMOCOES_EDITAR: '/produtos/promocoes/:promotionId',
  ADMIN_PRODUTOS_PROMOCOES_NOVO: '/produtos/promocoes/novo',
  ADMIN_PRODUTOS_EDITAR: '/produtos/edit',
  PROVIDERS: '/produtos/fornecedores',
  PROVIDERS_NOVO: '/produtos/fornecedores/novo',
  PROVIDERS_EDIT: '/produtos/fornecedores/edit',
  PDV_ORDERS: '/pedidos',
  PDV_ORDER_DETAILS: '/pedidos/detalhes',
  EMPRESA: '/empresa',
  STORE_INVENTORY: '/lojas/estoque',
  CANCELLATIONS: '/reports/cancellations',
  CANCELLATIONS_DETALHES: '/reports/cancellations/detalhes',
  REPORTS_ORDERS: '/reports/pedidos',
  REPORTS_BILLING: '/reports/faturamento',
  REPORTS_BILLING_SHOPKEEPER: '/reports/faturamento/lojista',
  REPORTS_TOP_SELLERS: '/reports/produtos/mais-vendidos',
  REPORTS_ORDERS_DETALHES: '/reports/pedidos/:orderId',
  REPORTS_FINANCIAL: '/reports/financeiro',
  REPORTS_BEST_CLIENTS: '/reports/clientes',
  REPORTS_BEST_CLIENTS_ORDERS_DETALHES: '/reports/clientes/:clientId',
  REPORTS_MOVES_STOCK: '/reports/movimentacao',
};
