export enum STOCK_ACTIONS {
  Down = 'DOWN',
  Up = 'UP',
}

export enum STOCK_REASONS {
  SupplyStore = 'SUPPLY_STORE',
  StoreReturn = 'STORE_RETURN',
  InputNote = 'INPUT_NOTE',
  OutputNote = 'OUTPUT_NOTE',
  CancellationNote = 'CANCELLATION_NOTE',
  Supply = 'SUPPLY',
  ReturnMainStock = 'RETURN_TO_MAIN_STOCK',
  Sale = 'SALE',
  DevolutionSale = 'DEVOLUTION_OF_SALE',
}

export enum STOCK_REASONS_DESCRIPTION {
  SUPPLY_STORE = 'Produto movido de estoque principal para estoque da loja',
  STORE_RETURN = 'Produto movido de estoque da loja para estoque principal',
  INPUT_NOTE = 'Entrada de Nota',
  OUTPUT_NOTE = 'Saída de Nota',
  CANCELLATION_NOTE = 'Cancelamento de Nota',
  SUPPLY = 'Produto movido de estoque principal para estoque da loja',
  RETURN_TO_MAIN_STOCK = 'Produto movido de estoque da loja para estoque principal',
  SALE = 'Venda',
  DEVOLUTION_OF_SALE = 'Devolução de venda',
}
