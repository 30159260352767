import { Grid, makeStyles } from '@material-ui/core';
import {
  Close,
  PersonAdd,
  Search,
  LocalMall,
  ViewCarousel,
  ArrowForward,
} from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import Selecionar from 'components/select';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import RouterNames from 'constants/router-names';
import { Texts } from 'constants/strings';
import { LojasActions } from 'store/ducks/lojas';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from 'components/pagination-table';
import InputCpfCnpj from 'components/input-cpf-cnpj';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

function Lojas() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {
    nomeFantasia,
    razaoSocial,
    cnpj,
    status,
    filterError,
    filterLoading,
    lojas,
  } = useSelector((state) => state.lojas);

  const createStore = () => {
    history.push(RouterNames.ADMIN_LOJAS_CADASTRO);
  };

  const callInventory = () => {
    history.push(RouterNames.STORE_INVENTORY);
  };

  const callBanners = () => {
    history.push(RouterNames.ADMIN_LOJAS_BANNER);
  };

  const editStore = (selectedStore) => {
    history.push(RouterNames.ADMIN_LOJAS_EDITAR, { store: selectedStore });
  };

  const changeText = (event) => {
    dispatch(LojasActions.updateFields(event.target.id, event.target.value));
  };

  const selected = ({ id, value }) => {
    dispatch(LojasActions.updateFields(id, value));
  };

  const clear = () => {
    dispatch(LojasActions.clearFields());
  };

  const filter = () => {
    dispatch(LojasActions.filter());
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={PersonAdd}
            onClick={createStore}
            title={Texts.store.buttons.novo}
            colored
          />
        </Grid>
        <Grid item>
          <Button
            icon={LocalMall}
            onClick={callInventory}
            title={Texts.store.buttons.gerenciarEstoque}
            colored
          />
        </Grid>
        <Grid item>
          <Button
            icon={ViewCarousel}
            onClick={callBanners}
            title="Banners"
            colored
          />
        </Grid>
        <Grid item xs={12}>
          <Title>{Texts.store.sessions.buscarLoja}</Title>
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextInput
            label={Texts.store.fields.nomeFantasia}
            id="nomeFantasia"
            onChange={changeText}
            value={nomeFantasia}
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextInput
            label={Texts.store.fields.razaoSocial}
            id="razaoSocial"
            onChange={changeText}
            value={razaoSocial}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <InputCpfCnpj id="cnpj" onChange={changeText} value={cnpj} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Selecionar
            label={Texts.store.fields.status}
            id="status"
            onChange={selected}
            options={[
              { id: 1, name: 'Ativo' },
              { id: 0, name: 'Inativo' },
            ]}
            value={status}
          />
        </Grid>
        <Grid item>
          <div className={classes.buttonContainer}>
            <Button
              icon={Close}
              onClick={clear}
              style={classes.button}
              title={Texts.store.buttons.clear}
            />
            <Button
              icon={Search}
              onClick={filter}
              style={classes.button}
              title={Texts.store.buttons.buscar}
              colored
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={['Loja', 'Código', 'Comissão', 'Status']}
            values={lojas}
            error={filterError}
            loading={filterLoading}
            fields={(store) => [
              store.fantasy_name,
              store.code_identification,
              `${store.currentCommission.commission}%`,
              store.active ? 'Ativa' : 'Inativa',
            ]}
            hasAction
            action={editStore}
            Icon={ArrowForward}
          />
        </Grid>
      </Grid>
    </Main>
  );
}

export { Lojas };
