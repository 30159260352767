import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Expandable from 'components/expandable';
import TextInfo from 'components/text-info';
import { AddMasks } from 'utils/Masks';

function DadosGerais() {
  const nfe = useSelector((state) => state.fiscal.nfe.new.data);
  return (
    <Expandable title="Informações Gerais">
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Chave de Acesso" value={nfe?.chNFe} noWrap />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Série" value={nfe?.serie} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Natureza da Operação" value={nfe?.natOp} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Número do Documento" value={nfe?.nNF} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Tipo da Operação" value={nfe?.tpNF?.description} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo
            label="Finalizade da Emissão"
            value={nfe?.finNFe?.description}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo
            label="Data da Emissão"
            value={AddMasks.dateHour(nfe?.dhEmi)}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo
            label="Modalidade de Frete"
            value={nfe?.modFrete?.description}
          />
        </Grid>
      </Grid>
    </Expandable>
  );
}

export default DadosGerais;
