import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
    minHeight: '80%',
  },
}));

function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <p>Página não encontrada</p>
    </div>
  );
}

export { NotFound };
