import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  heading: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 'bold',
  },
  header: {
    borderRadius: 15,
    boxShadow: theme.shadows[1],
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: 30,
  },
}));

function Expandable({ title, children }) {
  const classes = useStyles();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        className={classes.header}
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

Expandable.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export { Expandable };
