import React from 'react';
import { DatePicker, TimePicker, DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import { DateSelectorChange } from '.';

interface Props {
  id: string;
  value?: Moment;
  onChange: (value: DateSelectorChange) => void;
  type: 'date' | 'datetime' | 'time';
  className?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  label: string;
  disabled?: boolean;
}

const pickers = {
  date: DatePicker,
  time: TimePicker,
  datetime: DateTimePicker,
};

const formats = {
  date: 'dd/MM/yyyy',
  time: 'HH:mm',
  datetime: 'dd/MM/yyyy HH:mm',
};

const DateSelector = ({
  value,
  onChange,
  type = 'date',
  className,
  disableFuture = false,
  disablePast = false,
  label,
  id,
  disabled,
}: Props) => {
  const SelectedPicker = pickers[type];

  const handleDate = (date: MaterialUiPickersDate) => {
    if (date) {
      const selectedDate = moment(date.getTime()).clone();
      onChange({
        id,
        value: selectedDate,
        formatted: selectedDate.format(formats[type]),
      });
    }
  };

  return (
    <SelectedPicker
      value={value}
      label={label}
      fullWidth
      onChange={handleDate}
      className={className}
      inputVariant="outlined"
      autoOk
      format={formats[type]}
      disablePast={disablePast}
      disableFuture={disableFuture}
      cancelLabel="Cancelar"
      invalidDateMessage=""
      disabled={disabled}
    />
  );
};

export { DateSelector };
