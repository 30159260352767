import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ContainerRoutes } from 'routes';
import MainDrawer from 'components/drawer/Drawer';
import AppToolbar from 'components/toolbar';
import ApiInterceptors from 'components/api-interceptor';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RoutesConfig from 'routes/RoutesConfig';
import { LayoutActions } from 'store/ducks/layout';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    minHeight: '100vh',
    background: `linear-gradient(180deg, ${theme.palette.primary.main} 40%, ${theme.palette.secondary.main} 100%)`,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowX: 'scroll',
    backgroundColor: theme.palette.common.white,
    margin: '0px 10px 10px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 10px 10px 10px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0px 10px 10px 0px',
    },
    borderRadius: theme.shape.borderRadius,
  },
}));

function Main() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const route = RoutesConfig.find((routerConfig) => {
      const { parentPath, path } = routerConfig;
      return (
        (parentPath && parentPath === location.pathname) ||
        path === location.pathname
      );
    });
    if (route) {
      dispatch(
        LayoutActions.selected(
          route.toolbarName,
          route.parentPath || route.path
        )
      );
    }
  }, [location.pathname, dispatch]);

  return (
    <div className={classes.root}>
      <AppToolbar />
      <MainDrawer />
      <div className={classes.content}>
        <div className={classes.toolbar} />
        <ContainerRoutes />
      </div>
      <ApiInterceptors />
    </div>
  );
}

export { Main };
