import { ChevronLeft } from '@material-ui/icons';
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Button } from 'components/button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: theme.spacing(2),
    },
  })
);

const ButtonBack = () => {
  const classes = useStyles();
  const history = useHistory();
  const back = () => history.goBack();

  return (
    <Button
      icon={ChevronLeft}
      onClick={back}
      title="Voltar"
      style={classes.button}
    />
  );
};

export default ButtonBack;
