import { Grid } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import BillInfo from 'components/bill-info';
import { Button } from 'components/button';
import DateSelector, { DateSelectorChange } from 'components/date-selector';
import DatesInterval from 'components/dates-interval';
import Expandable from 'components/expandable';
import If from 'components/if/If';
import {
  Intervals,
  IntervalSelector,
  IntervalSelectorEvent,
} from 'components/interval-selector/IntervalSelector';
import Main from 'components/main-container';
import TextInfo from 'components/text-info';
import { useReports } from 'context/reports';
import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationActions } from 'store/ducks/notification';
import { RootState } from 'store/ducks/reducers';
import queryCreator from 'utils/queryCreator';
import { SalesTotal } from 'utils/types/reports';
import { Store } from 'utils/types/store';

interface Data extends SalesTotal {}

interface Fields {
  store?: Store;
  start?: Moment;
  end?: Moment;
}

const initialFields = {
  store: undefined,
  start: moment(),
  end: moment(),
};

const BillingShopkeeper = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<Data | undefined>(undefined);
  const { getTotalSales } = useReports();
  const [typeInterval, setTypeInterval] = useState(Intervals.Today);
  const [filter, setFilter] = useState<Fields>(initialFields);
  const { actualStore } = useSelector((state: RootState) => state.userStores);

  const handleDate = ({ id, value }: DateSelectorChange) => {
    setFilter((state) => ({ ...state, [id]: moment(value) }));
  };

  const clear = () => {
    setTypeInterval(Intervals.Today);
    setFilter(initialFields);
  };

  const getSales = useCallback(async (query: string) => {
    const total = await getTotalSales(query, actualStore.store_id);
    if (total) {
      setData(total);
    }
  }, [actualStore, getTotalSales]);

  const onSelectPeriod = (period: IntervalSelectorEvent) => {
    setTypeInterval(period.interval);
    setFilter((state) => ({ ...state, start: period.start, end: period.end }));
  };

  useEffect(() => {
    if (actualStore) {
      getSales('');
    }
  }, [actualStore, getSales]);

  const doFilter = useCallback(async () => {
    try {
      let start = null;
      let end = null;
      if (!filter.start || !filter.end) {
        dispatch(NotificationActions.warning('Selecione o intervalo de datas'));
        return;
      }
      start = filter.start;
      end = filter.end;
      dispatch(NotificationActions.info('Buscando...aguarde'));
      const queries = [];
      const date1 = start.format('YYYY-MM-DD');
      const date2 = end.format('YYYY-MM-DD');
      queries.push(queryCreator.interval('created_at', date1, date2));
      if (filter.store) {
        queries.push(queryCreator.equal('store_id', filter.store.store_id));
      }
      const query = queryCreator.formatter({
        queries,
      });
      getSales(query);
    } finally {
      setTimeout(() => dispatch(NotificationActions.dismiss()), 2000);
    }
  }, [filter, dispatch, getSales]);

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <IntervalSelector
            onChange={onSelectPeriod}
            id="interval"
            label="Período"
            value={typeInterval}
          />
        </Grid>
        <If condition={typeInterval === Intervals.Custom}>
          <Grid item xs={12} sm={4}>
            <DateSelector
              id="start"
              type="date"
              label="De"
              value={filter.start}
              onChange={handleDate}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DateSelector
              id="end"
              type="date"
              label="Até"
              value={filter.end}
              onChange={handleDate}
              disableFuture
            />
          </Grid>
        </If>
        <Grid item>
          <Button icon={Close} onClick={clear} title="Limpar" />
        </Grid>
        <Grid item>
          <Button icon={Search} onClick={doFilter} title="Filtrar" colored />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DatesInterval
            start={filter.start || moment()}
            end={filter.end || moment()}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <Expandable title="Faturamento">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <BillInfo
                  sm={6}
                  data={[
                    {
                      label: 'Vendas Realizadas',
                      value: data?.quantitySales.toString() || '-',
                    },
                    {
                      label: 'Total em Vendas',
                      value: data?.totalCurrency || '-',
                    },
                    {
                      label: 'Ticket Médio',
                      value: data?.ticketCurrency || '-',
                    },
                    {
                      label: 'Comissão',
                      value: data?.commission || '-',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Expandable>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Expandable title="Vendas por Tipo de pagamento">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <BillInfo
                  sm={6}
                  data={[
                    {
                      label: 'Cartão de Crédito',
                      value: data?.totalCreditCard || '-',
                    },
                    {
                      label: 'Pagamento em Loja',
                      value: data?.totalInStore || '-',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Expandable>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Expandable title="Forma de Pagamento">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextInfo
                  label="Cartão de Crédito"
                  value={data?.qtdCreditCard}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInfo label="Pagamento em Loja" value={data?.qtdInStore} />
              </Grid>
            </Grid>
          </Expandable>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Expandable title="Status dos Pedidos">
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextInfo
                  label="Entregues"
                  value={data?.qtdStatus.ORDER_DELIVERED}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInfo
                  label="Aguardando Pagamento"
                  value={data?.qtdStatus.AWAITING_PAYMENT_CONFIRMATION}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInfo
                  label="Cancelados"
                  value={data?.qtdStatus.PAYMENT_CANCELED}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInfo
                  label="Aguardando Retirada"
                  value={data?.qtdStatus.PAYMENT_CONFIRMED}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInfo
                  label="Recusados"
                  value={data?.qtdStatus.PAYMENT_REFUSED}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInfo
                  label="Solicitado Cancelamento"
                  value={data?.qtdStatus.REQUESTED_CANCELLATION}
                />
              </Grid>
            </Grid>
          </Expandable>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Expandable title="Vendas por Bandeira">
            <Grid container spacing={2}>
              {data?.typesPayment.map((brand) => (
                <Grid item sm={6} xs={12}>
                  <TextInfo label={brand.name} value={brand.qtd} />
                </Grid>
              ))}
            </Grid>
          </Expandable>
        </Grid>
      </Grid>
    </Main>
  );
};

export default BillingShopkeeper;
