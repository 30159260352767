import { Img } from 'components/logo/components/Styled';
import React from 'react';
import logoWhite from 'assets/img/logo/logo_white.png';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';

function LogoDrawer() {
  const history = useHistory();

  const goToMain = () => {
    history.push(RouterNames.MAIN);
  };
  return (
    <Img
      src={logoWhite}
      width="55%"
      onClick={goToMain}
      style={{
        alignSelf: 'center',
        marginBottom: 20,
        marginTop: 20,
        cursor: 'pointer',
        objectFit: 'contain',
      }}
    />
  );
}

export default LogoDrawer;
