import { Texts } from 'constants/strings';
import { Creators as ResetPasswordActions } from 'store/ducks/reset-password';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Fade, withStyles } from '@material-ui/core';
import Input from './Input';
import { Content } from './Styled';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.dark,
    maxWidth: 300,
    fontSize: 12,
    boxShadow: theme.shadows[2],
    fontWeight: 600,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

function Fields() {
  const dispatch = useDispatch();

  const {
    password,
    repeatPassword,
    passwordRepeatMessage,
    checkedSuccess,
    errorMessage,
  } = useSelector((state) => state.resetPassword);

  const changePassword = (text) => {
    dispatch(ResetPasswordActions.updatePassword(text, repeatPassword));
  };

  const changeRepeat = (text) => {
    dispatch(ResetPasswordActions.updatePassword(password, text));
  };

  return (
    <Content>
      <HtmlTooltip
        disableHoverListener={checkedSuccess}
        disableFocusListener={checkedSuccess}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        title={Texts.recuperarSenha.tooltipScreenPassword}
        placement="top-start"
        open={!checkedSuccess && errorMessage}
        arrow
      >
        <div>
          <Input
            isPass
            error={errorMessage}
            value={password}
            onChange={changePassword}
            label={Texts.recuperarSenha.fieldPassword}
          />
        </div>
      </HtmlTooltip>
      <Input
        isPass
        error={passwordRepeatMessage}
        onChange={changeRepeat}
        label={Texts.recuperarSenha.fieldRepeatPassword}
        value={repeatPassword}
      />
    </Content>
  );
}

export default Fields;
