import { call, put, select } from 'redux-saga/effects';
import { ProdutoApi } from 'services/api/ProdutoApi';
import queryCreator from 'utils/queryCreator';
import { ProdutoActions } from 'store/ducks/produto';
import { PlugNotasApi } from 'services/api/PlugNotasApi';
import { NotificationActions } from 'store/ducks/notification';
import { Errors } from 'constants/strings';
import { RemoveMasks } from 'utils/Masks';
import { CODE_CITY } from 'constants/config/Config';
import citycodes from 'constants/address/citycodes';

export function* getProdutos() {
  try {
    const { description, brand, gtin_ean_code, status } = yield select(
      (state) => state.produto
    );
    const query = [];
    if (description.trim()) {
      query.push(queryCreator.like('description', description));
    }
    if (brand.trim()) {
      query.push(queryCreator.like('brand', brand));
    }
    if (gtin_ean_code.trim()) {
      query.push(queryCreator.like('gtin_ean_code', gtin_ean_code));
    }
    if (status !== '') {
      query.push(queryCreator.bool('active', status));
    }

    const { data } = yield call(
      ProdutoApi.getProdutos,
      queryCreator.queryFormatter(query)
    );
    const list = data.data.map((item) => ({
      ...item,
      shopkeeperName: item.shopkeeper ? 'LOJISTA' : 'ADMIN', // corrigindo campo true ou false pra tabela
      activeName: item.active ? 'ATIVO' : 'INATIVO', // corrigindo campo true ou false pra tabela
    }));
    yield put(ProdutoActions.successFilter(list));
  } catch (error) {
    yield put(ProdutoActions.errorFilter());
  }
}

export function* getProviders({ payload }) {
  try {
    const { cpfCnpj, fantasyName } = payload;
    const query = [];
    if (cpfCnpj.trim()) {
      query.push(queryCreator.like('cpf_cnpj', cpfCnpj));
    }
    if (fantasyName.trim()) {
      query.push(queryCreator.like('fantasy_name', fantasyName));
    }
    const { data } = yield call(
      ProdutoApi.getProvidersList,
      queryCreator.queryFormatter(query)
    );
    yield put(ProdutoActions.requestProvidersSuccess(data.data));
  } catch (error) {
    yield put(ProdutoActions.requestProvidersFailure());
  }
}

export function* consultaCepProvider() {
  try {
    const { postcode } = yield select((state) => state.produto.updatedProvider);
    const { data } = yield call(PlugNotasApi.consultarCep, postcode);
    if (data.erro) {
      throw data;
    }
    const addrs = {
      city: data.municipio,
      uf: data.uf,
      neighborhood: data.bairro,
      street: data.logradouro,
      codeCity: data?.ibge || CODE_CITY,
    };
    yield put(ProdutoActions.getCepResult(addrs));
  } catch (error) {
    yield put(NotificationActions.error(Errors.findCep));
    yield put(ProdutoActions.getCepResult());
  }
}

export function* createUpdateProvider() {
  try {
    const {
      update,
      cpf_cnpj,
      rg,
      ie,
      social_reason,
      fantasy_name,
      email,
      postcode,
      street,
      neighborhood,
      city,
      uf,
      number,
      phone,
      providerId,
      codeCity,
    } = yield select((state) => state.produto.updatedProvider);

    const code = citycodes.find(citycode => citycode.name === city).code || "2304400";

    const data = {
      cpf_cnpj: RemoveMasks.onlyNumbers(cpf_cnpj),
      rg: RemoveMasks.onlyNumbers(rg),
      ie: RemoveMasks.onlyNumbers(ie),
      social_reason,
      fantasy_name,
      email,
      postcode: RemoveMasks.onlyNumbers(postcode),
      street,
      neighborhood,
      city,
      uf,
      number,
      phone: RemoveMasks.onlyNumbers(phone),
      code_city: code,
    };

    if (update && providerId) {
      yield call(ProdutoApi.updateProvider, data, providerId);
    } else {
      yield call(ProdutoApi.createProvider, data);
    }
    yield put(ProdutoActions.createUpdateProviderResult());
    yield put(NotificationActions.success('Sucesso'));
    yield put(ProdutoActions.resetProvider());
  } catch (error) {
    yield put(
      NotificationActions.error('Ocorreu um erro ao cadastrar o fornecedor')
    );
    if (error?.response?.status === 422 && Array.isArray(error.response.data)) {
      const errors = {};
      error.response.data.forEach((err) => {
        errors[`${err.field}Err`] = err.message;
      });
      yield put(ProdutoActions.createUpdateProviderResult(errors));
    } else {
      yield put(ProdutoActions.createUpdateProviderResult());
    }
  }
}
