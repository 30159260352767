import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Expandable from 'components/expandable';
import TextInfo from 'components/text-info';

function Fornecedor() {
  const nfe = useSelector((state) => state.fiscal.nfe.new.data);
  return (
    <Expandable title="Informações do Fornecedor">
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Razão Social" value={nfe?.provider?.social_reason} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Nome Fantasia" value={nfe?.provider?.fantasy_name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Inscrição Estadual" value={nfe?.provider?.ie} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="CPF/CNPJ" value={nfe?.provider?.cpf_cnpj} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="RG" value={nfe?.provider?.rg} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo
            label="Endereço"
            value={`${nfe?.provider?.street}, ${nfe?.provider?.number}, ${nfe?.provider?.neighborhood}, ${nfe?.provider?.city}-${nfe?.provider?.uf}, ${nfe?.provider?.postcode}`}
          />
        </Grid>
      </Grid>
    </Expandable>
  );
}

export default Fornecedor;
