import produce from 'immer';

export const Types = {
  GET_ORDER: 'orderDetails@GET_ORDER',
  GET_ORDER_ERROR: 'orderDetails@GET_ORDER_ERROR',
  GET_ORDER_SUCCESS: 'orderDetails@GET_ORDER_SUCCESS',
  GET_ITEMS: 'orderDetails@GET_ITEMS',
  GET_ITEMS_ERROR: 'orderDetails@GET_ITEMS_ERROR',
  GET_ITEMS_SUCCESS: 'orderDetails@GET_ITEMS_SUCCESS',
};

const initialState = {
  order: null,
  loadingTransaction: false,
  orderError: false,
  items: [],
  itemsLoading: false,
  itemError: false,
};

const orderDetails = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ORDER:
      state.loadingTransaction = true;
      state.orderError = false;
      state.order = null;
      return state;
    case Types.GET_ORDER_SUCCESS:
      state.loadingTransaction = false;
      state.orderError = false;
      state.order = payload;
      return state;
    case Types.GET_ORDER_ERROR:
      state.loadingTransaction = false;
      state.orderError = true;
      state.order = null;
      return state;
    case Types.GET_ITEMS:
      state.itemsLoading = true;
      state.itemsError = false;
      state.items = [];
      return state;
    case Types.GET_ITEMS_SUCCESS:
      state.itemsLoading = false;
      state.itemsError = false;
      state.items = payload;
      return state;
    case Types.GET_ITEMS_ERROR:
      state.itemsLoading = false;
      state.itemsError = true;
      state.items = [];
      return state;
    default:
      return state;
  }
});

export default orderDetails;

export const OrderDetailsActions = {
  getOrder: (idOrder) => ({
    type: Types.GET_ORDER,
    payload: idOrder,
  }),
  getOrderErr: () => ({
    type: Types.GET_ORDER_ERROR,
  }),
  getOrderSuccess: (order) => ({
    type: Types.GET_ORDER_SUCCESS,
    payload: order,
  }),
  getItems: (idOrder) => ({
    type: Types.GET_ITEMS,
    payload: idOrder,
  }),
  getItemsErr: () => ({
    type: Types.GET_ITEMS_ERROR,
  }),
  getItemsSuccess: (items) => ({
    type: Types.GET_ITEMS_SUCCESS,
    payload: items,
  }),
};

// order example
/**
 * {
    "order_id": 17,
    "client_id": 1,
    "type_payment_id": 2,
    "id_gateway_order": null,
    "created_at": "2020-12-13 20:39:06",
    "updated_at": "2020-12-13 20:39:06",
    "deleted_at": null,
    "totals": {
      "total_sale_value": 314.6999931335449,
      "total_discount": 0,
      "total": 314.6999931335449,
      "order_id": 17
    },
    "client": {
      "client_id": 1,
      "person_id": 9,
      "email": "client@wessex.com",
      "avatar": null,
      "active": true,
      "created_at": "2020-12-13 19:49:45",
      "updated_at": "2020-12-13 19:49:45",
      "deleted_at": null,
      "person": {
        "person_id": 9,
        "address_id": 2,
        "number": null,
        "name": "Virginia Moss",
        "cpf": "54768772404",
        "rg": "50038514800",
        "birthdate": "1996-08-05",
        "phone": "90180245993",
        "address": {
          "address_id": 2,
          "postcode": "98535045",
          "street": "Jovo Way",
          "neighborhood": "Caccen Plaza",
          "city": "Feprecup",
          "uf": "CE",
          "zoning_code": null
        }
      }
    },
    "currentStatus": {
      "order_history_id": 21,
      "order_id": 17,
      "order_status_id": 5,
      "note": null,
      "created_at": "2020-12-13 20:39:06",
      "updated_at": "2020-12-13 20:39:06",
      "status": {
        "order_status_id": 5,
        "description": "Aguardando pagamento",
        "constant": "AWAITING_PAYMENT_CONFIRMATION"
      }
    }
  }
 */
