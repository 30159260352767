import { Moment } from 'moment';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PromotionsApi } from 'services/api/Promotions';
import { NotificationActions } from 'store/ducks/notification';
import { RemoveMasks } from 'utils/Masks';
import { Product } from 'utils/types/product';
import {
  Promotion,
  PromotionDetailed,
  PromotionParams,
} from 'utils/types/promotion';
import { ResponsePagination } from 'utils/types/response';

interface PromotionsProps {
  children: React.ReactNode;
}

interface AllPromotionsResponse extends ResponsePagination<Promotion> {}

interface CreatePromotionParams {
  description: string;
  discount: string;
  start?: Moment;
  end?: Moment;
  active: boolean;
  products: Array<Product>;
}
interface Promotions {
  promotion: {
    promotions: AllPromotionsResponse | null;
    setPromotions: (promotion: AllPromotionsResponse | null) => void;
    getPromotions: (query: string) => void;
    createPromotion: (
      params: CreatePromotionParams,
      promotionId?: string
    ) => Promise<boolean>;
    getPromotionDetails: (
      promotionId: string
    ) => Promise<PromotionDetailed | undefined>;
  };
}

const PromotionContext = createContext<Promotions | null>(null);

const PromotionProvider = ({ children }: PromotionsProps) => {
  const dispatch = useDispatch();
  const [promotions, setPromotions] = useState<AllPromotionsResponse | null>(
    null
  );
  const getPromotions = (query: string = '') => {
    PromotionsApi.getAllPromotions(query)
      .then(({ data }) => {
        setPromotions(data);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(JSON.stringify(error)));
      })
      .finally(() => dispatch(NotificationActions.dismiss()));
  };

  const getPromotionDetails = async (
    promotionId: string
  ): Promise<PromotionDetailed | undefined> => {
    dispatch(NotificationActions.info('Buscando os dados, aguarde'));
    try {
      const { data } = await PromotionsApi.getPromotion(promotionId);
      dispatch(NotificationActions.dismiss());
      return data.data;
    } catch (error) {
      dispatch(NotificationActions.error(error));
      return undefined;
    }
  };

  const createPromotion = useCallback(
    async (
      params: CreatePromotionParams,
      promotionId?: string
    ): Promise<boolean> => {
      dispatch(NotificationActions.info('Enviando as informações'));
      try {
        if (!params.description.trim()) {
          dispatch(NotificationActions.error('Descrição não preenchida'));
          return false;
        }
        if (!params.discount.trim()) {
          dispatch(NotificationActions.error('Desconto não informado'));
          return false;
        }
        if (!params.start || !params.end) {
          dispatch(
            NotificationActions.error(
              'Selecione o intervalo de duração da promoção'
            )
          );
          return false;
        }
        if (params.end.isBefore(params.start)) {
          dispatch(
            NotificationActions.error(
              'Data final da promoção não pode ser antes da data inicial'
            )
          );
          return false;
        }
        if (params.products.length === 0) {
          dispatch(NotificationActions.error('Selecione ao menos um produto'));
          return false;
        }

        const discount = RemoveMasks.currency(params.discount) || 0;
        let productInvalid = false;
        params.products.forEach((prod) => {
          const priceWithDiscount = parseFloat(prod.sale_value) - discount;
          if (priceWithDiscount <= 0) {
            productInvalid = true;
          }
        });
        if (productInvalid) {
          dispatch(
            NotificationActions.error(
              'Um ou mais produtos ficam com valores negativos ao aplicar a promoção'
            )
          );
          return false;
        }
        const data: PromotionParams = {
          description: params.description,
          discount,
          starts_in: params.start.format('YYYY-MM-DD HH:mm:ss'),
          ends_in: params.end.format('YYYY-MM-DD HH:mm:ss'),
          products: params.products.map((prod) => prod.product_id),
          active: params.active.toString(),
        };
        if (!promotionId) {
          await PromotionsApi.createPromotion(data);
        } else {
          await PromotionsApi.updatePromotion(data, promotionId);
        }
        return true;
      } catch (error) {
        dispatch(NotificationActions.error(error));
        return false;
      }
    },
    [dispatch]
  );

  return (
    <PromotionContext.Provider
      value={{
        promotion: {
          promotions,
          setPromotions,
          getPromotions,
          createPromotion,
          getPromotionDetails,
        },
      }}
    >
      {children}
    </PromotionContext.Provider>
  );
};

const usePromotions = () => {
  const context = useContext(PromotionContext);
  if (!context) {
    throw new Error('usePromotions must be user within an PromotionsProvider');
  }
  return context.promotion;
};

export { PromotionProvider, usePromotions };
