import React from 'react';
import { makeStyles } from '@material-ui/core';
import Loading from 'components/loading';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
  },
}));

function LoadingBody() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Loading size={40} />
    </div>
  );
}
export default LoadingBody;
