import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageApi } from 'services/api/MessageApi';
import { NotificationActions } from 'store/ducks/notification';
import { RootState } from 'store/ducks/reducers';
import { Message } from 'utils/types/message';

interface MessagesProps {
  children: React.ReactNode;
}

interface Messages {
  messages: Array<Message>;
  message: Message | null;
  getMessagesLojista(): void;
  getMessagesAdmin(): void;
  deleteMessage(action: () => void): void;
  updateMessage(title: string, message: string): void;
  createMessage(title: string, message: string, action: () => void): void;
  setMessage(message: Message | null): void;
}

const MessagesContext = createContext<Messages | null>(null);

const MessageProvider = ({ children }: MessagesProps) => {
  const dispatch = useDispatch();
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [message, setMessage] = useState<Message | null>(null);
  const { actualStore } = useSelector((state: RootState) => state.userStores);

  const getMessagesLojista = useCallback(async () => {
    try {
      const { data } = await MessageApi.getMessagesLojista(
        actualStore.store_id
      );
      setMessages(data.data);
      dispatch(NotificationActions.dismiss());
    } catch (error) {
      setMessages([]);
    }
  }, [actualStore, dispatch]);

  const getMessagesAdmin = useCallback(async () => {
    try {
      dispatch(NotificationActions.info('Buscando mensagens'));
      const { data } = await MessageApi.getMessagesAdmin();
      setMessages(data.data);
      dispatch(NotificationActions.dismiss());
    } catch (error) {
      dispatch(
        NotificationActions.error(`Ocorreu um erro: ${JSON.stringify(error)}`)
      );
      setMessages([]);
    }
  }, [dispatch]);
  const deleteMessage = async (action: () => void) => {
    try {
      dispatch(NotificationActions.info('Deletando ...'));
      await MessageApi.deleteMessage(message?.store_message_id || 0);
      action();
      dispatch(NotificationActions.success('Sucesso!!!'));
    } catch (error) {
      dispatch(
        NotificationActions.error(`Ocorreu um erro: ${JSON.stringify(error)}`)
      );
    }
  };
  const updateMessage = async (title: string, text: string) => {
    try {
      dispatch(NotificationActions.info('Atualizando mensagem'));
      await MessageApi.updateMessage(
        title,
        text,
        message?.store_message_id || 0
      );
      dispatch(NotificationActions.success('Sucesso!!!'));
    } catch (error) {
      dispatch(
        NotificationActions.error(`Ocorreu um erro: ${JSON.stringify(error)}`)
      );
    }
  };
  const createMessage = async (
    title: string,
    text: string,
    action: () => void
  ) => {
    try {
      dispatch(NotificationActions.info('Criando mensagem...'));
      await MessageApi.createMessage(title, text);
      dispatch(NotificationActions.success('Sucesso!!!'));
      getMessagesAdmin();
      action();
    } catch (error) {
      dispatch(
        NotificationActions.error(`Ocorreu um erro: ${JSON.stringify(error)}`)
      );
    }
  };

  return (
    <MessagesContext.Provider
      value={{
        messages,
        message,
        getMessagesLojista,
        getMessagesAdmin,
        deleteMessage,
        updateMessage,
        createMessage,
        setMessage,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

const useMessages = () => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error('useMessages must be user within an MessageProvider');
  }
  return context;
};

export { MessageProvider, useMessages };
