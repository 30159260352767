import Uris from 'constants/endpoints';
import api from 'services/config/api';

export const EmpresaApi = {
  saveCompany: (data) => {
    return api.put(Uris.putEmpresa, data);
  },
  getCompany: () => {
    return api.get(Uris.getEmpresa);
  },
};
