import { Grid } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { NovaLojaActions } from 'store/ducks/nova-loja';
import { Button } from 'components/button';
import { AddCircle } from '@material-ui/icons';
import ProprietarioItem from './ProprietarioItem';

function Proprietarios() {
  const dispatch = useDispatch();
  const { owners, disableFields } = useSelector((state) => state.novaLoja);

  const add = () => {
    dispatch(NovaLojaActions.addProp());
  };
  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.novaLoja.sessions.prop}</Title>
      </Grid>
      {owners.map((item, index) => (
        <ProprietarioItem key={`Prop${index}`} index={index} />
      ))}
      {!disableFields && (
        <Grid item>
          <Button
            icon={AddCircle}
            onClick={add}
            title={Texts.novaLoja.buttons.addProp}
          />
        </Grid>
      )}
    </>
  );
}

export default Proprietarios;
