import React from 'react';
import { Grid } from '@material-ui/core';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { useDispatch, useSelector } from 'react-redux';
import { Texts } from 'constants/strings';
import { Button } from 'components/button';
import { VpnKey } from '@material-ui/icons';
import { Creators } from 'store/ducks/reset-password';

function UpdatePassword() {
  const dispatch = useDispatch();
  const { password, resetPassword, passwordMessage, resetLoading } =
    useSelector((state) => state.resetPassword);

  const changeText = (e) => {
    const values = { password, resetPassword, [e.target.id]: e.target.value };
    dispatch(Creators.updatePassword(values.password, values.resetPassword));
  };

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.recuperarSenha.titleScreenUpdatePassword}</Title>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          error={passwordMessage}
          id="password"
          onChange={changeText}
          label={Texts.recuperarSenha.fieldPassword}
          value={password}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          id="resetPassword"
          onChange={changeText}
          label={Texts.recuperarSenha.fieldRepeatPassword}
          value={resetPassword}
        />
      </Grid>
      <Grid item>
        <Button
          icon={VpnKey}
          onClick={() => {}}
          colored
          loading={resetLoading}
          title={Texts.recuperarSenha.buttonPassword}
        />
      </Grid>
    </>
  );
}

export default UpdatePassword;
