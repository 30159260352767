import { DashboardActions } from 'store/ducks/dashboard';
import { call, put } from 'redux-saga/effects';
import { DashboardApi } from 'services/api/DashboardApi';

export function* getLowStock() {
  const wessex = { quantity: 0, products: [], show: false };
  const stores = { quantity: 0, products: [], show: false };
  try {
    const { data: wessexResult } = yield call(
      DashboardApi.getInfoLowStock,
      'wessex'
    );
    wessex.quantity = wessexResult.quantity;
    wessex.products = wessexResult.products;
    wessex.show = wessexResult.quantity > 0;
    const { data: storesResult } = yield call(
      DashboardApi.getInfoLowStock,
      'stores'
    );
    stores.quantity = storesResult.quantity;
    stores.products = storesResult.products;
    stores.show = storesResult.quantity > 0;
    yield put(DashboardActions.getLowStockResult(stores, wessex));
  } catch (error) {
    yield put(DashboardActions.getLowStockResult(stores, wessex));
  }
}
// status: PAYMENT_CANCELED, PAYMENT_REFUSED, AWAITING_PAYMENT_CONFIRMATION, REQUESTED_CANCELLATION, PAYMENT_CONFIRMED, ORDER_DELIVERED
export function* getOrdersByStatus({ payload: status }) {
  try {
    const { data: result } = yield call(DashboardApi.getOrdersByStatus, status);
    yield put(DashboardActions.getOrdersByStatusResult(result, status));
  } catch (error) {
    yield put(DashboardActions.getOrdersByStatusResult({}, status));
  }
}
