import React, { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReportsApi } from 'services/api/ReportsApi';
import { RootState } from 'store/ducks/reducers';
import { Store } from 'utils/types/store';

interface StoreProps {
  children: React.ReactNode;
}

interface ContextProps {
  search: {
    stores: Array<Store>;
    setSearchStores: (stores: Array<Store>) => void;
    searchStores: (query: string) => void;
    loading: boolean;
    error: boolean;
  };
  isShopkeeper: boolean;
}

const StoreContext = createContext<ContextProps>({
  search: {
    stores: [],
    setSearchStores: () => {},
    searchStores: () => {},
    loading: false,
    error: false,
  },
  isShopkeeper: false,
});

const StoreProvider = ({ children }: StoreProps) => {
  const [stores, setSearchStores] = useState<Array<Store>>([]);
  const shopkeeper = useSelector(
    (state: RootState) => state?.user?.user?.shopkeeper
  );

  const [searchStatus, setSearchStatus] = useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: false, error: false });

  const searchStores = (query: string = '') => {
    setSearchStatus({
      loading: true,
      error: false,
    });
    ReportsApi.getAllStores(query)
      .then(({ data }) => {
        setSearchStores(data.data);
        setSearchStatus({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setSearchStatus({
          loading: false,
          error: true,
        });
      });
  };

  return (
    <StoreContext.Provider
      value={{
        search: {
          stores,
          setSearchStores,
          searchStores,
          loading: searchStatus.loading,
          error: searchStatus.error,
        },
        isShopkeeper: !!shopkeeper,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

const useSearchStores = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useOrders must be user within an StoreProvider');
  }
  return context.search;
};

const useShopkeeper = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useOrders must be user within an StoreProvider');
  }
  return context.isShopkeeper;
};

export { StoreProvider, useSearchStores, useShopkeeper };
