import React, { FC } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { AddCircleOutline, Adjust, Delete } from '@material-ui/icons';
import Main from 'components/main-container';
import SearchSelector from 'components/search-selector';
import Tabela from 'components/table';
import { Title } from 'components/title/Title';
import { Quantities, Store, StoreSelectorResult } from 'utils/types/store';
import { Product, ProductDetails } from 'utils/types/product';
import If from 'components/if/If';
import { Increment } from 'components';
import { FieldChange } from 'utils/types/input';
import { Button } from 'components/button';

type Props = {
  getStoreLabel(option: Store): string;
  getProductLabel(option: Product): string;
  onChangeInputStore(e: React.ChangeEvent<HTMLInputElement>): void;
  onChangeStore(event: StoreSelectorResult<Store>): void;
  stores: Store[];
  isRemoving: boolean;
  onSelectedProductByTable(product: ProductDetails): void;
  onChangeInputProduct(e: React.ChangeEvent<HTMLInputElement>): void;
  onSelectedProduct(product: FieldChange<Product>): void;
  loadingSearch: boolean;
  loadingInventory: boolean;
  loadingProduct: boolean;
  selectedStore: Store | null;
  inventory: ProductDetails[];
  quantities: Quantities;
  onChangeQuantity(quantity: number): void;
  products: Product[];
  selectedProduct?: Product | ProductDetails | null;
  updating: boolean;
  onUpdate(): void;
  onClean(): void;
  refProduct: React.RefObject<HTMLDivElement>;
};

const StoreInventory: FC<Props> = ({
  getStoreLabel,
  getProductLabel,
  onChangeInputStore,
  onChangeStore,
  stores,
  isRemoving,
  onSelectedProductByTable,
  loadingSearch,
  loadingInventory,
  selectedStore,
  inventory,
  quantities,
  onChangeQuantity,
  onChangeInputProduct,
  onSelectedProduct,
  products,
  loadingProduct,
  selectedProduct,
  updating,
  onUpdate,
  onClean,
  refProduct,
}) => {
  const updateLabel = isRemoving
    ? `Alterar estoque para ${quantities.selected}`
    : `Adicionar ${quantities.selected} ite${
        quantities.selected > 1 ? 'ns' : 'm'
      }`;

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <SearchSelector
            id="store"
            label="Selecione a Loja"
            onChangeInput={onChangeInputStore}
            getLabel={getStoreLabel}
            onChange={onChangeStore}
            options={stores}
            loading={loadingSearch}
            value={selectedStore}
            optionCheckField="store_id"
            selectedCheckField="store_id"
          />
        </Grid>
        <If condition={!!selectedStore}>
          <>
            <Grid item sm={12} xs={12}>
              <Title>Estoque Disponível</Title>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Tabela
                loading={loadingInventory}
                titles={['Nome', 'Estoque do Stand ', 'Estoque Wessex']}
                fields={['description', 'quantity', 'stock']}
                values={inventory}
                hasAction
                action={onSelectedProductByTable}
                icon={Adjust}
                actionLabel="Atualizar Estoque"
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Title>
                {isRemoving ? 'Atualizar Estoque' : `Adicionar Produto`}
              </Title>
            </Grid>
            <Grid item sm={8} xs={8}>
              <SearchSelector
                id="selectedProduct"
                label="Selecione o Produto"
                onChangeInput={onChangeInputProduct}
                getLabel={getProductLabel}
                onChange={onSelectedProduct}
                options={products}
                loading={loadingProduct}
                value={selectedProduct}
                optionCheckField="product_id"
                selectedCheckField="product_id"
                disabled={isRemoving}
              />
            </Grid>
            <If condition={!!selectedProduct && quantities.selected >= 0}>
              <div ref={refProduct} />
              <Grid item sm={4} xs={4}>
                <IconButton onClick={onClean}>
                  <Delete />
                </IconButton>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Title variant="h6">Quantidade</Title>
              </Grid>
              <Grid item sm={12} xs={12}>
                <Increment
                  max={quantities.max}
                  min={quantities.min}
                  value={quantities.selected}
                  changeValue={onChangeQuantity}
                />
              </Grid>
              <If
                condition={
                  (!isRemoving && quantities.selected >= 1) ||
                  (isRemoving &&
                    quantities.selected >= 0 &&
                    quantities.selected < quantities.max)
                }
              >
                <Grid item>
                  <Button
                    loading={updating}
                    icon={isRemoving ? Delete : AddCircleOutline}
                    title={updateLabel}
                    colored
                    onClick={onUpdate}
                    disable={!isRemoving && quantities.selected < 1}
                  />
                </Grid>
              </If>
            </If>
          </>
        </If>
      </Grid>
    </Main>
  );
};

export { StoreInventory };
