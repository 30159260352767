import { Grid } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import ButtonBack from 'components/button-back';
import Main from 'components/main-container';
import Table from 'components/pagination-table';
import RouterNames from 'constants/router-names';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProductsApi } from 'services/api/ProductsApi';
import { NotificationActions } from 'store/ducks/notification';
import { AddMasks } from 'utils/Masks';
import { TopSellings } from 'utils/types/product';

const ListTopSellings = () => {
  const [topSellings, setTopSellings] = useState<Array<TopSellings>>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const getTopSellings = useCallback(async () => {
    dispatch(NotificationActions.info('Buscando os produtos, aguarde...'));
    try {
      const { data } = await ProductsApi.topSellings();
      dispatch(NotificationActions.dismiss());
      setTopSellings(data);
    } catch (error) {
      dispatch(NotificationActions.dismiss());
      setTopSellings([]);
    }
  }, [dispatch]);

  const callProducts = (product: TopSellings) => {
    history.push(RouterNames.ADMIN_PRODUTOS_EDITAR, { produto: product });
  };

  useEffect(() => {
    getTopSellings();
  }, [getTopSellings]);

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ButtonBack />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={['Produto', 'Quantidade de Vendas', 'Valor em Vendas']}
            values={topSellings}
            fields={(product: TopSellings) => [
              product.description,
              product.sold_amount,
              AddMasks.money(product.total_value_sold),
            ]}
            hasAction
            action={callProducts}
            actionLabel="Ver Produto"
            Icon={ArrowForward}
          />
        </Grid>
      </Grid>
    </Main>
  );
};

export { ListTopSellings };
