import api from 'services/config/api';
import { ProductDetails } from 'utils/types/product';
import { ResponsePagination } from 'utils/types/response';
import { Store } from 'utils/types/store';

export default class StoreApi {
  getStore = async (query = ''): Promise<ResponsePagination<Store>> => {
    const { data } = await api.get<ResponsePagination<Store>>(
      `/admin/stores${query}`
    );
    return data;
  };

  getStoreInventory = async (storeId: number): Promise<ProductDetails[]> => {
    const { data } = await api.get<ProductDetails[]>(
      `/admin/stores/${storeId}/products`
    );
    return data;
  };

  sendProductToStore = async (
    productId: number,
    storeId: number,
    quantity: number
  ): Promise<void> => {
    await api.post(`/admin/products/${productId}/send-to-store/${storeId}`, {
      quantity,
    });
  };

  removeProductFromStore = async (
    productId: number,
    storeId: number,
    quantity: number
  ): Promise<void> => {
    await api.post(`/admin/products/${productId}/bring-from-store/${storeId}`, {
      quantity,
    });
  };
}
