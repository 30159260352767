import { Grid } from '@material-ui/core';
import Main from 'components/main-container';
import React, { useEffect } from 'react';
import Table from 'components/pagination-table';
import { AddMasks } from 'utils/Masks';
import { AddAlert, ArrowForward } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';
import { useMessages } from 'context/messages';
import { Message } from 'utils/types/message';
import { useShopkeeper } from 'context/store';
import { Button } from 'components/button';
import If from 'components/if/If';

const Messages = () => {
  const history = useHistory();
  const {
    getMessagesLojista,
    setMessage,
    messages,
    getMessagesAdmin,
  } = useMessages();
  const isShopkeeper = useShopkeeper();

  const updateMessage = (message: Message) => {
    setMessage(message);
    if (isShopkeeper) {
      history.push(RouterNames.ADMIN_ATUALIZACOES_SHOPKEEPER);
    } else {
      history.push(RouterNames.ADMIN_ATUALIZACOES_MENSAGEM);
    }
  };

  const createMessage = () => {
    setMessage(null);
    history.push(RouterNames.ADMIN_ATUALIZACOES_MENSAGEM);
  };

  useEffect(() => {
    if (isShopkeeper) {
      getMessagesLojista();
    } else {
      getMessagesAdmin();
    }
  }, [getMessagesAdmin, getMessagesLojista, isShopkeeper]);

  return (
    <Main>
      <Grid container spacing={2}>
        <If condition={!isShopkeeper}>
          <Grid item>
            <Button
              icon={AddAlert}
              onClick={createMessage}
              title="Nova Mensagem"
              colored
            />
          </Grid>
        </If>
        <Grid item xs={12} sm={12}>
          <Table
            labels={['Título', 'Data']}
            values={messages}
            fields={(message) => [
              message.title,
              AddMasks.dateHour(message.created_at),
            ]}
            hasAction
            action={updateMessage}
            actionLabel="Visualizar"
            Icon={ArrowForward}
          />
        </Grid>
      </Grid>
    </Main>
  );
};

export { Messages };
