import { Modules } from 'constants/permissions';
import produce from 'immer';
import { isDev, userTest } from 'tests';

export const Types = {
  SESSION_REQUEST: 'user@SESSION_REQUEST',
  SESSION_SUCCESS: 'user@SESSION_SUCCESS',
  SESSION_ERROR: 'user@SESSION_ERROR',
  LOGOUT: 'user@LOGOUT',
};

const initialState = {
  user: isDev ? userTest : undefined,
  userLoading: false,
  userError: false,
  userSuccess: false,
  acl: {
    profile: 'PROFILE_ADMIN',
    permissions: [],
    modules: [],
  },
};

const user = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SESSION_REQUEST:
      state.userLoading = true;
      state.user = undefined;
      state.userError = false;
      state.userSuccess = false;
      return state;
    case Types.SESSION_ERROR:
      state.userLoading = false;
      state.userError = true;
      return state;
    case Types.SESSION_SUCCESS:
      state.userLoading = false;
      state.userError = false;
      state.userSuccess = true;
      state.user = payload;
      state.acl = {
        profile: payload.profile.constant,
        modules: payload.profilePermissions
          .map(({ permission }) => permission.module.constant)
          .concat([Modules.AUTHORIZED]),
        permissions: payload.profilePermissions.map(
          ({ permission }) => permission.constant
        ),
      };
      return state;
    default:
      return state;
  }
});

export default user;

export const Creators = {
  requestSession: (history) => ({
    type: Types.SESSION_REQUEST,
    payload: { history },
  }),
  userError: (payload) => ({
    type: Types.SESSION_ERROR,
    payload,
  }),
  userSuccess: (userData) => ({
    type: Types.SESSION_SUCCESS,
    payload: userData,
  }),
  logout: () => ({
    type: Types.LOGOUT,
  }),
};
