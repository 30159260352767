import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import {
  ChevronRightOutlined,
  ChevronLeftOutlined,
  ShoppingCartOutlined,
  PersonOutlineOutlined,
  ReceiptOutlined,
  StorefrontOutlined,
  SpaOutlined,
  Close,
} from '@material-ui/icons';
import React, { FC } from 'react';
import {
  STOCK_REASONS,
  STOCK_REASONS_DESCRIPTION,
} from 'utils/enum/stocks.enum';

type Props = {
  type: STOCK_REASONS;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 25,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  })
);

const MovimentationIcon: FC<Props> = ({ type }: Props) => {
  const classes = useStyles();

  const icons = {
    [STOCK_REASONS.CancellationNote]: () => (
      <>
        <SpaOutlined className={classes.icon} />
        <Close className={classes.icon} />
        <ReceiptOutlined className={classes.icon} />
      </>
    ),
    [STOCK_REASONS.DevolutionSale]: () => (
      <>
        <ShoppingCartOutlined className={classes.icon} />
        <ChevronLeftOutlined className={classes.icon} />
        <PersonOutlineOutlined className={classes.icon} />
      </>
    ),
    [STOCK_REASONS.InputNote]: () => (
      <>
        <ReceiptOutlined className={classes.icon} />
        <ChevronRightOutlined className={classes.icon} />
        <SpaOutlined className={classes.icon} />
      </>
    ),
    [STOCK_REASONS.OutputNote]: () => (
      <>
        <SpaOutlined className={classes.icon} />
        <ChevronRightOutlined className={classes.icon} />
        <ReceiptOutlined className={classes.icon} />
      </>
    ),
    [STOCK_REASONS.ReturnMainStock]: () => (
      <>
        <SpaOutlined className={classes.icon} />
        <ChevronLeftOutlined className={classes.icon} />
        <StorefrontOutlined className={classes.icon} />
      </>
    ),
    [STOCK_REASONS.Sale]: () => (
      <>
        <ShoppingCartOutlined className={classes.icon} />
        <ChevronRightOutlined className={classes.icon} />
        <PersonOutlineOutlined className={classes.icon} />
      </>
    ),
    [STOCK_REASONS.StoreReturn]: () => (
      <>
        <SpaOutlined className={classes.icon} />
        <ChevronLeftOutlined className={classes.icon} />
        <StorefrontOutlined className={classes.icon} />
      </>
    ),
    [STOCK_REASONS.Supply]: () => (
      <>
        <SpaOutlined className={classes.icon} />
        <ChevronRightOutlined className={classes.icon} />
        <StorefrontOutlined className={classes.icon} />
      </>
    ),
    [STOCK_REASONS.SupplyStore]: () => (
      <>
        <SpaOutlined className={classes.icon} />
        <ChevronRightOutlined className={classes.icon} />
        <StorefrontOutlined className={classes.icon} />
      </>
    ),
  };
  const Icon = icons[type];
  return (
    <Tooltip title={STOCK_REASONS_DESCRIPTION[type]}>
      <Box>
        <Icon />
      </Box>
    </Tooltip>
  );
};

export default MovimentationIcon;
