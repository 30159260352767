import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { TextInput } from 'components/text-input';
import Masks from 'utils/Masks';
import Selecionar from 'components/select';
import { Button } from 'components/button';
import { Close, Search } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { OrdersActions } from 'store/ducks/orders';
import DateSelector from 'components/date-selector';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

function Filtro() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { order_id, status, pagamento, dateOrder } = useSelector(
    (state) => state.orders
  );

  const changeText = (event) => {
    dispatch(OrdersActions.changeText(event.target.id, event.target.value));
  };

  const getOrders = () => {
    dispatch(OrdersActions.getOrders());
  };

  const selected = ({ id, value }) => {
    dispatch(OrdersActions.changeText(id, value));
  };

  const handleDate = ({ id, value }) => {
    dispatch(OrdersActions.changeText(id, value));
  };

  const clear = () => {
    dispatch(OrdersActions.clear());
    dispatch(OrdersActions.getOrders());
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Title>{Texts.orders.sessions.buscarPedidos}</Title>
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextInput
          label={Texts.orders.fields.pedido}
          mask={Masks.pedido}
          id="order_id"
          onChange={changeText}
          value={order_id}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DateSelector
          id="dateOrder"
          type="date"
          label="Data da Compra"
          value={dateOrder}
          onChange={handleDate}
          disableFuture
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <Selecionar
          label={Texts.orders.fields.formaPagamento}
          onChange={selected}
          id="pagamento"
          options={[
            { id: 'IN_STORE_PAYMENT', name: 'Pagamento em loja' },
            { id: 'PAYMENT_CREDIT_CARD', name: 'Pagamento no cartão' },
          ]}
          value={pagamento}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Selecionar
          label={Texts.orders.fields.status}
          onChange={selected}
          id="status"
          options={[
            {
              id: 'PAYMENT_CANCELED',
              name: 'Cancelado',
            },
            {
              id: 'PAYMENT_REFUSED',
              name: 'Pagamento recusado',
            },
            {
              id: 'PAYMENT_CONFIRMED',
              name: 'Pago',
            },
            {
              id: 'AWAITING_PAYMENT_CONFIRMATION',
              name: 'Aguardando pagamento',
            },
            {
              id: 'REQUESTED_CANCELLATION',
              name: 'Cancelamento Solicitado',
            },
          ]}
          value={status}
        />
      </Grid>
      <Grid item>
        <div className={classes.buttonContainer}>
          <Button
            icon={Close}
            onClick={clear}
            style={classes.button}
            title={Texts.orders.buttons.limpar}
          />
          <Button
            icon={Search}
            onClick={getOrders}
            style={classes.button}
            title={Texts.orders.buttons.buscar}
            colored
          />
        </div>
      </Grid>
    </>
  );
}

export default Filtro;
