import { call, put, select } from 'redux-saga/effects';
import { LojaApi } from 'services/api/LojaApi';
import UserTypes from 'utils/UserTypes';
import { UserApi } from 'services/api/UserApi';
import { Errors, Texts } from 'constants/strings';
import { NovoUsuarioActions } from 'store/ducks/novo-usuario';
import { Creators as UsuarioActions } from 'store/ducks/usuarios';
import { RemoveMasks } from 'utils/Masks';
import { NotificationActions } from 'store/ducks/notification';

export function* getLojaByCpnj() {
  try {
    const { cnpjSearch, stores } = yield select((state) => state.novoUsuario);
    if (cnpjSearch.length < 18) {
      return yield put(
        NovoUsuarioActions.searchLojaError(Errors.findCNPJLenght)
      );
    }
    const { data } = yield call(LojaApi.getLojaByCnpj, cnpjSearch);
    const loja = data.data[0]; // Só é possível uma loja por pesquisa
    const already = stores.find((store) => store.store_id === loja.store_id);

    if (!loja) {
      yield put(NovoUsuarioActions.searchLojaError(Errors.findCNPJ));
    } else if (already) {
      yield put(NovoUsuarioActions.searchLojaError(Errors.findCNPJAlready));
    } else {
      yield put(NovoUsuarioActions.searchLojaSuccess([...stores, loja]));
    }
  } catch (error) {
    yield put(NovoUsuarioActions.searchLojaError(Errors.findCNPJ));
  }
}

export function* saveNewUser() {
  try {
    const { cpf, rg, name, phone, email, birthdate, profile_id, stores } =
      yield select((state) => state.novoUsuario);
    const body = {
      cpf: RemoveMasks.onlyNumbers(cpf),
      rg,
      name,
      phone: RemoveMasks.onlyNumbers(phone),
      birthdate: RemoveMasks.dateISO(birthdate),
      profile_id,
      email,
      shopkeeper: profile_id === UserTypes.PDV,
      stores:
        profile_id === UserTypes.PDV
          ? stores.map((store) => store.store_id)
          : [],
    };
    yield call(UserApi.createNewUser, body);
    yield put(NovoUsuarioActions.successOnSaveUser());
    yield put(NotificationActions.success(Texts.usuarios.texts.sucessoCriar));
    yield put(NovoUsuarioActions.reset());
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 422) {
        const errors = {};
        data.forEach((err) => {
          errors[`${err.field}Err`] = err.message;
        });
        yield put(NovoUsuarioActions.errorOnSaveUser(errors));
      } else {
        yield put(NovoUsuarioActions.errorOnSaveUser({}));
      }
    } else {
      yield put(NovoUsuarioActions.errorOnSaveUser({}));
    }
  }
}

export function* update() {
  try {
    const {
      cpf,
      rg,
      name,
      phone,
      email,
      birthdate,
      profile_id,
      stores,
      updateUser,
    } = yield select((state) => state.novoUsuario);
    const body = {
      cpf: RemoveMasks.onlyNumbers(cpf),
      rg,
      name,
      phone: RemoveMasks.onlyNumbers(phone),
      birthdate: RemoveMasks.dateISO(birthdate),
      profile_id,
      email,
      shopkeeper: profile_id === UserTypes.PDV,
      stores:
        profile_id === UserTypes.PDV
          ? stores.map((store) => store.store_id)
          : [],
    };
    yield call(UserApi.updateUser, body, updateUser.user_id);
    yield put(NovoUsuarioActions.successOnSaveUser());
    yield put(
      NotificationActions.success(Texts.usuarios.texts.sucessoAtualizar)
    );
    yield put(UsuarioActions.listRequest());
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 422) {
        const errors = {};
        data.forEach((err) => {
          errors[`${err.field}Err`] = err.message;
        });
        yield put(NovoUsuarioActions.errorOnSaveUser(errors));
      } else {
        yield put(NovoUsuarioActions.errorOnSaveUser({}));
      }
      yield put(NotificationActions.error(error.response.data));
    } else {
      yield put(NovoUsuarioActions.errorOnSaveUser({}));
      yield put(
        NotificationActions.error('Ocorreu um erro ao atualizar o usuário')
      );
    }
  }
}

export function* changeStatus() {
  try {
    const { updateUser } = yield select((state) => state.novoUsuario);
    yield call(
      UserApi.updateUserStatus,
      !updateUser.active,
      updateUser.user_id
    );
    yield put(NovoUsuarioActions.changeStatusSuccess());
    yield put(
      NotificationActions.success(Texts.usuarios.texts.sucessoAtivarInativar)
    );
    yield put(UsuarioActions.listRequest());
  } catch (error) {
    yield put(
      NovoUsuarioActions.changeStatusError(Errors.userErrorChangeStatus)
    );
    yield put(NotificationActions.error(Errors.userErrorChangeStatus));
  }
}
