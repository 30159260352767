import { Button as ButtonBase, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/loading';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  normal: {},
  colored: {
    background:
      'linear-gradient(160deg, rgba(243,42,86,1) 40%, rgba(139,34,89,1) 100%)',
    color: theme.palette.common.white,
  },
  iconNormal: {},
  iconColored: {
    color: theme.palette.common.white,
  },
}));

function Button({
  title,
  icon: Icon,
  onClick,
  loading,
  colored,
  style,
  disable,
  border,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const buttonStyle = colored ? classes.colored : classes.normal;
  const iconStyle = colored ? classes.iconColored : classes.iconNormal;
  const loadingColor = colored
    ? theme.palette.common.white
    : theme.palette.primary.main;

  return (
    <ButtonBase
      className={clsx(buttonStyle, style)}
      onClick={onClick}
      variant={border ? 'outlined' : 'text'}
      color="primary"
      disabled={disable}
      startIcon={Icon && !loading && <Icon className={iconStyle} />}
    >
      {!loading && title}
      {loading && <Loading color={loadingColor} />}
    </ButtonBase>
  );
}

Button.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.elementType,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disable: PropTypes.bool,
  colored: PropTypes.bool,
  style: PropTypes.string,
  border: PropTypes.bool,
};

Button.defaultProps = {
  title: '',
  icon: null,
  onClick: () => {},
  loading: false,
  disable: false,
  colored: false,
  style: '',
  border: true,
};

export { Button };
