import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BannersApi } from 'services/api/BannersApi';
import { NotificationActions } from 'store/ducks/notification';
import { Banner } from 'utils/types/banners';
import { Store } from 'utils/types/store';
import axios from 'axios';
import { Helpers } from 'utils/helpers';

type Link = {
  [key: string]: string;
};

export interface BannerImage {
  id: string;
  image: string;
  link?: string;
}

interface BannersProps {
  children: React.ReactNode;
}

interface Banners {
  banners: Array<BannerImage>;
  links: Link;
  setBanners: (images: Array<BannerImage>) => void;
  store?: Store;
  setStore: (store: Store) => void;
  getBanners(storeId: number): void;
  createBanner: (images: string[], link?: string) => void;
  deleteBanner: (bannerId: string) => void;
  resetBanners(): void;
  setLink(id: string, value: string): void;
  editLinkBanner(id: string): void;
}

const BannersContext = createContext<Banners | null>(null);

const BannersProvider = ({ children }: BannersProps) => {
  const dispatch = useDispatch();
  const [store, setStore] = useState<Store | undefined>();
  const [banners, setBanners] = useState<Array<BannerImage>>([]);
  const [links, setLinks] = useState<Link>({});

  const getBannersByStore = useCallback(
    async (storeId: number) => {
      dispatch(NotificationActions.info('Buscando os banners...aguarde'));
      try {
        const { data } = await BannersApi.getBannersByStore(storeId);
        const linksApi: Link = {};

        setBanners(
          data.data.map((banner: Banner) => {
            const id = banner.store_banner_id.toString();
            if (banner?.link) {
              linksApi[id] = banner?.link;
            }
            return {
              id,
              image: banner.fileurl,
              link: banner?.link || '',
            };
          })
        );
        setLinks((oldState) => ({ ...linksApi, ...oldState }));
        dispatch(NotificationActions.dismiss());
      } catch (error) {
        dispatch(NotificationActions.error(error));
      }
    },
    [dispatch]
  );

  const editLinkBanner = async (bannerId: string) => {
    const link = links[bannerId];
    if (link && !Helpers.validateLink(link)) {
      dispatch(NotificationActions.error('Link inválido'));
      return;
    }
    try {
      await BannersApi.updateLink({
        bannerId,
        storeId: store?.store_id || 0,
        link,
      });
      const bannersUpdate = banners.map((banner) => ({
        ...banner,
        link: banner.id === bannerId ? link : banner.link,
      }));
      setBanners(bannersUpdate);
      dispatch(NotificationActions.success('Link atualizado'));
    } catch (error) {
      dispatch(NotificationActions.error('Ocorreu um erro, tente novamente'));
    }
  };

  const deleteBanner = useCallback(
    async (bannerId: string) => {
      dispatch(NotificationActions.info('Removendo Banner'));
      try {
        await BannersApi.removeBanner(bannerId, store?.store_id);
        const newBanners = banners.filter((banner) => banner.id !== bannerId);
        setBanners(newBanners);
        dispatch(NotificationActions.success('Sucesso'));
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 304) {
            const newBanners = banners.filter(
              (banner) => banner.id !== bannerId
            );
            setBanners(newBanners);
            dispatch(NotificationActions.success('Sucesso'));
            return;
          }
        }
        dispatch(NotificationActions.error('Ocorreu um erro, tente novamente'));
      }
    },
    [store, banners]
  );

  const resetBanners = useCallback(() => {
    setStore(undefined);
    setBanners([]);
  }, []);

  const createBanner = useCallback(
    async (images: string[], link?: string) => {
      try {
        dispatch(NotificationActions.info('Salvando alterações...aguarde'));
        await Promise.all(
          images.map((image) =>
            BannersApi.createBanner(
              {
                image,
                link: link || '',
              },
              store?.store_id
            )
          )
        );
        getBannersByStore(store?.store_id || 0);
        dispatch(NotificationActions.success('Banner Adicionado'));
      } catch (error) {
        dispatch(NotificationActions.error(error));
      }
    },
    [store, banners, dispatch, getBannersByStore]
  );

  const setLink = useCallback(
    (id: string, value: string) => {
      setLinks((oldState) => ({ ...oldState, [id]: value }));
    },
    [banners, links]
  );

  return (
    <BannersContext.Provider
      value={{
        store,
        banners,
        setBanners,
        setStore,
        links,
        setLink,
        getBanners: getBannersByStore,
        resetBanners,
        createBanner,
        deleteBanner,
        editLinkBanner,
      }}
    >
      {children}
    </BannersContext.Provider>
  );
};

const useBanners = () => {
  const context = useContext(BannersContext);
  if (!context) {
    throw new Error('useBanners must be user within an BannersProvider');
  }
  return context;
};

export { BannersProvider, useBanners };
