import { makeStyles } from '@material-ui/core';
import { Creators as UserActions } from 'store/ducks/user';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
}));

function Unauthorized() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserActions.logout());
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <p>Sem autorização</p>
    </div>
  );
}

export { Unauthorized };
