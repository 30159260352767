import { Grid } from '@material-ui/core';
import Main from 'components/main-container';
import { Texts } from 'constants/strings';
import React from 'react';
import { useSelector } from 'react-redux';
import LastMessages from './components/LastMessages';
// import Cancelations from './components/Cancelations';
import LowStock from './components/LowStock';
import OpenOrdersShopkeeper from './components/OpenOrdersShopkeeper';
import Sales from './components/Sales';

function Dashboard() {
  const { shopkeeper } = useSelector((state) => state.user.user);

  return (
    <Main>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={12}>
          {!shopkeeper && <Cancelations />}
        </Grid> */}
        <Grid item xs={12} sm={12}>
          {shopkeeper && <LastMessages />}
        </Grid>
        <Grid item xs={12} sm={12}>
          {shopkeeper && <OpenOrdersShopkeeper />}
        </Grid>
        <Grid item xs={12} sm={12}>
          {!shopkeeper && <LowStock />}
        </Grid>
        <Grid item xs={12} sm={12}>
          {!shopkeeper && (
            <Sales type="daily" title={Texts.dashboard.cards.diario} />
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          {!shopkeeper && (
            <Sales type="monthly" title={Texts.dashboard.cards.mensal} />
          )}
        </Grid>
      </Grid>
    </Main>
  );
}

export { Dashboard };
