import { List } from '@material-ui/core';
import Acl from 'components/acl';
import React from 'react';
import RoutesConfig from 'routes/RoutesConfig';
import MenuItem from './MenuItem';

function Menu() {
  const drawerRoutes = RoutesConfig.filter((router) => router.drawer);
  return (
    <List>
      {drawerRoutes.map((router) => (
        <Acl module={router.module} key={router.path} onlyPdv={router.onlyPdv}>
          <MenuItem key={router.path} item={router} />
        </Acl>
      ))}
    </List>
  );
}

export default Menu;
