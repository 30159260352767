import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/loading';

function LoadingFailure({ children, failure, loading }) {
  if (loading) {
    return <Loading />;
  }
  if (failure) {
    return <p>ERROR</p>;
  }
  return children;
}

LoadingFailure.propTypes = {
  loading: PropTypes.bool,
  failure: PropTypes.bool,
  children: PropTypes.element,
};

LoadingFailure.defaultProps = {
  loading: false,
  failure: false,
  children: <></>,
};

export { LoadingFailure };
