import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './ducks/reducers';
import rootSaga from './sagas';

const persistConfig = {
  key: 'Wessex@root',
  blacklist: ['resetPassword'],
  storage,
};

// creates the store
/* ------------- Redux Configuration ------------- */
const middleware = [];
const enhancers = [];

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

/* ------------- Assemble Middleware ------------- */
enhancers.push(applyMiddleware(...middleware));

const persistedReducer = persistReducer(persistConfig, reducers);
const store = createStore(persistedReducer, compose(...enhancers));
const persistor = persistStore(store);

// kick off root saga
sagaMiddleware.run(rootSaga);

export { store, persistor };
