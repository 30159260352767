import React, { useEffect, useState } from 'react';

import Logo from 'components/logo';
import ModalResetPassword from 'components/modal-reset-password';
import { ColoredButton } from 'components/button';
import { Creators as AuthActions } from 'store/ducks/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Creators as UserActions } from 'store/ducks/user';
import { Version } from 'components';
import { Container } from './components/Styled';
import Fields from './components/Fields';

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loadingRequest } = useSelector((state) => state.auth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    dispatch(UserActions.logout());
  }, [dispatch]);

  const makeLogin = async () => {
    if (email && password) {
      dispatch(AuthActions.login({ email, password, history }));
    }
  };

  return (
    <Container>
      <Logo />
      <Fields
        email={email}
        password={password}
        setPassword={setPassword}
        setEmail={setEmail}
      />
      <ColoredButton
        loading={loadingRequest}
        title="Entrar"
        onClick={makeLogin}
      />
      <Version />
      <ModalResetPassword />
    </Container>
  );
}

export { Login };
