import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardMaterialUI from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

function Card({ children, style }) {
  const classes = useStyles();

  return (
    <CardMaterialUI className={clsx(classes.root, style)}>
      {children}
    </CardMaterialUI>
  );
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.string,
};

Card.defaultProps = {
  style: '',
};

export { Card };
