type FormatterParams = {
  queries: string[];
  operator?: string;
  page?: string | number;
  field?: string | null;
  sort?: string | null;
};

export default {
  queryFormatter: (
    queries: string[] = [],
    operator = 'and',
    page: string | number,
    field: string,
    sort: string
  ) => {
    const params = [];
    if (queries.length > 0)
      params.push(`query=${queries.join(`&${operator}=`)}`);
    if (page) params.push(`page=${page}`);
    if (sort && field) params.push(`sort=${field},${sort}`);
    return params.length > 0 ? `?${params.join('&')}` : '';
  },
  formatter: ({
    queries = [],
    operator = 'and',
    page = 0,
    field = null,
    sort = null,
  }: FormatterParams) => {
    const params = [];
    if (queries.length > 0)
      params.push(`query=${queries.join(`&${operator}=`)}`);
    if (page && page !== 0) params.push(`page=${page}`);
    if (sort && field) params.push(`sort=${field},${sort}`);
    return params.length > 0 ? `?${params.join('&')}` : '';
  },
  // `?query=${}`,
  like: (field: string, value: string | number) => `${field}||ilike||${value}`,
  bool: (field: string, value: string | number) => `${field}||eq||${value}`,
  equal: (field: string, value: string | number) => `${field}||eq||${value}`,
  notEqual: (field: string, value: string | number) => `${field}||ne||${value}`,
  greaterThat: (field: string, value: string | number) =>
    `${field}||gt||${value}`,
  greaterThanOrEqual: (field: string, value: string | number) =>
    `${field}||gte||${value}`,
  lowerThat: (field: string, value: string | number) =>
    `${field}||lt||${value}`,
  lowerThanOrEqual: (field: string, value: string | number) =>
    `${field}||lte||${value}`,
  in: (field: string, values: string[] = []) =>
    `${field}||in||${values.join(',')}`,
  notIn: (field: string, values: string[] = []) =>
    `${field}||notin||${values.join(',')}`,
  isNull: (field: string) => `${field}||isnull`,
  notNull: (field: string) => `${field}||notnull`,
  between: (field: string, value1: string, value2: string) =>
    `${field}||between||${value1},${value2}`,
  interval: (field: string, from: string, to: string) =>
    `${field}||between||${from},${to} 23:59:59`,
};
