import { Grid } from '@material-ui/core';
import React from 'react';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { TextInput } from 'components/text-input';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from '@material-ui/icons';
import { Button } from 'components/button';
import { EmpresaActions } from 'store/ducks/empresa';
import Masks from 'utils/Masks';

function Endereco() {
  const {
    postcode,
    postcodeErr,
    street,
    neighborhood,
    city,
    uf,
    number,
    numberErr,
    cepLoading,
    disableFields,
  } = useSelector((state) => state.empresa);
  const dispatch = useDispatch();

  const changeText = (event) => {
    dispatch(EmpresaActions.fields(event.target.id, event.target.value));
  };

  const getCep = () => {
    dispatch(EmpresaActions.getCep());
  };

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.empresa.sessions.endereco}</Title>
      </Grid>
      {
        // :CEP e Botão Buscar cep
      }
      <Grid item xs={12} sm={5}>
        <TextInput
          disabled={disableFields}
          label={Texts.novaLoja.fields.cep}
          id="postcode"
          required
          mask={Masks.cep}
          onChange={changeText}
          value={postcode}
          error={postcodeErr}
        />
      </Grid>
      <Grid item>
        <Button
          icon={Search}
          onClick={getCep}
          disable={disableFields}
          loading={cepLoading}
          title={Texts.empresa.buttons.buscar}
        />
      </Grid>
      {
        // Rua - Número
      }
      <Grid item xs={12} sm={8}>
        <TextInput
          disabled
          label={Texts.novaLoja.fields.rua}
          id="street"
          onChange={changeText}
          value={street}
          // error={streetErr}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextInput
          label={Texts.novaLoja.fields.numero}
          id="number"
          required
          disabled={disableFields}
          mask={Masks.endNumber}
          onChange={changeText}
          value={number}
          error={numberErr}
        />
      </Grid>
      {
        // Bairro
      }
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled
          label={Texts.empresa.fields.bairro}
          id="neighborhood"
          onChange={changeText}
          value={neighborhood}
          // error={neighborhoodErr}
        />
      </Grid>
      {
        // Cidade - Bairro
      }
      <Grid item xs={8} sm={6}>
        <TextInput
          disabled
          label={Texts.empresa.fields.cidade}
          id="city"
          onChange={changeText}
          value={city}
          // error={cityErr}
        />
      </Grid>
      <Grid item xs={4} sm={6}>
        <TextInput
          disabled
          label={Texts.empresa.fields.uf}
          id="uf"
          onChange={changeText}
          value={uf}
          // error={ufErr}
        />
      </Grid>
    </>
  );
}

export default Endereco;
