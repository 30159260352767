export const Modules = {
  AUTHORIZED: 'permission@AUTHORIZED',
  MODULE_DASHBOARD: 'MODULE_DASHBOARD',
  MODULE_COMPANY: 'MODULE_COMPANY',
  MODULE_USERS: 'MODULE_USERS',
  MODULE_PRODUCTS: 'MODULE_PRODUCTS',
  MODULE_STORE: 'MODULE_STORE',
  MODULE_ORDERS: 'MODULE_ORDERS',
  MODULE_MINI_STORES: 'MODULE_MINI_STORES',
  MODULE_FISCAL_NOTE: 'MODULE_FISCAL_NOTE',
  MODULE_UPDATES: 'MODULE_UPDATES',
  MODULE_REPORTS: 'MODULE_REPORTS',
};
