import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    color: theme.palette.common.white,
  },
  errors: {
    color: theme.palette.common.white,
  },
}));

function ErrorContainer({ title, errors, className }) {
  const classes = useStyles();

  return errors.length > 0 ? (
    <div className={clsx(classes.container, className)}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      {errors.map((err) => (
        <Typography className={classes.errors}>{`- ${err}`}</Typography>
      ))}
    </div>
  ) : null;
}

ErrorContainer.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  className: PropTypes.string,
};

ErrorContainer.defaultProps = {
  errors: [],
  title: 'Ocorreu os seguintes erros',
  className: '',
};

export { ErrorContainer };
