import RouterNames from 'constants/router-names';
import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import api from 'services/config/api';

export function ApiInterceptors() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    api.interceptors.response.use(undefined, (error) => {
      if (error && error.response && error.response.status) {
        if (error.response.status === 403) {
          history.push(RouterNames.FORBIDDEN);
        }
        /**
         * Corrigido falha onde o erro de login também mostrava a tela de não autorização
         */
        if (error.response.status === 401 && location.pathname !== '/login') {
          history.push(RouterNames.UNAUTHORIZED);
        }
      }
      return Promise.reject(error);
    });
  }, [history, location]);
  return <></>;
}
