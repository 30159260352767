import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Forbidden from 'pages/forbidden';

function Acl({ children, module, permission, redirect, onlyPdv }) {
  const { profile, permissions, modules } = useSelector(
    (state) => state.user.acl
  );

  const next = useMemo(() => {
    if (profile && profile === 'PROFILE_ADMIN' && onlyPdv) {
      return false;
    }

    if (profile && profile === 'PROFILE_ADMIN') {
      return true;
    }

    if (modules.indexOf(module) !== -1) {
      return true;
    }

    if (permissions.indexOf(permission) !== -1) {
      return true;
    }
    return false;
  }, [profile, modules, permissions, module, onlyPdv, permission]);

  function Action() {
    if (redirect) return <Forbidden />;
    return <></>;
  }

  return next ? children : <Action />;
}

Acl.propTypes = {
  children: PropTypes.element.isRequired,
  module: PropTypes.string,
  permission: PropTypes.string,
  redirect: PropTypes.bool,
  onlyPdv: PropTypes.bool,
};

Acl.defaultProps = {
  module: '',
  permission: '',
  redirect: false,
  onlyPdv: false,
};

export { Acl };
