import { Grid } from '@material-ui/core';
import ButtonBack from 'components/button-back';
import Main from 'components/main-container';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMessages } from 'context/messages';
import { useShopkeeper } from 'context/store';
import { TextInput } from 'components/text-input';
import { Button } from 'components/button';
import If from 'components/if/If';
import { AddAlert } from '@material-ui/icons';
import { RichEditor } from 'components';

const Message = () => {
  const history = useHistory();
  const [fields, setFields] = useState({
    title: '',
    message: '',
  });
  const { message, createMessage, updateMessage } = useMessages();
  const isShopkeeper = useShopkeeper();

  const changeText = (event: any) => {
    setFields({ ...fields, [event.target.id]: event.target.value });
  };

  useEffect(() => {
    if (message) {
      setFields({ title: message.title, message: message.message });
    }
  }, [message]);

  const goBack = () => {
    history.goBack();
  };

  const create = () => {
    createMessage(fields.title, fields.message, goBack);
  };

  const update = () => {
    updateMessage(fields.title, fields.message);
  };

  const onChangeEditor = (field: string, value: string) => {
    setFields({ ...fields, [field]: value });
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ButtonBack />
        </Grid>
        <If condition={!isShopkeeper && !!message}>
          <Grid item>
            <Button
              icon={AddAlert}
              onClick={update}
              title="Atualizar Mensagem"
              colored
            />
          </Grid>
        </If>
        <Grid item xs={12} sm={12}>
          <TextInput
            label="Título"
            id="title"
            onChange={changeText}
            value={fields.title}
            disabled={isShopkeeper}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <RichEditor
            field="message"
            onChange={onChangeEditor}
            value={fields.message}
            disabled={isShopkeeper}
            placeholder="Escreva a mensagem destinada ao Lojista"
          />
        </Grid>
        <If condition={!isShopkeeper && !message}>
          <Grid item xs={12} sm={12}>
            <Button
              icon={AddAlert}
              onClick={create}
              title="Criar Mensagem"
              colored
            />
          </Grid>
        </If>
      </Grid>
    </Main>
  );
};

export { Message };
