import { Grid } from '@material-ui/core';
import ButtonBack from 'components/button-back';
import Main from 'components/main-container';
import React, { useCallback, useEffect, useState } from 'react';
import { useOrders } from 'context/orders';
import Table from 'components/pagination-table';
import Masks, { AddMasks } from 'utils/Masks';
import queryCreator from 'utils/queryCreator';
import moment, { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ducks/notification';
import { TextInput } from 'components/text-input';
import DateSelector, { DateSelectorChange } from 'components/date-selector/';
import { Button } from 'components/button';
import { ArrowForward, Close, Search } from '@material-ui/icons';
import Selecionar from 'components/select';
import { STATUS_ORDER_SELECTED, TYPES_PAYMENT } from 'constants/config/Config';
import StoreSelector from 'components/store-selector';
import { Store } from 'utils/types/store';
import { Order } from 'utils/types/order';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';

interface FieldsFilter {
  order: string;
  payment: string;
  status: string;
  fromDate?: Moment;
  toDate?: Moment;
  stand?: Store;
}

interface IdValue {
  id: string;
  value: any;
}

const initialFields: FieldsFilter = {
  order: '',
  status: '',
  fromDate: moment(),
  toDate: moment(),
  payment: '',
  stand: undefined,
};

const ReportOrders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getOrders, setOrders, orders } = useOrders();
  const [fields, setFields] = useState<FieldsFilter>(initialFields);

  const changeText = (event: any) => {
    setFields({ ...fields, [event.target.id]: event.target.value });
  };

  const handleDate = ({ id, value }: DateSelectorChange) => {
    setFields((state) => ({ ...state, [id]: value }));
  };

  const changeSelect = ({ id, value }: IdValue) => {
    setFields({ ...fields, [id]: value });
  };

  const clear = () => setFields(initialFields);

  const filter = useCallback(
    (pageNumber: number = 1) => {
      const queries = [];
      const { order, fromDate, toDate, payment, status, stand } = fields;
      if (order.trim()) {
        queries.push(queryCreator.equal('order_id', order));
      }
      if (payment.trim()) {
        queries.push(queryCreator.equal('typePayment.constant', payment));
      }
      if (status.trim()) {
        queries.push(
          queryCreator.equal('currentStatus.status.constant', status)
        );
      }
      if (stand) {
        queries.push(
          queryCreator.equal(
            'store.storeProduct.store.store_id',
            stand.store_id
          )
        );
      }
      if (fromDate && toDate) {
        if (moment(fromDate).isAfter(moment(toDate), 'days')) {
          dispatch(
            NotificationActions.warning(
              'Data inicial não pode ser maior que data final'
            )
          );
          return;
        }

        const date1 = moment(fromDate).format('YYYY-MM-DD');
        const date2 = moment(toDate).format('YYYY-MM-DD');
        queries.push(queryCreator.interval('created_at', date1, date2));
      } else if (fromDate || toDate) {
        dispatch(NotificationActions.warning('Informe as duas datas'));
      }

      setOrders(null);
      getOrders(
        queryCreator.formatter({
          queries,
          page: pageNumber,
          sort: 'desc',
          field: 'created_at',
        })
      );
      dispatch(NotificationActions.info('Buscando, aguarde um momento...'));
    },
    [fields, dispatch, getOrders, setOrders]
  );

  const showDetails = (order: Order) => {
    history.push(
      RouterNames.REPORTS_ORDERS_DETALHES.replace(
        ':orderId',
        order.order_id.toString()
      )
    );
  };

  useEffect(() => {
    filter(1);
    return () => setOrders(null);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const total = orders?.total ? parseInt(orders.total, 10) : 0;
  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ButtonBack />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Nº Pedido"
            id="order"
            mask={Masks.onlyNumbers}
            onChange={changeText}
            value={fields.order}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateSelector
            id="fromDate"
            type="date"
            label="De"
            value={fields.fromDate}
            onChange={handleDate}
            disableFuture
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateSelector
            id="toDate"
            type="date"
            label="Até"
            value={fields.toDate}
            onChange={handleDate}
            disableFuture
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Selecionar
            label="Forma de Pagamento"
            onChange={changeSelect}
            id="payment"
            options={TYPES_PAYMENT}
            value={fields.payment}
            allowEmpty={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Selecionar
            label="Status do Pedido"
            onChange={changeSelect}
            id="status"
            options={STATUS_ORDER_SELECTED}
            value={fields.status}
            allowEmpty={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <StoreSelector
            value={fields.stand}
            onChange={changeSelect}
            id="stand"
          />
        </Grid>
        <Grid item>
          <Button icon={Close} onClick={clear} title="Limpar" />
        </Grid>
        <Grid item>
          <Button
            icon={Search}
            onClick={() => filter(1)}
            title="Filtrar"
            colored
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={[
              'Nº Pedido',
              'Stand',
              'Data do Pedido',
              'Valor',
              'Pagamento',
              'Status',
            ]}
            values={orders?.data || []}
            fields={(order) => [
              order.order_id.toString(),
              order.store.storeProduct.store.fantasy_name,
              AddMasks.dateHour(order.created_at),
              AddMasks.money(order.totals.total),
              order.typePayment.description,
              order.currentStatus.status.description,
            ]}
            pagination={total > 0}
            page={orders?.page || 1}
            total={total}
            perPage={orders?.perPage}
            onChangePage={(numPage) => filter(numPage)}
            hasAction
            action={showDetails}
            actionLabel="Itens"
            Icon={ArrowForward}
          />
        </Grid>
      </Grid>
    </Main>
  );
};

export default ReportOrders;
