import React from 'react';
import './App.css';
import { MainRoutes } from 'routes';
import { Provider } from 'react-redux';
import { store, persistor } from 'store/store';
import { PersistGate } from 'redux-persist/integration/react';
import Notification from 'components/notification';
import GeneralLoading from 'components/general-loading';
import { FornecedorProvider } from 'context/FornecedorProvider';
import { ProductProvider } from 'context/ProductProvider';
import { FiscalProvider } from 'context/FiscalProvider';
import { ReportProvider } from 'context/reports';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import brLocale from 'date-fns/locale/pt-BR';
import { OrderProvider } from 'context/orders';
import { StoreProvider } from 'context/store';
import { BestClientProvider } from 'context/best-clients';
import { ClientProvider } from 'context/clients';
import { MovesStockProvider } from 'context/stocks';
import { PromotionProvider } from 'context/promotions';
import { MessageProvider } from 'context/messages';
import { BannersProvider } from 'context/banners';
import { Provider as ProviderMobx } from 'mobx-react';
import storeMobx from 'stores';
import * as Toastify from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ProviderMobx rootStore={storeMobx}>
      <Provider store={store}>
        <Toastify.ToastContainer />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
          <ProductProvider>
            <PromotionProvider>
              <StoreProvider>
                <BannersProvider>
                  <BestClientProvider>
                    <ClientProvider>
                      <MessageProvider>
                        <OrderProvider>
                          <FornecedorProvider>
                            <FiscalProvider>
                              <ReportProvider>
                                <MovesStockProvider>
                                  <PersistGate
                                    loading={null}
                                    persistor={persistor}
                                  >
                                    <MainRoutes />
                                    <Notification />
                                    <GeneralLoading />
                                  </PersistGate>
                                </MovesStockProvider>
                              </ReportProvider>
                            </FiscalProvider>
                          </FornecedorProvider>
                        </OrderProvider>
                      </MessageProvider>
                    </ClientProvider>
                  </BestClientProvider>
                </BannersProvider>
              </StoreProvider>
            </PromotionProvider>
          </ProductProvider>
        </MuiPickersUtilsProvider>
      </Provider>
    </ProviderMobx>
  );
}

export default App;
