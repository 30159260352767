import Uris from 'constants/endpoints';
import api from 'services/config/api';

export const ProdutoApi = {
  getProdutos: (params = '') => {
    const url = `${Uris.getProdutos}${params}`;
    return api.get(url);
  },
  getProdutoDetails: (productId) => {
    return api.get(Uris.getProductDetails.replace('{productId}', productId));
  },
  createProduto: (data) => {
    return api.post(Uris.createProduto, data);
  },
  updateProduto: (data, idProduto) => {
    return api.put(`${Uris.updateProduto}/${idProduto}`, data);
  },
  activeInactive: (idProduto, active) => {
    const url = Uris.activeInactiveProduto.replace('{productId}', idProduto);
    return api.patch(url, { active });
  },
  getGroups: () => {
    return api.get(Uris.getProductGroups);
  },
  getProvidersList: (query) => {
    return api.get(`${Uris.getProvidersList}${query}`);
  },
  createProvider: (provider) => {
    return api.post(Uris.createProvider, provider);
  },
  updateProvider: (provider, providerId) => {
    return api.put(
      Uris.updateProvider.replace('{providerId}', providerId),
      provider
    );
  },
  sendProductToStore: (productId, storeId, quantity) => {
    const url = Uris.sendProductToStore
      .replace('{productId}', productId)
      .replace('{storeId}', storeId);
    return api.post(url, { quantity });
  },
  removeProductFromStore: (productId, storeId, quantity) => {
    const url = Uris.bringProductFromStore
      .replace('{productId}', productId)
      .replace('{storeId}', storeId);
    return api.post(url, { quantity });
  },
};
