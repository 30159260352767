const drawer = {
  dashboard: 'Página Inicial',
  usuarios: 'Usuários',
  criarUsuarios: 'Novo usuário',
  produtos: 'Produtos',
  logout: 'Deslogar',
  notasFiscais: 'Notas Fiscais',
  lojas: 'Lojas',
  relatorios: 'Relatórios',
  miniLojas: 'Mini Lojas',
  orders: 'Pedidos',
  atualizacoes: 'Atualizações',
};

const pages = {
  dashboard: 'Página Inicial',
  usuarios: 'Usuários',
  criarUsuarios: 'Novo usuário',
  produtos: 'Produtos',
  produtos_novo: 'Novo Produto',
  promocoes: 'Promoções',
  promocoesEditar: 'Atualizar Promoção',
  promocoesNovo: 'Nova Promoção',
  produtos_editar: 'Detalhes Produto',
  providers: 'Fornecedores',
  providers_novo: 'Novo Fornecedor',
  providers_edit: 'Editar Fornecedor',
  logout: 'Deslogar',
  notasFiscais: 'Notas Fiscais',
  notaEntrada: 'Entrada de Nota',
  notaSaida: 'Nota de Saída',
  notaDetalhes: 'Detalhes da nota fiscal',
  lojas: 'Lojas',
  banners: 'Banners',
  novaLoja: 'Nova Loja',
  gerenciarEstoque: 'Gerenciar Estoque',
  relatorios: 'Relatórios',
  miniLojas: 'Mini Lojas',
  atualizacoes: 'Atualizações',
  orders: 'Pedidos',
  ordersDetails: 'Detalhes do pedido',
  empresa: 'Dados da Empresa',
  usuarioPdv: 'Informações de Usuário',
  cancellations: 'Solicitações de Cancelamento',
  cancellationsDetails: 'Detalhe da solicitação',
  mensagens: 'Mensagem para Lojista',
  mensagem: 'Visualização de Mensagem',
  reportsOrders: 'Detalhes do Pedido',
  faturamento: 'Relatórios de Faturamento',
  produtosMaisVendidos: 'Produtos mais Vendidos',
  movimentacoes: 'Movimentações',
  reportsFinancial: 'Financeiro',
  reportsBestClients: 'Melhores Clientes',
  reportsBestClientsDetails: 'Detalhes dos Pedidos',
};

const login = {
  labelEmail: 'e-mail',
  labelPassword: 'senha',
  forgotPassword: 'Esqueci a senha',
  btEntrar: 'Entrar',
};

const recuperarSenha = {
  titleInsertEmail: 'Esqueci minha senha',
  infoInserEmail: 'Informe o e-mail cadastrado em sua conta Wessex.',
  buttonEnviar: 'Enviar',
  labelEmail: 'E-mail',
  sendEmailPrefix: 'Foi enviado um e-mail para sua caixa de mensagem',
  sendEmailSufix: ' com as instruções para redefinir sua senha.',
  titleScreenUpdatePassword: 'Alterar Senha',
  subtitleScreenUpdatePassword: 'Crie uma nova senha.',
  tooltipScreenPassword:
    'A senha deve conter pelo menos 8 caracteres, um símbolo, um número e letras maiúsculas e minúsculas.',
  resetPasswordError: 'A senha não atinge aos requisitos mínimos',
  min: 'mínimo 8 caractéres',
  number: '1 número',
  uppercase: '1 letra maiúscula',
  lowercase: '1 letra minúscula',
  special: '1 caractére especial',
  fieldPassword: 'Senha',
  fieldRepeatPassword: 'Repetir Senha',
  buttonPassword: 'Alterar Senha',
  diffPasswords: 'Senhas não conferem',
  resetSuccess: 'A senha foi alterada com sucesso!',
  resetSuccessButton: 'Efetuar Login',
};

const usuarios = {
  buttons: {
    voltar: 'Voltar',
    pdv: 'PDV',
    admin: 'Admin',
    selecLoja: 'Selecionar Loja',
    cadastrar: 'Cadastrar Usuário',
    pesquisarLoja: 'Pesquisar',
    callNewUser: 'Criar Novo Usuário',
    limpar: 'Limpar',
    pesquisar: 'Buscar',
    salvar: 'Salvar',
    inativar: 'Inativar',
    ativar: 'Ativar',
  },
  texts: {
    acesso:
      'Informe um e-mail para acesso ao PDV. Será enviada uma senha para o endereço informado.',
    lojas:
      'Adicione as lojas que o usuário poderá ter acesso. Essa etapa poderá ser realizada posteriormente no perfil do usuário ou da loja.',
    selecionarLojas:
      'Informe o CNPJ da empresa previamente cadastrada para prosseguir',
    sucessoCriar: 'O novo usuário foi criado com sucesso!',
    sucessoAtualizar: 'Usuário foi atualizado com sucesso!',
    sucessoAtivarInativar: 'Usuário Ativado/Inativado com sucesso',
  },
  sessions: {
    geral: 'Dados Gerais',
    acesso: 'Dados de Acesso',
    tipoUsuario: 'Tipo do Usuário',
    lojas: 'Lojas',
    selecionarLojas: 'Selecionar Lojas',
    buscarUsuarios: 'Buscar Usuário',
    sucessoCriar: 'Novo Usuário',
  },
  inputLabels: {
    nome: 'Nome Completo',
    nasc: 'Nasc',
    telefone: 'Telefone',
    rg: 'RG',
    cpf: 'CPF',
    email: 'E-mail',
    cnpj: 'CNPJ',
    filterName: 'Nome',
    filterStatus: 'Status',
    filterTipoUser: 'Tipo',
  },
};

const store = {
  buttons: {
    voltar: 'Voltar',
    clear: 'Limpar Pesquisa',
    buscar: 'Buscar',
    novo: 'Nova Loja',
    gerenciarEstoque: 'Gerenciar Estoque',
  },
  fields: {
    nomeFantasia: 'Nome Fantasia',
    cnpj: 'CNPJ',
    status: 'Status',
    razaoSocial: 'Razão Social',
  },
  table: {
    fantasia: 'Nome Fantasia',
    razaoSocial: 'Razão Social',
    status: 'Status',
    detalhes: 'Detalhes',
  },
  sessions: {
    buscarLoja: 'Buscar Loja',
  },
};
const fiscal = {
  buttons: {
    voltar: 'Voltar',
    clear: 'Limpar Pesquisa',
    buscar: 'Buscar',
    upload: 'Upload de XML',
    notaSaida: 'Nota de Saída',
    emitirNota: 'Emitir Nota',
  },
  fields: {
    chaveDevolucao: 'Chave de Acesso',
    propostaEmissao: 'Proposta de Emissão',
    tipoNota: 'Tipo de nota',
    presencaComprador: 'Comprador presente',
    consumidorFinal: 'Consumidor Final',
    provider: 'Fornecedor',
    products: 'Produtos da Nota',
    product: 'Produto',
    cfop: 'CFOP',
    icms_tax_situation: 'ICMS de situação tributária',
    icms_origin: 'ICMS de Origem',
    pis: 'Pis',
    cofins: 'Cofins',
    quantity: 'Quantidade',
  },
  sessions: {
    geral: 'Informações Gerais',
    notasFiscalDevolucao: 'NFes para devolução',
    produtosNaNota: 'Produtos na Nota',
    consultarNotas: 'Consultar suas Notas',
  },
  tabela: {
    dataEmissao: 'Emissão',
    naturezaOperacao: 'Nat Operação',
    column3: 'Coluna3',
  },
};

const novaLoja = {
  buttons: {
    voltar: 'Voltar',
    editar: 'Editar',
    ativar: 'Ativar',
    inativar: 'Inativar',
    salvar: 'Salvar',
    addProp: 'Adicionar Proprietário',
    cepBuscar: 'Buscar',
    cadastrar: 'Cadastrar',
  },
  fields: {
    nomeCompleto: 'Nome',
    nasc: 'Nascimento',
    rg: 'RG',
    cpf: 'CPF',
    telefone: 'Telefone',
    razaoSocial: 'Razão Social',
    situacao: 'Situação',
    nomeFantasia: 'Nome Fantasia',
    nome: 'Nome',
    natuJurid: 'Natureza Jurídica',
    CNPJ: 'CNPJ',
    nomeGerente: 'Gerente',
    email: 'E-mail',
    telefoneCom: 'Telefone',
    cep: 'CEP',
    rua: 'Rua',
    numero: 'Número',
    complem: 'Complemento',
    bairro: 'Bairro',
    cidade: 'Cidade',
    uf: 'Estado',
    codigoZoneam: 'Zoneamento',
  },
  sessions: {
    prop: 'Dados do Proprietário',
    propSubtitle: 'Proprietário',
    gerais: 'Dados Gerais da Loja',
    endereco: 'Endereço',
  },
  texts: {
    createdStore: 'Loja criada com sucesso',
    updatedStore: 'Loja atualizada com sucesso',
  },
};

const produto = {
  buttons: {
    cadastrarProduto: 'Cadastrar Produto',
    fornecedores: 'Fornecedores',
    buscar: 'Buscar',
    limpar: 'Limpar Pesquisa',
  },
  texts: {},
  sessions: {
    buscarProdutos: 'Buscar Produtos',
  },
  fields: {
    descricao: 'Descrição',
    gtin: 'Códito GTIN/EAN',
    marca: 'Marca',
    status: 'Status',
    categoria: 'Categoria',
  },
};

const novoProduto = {
  buttons: {
    voltar: 'Voltar',
    editar: 'Salvar',
    ativar: 'Ativar',
    inativar: 'Inativar',
    cadastrar: 'Cadastrar',
    adicionar: 'Adicionar',
    importar: 'Cadastrar Nota',
  },
  texts: {
    sucessoCriar: 'Produto cadastrado com sucesso',
    sucessoAtualizar: 'Produto alterado com sucesso',
    errorCriar: 'Erro ao cadastrar produto',
    errorAtualizar: 'Erro ao atualizar produto',
    active: 'Produto Ativado/Inativado com sucesso',
    errorActive: 'Ocorreu um erro ao Ativar/Inativar produto',
    formatoInvalid: 'Apenas arquivos de imagem',
    maxImagesExceded: 'Número máximo de imagens excedida',
    drag: 'Arrasta suas imagens aqui ou clique para adicionar',
    drop: 'Solte aqui',
    errorGroups: 'Ocorreu um erro ao carregar as categorias de produtos',
    errorProviders: 'Ocorreu um erro ao carregar os fornecedores',
    errorDetailsProduct: 'Ocorreu um erro ao carregar informações do produto',
  },
  sessions: {
    dadosProduto: 'Dados Gerais e Fiscais',
    exibicao: 'Detalhes para Exibição',
    fotos: 'Fotos',
  },
  fields: {
    descricao: 'Descrição',
    gtin: 'Códito GTIN/EAN',
    marca: 'Marca',
    status: 'Status',
    unid: 'Unidade',
    ncm: 'NCM',
    venda: 'Valor de venda',
    custo: 'Valor de custo',
    origem: 'Origem',
    fornec: 'Fornecedor',
    obs: 'Observações',
    categoria: 'Categoria',
    nomeExibicao: 'Nome para exibição',
    codeIdentification: 'Código de Identificação',
    descricaoProduto: 'Descrição do Produto',
    pis: 'PIS',
    cofins: 'COFINS',
    cfop: 'CFOP',
    icms_calculation_base_mode: 'ICMS de Modalidade de Base de Cálculo',
    icms_tax_situation: 'ICMS de situação tributária',
    icms_origin: 'ICMS de Origem',
    providers: 'Selecione os Fornecedores',
    aliquota: '% Alíquota',
    baseCalculo: '% Base de Cálculo',
  },
};

const empresa = {
  buttons: {
    voltar: 'Voltar',
    buscar: 'Buscar',
    salvar: 'Salvar',
  },
  sessions: {
    gerais: 'Informações Gerais',
    endereco: 'Endereço',
  },
  fields: {
    cnpj: 'CNPJ',
    ie: 'Inscrição Estadual',
    razaoSocial: 'Razão Social',
    nomeFantasia: 'Nome Fantasia',
    cep: 'CEP',
    rua: 'Rua/Avenida',
    numero: 'Número',
    complemento: 'Complemento',
    bairro: 'Bairro',
    cidade: 'Cidade',
    uf: 'UF',
  },
  texts: {
    sucesso: 'Dados da empresa salvos com sucesso',
    error: 'Ocorreu um erro ao salvar os dados da empresa',
    getEmpresaError: 'Ocorreu um erro ao buscar os dados da empresa',
  },
};

const tooltips = {
  empresa: 'Informações da empresa',
  logout: 'Deslogar',
};

const bestClients = {
  sessions: {
    filtrarCliente: 'Filtrar Cliente ',
  },
  fields: {
    nameFiltro: 'Nome do Cliente',
    name: 'Nome',
    CPF: 'CPF',
    minimumOrder: 'Quantidade mínima de pedidos',
    informacoesDoCliente: 'Informações do Cliente',
    totalDePedidos: 'Total de Pedidos',
    telefone: 'Telefone',
    totalEmCompras: 'Total em Compras',
    email: 'e-mail',
    DataDoUltimoPedido: 'Data do Último Pedido',
  },
  buttons: {
    limpar: 'Limpar Pesquisa',
    buscar: 'Buscar',
  },
  tabela: [
    'Nº Cliente',
    'Nome do Cliente',
    'Total de Pedidos',
    'Total em Compras',
    'Último Pedido',
  ],
  tabelaOrdersBestClients: ['Nº Pedido', 'Data', 'Pagamento', 'Valor'],
};

const promotions = {
  sessions: {
    filtrarPromocoes: 'Buscar Promoções',
  },
  fields: {
    descricao: 'Descrição',
    descricaoDaPromocao: 'Descrição da Promoção',
    status: 'Promoção Ativa',
    iniciarEm: 'Iniciar em',
    finalizarEm: 'Finalizar em',
    desconto: 'Desconto (em reais)',
    produtosIncluidos: 'Produtos incluidos na promoção',
  },
  buttons: {
    cadastrarDesconto: 'Cadastrar Desconto',
    cadastrarPromocoes: 'Nova Promoção',
    limpar: 'Limpar',
    buscar: 'Buscar',
  },
  tabela: ['Descrição', 'Ativa', 'Início', 'Fim'],
  tabelaItensPromocao: ['Nome', 'Valor', 'Desconto', 'Valor Promocional'],
};

const orders = {
  sessions: {
    buscarPedidos: 'Buscar Pedidos',
  },
  fields: {
    pedido: 'Nº Pedido',
    formaPagamento: 'Forma Pagamento',
    status: 'Status',
    valor: 'Valor',
  },
  buttons: {
    limpar: 'Limpar',
    buscar: 'Buscar',
  },
  tabela: ['Pedido', 'Status', 'Data', 'Pagamento', 'Valor'],
};

const order = {
  buttons: {
    cancelar: 'Cancelar Pedido',
    voltar: 'Voltar',
    finalizar: 'Finalizar Pedido',
  },
  sessions: {
    pedido: 'Pedido',
    comprador: 'Comprador',
    itens: 'Itens da compra',
  },
  texts: {
    dataCompra: 'Data',
    canal: 'Canal',
    status: 'Status',
    formaPag: 'Forma de Pagamento',
    nome: 'Nome',
    telefone: 'Telefone',
    email: 'E-mail',
  },
  tabela: [
    'Produto',
    'Quantidade',
    'Valor Unitário',
    'Desconto',
    'Valor Total',
  ],
};

const providers = {
  buttons: {
    cadastrar: 'Cadastrar Fornecedor',
    limpar: 'Limpar Pesquisa',
    buscar: 'Buscar',
    cancelar: 'Cancelar',
    voltar: 'Voltar',
    finalizar: 'Finalizar',
  },
  fields: {
    cpfCnpj: 'CPF/CNPJ',
    fantasyName: 'Nome Fantasia',
    socialReason: 'Razão Social',
    ie: 'Inscrição Estadual',
    rg: 'RG',
    email: 'E-mail',
    cep: 'CEP',
    rua: 'Rua/Avenida',
    numero: 'Número',
    complemento: 'Complemento',
    bairro: 'Bairro',
    cidade: 'Cidade',
    phone: 'Contato',
    uf: 'UF',
  },
  sessions: {
    buscar: 'Buscar Fornecedor',
    gerais: 'Informações Gerais',
  },
  texts: {
    dataCompra: 'Data',
    canal: 'Canal',
    status: 'Status',
    formaPag: 'Forma de Pagamento',
    nome: 'Nome',
    telefone: 'Telefone',
    email: 'E-mail',
  },
  tabela: ['Fornecedor', 'CPF/CNPJ'],
};

const dashboard = {
  cards: {
    estoqueBaixoWessex: 'Estoque Baixo',
    cancelamento: 'Solicitações de cancelamento',
    mensal: 'Acompanhamento Mensal',
    diario: 'Acompanhamento Diário',
  },
};

export default {
  login,
  recuperarSenha,
  usuarios,
  pages,
  drawer,
  store,
  novaLoja,
  produto,
  novoProduto,
  empresa,
  tooltips,
  orders,
  order,
  bestClients,
  promotions,
  providers,
  fiscal,
  dashboard,
};
