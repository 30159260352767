import { Grid, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Texts } from 'constants/strings';
import React from 'react';
import Masks from 'utils/Masks';
import PropTypes from 'prop-types';
import { TextInput } from 'components/text-input';

function NfeDevolucao({ value, index, onChange, onRemove }) {
  return (
    <>
      <Grid item xs={10} sm={11}>
        <TextInput
          label={Texts.fiscal.fields.chaveDevolucao}
          id={String(index)}
          mask={Masks.chaveNFe}
          onChange={(e) => onChange(index, e.target.value)}
          value={value}
        />
      </Grid>
      {index !== 0 && (
        <Grid item xs={2} sm={1}>
          <IconButton onClick={() => onRemove(index)}>
            <Close />
          </IconButton>
        </Grid>
      )}
    </>
  );
}

NfeDevolucao.propTypes = {
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default NfeDevolucao;
