export enum TypeUnitConstant {
  Ampola = 'AMPOLA',
  Bandeja = 'BANDEJ',
  Barra = 'BARRA',
  Capsula = 'CÁPSULAS',
  Cj = 'CJ',
  Cm = 'CM',
  Caixa = 'CX',
  Embalagem = 'EMBAL',
  Fardo = 'FARDO',
  Frasco = 'FRASCO',
  Garrafa = 'GF',
  Kg = 'KG',
  Kit = 'KIT',
  Lata = 'LATA',
  Litro = 'LITRO',
  Metro = 'M',
  Pacote = 'PACOTE',
  Pc = 'PC',
  Saco = 'SACO',
  Sacola = 'SACOLA',
  Unidade = 'UNID',
  Vidro = 'VIDRO',
}
