export default {
  input: {
    heightDiv: 60,
    paddingDiv: 10,
    height: 40,
    borderRadius: 8,
    iconSize: 30,
    fontSize: 20,
  },
  button: {
    height: 56,
  },
  drawer: {
    width: 240,
  },
  userButton: {
    containerNameSize: 50,
    radius: 25,
    buttonWidth: 240 - 15 - 5 - 50,
    buttonHeight: '80%',
    margin: 15,
  },
  images: {
    maxNumberOfProducts: 4,
  },
};
