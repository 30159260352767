import { call, put } from 'redux-saga/effects';
import { Creators as UserActions } from 'store/ducks/user';
import { UserStoreActions } from 'store/ducks/user-store';
import { UserApi } from 'services/api/UserApi';
import RouterNames from 'constants/router-names';
import { AddMasks } from 'utils/Masks';
import { LayoutActions } from 'store/ducks/layout';

export function* session({ payload }) {
  try {
    yield put(LayoutActions.showLoading());
    const { history } = payload;
    const { data } = yield call(UserApi.getSessionUserInfo);

    const user = data.data;
    if (user.shopkeeper && user.storeUser) {
      // consertar array de lojas que vem dentro de um objeto [{stores: {}}]
      const stores = user.storeUser.map((store) => store.stores);
      user.storeUser = stores;
      yield put(UserStoreActions.setStores(stores));
    }
    user.person.birthdate = AddMasks.dateISO(user.person.birthdate);
    yield put(UserActions.userSuccess(user));
    history.push(RouterNames.MAIN);
    yield put(LayoutActions.dismissLoading());
  } catch (error) {
    yield put(UserActions.userError());
    yield put(LayoutActions.dismissLoading());
  }
}
