import produce from 'immer';

export const Types = {
  CLICK_RESET_PASSWORD: 'reset-password@CLICK_RESET_PASSWORD',
  SEND_EMAIL: 'reset-password@SEND_EMAIL',
  SEND_EMAIL_ERROR: 'reset-password@SEND_EMAIL_ERROR',
  SEND_EMAIL_SUCCESS: 'reset-password@SEND_EMAIL_SUCCESS',
  CHANGE_EMAIL: 'reset-password@CHANGE_EMAIL',
  CLOSE_MODAL: 'reset-password@CLOSE_MODAL',
  CHANGE_VALUES: 'reset-password@CHANGE_VALUES',
  RESET_PASSWORD: 'reset-password@RESET_PASSWORD',
  CHECK_RESULT: 'reset-password@CHECK_RESULT',
  RESET_ERROR: 'reset-password@RESET_ERROR',
  RESET_SUCCESS: 'reset-password@RESET_SUCCESS',
};

const initialState = {
  email: '',
  showModalInsertEmail: false,
  sendingEmail: false,
  errorSendingEmail: '',
  showModalSendedEmail: false,
  password: '',
  repeatPassword: '',
  errorMessage: '',
  passwordMessage: '',
  passwordRepeatMessage: '',
  checkedSuccess: false,
  resetLoading: false,
  resetError: false,
  resetSuccess: false,
};

const resetPassword = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.CLICK_RESET_PASSWORD:
      state.showModalInsertEmail = true;
      state.errorSendingEmail = false;
      return state;
    case Types.SEND_EMAIL:
      state.sendingEmail = true;
      return state;
    case Types.SEND_EMAIL_ERROR:
      state.sendingEmail = false;
      state.errorSendingEmail = payload;
      return state;
    case Types.SEND_EMAIL_SUCCESS:
      state.sendingEmail = false;
      state.showModalInsertEmail = false;
      state.errorSendingEmail = '';
      state.showModalSendedEmail = true;
      return state;
    case Types.CHANGE_EMAIL:
      state.email = payload;
      return state;
    case Types.CLOSE_MODAL:
      state.showModalInsertEmail = false;
      state.showModalSendedEmail = false;
      return state;
    case Types.CHANGE_VALUES:
      state.password = payload.password;
      state.repeatPassword = payload.repeat;
      return state;
    case Types.CHECK_RESULT:
      state = { ...state, ...payload };
      return state;
    case Types.RESET_PASSWORD:
      state.resetLoading = true;
      state.resetError = false;
      state.resetSuccess = false;
      return state;
    case Types.RESET_ERROR:
      state.resetLoading = false;
      state.resetError = true;
      return state;
    case Types.RESET_SUCCESS:
      state.resetLoading = false;
      state.resetSuccess = true;
      return state;
    default:
      return state;
  }
});

export default resetPassword;

export const Creators = {
  resetPasswordClick: () => ({
    type: Types.CLICK_RESET_PASSWORD,
  }),
  sendEmail: () => ({
    type: Types.SEND_EMAIL,
  }),
  changeEmail: (email) => ({
    type: Types.CHANGE_EMAIL,
    payload: email,
  }),
  closeModais: () => ({
    type: Types.CLOSE_MODAL,
  }),
  updatePassword: (password, repeat) => ({
    type: Types.CHANGE_VALUES,
    payload: { password, repeat },
  }),
  resetPassword: (token) => ({
    type: Types.RESET_PASSWORD,
    payload: token,
  }),
  checkResult: (payload) => ({
    type: Types.CHECK_RESULT,
    payload,
  }),
  successSendEmail: () => ({
    type: Types.SEND_EMAIL_SUCCESS,
  }),
  errorSendEmail: (payload) => ({
    type: Types.SEND_EMAIL_ERROR,
    payload,
  }),
  errorResetPassword: () => ({
    type: Types.RESET_ERROR,
  }),
  successResetPassword: () => ({
    type: Types.RESET_SUCCESS,
  }),
};
