import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Creators as PasswordResetActions } from 'store/ducks/reset-password';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    margin: '1vh 0 0',
    padding: '0',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    lineHeight: '1',
    textTransform: 'none',
    fontSize: '14px',
  },
}));

function ForgotPassword() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const resetPassword = () => {
    dispatch(PasswordResetActions.resetPasswordClick());
  };
  return (
    <Button onClick={resetPassword} className={classes.root}>
      Esqueci a senha
    </Button>
  );
}

export default ForgotPassword;
