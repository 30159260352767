import { Grid } from '@material-ui/core';
import React from 'react';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { TextInput } from 'components/text-input';
import { useDispatch, useSelector } from 'react-redux';
import Masks from 'utils/Masks';
import { ProdutoActions } from 'store/ducks/produto';

function DadosGerais() {
  const {
    cpf_cnpj,
    cpf_cnpjErr,
    rg,
    rgErr,
    ie,
    ieErr,
    social_reason,
    social_reasonErr,
    fantasy_name,
    fantasy_nameErr,
    email,
    emailErr,
    phone,
    phoneErr,
  } = useSelector((state) => state.produto.updatedProvider);
  const dispatch = useDispatch();

  const changeText = (event) => {
    dispatch(
      ProdutoActions.changeTextProvider(event.target.id, event.target.value)
    );
  };

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.providers.sessions.gerais}</Title>
      </Grid>
      {
        // CPF CNPJ - RG
      }
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          label={Texts.providers.fields.cpfCnpj}
          id="cpf_cnpj"
          mask={cpf_cnpj.length <= 14 ? Masks.cpf_extra : Masks.cnpj}
          onChange={changeText}
          value={cpf_cnpj}
          error={cpf_cnpjErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.providers.fields.rg}
          id="rg"
          onChange={changeText}
          value={rg}
          error={rgErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          label={Texts.providers.fields.ie}
          id="ie"
          onChange={changeText}
          value={ie}
          error={ieErr}
        />
      </Grid>
      {
        // RAZÃO SOCIAL - NOME FANTASIA
      }
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          label={Texts.providers.fields.socialReason}
          id="social_reason"
          onChange={changeText}
          value={social_reason}
          error={social_reasonErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.providers.fields.fantasyName}
          id="fantasy_name"
          onChange={changeText}
          value={fantasy_name}
          error={fantasy_nameErr}
        />
      </Grid>
      {
        // EMAIL - PHONE
      }
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.providers.fields.email}
          id="email"
          onChange={changeText}
          value={email}
          error={emailErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.providers.fields.phone}
          id="phone"
          mask={Masks.celular}
          onChange={changeText}
          value={phone}
          error={phoneErr}
        />
      </Grid>
    </>
  );
}

export default DadosGerais;
