import { OrderDetailsActions } from 'store/ducks/order-details';
import { call, put, select } from 'redux-saga/effects';
import { OrderApi } from 'services/api/OrderApi';
import { AddMasks } from 'utils/Masks';
import { money } from 'utils/money';

export function* getDetails({ payload }) {
  const orderId = payload;
  try {
    const { store_id: storeId } = yield select(
      (state) => state.userStores.actualStore
    );
    const { data } = yield call(OrderApi.getDetails, storeId, orderId);
    let order = data.data;
    order = {
      ...order,
      created_at: AddMasks.dateISO(order.created_at),
      updated_at: AddMasks.dateISO(order.updated_at),
      totals: {
        ...order.totals,
        total_sale_value: money.formatCurrency(order.totals.total_sale_value),
        total_discount: money.formatCurrency(order.totals.total_discount),
        total: money.formatCurrency(order.totals.total),
      },
    };
    yield put(OrderDetailsActions.getOrderSuccess(order));
  } catch (error) {
    yield put(OrderDetailsActions.getOrderErr());
  }
}

export function* getItems({ payload }) {
  const transactionId = payload;
  try {
    const { store_id: storeId } = yield select(
      (state) => state.userStores.actualStore
    );
    const { data: items } = yield call(
      OrderApi.getOrdertems,
      storeId,
      transactionId
    );
    yield put(OrderDetailsActions.getItemsSuccess(items));
  } catch (error) {
    yield put(OrderDetailsActions.getItemsErr());
  }
}
