import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Title } from 'components/title/Title';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/button';
import { Texts } from 'constants/strings';
import UserTypes from 'utils/UserTypes';
import { NovoUsuarioActions } from 'store/ducks/novo-usuario';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

function TipoUsuario() {
  const { profile_id, updateMode } = useSelector((state) => state.novoUsuario);
  const dispatch = useDispatch();
  const classes = useStyles();
  const onClickUserTipo = (tipo) => {
    dispatch(NovoUsuarioActions.updateFields('profile_id', tipo));
  };
  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.usuarios.sessions.tipoUsuario}</Title>
      </Grid>
      <Grid item>
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => onClickUserTipo(UserTypes.ADMIN)}
            title={Texts.usuarios.buttons.admin}
            style={classes.button}
            colored={profile_id === UserTypes.ADMIN}
            disable={updateMode}
          />
          <Button
            onClick={() => onClickUserTipo(UserTypes.PDV)}
            title={Texts.usuarios.buttons.pdv}
            style={classes.button}
            colored={profile_id === UserTypes.PDV}
            disable={updateMode}
          />
        </div>
      </Grid>
    </>
  );
}

export default TipoUsuario;
