import Uris from 'constants/endpoints';
import api from 'services/config/api';
import { BestClient, BestClientOrder } from 'utils/types/client';

export const BestClientsApi = {
  getAllBestClients: (query: string) =>
    api.get<Array<BestClient>>(`${Uris.reportBestClients}${query}`),
  getAllBestClientsOrders: (clientId: string | number) =>
    api.get<Array<BestClientOrder>>(
      Uris.reportBestClientsOrders.replace('{clientId}', clientId.toString())
    ),
};
