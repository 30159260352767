import { Grid } from '@material-ui/core';
import If from 'components/if/If';
import Main from 'components/main-container';
import ReportButton from 'components/report-button';
import RouterNames from 'constants/router-names';
import { useShopkeeper } from 'context/store';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Reports = () => {
  const history = useHistory();
  const isShopkeeper = useShopkeeper();
  const callPedidos = () => history.push(RouterNames.REPORTS_ORDERS);
  const callBestClients = () => history.push(RouterNames.REPORTS_BEST_CLIENTS);
  const callFaturamento = () => history.push(RouterNames.REPORTS_BILLING);
  const callFaturamentoLojista = () =>
    history.push(RouterNames.REPORTS_BILLING_SHOPKEEPER);
  const callTopSellers = () => history.push(RouterNames.REPORTS_TOP_SELLERS);
  const movesStock = () => history.push(RouterNames.REPORTS_MOVES_STOCK);

  return (
    <Main>
      <Grid container spacing={2} justify="space-evenly">
        <If condition={!isShopkeeper}>
          <Grid item>
            <ReportButton
              label="Acompanhamento de Pedidos"
              onPress={callPedidos}
              icon="orders"
            />
          </Grid>
          <Grid item>
            <ReportButton
              label="Vendas"
              onPress={callFaturamento}
              icon="billing"
            />
          </Grid>
          <Grid item>
            <ReportButton
              label="Melhores Clientes"
              onPress={callBestClients}
              icon="clients"
            />
          </Grid>
          <Grid item>
            <ReportButton
              label="Produtos mais Vendidos"
              onPress={callTopSellers}
              icon="products"
            />
          </Grid>
          <Grid item>
            <ReportButton
              label="Movimentações"
              onPress={movesStock}
              icon="stock"
            />
          </Grid>
        </If>
        <If condition={isShopkeeper}>
          <Grid item>
            <ReportButton
              label="Vendas"
              onPress={callFaturamentoLojista}
              icon="orders"
            />
          </Grid>
        </If>
      </Grid>
    </Main>
  );
};

export default Reports;
