import Selecionar from 'components/select';
import moment, { Moment } from 'moment';
import React from 'react';
import { Helpers } from 'utils/helpers';

export enum Intervals {
  Today = 'today',
  CurrentMonth = 'current_month',
  Month = 'month',
  LastMonth = 'last_month',
  CurrentWeek = 'current_week',
  Week = 'week',
  Fortnight = 'fortnight',
  Custom = 'custom',
}

export interface IntervalValue {
  id: string;
  value: Intervals;
}

export interface IntervalSelectorEvent {
  id: string;
  interval: Intervals;
  start?: Moment;
  end?: Moment;
}

const FILTER_OPTIONS = [
  {
    id: Intervals.Today,
    name: 'Hoje',
    interval: Helpers.today(),
  },
  {
    id: Intervals.CurrentMonth,
    name: 'Nesse mês',
    interval: Helpers.currentMonth(),
  },
  {
    id: Intervals.Month,
    name: 'Intervalo Mês',
    interval: Helpers.thisMonth(),
  },
  {
    id: Intervals.LastMonth,
    name: 'Último Mês',
    interval: Helpers.lastMonth(),
  },
  {
    id: Intervals.CurrentWeek,
    name: 'Essa semana',
    interval: Helpers.currentWeek(),
  },
  {
    id: Intervals.Week,
    name: 'Semana',
    interval: Helpers.lastWeek(),
  },
  {
    id: Intervals.Fortnight,
    name: '15 dias',
    interval: Helpers.fortnight(),
  },
  {
    id: Intervals.Custom,
    name: 'Customizado',
    interval: Helpers.lastWeek(),
  },
];

type Props = {
  label: string;
  onChange: (selected: IntervalSelectorEvent) => void;
  id: string;
  value: Intervals;
};

const IntervalSelector = ({ label, onChange, id, value }: Props) => {
  const onChangeSelect = ({ value: interval }: IntervalValue) => {
    const item = FILTER_OPTIONS.find((i) => i.id === interval);
    onChange({
      id,
      interval,
      start: item?.interval?.start || moment(),
      end: item?.interval?.end || moment(),
    });
  };

  return (
    <Selecionar
      label={label}
      onChange={onChangeSelect}
      id={id}
      options={FILTER_OPTIONS}
      value={value}
      allowEmpty={false}
    />
  );
};

export { IntervalSelector };
