import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import SaleIcon from 'assets/img/icons/sales.png';
import StockIcon from 'assets/img/icons/stock.png';
import BillingIcon from 'assets/img/icons/billing.png';
import BestProductsIcon from 'assets/img/icons/best_products.png';
import BestClientsIcon from 'assets/img/icons/best_clients.png';

const icons = {
  orders: SaleIcon,
  clients: BestClientsIcon,
  billing: BillingIcon,
  stock: StockIcon,
  products: BestProductsIcon,
};

type Icon = 'orders' | 'clients' | 'billing' | 'stock' | 'products';

type Props = {
  label: string;
  icon: Icon;
  onPress: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.primary.main,
      width: '250px',
      height: '250px',
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    label: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      fontSize: `${theme.typography.subtitle1}`,
    },
    icon: {
      width: '40%',
      marginBottom: theme.spacing(2),
    },
  })
);

const ReportButton = ({ label, icon, onPress }: Props) => {
  const classes = useStyles();
  return (
    <Button className={classes.button} onClick={onPress}>
      <div className={classes.content}>
        <img className={classes.icon} src={icons[icon]} alt={icon} />
        <Typography className={classes.label}>{label}</Typography>
      </div>
    </Button>
  );
};

export { ReportButton };
