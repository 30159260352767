import { call, put } from 'redux-saga/effects';
import { Auth } from 'services/api/AuthUser';
import { Creators as AuthActions } from 'store/ducks/auth';
import { Creators as UserActions } from 'store/ducks/user';
import { Errors } from 'constants/strings';
import { NotificationActions } from 'store/ducks/notification';

export function* requestLogin({ payload }) {
  try {
    const { email, password, history } = payload;
    const { data } = yield call(Auth.login, email, password);
    yield put(AuthActions.loginSuccess(data.data));
    yield put(UserActions.requestSession(history));
  } catch (error) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 401) {
        yield put(AuthActions.loginError({ email: data.data }));
      } else if (status === 422) {
        let errors = {};
        data.forEach((err) => {
          errors = { ...errors, [err.field]: err.message };
        });
        yield put(AuthActions.loginError(errors));
      }
    } else {
      yield put(AuthActions.loginError({ email: Errors.login }));
      yield put(NotificationActions.error(Errors.login));
    }
  }
}
