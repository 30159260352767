import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import theme from 'styles/theme';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: (props) => props.color,
    textAlign: (props) => (props.center ? 'center' : 'left'),
    textOverflow: 'ellipsis',
  },
}));

function Title({ children, color, variant, style, center }) {
  const classes = useStyles({ color, center });
  return (
    <Typography noWrap className={clsx(classes.title, style)} variant={variant}>
      {children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  variant: PropTypes.string,
  center: PropTypes.bool,
  style: PropTypes.string,
};

Title.defaultProps = {
  children: null,
  color: theme.palette.primary.main,
  variant: 'h5',
  style: '',
  center: false,
};

export { Title };
