import SearchSelector from 'components/search-selector';
import React, { useMemo } from 'react';
import { TypeUnit, TypeUnitSelectorResult } from 'utils/types/product';
import {units} from './units';

type Props = {
  value?: string;
  onChange: (value: TypeUnitSelectorResult) => void;
  id: string;
  disabled?: boolean;
  error?: string;
}

function TypeUnitSelector({ value, onChange, id, disabled, error }: Props) {

  const onSelect = ({value: type_unit}: {value: TypeUnit}) => {
    onChange({ id, value: type_unit?.unit });
  };

  const selected = useMemo(() => {
    return units.find(unit => unit.unit === value);
  }, [value]);

  return (
    <SearchSelector
      id={id}
      label="Unidade"
      getLabel={(option: TypeUnit) => option?.description}
      onChange={onSelect}
      options={units}
      value={selected}
      optionCheckField="unit"
      selectedCheckField="unit"
      disabled={disabled}
      error={error}
    />
  );
}

export default TypeUnitSelector;
