import { Grid } from '@material-ui/core';
import { ChevronLeft, PersonAdd, PersonAddDisabled } from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import { Texts } from 'constants/strings';
import { NovoUsuarioActions } from 'store/ducks/novo-usuario';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UserTypes from 'utils/UserTypes';
import If from 'components/if/If';
import AcessoUsuario from './components/AcessoUsuario';
import DadosGerais from './components/DadosGerais';
import Lojas from './components/Lojas';
import TipoUsuario from './components/TipoUsuario';

function NovoUsuario() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    profile_id,
    saveLoading,
    updateMode,
    loadingInactivated,
    updateUser,
  } = useSelector((state) => state.novoUsuario);

  const reset = useCallback(() => {
    dispatch(NovoUsuarioActions.reset());
  }, [dispatch]);

  useEffect(() => {
    reset();
    if (history.location.state && history.location.state.user) {
      dispatch(NovoUsuarioActions.setEditUser(history.location.state.user));
    }
  }, [reset, dispatch, history]);

  const save = () => {
    dispatch(NovoUsuarioActions.saveUser());
  };

  const back = () => {
    history.goBack();
  };

  const update = () => {
    dispatch(NovoUsuarioActions.updateUser());
  };

  const inactivated = () => {
    dispatch(NovoUsuarioActions.changeStatusRequest());
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={ChevronLeft}
            onClick={back}
            title={Texts.usuarios.buttons.voltar}
          />
        </Grid>
        {updateMode && (
          <>
            <Grid item>
              <Button
                icon={PersonAddDisabled}
                onClick={inactivated}
                loading={loadingInactivated}
                title={
                  updateUser.active
                    ? Texts.usuarios.buttons.inativar
                    : Texts.usuarios.buttons.ativar
                }
              />
            </Grid>
            {updateUser.active && (
              <Grid item>
                <Button
                  icon={PersonAdd}
                  onClick={update}
                  colored
                  loading={saveLoading}
                  title={Texts.usuarios.buttons.salvar}
                />
              </Grid>
            )}
          </>
        )}
        <DadosGerais updateMode={updateMode} />
        <AcessoUsuario />
        <TipoUsuario />
        <If condition={profile_id === UserTypes.PDV}>
          <Lojas />
        </If>
        {profile_id && !updateMode && (
          <Grid item xs={12} sm={12}>
            <Button
              icon={PersonAdd}
              onClick={save}
              title={Texts.usuarios.buttons.cadastrar}
              colored
              loading={saveLoading}
            />
          </Grid>
        )}
      </Grid>
    </Main>
  );
}

export { NovoUsuario };
