import produce from 'immer';

export const Types = {
  REQUEST_AUTH: 'auth@REQUEST_AUTH',
  LOGIN_SUCCESS: 'auth@LOGIN_SUCCESS',
  LOGIN_ERROR: 'auth@LOGIN_ERROR',
};

const initialState = {
  token: undefined,
  loadingRequest: false,
  errorRequest: false,
  successRequest: false,
  emailMessage: '',
  passwordMessage: '',
};

const layout = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.REQUEST_AUTH:
      state.token = undefined;
      state.loadingRequest = true;
      state.errorRequest = false;
      state.successRequest = false;
      state.emailMessage = '';
      state.passwordMessage = '';
      return state;
    case Types.LOGIN_ERROR:
      state.token = undefined;
      state.loadingRequest = false;
      state.errorRequest = true;
      state.emailMessage = payload.email;
      state.passwordMessage = payload.password;
      return state;
    case Types.LOGIN_SUCCESS:
      state.token = payload.token;
      state.loadingRequest = false;
      state.successRequest = true;
      state.errorRequest = false;
      state.emailMessage = '';
      state.passwordMessage = '';
      return state;
    default:
      return state;
  }
});

export default layout;

export const Creators = {
  login: (payload) => ({
    type: Types.REQUEST_AUTH,
    payload,
  }),
  loginError: (payload) => ({
    type: Types.LOGIN_ERROR,
    payload,
  }),
  loginSuccess: (token) => ({
    type: Types.LOGIN_SUCCESS,
    payload: { token },
  }),
};
