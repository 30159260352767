import produce from 'immer';

export const Types = {
  REQUEST: 'empresa@REQUEST',
  REQUEST_EMPRESA_SUCCESS: 'empresa@REQUEST_EMPRESA_SUCCESS',
  REQUEST_EMPRESA_ERROR: 'empresa@REQUEST_EMPRESA_ERROR',
  REQUEST_SAVE: 'empresa@REQUEST_SAVE',
  REQUEST_SUCCESS: 'empresa@REQUEST_SUCCESS',
  REQUEST_ERROR: 'empresa@REQUEST_ERROR',
  FIELDS: 'empresa@FIELDS',
  GET_CEP: 'empresa@GET_CEP',
  GET_CEP_SUCCESS: 'empresa@GET_CEP_SUCCESS',
  GET_CEP_ERROR: 'empresa@GET_CEP_ERROR',
};

const initialState = {
  loading: false,
  loadingEmpresa: false,
  disableFields: true,
  cepLoading: false,
  cpf_cnpj: '',
  cpf_cnpjErr: '',
  ie: '',
  ieErr: '',
  social_reason: '',
  social_reasonErr: '',
  fantasy_name: '',
  fantasy_nameErr: '',
  postcode: '',
  postcodeErr: '',
  street: '',
  streetErr: '',
  neighborhood: '',
  neighborhoodErr: '',
  city: '',
  cityErr: '',
  uf: '',
  ufErr: '',
  number: '',
  numberErr: '',
  codeCity: '',
};

const empresa = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.FIELDS:
      if (state[`${payload.id}Err`]) {
        state[`${payload.id}Err`] = '';
      }
      state[payload.id] = payload.value;
      return state;
    case Types.REQUEST_SAVE:
      state.loading = true;
      return state;
    case Types.REQUEST_ERROR:
      state = { ...state, ...payload };
      state.loading = false;
      return state;
    case Types.REQUEST_SUCCESS:
      state.loading = false;
      return state;
    case Types.GET_CEP:
      state.cepLoading = true;
      return state;
    case Types.GET_CEP_SUCCESS:
      state = { ...state, ...payload };
      state.cepLoading = false;
      return state;
    case Types.GET_CEP_ERROR:
      state.cepLoading = false;
      return state;
    case Types.REQUEST:
      state.loadingEmpresa = true;
      return state;
    case Types.REQUEST_EMPRESA_ERROR:
      state.loadingEmpresa = false;
      return state;
    case Types.REQUEST_EMPRESA_SUCCESS:
      state = { ...state, ...payload };
      state.loadingEmpresa = false;
      return state;
    default:
      return state;
  }
});

export default empresa;

export const EmpresaActions = {
  fields: (id, value) => ({
    type: Types.FIELDS,
    payload: { id, value },
  }),
  getCep: () => ({
    type: Types.GET_CEP,
  }),
  getCepSuccess: (addrs) => ({
    type: Types.GET_CEP_SUCCESS,
    payload: addrs,
  }),
  getCepErr: () => ({
    type: Types.GET_CEP_ERROR,
  }),
  getEmpresa: () => ({
    type: Types.REQUEST,
  }),
  getEmpresaErr: () => ({
    type: Types.REQUEST_EMPRESA_ERROR,
  }),
  getEmpresaSuccess: (payload) => ({
    type: Types.REQUEST_EMPRESA_SUCCESS,
    payload,
  }),
  requestEmpresaSave: () => ({
    type: Types.REQUEST_SAVE,
  }),
  requestEmpresaErr: (errors = {}) => ({
    type: Types.REQUEST_ERROR,
    payload: errors,
  }),
  requestEmpresaSuccess: () => ({
    type: Types.REQUEST_SUCCESS,
  }),
};
