import { Moment } from 'moment';
import { DateSelector } from './DateSelector';

export interface DateSelectorChange {
  id: string;
  value: Moment;
  formatted: string;
}

export default DateSelector;
