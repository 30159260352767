import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React, { FC } from 'react';
import { STOCK_ACTIONS } from 'utils/enum/stocks.enum';

type Props = {
  type: STOCK_ACTIONS;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    up: {
      fontSize: 30,
      color: theme.palette.success.main,
    },
    down: {
      fontSize: 30,
      color: theme.palette.error.main,
    },
  })
);

const UpDownArrow: FC<Props> = ({ type }: Props) => {
  const classes = useStyles();

  const icons = {
    [STOCK_ACTIONS.Up]: () => <ArrowDropUp className={classes.up} />,
    [STOCK_ACTIONS.Down]: () => <ArrowDropDown className={classes.down} />,
  };
  const Icon = icons[type];
  return <Icon />;
};

export default UpDownArrow;
