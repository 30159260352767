import React, { FC, useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Main from 'components/main-container';
import { TextInput } from 'components/text-input';
import { Texts } from 'constants/strings';
import { Product } from 'utils/types/product';
import ProductSelector from 'components/product-selector';
import DateSelector, { DateSelectorChange } from 'components/date-selector';
import { Title } from 'components/title/Title';
import Table from 'components/pagination-table';
import { AddCircle } from '@material-ui/icons';
import { Button } from 'components/button';
import { AddMasks, RemoveMasks } from 'utils/Masks';
import moment, { Moment } from 'moment';
import { usePromotions } from 'context/promotions';
import { useHistory, useParams } from 'react-router-dom';
import If from 'components/if/If';
import ButtonBack from 'components/button-back';
import { NotificationActions } from 'store/ducks/notification';
import { useDispatch } from 'react-redux';

type Props = {};

interface Fields {
  description: string;
  discount: string;
  start?: Moment;
  end?: Moment;
  active: boolean;
  products: Array<Product>;
}

interface IdValue {
  id: string;
  value: Array<Product>;
}

const initialFields: Fields = {
  active: true,
  description: '',
  discount: '',
  start: moment(),
  end: moment(),
  products: [],
};

const NewPromotions: FC<Props> = () => {
  const { promotionId } = useParams<{ promotionId: string }>();
  const [fields, setFields] = useState<Fields>(initialFields);
  const [original, setOriginal] = useState<Fields | null>(null);
  const { getPromotionDetails, createPromotion } = usePromotions();
  const dispatch = useDispatch();
  const history = useHistory();
  const changeText = (event: any) => {
    setFields({ ...fields, [event.target.id]: event.target.value });
  };

  const handleDate = ({ id, value }: DateSelectorChange) => {
    setFields((state) => ({ ...state, [id]: moment(value) }));
  };

  const valuesTable = (product: Product) => {
    const discount: number = fields.discount
      ? RemoveMasks.currency(fields.discount)
      : 0;
    const priceWithDiscount = AddMasks.money(
      parseFloat(product.sale_value) - discount
    );

    return [
      product.description,
      AddMasks.money(product.sale_value),
      AddMasks.money(discount),
      priceWithDiscount,
    ];
  };

  const onSelectProduct = ({ value }: IdValue) => {
    setFields((state) => ({ ...state, products: value }));
  };

  const getPromotion = useCallback(async () => {
    const promotion = await getPromotionDetails(promotionId);
    if (promotion) {
      const promotionFields = {
        active: promotion.active,
        description: promotion.description,
        discount: AddMasks.money(promotion.discount),
        start: moment(promotion.starts_in, 'YYYY-MM-DD HH:mm:ss'),
        end: moment(promotion.ends_in, 'YYYY-MM-DD HH:mm:ss'),
        products: promotion.promotionProduct.map((prod) => prod.product),
      };
      setFields(promotionFields);
      setOriginal(promotionFields);
    }
  }, [promotionId, getPromotionDetails]);

  const create = useCallback(async () => {
    const success = await createPromotion(fields);
    if (success) {
      dispatch(NotificationActions.success('Promoção criada com sucesso!!!'));
      history.goBack();
    }
  }, [fields, dispatch, history, createPromotion]);

  const update = useCallback(async () => {
    const success = await createPromotion(fields, promotionId);
    if (success) {
      dispatch(NotificationActions.success('Promoção atualizada!!!'));
    }
  }, [fields, dispatch, createPromotion, promotionId]);

  const inactivate = async () => {
    if (original) {
      const success = await createPromotion(
        {
          ...original,
          active: !original.active,
        },
        promotionId
      );
      if (success) {
        dispatch(
          NotificationActions.success('Promoção ativada/inativada com sucesso')
        );
        getPromotion();
      }
    }
  };

  useEffect(() => {
    if (promotionId) {
      getPromotion();
    }
  }, [promotionId, getPromotion]);

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <ButtonBack />
        </Grid>
        <If condition={!!promotionId}>
          <Grid item>
            <Button
              icon={AddCircle}
              onClick={inactivate}
              title={fields.active ? 'Inativar' : 'Ativar'}
              colored
            />
          </Grid>
        </If>
        <If condition={!!promotionId}>
          <Grid item>
            <Button
              icon={AddCircle}
              onClick={update}
              title="Atualizar Promoção"
              colored
            />
          </Grid>
        </If>
        <Grid item xs={12} sm={12}>
          <TextInput
            label={Texts.promotions.fields.descricaoDaPromocao}
            id="description"
            onChange={changeText}
            value={fields.description}
            disabled={!fields.active}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            money
            label={Texts.promotions.fields.desconto}
            id="discount"
            value={fields.discount}
            onChange={changeText}
            disabled={!fields.active}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateSelector
            id="start"
            type="datetime"
            label={Texts.promotions.fields.iniciarEm}
            value={fields.start}
            onChange={handleDate}
            disabled={!fields.active}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateSelector
            id="end"
            type="datetime"
            label={Texts.promotions.fields.finalizarEm}
            value={fields.end}
            onChange={handleDate}
            disabled={!fields.active}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ProductSelector
            id="product"
            onChange={onSelectProduct}
            value={fields.products}
            multiple
            disabled={!fields.active}
          />
        </Grid>
        <Grid item xs={12}>
          <Title>{Texts.promotions.fields.produtosIncluidos}</Title>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={Texts.promotions.tabelaItensPromocao}
            values={fields.products}
            fields={valuesTable}
          />
        </Grid>
        <If condition={!promotionId}>
          <Grid item>
            <Button
              icon={AddCircle}
              onClick={create}
              title={Texts.promotions.buttons.cadastrarDesconto}
              colored
            />
          </Grid>
        </If>
      </Grid>
    </Main>
  );
};

export { NewPromotions };
