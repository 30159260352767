import { Grid } from '@material-ui/core';
import {
  ChevronLeft,
  PersonAddDisabled,
  ShoppingBasket,
} from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import { Texts } from 'constants/strings';
import { NovoProdutoActions } from 'store/ducks/novo-produto';
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Dados from './components/Dados';
import Exibicao from './components/Exibicao';
import Fotos from './components/Fotos';

function NovoProduto() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, updateMode, loadingActivate, active } = useSelector(
    (state) => state.novoProduto
  );

  const reset = useCallback(() => {
    dispatch(NovoProdutoActions.reset());
  }, [dispatch]);

  useEffect(() => {
    reset();
    if (history.location.state && history.location.state.produto) {
      dispatch(
        NovoProdutoActions.getDetails(history.location.state.produto.product_id)
      );
    } else if (history.location.state && history.location.state.fillProduct) {
      const { fillProduct } = history.location.state;
      dispatch(
        NovoProdutoActions.fillNewProductFromNfe(
          fillProduct.cost_value,
          fillProduct.type_unit,
          fillProduct.description,
          fillProduct.ncm
        )
      );
    }
    dispatch(NovoProdutoActions.groupRequest());
  }, [dispatch, history, reset]);

  const save = () => {
    dispatch(NovoProdutoActions.create());
  };

  const back = () => {
    history.goBack();
  };

  const update = () => {
    dispatch(NovoProdutoActions.update());
  };

  const inactivated = () => {
    dispatch(NovoProdutoActions.activeInactive());
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={ChevronLeft}
            onClick={back}
            title={Texts.novoProduto.buttons.voltar}
          />
        </Grid>
        {updateMode && (
          <>
            <Grid item>
              <Button
                icon={PersonAddDisabled}
                onClick={inactivated}
                loading={loadingActivate}
                title={
                  active
                    ? Texts.novoProduto.buttons.inativar
                    : Texts.novoProduto.buttons.ativar
                }
              />
            </Grid>
            {active && (
              <Grid item>
                <Button
                  icon={ShoppingBasket}
                  onClick={update}
                  colored
                  loading={loading}
                  title={Texts.novoProduto.buttons.editar}
                />
              </Grid>
            )}
          </>
        )}
        <Dados />
        <Exibicao />
        <Fotos />
        {!updateMode && (
          <Grid item xs={12} sm={12}>
            <Button
              icon={ShoppingBasket}
              onClick={save}
              title={Texts.novoProduto.buttons.cadastrar}
              colored
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
    </Main>
  );
}

export { NovoProduto };
