import { TypeUnitConstant } from "utils/enum/type-unit.enum";
import { TypeUnit } from "utils/types/product";

export const units: Array<TypeUnit> = [
  {
    "unit": TypeUnitConstant.Unidade,
    "description": "UNIDADE",
  },
  {
    "unit": TypeUnitConstant.Caixa,
    "description": "CAIXA",
  },
  {
    "unit": TypeUnitConstant.Pacote,
    "description": "PACOTE",
  },
  {
    "unit": TypeUnitConstant.Ampola,
    "description": "AMPOLA",
  },
  {
    "unit": TypeUnitConstant.Bandeja,
    "description": "BANDEJA",
  },
  {
    "unit": TypeUnitConstant.Barra,
    "description": "BARRA",
  },
  {
    "unit": TypeUnitConstant.Capsula,
    "description": "CÁPSULA",
  },
  {
    "unit": TypeUnitConstant.Cj,
    "description": "CONJUNTO",
  },
  {
    "unit": TypeUnitConstant.Cm,
    "description": "CENTÍMETRO",
  },
  {
    "unit": TypeUnitConstant.Embalagem,
    "description": "EMBALAGEM",
  },
  {
    "unit": TypeUnitConstant.Fardo,
    "description": "FARDO",
  },
  {
    "unit": TypeUnitConstant.Frasco,
    "description": "FRASCO",
  },
  {
    "unit": TypeUnitConstant.Garrafa,
    "description": "GARRAFA",
  },
  {
    "unit": TypeUnitConstant.Kg,
    "description": "QUILOGRAMA",
  },
  {
    "unit": TypeUnitConstant.Kit,
    "description": "KIT",
  },
  {
    "unit": TypeUnitConstant.Lata,
    "description": "LATA",
  },
  {
    "unit": TypeUnitConstant.Litro,
    "description": "LITRO",
  },
  {
    "unit": TypeUnitConstant.Metro,
    "description": "METRO",
  },
  {
    "unit": TypeUnitConstant.Pc,
    "description": "PEÇA",
  },
  {
    "unit": TypeUnitConstant.Saco,
    "description": "SACO",
  },
  {
    "unit": TypeUnitConstant.Saco,
    "description": "SACOLA",
  },
  {
    "unit": TypeUnitConstant.Vidro,
    "description": "VIDRO",
  }
]