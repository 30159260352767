import React from 'react';
import { Grid } from '@material-ui/core';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'components/text';
import { Texts } from 'constants/strings';
import { NovoUsuarioActions } from 'store/ducks/novo-usuario';

function AcessoUsuario() {
  const { email, emailErr, disableFields } = useSelector(
    (state) => state.novoUsuario
  );
  const dispatch = useDispatch();
  const changeText = (event) => {
    dispatch(
      NovoUsuarioActions.updateFields(event.target.id, event.target.value)
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.usuarios.sessions.acesso}</Title>
      </Grid>
      <Grid item xs={12}>
        <Text>{Texts.usuarios.texts.acesso}</Text>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.usuarios.inputLabels.email}
          id="email"
          disabled={disableFields}
          onChange={changeText}
          value={email}
          error={emailErr}
        />
      </Grid>
    </>
  );
}

export default AcessoUsuario;
