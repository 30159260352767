import React from 'react';
import { Grid } from '@material-ui/core';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { useSelector } from 'react-redux';
import Masks from 'utils/Masks';
import { Texts } from 'constants/strings';

function DadosGerais() {
  const { person, email } = useSelector((state) => state.user.user);

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.usuarios.sessions.geral}</Title>
      </Grid>
      <Grid item xs={12} sm={7}>
        <TextInput
          disabled
          label={Texts.usuarios.inputLabels.nome}
          value={person.name}
        />
      </Grid>
      <Grid item xs={6} sm={5}>
        <TextInput
          disabled
          label={Texts.usuarios.inputLabels.nasc}
          mask={Masks.data}
          value={person.birthdate}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextInput
          disabled
          label={Texts.usuarios.inputLabels.telefone}
          mask={Masks.celular}
          value={person.phone}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextInput
          disabled
          label={Texts.usuarios.inputLabels.rg}
          value={person.rg}
          mask={Masks.rg}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextInput
          disabled
          label={Texts.usuarios.inputLabels.cpf}
          mask={Masks.cpf}
          value={person.cpf}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.usuarios.inputLabels.email}
          id="email"
          disabled
          value={email}
        />
      </Grid>
    </>
  );
}

export default DadosGerais;
