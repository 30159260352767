import React, { useState } from 'react';
import Main from 'components/main-container';
import { Checkbox, Grid, makeStyles } from '@material-ui/core';
import { Button } from 'components/button';
import { useHistory } from 'react-router-dom';
import { Texts } from 'constants/strings';
import { AddCircle, ChevronLeft } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from 'components/loading-screen';
import Text from 'components/text';
import { FiscalActions } from 'store/ducks/fiscal';
import ErrorContainer from 'components/error-container';
import Produtos from './components/Produtos';
import ValoresTaxas from './components/ValoresTaxas';
import DadosGerais from './components/DadosGerais';
import Fornecedor from './components/Fornecedor';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fab: {
    position: 'fixed',
    right: 30,
    bottom: 30,
    zIndex: 9999,
  },
}));

function NotaEntrada() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { uploadLoading, entryLoading, entryFailure } = useSelector(
    (state) => state.fiscal.nfe.new
  );
  const [allow, setAllow] = useState(false);

  const back = () => {
    history.goBack();
  };

  const importNfe = () => {
    dispatch(FiscalActions.requestEntryNFe(history));
  };

  return (
    <>
      {uploadLoading && <LoadingScreen size={30} />}
      {!uploadLoading && (
        <Main>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                icon={ChevronLeft}
                onClick={back}
                title={Texts.empresa.buttons.voltar}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <DadosGerais />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Fornecedor />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ValoresTaxas />
            </Grid>
            <Grid item sm={12} xs={12}>
              <Produtos />
            </Grid>
            <Grid item sm={12} xs={12}>
              <div className={classes.row}>
                <Checkbox
                  color="primary"
                  checked={allow}
                  onChange={(event) => setAllow(event.target.checked)}
                />
                <Text>
                  Li e concordo que as informações apresentadas são reais e
                  condizentes com a Nota Fiscal importada.
                </Text>
              </div>
            </Grid>
            <Grid item sm={12} xs={12}>
              <Button
                icon={AddCircle}
                onClick={importNfe}
                title={Texts.novoProduto.buttons.importar}
                colored
                loading={entryLoading}
                disable={!allow}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              <ErrorContainer
                errors={entryFailure}
                title="Ocorreu um erro ao inserir a nota no sistema Inscrição Estadual Inválida"
              />
            </Grid>
          </Grid>
        </Main>
      )}
    </>
  );
}

export { NotaEntrada };
