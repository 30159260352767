import produce from 'immer';

export const Types = {
  FILTER: 'produto@FILTER',
  FILTER_ERROR: 'produto@FILTER_ERROR',
  FILTER_SUCCESS: 'produto@FILTER_SUCCESS',
  CHANGE_TEXT: 'produto@CHANGE_TEXT',
  CLEAR: 'produto@CLEAR',
  REQUEST_PROVIDERS: 'produto@REQUEST_PROVIDERS',
  REQUEST_PROVIDERS_RESULT: 'produto@REQUEST_PROVIDERS_RESULT',
  PROVIDER_CHANGE_FIELD: 'produto@PROVIDER_CHANGE_FIELD',
  GET_CEP: 'produto@GET_CEP',
  GET_CEP_RESULT: 'produto@GET_CEP_RESULT',
  CREATE_UPDATE_PROVIDER: 'produto@CREATE_UPDATE_PROVIDER',
  CREATE_UPDATE_PROVIDER_RESULT: 'produto@CREATE_UPDATE_PROVIDER_RESULT',
  RESET_PROVIDER: 'produto@RESET_PROVIDER',
  UPDATE_PROVIDER: 'produto@UPDATE_PROVIDER',
};

const initialState = {
  produtos: [],
  loading: false,
  error: false,
  description: '',
  brand: '',
  gtin_ean_code: '',
  status: '',
  providers: [],
  providerError: false,
  providerLoading: false,
  updatedProvider: {
    update: false,
    active: null,
    disabled: false,
    loadingUpdate: false,
    loadingInactivate: false,
    providerId: null,
    cpf_cnpj: '',
    cpf_cnpjErr: '',
    rg: '',
    rgErr: '',
    ie: '',
    ieErr: '',
    social_reason: '',
    social_reasonErr: '',
    fantasy_name: '',
    fantasy_nameErr: '',
    email: '',
    emailErr: '',
    postcode: '',
    cepLoading: false,
    postcodeErr: '',
    street: '',
    streetErr: '',
    neighborhood: '',
    neighborhoodErr: '',
    city: '',
    cityErr: '',
    uf: '',
    ufErr: '',
    number: '',
    numberErr: '',
    codeCity: '',
    phone: '',
    phoneErr: '',
  },
};

const produto = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.CHANGE_TEXT:
      state[payload.key] = payload.value;
      return state;
    case Types.FILTER:
      state.loading = true;
      state.error = false;
      return state;
    case Types.FILTER_ERROR:
      state.loading = false;
      state.error = true;
      return state;
    case Types.FILTER_SUCCESS:
      state.loading = false;
      state.error = false;
      state.produtos = payload;
      return state;
    case Types.CLEAR:
      state = {
        ...state,
        ...{
          description: '',
          brand: '',
          gtin_ean_code: '',
          status: '',
          categoria: '',
        },
      };
      return state;
    case Types.REQUEST_PROVIDERS:
      state.providerLoading = true;
      state.providerError = false;
      return state;
    case Types.REQUEST_PROVIDERS_RESULT:
      state.providerLoading = false;
      state.providerError = payload.error;
      state.providers = payload.providers || state.providers;
      return state;
    case Types.PROVIDER_CHANGE_FIELD:
      if (state.updatedProvider[`${payload.key}Err`]) {
        state.updatedProvider[`${payload.key}Err`] = '';
      }
      state.updatedProvider[payload.key] = payload.value;
      return state;
    case Types.GET_CEP:
      state.updatedProvider.cepLoading = true;
      return state;
    case Types.GET_CEP_RESULT:
      state.updatedProvider.cepLoading = false;
      state.updatedProvider = { ...state.updatedProvider, ...payload };
      return state;
    case Types.CREATE_UPDATE_PROVIDER:
      state.updatedProvider.loadingUpdate = true;
      return state;
    case Types.CREATE_UPDATE_PROVIDER_RESULT:
      state.updatedProvider.loadingUpdate = false;
      state.updatedProvider = { ...state.updatedProvider, ...payload };
      return state;
    case Types.RESET_PROVIDER:
      state.updatedProvider = initialState.updatedProvider;
      return state;
    case Types.UPDATE_PROVIDER:
      state.updatedProvider = {
        ...initialState.updatedProvider,
        update: true,
        providerId: payload.provider_id,
        cpf_cnpj: payload.cpf_cnpj || '',
        rg: payload.rg || '',
        ie: payload.ie || '',
        social_reason: payload.social_reason || '',
        fantasy_name: payload.fantasy_name || '',
        email: payload.email || '',
        postcode: payload.address?.postcode || '',
        street: payload.address?.street || '',
        neighborhood: payload.address?.neighborhood || '',
        city: payload.address?.city || '',
        uf: payload.address?.uf || '',
        number: payload.number || '',
        phone: payload?.phone || '',
        codeCity: payload.address?.code_city || '',
      };
      return state;
    default:
      return state;
  }
});

export default produto;

export const ProdutoActions = {
  changeText: (key, value) => ({
    type: Types.CHANGE_TEXT,
    payload: { key, value },
  }),
  filter: () => ({
    type: Types.FILTER,
  }),
  errorFilter: () => ({
    type: Types.FILTER_ERROR,
  }),
  successFilter: (result) => ({
    type: Types.FILTER_SUCCESS,
    payload: result,
  }),
  clear: () => ({
    type: Types.CLEAR,
  }),
  requestProviders: (cpfCnpj = '', fantasyName = '') => ({
    type: Types.REQUEST_PROVIDERS,
    payload: { cpfCnpj, fantasyName },
  }),
  requestProvidersSuccess: (providers) => ({
    type: Types.REQUEST_PROVIDERS_RESULT,
    payload: { error: false, providers },
  }),
  requestProvidersFailure: () => ({
    type: Types.REQUEST_PROVIDERS_RESULT,
    payload: { error: true },
  }),
  changeTextProvider: (key, value) => ({
    type: Types.PROVIDER_CHANGE_FIELD,
    payload: { key, value },
  }),
  createUpdateProvider: () => ({
    type: Types.CREATE_UPDATE_PROVIDER,
  }),
  createUpdateProviderResult: (errors = {}) => ({
    type: Types.CREATE_UPDATE_PROVIDER_RESULT,
    payload: errors,
  }),
  getCep: () => ({
    type: Types.GET_CEP,
  }),
  getCepResult: (result = {}) => ({
    type: Types.GET_CEP_RESULT,
    payload: result,
  }),
  resetProvider: () => ({
    type: Types.RESET_PROVIDER,
  }),
  updateProvider: (provider) => ({
    type: Types.UPDATE_PROVIDER,
    payload: provider,
  }),
};
