import Dashboard from 'pages/dashboard';
import {
  Home,
  ShoppingCart,
  Person,
  Receipt,
  Storefront,
  ShoppingBasket,
  Poll,
  AddAlert,
} from '@material-ui/icons';
import RouterNames from 'constants/router-names';
import { Modules } from 'constants/permissions';
import { Texts } from 'constants/strings';
import Test from 'pages/test';
import { NovoUsuario, Usuarios } from 'pages/usuarios';
import UsuarioPdv from 'pages/usuario-pdv';
import Forbidden from 'pages/forbidden';
import Lojas from 'pages/lojas';
import { NovaLoja } from 'pages/lojas/NovaLoja';
import Fiscal, {
  DetalhesNotaSaida,
  NotaEntrada,
  NotaSaida,
  DetalhesNotaEntrada,
} from 'pages/fiscal';
import { Produtos, NovoProduto } from 'pages/produtos/index';
import Empresa from 'pages/empresa';
import { Orders, OrderDetails } from 'pages/orders';
import StoreInventory from 'pages/store-inventory/index';
import Providers from 'pages/providers';
import UpdateProvider from 'pages/providers/UpdateProvider';
import { Cancellations } from 'pages/cancellations/Cancellations';
import CancellationDetails from 'pages/cancellation-details';
import {
  OrdersItems,
  ReportOrders,
  Reports,
  Billing,
  BestClientOrders,
  ReportBestClients,
  ListTopSellings,
  MovesStocks,
  BillingShopkeeper,
} from 'pages/reports';
import { Messages, Message, MessageShopkeeper } from 'pages/messages';
import { Promotions, NewPromotions } from 'pages/promotions';
import Banner from 'pages/banners';

export default [
  {
    path: RouterNames.FORBIDDEN,
    toolbarName: Texts.pages.dashboard,
    module: Modules.AUTHORIZED,
    component: Forbidden,
  },
  {
    path: RouterNames.MAIN,
    drawerName: Texts.drawer.dashboard,
    toolbarName: Texts.pages.dashboard,
    module: Modules.AUTHORIZED,
    drawer: true,
    icon: Home,
    component: Dashboard,
  },
  {
    path: RouterNames.EMPRESA,
    toolbarName: Texts.pages.empresa,
    module: Modules.MODULE_COMPANY,
    component: Empresa,
  },
  {
    path: RouterNames.ADMIN_PRODUTOS,
    drawerName: Texts.drawer.produtos,
    toolbarName: Texts.pages.produtos,
    module: Modules.MODULE_PRODUCTS,
    drawer: true,
    icon: ShoppingBasket,
    component: Produtos,
  },
  {
    path: RouterNames.ADMIN_PRODUTOS_NOVO,
    toolbarName: Texts.pages.produtos_novo,
    parentPath: RouterNames.ADMIN_PRODUTOS,
    module: Modules.MODULE_PRODUCTS,
    component: NovoProduto,
  },
  {
    path: RouterNames.ADMIN_PRODUTOS_PROMOCOES,
    toolbarName: Texts.pages.promocoes,
    parentPath: RouterNames.ADMIN_PRODUTOS,
    module: Modules.MODULE_PRODUCTS,
    component: Promotions,
  },
  {
    path: RouterNames.ADMIN_PRODUTOS_PROMOCOES_NOVO,
    toolbarName: Texts.pages.promocoesNovo,
    parentPath: RouterNames.ADMIN_PRODUTOS,
    module: Modules.MODULE_PRODUCTS,
    component: NewPromotions,
  },
  {
    path: RouterNames.ADMIN_PRODUTOS_PROMOCOES_EDITAR,
    toolbarName: Texts.pages.promocoesEditar,
    parentPath: RouterNames.ADMIN_PRODUTOS,
    module: Modules.MODULE_PRODUCTS,
    component: NewPromotions,
  },
  {
    path: RouterNames.ADMIN_PRODUTOS_EDITAR,
    toolbarName: Texts.pages.produtos_editar,
    parentPath: RouterNames.ADMIN_PRODUTOS,
    module: Modules.MODULE_PRODUCTS,
    component: NovoProduto,
  },
  {
    path: RouterNames.PROVIDERS,
    toolbarName: Texts.pages.providers,
    parentPath: RouterNames.ADMIN_PRODUTOS_NOVO,
    module: Modules.MODULE_PRODUCTS,
    component: Providers,
  },
  {
    path: RouterNames.PROVIDERS_NOVO,
    toolbarName: Texts.pages.providers_novo,
    parentPath: RouterNames.PROVIDERS_NOVO,
    module: Modules.MODULE_PRODUCTS,
    component: UpdateProvider,
  },
  {
    path: RouterNames.PROVIDERS_EDIT,
    toolbarName: Texts.pages.providers_edit,
    parentPath: RouterNames.PROVIDERS_EDIT,
    module: Modules.MODULE_PRODUCTS,
    component: UpdateProvider,
  },
  {
    path: RouterNames.ADMIN_USUARIOS,
    drawerName: Texts.drawer.usuarios,
    toolbarName: Texts.pages.usuarios,
    module: Modules.MODULE_USERS,
    drawer: true,
    icon: Person,
    component: Usuarios,
  },
  {
    path: RouterNames.ADMIN_USUARIOS_CADASTRO,
    parentPath: RouterNames.ADMIN_USUARIOS,
    toolbarName: Texts.pages.usuarios,
    module: Modules.MODULE_USERS,
    component: NovoUsuario,
  },
  {
    path: RouterNames.ADMIN_USUARIOS_EDITAR,
    parentPath: RouterNames.ADMIN_USUARIOS,
    toolbarName: Texts.pages.usuarios,
    module: Modules.MODULE_USERS,
    component: NovoUsuario,
  },
  {
    path: RouterNames.ADMIN_LOJAS,
    drawerName: Texts.drawer.lojas,
    toolbarName: Texts.pages.lojas,
    module: Modules.MODULE_STORE,
    drawer: true,
    icon: Storefront,
    component: Lojas,
  },
  {
    path: RouterNames.ADMIN_LOJAS_CADASTRO,
    parentPath: RouterNames.ADMIN_LOJAS,
    toolbarName: Texts.pages.novaLoja,
    module: Modules.MODULE_STORE,
    component: NovaLoja,
  },
  {
    path: RouterNames.ADMIN_LOJAS_BANNER,
    parentPath: RouterNames.ADMIN_LOJAS,
    toolbarName: Texts.pages.banners,
    module: Modules.MODULE_STORE,
    component: Banner,
  },
  {
    path: RouterNames.STORE_INVENTORY,
    parentPath: RouterNames.ADMIN_LOJAS,
    toolbarName: Texts.pages.gerenciarEstoque,
    module: Modules.MODULE_STORE,
    component: StoreInventory,
  },
  {
    path: RouterNames.ADMIN_LOJAS_EDITAR,
    parentPath: RouterNames.ADMIN_LOJAS,
    toolbarName: Texts.pages.novaLoja,
    module: Modules.MODULE_USERS,
    component: NovaLoja,
  },
  {
    path: RouterNames.ADMIN_NOTASFISCAIS,
    drawerName: Texts.drawer.notasFiscais,
    toolbarName: Texts.pages.notasFiscais,
    module: Modules.MODULE_FISCAL_NOTE,
    drawer: true,
    icon: Receipt,
    component: Fiscal,
  },
  {
    path: RouterNames.ADMIN_NOTASFISCAIS_ENTRADA,
    parent: RouterNames.ADMIN_NOTASFISCAIS,
    toolbarName: Texts.pages.notaEntrada,
    module: Modules.MODULE_FISCAL_NOTE,
    component: NotaEntrada,
  },
  {
    path: RouterNames.ADMIN_NOTASFISCAIS_SAIDA,
    parent: RouterNames.ADMIN_NOTASFISCAIS,
    toolbarName: Texts.pages.notaSaida,
    module: Modules.MODULE_FISCAL_NOTE,
    component: NotaSaida,
  },
  {
    path: RouterNames.ADMIN_NOTASFISCAIS_DETALHES_SAIDA,
    parent: RouterNames.ADMIN_NOTASFISCAIS,
    toolbarName: Texts.pages.notaDetalhes,
    module: Modules.MODULE_FISCAL_NOTE,
    component: DetalhesNotaSaida,
  },
  {
    path: RouterNames.ADMIN_NOTASFISCAIS_DETALHES_ENTRADA,
    parent: RouterNames.ADMIN_NOTASFISCAIS,
    toolbarName: Texts.pages.notaDetalhes,
    module: Modules.MODULE_FISCAL_NOTE,
    component: DetalhesNotaEntrada,
  },
  {
    path: RouterNames.ADMIN_RELATORIOS,
    drawerName: Texts.drawer.relatorios,
    toolbarName: Texts.pages.relatorios,
    module: Modules.MODULE_REPORTS,
    drawer: true,
    icon: Poll,
    component: Reports,
  },
  {
    path: RouterNames.REPORTS_ORDERS,
    toolbarName: Texts.pages.reportsOrders,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: ReportOrders,
  },
  {
    path: RouterNames.REPORTS_BILLING,
    toolbarName: Texts.pages.faturamento,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: Billing,
  },
  {
    path: RouterNames.REPORTS_BILLING_SHOPKEEPER,
    toolbarName: Texts.pages.faturamento,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: BillingShopkeeper,
  },
  {
    path: RouterNames.REPORTS_TOP_SELLERS,
    toolbarName: Texts.pages.produtosMaisVendidos,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: ListTopSellings,
  },
  {
    path: RouterNames.REPORTS_MOVES_STOCK,
    toolbarName: Texts.pages.movimentacoes,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: MovesStocks,
  },
  {
    path: RouterNames.REPORTS_ORDERS_DETALHES,
    toolbarName: Texts.pages.reportsOrders,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: OrdersItems,
  },
  {
    path: RouterNames.REPORTS_FINANCIAL,
    toolbarName: Texts.pages.reportsFinancial,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: Test,
  },
  {
    path: RouterNames.REPORTS_BEST_CLIENTS,
    toolbarName: Texts.pages.reportsBestClients,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: ReportBestClients,
  },
  {
    path: RouterNames.REPORTS_BEST_CLIENTS_ORDERS_DETALHES,
    toolbarName: Texts.pages.reportsBestClientsDetails,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: BestClientOrders,
  },
  {
    path: RouterNames.CANCELLATIONS,
    toolbarName: Texts.pages.cancellations,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: Cancellations,
  },
  {
    path: RouterNames.CANCELLATIONS_DETALHES,
    toolbarName: Texts.pages.cancellationsDetails,
    parentPath: RouterNames.ADMIN_RELATORIOS,
    module: Modules.MODULE_REPORTS,
    component: CancellationDetails,
  },
  {
    path: RouterNames.ADMIN_ATUALIZACOES,
    drawerName: Texts.drawer.atualizacoes,
    toolbarName: Texts.pages.atualizacoes,
    module: Modules.MODULE_UPDATES,
    drawer: true,
    icon: AddAlert,
    component: Messages,
  },
  {
    path: RouterNames.ADMIN_ATUALIZACOES_MENSAGEM,
    toolbarName: Texts.pages.mensagens,
    parentPath: RouterNames.ADMIN_ATUALIZACOES,
    module: Modules.MODULE_UPDATES,
    component: Message,
  },
  {
    path: RouterNames.ADMIN_ATUALIZACOES_SHOPKEEPER,
    toolbarName: Texts.pages.mensagem,
    parentPath: RouterNames.ADMIN_ATUALIZACOES,
    module: Modules.MODULE_UPDATES,
    component: MessageShopkeeper,
  },
  {
    path: RouterNames.PDV_ORDERS,
    drawerName: Texts.drawer.orders,
    toolbarName: Texts.pages.orders,
    module: Modules.MODULE_ORDERS,
    onlyPdv: true,
    drawer: true,
    icon: ShoppingCart,
    component: Orders,
  },
  {
    path: RouterNames.PDV_ORDER_DETAILS,
    toolbarName: Texts.pages.ordersDetails,
    module: Modules.MODULE_ORDERS,
    parentPath: RouterNames.PDV_ORDERS,
    onlyPdv: true,
    component: OrderDetails,
  },
  {
    path: RouterNames.PDV_USUARIO,
    toolbarName: Texts.pages.usuarioPdv,
    module: Modules.AUTHORIZED,
    component: UsuarioPdv,
  },
];
