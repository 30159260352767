import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Text from 'components/text';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
  label: {
    fontWeight: 'bold',
  },
  value: {},
}));

function TextInfo({ label, value, noWrap }) {
  const classes = useStyles();

  return (
    <div>
      <Text style={classes.label}>{label}</Text>
      <Typography noWrap={noWrap} className={classes.value}>
        {value || '-'}
      </Typography>
    </div>
  );
}

TextInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noWrap: PropTypes.bool,
};

TextInfo.defaultProps = {
  label: '',
  value: '',
  noWrap: false,
};

export { TextInfo };
