import { Grid, makeStyles, IconButton } from '@material-ui/core';
import { FileCopy, Delete, FiberNew } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Masks from 'utils/Masks';
import Text from 'components/text';
import SearchSelector from 'components/search-selector';
import { TextInput } from 'components/text-input';
import PropTypes from 'prop-types';
import { FiscalActions } from 'store/ducks/fiscal';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';
import { TypeUnitSelector } from 'components';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    borderWidth: 1,
    borderColor: (props) =>
      props.selected ? theme.palette.grey[300] : theme.palette.primary.main,
    borderStyle: 'solid',
  },
  index: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    fontSize: 25,
    marginRight: 10,
  },
  productName: {
    fontWeight: 'bold',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 30,
  },
  iconWrapper: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
}));

function Produto({ item, index }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles({ selected: true });
  const { products } = useSelector((state) => state.fiscal.nfe.new);

  const onChangeInput = (e) => {
    dispatch(FiscalActions.searchProducts((e && e.target.value) || ''));
  };

  const productLabel = (product) => product.description;

  const onSelectProduct = ({ value }) => {
    dispatch(FiscalActions.setProductToItem(index - 1, value));
  };

  const createNewProduct = () => {
    history.push(RouterNames.ADMIN_PRODUTOS_NOVO, {
      fillProduct: {
        cost_value: item.cost_value,
        type_unit: item.type_unit,
        description: item.description,
        ncm: item.ncm,
      },
    });
  };

  const changeText = (e) => {
    dispatch(
      FiscalActions.setProductUpdate(index - 1, e.target.id, e.target.value)
    );
  };

  const removeProduct = () => {
    dispatch(FiscalActions.removeProductFiscal(index - 1));
  };

  const duplicateProduct = () => {
    dispatch(FiscalActions.addProductFiscal(index - 1));
  };

  const changeType = ({ id, value }) => {
    dispatch(FiscalActions.setProductUpdate(index - 1, id, value));
  };

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item sm={12} xs={12}>
        <Text style={classes.productName}>
          <span className={classes.index}>{`${index}.`}</span>
          {item.description}
        </Text>
      </Grid>
      <Grid item sm={12} xs={12}>
        <SearchSelector
          id="product"
          label="Selecione o produto"
          onChangeInput={onChangeInput}
          getLabel={productLabel}
          onChange={onSelectProduct}
          options={products.list}
          loading={products.loading}
          value={item.product}
          optionCheckField="product_id"
          selectedCheckField="product_id"
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextInput
          label="Valor de Custo"
          id="cost_value"
          money
          onChange={changeText}
          value={item.cost_value}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextInput
          label="Quantidade"
          id="quantity"
          mask={Masks.quantity}
          onChange={changeText}
          value={item.quantity}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TypeUnitSelector
          value={item.product?.type_unit}
          onChange={changeType}
          id="type_unit"
        />
      </Grid>
      <Grid item sm={12} xs={12} className={classes.iconWrapper}>
        <IconButton onClick={createNewProduct}>
          <FiberNew className={classes.icon} />
        </IconButton>
        <IconButton onClick={removeProduct}>
          <Delete className={classes.icon} />
        </IconButton>
        <IconButton onClick={duplicateProduct}>
          <FileCopy className={classes.icon} />
        </IconButton>
      </Grid>
    </Grid>
  );
}

Produto.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    type_unit: PropTypes.string,
    quantity: PropTypes.string,
    cost_value: PropTypes.string,
    product: PropTypes.shape({}),
    ncm: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default Produto;
