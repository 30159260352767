const config = {
  timeToShowCancelButtonGeneralLoading: 20000, // 20 segundos
};

const status_order = {
  PAYMENT_CANCELED: 'ORDER_CANCELED',
  REQUESTED_CANCELLATION_REFUSED: 'REQUESTED_CANCELLATION_REFUSED',
  REQUESTED_CANCELLATION: 'REQUESTED_CANCELLATION',
  ORDER_DELIVERED: 'ORDER_DELIVERED',
  PAYMENT_CONFIRMED: 'PAYMENT_CONFIRMED',
  AWAITING_PAYMENT_CONFIRMATION: 'AWAITING_PAYMENT_CONFIRMATION',
  PAYMENT_REFUSED: 'PAYMENT_REFUSED',
};

const STATUS_ORDER_SELECTED = [
  {
    id: status_order.PAYMENT_CANCELED,
    name: 'Cancelado',
  },
  {
    id: status_order.REQUESTED_CANCELLATION,
    name: 'Solicitado Cancelamento',
  },
  {
    id: status_order.ORDER_DELIVERED,
    name: 'Entregue',
  },
  {
    id: status_order.PAYMENT_CONFIRMED,
    name: 'Pagamento Confirmado',
  },
  {
    id: status_order.AWAITING_PAYMENT_CONFIRMATION,
    name: 'Aguardando Pagamento',
  },
  {
    id: status_order.PAYMENT_REFUSED,
    name: 'Pagamento Recusado',
  },
];

const TYPE_NFE = {
  ENTRADA: '0',
  SAIDA: '1',
};

const purposeEmissionConstants = {
  NORMAL: 1,
  DEVOLUCAO: 2,
};

const purposeEmission = [
  {
    id: purposeEmissionConstants.NORMAL,
    name: 'Normal',
  },
  {
    id: purposeEmissionConstants.DEVOLUCAO,
    name: 'Devolução',
  },
];

const typesNfeConstants = {
  SAIDA: 'output',
  ENTRADA: 'input',
};
const typesNfe = [
  {
    id: typesNfeConstants.ENTRADA,
    name: 'Entrada',
  },
  {
    id: typesNfeConstants.SAIDA,
    name: 'Saída',
  },
];

const reasonsCancellation = [
  'Pedido Incorreto',
  'Cliente não pôde receber o pedido',
  'Pedido Duplicado',
  'Outros',
];

const presenceBuyer = [
  {
    id: 1,
    name: 'Não se aplica (por exemplo, para a Nota Fiscal complementar ou de ajuste)',
  },
  {
    id: 2,
    name: 'Operação presencial',
  },
  {
    id: 3,
    name: 'Operação não presencial, pela Internet',
  },
  {
    id: 4,
    name: 'Operação não presencial, Teleatendimento',
  },
  {
    id: 5,
    name: 'NFC-e em operação com entrega em domicílio',
  },
  {
    id: 9,
    name: 'Operação não presencial, outros.',
  },
];

const finalCostumer = [
  {
    id: 1,
    name: 'Normal',
  },
  {
    id: 2,
    name: 'Consumidor final',
  },
];

const TYPES_PAYMENT = [
  {
    id: 'PAYMENT_CREDIT_CARD',
    name: 'Pagamento no cartão de credito',
    type_payment_id: 1,
  },
  {
    id: 'IN_STORE_PAYMENT',
    name: 'Pagamento em loja',
    type_payment_id: 2,
  },
];

const CODE_CITY = '2304400';

export {
  config,
  status_order,
  reasonsCancellation,
  purposeEmission,
  presenceBuyer,
  finalCostumer,
  purposeEmissionConstants,
  typesNfeConstants,
  typesNfe,
  TYPE_NFE,
  TYPES_PAYMENT,
  STATUS_ORDER_SELECTED,
  CODE_CITY,
};
