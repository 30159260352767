import { call, put, select } from 'redux-saga/effects';
import queryCreator from 'utils/queryCreator';
import { RemoveMasks } from 'utils/Masks';
import { LojasActions } from 'store/ducks/lojas';
import { LojaApi } from 'services/api/LojaApi';
import { ProdutoApi } from 'services/api/ProdutoApi';
import { NotificationActions } from 'store/ducks/notification';
import { Errors } from 'constants/strings';

export function* getLojasFilter() {
  try {
    const { nomeFantasia, razaoSocial, cnpj, status } = yield select(
      (state) => state.lojas
    );
    const query = [];
    if (nomeFantasia.trim()) {
      query.push(queryCreator.like('fantasy_name', nomeFantasia));
    }
    if (razaoSocial.trim()) {
      query.push(queryCreator.like('social_reason', razaoSocial));
    }
    if (status !== '') {
      query.push(queryCreator.bool('active', status));
    }
    if (cnpj.trim()) {
      query.push(queryCreator.like('cpf_cnpj', RemoveMasks.onlyNumbers(cnpj)));
    }
    const { data } = yield call(
      LojaApi.getStores,
      queryCreator.queryFormatter(query)
    );
    const list = data.data.map((item) => ({
      ...item,
      activeName: item.active ? 'ATIVO' : 'INATIVO',
    }));
    yield put(LojasActions.filterSuccess(list));
  } catch (error) {
    yield put(LojasActions.filterError());
  }
}

export function* searchStores({ payload: search }) {
  try {
    const query = queryCreator.like('fantasy_name', search.trim());
    const { data } = yield call(LojaApi.getStores, `?query=${query}`);
    yield put(LojasActions.searchStoreSuccess(data.data));
  } catch (error) {
    yield put(LojasActions.searchStoreFailure());
  }
}

export function* searchProductsByName({ payload: search }) {
  try {
    const query = queryCreator.like('description', search.trim());
    const { data } = yield call(ProdutoApi.getProdutos, `?query=${query}`);
    yield put(LojasActions.searchProductsByNameSuccess(data.data));
  } catch (error) {
    yield put(LojasActions.searchProductsByNameFailure());
  }
}

export function* getStockProductsFromStore() {
  try {
    const { store } = yield select((state) => state.lojas.inventory);
    const { data } = yield call(LojaApi.getProdutosByStore, store.store_id);
    const products = data.filter((prod) => prod.storeProduct[0].quantity !== 0); // Removendo itens com estoque 0 na loja
    yield put(LojasActions.getProductsByStoreSuccess(products));
  } catch (error) {
    yield put(LojasActions.getProductsByStoreFailure());
  }
}

export function* moveProducts() {
  try {
    const { store, selectedProduct, quantity, updating } = yield select(
      (state) => state.lojas.inventory
    );

    const request = updating
      ? ProdutoApi.removeProductFromStore
      : ProdutoApi.sendProductToStore;
    yield call(request, selectedProduct.product_id, store.store_id, quantity);
    yield put(LojasActions.moveToStoreSuccess());
    yield put(LojasActions.getProductsByStore());
    yield put(LojasActions.searchProductsByName(''));
    yield put(LojasActions.moveProducts());
    yield put(NotificationActions.success('Sucesso ao mover produtos'));
  } catch (error) {
    yield put(LojasActions.moveToStoreFailure());
    yield put(
      NotificationActions.error(
        (error.response && error.response.data && error.response.data.data) ||
          Errors.moveProductToStore
      )
    );
  }
}
