import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import theme from 'styles/theme';
import 'fontsource-roboto';
import 'assets/fonts/montserrat.css';
import 'assets/fonts/nunito.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';

Sentry.init({
  dsn: 'https://e72bbbac74ab4df8b650c5748b0a48a2@o619393.ingest.sentry.io/5760314',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  debug: true,
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById('root')
);
