import { status_order } from 'constants/config/Config';
import produce from 'immer';

export const Types = {
  GET_LOW_STOCK: 'dashboard@GET_LOW_STOCK',
  GET_LOW_STOCK_RESULT: 'dashboard@GET_LOW_STOCK_RESULT',
  GET_ORDERS_BY_STATUS: 'dashboard@GET_ORDERS_BY_STATUS',
  GET_ORDERS_BY_STATUS_RESULT: 'dashboard@GET_ORDERS_BY_STATUS_RESULT',
};

const initialState = {
  stock: {
    wessex: {
      products: [],
      quantity: 0,
      show: false,
    },
    stores: {
      data: [],
      quantity: 0,
      show: false,
    },
  },
  sales: {
    [status_order.REQUESTED_CANCELLATION]: {
      orders: [],
      quantity: 0,
    },
    monthly: [],
    daily: [],
  },
};

const dashboard = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_LOW_STOCK_RESULT:
      state.stock.wessex = payload.wessex;
      state.stock.stores = payload.stores;
      return state;
    case Types.GET_ORDERS_BY_STATUS_RESULT:
      if (payload) {
        state.sales[payload.status] = payload.value;
      }
      return state;
    default:
      return state;
  }
});

export default dashboard;

export const DashboardActions = {
  getLowStock: () => ({
    type: Types.GET_LOW_STOCK,
  }),
  getLowStockResult: (stores, wessex) => ({
    type: Types.GET_LOW_STOCK_RESULT,
    payload: { stores, wessex },
  }),
  getOrdersByStatus: (status) => ({
    type: Types.GET_ORDERS_BY_STATUS,
    payload: status,
  }),
  getOrdersByStatusResult: (value, status) => ({
    type: Types.GET_ORDERS_BY_STATUS_RESULT,
    payload: value ? { status, value } : null,
  }),
};
