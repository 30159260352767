import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Title } from 'components/title/Title';
import Text from 'components/text';
import { Texts } from 'constants/strings';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  containerLojas: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  nameLoja: {
    fontWeight: 'bold',
  },
  cnpjLoja: {},
  icon: {
    color: theme.palette.primary.main,
  },
}));

function Lojas() {
  const { storeUser, shopkeeper } = useSelector((state) => state.user.user);
  const [stores] = useState(storeUser.map((store) => store));
  const classes = useStyles();

  return shopkeeper ? (
    <>
      <Grid item xs={12}>
        <Title>{Texts.usuarios.sessions.lojas}</Title>
      </Grid>
      <Grid item xs={12} sm={12}>
        {stores.map((loja) => (
          <div key={loja.store_id} className={classes.containerLojas}>
            <Text style={classes.nameLoja}>{loja.fantasy_name}</Text>
            <Text>{`-${loja.cpf_cnpj}`}</Text>
          </div>
        ))}
      </Grid>
    </>
  ) : null;
}

export default Lojas;
