import SearchSelector from 'components/search-selector';
import React, { useEffect } from 'react';
import { useSearchStores } from 'context/store';
import { Store, StoreSelectorResult } from 'utils/types/store';
import queryCreator from 'utils/queryCreator';
import { useCallback } from 'react';

type OptionValue = Store | Array<Store> | undefined;

type Props<T extends OptionValue> = {
  value?: T;
  onChange: (value: StoreSelectorResult<T>) => void;
  id: string;
  multiple?: boolean;
  disabled?: boolean;
}

function StoreSelector<T extends OptionValue>({ value, onChange, id, multiple, disabled }: Props<T>) {
  const {searchStores, loading, stores} = useSearchStores();

  const onChangeInputStore = useCallback((e: any) => {
    const fantasyName = e?.target?.value || '';
    const queries: Array<string> = [];
    if (fantasyName.trim()) {
      queries.push(queryCreator.like('fantasy_name', fantasyName));
    }
    searchStores(queryCreator.formatter({ queries }));
  }, [searchStores]);

  const onChangeStore = ({ value: store }: StoreSelectorResult<T>) => {
    onChange({ id, value: store });
  };

  useEffect(() => {
    onChangeInputStore('');
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <SearchSelector
      id="store"
      label="Selecione a Loja"
      onChangeInput={onChangeInputStore}
      getLabel={(option) => option.fantasy_name}
      onChange={onChangeStore}
      options={stores}
      loading={loading}
      value={value}
      optionCheckField="store_id"
      selectedCheckField="store_id"
      multiple={multiple}
      disabled={disabled}
    />
  );
}

export { StoreSelector };
