import produce from 'immer';

export const Types = {
  UPDATE_FIELDS: 'lojas@UPDATE_FIELDS',
  FILTER: 'lojas@FILTER',
  FILTER_SUCCESS: 'lojas@FILTER_SUCCESS',
  FILTER_ERROR: 'lojas@FILTER_ERROR',
  CLEAR_FIELDS: 'lojas@CLEAR_FIELDS',
  REQUEST_STORES_BY_NAME: 'lojas@REQUEST_STORES_BY_NAME',
  CONTROL_STORES_BY_NAME: 'lojas@CONTROL_STORES_BY_NAME',
  REQUEST_PRODUCTS_BY_STOKE_STORE: 'lojas@REQUEST_PRODUCTS_BY_STOKE_STORE',
  CONTROL_PRODUCTS_BY_STOKE_STORE: 'lojas@CONTROL_PRODUCTS_BY_STOKE_STORE',
  REQUEST_PRODUCTS_BY_NAME: 'lojas@REQUEST_PRODUCTS_BY_NAME',
  CONTROL_PRODUCTS_BY_NAME: 'lojas@CONTROL_PRODUCTS_BY_NAME',
  INVENTORY_UPDATE_FIELDS: 'lojas@INVENTORY_UPDATE_FIELDS',
  INVENTORY_GET_PRODUCTS_BY_STORE: 'lojas@INVENTORY_GET_PRODUCTS_BY_STORE',
  PRODUCT_ADD_OR_REMOVE: 'lojas@PRODUCT_ADD_OR_REMOVE',
  PRODUCT_ADD_OR_REMOVE_CONTROL: 'lojas@PRODUCT_ADD_OR_REMOVE_CONTROL',
  ADD_OR_REMOVE_PRODUCTS_OK: 'lojas@ADD_OR_REMOVE_OK',
  RESET_INVENTORY: 'lojas@RESET_INVENTORY',
};

const initialState = {
  nomeFantasia: '',
  razaoSocial: '',
  cnpj: '',
  status: '',
  filterError: false,
  filterLoading: false,
  lojas: [],
  searchStore: {
    loading: false,
    error: false,
    stores: [],
  },
  searchProducts: {
    failure: false,
    loading: false,
    products: [],
  },
  inventory: {
    store: null,
    selectedProduct: null,
    quantity: 0,
    updating: false,
    loadingStock: false,
    failureStock: false,
    productsByStore: [],
    movingToStore: {
      loading: false,
      failure: false,
    },
  },
};

const lojas = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.UPDATE_FIELDS:
      state[payload.key] = payload.value;
      return state;
    case Types.FILTER:
      state.filterLoading = true;
      state.filterError = false;
      return state;
    case Types.FILTER_ERROR:
      state.filterLoading = false;
      state.filterError = true;
      return state;
    case Types.FILTER_SUCCESS:
      state.filterLoading = false;
      state.filterError = false;
      state.lojas = payload;
      return state;
    case Types.CLEAR_FIELDS:
      state.nomeFantasia = '';
      state.razaoSocial = '';
      state.status = '';
      state.cnpj = '';
      return state;
    case Types.REQUEST_STORES_BY_NAME:
      state.searchStore.loading = true;
      state.searchStore.error = false;
      return state;
    case Types.CONTROL_STORES_BY_NAME:
      state.searchStore.loading = payload.loading;
      state.searchStore.error = payload.error;
      state.searchStore.stores = payload.stores || state.searchStore.stores;
      return state;
    case Types.REQUEST_PRODUCTS_BY_NAME:
      state.searchProducts.loading = true;
      state.searchProducts.error = false;
      return state;
    case Types.CONTROL_PRODUCTS_BY_NAME:
      state.searchProducts.loading = payload.loading;
      state.searchProducts.error = payload.error;
      state.searchProducts.products =
        payload.products || state.searchProducts.products;
      return state;
    case Types.INVENTORY_UPDATE_FIELDS:
      state.inventory[payload.key] = payload.value;
      return state;
    case Types.INVENTORY_GET_PRODUCTS_BY_STORE:
      return state;
    case Types.REQUEST_PRODUCTS_BY_STOKE_STORE:
      state.inventory.loadingStock = true;
      state.inventory.failureStock = false;
      state.inventory.productsByStore = [];
      return state;
    case Types.CONTROL_PRODUCTS_BY_STOKE_STORE:
      state.inventory.loadingStock = payload.loading;
      state.inventory.failureStock = payload.error;
      state.inventory.productsByStore =
        payload.products || state.inventory.productsByStore;
      return state;
    case Types.PRODUCT_ADD_OR_REMOVE:
      state.inventory.movingToStore.loading = true;
      state.inventory.movingToStore.error = false;
      return state;
    case Types.PRODUCT_ADD_OR_REMOVE_CONTROL:
      state.inventory.movingToStore.loading = payload.loading;
      state.inventory.movingToStore.error = payload.error;
      return state;
    case Types.ADD_OR_REMOVE_PRODUCTS_OK:
      state.inventory.selectedProduct = null;
      state.inventory.quantity = 0;
      state.inventory.updating = false;
      return state;
    case Types.RESET_INVENTORY:
      state.searchProducts = initialState.searchProducts;
      state.inventory = initialState.inventory;
      state.searchStore = initialState.searchStore;
      return state;
    default:
      return state;
  }
});

export default lojas;

export const LojasActions = {
  updateFields: (key, value) => ({
    type: Types.UPDATE_FIELDS,
    payload: { key, value },
  }),
  clearFields: () => ({
    type: Types.CLEAR_FIELDS,
  }),
  filter: () => ({
    type: Types.FILTER,
  }),
  filterSuccess: (list) => ({
    type: Types.FILTER_SUCCESS,
    payload: list,
  }),
  filterError: () => ({
    type: Types.FILTER_ERROR,
  }),
  searchStore: (search) => ({
    type: Types.REQUEST_STORES_BY_NAME,
    payload: search,
  }),
  searchStoreSuccess: (stores) => ({
    type: Types.CONTROL_STORES_BY_NAME,
    payload: { loading: false, error: false, stores },
  }),
  searchStoreFailure: () => ({
    type: Types.CONTROL_STORES_BY_NAME,
    payload: { loading: false, error: true },
  }),
  searchProductsByName: (search) => ({
    type: Types.REQUEST_PRODUCTS_BY_NAME,
    payload: search,
  }),
  searchProductsByNameSuccess: (products) => ({
    type: Types.CONTROL_PRODUCTS_BY_NAME,
    payload: { loading: false, error: false, products },
  }),
  searchProductsByNameFailure: () => ({
    type: Types.CONTROL_PRODUCTS_BY_NAME,
    payload: { loading: false, error: true },
  }),
  updateFieldsInventory: (key, value) => ({
    type: Types.INVENTORY_UPDATE_FIELDS,
    payload: { key, value },
  }),
  getProductsByStore: () => ({
    type: Types.REQUEST_PRODUCTS_BY_STOKE_STORE,
  }),
  getProductsByStoreSuccess: (products) => ({
    type: Types.CONTROL_PRODUCTS_BY_STOKE_STORE,
    payload: { loading: false, error: false, products },
  }),
  getProductsByStoreFailure: () => ({
    type: Types.INVENTORY_UPDATE_FIELDS,
    payload: { loading: false, error: true },
  }),
  moveToStore: () => ({
    type: Types.PRODUCT_ADD_OR_REMOVE,
  }),
  moveToStoreSuccess: () => ({
    type: Types.PRODUCT_ADD_OR_REMOVE_CONTROL,
    payload: { loading: false, error: false },
  }),
  moveToStoreFailure: () => ({
    type: Types.PRODUCT_ADD_OR_REMOVE_CONTROL,
    payload: { loading: false, error: true },
  }),
  moveProducts: () => ({
    type: Types.ADD_OR_REMOVE_PRODUCTS_OK,
  }),
  resetInventory: () => ({
    type: Types.RESET_INVENTORY,
  }),
};
