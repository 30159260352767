import produce from 'immer';
import { notificationTypes } from 'components/notification';

export const Types = {
  SHOW: 'notification@SHOW',
  DISMISS: 'notification@DISMISS',
};

const initialState = {
  show: false,
  type: '',
  message: '',
};

const notification = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SHOW:
      state.show = true;
      state.type = payload.type;
      state.message = payload.message;
      return state;
    case Types.DISMISS:
      return initialState;
    default:
      return state;
  }
});

export default notification;

export const NotificationActions = {
  success: (message) => ({
    type: Types.SHOW,
    payload: { message, type: notificationTypes.success },
  }),
  error: (message) => ({
    type: Types.SHOW,
    payload: { message, type: notificationTypes.error },
  }),
  warning: (message) => ({
    type: Types.SHOW,
    payload: { message, type: notificationTypes.warning },
  }),
  info: (message) => ({
    type: Types.SHOW,
    payload: { message, type: notificationTypes.info },
  }),
  dismiss: () => ({
    type: Types.DISMISS,
  }),
};
