import React, { useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import PropTypes from 'prop-types';
import './styles.css';
import { FormHelperText, makeStyles, Typography } from '@material-ui/core';
import If from '../if/If';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
}));

const config = {
  toolbar: [
    'heading',
    'alignment',
    'fontsize',
    'fontcolor',
    'fontbackgroundcolor',
    'bold',
    'italic',
    'strikethrough',
    'underline',
    'indent',
    'outdent',
    'numberedlist',
    'bulletedlist',
    'undo',
    'redo',
  ],
  fontSize: {
    options: [9, 10, 11, 12, 14, 16, 18, 20, 25, 30, 35],
    supportAllValues: true,
  },
  fontColor: {
    colors: [
      {
        color: '#F32A56',
        label: 'Wessex',
      },
      {
        color: '#CB2946',
        label: 'Wessex Dark',
      },
      {
        color: '#8B2259',
        label: 'Wessex Secondary',
      },
      {
        color: 'hsl(0, 0%, 0%)',
        label: 'Black',
      },
      {
        color: 'hsl(0, 0%, 30%)',
        label: 'Dim grey',
      },
      {
        color: 'hsl(0, 0%, 60%)',
        label: 'Grey',
      },
      {
        color: 'hsl(0, 0%, 100%)',
        label: 'White',
        hasBorder: true,
      },
      {
        color: 'hsl(0, 75%, 60%)',
        label: 'Red',
      },
      {
        color: 'hsl(30, 75%, 60%)',
        label: 'Orange',
      },
      {
        color: 'hsl(60, 75%, 60%)',
        label: 'Yellow',
      },
      {
        color: 'hsl(120, 75%, 60%)',
        label: 'Green',
      },
      {
        color: 'hsl(150, 75%, 60%)',
        label: 'Aquamarine',
      },
      {
        color: 'hsl(210, 75%, 60%)',
        label: 'Light blue',
      },
      {
        color: 'hsl(240, 75%, 60%)',
        label: 'Blue',
      },
      {
        color: 'hsl(270, 75%, 60%)',
        label: 'Purple',
      },
    ],
  },
  placeholder: 'Insira a descrição do produto',
  heading: {
    options: [
      { model: 'paragraph', title: 'Parágrafo', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Título 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Título 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Título 3',
        class: 'ck-heading_heading3',
      },
    ],
  },
};

const RickEditor = ({
  field,
  value = '',
  onChange,
  placeholder = '',
  error = '',
  disabled = false,
}) => {
  const editorRef = useRef(null);
  const classes = useStyles();

  return (
    <div className="container">
      <CKEditor
        onReady={(editor) => {
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
            );

          editorRef.current = editor;
        }}
        onError={({ willEditorRestart }) => {
          if (willEditorRestart) {
            editorRef.current.ui.view.toolbar.element.remove();
          }
        }}
        onChange={(_, editor) => onChange(field, editor?.getData() || '')}
        editor={DecoupledEditor}
        data={value || ''}
        config={{ ...config, placeholder }}
        disabled={disabled}
      />
      <If condition={!!error}>
        <FormHelperText>
          <Typography className={classes.error}>{error}</Typography>
        </FormHelperText>
      </If>
    </div>
  );
};

RickEditor.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

RickEditor.defaultProps = {
  placeholder: '',
  error: '',
  disabled: false,
};

export default RickEditor;
