import { Grid } from '@material-ui/core';
import ButtonBack from 'components/button-back';
import Main from 'components/main-container';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useOrderDetails } from 'context/orders';
import Table from 'components/pagination-table';
import { AddMasks } from 'utils/Masks';
import { useParams } from 'react-router-dom';
import If from 'components/if/If';
import Expandable from 'components/expandable';
import TextInfo from 'components/text-info';
import { Button } from 'components/button';
import { Close } from '@material-ui/icons';
import { StatusSale } from 'utils/enum/order.enum';
import ReasonCancellation from 'pages/orders/components/ReasonCancellation';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ducks/notification';
import { OrdersApi } from 'services/api/OrdersApi';

const OrdersItems = () => {
  const dispatch = useDispatch();
  const { order, getDetailsOrder, getOrderItems, items, resetDetails } =
    useOrderDetails();
  const { orderId } = useParams<{ orderId: string }>();
  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    getDetailsOrder(orderId);
    getOrderItems(orderId);
    return () => resetDetails();
  }, [getDetailsOrder, getOrderItems, resetDetails, orderId]);

  const requestCancel = useCallback(
    async (reason: string) => {
      dispatch(NotificationActions.info('Cancelando compra'));
      try {
        await OrdersApi.cancelOrder(orderId, reason);
        getDetailsOrder(orderId);
        dispatch(NotificationActions.success('Pedido cancelado'));
      } catch (error: any) {
        dispatch(
          NotificationActions.error(
            error?.response?.data || 'Ocorreu um erro ao cancelar o pedido'
          )
        );
      }
    },
    [dispatch, getDetailsOrder, orderId]
  );

  const showCancelButton = useMemo(() => {
    if (!order) return false;
    return ![
      StatusSale.RequestCancellation,
      StatusSale.Canceled,
      StatusSale.Delivered,
    ].includes(order.currentStatus.status.constant);
  }, [order]);

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ButtonBack />
          <If condition={showCancelButton}>
            <Button
              icon={Close}
              onClick={() => setShowCancel(true)}
              title="Cancelar"
              colored
            />
          </If>
        </Grid>
        <If condition={!!order}>
          <Grid item sm={12} xs={12}>
            <Expandable title="Informações de Pedido">
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextInfo label="Nº Pedido" value={order?.order_id} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Data do Pedido"
                    value={AddMasks.dateHour(order?.created_at)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Tipo de pagamento"
                    value={order?.typePayment?.description}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Status do Pedido"
                    value={order?.currentStatus?.status?.description}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo label="Parcelas" value={order?.installment} />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Nº Stand"
                    value={order?.store?.storeProduct?.store?.store_id}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Stand"
                    value={order?.store?.storeProduct?.store?.fantasy_name}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Telefone para Contato"
                    value={AddMasks.phone(
                      order?.store?.storeProduct?.store?.phone
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Email para contato"
                    value={order?.store?.storeProduct?.store?.email}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Total em produtos"
                    value={AddMasks.money(order?.totals?.total_sale_value)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Total em descontos"
                    value={AddMasks.money(order?.totals?.total_discount)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label="Subtotal"
                    value={AddMasks.money(order?.totals?.total)}
                  />
                </Grid>
              </Grid>
            </Expandable>
          </Grid>
        </If>
        <Grid item xs={12} sm={12}>
          <If condition={!!items}>
            <Table
              labels={[
                'Produto',
                'Marca',
                'Quantidade',
                'Preço por unidade',
                'Desconto por unidade',
                'Valor Total',
              ]}
              values={items || []}
              fields={(item) => [
                item.storeProduct.product.short_name,
                item.brand,
                item.commercial_quantity.toString(),
                AddMasks.money(item.taxable_unit_value),
                AddMasks.money(item.discount_unit_value),
                AddMasks.money(item.subtotal),
              ]}
            />
          </If>
        </Grid>
      </Grid>
      <ReasonCancellation
        show={showCancel}
        toogleModal={() => setShowCancel(!showCancel)}
        onSubmit={requestCancel}
      />
    </Main>
  );
};

export default OrdersItems;
