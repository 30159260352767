import { Grid, makeStyles } from '@material-ui/core';
import {
  Apartment,
  ArrowForwardIos,
  AttachMoney,
  Close,
  Search,
  ShoppingBasket,
} from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import React, { useState } from 'react';
import Masks from 'utils/Masks';
import Selecionar from 'components/select';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';
import { useStores } from 'utils';
import queryCreator from 'utils/queryCreator';
import Table from 'components/pagination-table';
import { InputChange } from 'utils/types/input';
import { Product } from 'utils/types/product';
import { observer } from 'mobx-react';

const defaultFilter = {
  description: '',
  brand: '',
  gtin_ean_code: '',
  status: '',
};

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

function Produtos() {
  const [filter, setFilter] = useState(defaultFilter);
  const [filtered, setFiltered] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { product } = useStores();
  const classes = useStyles();
  const history = useHistory();

  const newProduct = () => {
    history.push(RouterNames.ADMIN_PRODUTOS_NOVO);
  };

  const goToProviders = () => {
    history.push(RouterNames.PROVIDERS);
  };

  const updateProduto = (produto: Product) => {
    history.push(RouterNames.ADMIN_PRODUTOS_EDITAR, { produto });
  };

  const promotions = () => {
    history.push(RouterNames.ADMIN_PRODUTOS_PROMOCOES);
  };

  const onChange = ({ id, value }: InputChange) => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      [id]: value,
    }));
  };

  const clear = () => {
    setFilter(defaultFilter);
  };

  const onFilter = async () => {
    setLoading(true);
    setError(false);
    try {
      const { description, brand, gtin_ean_code, status } = filter;

      const queries = [];
      if (description.trim()) {
        queries.push(queryCreator.like('description', description));
      }
      if (brand.trim()) {
        queries.push(queryCreator.like('brand', brand));
      }
      if (gtin_ean_code.trim()) {
        queries.push(queryCreator.like('gtin_ean_code', gtin_ean_code));
      }
      if (status !== '') {
        queries.push(queryCreator.bool('active', status));
      }
      setFiltered(queries);
      const filterParams = queryCreator.formatter({ queries, page: 1 });
      await product.getProducts(filterParams);
    } catch (errorResponse) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onChangePage = async (page: number) => {
    setLoading(true);
    setError(false);
    try {
      const filterParams = queryCreator.formatter({
        queries: filtered,
        page,
      });
      await product.getProducts(filterParams);
    } catch (errorResponse) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={ShoppingBasket}
            onClick={newProduct}
            title={Texts.produto.buttons.cadastrarProduto}
            colored
          />
        </Grid>
        <Grid item>
          <Button
            icon={Apartment}
            onClick={goToProviders}
            title={Texts.produto.buttons.fornecedores}
            colored
          />
        </Grid>
        <Grid item>
          <Button
            icon={AttachMoney}
            onClick={promotions}
            title="Promoções"
            colored
          />
        </Grid>
        <Grid item xs={12}>
          <Title>{Texts.produto.sessions.buscarProdutos}</Title>
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextInput
            label={Texts.produto.fields.descricao}
            id="description"
            onChange={(event) => {
              onChange({ id: 'description', value: event.target.value });
            }}
            value={filter.description}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextInput
            label={Texts.produto.fields.gtin}
            id="gtin_ean_code"
            mask={Masks.gtin}
            onChange={(event) => {
              onChange({ id: 'gtin_ean_code', value: event.target.value });
            }}
            value={filter.gtin_ean_code}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextInput
            label={Texts.produto.fields.marca}
            id="brand"
            onChange={(event) => {
              onChange({ id: 'brand', value: event.target.value });
            }}
            value={filter.brand}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Selecionar
            label={Texts.produto.fields.status}
            id="status"
            onChange={onChange}
            options={[
              { id: 1, name: 'Ativo' },
              { id: 0, name: 'Inativo' },
            ]}
            value={filter.status}
          />
        </Grid>
        <Grid item>
          <div>
            <Button
              icon={Close}
              onClick={clear}
              style={classes.button}
              title={Texts.usuarios.buttons.limpar}
            />
            <Button
              icon={Search}
              onClick={onFilter}
              style={classes.button}
              title={Texts.usuarios.buttons.pesquisar}
              colored
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={['Descrição', 'Estoque', 'Unidade']}
            values={product.products.data}
            fields={(productItem) => [
              productItem.description,
              productItem.stock,
              productItem.type_unit,
            ]}
            loading={loading}
            error={error}
            hasAction
            action={updateProduto}
            actionLabel="Detalhes"
            Icon={ArrowForwardIos}
            pagination={parseInt(product.products.total) > 0}
            page={product.products.page}
            total={parseInt(product.products.total)}
            perPage={product.products.perPage}
            onChangePage={onChangePage}
          />
        </Grid>
      </Grid>
    </Main>
  );
}

export default observer(Produtos);
