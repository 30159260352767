import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Card from 'components/card';
import Text from 'components/text';
import { Title } from 'components/title/Title';
import { useReports } from 'context/reports';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from 'components/button';
import If from 'components/if/If';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';
import { RootState } from 'store/ducks/reducers';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridMessage: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    message: {
      textAlign: 'center',
      fontSize: 16,
    },
    gridButton: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-end',
    },
    quantity: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 18,
    },
  })
);

function OpenOrdersShopkeeper() {
  const history = useHistory();
  const classes = useStyles();
  const { getOpenOrders } = useReports();
  const [openOrders, setOpenOrders] = useState(0);
  const { actualStore } = useSelector((state: RootState) => state.userStores);

  const requestOpenOrders = useCallback(async () => {
    const quantity = await getOpenOrders(actualStore.store_id);
    setOpenOrders(quantity);
  }, [actualStore, getOpenOrders]);

  useEffect(() => {
    if (actualStore) requestOpenOrders();
  }, [requestOpenOrders, actualStore]);

  const openOrdersPdv = useCallback(() => {
    history.push(RouterNames.PDV_ORDERS);
  }, [history]);

  const hasOpenOrders = openOrders > 0;

  const message = hasOpenOrders ? `` : 'Sem pedidos em aberto';

  return (
    <>
      <Title>Novos Pedidos</Title>
      <Card>
        <Grid container>
          <Grid item xs={12} sm={9} className={classes.gridMessage}>
            <If condition={hasOpenOrders}>
              <Text style={classes.message}>
                Existem
                <span className={classes.quantity}>{` ${openOrders} `}</span>
                novos pedidos em seu Stand
              </Text>
            </If>
            <If condition={!hasOpenOrders}>
              <Text style={classes.message}>{message}</Text>
            </If>
          </Grid>
          <Grid item className={classes.gridButton} xs={12} sm={3}>
            <Button
              title="Ver Pedidos"
              border={false}
              onClick={openOrdersPdv}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default OpenOrdersShopkeeper;
