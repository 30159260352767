import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ResetPasswordActions } from 'store/ducks/reset-password';
import { Texts } from 'constants/strings';
import Text from 'components/text';
import { ColoredButton } from 'components/button';
import { makeStyles } from '@material-ui/core';
import { ColoredInput } from 'components/text-input';
import { Modal } from 'components/modal/Modal';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '30px 60px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 30px',
    },
  },
  input: {
    width: '100%',
    marginBottom: '20px',
  },
  subtitle: {
    marginTop: '20px',
    textAlign: 'center',
  },
}));

export default function InsertEmail() {
  const { showModalInsertEmail, email, sendingEmail, errorSendingEmail } =
    useSelector((state) => state.resetPassword);
  const dispatch = useDispatch();
  const classes = useStyles();

  const sendEmail = () => {
    dispatch(ResetPasswordActions.sendEmail());
  };

  const changeEmail = (value) => {
    dispatch(ResetPasswordActions.changeEmail(value));
  };

  const close = () => {
    dispatch(ResetPasswordActions.closeModais());
  };

  return (
    <div>
      <Modal
        show={showModalInsertEmail}
        onClose={close}
        title={Texts.recuperarSenha.titleInsertEmail}
      >
        <Text style={classes.subtitle}>
          {Texts.recuperarSenha.infoInserEmail}
        </Text>
        <ColoredInput
          error={errorSendingEmail}
          formStyle={classes.input}
          label={Texts.recuperarSenha.labelEmail}
          onChange={changeEmail}
          value={email}
        />
        <ColoredButton
          loading={sendingEmail}
          title={Texts.recuperarSenha.buttonEnviar}
          onClick={sendEmail}
        />
      </Modal>
    </div>
  );
}
