import Uris from 'constants/endpoints';
import api from 'services/config/api';
import { ResponsePagination, SimpleResponse } from 'utils/types/response';
import { Order, OrderItem } from 'utils/types/order';

export const OrdersApi = {
  getAllOrders: (query: string) =>
    api.get<ResponsePagination<Order>>(`${Uris.getAllOrders}${query}`),
  getAdminOrderDetails: (orderId: string | number) =>
    api.get<SimpleResponse<Order>>(
      Uris.getDetailsFromOrder.replace('{orderId}', orderId.toString())
    ),
  getAdminOrderItems: (orderId: string | number) =>
    api.get<Array<OrderItem>>(
      Uris.getItemsFromOrder.replace('{orderId}', orderId.toString())
    ),
  cancelOrder: (orderId: string | number | undefined, reason: string = '') =>
    api({
      url: `/admin/orders/${orderId}/cancel`,
      method: 'DELETE',
      data: { reason },
    }),
};
