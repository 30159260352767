export default {
  findCNPJ: 'CNPJ inválido ou não encontrado',
  findCNPJAlready: 'CNPJ já adicionado',
  findCNPJLenght: 'CNPJ Incompleto',
  tableEmpty: 'Sem resultados',
  tableError: 'Ocorreu um erro, tente novamente',
  userErrorChangeStatus: 'Ocorreu um erro ao ativar/desativar usuário',
  findCep: 'CEP inválido ou não encontrado',
  createStore: 'Ocorreu um erro ao criar a loja',
  udpateStore: 'Ocorreu um erro ao atualizar a loja',
  inactiveStore: 'Ocorreu um erro ao inativar a loja',
  login: 'Ocorreu uma falha ao tentar efetuar o login',
  moveProductToStore: 'Ocorreu um erro ao adicionar o produto na Loja',
  removeProductToStore: 'Ocorreu um erro ao remover o produto na Loja',
};
