import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  makeStyles,
  OutlinedInput,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  form: {
    marginBottom: '2vh',
  },
  label: {
    color: theme.palette.common.white,
    '&$focused': {
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
  focused: {},
  icon: {
    color: theme.palette.common.white,
  },
  helperError: {
    color: theme.palette.common.white,
  },
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main + 40,
    fontFamily: theme.typography.fontFamily,
    '& $notchedOutline': {
      borderColor: theme.palette.common.white,
      borderWidth: '1px',
      borderRadius: theme.shape.borderRadius,
      opacity: '100%',
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.common.white,
      borderWidth: '1px',
      opacity: '100%',
      borderRadius: theme.shape.borderRadius,
    },
    '&$focused $notchedOutline': {
      borderColor: theme.palette.common.white,
      borderWidth: '2px',
      borderRadius: theme.shape.borderRadius,
      opacity: '100%',
    },
  },
  error: {},
  focused: {},
  notchedOutline: {},
}));

function Input({ label, value, onChange, isPass, error }) {
  const [visible, setVisible] = useState(true);
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  const passwordIcon = () => (
    <IconButton onClick={() => setVisible(!visible)} edge="end">
      {visible ? (
        <VisibilityOff className={classes.icon} />
      ) : (
        <Visibility className={classes.icon} />
      )}
    </IconButton>
  );

  return (
    <FormControl variant="outlined" className={classes.form}>
      <InputLabel
        classes={{
          root: classes.label,
          focused: classes.focused,
        }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        classes={outlinedInputClasses}
        type={isPass && visible ? 'password' : 'text'}
        value={value}
        error={error}
        onChange={(e) => onChange(e.target.value)}
        endAdornment={isPass ? passwordIcon() : null}
        labelWidth={70}
      />
      {error && (
        <FormHelperText className={classes.helperError}>{error}</FormHelperText>
      )}
    </FormControl>
  );
}

Input.propTypes = {
  error: PropTypes.string,
  isPass: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

Input.defaultProps = {
  error: '',
  isPass: false,
  label: '',
  onChange: () => {},
  value: '',
};

export default Input;
