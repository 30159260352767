import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Title } from 'components/title/Title';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {},
  title: {
    color: theme.palette.primary.dark,
    textAlign: 'right',
    marginRight: theme.spacing(4),
  },
}));

function Total() {
  const classes = useStyles();
  const { order } = useSelector((state) => state.orderDetails);

  let total = '';

  if (order) {
    total = order.totals.total;
  }

  return (
    <Grid item xs={12} sm={12}>
      <Title style={classes.title}>{`Total ${total}`}</Title>
    </Grid>
  );
}

export default Total;
