import React from 'react';
import { Grid } from '@material-ui/core';
import { Title } from 'components/title/Title';
import Text from 'components/text';
import { Texts } from 'constants/strings';
import { useDispatch, useSelector } from 'react-redux';
import { NovoUsuarioActions } from 'store/ducks/novo-usuario';
import StoreSelector from 'components/store-selector';

function Lojas() {
  const { stores, disableFields } = useSelector((state) => state.novoUsuario);
  const dispatch = useDispatch();

  const changeStore = ({ value }) => {
    dispatch(NovoUsuarioActions.searchLojaSuccess(value));
  };

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.usuarios.sessions.lojas}</Title>
      </Grid>
      <Grid item>
        <Text>{Texts.usuarios.texts.lojas}</Text>
      </Grid>
      <Grid item xs={12} sm={12}>
        <StoreSelector
          label={Texts.usuarios.inputLabels.cnpj}
          value={stores}
          onChange={changeStore}
          id="store"
          multiple
          disabled={disableFields}
        />
      </Grid>
    </>
  );
}

export default Lojas;
