import { Button as ButtonBase, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/loading';
import theme from 'styles/theme';

const useStyles = makeStyles(() => ({
  root: {
    border: `1px solid ${theme.palette.common.white}`,
    width: '180px',
    marginTop: '10px',
    height: '56px',
    textTransform: 'none',
    fontSize: '16px',
    background:
      'linear-gradient(160deg, rgba(243,42,86,1) 40%, rgba(139,34,89,1) 100%)',
  },
}));

function ColoredButton({ onClick, title, loading }) {
  const classes = useStyles();
  return (
    <ButtonBase
      onClick={onClick}
      className={classes.root}
      variant="contained"
      disabled={loading}
      color="primary"
    >
      {!loading && title}
      {loading && <Loading color={theme.palette.common.white} />}
    </ButtonBase>
  );
}

ColoredButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
};

ColoredButton.defaultProps = {
  onClick: () => {},
  title: '',
  loading: false,
};

export { ColoredButton };
