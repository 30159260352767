import RouterNames from 'constants/router-names';
import { Texts } from 'constants/strings';
import produce from 'immer';

export const Types = {
  SELECTED: 'layout@SELECTED',
  OPENDRAWER: 'layout@OPENDRAWER',
  LOADING: 'layout@LOADING',
  LOADING_BUTTON: 'layout@LOADING_BUTTON',
};

const initialState = {
  drawerOpen: false,
  toolbarTitle: Texts.pages.dashboard,
  selectedPathMenu: RouterNames.MAIN,
  showLoading: false, // controla o loading geral (ocupa tela inteira)
  showLoadingButton: false, // controla botão cancelar que aparecerá depois de um timeout
};

const layout = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SELECTED:
      state.selectedPathMenu = payload.selectedPathMenu;
      state.toolbarTitle = payload.toolbarTitle;
      state.drawerOpen = false;
      return state;
    case Types.OPENDRAWER:
      state.drawerOpen = payload;
      return state;
    case Types.LOADING:
      state.showLoading = payload;
      state.showLoadingButton = false;
      return state;
    case Types.LOADING_BUTTON:
      state.showLoadingButton = true;
      return state;
    default:
      return state;
  }
});

export default layout;

export const LayoutActions = {
  selected: (toolbarTitle, selectedPathMenu) => ({
    type: Types.SELECTED,
    payload: { toolbarTitle, selectedPathMenu },
  }),
  handleDrawer: (open) => ({
    type: Types.OPENDRAWER,
    payload: open,
  }),
  showLoading: () => ({
    type: Types.LOADING,
    payload: true,
  }),
  dismissLoading: () => ({
    type: Types.LOADING,
    payload: false,
  }),
  showLoadingButton: () => ({
    type: Types.LOADING_BUTTON,
  }),
};
