import Uris from 'constants/endpoints';
import api from 'services/config/api';

export const OrderApi = {
  getAllOrders: (query) => api.get(`${Uris.getAllOrders}${query}`),
  getOrders: (storeId, query) => {
    let url = `${Uris.listOrdersByStoreShopkeeper.replace(
      '{idStore}',
      storeId
    )}`;
    url = `${url}${query}`;
    return api.get(url);
  },
  getDetails: (storeId, transactionId) => {
    let url = `${Uris.getOrderDetails.replace('{storeId}', storeId)}`;
    url = `${url.replace('{orderId}', transactionId)}`;
    return api.get(url);
  },
  cancelOrder: (orderId) => {
    const url = `${Uris.cancelOrder.replace('{orderId}', orderId)}`;
    // return api.delete(url, { reason: 'Devolução' });
    return api({
      url,
      method: 'DELETE',
      data: { reason: 'Devolução dfdsf fdfd' },
    });
  },
  getOrdertems: (storeId, transactionId) => {
    let url = `${Uris.getOrdertems.replace('{storeId}', storeId)}`;
    url = `${url.replace('{orderId}', transactionId)}`;
    return api.get(url);
  },
  confirmOrder: (storeId, orderId) => {
    let url = `${Uris.confirmOrder.replace('{storeId}', storeId)}`;
    url = `${url.replace('{orderId}', orderId)}`;
    return api.post(url);
  },
  shopkeeperRequestCancellation: (storeId, orderId, reason) => {
    const url = `${Uris.shopkeeperRequestCancel
      .replace('{storeId}', storeId)
      .replace('{orderId}', orderId)}`;
    return api({
      url,
      method: 'DELETE',
      data: { reason },
    });
  },
};
