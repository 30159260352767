import { Grid, makeStyles } from '@material-ui/core';
import { Search, Close, PersonAdd } from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import Tabela from 'components/table';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { Creators as UsuariosActions } from 'store/ducks/usuarios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Selecionar } from 'components/select/Selecionar';
import RouterNames from 'constants/router-names';
import Masks from 'utils/Masks';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

function Usuarios() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    filterName,
    filterCpf,
    filterStatus,
    filterTipoUser,
    filterList,
    filterCNPJ,
    listLoading,
    listError,
  } = useSelector((state) => state.usuarios);

  const getUsersByFilter = () => {
    dispatch(UsuariosActions.listRequest());
  };

  const createNewUser = () => {
    history.push(RouterNames.ADMIN_USUARIOS_CADASTRO);
  };

  const editUser = (selectedUser) => {
    history.push(RouterNames.ADMIN_USUARIOS_EDITAR, { user: selectedUser });
  };

  const changeText = (event) => {
    dispatch(UsuariosActions.updateFields(event.target.id, event.target.value));
  };

  const selected = ({ id, value }) => {
    dispatch(UsuariosActions.updateFields(id, value));
  };

  const clear = () => {
    dispatch(UsuariosActions.clearFilter());
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={PersonAdd}
            onClick={createNewUser}
            title={Texts.usuarios.buttons.callNewUser}
            colored
          />
        </Grid>
        <Grid item xs={12}>
          <Title>{Texts.usuarios.sessions.buscarUsuarios}</Title>
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextInput
            label={Texts.usuarios.inputLabels.filterName}
            id="filterName"
            onChange={changeText}
            value={filterName}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextInput
            label={Texts.usuarios.inputLabels.cpf}
            id="filterCpf"
            mask={Masks.cpf}
            onChange={changeText}
            value={filterCpf}
          />
        </Grid>
        <Grid item xs={8} sm={4}>
          <TextInput
            label={Texts.usuarios.inputLabels.cnpj}
            id="filterCNPJ"
            mask={Masks.cnpj}
            onChange={changeText}
            value={filterCNPJ}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Selecionar
            label={Texts.usuarios.inputLabels.filterStatus}
            id="filterStatus"
            onChange={selected}
            options={[
              { id: 1, name: 'Ativo' },
              { id: 0, name: 'Inativo' },
            ]}
            value={filterStatus}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <Selecionar
            label={Texts.usuarios.inputLabels.filterTipoUser}
            id="filterTipoUser"
            onChange={selected}
            options={[
              { id: 0, name: 'Admin' }, // no caso será verificado no filtro de o usuário
              { id: 1, name: 'Lojista' }, // tem o atr shopkeeper true, se sim é lojista
            ]}
            value={filterTipoUser}
          />
        </Grid>
        <Grid item>
          <div className={classes.buttonContainer}>
            <Button
              icon={Close}
              onClick={clear}
              style={classes.button}
              title={Texts.usuarios.buttons.limpar}
            />
            <Button
              icon={Search}
              onClick={getUsersByFilter}
              style={classes.button}
              title={Texts.usuarios.buttons.pesquisar}
              colored
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Tabela
            error={listError}
            loading={listLoading}
            titles={['Nome', 'Status', 'Tipo']}
            values={filterList}
            fields={['name', 'activeName', 'shopkeeperName']}
            hasAction
            action={editUser}
          />
        </Grid>
      </Grid>
    </Main>
  );
}

export { Usuarios };
