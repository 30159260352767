import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Expandable from 'components/expandable';
import { FiscalActions } from 'store/ducks/fiscal';
import Produto from './Produto';

function Produtos() {
  const dispatch = useDispatch();
  const { items } = useSelector((state) => {
    return state.fiscal.nfe.new.data;
  });

  useEffect(() => {
    dispatch(FiscalActions.searchProducts(''));
  }, [dispatch]);

  return (
    <Expandable title="Itens">
      <Grid container spacing={2}>
        {items.map((item, index) => (
          <Grid item sm={12} xs={12}>
            <Produto item={item} index={index + 1} />
          </Grid>
        ))}
      </Grid>
    </Expandable>
  );
}

export default Produtos;
