export const Permissions = {
  // DASHBOARD

  // COMPANY
  PERMISSION_READ_COMPANY: 'PERMISSION_READ_COMPANY',
  PERMISSION_UPDATE_COMPANY: 'PERMISSION_UPDATE_COMPANY',
  // USERS
  PERMISSION_READ_USERS: 'PERMISSION_READ_USERS',
  PERMISSION_CREATE_USERS: 'PERMISSION_CREATE_USERS',
  PERMISSION_UPDATE_USERS: 'PERMISSION_UPDATE_USERS',
  // PRODUCTS
  PERMISSION_READ_PRODUCTS: 'PERMISSION_READ_PRODUCTS',
  PERMISSION_CREATE_PRODUCTS: 'PERMISSION_CREATE_PRODUCTS',
  PERMISSION_UPDATE_PRODUCTS: 'PERMISSION_UPDATE_PRODUCTS',
  // STORE
  PERMISSION_READ_STORE: 'PERMISSION_READ_STORE',
  PERMISSION_CREATE_STORE: 'PERMISSION_CREATE_STORE',
  PERMISSION_UPDATE_STORE: 'PERMISSION_UPDATE_STORE',
  // ORDERS
  PERMISSION_READ_ORDER: 'PERMISSION_READ_ORDER',
  PERMISSION_CREATE_ORDER: 'PERMISSION_CREATE_ORDER',
  PERMISSION_UPDATE_ORDER: 'PERMISSION_UPDATE_ORDER',
  PERMISSION_CANCEL_ORDER: 'PERMISSION_CANCEL_ORDER',
  // MINI-STORES
  PERMISSION_READ_MINI_STORES: 'PERMISSION_READ_MINI_STORES',
  PERMISSION_CREATE_MINI_STORES: 'PERMISSION_CREATE_MINI_STORES',
  PERMISSION_UPDATE_MINI_STORES: 'PERMISSION_UPDATE_MINI_STORES',
  // FISCAL-NOTES

  // UPDATES

  // REPORTS
};
