import { Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { TextInput } from 'components/text-input';
import { useDispatch, useSelector } from 'react-redux';
import { NovaLojaActions } from 'store/ducks/nova-loja';
import Masks from 'utils/Masks';
import LegalNatureSelector from 'components/legal-nature-selector';
import If from 'components/if/If';
import Selecionar from 'components/select';
import InputCpfCnpj from 'components/input-cpf-cnpj';

const storeType = [
  {
    id: 1,
    name: 'CPF',
  },
  {
    id: 2,
    name: 'CNPJ',
  },
];

function DadosGerais({ updateMode }) {
  const {
    cpf_cnpj,
    cpf_cnpjErr,
    social_reason,
    social_reasonErr,
    fantasy_name,
    fantasy_nameErr,
    legal_natureErr,
    email,
    emailErr,
    legalNature,
    phone,
    phoneErr,
    manager,
    managerErr,
    disableFields,
    commissionErr,
    commission,
  } = useSelector((state) => state.novaLoja);
  const dispatch = useDispatch();
  const [typeSelect, setTypeSelect] = useState(storeType[1].id);

  const changeText = (event) => {
    dispatch(NovaLojaActions.updateFields(event.target.id, event.target.value));
  };

  const onSelect = ({ id, value }) => {
    dispatch(NovaLojaActions.updateFields(id, value));
  };

  const isCnpj = useMemo(() => {
    if (typeSelect === 1) {
      if (cpf_cnpj?.length > 14) {
        dispatch(
          NovaLojaActions.updateFields('cpf_cnpj', cpf_cnpj?.substring(0, 14))
        );
      }
      return false;
    }
    return true;
  }, [typeSelect, cpf_cnpj, dispatch]);

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.novaLoja.sessions.gerais}</Title>
      </Grid>
      {
        // Razão Social
      }
      <If condition={!updateMode}>
        <Grid item xs={12} sm={7}>
          <Selecionar
            label="Tipo da loja"
            onChange={(value) => {
              setTypeSelect(value.value);
            }}
            id="tipo_loja"
            options={storeType}
            value={typeSelect}
            allowEmpty={false}
          />
        </Grid>
      </If>
      <If condition={isCnpj}>
        <Grid item xs={12} sm={12}>
          <TextInput
            required
            disabled={disableFields}
            label={Texts.novaLoja.fields.razaoSocial}
            id="social_reason"
            onChange={changeText}
            value={social_reason}
            error={social_reasonErr}
          />
        </Grid>
      </If>
      {
        // Nome Fantasia - Natureza Juridica
      }
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          disabled={disableFields}
          label={isCnpj ? 'Nome fantasia' : 'Nome'}
          id="fantasy_name"
          onChange={changeText}
          value={fantasy_name}
          error={fantasy_nameErr}
        />
      </Grid>
      <If condition={isCnpj}>
        <Grid item xs={12} sm={6}>
          <LegalNatureSelector
            id="legalNature"
            onChange={onSelect}
            value={legalNature}
            disabled={disableFields}
            error={legal_natureErr}
          />
        </Grid>
      </If>
      {
        // :CNPJ - Nome Gerente
      }
      <Grid item xs={12} sm={6}>
        <InputCpfCnpj
          disabled={disableFields}
          onlyCnpj={isCnpj}
          onlyCpf={!isCnpj}
          required
          onChange={changeText}
          value={cpf_cnpj}
          error={cpf_cnpjErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled={disableFields}
          label={Texts.novaLoja.fields.nomeGerente}
          id="manager"
          onChange={changeText}
          value={manager}
          error={managerErr}
        />
      </Grid>
      {
        // Email Comercial - Telefone Comercial
      }
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled={disableFields}
          label={Texts.novaLoja.fields.email}
          id="email"
          required
          onChange={changeText}
          value={email}
          error={emailErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled={disableFields}
          label={Texts.novaLoja.fields.telefoneCom}
          id="phone"
          required
          mask={Masks.celular}
          onChange={changeText}
          value={phone}
          error={phoneErr}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextInput
          disabled={disableFields}
          label="% Comissão"
          id="commission"
          required
          number
          onChange={changeText}
          value={commission}
          error={commissionErr}
        />
      </Grid>
    </>
  );
}

export default DadosGerais;
