import React from 'react';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { Grid, makeStyles } from '@material-ui/core';
import Card from 'components/card';
import { useSelector } from 'react-redux';
import Info from './Info';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    minHeight: '20vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

function TransactionInfo() {
  const classes = useStyles();
  const { order } = useSelector((state) => state.orderDetails);

  let dataCompra = '';
  let status = '';
  let canal = '';

  if (order) {
    dataCompra = order.created_at;
    status = order.currentStatus.status.description;
    canal = 'Aplicativo';
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Title>{Texts.order.sessions.pedido}</Title>
        <Card style={classes.card}>
          <Info
            title={Texts.order.texts.dataCompra}
            value={dataCompra || ' - '}
          />
          <Info title={Texts.order.texts.canal} value={canal || ' - '} />
          <Info title={Texts.order.texts.status} value={status || ' - '} />
        </Card>
      </Grid>
    </>
  );
}

export default TransactionInfo;
