import React, { useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { useDispatch, useSelector } from 'react-redux';
import Masks from 'utils/Masks';
import { Texts } from 'constants/strings';
import { NovoProdutoActions } from 'store/ducks/novo-produto';
import LoadingFailure from 'components/loading-failure';
import { FiscalActions } from 'store/ducks/fiscal';
import SearchSelector from 'components/search-selector';
import { TypeUnitSelector } from 'components';

function Dados() {
  const {
    description,
    descriptionErr,
    brand,
    brandErr,
    type_unit,
    type_unitErr,
    gtin_ean_code,
    gtin_ean_codeErr,
    ncm,
    ncmErr,
    sale_value,
    sale_valueErr,
    cost_value,
    cost_valueErr,
    disableFields,
    cfop_id,
    cfop_idErr,
    pis_id,
    pis_idErr,
    cofins_id,
    cofins_idErr,
    icms_origin_id,
    icms_origin_idErr,
    icms_tax_situation_id,
    icms_tax_situation_idErr,
    // icms_calculation_base_mode_id,
    // icms_calculation_base_mode_idErr,
    // icms_aliquot,
    // icms_aliquotErr,
    // icms_calculation_base,
    // icms_calculation_baseErr,
    providers,
    providersErr,
    providersList,
  } = useSelector((state) => state.novoProduto);
  const {
    cfop,
    cfopLoading,
    cfopError,
    pis,
    pisLoading,
    pisError,
    cofins,
    cofinsLoading,
    cofinsError,
    icmsOrigin,
    icmsOriginLoading,
    icmsOriginError,
    // icmsCalculationBaseMode,
    // icmsCalculationBaseModeLoading,
    // icmsCalculationBaseModeError,
    icmsTaxSituation,
    icmsTaxSituationLoading,
    icmsTaxSituationError,
  } = useSelector((state) => state.fiscal);

  const dispatch = useDispatch();
  const changeText = (event) => {
    dispatch(NovoProdutoActions.field(event.target.id, event.target.value));
  };

  const selected = ({ id, value }) => {
    dispatch(NovoProdutoActions.field(id, value));
  };

  const getCommonLabel = (option) => {
    return option.value;
  };

  const getCfopFieldLabel = (option) => {
    return option.description || '';
  };

  const getProviderFieldLabel = (option) => {
    return option.exibition || '';
  };

  const onChangeCfopInput = (e) => {
    dispatch(FiscalActions.getCfopList(e.target.value));
  };

  const onChangeProviderInput = (e) => {
    dispatch(NovoProdutoActions.getProvidersList(e.target.value));
  };

  const getFiscalItems = useCallback(() => {
    dispatch(FiscalActions.getCfopList());
    dispatch(FiscalActions.getPis());
    dispatch(FiscalActions.getCofins());
    dispatch(FiscalActions.getIcmsTaxSituation());
    dispatch(FiscalActions.getIcmsCalculationBaseMode());
    dispatch(FiscalActions.getIcmsOrigin());
    dispatch(NovoProdutoActions.getProvidersList());
  }, [dispatch]);

  useEffect(() => {
    getFiscalItems();
  }, [getFiscalItems]);

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.novoProduto.sessions.dadosProduto}</Title>
      </Grid>
      {/* DESCRICAO - MARCA */}
      <Grid item xs={12} sm={7}>
        <TextInput
          disabled={disableFields}
          label={Texts.novoProduto.fields.descricao}
          id="description"
          onChange={changeText}
          value={description}
          error={descriptionErr}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextInput
          disabled={disableFields}
          label={Texts.novoProduto.fields.marca}
          id="brand"
          onChange={changeText}
          value={brand}
          error={brandErr}
        />
      </Grid>
      {/* UNIDADE - VALOR VENDA - VALOR CUSTO */}
      <Grid item xs={6} sm={4}>
        <TypeUnitSelector
          disabled={disableFields}
          value={type_unit}
          onChange={selected}
          id="type_unit"
          error={type_unitErr}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextInput
          disabled={disableFields}
          label={Texts.novoProduto.fields.venda}
          id="sale_value"
          money
          onChange={changeText}
          value={sale_value}
          error={sale_valueErr}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextInput
          disabled={disableFields}
          label={Texts.novoProduto.fields.custo}
          id="cost_value"
          money
          onChange={changeText}
          value={cost_value}
          error={cost_valueErr}
        />
      </Grid>
      <Grid item xs={8} sm={6}>
        <TextInput
          disabled={disableFields}
          label={Texts.novoProduto.fields.gtin}
          id="gtin_ean_code"
          mask={Masks.gtin}
          onChange={changeText}
          value={gtin_ean_code}
          error={gtin_ean_codeErr}
        />
      </Grid>
      <Grid item xs={8} sm={6}>
        <TextInput
          disabled={disableFields}
          label={Texts.novoProduto.fields.ncm}
          id="ncm"
          mask={Masks.ncm}
          onChange={changeText}
          value={ncm}
          error={ncmErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <LoadingFailure failure={cfopError} />
        {!cfopError && (
          <SearchSelector
            id="cfop_id"
            label={Texts.novoProduto.fields.cfop}
            onChangeInput={onChangeCfopInput}
            getLabel={getCfopFieldLabel}
            onChange={selected}
            options={cfop}
            loading={cfopLoading}
            error={cfop_idErr}
            value={cfop_id}
            optionCheckField="cfop_id"
            selectedCheckField="cfop_id"
            disabled={disableFields}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <LoadingFailure failure={pisError} loading={pisLoading} />
        {!pisError && !pisLoading && pis.length > 0 && (
          <SearchSelector
            id="pis_id"
            label={Texts.novoProduto.fields.pis}
            getLabel={getCommonLabel}
            onChange={selected}
            options={pis}
            loading={pisLoading}
            error={pis_idErr}
            value={pis_id}
            disabled={disableFields}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <LoadingFailure failure={cofinsError} loading={cofinsLoading} />
        {!cofinsError && !cofinsLoading && cofins.length >= 0 && (
          <SearchSelector
            id="cofins_id"
            label={Texts.novoProduto.fields.cofins}
            getLabel={getCommonLabel}
            onChange={selected}
            options={cofins}
            loading={cofinsLoading}
            error={cofins_idErr}
            value={cofins_id}
            disabled={disableFields}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12}>
        <Title variant="h6">ICMS</Title>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LoadingFailure failure={icmsOriginError} loading={icmsOriginLoading} />
        {!icmsOriginError && !icmsOriginLoading && (
          <SearchSelector
            label={Texts.novoProduto.fields.icms_origin}
            id="icms_origin_id"
            onChange={selected}
            options={icmsOrigin}
            value={icms_origin_id}
            error={icms_origin_idErr}
            disabled={disableFields}
            getLabel={getCommonLabel}
            loading={icmsOriginLoading}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <LoadingFailure
          failure={icmsTaxSituationError}
          loading={icmsTaxSituationLoading}
        />
        {!icmsTaxSituationError && !icmsTaxSituationLoading && (
          <SearchSelector
            label={Texts.novoProduto.fields.icms_tax_situation}
            id="icms_tax_situation_id"
            onChange={selected}
            options={icmsTaxSituation}
            value={icms_tax_situation_id}
            error={icms_tax_situation_idErr}
            disabled={disableFields}
            getLabel={getCommonLabel}
            loading={icmsTaxSituationLoading}
          />
        )}
      </Grid>
      {/* Modo de Base de Calculo - ICMS Situação de taxa */}
      {/* <Grid item xs={12} sm={6}>
        <LoadingFailure
          failure={icmsCalculationBaseModeError}
          loading={icmsCalculationBaseModeLoading}
        />
        {!icmsCalculationBaseModeError && !icmsCalculationBaseModeLoading && (
          <SearchSelector
            label={Texts.novoProduto.fields.icms_calculation_base_mode}
            id="icms_calculation_base_mode_id"
            getLabel={getCommonLabel}
            onChange={selected}
            loading={icmsCalculationBaseModeLoading}
            options={icmsCalculationBaseMode}
            value={icms_calculation_base_mode_id}
            error={icms_calculation_base_mode_idErr}
            disabled={disableFields}
          />
        )}
      </Grid> */}
      {/* Base de Cálculo - Aliquota */}
      {/* <Grid item xs={12} sm={6}>
        <TextInput
          disabled={disableFields}
          label={Texts.novoProduto.fields.baseCalculo}
          id="icms_calculation_base"
          number
          symbol=""
          onChange={changeText}
          value={icms_calculation_base}
          error={icms_calculation_baseErr}
        />
      </Grid> */}
      {/* <Grid item xs={12} sm={6}>
        <TextInput
          disabled={disableFields}
          label={Texts.novoProduto.fields.aliquota}
          id="icms_aliquot"
          number
          symbol=""
          onChange={changeText}
          value={icms_aliquot}
          error={icms_aliquotErr}
        />
      </Grid> */}
      <Grid item xs={12} sm={12}>
        <Title variant="h6">Fornecedor</Title>
        <LoadingFailure failure={providersList.error} />
        {!providersList.error && (
          <SearchSelector
            label={Texts.novoProduto.fields.providers}
            id="providers"
            onChangeInput={onChangeProviderInput}
            getLabel={getProviderFieldLabel}
            onChange={selected}
            options={providersList.data}
            loading={providersList.loading}
            value={providers}
            error={providersErr}
            optionCheckField="provider_id"
            selectedCheckField="provider_id"
            disabled={disableFields}
            multiple
          />
        )}
      </Grid>
    </>
  );
}

export default Dados;
