import React, { useEffect } from 'react';
import Main from 'components/main-container';
import { Grid } from '@material-ui/core';
import { Title } from 'components/title/Title';
import StoreSelector from 'components/store-selector';
import { Store, StoreSelectorResult } from 'utils/types/store';
import If from 'components/if/If';
import { useBanners } from 'context/banners';
import BannersDragAndDrop from './components/BannersDragAndDrop';

const Banner = () => {
  const { store, setStore, getBanners, resetBanners } = useBanners();

  useEffect(() => {}, []);

  const onChange = ({ value }: StoreSelectorResult<Store>) => {
    resetBanners();
    setStore(value);
    if (value) {
      getBanners(value.store_id);
    }
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <StoreSelector onChange={onChange} id="stand" value={store} />
        </Grid>
        <If condition={!!store}>
          <>
            <Grid item xs={12}>
              <Title>Selecione os Banners</Title>
            </Grid>
            <BannersDragAndDrop />
          </>
        </If>
      </Grid>
    </Main>
  );
};

export { Banner };
