import React from 'react';
import { Grid } from '@material-ui/core';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { useDispatch, useSelector } from 'react-redux';
import Masks from 'utils/Masks';
import { Texts } from 'constants/strings';
import { NovoUsuarioActions } from 'store/ducks/novo-usuario';

function DadosGerais({ updateMode = false }) {
  const {
    name,
    phone,
    rg,
    birthdate,
    cpf,
    nameErr,
    phoneErr,
    birthdateErr,
    cpfErr,
    rgErr,
    disableFields,
  } = useSelector((state) => state.novoUsuario);
  const dispatch = useDispatch();
  const changeText = (event) => {
    dispatch(
      NovoUsuarioActions.updateFields(event.target.id, event.target.value)
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.usuarios.sessions.geral}</Title>
      </Grid>
      <Grid item xs={12} sm={7}>
        <TextInput
          disabled={disableFields}
          label={Texts.usuarios.inputLabels.nome}
          id="name"
          onChange={changeText}
          value={name}
          error={nameErr}
        />
      </Grid>
      <Grid item xs={6} sm={5}>
        <TextInput
          disabled={disableFields}
          label={Texts.usuarios.inputLabels.nasc}
          id="birthdate"
          mask={Masks.data}
          onChange={changeText}
          value={birthdate}
          error={birthdateErr}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextInput
          disabled={disableFields}
          label={Texts.usuarios.inputLabels.telefone}
          id="phone"
          mask={Masks.celular}
          onChange={changeText}
          value={phone}
          error={phoneErr}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextInput
          disabled={disableFields || updateMode}
          label={Texts.usuarios.inputLabels.rg}
          id="rg"
          onChange={changeText}
          value={rg}
          error={rgErr}
          mask={Masks.rg}
        />
      </Grid>
      <Grid item xs={8} sm={4}>
        <TextInput
          disabled={disableFields || updateMode}
          label={Texts.usuarios.inputLabels.cpf}
          mask={Masks.cpf}
          id="cpf"
          onChange={changeText}
          value={cpf}
          error={cpfErr}
        />
      </Grid>
    </>
  );
}

export default DadosGerais;
