import { Button } from 'components/button';
import Modal from 'components/modal';
import Text from 'components/text';
import React, { useState } from 'react';
import { TextInput } from 'components/text-input';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface Props {
  show: boolean;
  onSubmit: (value: string) => void;
  toogleModal: () => void;
  title: string;
  description?: string;
  buttonTitle: string;
}

interface InputProps {
  target: {
    id: string;
    value: string;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },
    button: {
      marginTop: theme.spacing(3),
    },
  })
);

function PopupInput({
  show,
  onSubmit,
  toogleModal,
  title,
  description,
  buttonTitle,
}: Props) {
  const classes = useStyles();
  const [input, setInput] = useState('');

  const changeText = ({ target: { value } }: InputProps) => {
    setInput(value);
  };

  const onSelect = () => {
    onSubmit(input);
    toogleModal();
  };

  return (
    <Modal title={title} show={show} onClose={toogleModal}>
      <Text noWrap={false} style={classes.description}>
        {description}
      </Text>
      <TextInput label={title} onChange={changeText} value={input} />
      <Button
        style={classes.button}
        title={buttonTitle}
        onClick={onSelect}
        colored
      />
    </Modal>
  );
}

export { PopupInput };
