import produce from 'immer';
import { money } from 'utils/money';

export const Types = {
  FIELDS: 'novo-produto@FIELDS',
  CREATE: 'novo-produto@CREATE',
  CREATE_ERR: 'novo-produto@CREATE_ERR',
  CREATE_SUCCESS: 'novo-produto@CREATE_SUCCESS',
  UPDATE: 'novo-produto@UPDATE',
  UPDATE_ERR: 'novo-produto@UPDATE_ERR',
  UPDATE_SUCCESS: 'novo-produto@UPDATE_SUCCESS',
  RESET: 'novo-produto@RESET',
  ADD_IMAGE: 'novo-produto@ADD_IMAGE',
  REMOVE_IMAGE: 'novo-produto@REMOVE_IMAGE',
  CONFIG_UPDATE_MODE: 'novo-produto@CONFIG_UPDATE_MODE',
  ACTIVE_INACTIVE: 'novo-produto@ACTIVE_INACTIVE',
  ACTIVE_INACTIVE_ERROR: 'novo-produto@ACTIVE_INACTIVE_ERROR',
  ACTIVE_INACTIVE_SUCCESS: 'novo-produto@ACTIVE_INACTIVE_SUCCESS',
  GROUP_LOADING: 'novo-produto@GROUP_LOADING',
  GROUP_ERR: 'novo-produto@GROUP_ERR',
  GROUP_SUCCESS: 'novo-produto@GROUP_SUCCESS',
  DETAILS_REQUEST: 'novo-produto@DETAILS_REQUEST',
  DETAILS_SUCCESS: 'novo-produto@DETAILS_SUCCESS',
  DETAILS_ERROR: 'novo-produto@DETAILS_ERROR',
  REQUEST_LIST_PROVIDERS: 'novo-produto@REQUEST_LIST_PROVIDERS',
  LIST_PROVIDERS: 'novo-produto@LIST_PROVIDERS',
  FILL_PRODUCT_FROM_NFE: 'novo-produto@FILL_PRODUCT_FROM_NFE',
};

const initialState = {
  product_id: null,
  description: '',
  descriptionErr: '',
  brand: '',
  brandErr: '',
  type_unit: '',
  type_unitErr: '',
  gtin_ean_code: '',
  gtin_ean_codeErr: '',
  ncm: '',
  ncmErr: '',
  sale_value: money.formatCurrency(0),
  sale_valueErr: '',
  cost_value: money.formatCurrency(0),
  cost_valueErr: '',
  code_identification: '',
  code_identificationErr: '',
  details: '',
  detailsErr: '',
  short_name: '',
  short_nameErr: '',
  images: [],
  imagesErr: '',
  product_group_id: '',
  product_group_idErr: '',
  loading: false,
  updateMode: false,
  disableFields: false,
  loadingActivate: false,
  loadingGroups: false,
  groups: [],
  loadingDetails: false,
  errorDetails: false,
  successDetails: false,
  cfop_id: null,
  cfop_idErr: '',
  cofins_id: null,
  cofins_idErr: '',
  pis_id: null,
  pis_idErr: '',
  icms_origin_id: null,
  icms_origin_idErr: null,
  icms_tax_situation_id: null,
  icms_tax_situation_idErr: null,
  icms_calculation_base_mode_id: null,
  icms_calculation_base_mode_idErr: null,
  icms_aliquot: '',
  icms_aliquotErr: '',
  icms_calculation_base: '',
  icms_calculation_baseErr: '',
  providersList: {
    data: [],
    loading: false,
    error: false,
  },
  providers: [],
  providersErr: '',
};

const novoProduto = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.FIELDS:
      if (state[`${payload.key}Err`]) {
        state[`${payload.key}Err`] = '';
      }
      state[payload.key] = payload.value;
      return state;
    case Types.CREATE:
      state.loading = true;
      return state;
    case Types.CREATE_ERR:
      state = { ...state, ...payload };
      state.loading = false;
      return state;
    case Types.CREATE_SUCCESS:
      state.loading = false;
      return state;
    case Types.UPDATE:
      state.loading = true;
      return state;
    case Types.UPDATE_ERR:
      state = { ...state, ...payload };
      state.loading = false;
      return state;
    case Types.UPDATE_SUCCESS:
      state.loading = false;
      return state;
    case Types.RESET:
      return initialState;
    case Types.GROUP_LOADING:
      state.loadingGroups = true;
      return state;
    case Types.GROUP_SUCCESS:
      state.loadingGroups = false;
      state.groups = payload;
      return state;
    case Types.GROUP_ERR:
      state.loadingGroups = false;
      return state;
    case Types.ACTIVE_INACTIVE:
      state.loadingActivate = true;
      return state;
    case Types.ACTIVE_INACTIVE_ERROR:
      state.loadingActivate = false;
      return state;
    case Types.ACTIVE_INACTIVE_SUCCESS:
      state.active = !state.active;
      state.loadingActivate = false;
      state.disableFields = !state.active;
      return state;
    case Types.ADD_IMAGE:
      state.images = [...state.images, ...payload];
      return state;
    case Types.REMOVE_IMAGE:
      state.images = state.images.filter((image) => image.id !== payload);
      return state;
    case Types.DETAILS_REQUEST:
      state.loadingDetails = true;
      state.product_id = payload.productId;
      return state;
    case Types.DETAILS_ERROR:
      state.loadingDetails = false;
      return state;
    case Types.DETAILS_SUCCESS:
      state = { ...state, ...payload };
      state.loadingDetails = false;
      return state;
    case Types.REQUEST_LIST_PROVIDERS:
      state.providersList.loading = true;
      state.providersList.error = false;
      return state;
    case Types.LIST_PROVIDERS:
      state.providersList.loading = payload.loading;
      state.providersList.error = payload.error;
      state.providersList.data = payload.data || state.providersList.data; // se der erro, variável será nula, então o valor continuar o de antes
      return state;
    case Types.FILL_PRODUCT_FROM_NFE:
      state.cost_value = payload.cost_value;
      state.description = payload.description;
      state.type_unit = payload.type_unit;
      state.ncm = payload.ncm;
      return state;
    default:
      return state;
  }
});

export default novoProduto;

export const NovoProdutoActions = {
  field: (key, value) => ({
    type: Types.FIELDS,
    payload: { key, value },
  }),
  create: () => ({
    type: Types.CREATE,
  }),
  createErr: (errors = {}) => ({
    type: Types.CREATE_ERR,
    payload: errors,
  }),
  createSuccess: () => ({
    type: Types.CREATE_SUCCESS,
  }),
  update: () => ({
    type: Types.UPDATE,
  }),
  updateErr: (errors = {}) => ({
    type: Types.UPDATE_ERR,
    payload: errors,
  }),
  updateSuccess: () => ({
    type: Types.UPDATE_SUCCESS,
  }),
  reset: () => ({
    type: Types.RESET,
  }),
  setUpdateMode: (product) => ({
    type: Types.CONFIG_UPDATE_MODE,
    payload: product,
  }),
  activeInactive: () => ({
    type: Types.ACTIVE_INACTIVE,
  }),
  activeInactiveErr: () => ({
    type: Types.ACTIVE_INACTIVE_ERROR,
  }),
  activeInactive_Success: () => ({
    type: Types.ACTIVE_INACTIVE_SUCCESS,
  }),
  addImages: (listImages) => ({
    type: Types.ADD_IMAGE,
    payload: listImages,
  }),
  removeImage: (id) => ({
    type: Types.REMOVE_IMAGE,
    payload: id,
  }),
  groupRequest: () => ({
    type: Types.GROUP_LOADING,
  }),
  groupSuccess: (groups) => ({
    type: Types.GROUP_SUCCESS,
    payload: groups,
  }),
  groupErr: () => ({
    type: Types.GROUP_ERR,
  }),
  getDetails: (productId) => ({
    type: Types.DETAILS_REQUEST,
    payload: { productId },
  }),
  detailsErr: () => ({
    type: Types.DETAILS_ERROR,
  }),
  detailsSuccess: (product) => ({
    type: Types.DETAILS_SUCCESS,
    payload: product,
  }),
  getProvidersList: (fantasyName = '') => ({
    type: Types.REQUEST_LIST_PROVIDERS,
    payload: fantasyName,
  }),
  providerListError: () => ({
    type: Types.LIST_PROVIDERS,
    payload: {
      error: true,
      loading: false,
      data: null,
    },
  }),
  providerListSuccess: (data) => ({
    type: Types.LIST_PROVIDERS,
    payload: {
      error: false,
      loading: false,
      data,
    },
  }),
  fillNewProductFromNfe: (cost_value, type_unit, description, ncm) => ({
    type: Types.FILL_PRODUCT_FROM_NFE,
    payload: {
      cost_value,
      type_unit,
      description,
      ncm,
    },
  }),
};
