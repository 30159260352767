import React from 'react';
import Logo from 'components/logo';
import { ColoredButton } from 'components/button';
import { Creators as ResetPasswordActions } from 'store/ducks/reset-password';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { makeStyles, useTheme } from '@material-ui/core';
import Text from 'components/text';
import { useHistory, useParams } from 'react-router-dom';
import RouterNames from 'constants/router-names';
import { Container } from './components/Styled';
import Fields from './components/Fields';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    padding: '20px',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  title: {
    margin: '2vh 0 0',
  },
}));

function ResetPassword() {
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { errorMessage, resetLoading, resetSuccess } = useSelector(
    (state) => state.resetPassword
  );

  const onClick = () => {
    if (resetSuccess) {
      history.push(RouterNames.LOGIN);
    } else {
      dispatch(ResetPasswordActions.resetPassword(token));
    }
  };

  const passwordFields = () => (
    <>
      {errorMessage ? (
        <div style={{ margin: 32 }} />
      ) : (
        <Text style={classes.subtitle}>
          {Texts.recuperarSenha.subtitleScreenUpdatePassword}
        </Text>
      )}
      <Fields />
    </>
  );

  const success = () => (
    <Text style={classes.subtitle}>{Texts.recuperarSenha.resetSuccess}</Text>
  );

  return (
    <Container>
      <Logo />
      <Title
        color={theme.palette.common.white}
        variant="h4"
        style={classes.title}
      >
        {Texts.recuperarSenha.titleScreenUpdatePassword}
      </Title>
      {!resetSuccess && passwordFields()}
      {resetSuccess && success()}
      <ColoredButton
        loading={resetLoading}
        title={
          resetSuccess
            ? Texts.recuperarSenha.resetSuccessButton
            : Texts.recuperarSenha.buttonPassword
        }
        onClick={onClick}
      />
    </Container>
  );
}
export { ResetPassword };
