import React, { createContext, useContext, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { OrderApi } from 'services/api/OrderApi';
import { OrdersApi } from 'services/api/OrdersApi';
import { NotificationActions } from 'store/ducks/notification';
import { CancelOrder, Order, OrderItem } from 'utils/types/order';
import { ResponsePagination } from 'utils/types/response';

interface OrdersProps {
  children: React.ReactNode;
}

interface AllOrdersResponse extends ResponsePagination<Order> {}

interface Orders {
  cancel: {
    selected: CancelOrder | null;
    setSelected: (order: CancelOrder | null) => void;
    cancelOrder: (orderId: number) => void;
  };
  order: {
    orders?: AllOrdersResponse | null;
    setOrders: (orders: AllOrdersResponse | null) => void;
    getOrders: (query: string) => void;
  };
  details: {
    order?: Order | null;
    items?: Array<OrderItem>;
    getDetailsOrder: (orderId: string) => void;
    getOrderItems: (orderId: string) => void;
    resetDetails: () => void;
  };
}

const OrderContext = createContext<Orders>({
  cancel: {
    selected: null,
    setSelected: () => {},
    cancelOrder: () => {},
  },
  order: {
    orders: null,
    setOrders: () => {},
    getOrders: () => {},
  },
  details: {
    order: null,
    items: [],
    getDetailsOrder: () => {},
    getOrderItems: () => {},
    resetDetails: () => {},
  },
});

const OrderProvider = ({ children }: OrdersProps) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<CancelOrder | null>(null);
  const [orders, setOrders] = useState<AllOrdersResponse | null>(null);
  const [details, setDetails] = useState<Order | null>(null);
  const [items, setItems] = useState<Array<OrderItem>>([]);

  const resetDetails = useCallback(() => {
    setDetails(null);
    setItems([]);
  }, []);

  const getOrderItems = useCallback((orderId: string) => {
    OrdersApi.getAdminOrderItems(orderId)
      .then(({ data }) => {
        setItems(data);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(JSON.stringify(error)));
      });
  }, [dispatch, setItems]);

  const getDetailsOrder = useCallback((orderId: string) => {
    OrdersApi.getAdminOrderDetails(orderId)
      .then(({ data }) => {
        setDetails(data.data);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(JSON.stringify(error)));
      });
  }, [dispatch, setDetails]);

  const cancelOrder = useCallback((orderId: number) => {
    OrderApi.cancelOrder(orderId)
      .then(() => {
        dispatch(NotificationActions.success('Compra cancelada com sucesso'));
      })
      .catch((error) => {
        dispatch(NotificationActions.error(JSON.stringify(error)));
      });
  }, [dispatch]);

  const getOrders = useCallback((query: string = '') => {
    OrdersApi.getAllOrders(query)
      .then(({ data }) => {
        setOrders(data);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(JSON.stringify(error)));
      })
      .finally(() => dispatch(NotificationActions.dismiss()));
  }, [dispatch, setOrders]);

  return (
    <OrderContext.Provider
      value={{
        cancel: {
          selected,
          setSelected,
          cancelOrder,
        },
        order: {
          orders,
          setOrders,
          getOrders,
        },
        details: {
          order: details,
          items,
          getDetailsOrder,
          getOrderItems,
          resetDetails,
        },
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

const useOrders = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('useOrders must be user within an OrderProvider');
  }
  return context.order;
};
const useCancel = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('useCancel must be user within an OrderProvider');
  }
  return context.cancel;
};

const useOrderDetails = () => {
  const context = useContext(OrderContext);
  if (!context) {
    throw new Error('useCancel must be user within an OrderProvider');
  }
  return context.details;
};

export { OrderProvider, useOrders, useCancel, useOrderDetails };
