import React, { FC, useEffect, useRef, useState } from 'react';
import { showAlert, useStores } from 'utils';
import queryCreator from 'utils/queryCreator';
import { FieldChange } from 'utils/types/input';
import { Product, ProductDetails } from 'utils/types/product';
import { Store, StoreSelectorResult } from 'utils/types/store';
import { observer } from 'mobx-react';
import { StoreInventory } from './StoreInventory';

const StoreInventoryContainer: FC = () => {
  const { store, product } = useStores();
  const [stores, setStores] = useState<Store[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loadingInventory, setLoadingInventory] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const refProduct = useRef<HTMLDivElement>(null);

  const onChangeInputStore = (e: React.ChangeEvent<HTMLInputElement>) => {
    const queries = [];
    const fantasyName = e?.target?.value;
    if (fantasyName) {
      queries.push(queryCreator.like('fantasy_name', fantasyName));
    }
    getStores(queryCreator.formatter({ queries }));
  };

  const getStores = async (query: string) => {
    setLoadingSearch(true);
    try {
      const { data } = await store.getStores(query);
      setStores(data);
    } catch (error) {
      showAlert({
        message: 'Ocorreu um erro, tente novamente',
      });
      setStores([]);
    } finally {
      setLoadingSearch(false);
    }
  };

  const getProducts = async (query: string) => {
    setLoadingProducts(true);
    try {
      const { data } = await product.getProducts(query);
      setProducts(data);
    } catch (error) {
      setProducts([]);
      showAlert({
        message: 'Ocorreu um erro, tente novamente',
      });
    } finally {
      setLoadingProducts(false);
    }
  };

  const getInventory = async () => {
    setLoadingInventory(true);
    try {
      await store.getInventoryByStore();
    } catch (error) {
      showAlert({
        message: 'Ocorreu um erro, tente novamente',
      });
    } finally {
      setLoadingInventory(false);
    }
  };

  const onChangeStore = ({ value }: StoreSelectorResult<Store>) => {
    store.setInventoryStory(value);
    if (value?.store_id) {
      getInventory();
    } else {
      store.resetInventory();
    }
  };

  const onChangeProduct = ({ value }: FieldChange<Product>) => {
    if (value?.product_id) {
      store.setSelectedProduct(value);
    } else {
      store.resetProductInventory();
    }
  };

  const onChangeInputProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const queries = [];
    const description = e?.target?.value;
    if (description) {
      queries.push(queryCreator.like('description', description));
    }
    getProducts(queryCreator.formatter({ queries }));
  };

  const getStoreLabel = (option: Store): string => option.fantasy_name;

  const getProductLabel = (option: Product) => option.description;

  const reset = () => {
    store.resetInventory();
    setProducts([]);
    setLoadingSearch(false);
    setLoadingInventory(false);
    setLoadingProducts(false);
  };

  const onUpdate = async () => {
    try {
      showAlert({ message: 'Realizando Alterações', type: 'info' });
      await store.onUpdateInventory();
      showAlert({ message: 'Sucesso!', type: 'success' });
    } catch (error) {
      showAlert({
        message: 'Ocorreu um erro, tente novamente!',
      });
    } finally {
      await getInventory();
      await getProducts('');
    }
  };

  const onSelectProductInventory = (selectedProduct: ProductDetails) => {
    goToProductSelector();
    store.setSelectedProductInventory(selectedProduct);
  };

  const goToProductSelector = () => {
    setTimeout(() => {
      if (refProduct?.current) {
        refProduct.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  };

  useEffect(() => {
    getStores('');
    getProducts('');
    return reset;
  }, []);

  return (
    <StoreInventory
      getStoreLabel={getStoreLabel}
      getProductLabel={getProductLabel}
      onChangeInputStore={onChangeInputStore}
      onChangeStore={onChangeStore}
      stores={stores}
      isRemoving={store.removeMode}
      onSelectedProductByTable={onSelectProductInventory}
      onChangeInputProduct={onChangeInputProduct}
      onSelectedProduct={onChangeProduct}
      loadingSearch={loadingSearch}
      loadingInventory={loadingInventory}
      loadingProduct={loadingProducts}
      selectedStore={store.inventoryStore}
      inventory={store.inventory}
      quantities={store.quantities}
      onChangeQuantity={store.onChangeQuantity}
      products={products}
      selectedProduct={store.inventoryProduct}
      updating={false}
      onClean={store.resetProductInventory}
      onUpdate={onUpdate}
      refProduct={refProduct}
    />
  );
};

export default observer(StoreInventoryContainer);
