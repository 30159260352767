import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { NotificationActions } from 'store/ducks/notification';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

function Notification() {
  const dispatch = useDispatch();
  const { show, message, type } = useSelector((state) => state.notification);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(NotificationActions.dismiss());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={show}
      autoHideDuration={6000}
      onClose={handleClose}
      key="snack"
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={type}
      >
        {typeof message === 'string' ? message : JSON.stringify(message)}
      </MuiAlert>
    </Snackbar>
  );
}

export { Notification };
