import React from 'react';
import * as Toastify from 'react-toastify';

type AlertMessage = string | string[] | Element | Element[];
type TypeOptions = 'info' | 'success' | 'warning' | 'error' | 'default';
type TypePosition =
  | 'top-left'
  | 'top-right'
  | 'top-center'
  | 'bottom-left'
  | 'bottom-right'
  | 'bottom-center';

type ShowAlertProps = {
  message: AlertMessage;
  type?: TypeOptions;
  position?: TypePosition;
  autoClose?: number;
  clean?: boolean;
};

const Msg = ({ message }: any) => <div style={{ width: 260 }}>{message}</div>;

export default function showAlert({
  message,
  type = 'error',
  position = 'top-right',
  autoClose = 7000,
  clean = true,
  ...rest
}: ShowAlertProps): void {
  if (clean) {
    Toastify.toast.dismiss();
  }
  Toastify.toast(<Msg message={message} />, {
    type,
    position,
    autoClose,
    ...rest,
  });
}
