import SearchSelector from 'components/search-selector';
import React, { useCallback, useEffect, useState } from 'react';
import { FiscalApi } from 'services/api/Fiscal';
import queryCreator from 'utils/queryCreator';
import { LegalNature } from 'utils/types/fiscal';

interface IdValue<T> {
  id: string;
  value: T;
}

type OptionValue = LegalNature | Array<LegalNature> | undefined | null;

type LegalNatureProps<T extends OptionValue> = {
  value: T;
  onChange: (value: IdValue<T>) => void;
  id: string;
  multiple?: boolean;
  disabled?: boolean;
}

type Values = {
  loading: boolean;
  error: boolean;
  data: Array<LegalNature>;
};

function LegalNatureSelector<T extends OptionValue>({ value, onChange, id, multiple = false, disabled }: LegalNatureProps<T>) {
  const [values, setValues] = useState<Values>({
    loading: false,
    error: false,
    data: [],
  });

  const onChangeInput = useCallback((e: any) => {
    const description = e?.target?.value || '';
    const queries: Array<string> = [];
    if (description.trim()) {
      queries.push(queryCreator.like('description', description));
    }
    search(queryCreator.formatter({ queries }));
  }, []);

  const search = async (params: string = '') => {
    setValues({
      loading: true,
      error: false,
      data: [],
    });
    try {
      const { data } = await FiscalApi.getLegalNature(params);
      setValues({
        loading: false,
        error: false,
        data: data,
      });
    } catch (error) {
      setValues({
        loading: false,
        error: true,
        data: [],
      });
    }
  };

  const onSelect = ({ value: selected }: IdValue<T>) => {
    onChange({ id, value: selected });
  };

  useEffect(() => {
    onChangeInput('');
  }, [onChangeInput]);

  return (
    <SearchSelector
      id="legal_nature"
      label="Natureza Legal"
      onChangeInput={onChangeInput}
      getLabel={(option: LegalNature) => `${option.code} - ${option.description}`}
      onChange={onSelect}
      options={values.data}
      loading={values.loading}
      value={value}
      optionCheckField="legal_nature_id"
      selectedCheckField="legal_nature_id"
      multiple={multiple}
      disabled={disabled}
    />
  );
}

export { LegalNatureSelector };
