import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: (props) =>
      props.selected ? theme.palette.primary.main : theme.palette.common.white,
  },
  text: (props) =>
    props.selected
      ? {
          color: theme.palette.secondary.main,
          fontWeight: 'bold',
        }
      : {
          color: theme.palette.common.white,
        },
  item: {
    marginLeft: '8px',
    backgroundColor: (props) =>
      props.selected ? theme.palette.common.white : 'inherit',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
}));

function MenuItem({ item }) {
  const { selectedPathMenu } = useSelector((state) => state.layout);
  const { icon: Icon, path, drawerName, parentPath } = item;
  const selected = useMemo(() => {
    const pathItem = parentPath || path;
    return pathItem === selectedPathMenu;
  }, [selectedPathMenu, parentPath, path]);
  const classes = useStyles({ selected });
  const history = useHistory();

  const handleClick = () => {
    // dispatch(LayoutActions.selected(toolbarName, parentPath || path));
    history.push(path);
  };

  return (
    <ListItem
      onClick={handleClick}
      button
      key={drawerName}
      className={classes.item}
    >
      <ListItemIcon>
        <Icon classes={{ root: classes.icon }} />
      </ListItemIcon>
      <ListItemText classes={{ primary: classes.text }} primary={drawerName} />
    </ListItem>
  );
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.elementType.isRequired,
    path: PropTypes.string.isRequired,
    drawerName: PropTypes.string.isRequired,
    toolbarName: PropTypes.string.isRequired,
    parentPath: PropTypes.string,
  }).isRequired,
};

export default MenuItem;
