import React from 'react';
import {
  IconButton,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { AddCircle } from '@material-ui/icons';
import { getAttrByName } from '../../../utils/getAttrByName';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.dark,
    fontSize: 25,
  },
  iconButton: {
    border: 'none',
    width: 25,
    height: 25,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

function Body({ values, fields, icon: Icon, hasAction, action }) {
  const classes = useStyles();
  return (
    <TableBody>
      {values &&
        values.map((row, index) => (
          <StyledTableRow key={index}>
            {fields &&
              fields.map((field, indexRow) => (
                <StyledTableCell key={indexRow} align="center">
                  {getAttrByName(row, field)}
                </StyledTableCell>
              ))}
            {hasAction && (
              <StyledTableCell align="center">
                <IconButton
                  className={classes.iconButton}
                  onClick={() => action(row)}
                >
                  <Icon className={classes.icon} />
                </IconButton>
              </StyledTableCell>
            )}
          </StyledTableRow>
        ))}
    </TableBody>
  );
}

Body.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  hasAction: PropTypes.bool,
  action: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  icon: PropTypes.elementType,
};

Body.defaultProps = {
  values: [],
  hasAction: false,
  action: () => {},
  icon: AddCircle,
};

export default Body;
