import React from 'react';
import { Grid } from '@material-ui/core';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { useDispatch, useSelector } from 'react-redux';
import { Texts } from 'constants/strings';
import { NovoProdutoActions } from 'store/ducks/novo-produto';
import Selecionar from 'components/select';
import { RichEditor } from 'components';

function Exibicao() {
  const dispatch = useDispatch();
  const {
    code_identification,
    code_identificationErr,
    details,
    detailsErr,
    short_name,
    short_nameErr,
    product_group_id,
    product_group_idErr,
    groups,
    disableFields,
  } = useSelector((state) => state.novoProduto);

  const changeText = (event) => {
    dispatch(NovoProdutoActions.field(event.target.id, event.target.value));
  };

  const selected = ({ id, value }) => {
    dispatch(NovoProdutoActions.field(id, value));
  };

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.novoProduto.sessions.exibicao}</Title>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.novoProduto.fields.codeIdentification}
          onChange={changeText}
          id="code_identification"
          value={code_identification}
          error={code_identificationErr}
          disabled={disableFields}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.novoProduto.fields.nomeExibicao}
          onChange={changeText}
          id="short_name"
          value={short_name}
          error={short_nameErr}
          disabled={disableFields}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Selecionar
          label={Texts.novoProduto.fields.categoria}
          id="product_group_id"
          onChange={selected}
          options={groups}
          value={product_group_id}
          error={product_group_idErr}
          disabled={disableFields}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <RichEditor
          field="details"
          onChange={(field, value) => {
            selected({ id: field, value });
          }}
          value={details || ''}
          disabled={disableFields}
          placeholder="Descreva os detalhes do produto"
          error={detailsErr}
        />
      </Grid>
    </>
  );
}

export default Exibicao;
