import { Grid } from '@material-ui/core';
import Main from 'components/main-container';
import React, { useEffect } from 'react';
import ButtonBack from 'components/button-back';
import { useCancel } from 'context/orders';
import Table from 'components/pagination-table';
import { Title } from 'components/title/Title';
import { Button } from 'components/button';
import { Close } from '@material-ui/icons';
import { AddMasks } from 'utils/Masks';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    total: {
      textAlign: 'right',
    },
  })
);

const CancellationDetails = () => {
  const classes = useStyles();
  const { selected, cancelOrder, setSelected } = useCancel();
  useEffect(() => {
    return () => {
      setSelected(null);
    };
  }, [setSelected]);

  if (!selected) return null;
  const cancel = () => cancelOrder(selected.order_id);
  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ButtonBack />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Title variant="h6">{`Solicitação de cancelamento na loja ${selected.order.store.storeProduct.store.fantasy_name}`}</Title>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={['Produto', 'Valor produto']}
            values={selected.order.orderItems}
            fields={(product) => [
              product?.product_description,
              AddMasks.money(product?.subtotal),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Title style={classes.total}>
            {`Total ${AddMasks.money(selected.order.totals.total_sale_value)}`}
          </Title>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Button icon={Close} onClick={cancel} title="Aprovar cancelamento" />
        </Grid>
      </Grid>
    </Main>
  );
};

export { CancellationDetails };
