import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#F32A56',
      dark: '#CB2946',
    },
    secondary: {
      main: '#8B2259',
    },
    error: {
      main: '#AC0404',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '1rem',
        fontWeight: 'bold',
        textTransform: 'none',
      },
      outlined: {
        minWidth: '55px',
        minHeight: '56px',
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: '56px',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#FFF',
        },
      },
    },
  },
  mixins: {},
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      sm: 750,
    },
  },
});
