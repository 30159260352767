import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import {version} from '../../../package.json';

const useStyles = makeStyles((theme: Theme) => createStyles({
  version: {
    fontSize: 16,
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    fontWeight: 'bold'
  },
}));

const Version: React.FC = () => {
  const classes = useStyles();
  return <Typography className={classes.version}>Versão {version}</Typography>
}

export default Version;