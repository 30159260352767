import React from 'react';
import { Grid, GridSize } from '@material-ui/core';
import Text from 'components/text';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      color: theme.palette.primary.dark,
      fontSize: '2.5em',
    },
    title: {
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(1),
    },
    gridValue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gridButton: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    description: {
      fontSize: '1em',
    },
    iconContainer: {
      position: 'absolute',
      right: 30,
    },
    icon: {
      fontSize: 20,
    },
  })
);

// interface BillInfoProps {
//   labelLeft: string;
//   valueLeft: string;
//   labelCenter: string;
//   valueCenter: string;
//   labelRight: string;
//   valueRight: string;
// }

interface BillInfoProps {
  label: string;
  value: string;
}

interface Props {
  data: Array<BillInfoProps>;
  sm?: GridSize;
}

function BillInfo({ data, sm = 4 }: Props) {
  const classes = useStyles();
  return (
    <Grid container justify="center">
      {data.map((item) => (
        <Grid item className={classes.gridValue} xs={12} sm={sm}>
          <Text style={classes.value}>{item.value}</Text>
          <Text style={classes.description}>{item.label}</Text>
        </Grid>
      ))}
    </Grid>
  );
}

export { BillInfo };
