import { Grid } from '@material-ui/core';
import Main from 'components/main-container';
import RouterNames from 'constants/router-names';
import { Texts } from 'constants/strings';
import { OrdersActions } from 'store/ducks/orders';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Table from 'components/pagination-table';
import { AddMasks } from 'utils/Masks';
import { ArrowForward } from '@material-ui/icons';
import Filtro from './components/Filtro';

function Orders() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { orders, loading, error } = useSelector((state) => state.orders);

  const getOrders = useCallback(
    (page) => {
      dispatch(OrdersActions.getOrders(page || 1));
    },
    [dispatch]
  );

  useEffect(() => {
    getOrders(1);
  }, [getOrders]);

  const action = (order) => {
    history.push(RouterNames.PDV_ORDER_DETAILS, { order });
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Filtro />
        <Table
          error={error}
          loading={loading}
          labels={Texts.orders.tabela}
          values={orders.data}
          fields={(order) => [
            order.order_id,
            order.currentStatus.status.description,
            AddMasks.dateHour(order.created_at),
            order.typePayment.description,
            AddMasks.money(order.totals.total),
          ]}
          Icon={ArrowForward}
          pagination
          page={orders.page}
          perPage={orders.perPage}
          total={orders.total}
          lastPage={orders.lastPage}
          hasAction
          action={action}
          onChangePage={(newPage) => getOrders(newPage)}
        />
      </Grid>
    </Main>
  );
}

export { Orders };
