import { Grid } from '@material-ui/core';
import React from 'react';
import Expandable from 'components/expandable';
import TextInfo from 'components/text-info';
import { NfeRecipient } from 'utils/types/nfe';

interface Props {
  recipient: NfeRecipient;
}

function Recipient({
  recipient: {
    name,
    cpf_cnpj,
    telephone,
    street,
    number,
    neighborhood,
    city,
    uf,
    postcode,
  },
}: Props) {
  return (
    <Expandable title="Destinatário">
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Nome" value={name} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="CPF/CNPJ" value={cpf_cnpj} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Telefone" value={telephone} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Rua" value={cpf_cnpj} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo
            label="Endereço"
            value={`${street}, ${number}, ${neighborhood}, ${city}-${uf}, ${postcode}`}
          />
        </Grid>
      </Grid>
    </Expandable>
  );
}

export default Recipient;
