import { Grid, makeStyles } from '@material-ui/core';
import Main from 'components/main-container';
import { Texts } from 'constants/strings';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OrderDetailsActions } from 'store/ducks/order-details';
import { Button } from 'components/button';
import { ChevronLeft, Close, LocalShipping } from '@material-ui/icons';
import { OrdersActions } from 'store/ducks/orders';
import { status_order } from 'constants/config/Config';
import Table from 'components/pagination-table';
import { AddMasks } from 'utils/Masks';
import If from 'components/if/If';
import ShopperInfo from './components/ShopperInfo';
import TransactionInfo from './components/OrderInfo';
import Total from './components/Total';
import ReasonCancellation from './components/ReasonCancellation';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const OrderDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showCancel, setShowCancel] = useState(false);

  const { items, itemsLoading, itemError, order } = useSelector(
    (state) => state.orderDetails
  );

  const { shopkeeper } = useSelector((state) => state.orders);

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    if (history.location.state && history.location.state.order) {
      dispatch(
        OrderDetailsActions.getOrder(history.location.state.order.order_id)
      );
      dispatch(
        OrderDetailsActions.getItems(history.location.state.order.order_id)
      );
    }
  }, [history, dispatch]);

  const confirmOrder = () => {
    dispatch(OrdersActions.confirmOrder(order.order_id));
  };

  // const showCancelButton = useMemo(
  //   () =>
  //     ![
  //       status_order.REQUESTED_CANCELLATION,
  //       status_order.PAYMENT_CANCELED,
  //       status_order.ORDER_DELIVERED,
  //     ].includes(order?.currentStatus?.status?.constant),
  //   [order]
  // );
  const showCancelButton = false;

  const showDeliveredButton = useMemo(
    () =>
      ![
        status_order.REQUESTED_CANCELLATION,
        status_order.PAYMENT_CANCELED,
        status_order.ORDER_DELIVERED,
      ].includes(order?.currentStatus?.status?.constant),
    [order]
  );

  const requestCancel = (reason) => {
    dispatch(
      OrdersActions.shopkeeperRequestCancellation(order.order_id, reason)
    );
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <div className={classes.buttonContainer}>
            <Button
              icon={ChevronLeft}
              onClick={back}
              title={Texts.order.buttons.voltar}
              style={classes.button}
            />
            <If condition={showCancelButton}>
              <Button
                icon={Close}
                onClick={() => setShowCancel(true)}
                title={Texts.order.buttons.cancelar}
                style={classes.button}
                loading={shopkeeper.loadingCancelRequest}
              />
            </If>
            <If condition={showDeliveredButton}>
              <Button
                icon={LocalShipping}
                onClick={confirmOrder}
                colored
                title={Texts.order.buttons.finalizar}
                style={classes.button}
              />
            </If>
          </div>
        </Grid>
        <TransactionInfo />
        <ShopperInfo />
        <Grid item xs={12} sm={12}>
          <Table
            labels={Texts.order.tabela}
            values={items}
            loading={itemsLoading}
            error={itemError}
            fields={(product) => [
              product.storeProduct.product.code_identification,
              product.commercial_quantity,
              AddMasks.money(product.commercial_unit_value),
              AddMasks.money(product.discount_unit_value),
              AddMasks.money(product.subtotal),
            ]}
          />
        </Grid>
        <Total />
      </Grid>
      <ReasonCancellation
        show={showCancel}
        toogleModal={() => setShowCancel(!showCancel)}
        onSubmit={requestCancel}
      />
    </Main>
  );
};

export { OrderDetails };
