import Uris from 'constants/endpoints';
import api from 'services/config/api';

export const Auth = {
  login: (email, password) => {
    return api.post(Uris.login, {
      email,
      password,
      type: 'user',
    });
  },
};
