import { Grid } from '@material-ui/core';
import Main from 'components/main-container';
import React, { useEffect, useCallback } from 'react';
import { Button } from 'components/button';
import { ChevronLeft, PersonAdd, PersonAddDisabled } from '@material-ui/icons';
import { Texts } from 'constants/strings';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NovaLojaActions } from 'store/ducks/nova-loja';
import Proprietarios from './components/Proprietarios';
import Endereco from './components/Endereco';
import DadosGerais from './components/DadosGerais';

function NovaLoja() {
  const dispatch = useDispatch();
  const { updateMode, updateStore, loadingInactivate, saveLoading } =
    useSelector((state) => state.novaLoja);
  const history = useHistory();

  const reset = useCallback(() => {
    dispatch(NovaLojaActions.reset());
  }, [dispatch]);

  useEffect(() => {
    reset();
    if (history.location.state && history.location.state.store) {
      dispatch(NovaLojaActions.setEditStore(history.location.state.store));
    }
  }, [dispatch, history, reset]);

  const back = () => {
    history.goBack();
  };

  const inactivated = () => {
    dispatch(NovaLojaActions.activeInactive());
  };

  const update = () => {
    dispatch(NovaLojaActions.update());
  };

  const create = () => {
    dispatch(NovaLojaActions.create());
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={ChevronLeft}
            onClick={back}
            title={Texts.novaLoja.buttons.voltar}
          />
        </Grid>
        {updateMode && (
          <>
            <Grid item>
              <Button
                icon={PersonAddDisabled}
                onClick={inactivated}
                loading={loadingInactivate}
                title={
                  updateStore && updateStore.active
                    ? Texts.novaLoja.buttons.inativar
                    : Texts.novaLoja.buttons.ativar
                }
              />
            </Grid>
            {updateStore && updateStore.active && (
              <Grid item>
                <Button
                  icon={PersonAdd}
                  onClick={update}
                  colored
                  loading={saveLoading}
                  title={Texts.usuarios.buttons.salvar}
                />
              </Grid>
            )}
          </>
        )}
        <Proprietarios />
        <DadosGerais updateMode={updateMode} />
        <Endereco />
        {!updateMode && (
          <Grid item xs={12} sm={12}>
            <Button
              icon={PersonAdd}
              onClick={create}
              title={Texts.novaLoja.buttons.cadastrar}
              colored
              loading={saveLoading}
            />
          </Grid>
        )}
      </Grid>
    </Main>
  );
}

export { NovaLoja };
