import Uris from 'constants/endpoints';
import api from 'services/config/api';
import {
  Promotion,
  PromotionDetailed,
  PromotionParams,
} from 'utils/types/promotion';
import { ResponsePagination, SimpleResponse } from 'utils/types/response';

export const PromotionsApi = {
  getAllPromotions: (query: string = '') =>
    api.get<ResponsePagination<Promotion>>(`${Uris.getPromotions}${query}`),
  getPromotion: (promotionId: string) =>
    api.get<SimpleResponse<PromotionDetailed>>(
      Uris.updatePromotions.replace('{promotionId}', promotionId)
    ),
  createPromotion: (promotion: PromotionParams) =>
    api.post(Uris.createPromotions, promotion),
  updatePromotion: (promotion: PromotionParams, promotionId: string) =>
    api.put(
      Uris.updatePromotions.replace('{promotionId}', promotionId),
      promotion
    ),
};
