import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { EmojiTransportation, ExitToApp } from '@material-ui/icons';
import Acl from 'components/acl';
import { Permissions } from 'constants/permissions';
import RouterNames from 'constants/router-names';
import { Texts } from 'constants/strings';
import { LayoutActions } from 'store/ducks/layout';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 'auto',
    flexDirection: 'row',
    display: 'flex',
  },
  iconButton: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
  icon: {
    fontSize: 30,
  },
}));

function ToolbarMenu() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const callEmpresa = () => {
    dispatch(LayoutActions.selected(Texts.pages.empresa, RouterNames.EMPRESA));
    history.push(RouterNames.EMPRESA);
  };

  const logout = () => {
    history.push(RouterNames.LOGIN);
  };

  return (
    <div className={classes.container}>
      <Acl
        permission={Permissions.PERMISSION_READ_COMPANY}
        key={RouterNames.EMPRESA}
      >
        <Tooltip title={Texts.tooltips.empresa}>
          <IconButton
            edge="end"
            onClick={callEmpresa}
            className={classes.iconButton}
          >
            <EmojiTransportation className={classes.icon} />
          </IconButton>
        </Tooltip>
      </Acl>
      <Tooltip title={Texts.tooltips.logout}>
        <IconButton edge="end" onClick={logout} className={classes.iconButton}>
          <ExitToApp className={classes.icon} />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export { ToolbarMenu };
