import { Grid, MenuItem, Select } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { TextInput } from 'components/text-input';
import { useDispatch, useSelector } from 'react-redux';
import { Search } from '@material-ui/icons';
import { Button } from 'components/button';
import Masks from 'utils/Masks';
import { ProdutoActions } from 'store/ducks/produto';
import If from 'components/if/If';
import states from 'constants/address/states';

function Endereco() {
  const {
    postcode,
    postcodeErr,
    street,
    streetErr,
    neighborhood,
    neighborhoodErr,
    complemento,
    complementoErr,
    city,
    cityErr,
    uf,
    number,
    numberErr,
    cepLoading,
    disableFields,
  } = useSelector((state) => state.produto.updatedProvider);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ProdutoActions.changeTextProvider("uf", "CE"))
  }, [])


  const changeText = (event) => {
    dispatch(
      ProdutoActions.changeTextProvider(event.target.id, event.target.value)
    );
  };

  const changeUf = (event) => {
    dispatch(ProdutoActions.changeTextProvider("uf", event.target.value))
  };

  const getCep = () => {
    dispatch(ProdutoActions.getCep());
  };

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.novaLoja.sessions.endereco}</Title>
      </Grid>
      {
        // :CEP e Botão Buscar cep
      }
      <Grid item xs={12} sm={5}>
        <TextInput
          //disabled={disableFields}
          label={Texts.novaLoja.fields.cep}
          id="postcode"
          required
          mask={Masks.cep}
          onChange={changeText}
          value={postcode}
          error={postcodeErr}
        />
      </Grid>
      <Grid item>
        <If>
          <Button
            disable={disableFields}
            icon={Search}
            onClick={getCep}
            loading={cepLoading}
            title={Texts.novaLoja.buttons.cepBuscar}
          />
        </If>
      </Grid>
      {
        // Rua - Número
      }
      <Grid item xs={12} sm={8}>
        <TextInput
          //disabled
          required
          label={Texts.novaLoja.fields.rua}
          id="street"
          onChange={changeText}
          value={street}
          error={streetErr}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextInput
          //disabled={disableFields}
          label={Texts.novaLoja.fields.numero}
          id="number"
          required
          mask={Masks.endNumber}
          onChange={changeText}
          value={number}
          error={numberErr}
        />
      </Grid>
      {
        // Complemento - Bairro
      }
      <Grid item xs={12} sm={6}>
        <TextInput
          //disabled={disableFields}
          label={Texts.novaLoja.fields.complem}
          id="complemento"
          onChange={changeText}
          value={complemento}
          error={complementoErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          //disabled
          required
          label={Texts.novaLoja.fields.bairro}
          id="neighborhood"
          onChange={changeText}
          value={neighborhood}
          error={neighborhoodErr}
        />
      </Grid>
      {
        // Cidade - Bairro
      }
      <Grid item xs={8} sm={6}>
        <TextInput
          //disabled
          required
          label={Texts.novaLoja.fields.cidade}
          id="city"
          onChange={changeText}
          value={city}
          error={cityErr}
        />
      </Grid>
      <Grid item xs={4} sm={6}>
        <Select
          id="uf"
          value={uf}
          variant="outlined"
          onChange={changeUf}
          fullWidth>
          {states.map(state => <MenuItem key={state.value} value={state.value}>{state.name}</MenuItem>)}
        </Select>
      </Grid>
    </>
  );
}

export default Endereco;
