import { makeStyles, Button, useTheme } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import React, { FC, useEffect, useRef, useState } from 'react';
import { TextInput } from 'components/text-input';
import Masks from 'utils/Masks';
import { showAlert } from 'utils';

const BUTTON_SIZE = 30;
const WAIT_INTERVAL = 1000;

const useStyles = makeStyles((theme) => ({
  button: {
    maxWasdidth: BUTTON_SIZE,
    minWidth: BUTTON_SIZE,
    minHeight: BUTTON_SIZE,
    maxHeight: BUTTON_SIZE,
    borderRadius: BUTTON_SIZE / 2,
    background:
      'linear-gradient(160deg, rgba(243,42,86,1) 40%, rgba(139,34,89,1) 100%)',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: BUTTON_SIZE / 2,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  value: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: 20,
  },
  input: {
    width: 100,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

type Props = {
  value: number;
  changeValue(value: number): void;
  max: number;
  min: number;
};

const Increment: FC<Props> = ({
  value = 1,
  changeValue,
  max = 99,
  min = 1,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [quantity, setQuantity] = useState('0');

  const timeout = useRef(setTimeout(() => {}, WAIT_INTERVAL));

  const add = () => {
    const newValue = value + 1;
    if (newValue >= max) {
      changeValue(max);
    } else {
      changeValue(newValue);
    }
  };
  const remove = () => {
    const newValue = value - 1;
    if (newValue >= min) {
      changeValue(min);
    } else {
      changeValue(newValue);
    }
  };

  const disabledPlus =
    value === max ? { background: theme.palette.grey[400] } : {};

  const disabledMinus =
    value === min ? { background: theme.palette.grey[400] } : {};

  const debounceOnChange = (newValue: number) => {
    if (newValue > max || newValue < min) {
      showAlert({
        message: `Valor deve estar entre ${min} e ${max}`,
        type: 'warning',
      });
      changeValue(max);
    } else {
      changeValue(newValue);
    }
  };

  const changeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(event.target.value);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    const newValue = event.target.value ? parseFloat(event.target.value) : 1;
    timeout.current = setTimeout(
      (newValueArg) => {
        debounceOnChange(newValueArg);
      },
      WAIT_INTERVAL,
      newValue
    );
  };

  useEffect(() => {
    setQuantity(value.toString());
  }, [value]);

  return (
    <div className={classes.container}>
      <Button
        onClick={remove}
        className={classes.button}
        color="primary"
        style={disabledMinus}
        disabled={value <= min}
      >
        <Remove className={classes.icon} />
      </Button>
      <TextInput
        formStyle={classes.input}
        label="Estoque"
        mask={Masks.quantity}
        id="quantity"
        onChange={changeText}
        value={`${quantity}` || '0'}
      />
      <Button
        disabled={value === max}
        onClick={add}
        className={classes.button}
        color="primary"
        style={disabledPlus}
      >
        <Add className={classes.icon} />
      </Button>
    </div>
  );
};

export default Increment;
