/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { Cancel, Edit } from '@material-ui/icons';
import Text from 'components/text';
import { Texts } from 'constants/strings';
import { NotificationActions } from 'store/ducks/notification';
import React, { useState, useMemo, useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { getBase64FromFile } from 'utils/imageUtils';
import { useBanners } from 'context/banners';
import { TextInput } from 'components/text-input';
import If from 'components/if/If';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thumb: {
      display: 'inline-flex',
      position: 'relative',
      alignSelf: 'center',
    },
    img: {
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '60%',
      },
    },
    input: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100px',
      borderWidth: '2px',
      borderStyle: 'dashed',
      borderColor: (props: { dragging: boolean }) =>
        props.dragging ? theme.palette.primary.main : theme.palette.grey[400],
      borderRadius: theme.shape.borderRadius,
    },
    overlay: {
      display: 'flex',
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: `#FFFFFF60`,
      zIndex: 100,
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: 30,
    },
  })
);

const MAX_QUANTITY = 3;

function BannersDragAndDrop() {
  const dispatch = useDispatch();
  const {
    banners,
    setBanners,
    deleteBanner,
    createBanner,
    links,
    setLink,
    editLinkBanner,
  } = useBanners();
  const [dragging, setDragging] = useState(false);
  const [imageHover, setImageHover] = useState<string | null>(null);
  const classes = useStyles({ dragging });

  const dragMode = (mode = false) => {
    setDragging(mode);
  };

  const files = async (selectedFiles: Array<File>) => {
    const numberOfImages = banners.length + selectedFiles.length;
    // número de imagens excede o valor padrão
    if (numberOfImages > MAX_QUANTITY) {
      dispatch(NotificationActions.warning('Quantidade máxima alcançada'));
      return;
    }

    const selectedImages = await Promise.all(
      selectedFiles.map((file: any) => {
        return getBase64FromFile(file);
      })
    );
    createBanner(selectedImages.map((image) => image.image));
    // setBanners([...banners, ...selectedImages]);
  };

  const removeImageFromList = useCallback(
    (id: string) => {
      setBanners(banners.filter((banner) => banner.id !== id));
      setImageHover(null);
    },
    [banners, setBanners, setImageHover]
  );

  const onDrop = (
    acceptedFiles: Array<File>,
    notAccepted: Array<FileRejection>
  ) => {
    dragMode();
    files(acceptedFiles);
    if (notAccepted.length > 0) {
      dispatch(
        NotificationActions.warning(Texts.novoProduto.texts.formatoInvalid)
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop,
    onDragEnter: () => dragMode(true),
    onDragLeave: () => dragMode(false),
  });

  const thumbs = useMemo(
    () =>
      banners.map(({ image, id, link: currentLink }) => {
        const link = links[id];
        return (
          <>
            <Grid key={id} item xs={12} sm={3}>
              <img src={image} className={classes.img} alt="" />
            </Grid>
            <Grid item xs={8} sm={7}>
              <TextInput
                label="Link do banner (opcional)"
                id="link"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setLink(id, event.target.value);
                }}
                value={link || ''}
              />
            </Grid>
            <Grid item xs={2} sm={1}>
              <IconButton onClick={() => deleteBanner(id)}>
                <Cancel className={classes.icon} />
              </IconButton>
            </Grid>
            <If
              condition={!!(typeof link === 'string' && link !== currentLink)}
            >
              <Grid item xs={2} sm={1}>
                <IconButton onClick={() => editLinkBanner(id)}>
                  <Edit className={classes.icon} />
                </IconButton>
              </Grid>
            </If>
          </>
        );
      }),
    [banners, imageHover, classes, removeImageFromList, deleteBanner, links]
  );

  return (
    <>
      <Grid item xs={12} sm={12}>
        <div
          {...getRootProps({ className: 'dropzone' })}
          className={classes.input}
        >
          <input {...getInputProps()} />
          <Text>
            {dragging ? 'Solte aqui' : 'Arraste os Banners para adicionar'}
          </Text>
        </div>
      </Grid>
      <If condition={banners?.length !== 0}>{thumbs}</If>
      <If condition={banners?.length === 0}>
        <Text>Essa loja não possui banners</Text>
      </If>
    </>
  );
}

export default BannersDragAndDrop;
