import { call, put, select } from 'redux-saga/effects';
import { Creators as UsuariosActions } from 'store/ducks/usuarios';
// import { LojaApi } from 'services/api/LojaApi';
// import UserTypes from 'utils/UserTypes';
import { UserApi } from 'services/api/UserApi';
import { RemoveMasks } from 'utils/Masks';
import queryCreator from 'utils/queryCreator';

export function* getUsersByFilter() {
  try {
    const { filterName, filterCpf, filterStatus, filterTipoUser, filterCNPJ } =
      yield select((state) => state.usuarios);
    const query = [];
    if (filterName.trim()) {
      query.push(queryCreator.like('person.name', filterName));
    }
    if (filterCpf.trim()) {
      query.push(
        queryCreator.like('person.cpf', RemoveMasks.onlyNumbers(filterCpf))
      );
    }
    if (filterStatus !== '') {
      query.push(queryCreator.bool('active', filterStatus));
    }
    if (filterTipoUser !== '') {
      query.push(queryCreator.bool('shopkeeper', filterTipoUser));
    }
    if (filterCNPJ.trim()) {
      query.push(
        queryCreator.like(
          'storeUser.stores.cpf_cnpj',
          RemoveMasks.onlyNumbers(filterCNPJ)
        )
      );
    }
    const { data } = yield call(
      UserApi.getUsers,
      queryCreator.queryFormatter(query)
    );
    const list = data.data.map((item) => ({
      ...item,
      shopkeeperName: item.shopkeeper ? 'LOJISTA' : 'ADMIN', // corrigindo campo true ou false pra tabela
      activeName: item.active ? 'ATIVO' : 'INATIVO', // corrigindo campo true ou false pra tabela
    }));
    yield put(UsuariosActions.listSuccess(list));
  } catch (error) {
    yield put(UsuariosActions.listError());
  }
}

export function* getUsers() {
  try {
    const { data } = yield call(UserApi.getUsers);
    // const data = {
    //   data: [
    //     {
    //       shopkeeper: true,
    //       active: false,
    //       person: {
    //         name: 'Alisson',
    //       },
    //     },
    //   ],
    // };
    const list = data.data.map((item) => ({
      ...item,
      shopkeeperName: item.shopkeeper ? 'LOJISTA' : 'ADMIN', // corrigindo campo true ou false pra tabela
      activeName: item.active ? 'ATIVO' : 'INATIVO', // corrigindo campo true ou false pra tabela
    }));
    yield put(UsuariosActions.listSuccess(list));
  } catch (error) {
    yield put(UsuariosActions.listError());
  }
}
