import { all, takeLatest } from 'redux-saga/effects';

import { Types as AuthTypes } from 'store/ducks/auth';
import { Types as LayoutTypes } from 'store/ducks/layout';
import { Types as ResetPasswordTypes } from 'store/ducks/reset-password';
import { Types as UserTypes } from 'store/ducks/user';
import { Types as UsuariosTypes } from 'store/ducks/usuarios';
import { Types as LojasTypes } from 'store/ducks/lojas';
import { Types as NovaLojaTypes } from 'store/ducks/nova-loja';
import { Types as ProdutosTypes } from 'store/ducks/produto';
import { Types as NovoUsuarioTypes } from 'store/ducks/novo-usuario';
import { Types as NovoProdutoTypes } from 'store/ducks/novo-produto';
import { Types as EmpresaTypes } from 'store/ducks/empresa';
import { Types as UserStoreTypes } from 'store/ducks/user-store';
import { Types as TransactionTypes } from 'store/ducks/orders';
import { Types as TransactionDetailsTypes } from 'store/ducks/order-details';
import { Types as FiscalTypes } from 'store/ducks/fiscal';
import { Types as DashboardTypes } from 'store/ducks/dashboard';
import { requestLogin } from './auth';
import { sendEmailResetPassword, resetPassword } from './reset-password';
import { session } from './user';
import {
  getLojaByCpnj,
  saveNewUser,
  update,
  changeStatus,
} from './novo-usuario';
import { getUsersByFilter, getUsers } from './usuarios';
import {
  moveProducts,
  getLojasFilter,
  getStockProductsFromStore,
  searchProductsByName,
  searchStores,
} from './lojas';
import {
  activeInactive,
  consultaCep,
  createStore,
  updateStore,
} from './nova-loja';
import {
  consultaCepProvider,
  createUpdateProvider,
  getProdutos,
  getProviders,
} from './produto';
import { startTimeoutButton } from './layout';
import {
  activeInactiveProduto,
  createProduto,
  getProductDetails,
  getProductsGroups,
  updateProduto,
  getProvidersList,
} from './novo-produto';
import { consultaCepEmpresa, getEmpresa, saveEmpresa } from './empresa';
import { changeStore } from './user-store';
import {
  confirmOrder,
  confirmOrderCancel,
  getOrders,
  getRequestCancellations,
  shopkeeperRequestCancel,
} from './orders';
import { getDetails, getItems } from './transaction-details';
import {
  entryNFe,
  getCfop,
  getCofins,
  getIcmsCalculationBaseMode,
  getIcmsOrigin,
  getIcmsTaxSituation,
  getNFeList,
  getPis,
  searchProductsByNameFiscal,
  uploadXml,
} from './fiscal';
import { getLowStock, getOrdersByStatus } from './dashboard';

export default function* rootSaga() {
  yield all([
    takeLatest(AuthTypes.REQUEST_AUTH, requestLogin),
    takeLatest(ResetPasswordTypes.RESET_PASSWORD, resetPassword),
    takeLatest(ResetPasswordTypes.SEND_EMAIL, sendEmailResetPassword),
    takeLatest(UserTypes.SESSION_REQUEST, session),
    takeLatest(NovoUsuarioTypes.REQUEST_LOJAS, getLojaByCpnj),
    takeLatest(NovoUsuarioTypes.REQUEST_SAVE, saveNewUser),
    takeLatest(NovoUsuarioTypes.UPDATE_USER, update),
    takeLatest(NovoUsuarioTypes.STATUS_LOADING, changeStatus),
    takeLatest(UsuariosTypes.FILTER_LIST_REQUEST, getUsersByFilter),
    takeLatest(UsuariosTypes.GET_USERS, getUsers),
    takeLatest(LojasTypes.FILTER, getLojasFilter),
    takeLatest(LojasTypes.REQUEST_STORES_BY_NAME, searchStores),
    takeLatest(LojasTypes.REQUEST_PRODUCTS_BY_NAME, searchProductsByName),
    takeLatest(
      LojasTypes.REQUEST_PRODUCTS_BY_STOKE_STORE,
      getStockProductsFromStore
    ),
    takeLatest(LojasTypes.PRODUCT_ADD_OR_REMOVE, moveProducts),
    takeLatest(NovaLojaTypes.GET_CEP, consultaCep),
    takeLatest(NovaLojaTypes.CREATE, createStore),
    takeLatest(NovaLojaTypes.UPDATE, updateStore),
    takeLatest(NovaLojaTypes.ACTIVE_INACTIVE, activeInactive),
    takeLatest(ProdutosTypes.FILTER, getProdutos),
    takeLatest(ProdutosTypes.REQUEST_PROVIDERS, getProviders),
    takeLatest(ProdutosTypes.CREATE_UPDATE_PROVIDER, createUpdateProvider),
    takeLatest(ProdutosTypes.GET_CEP, consultaCepProvider),
    takeLatest(NovoProdutoTypes.CREATE, createProduto),
    takeLatest(NovoProdutoTypes.UPDATE, updateProduto),
    takeLatest(NovoProdutoTypes.ACTIVE_INACTIVE, activeInactiveProduto),
    takeLatest(NovoProdutoTypes.GROUP_LOADING, getProductsGroups),
    takeLatest(NovoProdutoTypes.DETAILS_REQUEST, getProductDetails),
    takeLatest(NovoProdutoTypes.REQUEST_LIST_PROVIDERS, getProvidersList),
    takeLatest(EmpresaTypes.REQUEST_SAVE, saveEmpresa),
    takeLatest(EmpresaTypes.GET_CEP, consultaCepEmpresa),
    takeLatest(EmpresaTypes.REQUEST, getEmpresa),
    takeLatest(UserStoreTypes.CHANGE_STORE, changeStore),
    takeLatest(TransactionTypes.GET_ORDERS, getOrders),
    takeLatest(LayoutTypes.LOADING, startTimeoutButton),
    takeLatest(TransactionDetailsTypes.GET_ORDER, getDetails),
    takeLatest(TransactionDetailsTypes.GET_ITEMS, getItems),
    takeLatest(FiscalTypes.GET_CFOP_REQUEST, getCfop),
    takeLatest(FiscalTypes.GET_COFINS_REQUEST, getCofins),
    takeLatest(FiscalTypes.GET_PIS_REQUEST, getPis),
    takeLatest(FiscalTypes.GET_ICMS_ORIGIN_REQUEST, getIcmsOrigin),
    takeLatest(
      FiscalTypes.GET_ICMS_CALCULATION_BASE_MODE_REQUEST,
      getIcmsCalculationBaseMode
    ),
    takeLatest(FiscalTypes.GET_ICMS_TAX_SITUATION_REQUEST, getIcmsTaxSituation),
    takeLatest(FiscalTypes.UPLOAD_XML, uploadXml),
    takeLatest(FiscalTypes.ENTRY_NFE_REQUEST, entryNFe),
    takeLatest(FiscalTypes.REQUEST_LIST_NFE, getNFeList),
    takeLatest(FiscalTypes.SEARCH_PRODUCTS, searchProductsByNameFiscal),
    takeLatest(TransactionTypes.REQUEST_CANCELLATIONS, getRequestCancellations),
    takeLatest(TransactionTypes.CANCEL_ORDER, confirmOrderCancel),
    takeLatest(TransactionTypes.CONFIRM_ORDER, confirmOrder),
    takeLatest(
      TransactionTypes.SHOPKEEPER_REQUEST_CANCEL,
      shopkeeperRequestCancel
    ),
    takeLatest(DashboardTypes.GET_LOW_STOCK, getLowStock),
    takeLatest(DashboardTypes.GET_ORDERS_BY_STATUS, getOrdersByStatus),
  ]);
}
