import { Grid } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import { Texts } from 'constants/strings';
import { NovoUsuarioActions } from 'store/ducks/novo-usuario';
import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DadosGerais from './components/DadosGerais';
import Lojas from './components/Lojas';
import UpdatePassword from './components/UpdatePassword';

function UsuarioPdv() {
  const dispatch = useDispatch();
  const history = useHistory();

  const reset = useCallback(() => {
    dispatch(NovoUsuarioActions.reset());
  }, [dispatch]);

  useEffect(() => {
    reset();
    if (history.location.state && history.location.state.user) {
      dispatch(NovoUsuarioActions.setEditUser(history.location.state.user));
    }
  }, [dispatch, history, reset]);

  const back = () => {
    history.goBack();
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={ChevronLeft}
            onClick={back}
            title={Texts.usuarios.buttons.voltar}
          />
        </Grid>
        <DadosGerais />
        <Lojas />
        <UpdatePassword />
      </Grid>
    </Main>
  );
}

export { UsuarioPdv };
