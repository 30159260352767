import { action, observable } from 'mobx';
import { ProductApi } from 'api';
import { ResponsePagination } from 'utils/types/response';
import { Product } from 'utils/types/product';
import { RootStore } from './index';

type InjectedApis = {
  productApi: ProductApi;
};

const productsDefault: ResponsePagination<Product> = {
  total: '0',
  perPage: 20,
  page: 1,
  lastPage: 1,
  data: [],
};

export default class ProductStore {
  rootStore: RootStore;

  productApi: ProductApi;

  @observable
  products = productsDefault;

  constructor(rootStore: RootStore, { productApi }: InjectedApis) {
    this.rootStore = rootStore;
    this.productApi = productApi;
  }

  @action
  getProducts = async (
    query?: string
  ): Promise<ResponsePagination<Product>> => {
    const products = await this.productApi.getProducts(query);
    this.products = products;
    return products;
  };
}
