import { makeStyles } from '@material-ui/core';
import Modal from 'components/modal';
import Tabela from 'components/table';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  divTabela: {},
  table: {},
}));

function LowStockModal({ showStore, show, onClose, products }) {
  const classes = useStyles();

  const tableView = useMemo(() => {
    if (showStore) {
      return {
        titles: ['ID', 'Produto', 'Loja', 'Estoque'],
        fields: ['product_id', 'description', 'fantasy_name', 'stock'],
      };
    }
    return {
      titles: ['ID', 'Produto', 'Estoque'],
      fields: ['product_id', 'description', 'stock'],
    };
  }, [showStore]);

  return (
    <Modal show={show} title="Estoque Baixo" onClose={onClose}>
      <div className={classes.divTabela}>
        <Tabela
          titles={tableView.titles}
          values={products}
          fields={tableView.fields}
        />
      </div>
    </Modal>
  );
}

LowStockModal.propTypes = {
  showStore: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      productName: PropTypes.string,
      storeName: PropTypes.string,
      product_id: PropTypes.number,
      stock: PropTypes.number,
    })
  ),
};

LowStockModal.defaultProps = {
  showStore: false,
  onClose: () => {},
  products: [],
};

export default LowStockModal;
