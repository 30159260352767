import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Text from 'components/text';
import { Errors } from 'constants/strings';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30vh',
  },
}));

function ErrorBody({ type }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Text>{type === 'empty' ? Errors.tableEmpty : Errors.tableError}</Text>
    </div>
  );
}

ErrorBody.propTypes = {
  type: PropTypes.oneOf(['empty', 'error']).isRequired,
};

export default ErrorBody;
