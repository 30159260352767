import { Grid } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import Expandable from 'components/expandable';
import TextInfo from 'components/text-info';
import { AddMasks } from 'utils/Masks';

function ValoresTaxas() {
  const nfe = useSelector((state) => state.fiscal.nfe.new.data);

  const currency = (key) => {
    return AddMasks.money(nfe?.total?.ICMSTot[key]) || ' - ';
  };

  return (
    <Expandable title="Valores e Taxas">
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Base de Cálculo ICMS" value={currency('vBC')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Valor ICMS" value={currency('vICMS')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Base de Cálculo ICMS ST" value={currency('vBCST')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Valor ICMS ST" value={currency('vST')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Imposto de Importação" value={currency('vII')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="IPI" value={currency('vIPI')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="PIS" value={currency('vPIS')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="COFINS" value={currency('vCOFINS')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Outras Despesas" value={currency('vOutro')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Valor do Frete" value={currency('vFrete')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo
            label="Valor Total dos Produtos"
            value={currency('vProd')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Descontos" value={currency('vDesc')} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Valor Total da NFE" value={currency('vNF')} />
        </Grid>
      </Grid>
    </Expandable>
  );
}

export default ValoresTaxas;
