import { Button } from 'components/button';
import Modal from 'components/modal';
import SearchSelector from 'components/search-selector';
import Text from 'components/text';
import { reasonsCancellation } from 'constants/config/Config';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

function ReasonCancellation({ show, onSubmit, toogleModal }) {
  const classes = useStyles();
  const [reason, setReason] = useState(reasonsCancellation[0]);

  const onSelect = () => {
    onSubmit(reason);
    toogleModal();
  };

  return (
    <Modal title="Cancelamento" show={show} onClose={toogleModal}>
      <Text style={classes.description}>
        Informe o motivo da solicitação do cancelamento do pedido
      </Text>
      <SearchSelector
        label="Motivo do Cancelamento"
        id="motivo-cancelamento"
        getLabel={(item) => item}
        onChange={({ value }) => setReason(value)}
        options={reasonsCancellation}
        value={reason}
      />
      <Button
        style={classes.button}
        title="Enviar"
        onClick={onSelect}
        colored
      />
    </Modal>
  );
}

ReasonCancellation.propTypes = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toogleModal: PropTypes.func.isRequired,
};

export default ReasonCancellation;
