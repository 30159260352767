import React, { FC, useMemo } from 'react';
import Masks from 'utils/Masks';
import { TextInput } from '../text-input';

type Props = {
  disabled?: boolean;
  id?: string;
  required?: boolean;
  value: string;
  error?: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onlyCpf?: boolean;
  onlyCnpj?: boolean;
};

const InputCpfCnpj: FC<Props> = ({
  disabled = false,
  id = 'cpf_cnpj',
  required = false,
  value,
  error,
  onChange,
  onlyCnpj,
  onlyCpf,
}) => {
  const label = useMemo(() => {
    if (onlyCpf) return 'CPF';
    if (onlyCnpj) return 'CNPJ';
    return 'CPF/CNPJ';
  }, [onlyCnpj, onlyCpf]);
  const mask = useMemo(() => {
    if (onlyCpf) return Masks.cpf_extra;
    if (onlyCnpj) return Masks.cnpj;
    return value?.length > 14 ? Masks.cnpj : Masks.cpf_extra;
  }, [onlyCnpj, onlyCpf, value]);

  return (
    <TextInput
      disabled={disabled}
      label={label}
      id={id}
      required={required}
      mask={mask}
      onChange={onChange}
      value={value}
      error={error}
    />
  );
};

export default InputCpfCnpj;
