import React, { createContext, useCallback, useContext, useState } from 'react';
import { ProdutoApi } from 'services/api/ProdutoApi';
import PropTypes from 'prop-types';
import queryCreator from 'utils/queryCreator';

const ProductContext = createContext(null);

const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState({
    products: [],
    loading: false,
    error: false,
  });

  const getProducts = useCallback(async (description = '') => {
    const query = `?query=${queryCreator.like(
      'description',
      description.trim()
    )}&page=1`;
    setProducts((state) => ({
      ...state,
      loading: true,
      error: false,
    }));
    try {
      const { data } = await ProdutoApi.getProdutos(query);
      setProducts((state) => ({
        ...state,
        loading: false,
        error: false,
        products: data.data,
      }));
    } catch (error) {
      setProducts((state) => ({
        ...state,
        loading: false,
        error: true,
      }));
    }
  }, []);

  return (
    <ProductContext.Provider value={{ products, getProducts }}>
      {children}
    </ProductContext.Provider>
  );
};

const useProducts = () => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProducts must be user within an ProductProvider');
  }
  return context;
};

ProductProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { ProductProvider, useProducts };
