import { makeStyles } from '@material-ui/core';
import React from 'react';
import Loading from 'components/loading';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'components/button';
import { Close } from '@material-ui/icons';
import { LayoutActions } from 'store/ducks/layout';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'absolute',
    backgroundColor: '#20202090',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 9999,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

function GeneralLoading() {
  const dispatch = useDispatch();
  const { showLoading, showLoadingButton } = useSelector(
    (state) => state.layout
  );

  const cancel = () => {
    dispatch(LayoutActions.dismissLoading());
  };

  const classes = useStyles();
  return showLoading ? (
    <div className={classes.container}>
      <Loading size={50} />
      {showLoadingButton && (
        <Button
          icon={Close}
          title="Cancelar"
          style={classes.button}
          colored
          onClick={cancel}
        />
      )}
    </div>
  ) : null;
}

export { GeneralLoading };
