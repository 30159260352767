import { Grid, Step, StepLabel, Stepper } from '@material-ui/core';
import {
  ChevronLeft,
  Close,
  DescriptionRounded,
  PictureAsPdfRounded,
} from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import { Texts } from 'constants/strings';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFiscal } from 'context/FiscalProvider';
import LoadingFailure from 'components/loading-failure';
import { Title } from 'components/title/Title';
import If from 'components/if/If';
import PopupInput from 'components/reason-cancellation';
import { AddMasks } from 'utils/Masks';
import { History } from 'utils/types/fiscal';
import Table from 'components/pagination-table';
import { Nfe as TypeNfe } from 'utils/types/nfe';
import Nfe from './components/Nfe';
import Recipient from './components/Recipient';

type UseFiscalContext = {
  detailsOutputNfe: any;
  cancelNote(params: any, params2: any): void;
  getNfeOutputDetails(params: any): void;
};

function DetalhesNotaSaida() {
  const history = useHistory();
  const {
    detailsOutputNfe,
    cancelNote,
    getNfeOutputDetails,
  }: UseFiscalContext = useFiscal();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getNfeOutputDetails(history?.location?.state || 0);
  }, [history, getNfeOutputDetails]);

  const back = () => {
    history.goBack();
  };

  const toogleModal = () => {
    setModal((oldValue) => !oldValue);
  };

  const onSubmitCancellation = (value: string) => {
    cancelNote(detailsOutputNfe?.data?.nfe_id, value);
  };

  const openFile = (linkFile: string) => {
    window.open(linkFile, '_blank');
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={ChevronLeft}
            onClick={back}
            title={Texts.fiscal.buttons.voltar}
          />
        </Grid>
        <If
          condition={
            detailsOutputNfe?.data &&
            detailsOutputNfe?.data?.plugnotas?.status !== 'CANCELADO'
          }
        >
          <Grid item>
            <Button
              icon={Close}
              onClick={toogleModal}
              title="Cancelar"
              colored
            />
          </Grid>
        </If>
        <If condition={detailsOutputNfe?.data?.plugnotas?.pdf}>
          <Grid item>
            <Button
              icon={PictureAsPdfRounded}
              onClick={() => openFile(detailsOutputNfe?.data?.plugnotas?.pdf)}
              title="Baixar PDF"
              colored
            />
          </Grid>
        </If>
        <If condition={detailsOutputNfe?.data?.plugnotas?.xml}>
          <Grid item>
            <Button
              icon={DescriptionRounded}
              onClick={() => openFile(detailsOutputNfe?.data?.plugnotas?.xml)}
              title="Baixar XML"
              colored
            />
          </Grid>
        </If>
        <LoadingFailure
          failure={detailsOutputNfe.error}
          loading={detailsOutputNfe.loading}
        >
          <If condition={detailsOutputNfe?.data}>
            <>
              <Grid item xs={12} sm={12}>
              {
                detailsOutputNfe?.data?.plugnotas === undefined ? <p>Esta nota não possui informações de geração</p> : <Nfe nfe={detailsOutputNfe?.data?.plugnotas} />
              }
              </Grid>
              <Grid item xs={12} sm={12}>
                <Recipient recipient={detailsOutputNfe?.data?.recipient} />
              </Grid>
              <If condition={!!detailsOutputNfe?.data?.history}>
                <>
                  <Grid item xs={12} sm={12}>
                    <Title variant="h6">Etapas da manifestação da Nota</Title>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stepper
                      activeStep={30}
                      orientation="vertical"
                      style={{ padding: 0 }}
                    >
                      {detailsOutputNfe?.data?.history
                        ?.reverse()
                        .map((item: History) => (
                          <Step key={item.nfe_history_id}>
                            <StepLabel>
                              {`${AddMasks.dateHour(item.created_at)} - ${
                                item.status.description
                              }`}
                            </StepLabel>
                          </Step>
                        ))}
                    </Stepper>
                  </Grid>
                </>
              </If>
              <Grid item xs={12} sm={12}>
                <Title variant="h6">Produtos na nota</Title>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Table
                  labels={['Produto', 'Unidade', 'Valor']}
                  values={detailsOutputNfe?.data?.items}
                  fields={(nfe: TypeNfe) => [
                    nfe.description,
                    nfe.commercial_unit,
                    AddMasks.money(nfe.commercial_unit_value),
                  ]}
                />
              </Grid>
            </>
          </If>
        </LoadingFailure>
      </Grid>
      <PopupInput
        show={modal}
        toogleModal={toogleModal}
        onSubmit={onSubmitCancellation}
        buttonTitle="Cancelar"
        title="Motivo do Cancelamento"
        description="Insira o motivo pelo qual deseja cancelar a nota"
      />
    </Main>
  );
}

export { DetalhesNotaSaida };
