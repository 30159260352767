import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ButtonBack from 'components/button-back';
import Expandable from 'components/expandable';
import If from 'components/if/If';
import Main from 'components/main-container';
import TextInfo from 'components/text-info';
import { useBestClients, useBestClientsOrders } from 'context/best-clients';
import { useClients } from 'context/clients/';
import { useHistory, useParams } from 'react-router-dom';
import Table from 'components/pagination-table';
import { AddMasks } from 'utils/Masks';
import { AddCircle } from '@material-ui/icons';
import RouterNames from 'constants/router-names';
import { BestClientOrder } from 'utils/types/client';
import queryCreator from 'utils/queryCreator';
import { TYPES_PAYMENT } from 'constants/config/Config';
import { Texts } from 'constants/strings';

const BestClientOrders = () => {
  const { getBestClientsOrders, bestclientsOrders } = useBestClientsOrders();
  const { bestClients, getBestClients } = useBestClients();
  const { clients, getClients } = useClients();
  const { clientId } = useParams<{ clientId: string }>();
  const history = useHistory();

  const showDetails = (order: BestClientOrder) => {
    history.push(
      RouterNames.REPORTS_ORDERS_DETALHES.replace(
        ':orderId',
        order.order_id.toString()
      )
    );
  };

  useEffect(() => {
    if (clientId) {
      getBestClients(
        queryCreator.formatter({
          queries: [queryCreator.equal('client_id', clientId)],
        })
      );
      getClients(
        queryCreator.formatter({
          queries: [queryCreator.equal('client_id', clientId)],
        })
      );
      getBestClientsOrders(clientId);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [clientId, getBestClients, getBestClientsOrders, getClients]);

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ButtonBack />
        </Grid>
        <If condition={!!bestClients?.length}>
          <Grid item sm={12} xs={12}>
            <Expandable title={Texts.bestClients.fields.informacoesDoCliente}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label={Texts.bestClients.fields.name}
                    value={bestClients && bestClients[0]?.name}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label={Texts.bestClients.fields.totalDePedidos}
                    value={bestClients && String(bestClients[0]?.total_orders)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label={Texts.bestClients.fields.telefone}
                    value={clients && AddMasks.phone(clients[0]?.person?.phone)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label={Texts.bestClients.fields.totalEmCompras}
                    value={bestClients && AddMasks.money(bestClients[0]?.total)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label={Texts.bestClients.fields.email}
                    value={clients && String(clients[0]?.email)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextInfo
                    label={Texts.bestClients.fields.DataDoUltimoPedido}
                    value={
                      bestClients &&
                      AddMasks.dateISO(bestClients[0]?.last_order)
                    }
                  />
                </Grid>
              </Grid>
            </Expandable>
          </Grid>
        </If>
        <Grid item xs={12} sm={12}>
          <If condition={!!bestclientsOrders}>
            <Table
              labels={Texts.bestClients.tabelaOrdersBestClients}
              values={bestclientsOrders || []}
              fields={(orders) => [
                orders?.order_id.toString(),
                AddMasks.dateISO(orders?.created_at.toString()),
                TYPES_PAYMENT.find(
                  (i) => i.type_payment_id === orders?.type_payment_id
                )?.name || '-',
                AddMasks.money(orders?.subtotal.toString()),
              ]}
              hasAction
              action={showDetails}
              actionLabel="Detalhes"
              Icon={AddCircle}
            />
          </If>
        </Grid>
      </Grid>
    </Main>
  );
};

export { BestClientOrders };
