import styled from 'styled-components';
import background from 'assets/img/background/login.png';

export const Container = styled.div`
  min-height: 100vh;
  padding: 10px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${background});
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2vh 0 5vh;
`;
