import Uris from 'constants/endpoints';
import api from 'services/config/api';
import { LegalNature } from 'utils/types/fiscal';
import { NfeInput, NfeOutput } from 'utils/types/nfe';

interface ResponseOutputNfe {
  status: number;
  data: NfeOutput;
}
interface ResponseInputNfe {
  status: number;
  data: NfeInput;
}

export const FiscalApi = {
  getCfop: (query = '') => {
    return api.get(`${Uris.getCfop}${query}`);
  },
  getPis: () => {
    return api.get(Uris.getPis);
  },
  getCofins: () => {
    return api.get(Uris.getCofins);
  },
  getTypesICMS: (type: string) => {
    return api.get(Uris.getTypesICMS.replace('{typeIcms}', type));
  },
  uploadXML: (xml: any) => {
    const formData = new FormData();
    formData.append('file', xml);
    return api.post(Uris.uploadXML, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  entryNFe: (nfe: string) => api.post(Uris.entryNFe, nfe),
  getNFeList: (params: string, typeNfe: string) =>
    api.get(`${Uris.listNFe}/${typeNfe}${params}`), // typeNfe pode ser output ou input
  createOutputNfe: (nfe: any) => api.post(Uris.createNfeOutput, nfe),
  getOutputNfe: (nfeId: number) =>
    api.get<ResponseOutputNfe>(
      Uris.detalhesNfeSaida.replace('{nfeId}', nfeId.toString())
    ),
  getInputNfe: (nfeId: number) =>
    api.get<ResponseInputNfe>(
      Uris.detalhesNfeEntrada.replace('{nfeId}', nfeId.toString())
    ),
  cancelNote: (nfeId: number, reason: string) =>
    api({
      url: Uris.cancelNote.replace('{nfeId}', nfeId.toString()),
      method: 'DELETE',
      data: { justification: reason },
    }),
  getLegalNature: (params = '') =>
    api.get<Array<LegalNature>>(`/admin/fiscal/legal-nature${params}`),
};
