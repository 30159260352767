import Uris from 'constants/endpoints';
import api from 'services/config/api';

export const UserApi = {
  getSessionUserInfo: () => {
    return api.get(Uris.getSession);
  },
  createNewUser: (body) => {
    return api.post(Uris.createNewUser, body);
  },
  updateUser: (body, idUser) => {
    const url = `${Uris.updateUser}/${idUser}`;
    return api.put(url, body);
  },
  getUsers: (params = '') => {
    const url = `${Uris.getUsers}${params}`;
    return api.get(url);
  },
  updateUserStatus: (active, idUser) => {
    const url = Uris.changeUserStatus.replace('{userId}', idUser);
    return api.patch(url, { active });
  },
};
