import produce from 'immer';
import moment from 'moment';

export const Types = {
  GET_ORDERS: 'orders@GET_ORDERS',
  GET_ORDERS_RESULT: 'orders@GET_ORDERS_RESULT',
  CHANGE_TEXT: 'orders@CHANGE_TEXT',
  CLEAR_FILTER: 'orders@CLEAR_FILTER',
  REQUEST_CANCELLATIONS: 'orders@REQUEST_CANCELLATIONS',
  REQUEST_CANCELLATIONS_RESULT: 'orders@REQUEST_CANCELLATIONS_RESULT',
  CANCEL_ORDER: 'orders@CANCEL_ORDER',
  CONFIRM_ORDER: 'orders@CONFIRM_ORDER',
  SHOPKEEPER_REQUEST_CANCEL: 'orders@SHOPKEEPER_REQUEST_CANCEL',
  SHOPKEEPER_RESPONSE_CANCEL: 'order@SHOPKEEPER_RESPONSE_CANCEL',
};

const initialState = {
  loading: false,
  error: false,
  orders: [],
  order_id: '',
  status: '',
  pagamento: '',
  dateOrder: moment(),
  cancellations: {
    data: [],
    loading: false,
    failure: false,
  },
  shopkeeper: {
    loadingCancelRequest: false,
  },
};

const orders = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ORDERS:
      state.loading = true;
      state.error = false;
      return state;
    case Types.GET_ORDERS_RESULT:
      state.loading = false;
      state.error = payload.error;
      state.orders = payload.result;
      return state;
    case Types.CHANGE_TEXT:
      state[payload.key] = payload.value;
      return state;
    case Types.CLEAR_FILTER:
      state.status = '';
      state.order_id = '';
      state.pagamento = '';
      state.dateOrder = moment();
      return state;
    case Types.REQUEST_CANCELLATIONS:
      state.cancellations.loading = true;
      state.cancellations.failure = false;
      state.cancellations.data = state.cancellations.error
        ? []
        : state.cancellations.data;
      return state;
    case Types.REQUEST_CANCELLATIONS_RESULT:
      state.cancellations.loading = false;
      state.cancellations.failure = payload.error;
      state.cancellations.data = payload.data;
      return state;
    case Types.SHOPKEEPER_REQUEST_CANCEL:
      state.shopkeeper.loadingCancelRequest = true;
      return state;
    case Types.SHOPKEEPER_RESPONSE_CANCEL:
      state.shopkeeper.loadingCancelRequest = false;
      return state;
    default:
      return state;
  }
});

export default orders;

export const OrdersActions = {
  getOrders: (page) => ({
    type: Types.GET_ORDERS,
    payload: page,
  }),
  getOrdersResult: (result, error = false) => ({
    type: Types.GET_ORDERS_RESULT,
    payload: { result, error },
  }),
  changeText: (key, value) => ({
    type: Types.CHANGE_TEXT,
    payload: { key, value },
  }),
  clear: () => ({
    type: Types.CLEAR_FILTER,
  }),
  getRequestsCancellatios: () => ({
    type: Types.REQUEST_CANCELLATIONS,
  }),
  resultRequestCancellations: (data = [], error = false) => ({
    type: Types.REQUEST_CANCELLATIONS_RESULT,
    payload: {
      error,
      data,
    },
  }),
  confirmOrderCancel: (orderId) => ({
    type: Types.CANCEL_ORDER,
    payload: orderId,
  }),
  confirmOrder: (orderId) => ({
    type: Types.CONFIRM_ORDER,
    payload: orderId,
  }),
  shopkeeperRequestCancellation: (orderId, reason) => ({
    type: Types.SHOPKEEPER_REQUEST_CANCEL,
    payload: { orderId, reason },
  }),
  shopkeeperResultRequestCancellation: () => ({
    type: Types.SHOPKEEPER_RESPONSE_CANCEL,
  }),
};
