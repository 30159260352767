import React from 'react';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { Grid, makeStyles } from '@material-ui/core';
import Card from 'components/card';
import { useSelector } from 'react-redux';
import Info from './Info';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    minHeight: '20vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

function ShopperInfo() {
  const classes = useStyles();
  const { order } = useSelector((state) => state.orderDetails);

  let nome = '';
  let telefone = '';
  let email = '';

  if (order) {
    nome = order.client.person.name;
    telefone = order.client.phone;
    email = order.client.email;
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Title>{Texts.order.sessions.comprador}</Title>
        <Card style={classes.card}>
          <Info title={Texts.order.texts.nome} value={nome || ' - '} />
          <Info title={Texts.order.texts.telefone} value={telefone || ' - '} />
          <Info title={Texts.order.texts.email} value={email || ' - '} />
        </Card>
      </Grid>
    </>
  );
}

export default ShopperInfo;
