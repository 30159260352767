import { LayoutActions } from 'store/ducks/layout';
import { delay, put } from 'redux-saga/effects';
import config from 'constants/config';

export function* startTimeoutButton({ payload }) {
  if (payload) {
    yield delay(config.timeToShowCancelButtonGeneralLoading);
    yield put(LayoutActions.showLoadingButton());
  }
}
