import { generateId } from 'utils/generate-id';

const convertBlobToBase64 = (image) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const imageBase64 = (image) => ({
  id: generateId(),
  image,
});

const getBase64FromFile = async (file) => {
  const base64 = await convertBlobToBase64(file);
  return imageBase64(base64);
};

export { getBase64FromFile, imageBase64 };
