import { Texts } from 'constants/strings';
import { Creators as ResetPasswordActions } from 'store/ducks/reset-password';
import { call, put, select } from 'redux-saga/effects';
import { ChangePassword } from 'services/api/ChangePassword';

export function* resetPassword({ payload: token }) {
  try {
    const { password, repeatPassword } = yield select(
      (state) => state.resetPassword
    );
    const regexs = {
      containsNumber: /\d+/,
      containsUppercase: /[A-Z]/,
      containsLowercase: /[a-z]/,
      specialCharacters: /[!@#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g,
    };

    const minCharacters = password.length >= 8;
    const hasUppercase = regexs.containsUppercase.test(password);
    const hasLowercase = regexs.containsLowercase.test(password);
    const hasNumber = regexs.containsNumber.test(password);
    const specialCharacter = regexs.specialCharacters.test(password);
    const passwordRepeatMessage =
      password !== repeatPassword ? Texts.recuperarSenha.diffPasswords : '';

    const checkedSuccess =
      minCharacters &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      specialCharacter &&
      !passwordRepeatMessage;

    if (!checkedSuccess) {
      yield put(
        ResetPasswordActions.checkResult({
          errorMessage: checkedSuccess
            ? ''
            : Texts.recuperarSenha.resetPasswordError,
          passwordRepeatMessage,
          checkedSuccess,
          resetLoading: false,
        })
      );
    } else {
      yield call(ChangePassword.resetPassword, password, token);
      yield put(ResetPasswordActions.successResetPassword());
    }
  } catch (error) {
    yield put(ResetPasswordActions.errorResetPassword());
  }
}

export function* sendEmailResetPassword() {
  try {
    const { email } = yield select((state) => state.resetPassword);
    yield call(ChangePassword.changePasswordSendEmail, email);
    yield put(ResetPasswordActions.successSendEmail());
  } catch (error) {
    const { data, status } = error.response;
    let message = '';
    if (status === 422) {
      message = data[0].message;
    } else if (status === 404) {
      message = data.data;
    }
    yield put(ResetPasswordActions.errorSendEmail(message));
  }
}
