/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TableMaterialUi from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { AddCircle, SvgIconComponent } from '@material-ui/icons';
import Loading from 'components/loading';
import {
  TableHead,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import clsx from 'clsx';
import If from 'components/if/If';
import Text from 'components/text';
import { Errors } from 'constants/strings';

enum TypeErrors {
  Empty = 'empty',
  Error = 'error',
}

interface ErrorType {
  type: TypeErrors;
}

interface TableItem {
  // id: string | number;
}

interface TableProps<T> {
  labels: Array<string>;
  values: Array<T>;
  hasAction?: boolean;
  action?: (tableItem: T) => void;
  fields: (tableItem: T) => Array<any>;
  loading?: boolean;
  error?: boolean;
  Icon?: SvgIconComponent;
  actionLabel?: string;
  style?: string;
  pagination?: boolean;
  perPage?: number;
  total?: number;
  page?: number;
  lastPage?: number;
  onChangePage?: (page: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: '100%',
    },
    alertsContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '30vh',
    },
    head: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
    icon: {
      color: theme.palette.primary.dark,
      fontSize: 25,
    },
    iconButton: {
      border: 'none',
      width: 25,
      height: 25,
    },
    tablerow: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
    pagination: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1.5),
    },
  })
);

const Table = <T extends TableItem>({
  labels,
  values,
  hasAction = false,
  action = () => {},
  fields,
  loading,
  error,
  Icon = AddCircle,
  actionLabel,
  style,
  pagination = false,
  page = 0,
  perPage = 20,
  total = 0,
  onChangePage,
}: TableProps<T>) => {
  const isEmpty = values && !error && values.length === 0;
  const classes = useStyles();

  const Loader = () => (
    <div className={classes.alertsContent}>
      <Loading size={40} />
    </div>
  );

  const Error = ({ type }: ErrorType) => (
    <div className={classes.alertsContent}>
      <Text>
        {type === TypeErrors.Empty ? Errors.tableEmpty : Errors.tableError}
      </Text>
    </div>
  );

  const Header = () => (
    <TableHead>
      <TableRow>
        {labels?.map((title) => (
          <TableCell className={classes.head} key={title} align="center">
            {title}
          </TableCell>
        ))}
        <If condition={hasAction}>
          <TableCell className={classes.head} key="actionLabel" align="center">
            {actionLabel}
          </TableCell>
        </If>
      </TableRow>
    </TableHead>
  );

  const Body = () => (
    <TableBody>
      {values?.map((row, index) => (
        <TableRow className={classes.tablerow} key={index}>
          {fields(row).map((field, indexRow) => (
            <TableCell key={indexRow} align="center">
              {field}
            </TableCell>
          ))}
          <If condition={hasAction}>
            <TableCell align="center">
              <IconButton
                className={classes.iconButton}
                onClick={() => action(row)}
              >
                <Icon className={classes.icon} />
              </IconButton>
            </TableCell>
          </If>
        </TableRow>
      ))}
    </TableBody>
  );

  const handleChange = (event: unknown, newPage: number) => {
    // setPage(newPage);
    if(onChangePage) onChangePage(newPage+1);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {};

  return (
    <TableContainer component={Paper}>
      <TableMaterialUi className={clsx(classes.table, style)} stickyHeader>
        <Header />
        <If condition={!isEmpty && !error && !loading}>
          <Body />
        </If>
      </TableMaterialUi>
      <If condition={loading}>
        <Loader />
      </If>
      <If condition={isEmpty && !loading}>
        <Error type={TypeErrors.Empty} />
      </If>
      <If condition={error}>
        <Error type={TypeErrors.Error} />
      </If>
      <If condition={pagination}>
         <TablePagination
          rowsPerPageOptions={[perPage]}
          count={total}
          rowsPerPage={perPage}
          page={page -1 }
          onPageChange={handleChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </If>
    </TableContainer>
  );
}

export { Table };
