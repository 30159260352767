import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ducks/notification';
import { Client } from 'utils/types/client';
import { ClientsApi } from 'services/api/ClientsApi';

interface ClientsProps {
  children: React.ReactNode;
}

interface Clients {
  Clients: {
    clients?: Client[];
    setClients: (clients: Client[]) => void;
    getClients: (query: string) => void;
  };
}

const ClientsContext = createContext<Clients>({
  Clients: {
    clients: [],
    setClients: () => {},
    getClients: () => {},
  },
});

const ClientProvider = ({ children }: ClientsProps) => {
  const dispatch = useDispatch();
  const [clients, setClients] = useState<Client[]>([]);

  const getClients = useCallback((query: string = '') => {
    ClientsApi.getAllClients(query)
      .then(({ data }) => {
        setClients(data);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(JSON.stringify(error)));
      })
      .finally(() => dispatch(NotificationActions.dismiss()));
  }, [dispatch]);

  return (
    <ClientsContext.Provider
      value={{
        Clients: {
          clients,
          setClients,
          getClients,
        },
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

const useClients = () => {
  const context = useContext(ClientsContext);
  if (!context) {
    throw new Error('useClients must be user within an ClientsProvider');
  }
  return context.Clients;
};

export { ClientProvider, useClients };
