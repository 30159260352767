import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Table from 'components/pagination-table';
import ButtonBack from 'components/button-back';
import Main from 'components/main-container';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { Button } from 'components/button';
import { ArrowForward, Close, Search } from '@material-ui/icons';
import Masks, { AddMasks, RemoveMasks } from 'utils/Masks';
import queryCreator from 'utils/queryCreator';
import { useBestClients } from 'context/best-clients';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ducks/notification';
import { BestClient } from 'utils/types/client';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';

interface FieldsFilter {
  name: string;
  cpf: string;
  minimumOrder: string;
}

const initialFields: FieldsFilter = {
  name: '',
  cpf: '',
  minimumOrder: '',
};

const ReportBestClients = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fields, setFields] = useState<FieldsFilter>(initialFields);
  const { getBestClients, setBestClients, bestClients } = useBestClients();

  const changeText = (event: any) => {
    setFields({ ...fields, [event.target.id]: event.target.value });
  };

  const showDetails = (bestClient: BestClient) => {
    history.push(
      RouterNames.REPORTS_BEST_CLIENTS_ORDERS_DETALHES.replace(
        ':clientId',
        bestClient.client_id.toString()
      )
    );
  };

  const clear = () => setFields(initialFields);

  const filter = useCallback(() => {
    const queries = [];
    const { name, cpf, minimumOrder } = fields;
    if (name.trim()) {
      queries.push(queryCreator.like('name', name));
    }
    if (cpf.trim()) {
      queries.push(queryCreator.equal('cpf', RemoveMasks.onlyNumbers(cpf)));
    }
    if (minimumOrder.trim()) {
      queries.push(
        queryCreator.greaterThanOrEqual('total_orders', minimumOrder)
      );
    }
    setBestClients([]);
    getBestClients(
      queryCreator.formatter({
        queries,
      })
    );
    dispatch(NotificationActions.info('Buscando, aguarde um momento...'));
  }, [fields, dispatch, getBestClients, setBestClients]);
  
  useEffect(() => {
    filter();
    return () => setBestClients([]);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ButtonBack />
        </Grid>
        <Grid item xs={12}>
          <Title>{Texts.bestClients.sessions.filtrarCliente}</Title>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={Texts.bestClients.fields.nameFiltro}
            id="name"
            onChange={changeText}
            value={fields.name}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={Texts.bestClients.fields.CPF}
            id="cpf"
            mask={Masks.cpf}
            onChange={changeText}
            value={fields.cpf}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label={Texts.bestClients.fields.minimumOrder}
            mask={Masks.quantity}
            onChange={changeText}
            id="minimumOrder"
            value={fields.minimumOrder}
          />
        </Grid>
        <Grid item>
          <Button
            icon={Close}
            onClick={clear}
            title={Texts.bestClients.buttons.limpar}
          />
        </Grid>
        <Grid item>
          <Button
            icon={Search}
            onClick={() => filter()}
            title={Texts.bestClients.buttons.buscar}
            colored
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={Texts.bestClients.tabela}
            values={bestClients || []}
            fields={(client) => [
              client?.client_id.toString(),
              client?.name.toString(),
              client?.total_orders.toString(),
              AddMasks.money(client?.total.toString()),
              AddMasks.dateISO(client?.last_order.toString()),
            ]}
            hasAction
            action={showDetails}
            actionLabel="Detalhes"
            Icon={ArrowForward}
          />
        </Grid>
      </Grid>
    </Main>
  );
};

export { ReportBestClients };
