import React, { createContext, useContext, useState } from 'react';
import { BestClientsApi } from 'services/api/BestClientsApi';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ducks/notification';
import { BestClient, BestClientOrder } from 'utils/types/client';
import { useCallback } from 'react';

interface BestClientsProps {
  children: React.ReactNode;
}

interface BestClients {
  bestClients: {
    bestClients?: BestClient[];
    setBestClients: (clients: BestClient[]) => void;
    getBestClients: (query: string) => void;
  };
  bestClientsOrders: {
    bestclientsOrders?: BestClientOrder[];
    getBestClientsOrders: (clientId: string | number) => void;
  };
}

const BestClientsContext = createContext<BestClients>({
  bestClients: {
    bestClients: [],
    setBestClients: () => {},
    getBestClients: () => {},
  },
  bestClientsOrders: {
    bestclientsOrders: [],
    getBestClientsOrders: () => {},
  },
});

const BestClientProvider = ({ children }: BestClientsProps) => {
  const dispatch = useDispatch();
  const [bestClients, setBestClients] = useState<BestClient[]>([]);
  const [bestclientsOrders, setBestclientsOrders] = useState<BestClientOrder[]>(
    []
  );

  const getBestClients = useCallback((query: string = '') => {
    BestClientsApi.getAllBestClients(query)
      .then(({ data }) => {
        setBestClients(data);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(JSON.stringify(error)));
      })
      .finally(() => dispatch(NotificationActions.dismiss()));
  },[dispatch]);

  const getBestClientsOrders = useCallback((clientId: string | number) => {
    BestClientsApi.getAllBestClientsOrders(clientId)
      .then(({ data }) => {
        setBestclientsOrders(data);
      })
      .catch((error) => {
        dispatch(NotificationActions.error(JSON.stringify(error)));
      })
      .finally(() => dispatch(NotificationActions.dismiss()));
  }, [setBestclientsOrders, dispatch]);

  return (
    <BestClientsContext.Provider
      value={{
        bestClients: {
          bestClients,
          setBestClients,
          getBestClients,
        },
        bestClientsOrders: {
          bestclientsOrders,
          getBestClientsOrders,
        },
      }}
    >
      {children}
    </BestClientsContext.Provider>
  );
};

const useBestClients = () => {
  const context = useContext(BestClientsContext);
  if (!context) {
    throw new Error(
      'useBestClients must be user within an BestClientsProvider'
    );
  }
  return context.bestClients;
};

const useBestClientsOrders = () => {
  const context = useContext(BestClientsContext);
  if (!context) {
    throw new Error(
      'useBestClients must be user within an BestClientsProvider'
    );
  }
  return context.bestClientsOrders;
};

export { BestClientProvider, useBestClients, useBestClientsOrders };
