import currency from 'currency.js';

const options = {
  symbol: 'R$ ',
  separator: '.',
  decimal: ',',
  fromCents: true,
};

export const money = {
  formatCurrency: (value) => {
    // add .00 caso não exista
    value = value.toLocaleString('en', {
      useGrouping: false,
      minimumFractionDigits: 2,
    });
    return currency(value, options).format();
  },
  removeCurrency: (value) => {
    value = String(value).replace(/\./g, '').replace(/,/g, '.');
    const a = parseFloat(value.replace(/[^0-9.-]+/g, ''));
    return a;
  },
};
