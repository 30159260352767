import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from 'components/protected-route';
import Login from 'pages/login';
import Main from 'pages/main';
import ResetPassword from 'pages/reset-password';
import RouterNames from 'constants/router-names';
import Unauthorized from 'pages/unauthorized';
import { Privacy } from 'pages/privacy';

function MainRoute() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={RouterNames.LOGIN} component={Login} exact />
        <Route
          path={RouterNames.CHANGE_PASSWORD}
          component={ResetPassword}
          exact
        />
        <Route path={RouterNames.UNAUTHORIZED} exact component={Unauthorized} />
        <Route path={RouterNames.PRIVACY} exact component={Privacy} />
        <ProtectedRoute path={RouterNames.MAIN} component={Main} />
      </Switch>
    </BrowserRouter>
  );
}

export default MainRoute;
