import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');

interface StartEnd {
  start: Moment;
  end: Moment;
}

export const Helpers = {
  today: () => subtractDate(moment(), 0, 'days'),
  thisMonth: () => subtractDate(moment(), 1, 'months'),
  lastWeek: () => subtractDate(moment(), 7, 'days'),
  lastMonth: () => {
    const start = moment().subtract(1, 'months').startOf('month');
    const end = moment().subtract(1, 'months').endOf('month');
    return { start, end };
  },
  currentMonth: (): StartEnd => currentDatesInterval('month'),
  currentWeek: (): StartEnd => currentDatesInterval('week'),
  fortnight: (): StartEnd => subtractDate(moment(), 15, 'days'),
  validateLink: (link: string) => validateLink(link),
};

const subtractDate = (
  date: Moment,
  quantity: number,
  interval: 'days' | 'hours' | 'months'
): StartEnd => {
  const end = date || moment();
  const start = moment(end).subtract(quantity, interval);
  return { start, end };
};

const currentDatesInterval = (type: 'month' | 'week'): StartEnd => {
  const start = moment().startOf(type);
  const end = moment();
  return { start, end };
};

const validateLink = (link: string): boolean => {
  if (!link) return false;
  const regex = new RegExp(/(^http[s]?:\/{2})|(^www)|(^\/{1,2})/, 'gim');
  return regex.test(link);
};
