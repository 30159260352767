import moment from 'moment';
import { money } from './money';

export const RemoveMasks = {
  onlyNumbers: (param) => param.replace(/[^\d]/g, ''),
  dateISO: (date) => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
  currency: (value) =>
    parseFloat(String(value).replace('R$ ', '').replace(',', '.')),
  getNFeNum: (keyNfe = '') => keyNfe.substring(24, 33),
};

export const AddMasks = {
  dateISO: (date) => moment(date).format('DD/MM/YYYY'),
  dateFromNow: (date) => moment(date).fromNow(),
  cpfCnpj: (value) => {
    let v = value.replace(/\D/g, '');
    if (value.length <= 11) {
      v = v.replace(/(\d{3})(\d)/, '$1.$2');
      v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      v = v.replace(/^(\d{2})(\d)/, '$1.$2');
      v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
      v = v.replace(/(\d{4})(\d)/, '$1-$2');
    }
    return v;
  },
  phone: (value) => {
    if (value) {
      let v = value.replace(/\D/g, '');
      v = v.replace(/^(\d{2})(\d)/g, '($1) $2');
      v = v.replace(/(\d)(\d{4})$/, '$1-$2');
      return v;
    }
  },
  dateHour: (datehour) => moment(datehour).format('DD/MM/YYYY HH:mm'),
  money: (value) => (value ? money.formatCurrency(value) : 'R$ 0,00'),
};

export const Validators = {
  nfeKeyIsValid: (nfeKey) => {
    try {
      // validacao simples que pode ser aperfeicoada;
      const nfe = nfeKey.replace(/\D/g, '');
      return nfe.length === 44;
    } catch (error) {
      return false;
    }
  },
};

export default {
  data: '99/99/9999',
  rg: '99999999999999',
  cpf: '999.999.999-99',
  cpf_extra: '999.999.999-999',
  cnpj: '99.999.999/9999-99',
  numNFe: '999999999',
  chaveNFe: '99-9999-99999999999999-99-999-999999999-9-99999999-9',
  celular: '(99) 99999-9999',
  ie: '999999999',
  cep: '99999-999',
  endNumber: '999999',
  ncm: '99999999',
  gtin: '99999999999999',
  onlyNumbers: '99999',
  baseCalculo: '999',
  money: (value, addCurrency) => {
    try {
      value = RemoveMasks.onlyNumbers(String(value));
      if (value) {
        if (value.length === 1) {
          value = `0${value}`;
        }
        value = parseFloat(
          value.replace(/[^\d]/g, '').replace(/(\d\d?)$/, '.$1')
        ).toFixed(2);
      } else {
        value = 0;
      }
      return addCurrency ? money.formatCurrency(value) : value;
    } catch (error) {
      return 0;
    }
  },
  unit: 'aaaaaa',
  pedido: '99999999',
  quantity: '999',
};
