import produce from 'immer';

export const Types = {
  UPDATE_FIELDS: 'usuarios@UPDATE_FIELDS',
  SUCCESS_SAVE: 'usuarios@SUCCESS_SAVE',
  REQUEST_SAVE: 'usuarios@REQUEST_SAVE',
  ERROR_SAVE: 'usuarios@ERROR_SAVE',
  REQUEST_LOJAS: 'usuario@REQUEST_LOJAS',
  SUCCESS_LOJAS: 'usuario@SUCCESS_LOJAS',
  ERROR_LOJAS: 'usuario@ERROR_LOJAS',
  REMOVE_LOJAS_FROM_LIST: 'usuario@REMOVE_LOJAS_FROM_LIST',
  FILTER_LIST_REQUEST: 'usuario@FILTER_LIST_REQUEST',
  FILTER_LIST_SUCCESS: 'usuario@FILTER_LIST_SUCCESS',
  FILTER_LIST_ERROR: 'usuario@FILTER_LIST_ERROR',
  FILTER_CLEAR: 'usuarios@FILTER_CLEAR',
  RESET: 'usuarios@RESET',
  GET_USERS: 'usuarios@GET_USERS',
};

const initialState = {
  filterName: '',
  filterCpf: '',
  filterCNPJ: '',
  filterStatus: '',
  filterTipoUser: '',
  filterList: undefined,
  listLoading: false,
  listError: false,
};

const usuarios = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.UPDATE_FIELDS:
      state[payload.id] = payload.value;
      return state;
    case Types.GET_USERS:
      state.listLoading = true;
      state.listError = false;
      state.filterList = undefined;
      return state;
    case Types.FILTER_LIST_REQUEST:
      state.listLoading = true;
      state.listError = false;
      state.filterList = undefined;
      return state;
    case Types.FILTER_LIST_SUCCESS:
      state.listLoading = false;
      state.listError = false;
      state.filterList = payload;
      return state;
    case Types.FILTER_LIST_ERROR:
      state.listLoading = false;
      state.listError = true;
      state.filterList = undefined;
      return state;
    case Types.FILTER_CLEAR:
      state.filterLoja = '';
      state.filterName = '';
      state.filterStatus = '';
      state.filterTipoUser = '';
      state.filterCNPJ = '';
      return state;
    default:
      return state;
  }
});

export default usuarios;

export const Creators = {
  updateFields: (id, value) => ({
    type: Types.UPDATE_FIELDS,
    payload: { id, value },
  }),
  getUsers: () => ({
    type: Types.GET_USERS,
  }),
  listRequest: () => ({
    type: Types.FILTER_LIST_REQUEST,
  }),
  listError: () => ({
    type: Types.FILTER_LIST_ERROR,
  }),
  listSuccess: (list) => ({
    type: Types.FILTER_LIST_SUCCESS,
    payload: list,
  }),
  clearFilter: () => ({
    type: Types.FILTER_CLEAR,
  }),
};
