import { Grid, Typography } from '@material-ui/core';
import ButtonBack from 'components/button-back';
import Main from 'components/main-container';
import React from 'react';
import { useMessages } from 'context/messages';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from 'components/card';
import { AddMasks } from 'utils/Masks';
import If from 'components/if/If';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 25,
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
    },
    date: {
      color: theme.palette.grey[700],
    },
  })
);

const MessageShopkeeper = () => {
  const classes = useStyles();
  const { message } = useMessages();

  const RawHTML = ({ children }: { children: string }) =>
    React.createElement('div', {
      dangerouslySetInnerHTML: {
        __html: children,
      },
    });

  return (
    <Main>
      <If condition={!!message}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <ButtonBack />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className={classes.title}>{message?.title}</Typography>
            <Typography className={classes.date}>
              {AddMasks.dateFromNow(message?.created_at)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <RawHTML>{message?.message || ''}</RawHTML>
            </Card>
          </Grid>
        </Grid>
      </If>
    </Main>
  );
};

export { MessageShopkeeper };
