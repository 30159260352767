import Uris from 'constants/endpoints';
import api from 'services/config/api';
import { RemoveMasks } from 'utils/Masks';

export const LojaApi = {
  getLojaByCnpj: (cnpj) => {
    cnpj = RemoveMasks.onlyNumbers(cnpj);
    const params = `${Uris.getStores}?query=cpf_cnpj|ilike|${cnpj}`;
    return api.get(params);
  },
  getStores: (params = '') => {
    const url = `${Uris.getStores}${params}`;
    return api.get(url);
  },
  createStore: (data) => {
    return api.post(Uris.createStore, data);
  },
  updateStore: (data, idStore) => {
    return api.put(`${Uris.updateStore}/${idStore}`, data);
  },
  activeInactive: (idStore, active) => {
    const url = Uris.inactiveStore.replace('{storeId}', idStore);
    return api.patch(url, { active });
  },
  getProdutosByStore: (storeId, params = '') => {
    const url = `${Uris.getProductsByStore.replace(
      '{storeId}',
      storeId
    )}${params}`;
    return api.get(url);
  },
};
