import { FiscalApi } from 'services/api/Fiscal';
import { FiscalActions } from 'store/ducks/fiscal';
import { call, put, select } from '@redux-saga/core/effects';
import queryCreator from 'utils/queryCreator';
import { ProdutoApi } from 'services/api/ProdutoApi';
import Masks, { RemoveMasks } from 'utils/Masks';
import { NotificationActions } from 'store/ducks/notification';

export function* getCfop({ payload: cfopCode }) {
  try {
    const query = `?query=${queryCreator.like('code', cfopCode.trim())}&page=1`;

    const { data } = yield call(FiscalApi.getCfop, query);
    const cfops = data.data.map((cfop) => ({
      ...cfop,
      description: `${cfop.code} - ${cfop.description}`,
    }));
    yield put(FiscalActions.getCfopListSuccess(cfops));
  } catch (error) {
    yield put(FiscalActions.getCfopListFailure());
  }
}

export function* getPis() {
  try {
    const { data } = yield call(FiscalApi.getPis);
    const pis = data.map((p) => ({
      id: p.pis_id,
      value: `${p.code} - ${p.description}`,
    }));
    yield put(FiscalActions.getPisSuccess(pis));
  } catch (error) {
    yield put(FiscalActions.getPisFailure());
  }
}
export function* getCofins() {
  try {
    const { data } = yield call(FiscalApi.getCofins);
    const cofins = data.map((cof) => ({
      id: cof.cofins_id,
      value: `${cof.code} - ${cof.description}`,
    }));
    yield put(FiscalActions.getCofinsSuccess(cofins));
  } catch (error) {
    yield put(FiscalActions.getCofinsFailure());
  }
}

export function* getIcmsCalculationBaseMode() {
  try {
    const { data } = yield call(
      FiscalApi.getTypesICMS,
      'calculation-base-mode'
    );
    const values = data.map((value) => ({
      id: value.icms_calculation_base_mode_id,
      value: `${value.code} - ${value.description}`,
    }));
    yield put(FiscalActions.getIcmsCalculationBaseModeSuccess(values));
  } catch (error) {
    yield put(FiscalActions.getIcmsCalculationBaseModeFailure());
  }
}

export function* getIcmsOrigin() {
  try {
    const { data } = yield call(FiscalApi.getTypesICMS, 'origin');
    const origins = data.map((origin) => ({
      id: origin.icms_origin_id,
      value: `${origin.code} - ${origin.description}`,
    }));
    yield put(FiscalActions.getIcmsOriginSuccess(origins));
  } catch (error) {
    yield put(FiscalActions.getIcmsOriginFailure());
  }
}

export function* getIcmsTaxSituation() {
  try {
    const { data } = yield call(FiscalApi.getTypesICMS, 'tax-situation');
    const taxSituations = data.map((tax) => ({
      id: tax.icms_tax_situation_id,
      value: `${tax.code} - ${tax.description}`,
    }));
    yield put(FiscalActions.getIcmsTaxSituationSuccess(taxSituations));
  } catch (error) {
    yield put(FiscalActions.getIcmsTaxSituationFailure());
  }
}

export function* uploadXml({ payload: xml }) {
  try {
    const { data } = yield call(FiscalApi.uploadXML, xml);
    const nfe = {
      ...data.data,
      items: data.data.items.map((item) => ({
        ...item,
        cost_value: Masks.money(parseFloat(item.cost_value).toFixed(2), true),
      })),
    };
    yield put(FiscalActions.uploadXmlResult(nfe, false));
  } catch (error) {
    yield put(FiscalActions.uploadXmlResult(null, true));
  }
}

export function* searchProductsByNameFiscal({ payload: search }) {
  try {
    const query = queryCreator.like('description', search.trim());
    const { data } = yield call(
      ProdutoApi.getProdutos,
      queryCreator.queryFormatter([query])
    );
    yield put(FiscalActions.resultSearchProducts(data.data, false));
  } catch (error) {
    yield put(FiscalActions.resultSearchProducts([], true));
  }
}

export function* entryNFe({ payload: history }) {
  try {
    const { data } = yield select((state) => state.fiscal.nfe.new);
    const nfe = {
      chNFe: data.chNFe,
      provider: data.provider,
      items: data.items.map((item) => ({
        quantity: item.quantity,
        cost_value: RemoveMasks.currency(item.cost_value).toString(),
        sale_value: item.product.sale_value,
        product_id: item.product.product_id.toString(),
      })),
    };
    yield call(FiscalApi.entryNFe, nfe);
    yield put(FiscalActions.resultEntryNFe([]));
    yield put(
      NotificationActions.success('Entrada de nota efetuada com sucesso')
    );
    history.goBack();
  } catch (error) {
    if (error?.response?.data) {
      const errors = error.response.data.map((err) => {
        return err.message[0].message;
      });
      yield put(FiscalActions.resultEntryNFe(errors));
    } else {
      yield put(FiscalActions.resultEntryNFe([]));
    }
    yield put(
      NotificationActions.error(
        'Dados não preenchidos corretamente ou ocorreu um erro ao cadatrar nota'
      )
    );
  }
}

export function* getNFeList({ payload }) {
  try {
    const { chave, num, cnpj } = payload;
    const query = [];
    if (chave.trim()) {
      query.push(queryCreator.like('chave', RemoveMasks.onlyNumbers(chave)));
    }
    if (num.trim()) {
      query.push(queryCreator.like('num', num));
    }
    if (cnpj.trim()) {
      query.push(queryCreator.like('cnpj', RemoveMasks.onlyNumbers(cnpj)));
    }

    yield call(FiscalApi.getNFeList, queryCreator.queryFormatter(query));
    // const list = data.data.map((item) => ({
    //   ...item,
    //   shopkeeperName: item.shopkeeper ? 'LOJISTA' : 'ADMIN', // corrigindo campo true ou false pra tabela
    //   activeName: item.active ? 'ATIVO' : 'INATIVO', // corrigindo campo true ou false pra tabela
    // }));
    yield put(FiscalActions.resultListNFe([], false));
  } catch (error) {
    yield put(FiscalActions.resultListNFe([], true));
  }
}
