import { Grid } from '@material-ui/core';
import React from 'react';
import Expandable from 'components/expandable';
import TextInfo from 'components/text-info';
import { AddMasks } from 'utils/Masks';
import { PlugNotasNfe } from 'utils/types/nfe';

interface Props {
  nfe: PlugNotasNfe;
}

function Nfe({
  nfe: { chave, emissao, status, emitente, destinatario, valor, numero, serie },
}: Props) {
  return (
    <Expandable title="Dados Gerais da NFe">
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <TextInfo label="Chave" value={chave} noWrap />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Data de Emissão" value={emissao} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Número" value={numero} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Série" value={serie} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Status" value={status} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Valor" value={AddMasks.money(valor)} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Emitente" value={emitente} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInfo label="Destinatário" value={destinatario} />
        </Grid>
      </Grid>
    </Expandable>
  );
}

export default Nfe;
