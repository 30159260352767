import { TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}))(TableCell);

function Header({ titles, hasAction, actionLabel }) {
  return (
    <TableHead>
      <TableRow>
        {titles &&
          titles.map((title, index) => (
            <StyledTableCell key={index} align="center">
              {title}
            </StyledTableCell>
          ))}
        {hasAction && (
          <StyledTableCell key={titles.length} align="center">
            {actionLabel}
          </StyledTableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

Header.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  hasAction: PropTypes.bool,
  actionLabel: PropTypes.string,
};

Header.defaultProps = {
  titles: [],
  hasAction: false,
  actionLabel: 'Detalhes',
};

export default Header;
