/* eslint-disable react/jsx-props-no-spreading */
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import Text from 'components/text';
import { Texts } from 'constants/strings';
import { NotificationActions } from 'store/ducks/notification';
import React, { useState, useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { NovoProdutoActions } from 'store/ducks/novo-produto';
import { getBase64FromFile } from 'utils/imageUtils';
import metrics from 'styles/metrics';

const useStyles = makeStyles((theme) => ({
  thumb: {
    display: 'inline-flex',
    width: 150,
    height: 150,
    position: 'relative',
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  input: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100px',
    borderWidth: '2px',
    borderStyle: 'dashed',
    borderColor: (props) =>
      props.dragging ? theme.palette.primary.main : theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
  },
  desc: {
    position: 'relative',
    zIndex: -100,
  },
  overlay: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: `#FFFFFF60`,
    zIndex: 100,
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 40,
  },
  iconButton: {
    width: 44,
    height: 44,
    borderRadius: 22,
    borderWidth: '10px',
    backgroundColor: 'white',
  },
}));

function DragAndDrop() {
  const dispatch = useDispatch();
  const { images, disableFields } = useSelector((state) => state.novoProduto);
  const [dragging, setDragging] = useState(false);
  const [imageHover, setImageHover] = useState(null);
  const classes = useStyles({ dragging });

  const dragMode = (mode = false) => {
    setDragging(mode);
  };

  const files = async (selectedFiles) => {
    const numberOfImages = images.length + selectedFiles.length;
    // número de imagens excede o valor padrão
    if (numberOfImages > metrics.images.maxNumberOfProducts) {
      dispatch(
        NotificationActions.warning(Texts.novoProduto.texts.maxImagesExceded)
      );
      return;
    }

    const selectedImages = await Promise.all(
      selectedFiles.map((file) => {
        return getBase64FromFile(file);
      })
    );
    dispatch(NovoProdutoActions.addImages(selectedImages));
  };

  const removeImageFromList = useCallback(
    (path) => {
      dispatch(NovoProdutoActions.removeImage(path));
      setImageHover(null);
    },
    [dispatch, setImageHover]
  );

  const onDrop = (acceptedFiles, notAccepted) => {
    dragMode();
    files(acceptedFiles);
    if (notAccepted.length > 0) {
      dispatch(
        NotificationActions.warning(Texts.novoProduto.texts.formatoInvalid)
      );
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop,
    onDragEnter: () => dragMode(true),
    onDragLeave: dragMode,
  });

  const thumbs = useMemo(
    () =>
      images.map(({ image, id }) => {
        return (
          <Grid
            key={id}
            item
            onMouseEnter={() => {
              setImageHover(id);
            }}
            onMouseLeave={() => {
              setImageHover(null);
            }}
          >
            <div className={classes.thumb} key={id}>
              {!disableFields && imageHover && imageHover === id && (
                <div className={classes.overlay}>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => removeImageFromList(imageHover)}
                  >
                    <Cancel className={classes.icon} />
                  </IconButton>
                </div>
              )}
              <img src={image} className={classes.img} alt="" />
            </div>
          </Grid>
        );
      }),
    [images, imageHover, classes, disableFields, removeImageFromList]
  );

  return (
    <>
      {!disableFields && (
        <Grid item xs={12} sm={12}>
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={classes.input}
          >
            <input {...getInputProps()} />
            <Text className={classes.desc}>
              {dragging
                ? Texts.novoProduto.texts.drop
                : Texts.novoProduto.texts.drag}
            </Text>
          </div>
        </Grid>
      )}
      {thumbs}
    </>
  );
}

export default DragAndDrop;
