import { Grid } from '@material-ui/core';
import Main from 'components/main-container';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFiscal } from 'context/FiscalProvider';
import LoadingFailure from 'components/loading-failure';
import { Title } from 'components/title/Title';
import If from 'components/if/If';
import { AddMasks } from 'utils/Masks';
import Table from 'components/pagination-table';
import { Nfe as TypeNfe } from 'utils/types/nfe';
import { PlugNotasNFeStatus } from 'utils/enum/fiscal.enum';
import ButtonBack from 'components/button-back';
import Nfe from './components/Nfe';

type useFiscalContext = {
  detailsInputNfe: {
    loading: false;
    error: false;
    data: any;
  };
  getNfeInputDetails(nfeId: any): void;
};

function DetalhesNotaEntrada() {
  const history = useHistory();
  const { detailsInputNfe, getNfeInputDetails }: useFiscalContext = useFiscal();

  useEffect(() => {
    const nfeId = history?.location?.state || 0;
    if (getNfeInputDetails) {
      getNfeInputDetails(nfeId);
    }
  }, [history, getNfeInputDetails]);

  const nfe = detailsInputNfe?.data;

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <ButtonBack />
        </Grid>
        <LoadingFailure
          failure={detailsInputNfe.error}
          loading={detailsInputNfe.loading}
        >
          <If condition={nfe}>
            <>
              <Grid item xs={12} sm={12}>
                <Nfe
                  nfe={{
                    id: nfe?.nfe_id,
                    chave: nfe?.key_nfe,
                    emissao: nfe?.date_emission,
                    status: PlugNotasNFeStatus.Concluido,
                    emitente: nfe?.emitent.social_reason,
                    destinatario: nfe?.recipient?.social_reason,
                    valor: 0,
                    numero: '',
                    serie: '',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Title variant="h6">Produtos na nota</Title>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Table
                  labels={['Produto', 'Unidade', 'Valor']}
                  values={nfe?.items}
                  fields={(nfeInfo: TypeNfe) => [
                    nfeInfo.description,
                    nfeInfo.commercial_unit,
                    AddMasks.money(nfeInfo.commercial_unit_value),
                  ]}
                />
              </Grid>
            </>
          </If>
        </LoadingFailure>
      </Grid>
    </Main>
  );
}

export { DetalhesNotaEntrada };
