import produce from 'immer';
import moment from 'moment';

export const Types = {
  UPDATE_FIELDS: 'nova-loja@UPDATE_FIELDS',
  GET_CEP: 'nova-loja@GET_CEP',
  GET_CEP_ERROR: 'nova-loja@GET_CEP_ERROR',
  GET_CEP_SUCCESS: 'nova-loja@GET_CEP_SUCCESS',
  UPDATE_FIELDS_PROPRIETARIOS: 'nova-loja@UPDATE_FIELDS_PROPRIETARIOS',
  ADD_PROP: 'nova-loja@ADD_PROP',
  REMOVE_PROP: 'nova-loja@REMOVE_PROP',
  ACTIVE_INACTIVE: 'nova-loja@ACTIVE_INACTIVE',
  ACTIVE_INACTIVE_SUCCESS: 'nova-loja@ACTIVE_INACTIVE_SUCCESS',
  ACTIVE_INACTIVE_ERROR: 'nova-loja@ACTIVE_INACTIVE_ERROR',
  UPDATE: 'nova-loja@UPDATE',
  UPDATE_SUCCESS: 'nova-loja@UPDATE_SUCCESS',
  UPDATE_ERROR: 'nova-loja@UPDATE_ERROR',
  CREATE: 'nova-loja@CREATE',
  CREATE_ERR: 'nova-loja@CREATE_ERR',
  CREATE_SUCCESS: 'nova-loja@CREATE_SUCCESS',
  RESET: 'nova-loja@RESET',
  SET_STORE: 'nova-loja@SET_STORE',
};

const prop = {
  name: '',
  nameErr: '',
  birthdate: '',
  birthdateErr: '',
  rg: '',
  rgErr: '',
  cpf: '',
  cpfErr: '',
  phone: '',
  phoneErr: '',
};

const initialState = {
  owners: [prop],
  cpf_cnpj: '',
  cpf_cnpjErr: '',
  social_reason: '',
  ie: '',
  social_reasonErr: '',
  fantasy_name: '',
  fantasy_nameErr: '',
  description_search: '',
  description_searchErr: '',
  matrix_id: '',
  matrix_idErr: '',
  postcode: '',
  postcodeErr: '',
  street: '',
  streetErr: '',
  complemento: '',
  complementoErr: '',
  neighborhood: '',
  neighborhoodErr: '',
  city: '',
  cityErr: '',
  uf: 'CE',
  ufErr: '',
  number: '',
  numberErr: '',
  zoning_code: '',
  zoning_codeErr: '',
  logo: '',
  logoErr: '',
  cepLoading: false,
  active: true,
  activeErr: '',
  email: '',
  emailErr: '',
  phone: '',
  phoneErr: '',
  manager: '',
  managerErr: '',
  updateMode: false,
  updateStore: null,
  loadingInactivate: false,
  saveLoading: false,
  codeCity: '',
  commission: '',
  legal_natureErr: '',
  legalNature: null,
  legal_nature_err: '',
  disableFields: false,
};

const novaLoja = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.UPDATE_FIELDS:
      state[payload.key] = payload.value;
      if (state[`${payload.key}Err`]) {
        state[`${payload.key}Err`] = '';
      }
      return state;
    case Types.UPDATE_FIELDS_PROPRIETARIOS:
      state.owners = state.owners.map((item, index) => {
        if (index === payload.index) {
          item[payload.key] = payload.value;
          if (item[`${payload.key}Err`]) {
            item[`${payload.key}Err`] = '';
          }
        }
        return item;
      });
      return state;
    case Types.GET_CEP:
      state.cepLoading = true;
      return state;
    case Types.GET_CEP_ERROR:
      state.cepLoading = false;
      return state;
    case Types.GET_CEP_SUCCESS:
      state = { ...state, ...payload };
      state.cepLoading = false;
      return state;
    case Types.ADD_PROP:
      state.owners.push(prop);
      return state;
    case Types.REMOVE_PROP:
      if (state.owners.length === 1) {
        return state;
      }
      state.owners.splice(payload, 1);
      return state;
    case Types.ACTIVE_INACTIVE:
      state.loadingInactivate = true;
      return state;
    case Types.ACTIVE_INACTIVE_ERROR:
      state.loadingInactivate = false;
      return state;
    case Types.ACTIVE_INACTIVE_SUCCESS:
      state.updateStore.active = !state.updateStore.active;
      state.disableFields = !state.updateStore.active;
      state.loadingInactivate = false;
      return state;
    case Types.UPDATE:
      state.saveLoading = true;
      return state;
    case Types.UPDATE_ERROR:
      state = { ...state, ...payload };
      if (payload.ownersErr) {
        state.owners = state.owners.map((owner) => ({
          ...owner,
          ...payload.ownersErr,
        }));
      }
      state.saveLoading = false;
      return state;
    case Types.UPDATE_SUCCESS:
      state.saveLoading = false;
      return state;
    case Types.CREATE:
      state.saveLoading = true;
      return state;
    case Types.CREATE_ERR:
      state = { ...state, ...payload };
      if (payload.ownersErr) {
        state.owners = state.owners.map((owner) => ({
          ...owner,
          ...payload.ownersErr,
        }));
      }
      state.saveLoading = false;
      return state;
    case Types.CREATE_SUCCESS:
      state.saveLoading = false;
      return state;
    case Types.RESET:
      return initialState;
    case Types.SET_STORE:
      Object.keys(payload).forEach((key) => {
        if (key === 'address') {
          state.postcode = payload.address.postcode;
          state.street = payload.address.street;
          state.neighborhood = payload.address.neighborhood;
          state.city = payload.address.city;
          state.uf = payload.address.uf;
        } else if (key === 'owners') {
          if (payload.owners.length === 0) {
            state.owners = [prop];
          } else {
            state.owners = payload.owners.map((owner) => {
              return {
                name: owner.person.name,
                cpf: owner.person.cpf,
                rg: owner.person.rg,
                birthdate:
                  moment(owner.person.birthdate).format('DD/MM/YYYY') || '',
                phone: owner.person.phone,
              };
            });
          }
        } else if (payload[key]) {
          state[key] = payload[key];
        }
      });
      state.commission = payload.currentCommission.commission || 0;
      state.updateMode = true;
      state.disableFields = !payload.active;
      state.updateStore = payload;
      return state;
    default:
      return state;
  }
});

export default novaLoja;

export const NovaLojaActions = {
  updateFields: (key, value) => ({
    type: Types.UPDATE_FIELDS,
    payload: { key, value },
  }),
  updateFieldsProp: (key, value, index) => ({
    type: Types.UPDATE_FIELDS_PROPRIETARIOS,
    payload: { key, value, index },
  }),
  getCep: () => ({
    type: Types.GET_CEP,
  }),
  addProp: () => ({
    type: Types.ADD_PROP,
  }),
  removeProp: (index) => ({
    type: Types.REMOVE_PROP,
    payload: index,
  }),
  getCepError: () => ({
    type: Types.GET_CEP_ERROR,
  }),
  getCepSuccess: (payload) => ({
    type: Types.GET_CEP_SUCCESS,
    payload,
  }),
  activeInactive: () => ({
    type: Types.ACTIVE_INACTIVE,
  }),
  activeInactiveErr: () => ({
    type: Types.ACTIVE_INACTIVE_ERROR,
  }),
  activeInactiveSuccess: () => ({
    type: Types.ACTIVE_INACTIVE_SUCCESS,
  }),
  update: () => ({
    type: Types.UPDATE,
  }),
  updateSuccess: () => ({
    type: Types.UPDATE_SUCCESS,
  }),
  updateErr: (errors = {}) => ({
    type: Types.UPDATE_ERROR,
    payload: errors,
  }),
  create: () => ({
    type: Types.CREATE,
  }),
  createErr: (errors = {}) => ({
    type: Types.CREATE_ERR,
    payload: errors,
  }),
  createSuccess: () => ({
    type: Types.CREATE_SUCCESS,
  }),
  reset: () => ({
    type: Types.RESET,
  }),
  setEditStore: (store) => ({
    type: Types.SET_STORE,
    payload: store,
  }),
};
