import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import Text from 'components/text';
import RouterNames from 'constants/router-names';
import { UserStoreActions } from 'store/ducks/user-store';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import metrics from 'styles/metrics';
import { reduceName, generateName } from './utils/utils';

const { userButton } = metrics;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    margin: '0px 15px 0px 5px',
  },
  name: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  nameContainer: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.main,
    border: '3px solid #FFF',
    justifyContent: 'center',
    alignItems: 'center',
    height: userButton.containerNameSize,
    borderRadius: userButton.radius,
    width: userButton.containerNameSize,
    position: 'relative',
    left: 10,
    zIndex: 1,
  },
  button: {
    backgroundColor: theme.palette.common.white,
    height: userButton.buttonHeight,
    width: userButton.buttonWidth,
    fontWeight: 'normal',
    fontSize: '13px',
    textTransform: 'none',
  },
  text: {
    fontWeight: 'normal',
    fontSize: '13px',
    textTransform: 'none',
  },
}));

function User() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { person, profile } = useSelector((state) => state.user.user);
  const { actualStore, stores } = useSelector((state) => state.userStores);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(
    stores.findIndex((item) => item.store_id === actualStore.store_id) || 0
  );

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    dispatch(UserStoreActions.changeStore(index));
    history.push(RouterNames.MAIN);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const storeSelector = () => {
    return (
      <Button
        className={classes.button}
        endIcon={<ArrowDropDown />}
        onClick={handleClickListItem}
      >
        {reduceName(actualStore.fantasy_name)}
      </Button>
    );
  };

  const userName = () => {
    return (
      <Button className={classes.button}>
        <Text className={classes.text}>{reduceName(person.name)}</Text>
      </Button>
    );
  };

  const callDetailsPdv = () => {
    history.push(RouterNames.PDV_USUARIO);
  };

  return (
    <div className={classes.container}>
      <div className={classes.nameContainer}>
        <Button onClick={callDetailsPdv}>
          <Typography className={classes.name}>
            {generateName(person.name)}
          </Typography>
        </Button>
      </div>
      {profile.profile === 'Admin' ? userName() : storeSelector()}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {stores.map((option, index) => (
          <MenuItem
            key={`MenuItem-${index}-${option}`}
            selected={index === selectedIndex}
            disabled={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.fantasy_name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export { User };
