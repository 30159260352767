import { IconButton, makeStyles } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import theme from 'styles/theme';

const useStyles = makeStyles(() => ({
  icon: {
    color: (props) => props.color,
    fontSize: (props) => props.size,
  },
  iconButton: {
    marginLeft: 'auto',
    border: 'none',
    width: '10%',
    marginRight: '5px',
    paddingBottom: '5px',
    borderRadius: 0,
  },
}));

function CloseModal({ onClick, color, size }) {
  const classes = useStyles({ color, size });
  return (
    <IconButton className={classes.iconButton} onClick={onClick}>
      <Cancel className={classes.icon} />
    </IconButton>
  );
}

CloseModal.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.number,
};

CloseModal.defaultProps = {
  onClick: () => {},
  color: theme.palette.primary.dark,
  size: 35,
};

export { CloseModal };
