import { Grid } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import { Button } from 'components/button';
import DateSelector, { DateSelectorChange } from 'components/date-selector';
import DatesInterval from 'components/dates-interval';
import If from 'components/if/If';
import {
  Intervals,
  IntervalSelector,
  IntervalSelectorEvent,
} from 'components/interval-selector/IntervalSelector';
import MovimentationIcon from 'components/movimentation-icon/MovimentationIcon';
import Table from 'components/pagination-table';
import ProductSelector from 'components/product-selector';
import StoreSelector from 'components/store-selector';
import UpDownArrow from 'components/up-down-arrow/UpDownArrow';
import { useMovesStores } from 'context/stocks';
import moment, { Moment } from 'moment';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ducks/notification';
import queryCreator from 'utils/queryCreator';
import { Product } from 'utils/types/product';
import { Store } from 'utils/types/store';

type IdValue = {
  id: string;
  value: any;
};

interface Fields {
  product?: Product;
  start?: Moment;
  end?: Moment;
  stand?: Store;
}

const initialFields: Fields = {
  product: undefined,
  start: moment(),
  end: moment(),
  stand: undefined,
};

const StoreStockMov = () => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState<Fields>(initialFields);
  const { store, getStoreStock } = useMovesStores();
  const [typeInterval, setTypeInterval] = useState(Intervals.Today);

  const clear = () => {
    setFields(initialFields);
    setTypeInterval(Intervals.Today);
  };

  const onSelectPeriod = (period: IntervalSelectorEvent) => {
    setTypeInterval(period.interval);
    setFields((state) => ({ ...state, start: period.start, end: period.end }));
  };

  const onChange = ({ id, value }: IdValue | DateSelectorChange) => {
    setFields((state) => ({ ...state, [id]: value }));
  };

  const filter = useCallback(async () => {
    const { product, start, end, stand } = fields;
    if (!start || !end) {
      dispatch(NotificationActions.warning('Selecione o intervalo de datas'));
      return;
    }
    const queries = [];
    if (stand) {
      queries.push(queryCreator.equal('store_id', stand.store_id));
    }
    queries.push(
      queryCreator.interval(
        'logStockStore.created_at',
        start.format('YYYY-MM-DD'),
        end.format('YYYY-MM-DD')
      )
    );
    if (product) {
      queries.push(
        queryCreator.equal('logStockStore.product_id', product.product_id)
      );
    }
    getStoreStock(
      queryCreator.formatter({
        queries,
      })
    );
  }, [fields, dispatch, getStoreStock]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <IntervalSelector
          onChange={onSelectPeriod}
          id="interval"
          label="Período"
          value={typeInterval}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <StoreSelector onChange={onChange} id="stand" value={fields.stand} />
      </Grid>
      <If condition={!!fields.stand}>
        <Grid item xs={12} sm={6}>
          <ProductSelector
            onChange={onChange}
            id="product"
            value={fields?.product}
          />
        </Grid>
        <If condition={fields.stand && typeInterval === Intervals.Custom}>
          <Grid item xs={12} sm={3}>
            <DateSelector
              id="start"
              type="date"
              label="Data inicial"
              value={fields.start}
              onChange={onChange}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DateSelector
              id="end"
              type="date"
              label="Data Final"
              value={fields.end}
              onChange={onChange}
              disableFuture
            />
          </Grid>
        </If>
        <Grid item>
          <Button icon={Close} onClick={clear} title="Limpar" />
        </Grid>
        <Grid item>
          <Button icon={Search} onClick={filter} title="Filtrar" colored />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DatesInterval
            start={fields?.start || moment()}
            end={fields?.end || moment()}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={[
              'Data',
              'Produto',
              'Ação',
              'Operação',
              'Movimentado',
              'Estoque',
            ]}
            values={store?.logStockStore || []}
            fields={(log) => [
              moment(log.created_at).format('DD/MM/YYYY HH:mm'),
              log.product.description,
              <UpDownArrow type={log.action} />,
              <MovimentationIcon type={log.reason} />,
              log.quantity,
              log.after_stock,
            ]}
          />
        </Grid>
      </If>
    </Grid>
  );
};

export { StoreStockMov };
