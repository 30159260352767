import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { AddCircle } from '@material-ui/icons';

import clsx from 'clsx';
import LoadingBody from './components/LoadingBody';
import Body from './components/Body';
import Header from './components/Header';
import ErrorBody from './components/ErrorBody';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: '100%',
  },
}));

export default function Tabela({
  titles,
  values,
  hasAction,
  action,
  fields,
  loading,
  error,
  icon,
  actionLabel,
  style,
}) {
  const isEmpty = values && !error && values.length === 0;
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={clsx(classes.table, style)} stickyHeader>
        <Header
          titles={titles}
          hasAction={hasAction}
          actionLabel={actionLabel}
        />
        {!isEmpty && !error && !loading && (
          <Body
            values={values}
            hasAction={hasAction}
            action={action}
            fields={fields}
            icon={icon}
          />
        )}
      </Table>
      {loading && <LoadingBody />}
      {isEmpty && !loading && <ErrorBody type="empty" />}
      {error && <ErrorBody type="error" />}
    </TableContainer>
  );
}

Tabela.propTypes = {
  titles: PropTypes.arrayOf(PropTypes.string),
  values: PropTypes.arrayOf(PropTypes.object),
  hasAction: PropTypes.bool,
  action: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  icon: PropTypes.elementType,
  actionLabel: PropTypes.string,
  style: PropTypes.string,
};

Tabela.defaultProps = {
  titles: [],
  values: [],
  hasAction: false,
  action: () => {},
  loading: false,
  error: false,
  icon: AddCircle,
  actionLabel: 'Detalhes',
  style: '',
};

export { Tabela };
