import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import theme from 'styles/theme';

const useStyles = makeStyles(() => ({
  circular: {
    color: (props) => props.color,
  },
}));

function Loading({ color, size }) {
  const classes = useStyles({ color });
  return <CircularProgress className={classes.circular} size={size} />;
}

Loading.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

Loading.defaultProps = {
  color: theme.palette.primary.main,
  size: 24,
};

export { Loading };
