import { Grid } from '@material-ui/core';
import React from 'react';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { TextInput } from 'components/text-input';
import { useDispatch, useSelector } from 'react-redux';
import Masks from 'utils/Masks';
import { EmpresaActions } from 'store/ducks/empresa';

function DadosGerais() {
  const {
    cpf_cnpj,
    cpf_cnpjErr,
    ie,
    ieErr,
    disableFields,
    social_reason,
    social_reasonErr,
    fantasy_name,
    fantasy_nameErr,
  } = useSelector((state) => state.empresa);
  const dispatch = useDispatch();

  const changeText = (event) => {
    dispatch(EmpresaActions.fields(event.target.id, event.target.value));
  };

  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.empresa.sessions.gerais}</Title>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.usuarios.inputLabels.cnpj}
          id="cpf_cnpj"
          disabled={disableFields}
          mask={Masks.cnpj}
          error={cpf_cnpjErr}
          onChange={changeText}
          value={cpf_cnpj}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.empresa.fields.ie}
          id="ie"
          disabled={disableFields}
          mask={Masks.ie}
          onChange={changeText}
          error={ieErr}
          value={ie}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          disabled={disableFields}
          label={Texts.empresa.fields.razaoSocial}
          id="social_reason"
          onChange={changeText}
          error={social_reasonErr}
          value={social_reason}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          label={Texts.empresa.fields.nomeFantasia}
          id="fantasy_name"
          disabled={disableFields}
          onChange={changeText}
          error={fantasy_nameErr}
          value={fantasy_name}
        />
      </Grid>
    </>
  );
}

export default DadosGerais;
