import React, { useEffect } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import Card from 'components/card';
import Text from 'components/text';
import { Title } from 'components/title/Title';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import { useMessages } from 'context/messages';
import { Message } from 'utils/types/message';
import If from 'components/if/If';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridMessage: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    icon: {
      color: theme.palette.primary.main,
    },
    line: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      backgroundColor: theme.palette.primary.main,
      opacity: 0.3,
      height: 0.2,
    },
    empty: {
      textAlign: 'center',
      flex: 1,
    },
  })
);

function LastMessages() {
  const history = useHistory();
  const classes = useStyles();
  const { getMessagesLojista, setMessage, messages } = useMessages();
  useEffect(() => {
    getMessagesLojista();
  }, [getMessagesLojista]);

  const onSelectMessage = (message: Message) => {
    setMessage(message);
    history.push(RouterNames.ADMIN_ATUALIZACOES_SHOPKEEPER);
  };

  const msgs = messages.slice(0, 2);

  return (
    <>
      <Title>Novidades</Title>
      <Card>
        <Grid container>
          <If condition={msgs.length > 0}>
            <>
              {msgs.map((message: Message, index: number) => (
                <>
                  <Grid item className={classes.gridMessage} xs={12} sm={12}>
                    <Text>{message.title}</Text>
                    <IconButton
                      size="small"
                      onClick={() => onSelectMessage(message)}
                    >
                      <ArrowForward className={classes.icon} />
                    </IconButton>
                  </Grid>
                  <If condition={index < msgs.length - 1}>
                    <Grid item xs={12} sm={12} className={classes.line} />
                  </If>
                </>
              ))}
            </>
          </If>
          <If condition={msgs.length === 0}>
            <Text style={classes.empty}>Não há mensagens</Text>
          </If>
        </Grid>
      </Card>
    </>
  );
}

export default LastMessages;
