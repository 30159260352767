import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Names from 'constants/router-names';
import { useSelector } from 'react-redux';

function ProtectedRoute({ component: Component, ...props }) {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);

  const isAuthenticated = token && user;

  return isAuthenticated ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...props} component={Component} />
  ) : (
    <Redirect to={{ pathname: Names.LOGIN }} />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
};

// Same approach for defaultProps too
ProtectedRoute.defaultProps = {
  component: () => <></>,
};

export { ProtectedRoute };
