import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import User from 'components/user';
import Menu from 'components/drawer/components/Menu';
import LogoDrawer from 'components/drawer/components/Logo';
import AppToolbar from 'components/toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutActions } from 'store/ducks/layout';
import metrics from 'styles/metrics';

const drawerWidth = metrics.drawer.width;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    background: (props) =>
      props.drawerOpen
        ? `linear-gradient(180deg, ${theme.palette.primary.main} 40%, ${theme.palette.secondary.main} 100%)`
        : 'inherit',
    width: drawerWidth,
    borderRight: 0,
  },
}));

export default function MainDrawer({ window }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.layout);
  const classes = useStyles({ drawerOpen });

  const handleDrawerToggle = () => {
    dispatch(LayoutActions.handleDrawer(false));
  };

  const drawer = (
    <>
      <LogoDrawer />
      <User />
      <Menu />
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <AppToolbar />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={drawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

MainDrawer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  window: PropTypes.func,
};
