import produce from 'immer';
import moment from 'moment';

export const Types = {
  UPDATE_FIELDS: 'novoUsuario@UPDATE_FIELDS',
  SUCCESS_SAVE: 'novoUsuario@SUCCESS_SAVE',
  REQUEST_SAVE: 'novoUsuario@REQUEST_SAVE',
  ERROR_SAVE: 'novoUsuario@ERROR_SAVE',
  REQUEST_LOJAS: 'novoUsuario@REQUEST_LOJAS',
  SUCCESS_LOJAS: 'novoUsuario@SUCCESS_LOJAS',
  ERROR_LOJAS: 'novoUsuario@ERROR_LOJAS',
  REMOVE_LOJAS_FROM_LIST: 'novoUsuario@REMOVE_LOJAS_FROM_LIST',
  FILTER_LIST_REQUEST: 'novoUsuario@FILTER_LIST_REQUEST',
  FILTER_LIST_SUCCESS: 'novoUsuario@FILTER_LIST_SUCCESS',
  FILTER_LIST_ERROR: 'novoUsuario@FILTER_LIST_ERROR',
  FILTER_CLEAR: 'novoUsuario@FILTER_CLEAR',
  SET_USER_EDIT: 'novoUsuario@SET_USER_EDIT',
  UPDATE_USER: 'novoUsuario@UPDATE_USER',
  STATUS_LOADING: 'novoUsuario@STATUS_LOADING',
  STATUS_ERROR: 'novoUsuario@STATUS_ERROR',
  STATUS_SUCCESS: 'novoUsuario@STATUS_SUCCESS',
  RESET: 'novoUsuario@RESET',
};

const initialState = {
  name: '',
  phone: '',
  rg: '',
  rgErr: '',
  cpf: '',
  email: '',
  birthdate: '',
  phoneErr: '',
  nameErr: '',
  cpfErr: '',
  emailErr: '',
  profile_idErr: '',
  birthdateErr: '',
  profile_id: null,
  cnpjSearch: '',
  stores: [],
  saveLoading: false,
  saveError: false,
  saveSuccess: false,
  showModalLojas: false,
  loadingSelecionarLoja: false,
  storesErr: '',
  updateUser: undefined,
  updateMode: false,
  loadingInactivated: false,
  errorInactivated: '',
  successInactivated: false,
  disableFields: false,
};

const novoUsuario = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.UPDATE_FIELDS:
      state[payload.id] = payload.value;
      if (state[`${payload.id}Err`]) {
        state[`${payload.id}Err`] = '';
      }
      return state;
    case Types.RESET:
      return initialState;
    case Types.SUCCESS_SAVE:
      state.saveLoading = false;
      state.saveError = false;
      state.saveSuccess = true;
      state.phoneErr = '';
      state.nameErr = '';
      state.cpfErr = '';
      state.emailErr = '';
      state.profile_idErr = '';
      state.birthdateErr = '';
      return state;
    case Types.ERROR_SAVE:
      state = { ...state, ...payload };
      state.saveLoading = false;
      state.saveSuccess = false;
      return state;
    case Types.REQUEST_SAVE:
      state.saveLoading = true;
      state.saveError = false;
      state.saveSuccess = false;
      return state;
    case Types.REQUEST_LOJAS:
      state.loadingSelecionarLoja = true;
      state.storesErr = '';
      return state;
    case Types.ERROR_LOJAS:
      state.loadingSelecionarLoja = false;
      state.storesErr = payload;
      return state;
    case Types.SUCCESS_LOJAS:
      state.loadingSelecionarLoja = false;
      state.storesErr = '';
      state.cnpjSearch = '';
      state.stores = payload;
      return state;
    case Types.UPDATE_USER:
      state.saveLoading = true;
      state.saveError = false;
      state.saveSuccess = false;
      return state;
    case Types.REMOVE_LOJAS_FROM_LIST:
      state.stores = state.stores.filter((loja) => loja.store_id !== payload);
      return state;
    case Types.SET_USER_EDIT:
      state.updateUser = payload;
      state.updateMode = true;
      state.name = payload.person.name || '';
      state.rg = payload.person.rg || '';
      state.cpf = payload.person.cpf || '';
      state.stores = payload.storeUser.map((store) => store.stores);
      state.phone = payload.person.phone || '';
      state.birthdate =
        moment(payload.person.birthdate).format('DD/MM/YYYY') || '';
      state.profile_id = payload.profile_id;
      state.email = payload.email || '';
      state.disableFields = !payload.active;
      return state;
    case Types.STATUS_LOADING:
      state.errorInactivated = '';
      state.loadingInactivated = true;
      state.successInactivated = false;
      return state;
    case Types.STATUS_ERROR:
      state.errorInactivated = payload;
      state.loadingInactivated = false;
      state.successInactivated = false;
      return state;
    case Types.STATUS_SUCCESS:
      state.errorInactivated = '';
      state.loadingInactivated = false;
      state.successInactivated = true;
      state.updateUser.active = !state.updateUser.active;
      state.disableFields = !state.updateUser.active;
      return state;
    default:
      return state;
  }
});

export default novoUsuario;

export const NovoUsuarioActions = {
  updateFields: (id, value) => ({
    type: Types.UPDATE_FIELDS,
    payload: { id, value },
  }),
  setEditUser: (user) => ({
    type: Types.SET_USER_EDIT,
    payload: user,
  }),
  saveUser: () => ({
    type: Types.REQUEST_SAVE,
  }),
  errorOnSaveUser: (error) => ({
    type: Types.ERROR_SAVE,
    payload: error,
  }),
  successOnSaveUser: () => ({
    type: Types.SUCCESS_SAVE,
  }),
  searchLoja: () => ({
    type: Types.REQUEST_LOJAS,
  }),
  searchLojaError: (payload) => ({
    type: Types.ERROR_LOJAS,
    payload,
  }),
  searchLojaSuccess: (list) => ({
    type: Types.SUCCESS_LOJAS,
    payload: list,
  }),
  removeLojaFromList: (index) => ({
    type: Types.REMOVE_LOJAS_FROM_LIST,
    payload: index,
  }),
  reset: () => ({
    type: Types.RESET,
  }),
  updateUser: () => ({
    type: Types.UPDATE_USER,
  }),
  changeStatusRequest: () => ({
    type: Types.STATUS_LOADING,
  }),
  changeStatusError: () => ({
    type: Types.STATUS_ERROR,
  }),
  changeStatusSuccess: () => ({
    type: Types.STATUS_SUCCESS,
  }),
};
