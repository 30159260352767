import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  makeStyles,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ReactInputMask from 'react-input-mask';
import clsx from 'clsx';
import Masks from 'utils/Masks';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  label: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.common.white,
    '&$focused': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      paddingRight: '5px',
    },
  },
  focused: {},
  icon: {
    color: theme.palette.common.white,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
}));

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    '& $notchedOutline': {
      color: theme.palette.grey[700],
      borderWidth: '1px',
      borderRadius: theme.shape.borderRadius,
    },
    '&:hover $notchedOutline': {
      color: theme.palette.grey[700],
      borderWidth: '1px',
      borderRadius: theme.shape.borderRadius,
    },
    '&$focused $notchedOutline': {
      color: theme.palette.grey[700],
      borderWidth: '2px',
      borderRadius: theme.shape.borderRadius,
    },
  },
  focused: {},
  notchedOutline: {},
}));

function TextInput({
  label,
  value,
  onChange,
  isPass,
  error,
  formStyle,
  required,
  disabled,
  mask,
  id,
  money,
  multiline,
  number,
}) {
  const [visible, setVisible] = useState(true);
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  const passwordIcon = () => (
    <IconButton onClick={() => setVisible(!visible)} edge="end">
      {visible ? (
        <VisibilityOff className={classes.icon} />
      ) : (
        <Visibility className={classes.icon} />
      )}
    </IconButton>
  );

  const moneyMask = (event) => {
    const maskedValue = Masks.money(event.target.value, money);
    onChange({
      target: {
        id: event.target.id,
        value: maskedValue,
      },
    });
  };

  const onChangeText = (event) => {
    if (money || number) {
      moneyMask(event);
    } else {
      onChange(event);
    }
  };

  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      className={clsx(classes.form, formStyle)}
    >
      <InputLabel
        required={required}
        classes={{
          root: classes.label,
          focused: classes.focused,
        }}
      >
        {label}
      </InputLabel>
      <ReactInputMask
        mask={mask}
        value={value}
        onChange={onChangeText}
        maskPlaceholder=""
      >
        <OutlinedInput
          id={id}
          classes={outlinedInputClasses}
          type={isPass && visible ? 'password' : 'text'}
          error={!!error}
          // onChange={onChangeText}
          endAdornment={isPass ? passwordIcon() : null}
          labelWidth={70}
          // TODO: Resolver o bug do multiline
          multiline={multiline}
        />
      </ReactInputMask>
      {error && (
        <FormHelperText>
          <Typography className={classes.error}>{error}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
}

TextInput.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  isPass: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formStyle: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  money: PropTypes.bool,
  multiline: PropTypes.bool,
  number: PropTypes.bool,
};

TextInput.defaultProps = {
  error: '',
  mask: null,
  id: null,
  isPass: false,
  label: '',
  onChange: () => {},
  value: '',
  formStyle: '',
  required: false,
  disabled: false,
  money: false,
  multiline: false,
  number: false, // Entrada de valores sem o prefixo R$
};

export { TextInput };
