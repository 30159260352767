import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutActions } from 'store/ducks/layout';
import MenuIcon from '@material-ui/icons/Menu';
import metrics from 'styles/metrics';
import { Title } from 'components/title/Title';
import ToolbarMenu from 'components/toolbar-menu';

const drawerWidth = metrics.drawer.width;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

function AppToolbar() {
  const dispatch = useDispatch();
  const { toolbarTitle } = useSelector((state) => state.layout);
  const classes = useStyles();
  const theme = useTheme();

  const handleDrawerOpen = () => {
    dispatch(LayoutActions.handleDrawer(true));
  };

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar>
        <IconButton
          edge="start"
          onClick={handleDrawerOpen}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Title color={theme.palette.common.white}>{toolbarTitle}</Title>
        <ToolbarMenu />
      </Toolbar>
    </AppBar>
  );
}

export { AppToolbar };
