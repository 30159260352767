import { Grid } from '@material-ui/core';
import Main from 'components/main-container';
import React from 'react';
import { useSelector } from 'react-redux';
import { status_order } from 'constants/config/Config';
import Table from 'components/pagination-table';
import { AddMasks } from 'utils/Masks';
import { useCancel } from 'context/orders';
import { useHistory } from 'react-router-dom';
import RouterNames from 'constants/router-names';
import { ArrowForward } from '@material-ui/icons';

function Cancellations() {
  const { setSelected } = useCancel();
  const history = useHistory();
  const { loading, failure } = useSelector(
    (state) => state.orders.cancellations
  );
  const { sales } = useSelector((state) => state.dashboard);
  const cancelations = sales[status_order.REQUESTED_CANCELLATION];

  const action = (order) => {
    setSelected(order);
    history.push(RouterNames.CANCELLATIONS_DETALHES);
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Table
          error={failure}
          loading={loading}
          labels={['ID Pedido', 'Loja', 'Valor da Compra']}
          values={cancelations.orders}
          fields={({ order }) => {
            return [
              order?.order_id,
              order.store?.storeProduct?.store?.fantasy_name,
              AddMasks.money(order?.totals?.total_sale_value),
            ];
          }}
          hasAction
          actionLabel="Analisar"
          action={action}
          Icon={ArrowForward}
        />
      </Grid>
    </Main>
  );
}

export { Cancellations };
