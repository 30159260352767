import {
  FormHelperText,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.white,
  },
  label: {
    width: '100%',
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.common.white,
    '&$focused': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      paddingRight: '5px',
    },
  },
  focused: {},
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
}));

const WAIT_INTERVAL = 1000;

function SearchSelector({
  id,
  options,
  label,
  value,
  error,
  onChange,
  disabled,
  loading,
  multiple,
  onChangeInput,
  getLabel,
  optionCheckField,
  selectedCheckField,
  tooltip,
}) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const timeout = useRef(null);

  const onSelect = (event, selected) => {
    onChange({ id, value: selected });
  };

  const debounceOnChange = (event) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(
      (newValue) => {
        onChangeInput({ target: { value: newValue } });
      },
      WAIT_INTERVAL,
      event?.target?.value || ''
    );
  };

  const selectedOrDisabled = (option) => {
    if (!value) return false;
    if (multiple) {
      const exists = value.find(
        (v) => v[selectedCheckField] === option[optionCheckField]
      );
      return !!exists;
    }
    return value && value[selectedCheckField]
      ? value[selectedCheckField] === option[optionCheckField]
      : false;
  };

  return (
    <>
      <Autocomplete
        open={open}
        className={classes.container}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disabled={disabled}
        options={options}
        getOptionLabel={getLabel}
        fullWidth
        loading={loading}
        multiple={multiple}
        getOptionSelected={selectedOrDisabled}
        getOptionDisabled={selectedOrDisabled}
        noOptionsText="Sem opções"
        onChange={onSelect}
        disableListWrap
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            noWrap
            label={label}
            variant="outlined"
            onChange={(text) => debounceOnChange(text)}
          />
        )}
        value={value}
        renderOption={(option) => (
          <Tooltip title={tooltip(option)}>
            <Typography noWrap>{getLabel(option)}</Typography>
          </Tooltip>
        )}
      />
      {error && (
        <FormHelperText>
          <Typography className={classes.error}>{error}</Typography>
        </FormHelperText>
      )}
    </>
  );
}

SearchSelector.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onChangeInput: PropTypes.func,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  loading: PropTypes.bool,
  getLabel: PropTypes.func,
  tooltip: PropTypes.func,
  optionCheckField: PropTypes.string,
  selectedCheckField: PropTypes.string,
};

SearchSelector.defaultProps = {
  options: [],
  label: '',
  error: '',
  onChange: () => {},
  onChangeInput: () => {},
  value: null,
  disabled: false,
  multiple: false,
  loading: false,
  getLabel: () => '', // Indica o campo que exibira o nome
  tooltip: () => '', // Indica o campo que exibirá a dica (Usado em campos muito grandes)
  optionCheckField: 'id', // campos para validação de items selecionados
  selectedCheckField: 'id',
};

export { SearchSelector };
