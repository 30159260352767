import api from 'services/config/api';
import { Product } from 'utils/types/product';
import { ResponsePagination } from 'utils/types/response';

export default class ProductApi {
  getProducts = async (query = ''): Promise<ResponsePagination<Product>> => {
    const { data } = await api.get<ResponsePagination<Product>>(
      `/admin/products${query}`
    );
    return data;
  };
}
