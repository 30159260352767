import { call, put, select } from 'redux-saga/effects';
import { ProdutoApi } from 'services/api/ProdutoApi';
import { NovoProdutoActions } from 'store/ducks/novo-produto';
import { NotificationActions } from 'store/ducks/notification';
import { Texts } from 'constants/strings';
import { ProdutoActions } from 'store/ducks/produto';
import { money } from 'utils/money';
import { AddMasks } from 'utils/Masks';
import queryCreator from 'utils/queryCreator';

export function* createProduto() {
  try {
    const {
      description,
      brand,
      type_unit,
      gtin_ean_code,
      ncm,
      sale_value,
      cost_value,
      images,
      code_identification,
      details,
      short_name,
      product_group_id,
      cfop_id,
      cofins_id,
      pis_id,
      icms_origin_id,
      icms_tax_situation_id,
      // icms_calculation_base_mode_id,
      // icms_aliquot,
      // icms_calculation_base,
      providers,
    } = yield select((state) => state.novoProduto);

    const arrayBase64 = images.map((image) => image.image);

    const data = {
      description,
      brand,
      type_unit,
      gtin_ean_code,
      ncm,
      sale_value: money.removeCurrency(sale_value),
      cost_value: money.removeCurrency(cost_value),
      images: arrayBase64,
      details,
      code_identification,
      short_name,
      product_group_id: String(product_group_id),
      cfop_id: cfop_id ? String(cfop_id.cfop_id) : null,
      icms_origin_id: icms_origin_id ? String(icms_origin_id.id) : null,
      icms_tax_situation_id: icms_tax_situation_id
        ? String(icms_tax_situation_id.id)
        : null,
      // icms_calculation_base_mode_id: icms_calculation_base_mode_id
      //   ? String(icms_calculation_base_mode_id.id)
      //   : null,
      // icms_aliquot: parseFloat(icms_aliquot),
      // icms_calculation_base: parseFloat(icms_calculation_base),
      pis_id: pis_id ? String(pis_id.id) : null,
      cofins_id: cofins_id ? String(cofins_id.id) : null,
      providers: providers.map((prov) => String(prov.provider_id)),
    };
    yield call(ProdutoApi.createProduto, data);
    yield put(
      NotificationActions.success(Texts.novoProduto.texts.sucessoCriar)
    );
    yield put(NovoProdutoActions.createSuccess());
    yield put(NovoProdutoActions.reset());
  } catch (error) {
    yield put(NotificationActions.error(Texts.novoProduto.texts.errorCriar));
    if (error.response && error.response.status === 422) {
      const errors = {};
      error.response.data.forEach((err) => {
        errors[`${err.field}Err`] = err.message;
      });
      yield put(NovoProdutoActions.createErr(errors));
    } else {
      yield put(NovoProdutoActions.createErr());
    }
  }
}

export function* updateProduto() {
  try {
    const {
      description,
      brand,
      type_unit,
      gtin_ean_code,
      ncm,
      sale_value,
      cost_value,
      code_identification,
      product_id,
      images,
      details,
      short_name,
      product_group_id,
      cfop_id,
      cofins_id,
      pis_id,
      icms_origin_id,
      icms_tax_situation_id,
      // icms_calculation_base_mode_id,
      // icms_aliquot,
      // icms_calculation_base,
      providers,
    } = yield select((state) => state.novoProduto);

    const arrayBase64 = images.map((image) => image.image);
    const data = {
      description,
      brand,
      type_unit,
      gtin_ean_code,
      ncm,
      sale_value: money.removeCurrency(sale_value),
      cost_value: money.removeCurrency(cost_value),
      images: arrayBase64,
      code_identification,
      details,
      short_name,
      product_group_id: String(product_group_id),
      cfop_id: cfop_id ? String(cfop_id.cfop_id) : null,
      icms_origin_id: icms_origin_id ? String(icms_origin_id.id) : null,
      icms_tax_situation_id: icms_tax_situation_id
        ? String(icms_tax_situation_id.id)
        : null,
      // icms_calculation_base_mode_id: icms_calculation_base_mode_id
      //   ? String(icms_calculation_base_mode_id.id)
      //   : null,
      // icms_aliquot: parseFloat(icms_aliquot),
      // icms_calculation_base: parseFloat(icms_calculation_base),
      pis_id: pis_id ? String(pis_id.id) : null,
      cofins_id: cofins_id ? String(cofins_id.id) : null,
      providers: providers.map((prov) => String(prov.provider_id)),
    };
    yield call(ProdutoApi.updateProduto, data, product_id);
    yield put(
      NotificationActions.success(Texts.novoProduto.texts.sucessoAtualizar)
    );
    yield put(NovoProdutoActions.updateSuccess());
    yield put(ProdutoActions.filter());
  } catch (error) {
    yield put(
      NotificationActions.error(Texts.novoProduto.texts.errorAtualizar)
    );
    if (error.response && error.response.status === 422) {
      const errors = {};
      error.response.data.forEach((err) => {
        errors[`${err.field}Err`] = err.message;
      });
      yield put(NovoProdutoActions.updateErr(errors));
    } else {
      yield put(NovoProdutoActions.updateErr());
    }
  }
}

export function* activeInactiveProduto() {
  try {
    const { product_id, active } = yield select((state) => state.novoProduto);
    yield call(ProdutoApi.activeInactive, product_id, !active);
    yield put(NotificationActions.success(Texts.novoProduto.texts.active));
    yield put(ProdutoActions.filter());
    yield put(NovoProdutoActions.activeInactive_Success());
  } catch (error) {
    yield put(NovoProdutoActions.activeInactiveErr());
    yield put(NotificationActions.error(Texts.novoProduto.texts.errorActive));
  }
}

export function* getProductsGroups() {
  try {
    const { data } = yield call(ProdutoApi.getGroups);
    const groups = data.data.map((group) => ({
      id: group.product_group_id,
      name: group.description,
    }));
    yield put(NovoProdutoActions.groupSuccess(groups));
  } catch (error) {
    yield put(NovoProdutoActions.groupErr());
    yield put(NotificationActions.error(Texts.novoProduto.texts.errorGroups));
  }
}

export function* getProvidersList({ payload: fantasyName }) {
  try {
    const query = `?query=${queryCreator.like(
      'fantasy_name',
      fantasyName.trim()
    )}&page=1`;
    const { data } = yield call(ProdutoApi.getProvidersList, query);
    const providers = data.data.map((provider) => ({
      ...provider,
      exibition: `${AddMasks.cpfCnpj(provider.cpf_cnpj)} - ${
        provider.fantasy_name
      }`,
    }));
    yield put(NovoProdutoActions.providerListSuccess(providers));
  } catch (error) {
    yield put(NovoProdutoActions.providerListError());
    yield put(
      NotificationActions.error(Texts.novoProduto.texts.errorProviders)
    );
  }
}

export function* getProductDetails({ payload }) {
  try {
    const { data } = yield call(
      ProdutoApi.getProdutoDetails,
      payload.productId
    );

    let images = [];

    if (data.data.images) {
      images = data.data.images.map(({ fileurl, product_image_id }) => ({
        id: product_image_id,
        image: fileurl,
      }));
    }

    const product = {
      product_id: data.data.product_id,
      description: data.data.description,
      brand: data.data.brand,
      code_identification: data.data.code_identification,
      type_unit: data.data.type_unit,
      gtin_ean_code: data.data.gtin_ean_code,
      ncm: data.data.ncm,
      sale_value: money.formatCurrency(data.data.sale_value),
      cost_value: money.formatCurrency(data.data.cost_value),
      updateMode: true,
      disableFields: !data.data.active,
      images,
      details: data.data.details,
      short_name: data.data.short_name,
      product_group_id: data.data.group.product_group_id,
      active: data.data.active,
      cofins_id: {
        id: data.data.cofins.cofins_id,
        value: `${data.data.cofins.code} - ${data.data.cofins.description}`,
      },
      pis_id: {
        id: data.data.pis.pis_id,
        value: `${data.data.pis.code} - ${data.data.pis.description}`,
      },
      cfop_id: {
        ...data.data.cfop,
        description: `${data.data.cfop.code} - ${data.data.cfop.description}`,
      },
      icms_origin_id: {
        id: data.data.icmsOrigin.icms_origin_id,
        value: `${data.data.icmsOrigin.code} - ${data.data.icmsOrigin.description}`,
      },
      icms_tax_situation_id: {
        id: data.data.icmsTaxSituation.icms_tax_situation_id,
        value: `${data.data.icmsTaxSituation.code} - ${data.data.icmsTaxSituation.description}`,
      },
      // icms_calculation_base_mode_id: {
      //   id: data.data.icms_calculation_base_mode_id,
      //   value: `${data.data.icmsCalculationBaseMode.code} - ${data.data.icmsCalculationBaseMode.description}`,
      // },
      // icms_aliquot: data.data.icms_aliquot,
      // icms_calculation_base: data.data.icms_calculation_base,
      providers: data.data.productProvider.map(({ provider }) => ({
        ...provider,
        exibition: `${AddMasks.cpfCnpj(provider.cpf_cnpj)} - ${
          provider.fantasy_name
        }`,
      })),
    };
    yield put(NovoProdutoActions.detailsSuccess(product));
  } catch (error) {
    console.log(error);
    yield put(NovoProdutoActions.detailsErr());
    yield put(
      NotificationActions.error(Texts.novoProduto.texts.errorDetailsProduct)
    );
  }
}
