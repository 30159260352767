import { Dialog, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import CloseModal from 'components/close-button-modal';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    margin: theme.spacing(1.5),
    overflowY: 'scroll',
    overflowX: 'scroll',
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 25,
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function Modal({ show, onClose, children, title }) {
  const classes = useStyles();

  return (
    <Dialog open={show} className={classes.container}>
      <CloseModal onClick={onClose} />
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.content}>{children}</div>
    </Dialog>
  );
}

Modal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

Modal.defaultProps = {
  show: false,
  onClose: () => {},
  title: '',
  children: null,
};

export { Modal };
