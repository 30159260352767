import { Grid } from '@material-ui/core';
import { ChevronLeft, PersonAdd } from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import { ProdutoActions } from 'store/ducks/produto';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import If from 'components/if/If';
import DadosGerais from './components/DadosGerais';
import Endereco from './components/Endereco';

function UpdateProvider() {
  const { update, loadingUpdate } = useSelector(
    (state) => state.produto.updatedProvider
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const back = () => {
    history.goBack();
  };

  const createUpdateProvider = () => {
    dispatch(ProdutoActions.createUpdateProvider());
  };

  useEffect(() => {
    return () => {
      dispatch(ProdutoActions.resetProvider());
    };
  }, [dispatch]);

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button icon={ChevronLeft} onClick={back} title="Voltar" />
        </Grid>
        <If condition={update}>
          <Grid item>
            <Button
              icon={PersonAdd}
              onClick={createUpdateProvider}
              title="Atualizar Fornecedor"
              colored
              loading={loadingUpdate}
            />
          </Grid>
        </If>
        <DadosGerais />
        <Endereco />
        {!update && (
          <Grid item xs={12} sm={12}>
            <Button
              icon={PersonAdd}
              onClick={createUpdateProvider}
              title="Cadastrar Fornecedor"
              colored
              loading={loadingUpdate}
            />
          </Grid>
        )}
      </Grid>
    </Main>
  );
}

export default UpdateProvider;
