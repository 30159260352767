export const isAdmin = true;

const Uris = {
  URLBASE: `https://wessexstores.com.br/`,
  URL_PLUG_NOTAS: 'https://api.plugnotas.com.br',
  login: '/login',
  changePasswordSendEmail: '/change-password/send-mail',
  resetPassword: '/change-password',
  getSession: isAdmin ? '/admin/session' : '/client/session',
  getStores: '/admin/stores',
  createNewUser: '/admin/users',
  getUsers: '/admin/users',
  changeUserStatus: '/admin/users/{userId}/change-status',
  updateUser: '/admin/users',
  createStore: '/admin/stores',
  updateStore: '/admin/stores',
  inactiveStore: '/admin/stores/{storeId}/change-status',
  getProductsByStore: '/admin/stores/{storeId}/products',
  // produtos
  getProdutos: '/admin/products',
  getProductDetails: '/admin/products/{productId}',
  createProduto: '/admin/products',
  updateProduto: '/admin/products',
  activeInactiveProduto: '/admin/products/{productId}/change-status',
  getProductGroups: '/admin/products/groups',
  getProvidersList: '/admin/providers',
  createProvider: '/admin/providers',
  updateProvider: '/admin/providers/{providerId}',
  sendProductToStore: '/admin/products/{productId}/send-to-store/{storeId}',
  bringProductFromStore:
    '/admin/products/{productId}/bring-from-store/{storeId}',
  // promoção
  getPromotions: '/admin/promotions',
  createPromotions: '/admin/promotions',
  getPromotionsDetails: '/admin/promotion/{promotionId}',
  updatePromotions: '/admin/promotions/{promotionId}',

  // empresa
  getEmpresa: '/admin/company',
  putEmpresa: '/admin/company',
  // transacao
  listOrdersByStoreShopkeeper: '/admin/shopkeeper/store/{idStore}/orders',
  getItemsFromOrder: 'admin/orders/{orderId}/items',
  getDetailsFromOrder: 'admin/orders/{orderId}',
  getOrderDetails: '/admin/shopkeeper/store/{storeId}/orders/{orderId}',
  getOrdertems: '/admin/shopkeeper/store/{storeId}/orders/{orderId}/items',
  getAllOrders: '/admin/orders',
  cancelOrder: '/admin/orders/{orderId}/cancel',
  confirmOrder: '/admin/shopkeeper/store/{storeId}/orders/{orderId}/deliver',
  shopkeeperRequestCancel:
    '/admin/shopkeeper/store/{storeId}/orders/{orderId}/cancel',
  // fiscal
  getCfop: '/admin/fiscal/cfop',
  getPis: '/admin/fiscal/pis',
  getCofins: '/admin/fiscal/cofins',
  getTypesICMS: '/admin/fiscal/icms/{typeIcms}',
  uploadXML: '/admin/fiscal/nfe/uploadxml',
  entryNFe: '/admin/fiscal/nfe/input',
  listNFe: '/admin/fiscal/nfe',
  createNfeOutput: '/admin/fiscal/nfe/output',
  detalhesNfeSaida: '/admin/fiscal/nfe/output/{nfeId}',
  detalhesNfeEntrada: '/admin/fiscal/nfe/input/{nfeId}',
  cancelNote: '/admin/fiscal/nfe/output/{nfeId}',
  // dashboard
  lowStock: '/admin/dashboard/low-stock-products/{local}',
  getSalesByPeriod: '/admin/dashboard/sales/{period}',
  getSalesByStatus: '/admin/dashboard/orders/{saleStatus}',
  // plug notas
  consultaCep: '/admin/fiscal/cep/{cep}',
  // reports
  reportSales: '/admin/reports/sales',
  reportBestClients: '/admin/reports/best-clients',
  reportBestClientsOrders: '/admin/reports/best-clients/{clientId}',
  reportClients: '/admin/reports/clients',
  movMainStock: '/admin/reports/products/stock/main',
  movStockStores: '/admin/reports/products/stock/store',
};

export { Uris };
