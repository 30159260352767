import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Label } from '@material-ui/icons';
import LoadingFailure from 'components/loading-failure';
import SearchSelector from 'components/search-selector';
import { TextInput } from 'components/text-input';
import { Texts } from 'constants/strings';
import { useFiscal } from 'context/FiscalProvider';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Masks from 'utils/Masks';

const useStyles = makeStyles((theme) => ({
  productName: {
    color: theme.palette.primary.dark,
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  productContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  container: {
    margin: theme.spacing(1),
    padding: theme.spacing(1.5),
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    color: theme.palette.primary.dark,
  },
}));

function ProdutosNota({ product, onChange }) {
  const classes = useStyles();
  const {
    taxs: { icmsTaxSituation, icmsOrigin, pis, cofins, cfop },
    getCfop,
  } = useFiscal();

  const changeField = useCallback(
    (key, value) => {
      onChange(product.productId, key, value);
    },
    [onChange, product]
  );

  const onChangeInputCfop = useCallback(
    (e) => {
      getCfop(e.target.value);
    },
    [getCfop]
  );

  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10} className={classes.productContainer}>
          <Label className={classes.icon} />
          <Typography className={classes.productName}>
            {product.description}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextInput
            label={Texts.fiscal.fields.quantity}
            id="quantity"
            onChange={(e) => changeField(e.target.id, e.target.value)}
            value={product.quantity}
            mask={Masks.onlyNumbers}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadingFailure failure={cfop.error} loading={cfop.loading}>
            <SearchSelector
              id="cfop"
              label={Texts.fiscal.fields.cfop}
              onChangeInput={onChangeInputCfop}
              getLabel={(option) => option?.description || String(option.id)}
              onChange={({ id, value }) => changeField(id, value)}
              options={cfop.data}
              loading={cfop.loading}
              error="" // add
              value={product.cfop}
              optionCheckField="id"
              selectedCheckField="id"
            />
          </LoadingFailure>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadingFailure
            failure={icmsTaxSituation.error}
            loading={icmsTaxSituation.loading}
          >
            <SearchSelector
              id="icmsTaxSituation"
              label={Texts.fiscal.fields.icms_tax_situation}
              getLabel={(option) => option?.description || String(option.id)}
              onChange={({ id, value }) => changeField(id, value)}
              options={icmsTaxSituation.data}
              loading={icmsTaxSituation.loading}
              error="" // add
              value={product.icmsTaxSituation}
              optionCheckField="id"
              selectedCheckField="id"
            />
          </LoadingFailure>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadingFailure
            failure={icmsOrigin.error}
            loading={icmsOrigin.loading}
          >
            <SearchSelector
              id="icmsOrigin"
              label={Texts.fiscal.fields.icms_origin}
              getLabel={(option) => option?.description || String(option.id)}
              onChange={({ id, value }) => changeField(id, value)}
              options={icmsOrigin.data}
              loading={icmsOrigin.loading}
              error="" // add
              value={product.icmsOrigin}
              optionCheckField="id"
              selectedCheckField="id"
            />
          </LoadingFailure>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadingFailure failure={pis.error} loading={pis.loading}>
            <SearchSelector
              id="pis"
              label={Texts.fiscal.fields.pis}
              getLabel={(option) => option?.description || String(option.id)}
              onChange={({ id, value }) => changeField(id, value)}
              options={pis.data}
              loading={pis.loading}
              error="" // add
              value={product.pis}
              optionCheckField="id"
              selectedCheckField="id"
            />
          </LoadingFailure>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadingFailure failure={cofins.error} loading={cofins.loading}>
            <SearchSelector
              id="cofins"
              label={Texts.fiscal.fields.cofins}
              getLabel={(option) => option?.description || String(option.id)}
              onChange={({ id, value }) => changeField(id, value)}
              options={cofins.data}
              loading={cofins.loading}
              error="" // add
              value={product.cofins}
              optionCheckField="id"
              selectedCheckField="id"
            />
          </LoadingFailure>
        </Grid>
      </Grid>
    </div>
  );
}

ProdutosNota.propTypes = {
  product: PropTypes.shape({
    productId: PropTypes.number,
    description: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cfop: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
    }),
    icmsTaxSituation: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
    }),
    icmsOrigin: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
    }),
    pis: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
    }),
    cofins: PropTypes.shape({
      id: PropTypes.number,
      description: PropTypes.string,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export { ProdutosNota };
