import React, { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import queryCreator from 'utils/queryCreator';
import { FiscalApi } from 'services/api/Fiscal';
import { purposeEmissionConstants } from 'constants/config/Config';
import { RemoveMasks, Validators } from 'utils/Masks';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { NotificationActions } from 'store/ducks/notification';
import { DocumentTypes } from 'utils/enum/fiscal.enum';

const FiscalContext = createContext(null);

const FiscalProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [detailsOutputNfe, setDetailsOutputNfe] = useState({
    loading: false,
    error: false,
    data: null,
  });
  const [detailsInputNfe, setDetailsInputNfe] = useState({
    loading: false,
    error: false,
    data: null,
  });
  const [taxs, setTaxs] = useState({
    cfop: {
      data: [],
      loading: false,
      error: false,
    },
    icmsTaxSituation: {
      data: [],
      loading: false,
      error: false,
    },
    icmsOrigin: {
      data: [],
      loading: false,
      error: false,
    },
    pis: {
      data: [],
      loading: false,
      error: false,
    },
    cofins: {
      data: [],
      loading: false,
      error: false,
    },
  });
  const [outputNfe, setOutputNfe] = useState({
    errors: {},
    loading: false,
  });
  const [nfes, setNfes] = useState({ loading: false, error: false, data: [] });

  const changeState = (key, value, loading = false, error = false) => {
    setTaxs((state) => ({
      ...state,
      [key]: {
        data: value,
        loading,
        error,
      },
    }));
  };

  const getIcmsTaxs = useCallback(
    async (key, type, id) => {
      changeState(key, taxs[key].data, true, false);
      try {
        const { data } = await FiscalApi.getTypesICMS(type);
        const result = data.map((tax) => ({
          id: tax[id],
          description: `${tax.code} - ${tax.description}`,
        }));
        changeState(key, result);
      } catch (error) {
        changeState(key, taxs[key].data, false, true);
      }
    },
    [taxs]
  );

  const getPis = useCallback(async () => {
    changeState('pis', taxs.pis.data, true);
    try {
      const { data } = await FiscalApi.getPis();
      const result = data.map((tax) => ({
        id: tax.pis_id,
        description: `${tax.code} - ${tax.description}`,
      }));
      changeState('pis', result);
    } catch (error) {
      changeState('pis', taxs.pis.data, false, true);
    }
  }, [taxs]);

  const getCofins = useCallback(async () => {
    changeState('cofins', taxs.cofins.data, true);
    try {
      const { data } = await FiscalApi.getCofins();
      const result = data.map((tax) => ({
        id: tax.cofins_id,
        description: `${tax.code} - ${tax.description}`,
      }));
      changeState('cofins', result);
    } catch (error) {
      changeState('cofins', taxs.pis.data, false, true);
    }
  }, [taxs]);

  const getCfop = useCallback(
    async (cfopCode = '') => {
      changeState('cfop', taxs.cfop.data, true);
      try {
        const query = `?query=${queryCreator.like(
          'code',
          cfopCode.trim()
        )}&page=1`;
        const { data } = await FiscalApi.getCfop(query);
        const result = data.data.map((cfop) => ({
          id: cfop.cfop_id,
          description: `${cfop.code} - ${cfop.description}`,
        }));
        changeState('cfop', result);
      } catch (error) {
        changeState('cfop', taxs.cfop.data, false, true);
      }
    },
    [taxs]
  );

  const getIcmsTaxSituation = useCallback(() => {
    getIcmsTaxs('icmsTaxSituation', 'tax-situation', 'icms_tax_situation_id');
  }, [getIcmsTaxs]);

  const getIcmsOrigin = useCallback(() => {
    getIcmsTaxs('icmsOrigin', 'origin', 'icms_origin_id');
  }, [getIcmsTaxs]);

  const cancelNote = useCallback(
    (nfeId, reason = '') => {
      if (reason.length < 15) {
        dispatch(
          NotificationActions.error(
            'Justificativa deve ter entre 15 e 255 caracteres'
          )
        );
        return;
      }
      FiscalApi.cancelNote(nfeId || 0, reason)
        .then(() => {
          dispatch(NotificationActions.success('Nota cancelada com sucesso'));
        })
        .catch((error) => {
          const errorMessage = error?.response.data;
          dispatch(
            NotificationActions.error(
              `Código ${error?.status}. ${
                errorMessage
                  ? JSON.stringify(errorMessage)
                  : 'Erro ao cancelar nota'
              }`
            )
          );
        });
    },
    [dispatch]
  );

  const emitOutputNfe = useCallback(
    (nfe) => {
      const confs =
        nfe.purposeEmission === purposeEmissionConstants.DEVOLUCAO
          ? {
              document_type_id: DocumentTypes.Entrada,
              operation_nature: 'Devolução',
              type_payment_id: String(4),
            }
          : {
              document_type_id: DocumentTypes.Saida,
              operation_nature: 'Remessa',
              type_payment_id: String(2),
            };

      const body = {
        company_id: String(1),
        ...confs,
        provider_id: String(nfe.provider.provider_id),
        purpose_emission_id: String(nfe.purposeEmission),
        final_costumer_id: String(nfe.finalCostumer),
        presence_buyer_id: String(nfe.presenceBuyer),
        products: nfe.products.map((prod) => ({
          product_id: String(prod.productId),
          quantity: prod.quantity,
          cfop_id: String(prod.cfop.id),
          icms_tax_situation_id: String(prod.icmsTaxSituation.id),
          icms_origin_id: String(prod.icmsOrigin.id),
          pis_id: String(prod.pis.id),
          cofins_id: String(prod.cofins.id),
        })),
      };
      if (nfe.purposeEmission === purposeEmissionConstants.DEVOLUCAO) {
        body.keys_nfe_references = nfe.nfesDevolucao.map((nfeDev) =>
          RemoveMasks.onlyNumbers(nfeDev)
        );
      }
      FiscalApi.createOutputNfe(body)
        .then(() => {
          setOutputNfe((state) => ({ ...state, errors: {}, loading: false }));
          dispatch(
            NotificationActions.success(
              'A nota foi enviada para manifestação, você pode acompanhar o status da nota pelo dashboard'
            )
          );
        })
        .catch((error) => {
          dispatch(
            NotificationActions.error(
              JSON.stringify(error?.response?.data || JSON.stringify(error))
            )
          );
          setOutputNfe((state) => ({ ...state, loading: false }));
        });
    },
    [dispatch]
  );

  const getNfeOutputDetails = useCallback(
    (nfeId) => {
      setDetailsOutputNfe((state) => ({
        ...state,
        loading: true,
        error: false,
      }));
      FiscalApi.getOutputNfe(nfeId)
        .then(({ data }) => {
          setDetailsOutputNfe((state) => ({
            ...state,
            data: data.data,
            loading: false,
            error: false,
          }));
        })
        .catch(() => {
          setDetailsOutputNfe((state) => ({
            ...state,
            loading: false,
            error: true,
          }));
        });
    },
    [setDetailsOutputNfe]
  );

  const getNfeInputDetails = useCallback(
    (nfeId) => {
      setDetailsInputNfe((state) => ({
        ...state,
        loading: true,
        error: false,
      }));
      FiscalApi.getInputNfe(nfeId)
        .then(({ data }) => {
          setDetailsInputNfe((state) => ({
            ...state,
            data: data.data,
            loading: false,
            error: false,
          }));
        })
        .catch(() => {
          setDetailsInputNfe((state) => ({
            ...state,
            loading: false,
            error: true,
          }));
        });
    },
    [setDetailsInputNfe]
  );

  const createOutputNfe = useCallback(
    (nfe) => {
      const errors = {};
      const { nfesDevolucao, purposeEmission, provider, products } = nfe;
      if (!provider) errors.provider = 'Fornecedor não informado';
      if (products.length === 0)
        errors.products = 'Informe ao menos um produto para a nota';
      else {
        const productsErrors = [];
        products.forEach((prod) => {
          if (prod.quantity === 0) {
            productsErrors.push(
              `Produto ${prod.description} com quantidade zerada`
            );
          }
        });
        if (productsErrors.length > 0) {
          errors.productsItems = productsErrors;
        }
      }
      if (purposeEmission === purposeEmissionConstants.DEVOLUCAO) {
        let hasErrors = false;
        nfesDevolucao.forEach((chaveNfe) => {
          const check = Validators.nfeKeyIsValid(chaveNfe);
          if (!check) hasErrors = true;
        });
        if (hasErrors)
          errors.nfesDevolucao = 'Alguma chave inserida contém erro';
      }
      if (Object.keys(errors).length !== 0) {
        // algum erro
        dispatch(
          NotificationActions.error('Ocorreu um erro na validação dos campos')
        );
        setOutputNfe((state) => ({ ...state, errors }));
      } else {
        setOutputNfe((state) => ({ ...state, errors, loading: true }));
        emitOutputNfe(nfe);
      }
    },
    [dispatch, emitOutputNfe]
  );

  // type pode ser output ou input
  const getNfes = useCallback((type = 'output', query = '') => {
    setNfes((state) => ({
      ...state,
      loading: true,
      error: false,
    }));
    FiscalApi.getNFeList(query, type)
      .then(({ data }) => {
        const items = {
          ...data,
          data: data.data.map((item) => ({
            ...item,
            dateEmission: moment(item.created_at).format('DD/MM/YY HH:mm'),
            numDocument: RemoveMasks.getNFeNum(item?.key_nfe || ''),
          })),
        };
        setNfes((state) => ({
          ...state,
          data: items,
          loading: false,
          error: false,
        }));
      })
      .catch(() => {
        setNfes((state) => ({
          ...state,
          loading: false,
          error: true,
        }));
      });
  }, []);

  return (
    <FiscalContext.Provider
      value={{
        taxs,
        getCfop,
        getPis,
        getCofins,
        getIcmsTaxSituation,
        getIcmsOrigin,
        outputNfe,
        createOutputNfe,
        nfes,
        getNfes,
        detailsOutputNfe,
        getNfeOutputDetails,
        cancelNote,
        detailsInputNfe,
        getNfeInputDetails,
      }}
    >
      {children}
    </FiscalContext.Provider>
  );
};

const useFiscal = () => {
  const context = useContext(FiscalContext);
  if (!context) {
    throw new Error('useProducts must be user within an FiscalProvider');
  }
  return context;
};

FiscalProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { FiscalProvider, useFiscal };
