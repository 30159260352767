import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  withStyles,
} from '@material-ui/core';
import ButtonBack from 'components/button-back';
import Main from 'components/main-container';
import React, { FC, useState } from 'react';
import { MainStockMov } from './MainStock';
import { StoreStockMov } from './StoreStock';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
type Props = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.grey[400],
      borderWidth: 2,
      borderStyle: 'solid',
    },
    tab: {
      background: theme.palette.common.white,
      '&$selected': {
        background: 'blue',
      },
    },
    selectedTab: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      fontWeight: 'bold',
    },
  })
);

const TabItem = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'capitalize',
      fontWeight: 'bold',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey[600],
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  })
)(Tab);

const MovesStocks: FC<Props> = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <ButtonBack />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Tabs
            variant="fullWidth"
            value={tab}
            onChange={handleChange}
            className={classes.tabs}
            TabIndicatorProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <TabItem label="Principal" />
            <TabItem label="Stand" />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TabPanel value={tab} index={0}>
            <MainStockMov />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <StoreStockMov />
          </TabPanel>
        </Grid>
      </Grid>
    </Main>
  );
};

export { MovesStocks };
