import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ArrowForward } from '@material-ui/icons';
import { Moment } from 'moment';
import If from 'components/if/If';

interface Props {
  start?: Moment;
  end?: Moment;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.grey[500],
      fontWeight: 'bold',
      fontSize: 14,
    },
    date: {
      fontSize: 16,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    colored: {
      background:
        'linear-gradient(160deg, rgba(243,42,86,1) 40%, rgba(139,34,89,1) 100%)',
      color: theme.palette.common.white,
    },
    icon: {
      height: '100%',
      color: theme.palette.grey[400],
    },
  })
);

function DatesInterval({ start, end }: Props) {
  const classes = useStyles();

  const format = (date?: Moment) => {
    if (!date) return '';
    const day = date.format('DD');
    let month = date.format('MMM');
    month = month.charAt(0).toUpperCase() + month.slice(1);
    return `${day} de ${month}`;
  };

  return (
    <If condition={!!start && !!end}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography className={classes.label}>De</Typography>
          <Typography className={classes.date}>{format(start)}</Typography>
        </Grid>
        <Grid item justify="center">
          <ArrowForward className={classes.icon} />
        </Grid>
        <Grid item>
          <Typography className={classes.label}>Para</Typography>
          <Typography className={classes.date}>{format(end)}</Typography>
        </Grid>
      </Grid>
    </If>
  );
}

export { DatesInterval };
