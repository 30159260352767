import produce from 'immer';

export const Types = {
  GET_CFOP_REQUEST: 'fiscal@GET_CFOP_LOADING',
  GET_CFOP_FAILURE: 'fiscal@GET_CFOP_FAILURE',
  GET_CFOP_SUCCESS: 'fiscal@GET_CFOP_SUCCESS',
  GET_PIS_REQUEST: 'fiscal@GET_PIS_LOADING',
  GET_PIS_FAILURE: 'fiscal@GET_PIS_FAILURE',
  GET_PIS_SUCCESS: 'fiscal@GET_PIS_SUCCESS',
  GET_COFINS_REQUEST: 'fiscal@GET_COFINS_LOADING',
  GET_COFINS_FAILURE: 'fiscal@GET_COFINS_FAILURE',
  GET_COFINS_SUCCESS: 'fiscal@GET_COFINS_SUCCESS',
  GET_ICMS_ORIGIN_REQUEST: 'fiscal@GET_ICMS_ORIGIN_LOADING',
  GET_ICMS_ORIGIN_FAILURE: 'fiscal@GET_ICMS_ORIGIN_FAILURE',
  GET_ICMS_ORIGIN_SUCCESS: 'fiscal@GET_ICMS_ORIGIN_SUCCESS',
  GET_ICMS_TAX_SITUATION_REQUEST: 'fiscal@GET_ICMS_TAX_SITUATION_LOADING',
  GET_ICMS_TAX_SITUATION_FAILURE: 'fiscal@GET_ICMS_TAX_SITUATION_FAILURE',
  GET_ICMS_TAX_SITUATION_SUCCESS: 'fiscal@GET_ICMS_TAX_SITUATION_SUCCESS',
  GET_ICMS_CALCULATION_BASE_MODE_REQUEST:
    'fiscal@GET_ICMS_CALCULATION_BASE_MODE_LOADING',
  GET_ICMS_CALCULATION_BASE_MODE_FAILURE:
    'fiscal@GET_ICMS_CALCULATION_BASE_MODE_FAILURE',
  GET_ICMS_CALCULATION_BASE_MODE_SUCCESS:
    'fiscal@GET_ICMS_CALCULATION_BASE_MODE_SUCCESS',
  UPLOAD_XML: 'fiscal@UPLOAD_XML',
  UPLOAD_XML_RESULT: 'fiscal@UPLOAD_XML_RESULT',
  SEARCH_PRODUCTS: 'fiscal@SEARCH_PRODUCTS',
  RESULT_SEARCH_PRODUCTS: 'fiscal@RESULT_SEARCH_PRODUCTS',
  SET_PRODUCT_TO_ITEM: 'fiscal@SET_PRODUCT_TO_ITEM',
  UPDATE_PRODUCT: 'fiscal@UPDATE_PRODUCT',
  ENTRY_NFE_REQUEST: 'fiscal@ENTRY_NFE_REQUEST',
  ENTRY_NFE_RESULT: 'fiscal@ENTRY_NFE_RESULT',
  REQUEST_LIST_NFE: 'fiscal@REQUEST_LIST_NFE',
  RESULT_LIST_NFE: 'fiscal@RESULT_LIST_NFE',
  RESET_NFE_ENTRY: 'fiscal@RESET_NFE_ENTRY',
  REMOVE_PRODUCT: 'fiscal@REMOVE_PRODUCT',
  ADD_PRODUCT: 'fiscal@ADD_PRODUCT',
};

const initialState = {
  cfop: [],
  cfopLoading: false,
  cfopError: false,
  pis: [],
  pisLoading: true,
  pisError: false,
  cofins: [],
  cofinsLoading: false,
  cofinsError: false,
  icmsOrigin: [],
  icmsOriginLoading: false,
  icmsOriginError: false,
  icmsCalculationBaseMode: [],
  icmsCalculationBaseModeLoading: false,
  icmsCalculationBaseModeError: false,
  icmsTaxSituation: [],
  icmsTaxSituationLoading: false,
  icmsTaxSituationError: false,
  nfe: {
    list: {
      loading: false,
      failure: false,
      data: [],
    },
    new: {
      products: {
        list: [],
        loading: false,
        error: false,
      },
      uploadLoading: false,
      uploadFailure: false,
      data: null,
      entryLoading: false,
      entryFailure: [],
    },
  },
};

const fiscal = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_CFOP_REQUEST:
      state.cfopLoading = true;
      state.cfopError = false;
      return state;
    case Types.GET_CFOP_FAILURE:
      state.cfopLoading = false;
      state.cfopError = state.cfop.length === 0;
      return state;
    case Types.GET_CFOP_SUCCESS:
      state.cfopLoading = false;
      state.cfopError = false;
      state.cfop = payload;
      return state;
    case Types.GET_PIS_REQUEST:
      state.pisLoading = true;
      state.pisError = false;
      return state;
    case Types.GET_PIS_FAILURE:
      state.pisLoading = false;
      state.pisError = state.pis.length === 0;
      return state;
    case Types.GET_PIS_SUCCESS:
      state.pisLoading = false;
      state.pisError = false;
      state.pis = payload;
      return state;
    case Types.GET_COFINS_REQUEST:
      state.cofinsLoading = true;
      state.cofinsError = false;
      return state;
    case Types.GET_COFINS_FAILURE:
      state.cofinsLoading = false;
      state.cofinsError = state.cofins.length === 0;
      return state;
    case Types.GET_COFINS_SUCCESS:
      state.cofinsLoading = false;
      state.cofinsError = false;
      state.cofins = payload;
      return state;
    case Types.GET_ICMS_CALCULATION_BASE_MODE_REQUEST:
      state.icmsCalculationBaseModeLoading = true;
      state.icmsCalculationBaseModeError = false;
      return state;
    case Types.GET_ICMS_CALCULATION_BASE_MODE_FAILURE:
      state.icmsCalculationBaseModeLoading = false;
      state.icmsCalculationBaseModeError =
        state.icmsCalculationBaseMode.length === 0;
      return state;
    case Types.GET_ICMS_CALCULATION_BASE_MODE_SUCCESS:
      state.icmsCalculationBaseModeLoading = false;
      state.icmsCalculationBaseModeError = false;
      state.icmsCalculationBaseMode = payload;
      return state;
    case Types.GET_ICMS_ORIGIN_REQUEST:
      state.icmsOriginLoading = true;
      state.icmsOriginError = false;
      return state;
    case Types.GET_ICMS_ORIGIN_FAILURE:
      state.icmsOriginLoading = false;
      state.icmsOriginError = state.icmsOrigin.length === 0;
      return state;
    case Types.GET_ICMS_ORIGIN_SUCCESS:
      state.icmsOriginLoading = false;
      state.icmsOriginError = false;
      state.icmsOrigin = payload;
      return state;
    case Types.GET_ICMS_TAX_SITUATION_REQUEST:
      state.icmsTaxSituationLoading = true;
      state.icmsTaxSituationError = false;
      return state;
    case Types.GET_ICMS_TAX_SITUATION_FAILURE:
      state.icmsTaxSituationLoading = false;
      state.icmsTaxSituationError = state.icmsTaxSituation.length === 0;
      return state;
    case Types.GET_ICMS_TAX_SITUATION_SUCCESS:
      state.icmsTaxSituationLoading = false;
      state.icmsTaxSituationError = false;
      state.icmsTaxSituation = payload;
      return state;
    case Types.UPLOAD_XML:
      state.nfe.new.uploadLoading = true;
      state.nfe.new.uploadFailure = false;
      return state;
    case Types.UPLOAD_XML_RESULT:
      state.nfe.new.uploadLoading = false;
      state.nfe.new.uploadFailure = payload.error;
      state.nfe.new.data = payload.data;
      return state;
    case Types.SEARCH_PRODUCTS:
      state.nfe.new.products = {
        list: [],
        loading: true,
        error: false,
      };
      return state;
    case Types.RESULT_SEARCH_PRODUCTS:
      state.nfe.new.products = {
        list: payload.products,
        loading: false,
        error: payload.error,
      };
      return state;
    case Types.SET_PRODUCT_TO_ITEM:
      state.nfe.new.data.items[payload.index].product = payload.product;
      return state;
    case Types.UPDATE_PRODUCT:
      state.nfe.new.data.items[payload.index][payload.key] = payload.value;
      return state;
    case Types.ENTRY_NFE_REQUEST:
      state.nfe.new.entryLoading = true;
      state.nfe.new.entryFailure = [];
      return state;
    case Types.ENTRY_NFE_RESULT:
      state.nfe.new.entryLoading = false;
      state.nfe.new.entryFailure = payload;
      return state;
    case Types.REQUEST_LIST_NFE:
      state.nfe.list = {
        loading: false,
        failure: false,
      };
      return state;
    case Types.RESULT_LIST_NFE:
      state.nfe.list = {
        loading: false,
        failure: payload.error,
        data: payload.error ? payload.nfe.list.data : payload.nfes,
      };
      return state;
    case Types.RESET_NFE_ENTRY:
      state.nfe.new = initialState.nfe.new;
      return state;
    case Types.REMOVE_PRODUCT:
      state.nfe.new.data.items =
        state.nfe.new.data.items?.filter((_, index) => index !== payload) || [];
      return state;
    case Types.ADD_PRODUCT:
      const item = state.nfe.new.data.items[payload];
      state.nfe.new.data.items = [...state.nfe.new.data.items, item];
      return state;
    default:
      return state;
  }
});

export default fiscal;

export const FiscalActions = {
  getCfopList: (code = '') => ({
    type: Types.GET_CFOP_REQUEST,
    payload: code,
  }),
  getCfopListSuccess: (cfops) => ({
    type: Types.GET_CFOP_SUCCESS,
    payload: cfops,
  }),
  getCfopListFailure: () => ({
    type: Types.GET_CFOP_FAILURE,
  }),
  getPis: () => ({
    type: Types.GET_PIS_REQUEST,
  }),
  getPisSuccess: (pis) => ({
    type: Types.GET_PIS_SUCCESS,
    payload: pis,
  }),
  getPisFailure: () => ({
    type: Types.GET_PIS_FAILURE,
  }),
  getCofins: () => ({
    type: Types.GET_COFINS_REQUEST,
  }),
  getCofinsSuccess: (cofins) => ({
    type: Types.GET_COFINS_SUCCESS,
    payload: cofins,
  }),
  getCofinsFailure: () => ({
    type: Types.GET_COFINS_FAILURE,
  }),
  getIcmsTaxSituation: () => ({
    type: Types.GET_ICMS_TAX_SITUATION_REQUEST,
  }),
  getIcmsTaxSituationSuccess: (icmsTaxSituation) => ({
    type: Types.GET_ICMS_TAX_SITUATION_SUCCESS,
    payload: icmsTaxSituation,
  }),
  getIcmsTaxSituationFailure: () => ({
    type: Types.GET_ICMS_TAX_SITUATION_FAILURE,
  }),
  getIcmsOrigin: () => ({
    type: Types.GET_ICMS_ORIGIN_REQUEST,
  }),
  getIcmsOriginSuccess: (origin) => ({
    type: Types.GET_ICMS_ORIGIN_SUCCESS,
    payload: origin,
  }),
  getIcmsOriginFailure: () => ({
    type: Types.GET_ICMS_ORIGIN_FAILURE,
  }),
  getIcmsCalculationBaseMode: () => ({
    type: Types.GET_ICMS_CALCULATION_BASE_MODE_REQUEST,
  }),
  getIcmsCalculationBaseModeSuccess: (IcmsCalculationBaseMode) => ({
    type: Types.GET_ICMS_CALCULATION_BASE_MODE_SUCCESS,
    payload: IcmsCalculationBaseMode,
  }),
  getIcmsCalculationBaseModeFailure: () => ({
    type: Types.GET_ICMS_CALCULATION_BASE_MODE_FAILURE,
  }),
  uploadXml: (xml) => ({
    type: Types.UPLOAD_XML,
    payload: xml,
  }),
  uploadXmlResult: (data = null, error = false) => ({
    type: Types.UPLOAD_XML_RESULT,
    payload: { data, error },
  }),
  searchProducts: (productName) => ({
    type: Types.SEARCH_PRODUCTS,
    payload: productName,
  }),
  resultSearchProducts: (products, error) => ({
    type: Types.RESULT_SEARCH_PRODUCTS,
    payload: {
      products,
      error,
    },
  }),
  setProductToItem: (index, product) => ({
    type: Types.SET_PRODUCT_TO_ITEM,
    payload: { index, product },
  }),
  setProductUpdate: (index, key, value) => ({
    type: Types.UPDATE_PRODUCT,
    payload: { index, key, value },
  }),
  requestEntryNFe: (history) => ({
    type: Types.ENTRY_NFE_REQUEST,
    payload: history,
  }),
  resultEntryNFe: (error = []) => ({
    type: Types.ENTRY_NFE_RESULT,
    payload: error,
  }),
  requestListNFe: (filters) => ({
    type: Types.REQUEST_LIST_NFE,
    payload: filters,
  }),
  resultListNFe: (nfes, error) => ({
    type: Types.RESULT_LIST_NFE,
    payload: {
      nfes,
      error,
    },
  }),
  resetNFeEntry: () => ({
    type: Types.RESET_NFE_ENTRY,
  }),
  removeProductFiscal: (index) => ({
    type: Types.REMOVE_PRODUCT,
    payload: index,
  }),
  addProductFiscal: (index) => ({
    type: Types.ADD_PRODUCT,
    payload: index,
  }),
};
