import api from 'services/config/api';
import { Banner } from 'utils/types/banners';
import { ResponsePagination } from 'utils/types/response';

type BannerCreate = {
  image: string;
  link?: string;
};

type LinkUpdate = {
  link: string;
  bannerId: string;
  storeId: number;
};

export const BannersApi = {
  getBannersByStore: (store?: number) =>
    api.get<ResponsePagination<Banner>>(
      `admin/stores/banners?query=store_id||eq||${store}`
    ),
  removeBanner: (banner: number | string, store?: number) =>
    api.delete(`/admin/stores/${store}/banners/${banner}`),
  createBanner: (banner: BannerCreate, store?: number) =>
    api.post(`/admin/stores/${store}/banners`, banner),
  updateLink: ({ link, bannerId, storeId }: LinkUpdate) =>
    api.put(
      `http://20.195.197.98:3333/admin/stores/${storeId}/banners/${bannerId}`,
      {
        link,
      }
    ),
};
