import React, { createContext, useCallback, useContext, useState } from 'react';
import { ProdutoApi } from 'services/api/ProdutoApi';
import PropTypes from 'prop-types';
import queryCreator from 'utils/queryCreator';

const FornecedorContext = createContext(null);

const FornecedorProvider = ({ children }) => {
  const getFornecedores = useCallback(async (fantasyName = '') => {
    const query = `?query=${queryCreator.like(
      'fantasy_name',
      fantasyName.trim()
    )}&page=1`;
    setFornecedor((state) => ({
      ...state,
      loadingProvider: true,
      errorProvider: false,
    }));
    try {
      const { data: providers } = await ProdutoApi.getProvidersList(query);
      setFornecedor((state) => ({
        ...state,
        loadingProvider: false,
        errorProvider: false,
        providers: providers.data,
      }));
    } catch (error) {
      setFornecedor((state) => ({
        ...state,
        loadingProvider: false,
        errorProvider: true,
      }));
    }
  }, []);

  const [fornecedor, setFornecedor] = useState({
    providers: [],
    loadingProvider: false,
    errorProvider: false,
  });

  return (
    <FornecedorContext.Provider value={{ fornecedor, getFornecedores }}>
      {children}
    </FornecedorContext.Provider>
  );
};

const useFornecedor = () => {
  const context = useContext(FornecedorContext);
  if (!context) {
    throw new Error('useFornecedor must be user within an FornecedorProvider');
  }
  return context;
};

FornecedorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { FornecedorProvider, useFornecedor };
