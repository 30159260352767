import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Card from 'components/card';
import Text from 'components/text';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { Button } from 'components/button';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardActions } from 'store/ducks/dashboard';
import { Spa, Storefront } from '@material-ui/icons';
import LowStockModal from './LowStockModal';

const useStyles = makeStyles((theme) => ({
  value: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: 20,
  },
  title: {
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  titleStock: {
    fontSize: 18,
    marginBottom: theme.spacing(1),
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 40,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  gridIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridValue: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  gridButton: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
}));

function LowStock() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [config, setConfig] = useState({
    show: false,
    showStore: false,
    products: [],
  });
  const { wessex, stores } = useSelector((state) => state.dashboard.stock);

  useEffect(() => {
    dispatch(DashboardActions.getLowStock());
  }, [dispatch]);

  const onPressProducts = (showStore, products) => {
    setConfig({ show: true, showStore, products });
  };

  const onClose = () => {
    setConfig({ ...config, show: false });
  };

  const WessexStock = () => (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Title style={classes.titleStock}>Wessex</Title>
      </Grid>
      <Grid item className={classes.gridIcon} xs={12} sm={2}>
        <Spa className={classes.icon} />
      </Grid>
      <Grid item className={classes.gridValue} xs={12} sm={7}>
        <Text>
          <span className={classes.value}>{`${wessex.quantity} `}</span>
          Produtos com estoque baixo
        </Text>
      </Grid>
      <Grid item className={classes.gridButton} xs={12} sm={3}>
        <Button
          title="Ver Produtos"
          border={false}
          onClick={() => onPressProducts(false, wessex.products)}
        />
      </Grid>
    </Grid>
  );

  const StoresStock = () => (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Title style={classes.titleStock}>Lojas</Title>
      </Grid>
      <Grid item className={classes.gridIcon} xs={12} sm={2}>
        <Storefront className={classes.icon} />
      </Grid>
      <Grid item className={classes.gridValue} xs={12} sm={7}>
        <Text>
          <span className={classes.value}>{`${stores.quantity} `}</span>
          Produtos com estoque baixo
        </Text>
      </Grid>
      <Grid item className={classes.gridButton} xs={12} sm={3}>
        <Button
          title="Ver Produtos"
          border={false}
          onClick={() => onPressProducts(true, stores.products)}
        />
      </Grid>
    </Grid>
  );

  return wessex.show || stores.show ? (
    <>
      <Title style={classes.title}>
        {Texts.dashboard.cards.estoqueBaixoWessex}
      </Title>
      <Card>
        <Grid container>
          {wessex.show && (
            <Grid item xs={12} sm={12}>
              <WessexStock />
            </Grid>
          )}
          {stores.show && (
            <Grid item xs={12} sm={12}>
              <StoresStock />
            </Grid>
          )}
        </Grid>
      </Card>
      <LowStockModal
        show={config.show}
        showStore={config.showStore}
        onClose={onClose}
        products={config.products}
      />
    </>
  ) : null;
}

export default LowStock;
