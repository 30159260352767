import SearchSelector from 'components/search-selector';
import React, { useCallback, useEffect, useState } from 'react';
import queryCreator from 'utils/queryCreator';
import { Product } from 'utils/types/product';
import { ProductsApi } from 'services/api/ProductsApi';

interface IdValue<T> {
  id: string;
  value: T;
}

type OptionValue = Product | Array<Product> | undefined;

type ProductProps<T extends OptionValue> = {
  value: T;
  onChange: (value: IdValue<T>) => void;
  id: string;
  multiple?: boolean;
  disabled?: boolean;
}

type Values = {
  loading: boolean;
  error: boolean;
  data: Array<Product>;
};

function ProductSelector<T extends OptionValue>({ value, onChange, id, multiple = false, disabled }: ProductProps<T>) {
  const [values, setValues] = useState<Values>({
    loading: false,
    error: false,
    data: [],
  });

  const searchProducts = async (params: string = '') => {
    setValues({
      loading: true,
      error: false,
      data: [],
    });
    try {
      const { data } = await ProductsApi.getProductList(params);
      setValues({
        loading: false,
        error: false,
        data: data.data,
      });
    } catch (error) {
      setValues({
        loading: false,
        error: true,
        data: [],
      });
    }
  };

  const onChangeProduct = ({ value: product }: IdValue<T>) => {
    onChange({ id, value: product });
  };

  const onChangeInput = useCallback((e: any) => {
    const description = e?.target?.value || '';
    const queries: Array<string> = [];
    if (description.trim()) {
      queries.push(queryCreator.like('description', description));
    }
    searchProducts(queryCreator.formatter({ queries, page: 1 }));
  }, []);

  useEffect(() => {
    onChangeInput('');
  }, [onChangeInput]);
  

  return (
    <SearchSelector
      id="product"
      label="Selecione o Produto"
      onChangeInput={onChangeInput}
      getLabel={(option: Product) => option.description}
      onChange={onChangeProduct}
      options={values.data}
      loading={values.loading}
      value={value}
      optionCheckField="product_id"
      selectedCheckField="product_id"
      multiple={multiple}
      disabled={disabled}
    />
  );
}

export { ProductSelector };
