import { Grid, makeStyles } from '@material-ui/core';
import {
  ArrowForward,
  Close,
  CloudUpload,
  Launch,
  NoteAdd,
  Search,
} from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import RouterNames from 'constants/router-names';
import { Texts } from 'constants/strings';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Masks, { AddMasks, RemoveMasks } from 'utils/Masks';
import { DropzoneDialog } from 'material-ui-dropzone';
import { FiscalActions } from 'store/ducks/fiscal';
import Selecionar from 'components/select';
import { typesNfe, TYPE_NFE } from 'constants/config/Config';
import { useFiscal } from 'context/FiscalProvider';
import queryCreator from 'utils/queryCreator';
import { NotificationActions } from 'store/ducks/notification';
import DateSelector from 'components/date-selector';
import moment from 'moment';
import Table from 'components/pagination-table';
import If from 'components/if/If';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

function Fiscal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { getNfes, nfes } = useFiscal();

  const [fields, setFields] = useState({
    type: typesNfe[1].id,
    chave: '',
    dateEmission: null,
  });
  const [upload, setUpload] = useState(false);

  const uploadXML = (file) => {
    dispatch(FiscalActions.uploadXml(file));
    history.push(RouterNames.ADMIN_NOTASFISCAIS_ENTRADA);
  };

  const createNote = () => history.push(RouterNames.ADMIN_NOTASFISCAIS_SAIDA);

  const changeText = (event) => {
    setFields({ ...fields, [event.target.id]: event.target.value });
  };

  const changeSelect = ({ id, value }) => {
    setFields({ ...fields, [id]: value });
  };

  const clear = () => {
    setFields({
      ...fields,
      chave: '',
      dateEmission: null,
    });
  };

  const filter = useCallback(() => {
    const query = [];
    const { chave, dateEmission, type } = fields;
    if (chave.trim()) {
      query.push(queryCreator.like('key_nfe', RemoveMasks.onlyNumbers(chave)));
    }
    if (dateEmission) {
      const date = moment(dateEmission).format('YYYY-MM-DD');
      query.push(queryCreator.equal('date_emission', date));
    }
    getNfes(type, queryCreator.queryFormatter(query));
  }, [fields, getNfes]);

  const openDashboard = () => {
    window.open(
      `http://20.195.197.98/f9c5bb0937fe1c5e112ea2046d06a472`,
      '_blank'
    );
  };

  const openNote = (note) => {
    if (note?.documentType?.code === TYPE_NFE.SAIDA) {
      history.push(RouterNames.ADMIN_NOTASFISCAIS_DETALHES_SAIDA, note.nfe_id);
    } else if (note?.documentType?.code === TYPE_NFE.ENTRADA) {
      history.push(
        RouterNames.ADMIN_NOTASFISCAIS_DETALHES_ENTRADA,
        note.nfe_id
      );
    } else {
      dispatch(NotificationActions.error('Ocorreu um erro'));
    }
  };

  const handleDate = ({ id, value }) => {
    setFields((state) => ({ ...state, [id]: value }));
  };

  useEffect(() => {
    filter();
  }, []);

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={CloudUpload}
            onClick={() => setUpload(true)}
            title={Texts.fiscal.buttons.upload}
            colored
          />
        </Grid>
        <Grid item>
          <If>
            <Button
              icon={NoteAdd}
              onClick={createNote}
              title={Texts.fiscal.buttons.notaSaida}
              colored
            />
          </If>
        </Grid>
        <Grid item>
          <Button
            icon={Launch}
            onClick={openDashboard}
            title="Dashboard NF-e"
          />
        </Grid>
        <Grid item xs={12}>
          <Title>{Texts.fiscal.sessions.consultarNotas}</Title>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Selecionar
            label={Texts.fiscal.fields.tipoNota}
            onChange={changeSelect}
            id="type"
            options={typesNfe}
            value={fields.type}
            allowEmpty={false}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            label={Texts.fiscal.fields.chaveDevolucao}
            id="chave"
            mask={Masks.chaveNFe}
            onChange={changeText}
            value={fields.chave}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DateSelector
            id="dateEmission"
            type="date"
            label="Data de emissão"
            value={fields.dateEmission}
            onChange={handleDate}
            disableFuture
          />
        </Grid>
        <Grid item>
          <div className={classes.buttonContainer}>
            <Button
              icon={Close}
              onClick={clear}
              style={classes.button}
              title={Texts.store.buttons.clear}
            />
            <Button
              icon={Search}
              onClick={filter}
              style={classes.button}
              title={Texts.store.buttons.buscar}
              colored
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={['Emissão', 'Nº documento', 'Status']}
            values={nfes.data.data}
            error={nfes.error}
            loading={nfes.loading}
            fields={(nfe) => [
              AddMasks.dateHour(nfe.date_emission),
              nfe.numDocument,
              nfe.history[0]?.status.description,
            ]}
            hasAction
            action={openNote}
            actionLabel="Visualizar"
            Icon={ArrowForward}
          />
        </Grid>
      </Grid>
      <DropzoneDialog
        open={upload}
        acceptedFiles={['.xml ']}
        filesLimit={1}
        onChange={(files) => {
          if (files.length > 0) {
            uploadXML(files[0]);
            setUpload(false);
          }
        }}
        cancelButtonText="Cancelar"
        submitButtonText="Upload"
        dropzoneText="Arraste e solte ou clique para selecionar"
        onClose={() => setUpload(false)}
        dialogTitle="Selecione o XML da nota"
      />
    </Main>
  );
}

export { Fiscal };
