import React from 'react';
import logoWhite from 'assets/img/logo/logo_white.png';
import { useMediaQuery } from '@material-ui/core';
import theme from 'styles/theme';
import { Img } from './components/Styled';

function Logo() {
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return <Img src={logoWhite} width={matches ? '20vw' : '40vw'} />;
}

export { Logo };
