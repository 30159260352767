import Uris from 'constants/endpoints';
import api from 'services/config/api';
import { Order, StatusSale } from 'utils/types/order';
import {
  Comission,
  DashboardSalesPeriod,
  ReportsPeriod,
  SimpleSales,
} from 'utils/types/reports';
import { ResponsePagination } from 'utils/types/response';
import { StockMain, StockStore } from 'utils/types/stock';
import { Store } from 'utils/types/store';

export const ReportsApi = {
  getInfoLowStock: (local: string) => {
    return api.get(Uris.lowStock.replace('{local}', local));
  },
  getSalesByPeriod: (period: ReportsPeriod) => {
    const url = Uris.getSalesByPeriod.replace('{period}', period);
    return api.get<Array<DashboardSalesPeriod>>(
      `${url}?query=constant||eq||ORDER_DELIVERED`
    );
  },
  getSalesByStatus: (status: StatusSale) => {
    return api.get(Uris.getSalesByStatus.replace('{saleStatus}', status));
  },
  getAllStores: (query: string) =>
    api.get<ResponsePagination<Store>>(`${Uris.getStores}${query}`),
  getSales: (query: string = '') =>
    api.get<Array<SimpleSales>>(`${Uris.reportSales}${query}`),
  getSalesShopkeeper: (query: string = '', storeId: number) =>
    api.get<Array<SimpleSales>>(
      `/admin/shopkeeper/store/${storeId}/reports/sales${query}`
    ),
  movMainStock: (params: string = '') =>
    api.get<Array<StockMain>>(`${Uris.movMainStock}${params}`),
  movStockStore: (params: string = '') =>
    api.get<Array<StockStore>>(`${Uris.movStockStores}${params}`),
  getComissions: (query: string = '') =>
    api.get<Array<Comission>>(`/admin/reports/commissions${query}`),
  getComissionsShopkeeper: (query: string = '', storeId: number) =>
    api.get<Array<Comission>>(
      `/admin/shopkeeper/store/${storeId}/reports/commissions${query}`
    ),
  getOpenOrders: (storeId: number) =>
    api.get<ResponsePagination<Order>>(
      `/admin/shopkeeper/store/${storeId}/orders?query=currentStatus.status.constant||like||PAYMENT_CONFIRMED&or=currentStatus.status.constant||like||AWAITING_PAYMENT_CONFIRMATION`
    ),
};
