import React, { useEffect, useState } from 'react';
import Main from 'components/main-container';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { Button } from 'components/button';
import { useHistory } from 'react-router-dom';
import { Texts } from 'constants/strings';
import { AddCircle, ChevronLeft } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import LoadingScreen from 'components/loading-screen';
import { Title } from 'components/title/Title';
import Selecionar from 'components/select';
import {
  purposeEmission,
  presenceBuyer,
  finalCostumer,
  purposeEmissionConstants,
} from 'constants/config/Config';
import { useFornecedor } from 'context/FornecedorProvider';
import LoadingFailure from 'components/loading-failure';
import SearchSelector from 'components/search-selector';
import { useProducts } from 'context/ProductProvider';
import { useFiscal } from 'context/FiscalProvider';
import ErrorContainer from 'components/error-container';
import { ProdutosNota } from './components/ProdutoNota';
import NfeDevolucao from './components/NFeDevolucao';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.dark,
  },
  productErrorsContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function NotaSaida() {
  const classes = useStyles();
  const history = useHistory();
  const [nfe, setNfe] = useState({
    nfesDevolucao: [''],
    purposeEmission: purposeEmission[0].id,
    presenceBuyer: presenceBuyer[0].id,
    finalCostumer: finalCostumer[0].id,
    provider: null,
    products: [],
  });
  const { uploadLoading } = useSelector((state) => state.fiscal.nfe.new);

  const { getFornecedores, fornecedor } = useFornecedor();

  const { getProducts, products } = useProducts();

  const {
    taxs: { icmsTaxSituation, icmsOrigin, pis, cofins, cfop },
    getCfop,
    getPis,
    getCofins,
    getIcmsTaxSituation,
    getIcmsOrigin,
    createOutputNfe,
    outputNfe,
  } = useFiscal();

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    if (icmsTaxSituation.data.length === 0) getIcmsTaxSituation();
    if (icmsOrigin.data.length === 0) getIcmsOrigin();
    if (pis.data.length === 0) getPis();
    if (cofins.data.length === 0) getCofins();
    if (cfop.data.length === 0) getCfop();
    getFornecedores();
    getProducts();
  }, []);

  const create = () => {
    createOutputNfe(nfe);
  };

  const changeField = (id, value) => {
    setNfe({
      ...nfe,
      [id]: value,
    });
  };

  const onSelectProduct = ({ value }) => {
    const prods = value.map((prod) => {
      // checar se item ja existe no array de produtos, a lógica checa se o item do array já está no objeto novo
      if (prod?.productId) return prod;
      // se não foi adicionado, significa que é um produto recém selecionado e pode iniciar com valores padrão.
      const taxSituation = icmsTaxSituation.data.find(
        (item) => item.id === prod.icms_tax_situation_id
      );
      const origin = icmsOrigin.data.find(
        (item) => item.id === prod.icms_origin_id
      );
      const pisTax = pis.data.find((item) => item.id === prod.pis_id);
      const cofinsTax = cofins.data.find((item) => item.id === prod.cofins_id);
      const cfopTax = cfop.data.find((item) => item.id === prod.cfop_id);
      return {
        description: prod.description,
        productId: prod.product_id,
        quantity: '1',
        cfop: cfopTax || { id: prod.cfop_id },
        icmsTaxSituation: taxSituation || { id: prod.icms_tax_situation_id },
        icmsOrigin: origin || { id: prod.icms_origin_id },
        pis: pisTax || { id: prod.pis_id },
        cofins: cofinsTax || { id: prod.cofins_id },
      };
    });
    setNfe((state) => ({ ...state, products: prods }));
  };

  const changeFieldProduct = (productId, key, value) => {
    const prods = nfe.products.map((prod) => ({
      ...prod,
      [key]: prod.productId === productId ? value : prod[key],
    }));
    setNfe((state) => ({ ...state, products: prods }));
  };

  const onChangeInputProvider = (e) => {
    getFornecedores(e.target.value);
  };

  const onChangeInputProduct = (e) => {
    getProducts(e.target.value);
  };

  const Provider = () => {
    return (
      <Grid item xs={12} sm={12}>
        <LoadingFailure failure={fornecedor.errorProvider}>
          <SearchSelector
            id="provider"
            label={Texts.fiscal.fields.provider}
            onChangeInput={onChangeInputProvider}
            getLabel={(item) => `${item.cpf_cnpj} - ${item.fantasy_name}`}
            onChange={({ id, value }) => changeField(id, value)}
            options={fornecedor.providers}
            loading={fornecedor.loadingProvider}
            error={outputNfe.errors.provider}
            value={nfe.provider}
            optionCheckField="provider_id"
            selectedCheckField="provider_id"
          />
        </LoadingFailure>
      </Grid>
    );
  };

  const Products = () => {
    return (
      <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={12}>
          <Title variant="h6">{Texts.fiscal.sessions.produtosNaNota}</Title>
        </Grid>
        <LoadingFailure failure={products.error}>
          <>
            <SearchSelector
              id="products"
              label={Texts.fiscal.fields.products}
              onChangeInput={onChangeInputProduct}
              getLabel={(item) => item.description}
              onChange={onSelectProduct}
              options={products.products}
              loading={products.loading}
              value={nfe.products}
              optionCheckField="product_id"
              selectedCheckField="productId"
              multiple
              error={outputNfe.errors.products}
            />
            <Grid item sm={12} xs={12} spacing={2}>
              <ErrorContainer
                className={classes.productErrorsContainer}
                errors={outputNfe.errors.productsItems}
              />
            </Grid>
            {nfe.products.map((item) => (
              <ProdutosNota
                key={item.productId}
                product={item}
                onChange={changeFieldProduct}
              />
            ))}
          </>
        </LoadingFailure>
      </Grid>
    );
  };

  const NfesDevolucao = () => (
    <>
      <Grid item>
        <Title variant="h6">{Texts.fiscal.sessions.notasFiscalDevolucao}</Title>
      </Grid>
      <Grid item>
        <IconButton
          onClick={() => {
            setNfe({ ...nfe, nfesDevolucao: [...nfe.nfesDevolucao, ''] });
          }}
          size="small"
        >
          <AddCircle className={classes.icon} />
        </IconButton>
      </Grid>
      {nfe.nfesDevolucao.map((item, index) => (
        <NfeDevolucao
          key={String(index)}
          index={index}
          value={item}
          onChange={(selectedIndex, value) => {
            const nfes = [...nfe.nfesDevolucao];
            nfes[selectedIndex] = value;
            setNfe({ ...nfe, nfesDevolucao: nfes });
          }}
          onRemove={(selectedIndex) => {
            const nfes = [...nfe.nfesDevolucao];
            nfes.splice(selectedIndex, 1);
            setNfe({ ...nfe, nfesDevolucao: nfes });
          }}
        />
      ))}
    </>
  );

  const Gerais = () => (
    <>
      <Grid item xs={12} sm={12}>
        <Title variant="h6">{Texts.fiscal.sessions.geral}</Title>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Selecionar
          label="Propósito de Emissão"
          onChange={({ id, value }) => changeField(id, value)}
          id="purposeEmission"
          options={purposeEmission}
          value={nfe.purposeEmission}
          allowEmpty={false}
          error={outputNfe.errors.purposeEmission}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Selecionar
          label={Texts.fiscal.fields.consumidorFinal}
          onChange={({ id, value }) => changeField(id, value)}
          id="finalCostumer"
          options={finalCostumer}
          value={nfe.finalCostumer}
          allowEmpty={false}
          error={outputNfe.errors.finalCostumer}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Selecionar
          label={Texts.fiscal.fields.presencaComprador}
          onChange={({ id, value }) => changeField(id, value)}
          id="presenceBuyer"
          options={presenceBuyer}
          value={nfe.presenceBuyer}
          allowEmpty={false}
          error={outputNfe.errors.presenceBuyer}
        />
      </Grid>
    </>
  );

  return (
    <>
      {uploadLoading && <LoadingScreen size={30} />}
      {!uploadLoading && (
        <Main>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                icon={ChevronLeft}
                onClick={back}
                title={Texts.fiscal.buttons.voltar}
              />
            </Grid>
            <Gerais />
            <Provider />
            {nfe.purposeEmission === purposeEmissionConstants.DEVOLUCAO && (
              <NfesDevolucao />
            )}
            <Products />
            <Grid item sm={12} xs={12}>
              <Button
                icon={AddCircle}
                onClick={create}
                title={Texts.fiscal.buttons.emitirNota}
                colored
                loading={outputNfe.loading}
              />
            </Grid>
          </Grid>
        </Main>
      )}
    </>
  );
}

export { NotaSaida };
