import React, { ReactNode } from 'react';

interface Props {
  condition: boolean | null | undefined;
  children: ReactNode;
}

function If({ condition = false, children }: Props) {
  return <>{condition && children}</>;
}

export default If;
