import { Errors, Texts } from 'constants/strings';
import { EmpresaActions } from 'store/ducks/empresa';
import { NotificationActions } from 'store/ducks/notification';
import { call, put, select } from 'redux-saga/effects';
import { PlugNotasApi } from 'services/api/PlugNotasApi';
import { EmpresaApi } from 'services/api/EmpresaApi';
import { RemoveMasks } from 'utils/Masks';
import { CODE_CITY } from 'constants/config/Config';

export function* getEmpresa() {
  try {
    const response = yield call(EmpresaApi.getCompany);
    const { data } = response.data;
    const empresa = {
      cpf_cnpj: data.cpf_cnpj,
      ie: data.ie,
      social_reason: data.social_reason,
      fantasy_name: data.fantasy_name,
      postcode: data.address.postcode,
      street: data.address.street,
      neighborhood: data.address.neighborhood,
      city: data.address.city,
      uf: data.address.uf,
      number: data.number,
    };
    yield put(EmpresaActions.getEmpresaSuccess(empresa));
  } catch (error) {
    yield put(EmpresaActions.getEmpresaErr());
    yield put(NotificationActions.error(Texts.empresa.texts.getEmpresaError));
  }
}

export function* saveEmpresa() {
  try {
    const {
      cpf_cnpj,
      ie,
      social_reason,
      fantasy_name,
      postcode,
      street,
      neighborhood,
      city,
      uf,
      number,
    } = yield select((state) => state.empresa);
    const data = {
      cpf_cnpj: RemoveMasks.onlyNumbers(cpf_cnpj),
      ie,
      social_reason,
      fantasy_name,
      postcode: RemoveMasks.onlyNumbers(postcode),
      street,
      neighborhood,
      city,
      uf,
      number,
    };
    yield call(EmpresaApi.saveCompany, data);
    yield put(EmpresaActions.requestEmpresaSuccess());
    yield put(NotificationActions.success(Texts.empresa.texts.sucesso));
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const { data } = error.response;
      const errors = {};
      data.forEach((err) => {
        errors[`${err.field}Err`] = err.message;
      });
      yield put(EmpresaActions.requestEmpresaErr(errors));
    } else {
      yield put(EmpresaActions.requestEmpresaErr());
    }
    yield put(NotificationActions.error(Texts.empresa.texts.error));
  }
}

export function* consultaCepEmpresa() {
  try {
    const { postcode } = yield select((state) => state.empresa);
    const { data } = yield call(PlugNotasApi.consultarCep, postcode);
    if (data.erro) {
      throw data;
    }
    const addrs = {
      city: data.municipio,
      uf: data.uf,
      neighborhood: data.bairro,
      street: data.logradouro,
      codeCity: data?.ibge || CODE_CITY,
    };
    yield put(EmpresaActions.getCepSuccess(addrs));
  } catch (error) {
    yield put(NotificationActions.error(Errors.findCep));
    yield put(EmpresaActions.getCepErr());
  }
}
