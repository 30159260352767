import { makeStyles } from '@material-ui/core';
import React from 'react';
import Loading from 'components/loading';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
    minHeight: '80%',
  },
}));

function LoadingScreen(props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Loading {...props} />
    </div>
  );
}

export { LoadingScreen };
