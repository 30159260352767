import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Acl from 'components/acl';
import NotFound from 'pages/not-found';
import RoutesConfig from './RoutesConfig';

function ContainerRoutes() {
  return (
    <Switch>
      {RoutesConfig.map((route) => (
        <Route key={route.module} exact path={route.path}>
          <Acl module={route.module} redirect onlyPdv={route.onlyPdv}>
            <route.component />
          </Acl>
        </Route>
      ))}
      <Route component={NotFound} />
    </Switch>
  );
}

export default ContainerRoutes;
