import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import { Modal } from 'components/modal/Modal';
import Text from 'components/text';
import { makeStyles } from '@material-ui/core';
import { Creators as ResetPasswordActions } from 'store/ducks/reset-password';

const useStyles = makeStyles(() => ({
  subtitle: {
    marginTop: '20px',
    textAlign: 'center',
  },
}));

export default function SendedEmail() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { showModalSendedEmail } = useSelector((state) => state.resetPassword);

  const close = () => {
    dispatch(ResetPasswordActions.closeModais());
  };

  return (
    <div>
      <Modal show={showModalSendedEmail} onClose={close}>
        <Title variant="h4">{Texts.recuperarSenha.titleInsertEmail}</Title>
        <Text style={classes.subtitle}>
          {Texts.recuperarSenha.sendEmailPrefix}
        </Text>
      </Modal>
    </div>
  );
}
