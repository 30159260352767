import React from 'react';
import InsertEmail from './components/InsertEmail';
import SendedEmail from './components/SendEmail';

function ModalResetPassword() {
  return (
    <>
      <InsertEmail />
      <SendedEmail />
    </>
  );
}

export { ModalResetPassword };
