import { ProductApi, StoreApi } from '../api';
import ProductStore from './product.store';
import ShopStore from './shop.store';

const productApi = new ProductApi();
const storeApi = new StoreApi();

class RootStore {
  product: ProductStore;

  store: ShopStore;

  constructor() {
    this.product = new ProductStore(this, {
      productApi,
    });
    this.store = new ShopStore(this, {
      storeApi,
    });
  }
}

const store = new RootStore();

export { RootStore, ProductStore };

export default store;
