import produce from 'immer';

export const Types = {
  SET_STORES: 'userStores@SET_STORES',
  CHANGE_STORE: 'userStores@CHANGE_STORE',
};

const initialState = {
  stores: [],
  actualStore: null,
};

const userStores = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_STORES:
      state.stores = [...payload];
      // eslint-disable-next-line prefer-destructuring
      state.actualStore = payload[0];
      return state;
    case Types.CHANGE_STORE:
      state.actualStore = state.stores[payload];
      return state;
    default:
      return state;
  }
});

export default userStores;

export const UserStoreActions = {
  setStores: (stores) => ({
    type: Types.SET_STORES,
    payload: stores,
  }),
  changeStore: (index) => ({
    type: Types.CHANGE_STORE,
    payload: index,
  }),
};
