import api from 'services/config/api';
import { Message } from 'utils/types/message';
import { ResponsePagination, SimpleResponse } from 'utils/types/response';

export const MessageApi = {
  getMessagesLojista: (storeId: number) =>
    api.get<ResponsePagination<Message>>(
      `/admin/shopkeeper/store/${storeId}/messages?sort=created_at,desc`
    ),
  getMessagesAdmin: (params: string = '') =>
    api.get<ResponsePagination<Message>>(`/admin/stores/messages${params}`),
  createMessage: (title: string, message: string) =>
    api.post<SimpleResponse<any>>(`/admin/stores/messages`, { title, message }),
  updateMessage: (title: string, message: string, messageId: number) =>
    api.put<SimpleResponse<any>>(`/admin/stores/messages/${messageId}`, {
      title,
      message,
    }),
  deleteMessage: (messageId: number) =>
    api.delete(`/admin/stores/messages/${messageId}`),
};
