import { call, put, select } from 'redux-saga/effects';
import { NovaLojaActions } from 'store/ducks/nova-loja';
import { PlugNotasApi } from 'services/api/PlugNotasApi';
import { NotificationActions } from 'store/ducks/notification';
import { Errors, Texts } from 'constants/strings';
import { LojaApi } from 'services/api/LojaApi';
import { LojasActions } from 'store/ducks/lojas';
import { RemoveMasks } from 'utils/Masks';
import { CODE_CITY } from 'constants/config/Config';

export function* consultaCep() {
  try {
    const { postcode } = yield select((state) => state.novaLoja);
    const { data } = yield call(PlugNotasApi.consultarCep, postcode);
    if (data.erro) {
      throw data;
    }
    const addrs = {
      city: data.municipio,
      uf: data.uf,
      neighborhood: data.bairro,
      street: data.logradouro,
      codeCity: data?.ibge || CODE_CITY,
    };
    yield put(NovaLojaActions.getCepSuccess(addrs));
  } catch (error) {
    yield put(NotificationActions.error(Errors.findCep));
    yield put(NovaLojaActions.getCepError());
  }
}

export function* createStore() {
  try {
    const {
      cpf_cnpj,
      social_reason,
      fantasy_name,
      description_search,
      postcode,
      street,
      ie,
      owners,
      neighborhood,
      city,
      uf,
      number,
      zoning_code,
      logo,
      legalNature,
      commission,
      email,
      phone,
      manager,
    } = yield select((state) => state.novaLoja);
    const body = {
      cpf_cnpj: RemoveMasks.onlyNumbers(cpf_cnpj),
      social_reason,
      fantasy_name,
      description_search,
      postcode: RemoveMasks.onlyNumbers(postcode),
      ie,
      owners: owners.map((owner) => ({
        name: owner.name,
        rg: owner.rg,
        cpf: RemoveMasks.onlyNumbers(owner.cpf),
        phone: RemoveMasks.onlyNumbers(owner.phone),
        birthdate: RemoveMasks.dateISO(owner.birthdate),
      })),
      street,
      neighborhood,
      city,
      uf,
      number,
      zoning_code,
      logo,
      legal_nature_id: legalNature?.legal_nature_id,
      email,
      commission: parseFloat(commission),
      phone: RemoveMasks.onlyNumbers(phone),
      manager,
    };
    yield call(LojaApi.createStore, body);
    yield put(NovaLojaActions.createSuccess());
    yield put(NotificationActions.success(Texts.novaLoja.texts.createdStore));
    yield put(NovaLojaActions.reset());
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const { data } = error.response;
      const errors = {};
      data.forEach((err) => {
        if (err.field === 'owners') {
          const ownersErrors = {};
          err.message.forEach((ownersErr) => {
            ownersErrors[`${ownersErr.field}Err`] = ownersErr.message;
          });
          errors[`${err.field}Err`] = ownersErrors;
        } else {
          errors[`${err.field}Err`] = err.message;
        }
      });
      yield put(NovaLojaActions.createErr(errors));
    } else {
      yield put(NovaLojaActions.createErr());
    }
    yield put(NotificationActions.error(Errors.createStore));
  }
}

export function* updateStore() {
  try {
    const {
      cpf_cnpj,
      social_reason,
      fantasy_name,
      description_search,
      postcode,
      street,
      ie,
      owners,
      neighborhood,
      city,
      uf,
      number,
      zoning_code,
      logo,
      legalNature,
      email,
      phone,
      commission,
      manager,
      updateStore: store,
    } = yield select((state) => state.novaLoja);
    const body = {
      cpf_cnpj: RemoveMasks.onlyNumbers(cpf_cnpj),
      social_reason,
      fantasy_name,
      description_search,
      postcode: RemoveMasks.onlyNumbers(postcode),
      ie,
      owners: owners.map((owner) => ({
        name: owner.name,
        rg: owner.rg,
        cpf: RemoveMasks.onlyNumbers(owner.cpf),
        phone: RemoveMasks.onlyNumbers(owner.phone),
        birthdate: RemoveMasks.dateISO(owner.birthdate),
      })),
      street,
      neighborhood,
      city,
      uf,
      number,
      zoning_code,
      logo,
      legal_nature_id: legalNature.legal_nature_id,
      email,
      commission: parseFloat(commission),
      phone: RemoveMasks.onlyNumbers(phone),
      manager,
    };
    yield call(LojaApi.updateStore, body, store.store_id);
    yield put(NotificationActions.success(Texts.novaLoja.texts.updatedStore));
    yield put(LojasActions.filter());
    yield put(NovaLojaActions.updateSuccess());
  } catch (error) {
    if (error.response && error.response.status === 422) {
      const { data } = error.response;
      const errors = {};
      data.forEach((err) => {
        if (err.field === 'owners') {
          const ownersErrors = {};
          err.message.forEach((ownersErr) => {
            ownersErrors[`${ownersErr.field}Err`] = ownersErr.message;
          });
          errors[`${err.field}Err`] = ownersErrors;
        } else {
          errors[`${err.field}Err`] = err.message;
        }
      });
      yield put(NovaLojaActions.updateErr(errors));
    } else {
      yield put(NovaLojaActions.updateErr());
    }
    yield put(NotificationActions.error(Errors.udpateStore));
  }
}

export function* activeInactive() {
  try {
    const { updateStore: store } = yield select((state) => state.novaLoja);
    yield call(LojaApi.activeInactive, store.store_id, !store.active);
    yield put(NotificationActions.success(Texts.novaLoja.texts.updatedStore));
    yield put(LojasActions.filter());
    yield put(NovaLojaActions.activeInactiveSuccess());
  } catch (error) {
    yield put(NovaLojaActions.activeInactiveErr());
    yield put(NotificationActions.error(Errors.inactiveStore));
  }
}
