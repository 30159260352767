import axios from 'axios';
import Uris from 'constants/endpoints';
import { store } from 'store/store';

const api = axios.create({
  baseURL: Uris.URLBASE,
});

api.interceptors.request.use((config) => {
  const { auth } = store.getState();
  const { token } = auth;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
