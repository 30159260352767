import React, { useEffect } from 'react';
import Main from 'components/main-container';
import { Grid } from '@material-ui/core';
import { Button } from 'components/button';
import { useHistory } from 'react-router-dom';
import { Texts } from 'constants/strings';
import { ChevronLeft, EmojiTransportation } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { EmpresaActions } from 'store/ducks/empresa';
import LoadingScreen from 'components/loading-screen';
import DadosGerais from './components/DadosGerais';
import Endereco from './components/Endereco';

function Empresa() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, disableFields, loadingEmpresa } = useSelector(
    (state) => state.empresa
  );

  useEffect(() => {
    dispatch(EmpresaActions.getEmpresa());
  }, [dispatch]);

  const back = () => {
    history.goBack();
  };

  const commit = () => {
    dispatch(EmpresaActions.requestEmpresaSave());
  };

  return (
    <>
      {loadingEmpresa && <LoadingScreen size={30} />}
      {!loadingEmpresa && (
        <Main>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                icon={ChevronLeft}
                onClick={back}
                title={Texts.empresa.buttons.voltar}
              />
            </Grid>
            {!disableFields && (
              <>
                <Grid item xs={12} sm={12}>
                  <Button
                    icon={EmojiTransportation}
                    onClick={commit}
                    title={Texts.empresa.buttons.salvar}
                    colored
                    loading={loading}
                  />
                </Grid>
              </>
            )}
            <DadosGerais />
            <Endereco />
          </Grid>
        </Main>
      )}
    </>
  );
}

export { Empresa };
