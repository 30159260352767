import { combineReducers } from 'redux';
import layout from './layout';
import resetPassword from './reset-password';
import auth from './auth';
import usuarios from './usuarios';
import novoUsuario from './novo-usuario';
import notification from './notification';
import novaLoja from './nova-loja';
import lojas from './lojas';
import produto from './produto';
import novoProduto from './novo-produto';
import user, { Types } from './user';
import empresa from './empresa';
import userStores from './user-store';
import orders from './orders';
import orderDetails from './order-details';
import fiscal from './fiscal';
import dashboard from './dashboard';

const appReducer = combineReducers({
  layout,
  resetPassword,
  auth,
  user,
  usuarios,
  novoUsuario,
  notification,
  lojas,
  produto,
  novaLoja,
  novoProduto,
  empresa,
  userStores,
  orders,
  orderDetails,
  fiscal,
  dashboard,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === Types.LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;

// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof rootReducer>;
