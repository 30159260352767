import { Grid } from '@material-ui/core';
import { AddCircle, Close, Search } from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import Table from 'components/pagination-table';
import { Selecionar } from 'components/select/Selecionar';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import RouterNames from 'constants/router-names';
import { Texts } from 'constants/strings';
import { usePromotions } from 'context/promotions';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NotificationActions } from 'store/ducks/notification';
import { AddMasks } from 'utils/Masks';
import queryCreator from 'utils/queryCreator';
import { Promotion } from 'utils/types/promotion';

type Props = {};

interface FieldsFilter {
  description: string;
  status: string;
}

interface IdValue {
  id: string;
  value: any;
}

const initialFields: FieldsFilter = {
  description: '',
  status: '',
};

const Promotions: FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fields, setFields] = useState<FieldsFilter>(initialFields);
  const { getPromotions, setPromotions, promotions } = usePromotions();

  const newPromotions = () => {
    history.push(RouterNames.ADMIN_PRODUTOS_PROMOCOES_NOVO);
  };

  const updatePromotion = (promotion: Promotion) => {
    history.push(
      RouterNames.ADMIN_PRODUTOS_PROMOCOES_EDITAR.replace(
        ':promotionId',
        promotion.promotion_id ? promotion.promotion_id.toString() : ''
      )
    );
  };

  const changeText = (event: any) => {
    setFields({ ...fields, [event.target.id]: event.target.value });
  };

  const changeSelect = ({ id, value }: IdValue) => {
    setFields({ ...fields, [id]: value });
  };

  const clear = () => setFields(initialFields);

  const filter = useCallback(
    (pageNumber: number = 1) => {
      const queries = [];
      const { description, status } = fields;
      if (description.trim()) {
        queries.push(queryCreator.like('description', description));
      }
      if (status) {
        queries.push(queryCreator.bool('active', status));
      }

      setPromotions(null);
      getPromotions(
        queryCreator.formatter({
          queries,
          page: pageNumber,
          sort: 'desc',
          field: 'created_at',
        })
      );
      dispatch(NotificationActions.info('Buscando, aguarde um momento...'));
    },
    [fields, dispatch, getPromotions, setPromotions]
  );

  useEffect(() => {
    filter(1);
    return () => setPromotions(null);
  }, []);

  const total = promotions?.total ? parseInt(promotions.total, 10) : 0;

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={AddCircle}
            onClick={newPromotions}
            title={Texts.promotions.buttons.cadastrarPromocoes}
            colored
          />
        </Grid>
        <Grid item xs={12}>
          <Title>{Texts.promotions.sessions.filtrarPromocoes}</Title>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextInput
            label={Texts.promotions.fields.descricao}
            id="description"
            onChange={changeText}
            value={fields.description}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Selecionar
            label={Texts.promotions.fields.status}
            onChange={changeSelect}
            value={fields.status}
            id="status"
            options={[
              { id: '1', name: 'Ativa' },
              { id: '0', name: 'Inativa' },
            ]}
          />
        </Grid>
        <Grid item>
          <Button
            icon={Close}
            onClick={clear}
            title={Texts.promotions.buttons.limpar}
          />
        </Grid>
        <Grid item>
          <Button
            icon={Search}
            onClick={() => filter(1)}
            title={Texts.promotions.buttons.buscar}
            colored
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Table
            labels={Texts.promotions.tabela}
            values={promotions?.data || []}
            fields={(promotion) => [
              promotion.description.toString(),
              promotion.active ? 'Sim' : 'Não',
              AddMasks.dateHour(promotion.starts_in),
              AddMasks.dateHour(promotion.ends_in),
            ]}
            hasAction
            action={updatePromotion}
            actionLabel="Detalhes"
            Icon={AddCircle}
            pagination={total > 0}
            page={promotions?.page || 1}
            total={total}
            perPage={promotions?.perPage}
            onChangePage={(numPage) => filter(numPage)}
          />
        </Grid>
      </Grid>
    </Main>
  );
};

export { Promotions };
