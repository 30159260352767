import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import theme from 'styles/theme';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  title: {
    color: (props) => props.color,
    textAlign: (props) => (props.center ? 'center' : 'left'),
  },
}));

function Text({ children, color, style, center, noWrap }) {
  const classes = useStyles({ color, center });
  return (
    <Typography noWrap={noWrap} className={clsx(classes.title, style)}>
      {children}
    </Typography>
  );
}

Text.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  style: PropTypes.string,
  center: PropTypes.bool,
  noWrap: PropTypes.bool,
};

Text.defaultProps = {
  children: '',
  color: theme.palette.text.primary,
  style: '',
  center: false,
  noWrap: true,
};

export { Text };
