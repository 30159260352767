import { makeStyles } from '@material-ui/core';
import React from 'react';
import Text from 'components/text';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  bold: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
}));

function Info({ title, value }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Text style={classes.bold}>{`${title}:`}</Text>
      <Text>{value}</Text>
    </div>
  );
}

Info.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

Info.defaultProps = {
  title: '',
  value: '',
};

export default Info;
