import Uris from 'constants/endpoints';
import api from 'services/config/api';

export const DashboardApi = {
  getInfoLowStock: (local) => {
    return api.get(Uris.lowStock.replace('{local}', local));
  },
  getOrdersByStatus: (status) => {
    return api.get(Uris.getSalesByStatus.replace('{saleStatus}', status));
  },
};
