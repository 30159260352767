import { Grid, makeStyles } from '@material-ui/core';
import { Close, PersonAdd, Search } from '@material-ui/icons';
import { Button } from 'components/button';
import Main from 'components/main-container';
import Tabela from 'components/table';
import { TextInput } from 'components/text-input';
import { Title } from 'components/title/Title';
import RouterNames from 'constants/router-names';
import { Texts } from 'constants/strings';
import { ProdutoActions } from 'store/ducks/produto';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
  },
  button: {
    marginRight: theme.spacing(2),
  },
}));

function Providers() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { providers, providerLoading, providerError } = useSelector(
    (state) => state.produto
  );

  useEffect(() => {
    dispatch(ProdutoActions.requestProviders());
  }, [dispatch]);

  const [filter, setFilter] = useState({
    cpfCnpj: '',
    fantasyName: '',
  });

  const createProvider = () => {
    history.push(RouterNames.PROVIDERS_NOVO);
  };

  const editProvider = (provider) => {
    dispatch(ProdutoActions.updateProvider(provider));
    history.push(RouterNames.PROVIDERS_EDIT);
  };

  const clear = () => {
    setFilter({
      cpfCnpj: '',
      fantasyName: '',
    });
  };

  const changeText = (event) => {
    setFilter({ ...filter, [event.target.id]: event.target.value });
  };

  const doSearch = () => {
    dispatch(
      ProdutoActions.requestProviders(filter.cpfCnpj, filter.fantasyName)
    );
  };

  return (
    <Main>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            icon={PersonAdd}
            onClick={createProvider}
            title={Texts.providers.buttons.cadastrar}
            colored
          />
        </Grid>
        <Grid item xs={12}>
          <Title>{Texts.providers.sessions.buscar}</Title>
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextInput
            label={Texts.providers.fields.cpfCnpj}
            id="cpfCnpj"
            onChange={changeText}
            value={filter.cpfCnpj}
            disabled={providerLoading || providerError}
          />
        </Grid>
        <Grid item xs={8} sm={6}>
          <TextInput
            label={Texts.providers.fields.fantasyName}
            id="fantasyName"
            onChange={changeText}
            value={filter.fantasyName}
            disabled={providerLoading || providerError}
          />
        </Grid>
        <Grid item>
          <div className={classes.buttonContainer}>
            <Button
              icon={Close}
              onClick={clear}
              style={classes.button}
              title={Texts.providers.buttons.limpar}
            />
            <Button
              icon={Search}
              onClick={doSearch}
              style={classes.button}
              title={Texts.providers.buttons.buscar}
              colored
              disable={providerLoading || providerError}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Tabela
            error={providerError}
            loading={providerLoading}
            titles={Texts.providers.tabela}
            values={providers}
            fields={['fantasy_name', 'cpf_cnpj']}
            hasAction
            action={editProvider}
          />
        </Grid>
      </Grid>
    </Main>
  );
}

export { Providers };
