import { Grid, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import { Texts } from 'constants/strings';
import { TextInput } from 'components/text-input';
import { useDispatch, useSelector } from 'react-redux';
import { NovaLojaActions } from 'store/ducks/nova-loja';
import Masks from 'utils/Masks';
import PropTypes from 'prop-types';
import { Title } from 'components/title/Title';
import { Cancel } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  subtitleContent: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
  },
}));

function ProprietarioItem({ index }) {
  const {
    name,
    nameErr,
    birthdate,
    birthdateErr,
    rg,
    rgErr,
    cpf,
    cpfErr,
    phone,
    phoneErr,
  } = useSelector((state) => state.novaLoja.owners[index]);
  const { disableFields } = useSelector((state) => state.novaLoja);
  const dispatch = useDispatch();
  const classes = useStyles();

  const changeText = (event) => {
    dispatch(
      NovaLojaActions.updateFieldsProp(
        event.target.id,
        event.target.value,
        index
      )
    );
  };

  const removeProp = () => {
    dispatch(NovaLojaActions.removeProp(index));
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <div className={classes.subtitleContent}>
          <Title variant="h6">{`Proprietário ${index + 1}`}</Title>
          <IconButton
            disabled={disableFields}
            className={classes.iconButton}
            onClick={removeProp}
          >
            <Cancel className={classes.icon} />
          </IconButton>
        </div>
      </Grid>
      {
        // Nome Completo e Data Nascimento
      }
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          disabled={disableFields}
          label={Texts.novaLoja.fields.nomeCompleto}
          id="name"
          onChange={changeText}
          value={name}
          error={nameErr}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput
          required
          label={Texts.novaLoja.fields.nasc}
          id="birthdate"
          disabled={disableFields}
          mask={Masks.data}
          onChange={changeText}
          value={birthdate}
          error={birthdateErr}
        />
      </Grid>
      {
        // :RG - CPF - Telefone
      }
      <Grid item xs={12} sm={4}>
        <TextInput
          label={Texts.novaLoja.fields.rg}
          id="rg"
          required
          disabled={disableFields}
          mask={Masks.rg}
          onChange={changeText}
          value={rg}
          error={rgErr}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          required
          label={Texts.novaLoja.fields.cpf}
          id="cpf"
          mask={Masks.cpf}
          disabled={disableFields}
          onChange={changeText}
          value={cpf}
          error={cpfErr}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextInput
          required
          disabled={disableFields}
          label={Texts.novaLoja.fields.telefone}
          id="phone"
          mask={Masks.celular}
          onChange={changeText}
          value={phone}
          error={phoneErr}
        />
      </Grid>
    </>
  );
}

ProprietarioItem.propTypes = {
  index: PropTypes.number.isRequired,
};

export default ProprietarioItem;
