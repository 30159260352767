import { action, observable, makeObservable } from 'mobx';
import { StoreApi } from 'api';
import { ResponsePagination } from 'utils/types/response';
import { Product, ProductDetails } from 'utils/types/product';
import { Quantities, Store } from 'utils/types/store';
import { RootStore } from './index';

type InjectedApis = {
  storeApi: StoreApi;
};

const MIN_STOCK = 0;

const quantitiesDefault: Quantities = {
  min: MIN_STOCK,
  max: 99,
  selected: 1,
};

export default class ShopStore {
  rootStore: RootStore;

  storeApi: StoreApi;

  @observable
  inventoryStore: Store | null = null;

  @observable inventory: ProductDetails[] = [];

  @observable quantities: Quantities = quantitiesDefault;

  @observable inventoryProduct: Product | ProductDetails | null = null;

  @observable removeMode: boolean = false;

  constructor(rootStore: RootStore, { storeApi }: InjectedApis) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.storeApi = storeApi;
  }

  @action
  getStores = async (query = ''): Promise<ResponsePagination<Store>> => {
    return this.storeApi.getStore(query);
  };

  @action
  setInventoryStory = (store: Store) => {
    this.inventoryStore = store;
  };

  @action
  getInventoryByStore = async (): Promise<void> => {
    if (this.inventoryStore) {
      const inventory = await this.storeApi.getStoreInventory(
        this.inventoryStore.store_id
      );
      this.inventory = inventory.filter(
        (item) => item?.storeProduct[0].quantity !== 0
      );
    }
  };

  @action
  setSelectedProductInventory = (product: ProductDetails) => {
    this.removeMode = true;
    this.inventoryProduct = product;
    const max = product?.storeProduct[0].quantity;
    this.quantities = {
      min: MIN_STOCK,
      max,
      selected: 1,
    };
  };

  @action
  setSelectedProduct = (product: Product): void => {
    this.inventoryProduct = product;
    const max = product.stock;
    this.quantities = {
      min: MIN_STOCK,
      max,
      selected: 1,
    };
  };

  @action
  calcInventory = (selected: number): void => {
    this.quantities = {
      ...this.quantities,
      selected,
    };
  };

  @action
  onChangeQuantity = (quantity: number): void => {
    if (quantity > this.quantities.max) {
      this.calcInventory(this.quantities.max);
    } else {
      this.calcInventory(quantity);
    }
  };

  @action
  resetInventory = (): void => {
    this.resetProductInventory();
    this.inventoryStore = null;
    this.inventory = [];
  };

  @action
  resetProductInventory = (): void => {
    this.inventoryProduct = null;
    this.quantities = quantitiesDefault;
    this.removeMode = false;
  };

  @action
  onUpdateInventory = async (): Promise<void> => {
    // atualizar para valor selecionado
    if (this.removeMode) {
      const quantity =
        this.quantities.selected === 0
          ? this.quantities.max
          : this.quantities.max - this.quantities.selected;
      await this.storeApi.removeProductFromStore(
        this.inventoryProduct?.product_id || 0,
        this.inventoryStore?.store_id || 0,
        quantity
      );
    }
    // adicionar valor selecionado
    else {
      await this.storeApi.sendProductToStore(
        this.inventoryProduct?.product_id || 0,
        this.inventoryStore?.store_id || 0,
        this.quantities.selected
      );
    }
    this.resetProductInventory();
  };
}
