const citycodes = [
  { code: '5300108', name: 'Brasília' },
  { code: '1100015', name: "Alta Floresta D'Oeste" },
  { code: '1100023', name: 'Ariquemes' },
  { code: '1100031', name: 'Cabixi' },
  { code: '1100049', name: 'Cacoal' },
  { code: '1100056', name: 'Cerejeiras' },
  { code: '1100064', name: 'Colorado do Oeste' },
  { code: '1100072', name: 'Corumbiara' },
  { code: '1100080', name: 'Costa Marques' },
  { code: '1100098', name: "Espigão D'Oeste" },
  { code: '1100106', name: 'Guajará-Mirim' },
  { code: '1100114', name: 'Jaru' },
  { code: '1100122', name: 'Ji-Paraná' },
  { code: '1100130', name: "Machadinho D'Oeste" },
  { code: '1100148', name: "Nova Brasilândia D'Oeste" },
  { code: '1100155', name: 'Ouro Preto do Oeste' },
  { code: '1100189', name: 'Pimenta Bueno' },
  { code: '1100205', name: 'Porto Velho' },
  { code: '1100254', name: 'Presidente Médici' },
  { code: '1100262', name: 'Rio Crespo' },
  { code: '1100288', name: 'Rolim de Moura' },
  { code: '1100296', name: "Santa Luzia D'Oeste" },
  { code: '1100304', name: 'Vilhena' },
  { code: '1100320', name: 'São Miguel do Guaporé' },
  { code: '1100338', name: 'Nova Mamoré' },
  { code: '1100346', name: "Alvorada D'Oeste" },
  { code: '1100379', name: 'Alto Alegre dos Parecis' },
  { code: '1100403', name: 'Alto Paraíso' },
  { code: '1100452', name: 'Buritis' },
  { code: '1100502', name: 'Novo Horizonte do Oeste' },
  { code: '1100601', name: 'Cacaulândia' },
  { code: '1100700', name: 'Campo Novo de Rondônia' },
  { code: '1100809', name: 'Candeias do Jamari' },
  { code: '1100908', name: 'Castanheiras' },
  { code: '1100924', name: 'Chupinguaia' },
  { code: '1100940', name: 'Cujubim' },
  { code: '1101005', name: 'Governador Jorge Teixeira' },
  { code: '1101104', name: 'Itapuã do Oeste' },
  { code: '1101203', name: 'Ministro Andreazza' },
  { code: '1101302', name: 'Mirante da Serra' },
  { code: '1101401', name: 'Monte Negro' },
  { code: '1101435', name: 'Nova União' },
  { code: '1101450', name: 'Parecis' },
  { code: '1101468', name: 'Pimenteiras do Oeste' },
  { code: '1101476', name: 'Primavera de Rondônia' },
  { code: '1101484', name: "São Felipe D'Oeste" },
  { code: '1101492', name: 'São Francisco do Guaporé' },
  { code: '1101500', name: 'Seringueiras' },
  { code: '1101559', name: 'Teixeirópolis' },
  { code: '1101609', name: 'Theobroma' },
  { code: '1101708', name: 'Urupá' },
  { code: '1101757', name: 'Vale do Anari' },
  { code: '1101807', name: 'Vale do Paraíso' },
  { code: '1200013', name: 'Acrelândia' },
  { code: '1200054', name: 'Assis Brasil' },
  { code: '1200104', name: 'Brasiléia' },
  { code: '1200138', name: 'Bujari' },
  { code: '1200179', name: 'Capixaba' },
  { code: '1200203', name: 'Cruzeiro do Sul' },
  { code: '1200252', name: 'Epitaciolândia' },
  { code: '1200302', name: 'Feijó' },
  { code: '1200328', name: 'Jordão' },
  { code: '1200336', name: 'Mâncio Lima' },
  { code: '1200344', name: 'Manoel Urbano' },
  { code: '1200351', name: 'Marechal Thaumaturgo' },
  { code: '1200385', name: 'Plácido de Castro' },
  { code: '1200393', name: 'Porto Walter' },
  { code: '1200401', name: 'Rio Branco' },
  { code: '1200427', name: 'Rodrigues Alves' },
  { code: '1200435', name: 'Santa Rosa do Purus' },
  { code: '1200450', name: 'Senador Guiomard' },
  { code: '1200500', name: 'Sena Madureira' },
  { code: '1200609', name: 'Tarauacá' },
  { code: '1200708', name: 'Xapuri' },
  { code: '1200807', name: 'Porto Acre' },
  { code: '1300029', name: 'Alvarães' },
  { code: '1300060', name: 'Amaturá' },
  { code: '1300086', name: 'Anamã' },
  { code: '1300102', name: 'Anori' },
  { code: '1300144', name: 'Apuí' },
  { code: '1300201', name: 'Atalaia do Norte' },
  { code: '1300300', name: 'Autazes' },
  { code: '1300409', name: 'Barcelos' },
  { code: '1300508', name: 'Barreirinha' },
  { code: '1300607', name: 'Benjamin Constant' },
  { code: '1300631', name: 'Beruri' },
  { code: '1300680', name: 'Boa Vista do Ramos' },
  { code: '1300706', name: 'Boca do Acre' },
  { code: '1300805', name: 'Borba' },
  { code: '1300839', name: 'Caapiranga' },
  { code: '1300904', name: 'Canutama' },
  { code: '1301001', name: 'Carauari' },
  { code: '1301100', name: 'Careiro' },
  { code: '1301159', name: 'Careiro da Várzea' },
  { code: '1301209', name: 'Coari' },
  { code: '1301308', name: 'Codajás' },
  { code: '1301407', name: 'Eirunepé' },
  { code: '1301506', name: 'Envira' },
  { code: '1301605', name: 'Fonte Boa' },
  { code: '1301654', name: 'Guajará' },
  { code: '1301704', name: 'Humaitá' },
  { code: '1301803', name: 'Ipixuna' },
  { code: '1301852', name: 'Iranduba' },
  { code: '1301902', name: 'Itacoatiara' },
  { code: '1301951', name: 'Itamarati' },
  { code: '1302009', name: 'Itapiranga' },
  { code: '1302108', name: 'Japurá' },
  { code: '1302207', name: 'Juruá' },
  { code: '1302306', name: 'Jutaí' },
  { code: '1302405', name: 'Lábrea' },
  { code: '1302504', name: 'Manacapuru' },
  { code: '1302553', name: 'Manaquiri' },
  { code: '1302603', name: 'Manaus' },
  { code: '1302702', name: 'Manicoré' },
  { code: '1302801', name: 'Maraã' },
  { code: '1302900', name: 'Maués' },
  { code: '1303007', name: 'Nhamundá' },
  { code: '1303106', name: 'Nova Olinda do Norte' },
  { code: '1303205', name: 'Novo Airão' },
  { code: '1303304', name: 'Novo Aripuanã' },
  { code: '1303403', name: 'Parintins' },
  { code: '1303502', name: 'Pauini' },
  { code: '1303536', name: 'Presidente Figueiredo' },
  { code: '1303569', name: 'Rio Preto da Eva' },
  { code: '1303601', name: 'Santa Isabel do Rio Negro' },
  { code: '1303700', name: 'Santo Antônio do Içá' },
  { code: '1303809', name: 'São Gabriel da Cachoeira' },
  { code: '1303908', name: 'São Paulo de Olivença' },
  { code: '1303957', name: 'São Sebastião do Uatumã' },
  { code: '1304005', name: 'Silves' },
  { code: '1304062', name: 'Tabatinga' },
  { code: '1304104', name: 'Tapauá' },
  { code: '1304203', name: 'Tefé' },
  { code: '1304237', name: 'Tonantins' },
  { code: '1304260', name: 'Uarini' },
  { code: '1304302', name: 'Urucará' },
  { code: '1304401', name: 'Urucurituba' },
  { code: '1400027', name: 'Amajari' },
  { code: '1400050', name: 'Alto Alegre' },
  { code: '1400100', name: 'Boa Vista' },
  { code: '1400159', name: 'Bonfim' },
  { code: '1400175', name: 'Cantá' },
  { code: '1400209', name: 'Caracaraí' },
  { code: '1400233', name: 'Caroebe' },
  { code: '1400282', name: 'Iracema' },
  { code: '1400308', name: 'Mucajaí' },
  { code: '1400407', name: 'Normandia' },
  { code: '1400456', name: 'Pacaraima' },
  { code: '1400472', name: 'Rorainópolis' },
  { code: '1400506', name: 'São João da Baliza' },
  { code: '1400605', name: 'São Luiz' },
  { code: '1400704', name: 'Uiramutã' },
  { code: '1500107', name: 'Abaetetuba' },
  { code: '1500131', name: 'Abel Figueiredo' },
  { code: '1500206', name: 'Acará' },
  { code: '1500305', name: 'Afuá' },
  { code: '1500347', name: 'Água Azul do Norte' },
  { code: '1500404', name: 'Alenquer' },
  { code: '1500503', name: 'Almeirim' },
  { code: '1500602', name: 'Altamira' },
  { code: '1500701', name: 'Anajás' },
  { code: '1500800', name: 'Ananindeua' },
  { code: '1500859', name: 'Anapu' },
  { code: '1500909', name: 'Augusto Corrêa' },
  { code: '1500958', name: 'Aurora do Pará' },
  { code: '1501006', name: 'Aveiro' },
  { code: '1501105', name: 'Bagre' },
  { code: '1501204', name: 'Baião' },
  { code: '1501253', name: 'Bannach' },
  { code: '1501303', name: 'Barcarena' },
  { code: '1501402', name: 'Belém' },
  { code: '1501451', name: 'Belterra' },
  { code: '1501501', name: 'Benevides' },
  { code: '1501576', name: 'Bom Jesus do Tocantins' },
  { code: '1501600', name: 'Bonito' },
  { code: '1501709', name: 'Bragança' },
  { code: '1501725', name: 'Brasil Novo' },
  { code: '1501758', name: 'Brejo Grande do Araguaia' },
  { code: '1501782', name: 'Breu Branco' },
  { code: '1501808', name: 'Breves' },
  { code: '1501907', name: 'Bujaru' },
  { code: '1501956', name: 'Cachoeira do Piriá' },
  { code: '1502004', name: 'Cachoeira do Arari' },
  { code: '1502103', name: 'Cametá' },
  { code: '1502152', name: 'Canaã dos Carajás' },
  { code: '1502202', name: 'Capanema' },
  { code: '1502301', name: 'Capitão Poço' },
  { code: '1502400', name: 'Castanhal' },
  { code: '1502509', name: 'Chaves' },
  { code: '1502608', name: 'Colares' },
  { code: '1502707', name: 'Conceição do Araguaia' },
  { code: '1502756', name: 'Concórdia do Pará' },
  { code: '1502764', name: 'Cumaru do Norte' },
  { code: '1502772', name: 'Curionópolis' },
  { code: '1502806', name: 'Curralinho' },
  { code: '1502855', name: 'Curuá' },
  { code: '1502905', name: 'Curuçá' },
  { code: '1502939', name: 'Dom Eliseu' },
  { code: '1502954', name: 'Eldorado dos Carajás' },
  { code: '1503002', name: 'Faro' },
  { code: '1503044', name: 'Floresta do Araguaia' },
  { code: '1503077', name: 'Garrafão do Norte' },
  { code: '1503093', name: 'Goianésia do Pará' },
  { code: '1503101', name: 'Gurupá' },
  { code: '1503200', name: 'Igarapé-Açu' },
  { code: '1503309', name: 'Igarapé-Miri' },
  { code: '1503408', name: 'Inhangapi' },
  { code: '1503457', name: 'Ipixuna do Pará' },
  { code: '1503507', name: 'Irituia' },
  { code: '1503606', name: 'Itaituba' },
  { code: '1503705', name: 'Itupiranga' },
  { code: '1503754', name: 'Jacareacanga' },
  { code: '1503804', name: 'Jacundá' },
  { code: '1503903', name: 'Juruti' },
  { code: '1504000', name: 'Limoeiro do Ajuru' },
  { code: '1504059', name: 'Mãe do Rio' },
  { code: '1504109', name: 'Magalhães Barata' },
  { code: '1504208', name: 'Marabá' },
  { code: '1504307', name: 'Maracanã' },
  { code: '1504406', name: 'Marapanim' },
  { code: '1504422', name: 'Marituba' },
  { code: '1504455', name: 'Medicilândia' },
  { code: '1504505', name: 'Melgaço' },
  { code: '1504604', name: 'Mocajuba' },
  { code: '1504703', name: 'Moju' },
  { code: '1504752', name: 'Mojuí dos Campos' },
  { code: '1504802', name: 'Monte Alegre' },
  { code: '1504901', name: 'Muaná' },
  { code: '1504950', name: 'Nova Esperança do Piriá' },
  { code: '1504976', name: 'Nova Ipixuna' },
  { code: '1505007', name: 'Nova Timboteua' },
  { code: '1505031', name: 'Novo Progresso' },
  { code: '1505064', name: 'Novo Repartimento' },
  { code: '1505106', name: 'Óbidos' },
  { code: '1505205', name: 'Oeiras do Pará' },
  { code: '1505304', name: 'Oriximiná' },
  { code: '1505403', name: 'Ourém' },
  { code: '1505437', name: 'Ourilândia do Norte' },
  { code: '1505486', name: 'Pacajá' },
  { code: '1505494', name: 'Palestina do Pará' },
  { code: '1505502', name: 'Paragominas' },
  { code: '1505536', name: 'Parauapebas' },
  { code: '1505551', name: "Pau D'Arco" },
  { code: '1505601', name: 'Peixe-Boi' },
  { code: '1505635', name: 'Piçarra' },
  { code: '1505650', name: 'Placas' },
  { code: '1505700', name: 'Ponta de Pedras' },
  { code: '1505809', name: 'Portel' },
  { code: '1505908', name: 'Porto de Moz' },
  { code: '1506005', name: 'Prainha' },
  { code: '1506104', name: 'Primavera' },
  { code: '1506112', name: 'Quatipuru' },
  { code: '1506138', name: 'Redenção' },
  { code: '1506161', name: 'Rio Maria' },
  { code: '1506187', name: 'Rondon do Pará' },
  { code: '1506195', name: 'Rurópolis' },
  { code: '1506203', name: 'Salinópolis' },
  { code: '1506302', name: 'Salvaterra' },
  { code: '1506351', name: 'Santa Bárbara do Pará' },
  { code: '1506401', name: 'Santa Cruz do Arari' },
  { code: '1506500', name: 'Santa Isabel do Pará' },
  { code: '1506559', name: 'Santa Luzia do Pará' },
  { code: '1506583', name: 'Santa Maria das Barreiras' },
  { code: '1506609', name: 'Santa Maria do Pará' },
  { code: '1506708', name: 'Santana do Araguaia' },
  { code: '1506807', name: 'Santarém' },
  { code: '1506906', name: 'Santarém Novo' },
  { code: '1507003', name: 'Santo Antônio do Tauá' },
  { code: '1507102', name: 'São Caetano de Odivelas' },
  { code: '1507151', name: 'São Domingos do Araguaia' },
  { code: '1507201', name: 'São Domingos do Capim' },
  { code: '1507300', name: 'São Félix do Xingu' },
  { code: '1507409', name: 'São Francisco do Pará' },
  { code: '1507458', name: 'São Geraldo do Araguaia' },
  { code: '1507466', name: 'São João da Ponta' },
  { code: '1507474', name: 'São João de Pirabas' },
  { code: '1507508', name: 'São João do Araguaia' },
  { code: '1507607', name: 'São Miguel do Guamá' },
  { code: '1507706', name: 'São Sebastião da Boa Vista' },
  { code: '1507755', name: 'Sapucaia' },
  { code: '1507805', name: 'Senador José Porfírio' },
  { code: '1507904', name: 'Soure' },
  { code: '1507953', name: 'Tailândia' },
  { code: '1507961', name: 'Terra Alta' },
  { code: '1507979', name: 'Terra Santa' },
  { code: '1508001', name: 'Tomé-Açu' },
  { code: '1508035', name: 'Tracuateua' },
  { code: '1508050', name: 'Trairão' },
  { code: '1508084', name: 'Tucumã' },
  { code: '1508100', name: 'Tucuruí' },
  { code: '1508126', name: 'Ulianópolis' },
  { code: '1508159', name: 'Uruará' },
  { code: '1508209', name: 'Vigia' },
  { code: '1508308', name: 'Viseu' },
  { code: '1508357', name: 'Vitória do Xingu' },
  { code: '1508407', name: 'Xinguara' },
  { code: '1600055', name: 'Serra do Navio' },
  { code: '1600105', name: 'Amapá' },
  { code: '1600154', name: 'Pedra Branca do Amapari' },
  { code: '1600204', name: 'Calçoene' },
  { code: '1600212', name: 'Cutias' },
  { code: '1600238', name: 'Ferreira Gomes' },
  { code: '1600253', name: 'Itaubal' },
  { code: '1600279', name: 'Laranjal do Jari' },
  { code: '1600303', name: 'Macapá' },
  { code: '1600402', name: 'Mazagão' },
  { code: '1600501', name: 'Oiapoque' },
  { code: '1600535', name: 'Porto Grande' },
  { code: '1600550', name: 'Pracuúba' },
  { code: '1600600', name: 'Santana' },
  { code: '1600709', name: 'Tartarugalzinho' },
  { code: '1600808', name: 'Vitória do Jari' },
  { code: '1700251', name: 'Abreulândia' },
  { code: '1700301', name: 'Aguiarnópolis' },
  { code: '1700350', name: 'Aliança do Tocantins' },
  { code: '1700400', name: 'Almas' },
  { code: '1700707', name: 'Alvorada' },
  { code: '1701002', name: 'Ananás' },
  { code: '1701051', name: 'Angico' },
  { code: '1701101', name: 'Aparecida do Rio Negro' },
  { code: '1701309', name: 'Aragominas' },
  { code: '1701903', name: 'Araguacema' },
  { code: '1702000', name: 'Araguaçu' },
  { code: '1702109', name: 'Araguaína' },
  { code: '1702158', name: 'Araguanã' },
  { code: '1702208', name: 'Araguatins' },
  { code: '1702307', name: 'Arapoema' },
  { code: '1702406', name: 'Arraias' },
  { code: '1702554', name: 'Augustinópolis' },
  { code: '1702703', name: 'Aurora do Tocantins' },
  { code: '1702901', name: 'Axixá do Tocantins' },
  { code: '1703008', name: 'Babaçulândia' },
  { code: '1703057', name: 'Bandeirantes do Tocantins' },
  { code: '1703073', name: 'Barra do Ouro' },
  { code: '1703107', name: 'Barrolândia' },
  { code: '1703206', name: 'Bernardo Sayão' },
  { code: '1703305', name: 'Bom Jesus do Tocantins' },
  { code: '1703602', name: 'Brasilândia do Tocantins' },
  { code: '1703701', name: 'Brejinho de Nazaré' },
  { code: '1703800', name: 'Buriti do Tocantins' },
  { code: '1703826', name: 'Cachoeirinha' },
  { code: '1703842', name: 'Campos Lindos' },
  { code: '1703867', name: 'Cariri do Tocantins' },
  { code: '1703883', name: 'Carmolândia' },
  { code: '1703891', name: 'Carrasco Bonito' },
  { code: '1703909', name: 'Caseara' },
  { code: '1704105', name: 'Centenário' },
  { code: '1704600', name: 'Chapada de Areia' },
  { code: '1705102', name: 'Chapada da Natividade' },
  { code: '1705508', name: 'Colinas do Tocantins' },
  { code: '1705557', name: 'Combinado' },
  { code: '1705607', name: 'Conceição do Tocantins' },
  { code: '1706001', name: 'Couto de Magalhães' },
  { code: '1706100', name: 'Cristalândia' },
  { code: '1706258', name: 'Crixás do Tocantins' },
  { code: '1706506', name: 'Darcinópolis' },
  { code: '1707009', name: 'Dianópolis' },
  { code: '1707108', name: 'Divinópolis do Tocantins' },
  { code: '1707207', name: 'Dois Irmãos do Tocantins' },
  { code: '1707306', name: 'Dueré' },
  { code: '1707405', name: 'Esperantina' },
  { code: '1707553', name: 'Fátima' },
  { code: '1707652', name: 'Figueirópolis' },
  { code: '1707702', name: 'Filadélfia' },
  { code: '1708205', name: 'Formoso do Araguaia' },
  { code: '1708254', name: 'Fortaleza do Tabocão' },
  { code: '1708304', name: 'Goianorte' },
  { code: '1709005', name: 'Goiatins' },
  { code: '1709302', name: 'Guaraí' },
  { code: '1709500', name: 'Gurupi' },
  { code: '1709807', name: 'Ipueiras' },
  { code: '1710508', name: 'Itacajá' },
  { code: '1710706', name: 'Itaguatins' },
  { code: '1710904', name: 'Itapiratins' },
  { code: '1711100', name: 'Itaporã do Tocantins' },
  { code: '1711506', name: 'Jaú do Tocantins' },
  { code: '1711803', name: 'Juarina' },
  { code: '1711902', name: 'Lagoa da Confusão' },
  { code: '1711951', name: 'Lagoa do Tocantins' },
  { code: '1712009', name: 'Lajeado' },
  { code: '1712157', name: 'Lavandeira' },
  { code: '1712405', name: 'Lizarda' },
  { code: '1712454', name: 'Luzinópolis' },
  { code: '1712504', name: 'Marianópolis do Tocantins' },
  { code: '1712702', name: 'Mateiros' },
  { code: '1712801', name: 'Maurilândia do Tocantins' },
  { code: '1713205', name: 'Miracema do Tocantins' },
  { code: '1713304', name: 'Miranorte' },
  { code: '1713601', name: 'Monte do Carmo' },
  { code: '1713700', name: 'Monte Santo do Tocantins' },
  { code: '1713809', name: 'Palmeiras do Tocantins' },
  { code: '1713957', name: 'Muricilândia' },
  { code: '1714203', name: 'Natividade' },
  { code: '1714302', name: 'Nazaré' },
  { code: '1714880', name: 'Nova Olinda' },
  { code: '1715002', name: 'Nova Rosalândia' },
  { code: '1715101', name: 'Novo Acordo' },
  { code: '1715150', name: 'Novo Alegre' },
  { code: '1715259', name: 'Novo Jardim' },
  { code: '1715507', name: 'Oliveira de Fátima' },
  { code: '1715705', name: 'Palmeirante' },
  { code: '1715754', name: 'Palmeirópolis' },
  { code: '1716109', name: 'Paraíso do Tocantins' },
  { code: '1716208', name: 'Paranã' },
  { code: '1716307', name: "Pau D'Arco" },
  { code: '1716505', name: 'Pedro Afonso' },
  { code: '1716604', name: 'Peixe' },
  { code: '1716653', name: 'Pequizeiro' },
  { code: '1716703', name: 'Colméia' },
  { code: '1717008', name: 'Pindorama do Tocantins' },
  { code: '1717206', name: 'Piraquê' },
  { code: '1717503', name: 'Pium' },
  { code: '1717800', name: 'Ponte Alta do Bom Jesus' },
  { code: '1717909', name: 'Ponte Alta do Tocantins' },
  { code: '1718006', name: 'Porto Alegre do Tocantins' },
  { code: '1718204', name: 'Porto Nacional' },
  { code: '1718303', name: 'Praia Norte' },
  { code: '1718402', name: 'Presidente Kennedy' },
  { code: '1718451', name: 'Pugmil' },
  { code: '1718501', name: 'Recursolândia' },
  { code: '1718550', name: 'Riachinho' },
  { code: '1718659', name: 'Rio da Conceição' },
  { code: '1718709', name: 'Rio dos Bois' },
  { code: '1718758', name: 'Rio Sono' },
  { code: '1718808', name: 'Sampaio' },
  { code: '1718840', name: 'Sandolândia' },
  { code: '1718865', name: 'Santa Fé do Araguaia' },
  { code: '1718881', name: 'Santa Maria do Tocantins' },
  { code: '1718899', name: 'Santa Rita do Tocantins' },
  { code: '1718907', name: 'Santa Rosa do Tocantins' },
  { code: '1719004', name: 'Santa Tereza do Tocantins' },
  { code: '1720002', name: 'Santa Terezinha do Tocantins' },
  { code: '1720101', name: 'São Bento do Tocantins' },
  { code: '1720150', name: 'São Félix do Tocantins' },
  { code: '1720200', name: 'São Miguel do Tocantins' },
  { code: '1720259', name: 'São Salvador do Tocantins' },
  { code: '1720309', name: 'São Sebastião do Tocantins' },
  { code: '1720499', name: 'São Valério da Natividade' },
  { code: '1720655', name: 'Silvanópolis' },
  { code: '1720804', name: 'Sítio Novo do Tocantins' },
  { code: '1720853', name: 'Sucupira' },
  { code: '1720903', name: 'Taguatinga' },
  { code: '1720937', name: 'Taipas do Tocantins' },
  { code: '1720978', name: 'Talismã' },
  { code: '1721000', name: 'Palmas' },
  { code: '1721109', name: 'Tocantínia' },
  { code: '1721208', name: 'Tocantinópolis' },
  { code: '1721257', name: 'Tupirama' },
  { code: '1721307', name: 'Tupiratins' },
  { code: '1722081', name: 'Wanderlândia' },
  { code: '1722107', name: 'Xambioá' },
  { code: '2100055', name: 'Açailândia' },
  { code: '2100105', name: 'Afonso Cunha' },
  { code: '2100154', name: 'Água Doce do Maranhão' },
  { code: '2100204', name: 'Alcântara' },
  { code: '2100303', name: 'Aldeias Altas' },
  { code: '2100402', name: 'Altamira do Maranhão' },
  { code: '2100436', name: 'Alto Alegre do Maranhão' },
  { code: '2100477', name: 'Alto Alegre do Pindaré' },
  { code: '2100501', name: 'Alto Parnaíba' },
  { code: '2100550', name: 'Amapá do Maranhão' },
  { code: '2100600', name: 'Amarante do Maranhão' },
  { code: '2100709', name: 'Anajatuba' },
  { code: '2100808', name: 'Anapurus' },
  { code: '2100832', name: 'Apicum-Açu' },
  { code: '2100873', name: 'Araguanã' },
  { code: '2100907', name: 'Araioses' },
  { code: '2100956', name: 'Arame' },
  { code: '2101004', name: 'Arari' },
  { code: '2101103', name: 'Axixá' },
  { code: '2101202', name: 'Bacabal' },
  { code: '2101251', name: 'Bacabeira' },
  { code: '2101301', name: 'Bacuri' },
  { code: '2101350', name: 'Bacurituba' },
  { code: '2101400', name: 'Balsas' },
  { code: '2101509', name: 'Barão de Grajaú' },
  { code: '2101608', name: 'Barra do Corda' },
  { code: '2101707', name: 'Barreirinhas' },
  { code: '2101731', name: 'Belágua' },
  { code: '2101772', name: 'Bela Vista do Maranhão' },
  { code: '2101806', name: 'Benedito Leite' },
  { code: '2101905', name: 'Bequimão' },
  { code: '2101939', name: 'Bernardo do Mearim' },
  { code: '2101970', name: 'Boa Vista do Gurupi' },
  { code: '2102002', name: 'Bom Jardim' },
  { code: '2102036', name: 'Bom Jesus das Selvas' },
  { code: '2102077', name: 'Bom Lugar' },
  { code: '2102101', name: 'Brejo' },
  { code: '2102150', name: 'Brejo de Areia' },
  { code: '2102200', name: 'Buriti' },
  { code: '2102309', name: 'Buriti Bravo' },
  { code: '2102325', name: 'Buriticupu' },
  { code: '2102358', name: 'Buritirana' },
  { code: '2102374', name: 'Cachoeira Grande' },
  { code: '2102408', name: 'Cajapió' },
  { code: '2102507', name: 'Cajari' },
  { code: '2102556', name: 'Campestre do Maranhão' },
  { code: '2102606', name: 'Cândido Mendes' },
  { code: '2102705', name: 'Cantanhede' },
  { code: '2102754', name: 'Capinzal do Norte' },
  { code: '2102804', name: 'Carolina' },
  { code: '2102903', name: 'Carutapera' },
  { code: '2103000', name: 'Caxias' },
  { code: '2103109', name: 'Cedral' },
  { code: '2103125', name: 'Central do Maranhão' },
  { code: '2103158', name: 'Centro do Guilherme' },
  { code: '2103174', name: 'Centro Novo do Maranhão' },
  { code: '2103208', name: 'Chapadinha' },
  { code: '2103257', name: 'Cidelândia' },
  { code: '2103307', name: 'Codó' },
  { code: '2103406', name: 'Coelho Neto' },
  { code: '2103505', name: 'Colinas' },
  { code: '2103554', name: 'Conceição do Lago-Açu' },
  { code: '2103604', name: 'Coroatá' },
  { code: '2103703', name: 'Cururupu' },
  { code: '2103752', name: 'Davinópolis' },
  { code: '2103802', name: 'Dom Pedro' },
  { code: '2103901', name: 'Duque Bacelar' },
  { code: '2104008', name: 'Esperantinópolis' },
  { code: '2104057', name: 'Estreito' },
  { code: '2104073', name: 'Feira Nova do Maranhão' },
  { code: '2104081', name: 'Fernando Falcão' },
  { code: '2104099', name: 'Formosa da Serra Negra' },
  { code: '2104107', name: 'Fortaleza dos Nogueiras' },
  { code: '2104206', name: 'Fortuna' },
  { code: '2104305', name: 'Godofredo Viana' },
  { code: '2104404', name: 'Gonçalves Dias' },
  { code: '2104503', name: 'Governador Archer' },
  { code: '2104552', name: 'Governador Edison Lobão' },
  { code: '2104602', name: 'Governador Eugênio Barros' },
  { code: '2104628', name: 'Governador Luiz Rocha' },
  { code: '2104651', name: 'Governador Newton Bello' },
  { code: '2104677', name: 'Governador Nunes Freire' },
  { code: '2104701', name: 'Graça Aranha' },
  { code: '2104800', name: 'Grajaú' },
  { code: '2104909', name: 'Guimarães' },
  { code: '2105005', name: 'Humberto de Campos' },
  { code: '2105104', name: 'Icatu' },
  { code: '2105153', name: 'Igarapé do Meio' },
  { code: '2105203', name: 'Igarapé Grande' },
  { code: '2105302', name: 'Imperatriz' },
  { code: '2105351', name: 'Itaipava do Grajaú' },
  { code: '2105401', name: 'Itapecuru Mirim' },
  { code: '2105427', name: 'Itinga do Maranhão' },
  { code: '2105450', name: 'Jatobá' },
  { code: '2105476', name: 'Jenipapo dos Vieiras' },
  { code: '2105500', name: 'João Lisboa' },
  { code: '2105609', name: 'Joselândia' },
  { code: '2105658', name: 'Junco do Maranhão' },
  { code: '2105708', name: 'Lago da Pedra' },
  { code: '2105807', name: 'Lago do Junco' },
  { code: '2105906', name: 'Lago Verde' },
  { code: '2105922', name: 'Lagoa do Mato' },
  { code: '2105948', name: 'Lago dos Rodrigues' },
  { code: '2105963', name: 'Lagoa Grande do Maranhão' },
  { code: '2105989', name: 'Lajeado Novo' },
  { code: '2106003', name: 'Lima Campos' },
  { code: '2106102', name: 'Loreto' },
  { code: '2106201', name: 'Luís Domingues' },
  { code: '2106300', name: 'Magalhães de Almeida' },
  { code: '2106326', name: 'Maracaçumé' },
  { code: '2106359', name: 'Marajá do Sena' },
  { code: '2106375', name: 'Maranhãozinho' },
  { code: '2106409', name: 'Mata Roma' },
  { code: '2106508', name: 'Matinha' },
  { code: '2106607', name: 'Matões' },
  { code: '2106631', name: 'Matões do Norte' },
  { code: '2106672', name: 'Milagres do Maranhão' },
  { code: '2106706', name: 'Mirador' },
  { code: '2106755', name: 'Miranda do Norte' },
  { code: '2106805', name: 'Mirinzal' },
  { code: '2106904', name: 'Monção' },
  { code: '2107001', name: 'Montes Altos' },
  { code: '2107100', name: 'Morros' },
  { code: '2107209', name: 'Nina Rodrigues' },
  { code: '2107258', name: 'Nova Colinas' },
  { code: '2107308', name: 'Nova Iorque' },
  { code: '2107357', name: 'Nova Olinda do Maranhão' },
  { code: '2107407', name: "Olho d'Água das Cunhãs" },
  { code: '2107456', name: 'Olinda Nova do Maranhão' },
  { code: '2107506', name: 'Paço do Lumiar' },
  { code: '2107605', name: 'Palmeirândia' },
  { code: '2107704', name: 'Paraibano' },
  { code: '2107803', name: 'Parnarama' },
  { code: '2107902', name: 'Passagem Franca' },
  { code: '2108009', name: 'Pastos Bons' },
  { code: '2108058', name: 'Paulino Neves' },
  { code: '2108108', name: 'Paulo Ramos' },
  { code: '2108207', name: 'Pedreiras' },
  { code: '2108256', name: 'Pedro do Rosário' },
  { code: '2108306', name: 'Penalva' },
  { code: '2108405', name: 'Peri Mirim' },
  { code: '2108454', name: 'Peritoró' },
  { code: '2108504', name: 'Pindaré-Mirim' },
  { code: '2108603', name: 'Pinheiro' },
  { code: '2108702', name: 'Pio XII' },
  { code: '2108801', name: 'Pirapemas' },
  { code: '2108900', name: 'Poção de Pedras' },
  { code: '2109007', name: 'Porto Franco' },
  { code: '2109056', name: 'Porto Rico do Maranhão' },
  { code: '2109106', name: 'Presidente Dutra' },
  { code: '2109205', name: 'Presidente Juscelino' },
  { code: '2109239', name: 'Presidente Médici' },
  { code: '2109270', name: 'Presidente Sarney' },
  { code: '2109304', name: 'Presidente Vargas' },
  { code: '2109403', name: 'Primeira Cruz' },
  { code: '2109452', name: 'Raposa' },
  { code: '2109502', name: 'Riachão' },
  { code: '2109551', name: 'Ribamar Fiquene' },
  { code: '2109601', name: 'Rosário' },
  { code: '2109700', name: 'Sambaíba' },
  { code: '2109759', name: 'Santa Filomena do Maranhão' },
  { code: '2109809', name: 'Santa Helena' },
  { code: '2109908', name: 'Santa Inês' },
  { code: '2110005', name: 'Santa Luzia' },
  { code: '2110039', name: 'Santa Luzia do Paruá' },
  { code: '2110104', name: 'Santa Quitéria do Maranhão' },
  { code: '2110203', name: 'Santa Rita' },
  { code: '2110237', name: 'Santana do Maranhão' },
  { code: '2110278', name: 'Santo Amaro do Maranhão' },
  { code: '2110302', name: 'Santo Antônio dos Lopes' },
  { code: '2110401', name: 'São Benedito do Rio Preto' },
  { code: '2110500', name: 'São Bento' },
  { code: '2110609', name: 'São Bernardo' },
  { code: '2110658', name: 'São Domingos do Azeitão' },
  { code: '2110708', name: 'São Domingos do Maranhão' },
  { code: '2110807', name: 'São Félix de Balsas' },
  { code: '2110856', name: 'São Francisco do Brejão' },
  { code: '2110906', name: 'São Francisco do Maranhão' },
  { code: '2111003', name: 'São João Batista' },
  { code: '2111029', name: 'São João do Carú' },
  { code: '2111052', name: 'São João do Paraíso' },
  { code: '2111078', name: 'São João do Soter' },
  { code: '2111102', name: 'São João dos Patos' },
  { code: '2111201', name: 'São José de Ribamar' },
  { code: '2111250', name: 'São José dos Basílios' },
  { code: '2111300', name: 'São Luís' },
  { code: '2111409', name: 'São Luís Gonzaga do Maranhão' },
  { code: '2111508', name: 'São Mateus do Maranhão' },
  { code: '2111532', name: 'São Pedro da Água Branca' },
  { code: '2111573', name: 'São Pedro dos Crentes' },
  { code: '2111607', name: 'São Raimundo das Mangabeiras' },
  { code: '2111631', name: 'São Raimundo do Doca Bezerra' },
  { code: '2111672', name: 'São Roberto' },
  { code: '2111706', name: 'São Vicente Ferrer' },
  { code: '2111722', name: 'Satubinha' },
  { code: '2111748', name: 'Senador Alexandre Costa' },
  { code: '2111763', name: 'Senador La Rocque' },
  { code: '2111789', name: 'Serrano do Maranhão' },
  { code: '2111805', name: 'Sítio Novo' },
  { code: '2111904', name: 'Sucupira do Norte' },
  { code: '2111953', name: 'Sucupira do Riachão' },
  { code: '2112001', name: 'Tasso Fragoso' },
  { code: '2112100', name: 'Timbiras' },
  { code: '2112209', name: 'Timon' },
  { code: '2112233', name: 'Trizidela do Vale' },
  { code: '2112274', name: 'Tufilândia' },
  { code: '2112308', name: 'Tuntum' },
  { code: '2112407', name: 'Turiaçu' },
  { code: '2112456', name: 'Turilândia' },
  { code: '2112506', name: 'Tutóia' },
  { code: '2112605', name: 'Urbano Santos' },
  { code: '2112704', name: 'Vargem Grande' },
  { code: '2112803', name: 'Viana' },
  { code: '2112852', name: 'Vila Nova dos Martírios' },
  { code: '2112902', name: 'Vitória do Mearim' },
  { code: '2113009', name: 'Vitorino Freire' },
  { code: '2114007', name: 'Zé Doca' },
  { code: '2200053', name: 'Acauã' },
  { code: '2200103', name: 'Agricolândia' },
  { code: '2200202', name: 'Água Branca' },
  { code: '2200251', name: 'Alagoinha do Piauí' },
  { code: '2200277', name: 'Alegrete do Piauí' },
  { code: '2200301', name: 'Alto Longá' },
  { code: '2200400', name: 'Altos' },
  { code: '2200459', name: 'Alvorada do Gurguéia' },
  { code: '2200509', name: 'Amarante' },
  { code: '2200608', name: 'Angical do Piauí' },
  { code: '2200707', name: 'Anísio de Abreu' },
  { code: '2200806', name: 'Antônio Almeida' },
  { code: '2200905', name: 'Aroazes' },
  { code: '2200954', name: 'Aroeiras do Itaim' },
  { code: '2201002', name: 'Arraial' },
  { code: '2201051', name: 'Assunção do Piauí' },
  { code: '2201101', name: 'Avelino Lopes' },
  { code: '2201150', name: 'Baixa Grande do Ribeiro' },
  { code: '2201176', name: "Barra D'Alcântara" },
  { code: '2201200', name: 'Barras' },
  { code: '2201309', name: 'Barreiras do Piauí' },
  { code: '2201408', name: 'Barro Duro' },
  { code: '2201507', name: 'Batalha' },
  { code: '2201556', name: 'Bela Vista do Piauí' },
  { code: '2201572', name: 'Belém do Piauí' },
  { code: '2201606', name: 'Beneditinos' },
  { code: '2201705', name: 'Bertolínia' },
  { code: '2201739', name: 'Betânia do Piauí' },
  { code: '2201770', name: 'Boa Hora' },
  { code: '2201804', name: 'Bocaina' },
  { code: '2201903', name: 'Bom Jesus' },
  { code: '2201919', name: 'Bom Princípio do Piauí' },
  { code: '2201929', name: 'Bonfim do Piauí' },
  { code: '2201945', name: 'Boqueirão do Piauí' },
  { code: '2201960', name: 'Brasileira' },
  { code: '2201988', name: 'Brejo do Piauí' },
  { code: '2202000', name: 'Buriti dos Lopes' },
  { code: '2202026', name: 'Buriti dos Montes' },
  { code: '2202059', name: 'Cabeceiras do Piauí' },
  { code: '2202075', name: 'Cajazeiras do Piauí' },
  { code: '2202083', name: 'Cajueiro da Praia' },
  { code: '2202091', name: 'Caldeirão Grande do Piauí' },
  { code: '2202109', name: 'Campinas do Piauí' },
  { code: '2202117', name: 'Campo Alegre do Fidalgo' },
  { code: '2202133', name: 'Campo Grande do Piauí' },
  { code: '2202174', name: 'Campo Largo do Piauí' },
  { code: '2202208', name: 'Campo Maior' },
  { code: '2202251', name: 'Canavieira' },
  { code: '2202307', name: 'Canto do Buriti' },
  { code: '2202406', name: 'Capitão de Campos' },
  { code: '2202455', name: 'Capitão Gervásio Oliveira' },
  { code: '2202505', name: 'Caracol' },
  { code: '2202539', name: 'Caraúbas do Piauí' },
  { code: '2202554', name: 'Caridade do Piauí' },
  { code: '2202604', name: 'Castelo do Piauí' },
  { code: '2202653', name: 'Caxingó' },
  { code: '2202703', name: 'Cocal' },
  { code: '2202711', name: 'Cocal de Telha' },
  { code: '2202729', name: 'Cocal dos Alves' },
  { code: '2202737', name: 'Coivaras' },
  { code: '2202752', name: 'Colônia do Gurguéia' },
  { code: '2202778', name: 'Colônia do Piauí' },
  { code: '2202802', name: 'Conceição do Canindé' },
  { code: '2202851', name: 'Coronel José Dias' },
  { code: '2202901', name: 'Corrente' },
  { code: '2203008', name: 'Cristalândia do Piauí' },
  { code: '2203107', name: 'Cristino Castro' },
  { code: '2203206', name: 'Curimatá' },
  { code: '2203230', name: 'Currais' },
  { code: '2203255', name: 'Curralinhos' },
  { code: '2203271', name: 'Curral Novo do Piauí' },
  { code: '2203305', name: 'Demerval Lobão' },
  { code: '2203354', name: 'Dirceu Arcoverde' },
  { code: '2203404', name: 'Dom Expedito Lopes' },
  { code: '2203420', name: 'Domingos Mourão' },
  { code: '2203453', name: 'Dom Inocêncio' },
  { code: '2203503', name: 'Elesbão Veloso' },
  { code: '2203602', name: 'Eliseu Martins' },
  { code: '2203701', name: 'Esperantina' },
  { code: '2203750', name: 'Fartura do Piauí' },
  { code: '2203800', name: 'Flores do Piauí' },
  { code: '2203859', name: 'Floresta do Piauí' },
  { code: '2203909', name: 'Floriano' },
  { code: '2204006', name: 'Francinópolis' },
  { code: '2204105', name: 'Francisco Ayres' },
  { code: '2204154', name: 'Francisco Macedo' },
  { code: '2204204', name: 'Francisco Santos' },
  { code: '2204303', name: 'Fronteiras' },
  { code: '2204352', name: 'Geminiano' },
  { code: '2204402', name: 'Gilbués' },
  { code: '2204501', name: 'Guadalupe' },
  { code: '2204550', name: 'Guaribas' },
  { code: '2204600', name: 'Hugo Napoleão' },
  { code: '2204659', name: 'Ilha Grande' },
  { code: '2204709', name: 'Inhuma' },
  { code: '2204808', name: 'Ipiranga do Piauí' },
  { code: '2204907', name: 'Isaías Coelho' },
  { code: '2205003', name: 'Itainópolis' },
  { code: '2205102', name: 'Itaueira' },
  { code: '2205151', name: 'Jacobina do Piauí' },
  { code: '2205201', name: 'Jaicós' },
  { code: '2205250', name: 'Jardim do Mulato' },
  { code: '2205276', name: 'Jatobá do Piauí' },
  { code: '2205300', name: 'Jerumenha' },
  { code: '2205359', name: 'João Costa' },
  { code: '2205409', name: 'Joaquim Pires' },
  { code: '2205458', name: 'Joca Marques' },
  { code: '2205508', name: 'José de Freitas' },
  { code: '2205516', name: 'Juazeiro do Piauí' },
  { code: '2205524', name: 'Júlio Borges' },
  { code: '2205532', name: 'Jurema' },
  { code: '2205540', name: 'Lagoinha do Piauí' },
  { code: '2205557', name: 'Lagoa Alegre' },
  { code: '2205565', name: 'Lagoa do Barro do Piauí' },
  { code: '2205573', name: 'Lagoa de São Francisco' },
  { code: '2205581', name: 'Lagoa do Piauí' },
  { code: '2205599', name: 'Lagoa do Sítio' },
  { code: '2205607', name: 'Landri Sales' },
  { code: '2205706', name: 'Luís Correia' },
  { code: '2205805', name: 'Luzilândia' },
  { code: '2205854', name: 'Madeiro' },
  { code: '2205904', name: 'Manoel Emídio' },
  { code: '2205953', name: 'Marcolândia' },
  { code: '2206001', name: 'Marcos Parente' },
  { code: '2206050', name: 'Massapê do Piauí' },
  { code: '2206100', name: 'Matias Olímpio' },
  { code: '2206209', name: 'Miguel Alves' },
  { code: '2206308', name: 'Miguel Leão' },
  { code: '2206357', name: 'Milton Brandão' },
  { code: '2206407', name: 'Monsenhor Gil' },
  { code: '2206506', name: 'Monsenhor Hipólito' },
  { code: '2206605', name: 'Monte Alegre do Piauí' },
  { code: '2206654', name: 'Morro Cabeça no Tempo' },
  { code: '2206670', name: 'Morro do Chapéu do Piauí' },
  { code: '2206696', name: 'Murici dos Portelas' },
  { code: '2206704', name: 'Nazaré do Piauí' },
  { code: '2206720', name: 'Nazária' },
  { code: '2206753', name: 'Nossa Senhora de Nazaré' },
  { code: '2206803', name: 'Nossa Senhora dos Remédios' },
  { code: '2206902', name: 'Novo Oriente do Piauí' },
  { code: '2206951', name: 'Novo Santo Antônio' },
  { code: '2207009', name: 'Oeiras' },
  { code: '2207108', name: "Olho D'Água do Piauí" },
  { code: '2207207', name: 'Padre Marcos' },
  { code: '2207306', name: 'Paes Landim' },
  { code: '2207355', name: 'Pajeú do Piauí' },
  { code: '2207405', name: 'Palmeira do Piauí' },
  { code: '2207504', name: 'Palmeirais' },
  { code: '2207553', name: 'Paquetá' },
  { code: '2207603', name: 'Parnaguá' },
  { code: '2207702', name: 'Parnaíba' },
  { code: '2207751', name: 'Passagem Franca do Piauí' },
  { code: '2207777', name: 'Patos do Piauí' },
  { code: '2207793', name: "Pau D'Arco do Piauí" },
  { code: '2207801', name: 'Paulistana' },
  { code: '2207850', name: 'Pavussu' },
  { code: '2207900', name: 'Pedro II' },
  { code: '2207934', name: 'Pedro Laurentino' },
  { code: '2207959', name: 'Nova Santa Rita' },
  { code: '2208007', name: 'Picos' },
  { code: '2208106', name: 'Pimenteiras' },
  { code: '2208205', name: 'Pio IX' },
  { code: '2208304', name: 'Piracuruca' },
  { code: '2208403', name: 'Piripiri' },
  { code: '2208502', name: 'Porto' },
  { code: '2208551', name: 'Porto Alegre do Piauí' },
  { code: '2208601', name: 'Prata do Piauí' },
  { code: '2208650', name: 'Queimada Nova' },
  { code: '2208700', name: 'Redenção do Gurguéia' },
  { code: '2208809', name: 'Regeneração' },
  { code: '2208858', name: 'Riacho Frio' },
  { code: '2208874', name: 'Ribeira do Piauí' },
  { code: '2208908', name: 'Ribeiro Gonçalves' },
  { code: '2209005', name: 'Rio Grande do Piauí' },
  { code: '2209104', name: 'Santa Cruz do Piauí' },
  { code: '2209153', name: 'Santa Cruz dos Milagres' },
  { code: '2209203', name: 'Santa Filomena' },
  { code: '2209302', name: 'Santa Luz' },
  { code: '2209351', name: 'Santana do Piauí' },
  { code: '2209377', name: 'Santa Rosa do Piauí' },
  { code: '2209401', name: 'Santo Antônio de Lisboa' },
  { code: '2209450', name: 'Santo Antônio dos Milagres' },
  { code: '2209500', name: 'Santo Inácio do Piauí' },
  { code: '2209559', name: 'São Braz do Piauí' },
  { code: '2209609', name: 'São Félix do Piauí' },
  { code: '2209658', name: 'São Francisco de Assis do Piauí' },
  { code: '2209708', name: 'São Francisco do Piauí' },
  { code: '2209757', name: 'São Gonçalo do Gurguéia' },
  { code: '2209807', name: 'São Gonçalo do Piauí' },
  { code: '2209856', name: 'São João da Canabrava' },
  { code: '2209872', name: 'São João da Fronteira' },
  { code: '2209906', name: 'São João da Serra' },
  { code: '2209955', name: 'São João da Varjota' },
  { code: '2209971', name: 'São João do Arraial' },
  { code: '2210003', name: 'São João do Piauí' },
  { code: '2210052', name: 'São José do Divino' },
  { code: '2210102', name: 'São José do Peixe' },
  { code: '2210201', name: 'São José do Piauí' },
  { code: '2210300', name: 'São Julião' },
  { code: '2210359', name: 'São Lourenço do Piauí' },
  { code: '2210375', name: 'São Luis do Piauí' },
  { code: '2210383', name: 'São Miguel da Baixa Grande' },
  { code: '2210391', name: 'São Miguel do Fidalgo' },
  { code: '2210409', name: 'São Miguel do Tapuio' },
  { code: '2210508', name: 'São Pedro do Piauí' },
  { code: '2210607', name: 'São Raimundo Nonato' },
  { code: '2210623', name: 'Sebastião Barros' },
  { code: '2210631', name: 'Sebastião Leal' },
  { code: '2210656', name: 'Sigefredo Pacheco' },
  { code: '2210706', name: 'Simões' },
  { code: '2210805', name: 'Simplício Mendes' },
  { code: '2210904', name: 'Socorro do Piauí' },
  { code: '2210938', name: 'Sussuapara' },
  { code: '2210953', name: 'Tamboril do Piauí' },
  { code: '2210979', name: 'Tanque do Piauí' },
  { code: '2211001', name: 'Teresina' },
  { code: '2211100', name: 'União' },
  { code: '2211209', name: 'Uruçuí' },
  { code: '2211308', name: 'Valença do Piauí' },
  { code: '2211357', name: 'Várzea Branca' },
  { code: '2211407', name: 'Várzea Grande' },
  { code: '2211506', name: 'Vera Mendes' },
  { code: '2211605', name: 'Vila Nova do Piauí' },
  { code: '2211704', name: 'Wall Ferraz' },
  { code: '2300101', name: 'Abaiara' },
  { code: '2300150', name: 'Acarape' },
  { code: '2300200', name: 'Acaraú' },
  { code: '2300309', name: 'Acopiara' },
  { code: '2300408', name: 'Aiuaba' },
  { code: '2300507', name: 'Alcântaras' },
  { code: '2300606', name: 'Altaneira' },
  { code: '2300705', name: 'Alto Santo' },
  { code: '2300754', name: 'Amontada' },
  { code: '2300804', name: 'Antonina do Norte' },
  { code: '2300903', name: 'Apuiarés' },
  { code: '2301000', name: 'Aquiraz' },
  { code: '2301109', name: 'Aracati' },
  { code: '2301208', name: 'Aracoiaba' },
  { code: '2301257', name: 'Ararendá' },
  { code: '2301307', name: 'Araripe' },
  { code: '2301406', name: 'Aratuba' },
  { code: '2301505', name: 'Arneiroz' },
  { code: '2301604', name: 'Assaré' },
  { code: '2301703', name: 'Aurora' },
  { code: '2301802', name: 'Baixio' },
  { code: '2301851', name: 'Banabuiú' },
  { code: '2301901', name: 'Barbalha' },
  { code: '2301950', name: 'Barreira' },
  { code: '2302008', name: 'Barro' },
  { code: '2302057', name: 'Barroquinha' },
  { code: '2302107', name: 'Baturité' },
  { code: '2302206', name: 'Beberibe' },
  { code: '2302305', name: 'Bela Cruz' },
  { code: '2302404', name: 'Boa Viagem' },
  { code: '2302503', name: 'Brejo Santo' },
  { code: '2302602', name: 'Camocim' },
  { code: '2302701', name: 'Campos Sales' },
  { code: '2302800', name: 'Canindé' },
  { code: '2302909', name: 'Capistrano' },
  { code: '2303006', name: 'Caridade' },
  { code: '2303105', name: 'Cariré' },
  { code: '2303204', name: 'Caririaçu' },
  { code: '2303303', name: 'Cariús' },
  { code: '2303402', name: 'Carnaubal' },
  { code: '2303501', name: 'Cascavel' },
  { code: '2303600', name: 'Catarina' },
  { code: '2303659', name: 'Catunda' },
  { code: '2303709', name: 'Caucaia' },
  { code: '2303808', name: 'Cedro' },
  { code: '2303907', name: 'Chaval' },
  { code: '2303931', name: 'Choró' },
  { code: '2303956', name: 'Chorozinho' },
  { code: '2304004', name: 'Coreaú' },
  { code: '2304103', name: 'Crateús' },
  { code: '2304202', name: 'Crato' },
  { code: '2304236', name: 'Croatá' },
  { code: '2304251', name: 'Cruz' },
  { code: '2304269', name: 'Deputado Irapuan Pinheiro' },
  { code: '2304277', name: 'Ererê' },
  { code: '2304285', name: 'Eusébio' },
  { code: '2304301', name: 'Farias Brito' },
  { code: '2304350', name: 'Forquilha' },
  { code: '2304400', name: 'Fortaleza' },
  { code: '2304459', name: 'Fortim' },
  { code: '2304509', name: 'Frecheirinha' },
  { code: '2304608', name: 'General Sampaio' },
  { code: '2304657', name: 'Graça' },
  { code: '2304707', name: 'Granja' },
  { code: '2304806', name: 'Granjeiro' },
  { code: '2304905', name: 'Groaíras' },
  { code: '2304954', name: 'Guaiúba' },
  { code: '2305001', name: 'Guaraciaba do Norte' },
  { code: '2305100', name: 'Guaramiranga' },
  { code: '2305209', name: 'Hidrolândia' },
  { code: '2305233', name: 'Horizonte' },
  { code: '2305266', name: 'Ibaretama' },
  { code: '2305308', name: 'Ibiapina' },
  { code: '2305332', name: 'Ibicuitinga' },
  { code: '2305357', name: 'Icapuí' },
  { code: '2305407', name: 'Icó' },
  { code: '2305506', name: 'Iguatu' },
  { code: '2305605', name: 'Independência' },
  { code: '2305654', name: 'Ipaporanga' },
  { code: '2305704', name: 'Ipaumirim' },
  { code: '2305803', name: 'Ipu' },
  { code: '2305902', name: 'Ipueiras' },
  { code: '2306009', name: 'Iracema' },
  { code: '2306108', name: 'Irauçuba' },
  { code: '2306207', name: 'Itaiçaba' },
  { code: '2306256', name: 'Itaitinga' },
  { code: '2306306', name: 'Itapagé' },
  { code: '2306405', name: 'Itapipoca' },
  { code: '2306504', name: 'Itapiúna' },
  { code: '2306553', name: 'Itarema' },
  { code: '2306603', name: 'Itatira' },
  { code: '2306702', name: 'Jaguaretama' },
  { code: '2306801', name: 'Jaguaribara' },
  { code: '2306900', name: 'Jaguaribe' },
  { code: '2307007', name: 'Jaguaruana' },
  { code: '2307106', name: 'Jardim' },
  { code: '2307205', name: 'Jati' },
  { code: '2307254', name: 'Jijoca de Jericoacoara' },
  { code: '2307304', name: 'Juazeiro do Norte' },
  { code: '2307403', name: 'Jucás' },
  { code: '2307502', name: 'Lavras da Mangabeira' },
  { code: '2307601', name: 'Limoeiro do Norte' },
  { code: '2307635', name: 'Madalena' },
  { code: '2307650', name: 'Maracanaú' },
  { code: '2307700', name: 'Maranguape' },
  { code: '2307809', name: 'Marco' },
  { code: '2307908', name: 'Martinópole' },
  { code: '2308005', name: 'Massapê' },
  { code: '2308104', name: 'Mauriti' },
  { code: '2308203', name: 'Meruoca' },
  { code: '2308302', name: 'Milagres' },
  { code: '2308351', name: 'Milhã' },
  { code: '2308377', name: 'Miraíma' },
  { code: '2308401', name: 'Missão Velha' },
  { code: '2308500', name: 'Mombaça' },
  { code: '2308609', name: 'Monsenhor Tabosa' },
  { code: '2308708', name: 'Morada Nova' },
  { code: '2308807', name: 'Moraújo' },
  { code: '2308906', name: 'Morrinhos' },
  { code: '2309003', name: 'Mucambo' },
  { code: '2309102', name: 'Mulungu' },
  { code: '2309201', name: 'Nova Olinda' },
  { code: '2309300', name: 'Nova Russas' },
  { code: '2309409', name: 'Novo Oriente' },
  { code: '2309458', name: 'Ocara' },
  { code: '2309508', name: 'Orós' },
  { code: '2309607', name: 'Pacajus' },
  { code: '2309706', name: 'Pacatuba' },
  { code: '2309805', name: 'Pacoti' },
  { code: '2309904', name: 'Pacujá' },
  { code: '2310001', name: 'Palhano' },
  { code: '2310100', name: 'Palmácia' },
  { code: '2310209', name: 'Paracuru' },
  { code: '2310258', name: 'Paraipaba' },
  { code: '2310308', name: 'Parambu' },
  { code: '2310407', name: 'Paramoti' },
  { code: '2310506', name: 'Pedra Branca' },
  { code: '2310605', name: 'Penaforte' },
  { code: '2310704', name: 'Pentecoste' },
  { code: '2310803', name: 'Pereiro' },
  { code: '2310852', name: 'Pindoretama' },
  { code: '2310902', name: 'Piquet Carneiro' },
  { code: '2310951', name: 'Pires Ferreira' },
  { code: '2311009', name: 'Poranga' },
  { code: '2311108', name: 'Porteiras' },
  { code: '2311207', name: 'Potengi' },
  { code: '2311231', name: 'Potiretama' },
  { code: '2311264', name: 'Quiterianópolis' },
  { code: '2311306', name: 'Quixadá' },
  { code: '2311355', name: 'Quixelô' },
  { code: '2311405', name: 'Quixeramobim' },
  { code: '2311504', name: 'Quixeré' },
  { code: '2311603', name: 'Redenção' },
  { code: '2311702', name: 'Reriutaba' },
  { code: '2311801', name: 'Russas' },
  { code: '2311900', name: 'Saboeiro' },
  { code: '2311959', name: 'Salitre' },
  { code: '2312007', name: 'Santana do Acaraú' },
  { code: '2312106', name: 'Santana do Cariri' },
  { code: '2312205', name: 'Santa Quitéria' },
  { code: '2312304', name: 'São Benedito' },
  { code: '2312403', name: 'São Gonçalo do Amarante' },
  { code: '2312502', name: 'São João do Jaguaribe' },
  { code: '2312601', name: 'São Luís do Curu' },
  { code: '2312700', name: 'Senador Pompeu' },
  { code: '2312809', name: 'Senador Sá' },
  { code: '2312908', name: 'Sobral' },
  { code: '2313005', name: 'Solonópole' },
  { code: '2313104', name: 'Tabuleiro do Norte' },
  { code: '2313203', name: 'Tamboril' },
  { code: '2313252', name: 'Tarrafas' },
  { code: '2313302', name: 'Tauá' },
  { code: '2313351', name: 'Tejuçuoca' },
  { code: '2313401', name: 'Tianguá' },
  { code: '2313500', name: 'Trairi' },
  { code: '2313559', name: 'Tururu' },
  { code: '2313609', name: 'Ubajara' },
  { code: '2313708', name: 'Umari' },
  { code: '2313757', name: 'Umirim' },
  { code: '2313807', name: 'Uruburetama' },
  { code: '2313906', name: 'Uruoca' },
  { code: '2313955', name: 'Varjota' },
  { code: '2314003', name: 'Várzea Alegre' },
  { code: '2314102', name: 'Viçosa do Ceará' },
  { code: '2400109', name: 'Acari' },
  { code: '2400208', name: 'Açu' },
  { code: '2400307', name: 'Afonso Bezerra' },
  { code: '2400406', name: 'Água Nova' },
  { code: '2400505', name: 'Alexandria' },
  { code: '2400604', name: 'Almino Afonso' },
  { code: '2400703', name: 'Alto do Rodrigues' },
  { code: '2400802', name: 'Angicos' },
  { code: '2400901', name: 'Antônio Martins' },
  { code: '2401008', name: 'Apodi' },
  { code: '2401107', name: 'Areia Branca' },
  { code: '2401206', name: 'Arês' },
  { code: '2401305', name: 'Augusto Severo' },
  { code: '2401404', name: 'Baía Formosa' },
  { code: '2401453', name: 'Baraúna' },
  { code: '2401503', name: 'Barcelona' },
  { code: '2401602', name: 'Bento Fernandes' },
  { code: '2401651', name: 'Bodó' },
  { code: '2401701', name: 'Bom Jesus' },
  { code: '2401800', name: 'Brejinho' },
  { code: '2401859', name: 'Caiçara do Norte' },
  { code: '2401909', name: 'Caiçara do Rio do Vento' },
  { code: '2402006', name: 'Caicó' },
  { code: '2402105', name: 'Campo Redondo' },
  { code: '2402204', name: 'Canguaretama' },
  { code: '2402303', name: 'Caraúbas' },
  { code: '2402402', name: 'Carnaúba dos Dantas' },
  { code: '2402501', name: 'Carnaubais' },
  { code: '2402600', name: 'Ceará-Mirim' },
  { code: '2402709', name: 'Cerro Corá' },
  { code: '2402808', name: 'Coronel Ezequiel' },
  { code: '2402907', name: 'Coronel João Pessoa' },
  { code: '2403004', name: 'Cruzeta' },
  { code: '2403103', name: 'Currais Novos' },
  { code: '2403202', name: 'Doutor Severiano' },
  { code: '2403251', name: 'Parnamirim' },
  { code: '2403301', name: 'Encanto' },
  { code: '2403400', name: 'Equador' },
  { code: '2403509', name: 'Espírito Santo' },
  { code: '2403608', name: 'Extremoz' },
  { code: '2403707', name: 'Felipe Guerra' },
  { code: '2403756', name: 'Fernando Pedroza' },
  { code: '2403806', name: 'Florânia' },
  { code: '2403905', name: 'Francisco Dantas' },
  { code: '2404002', name: 'Frutuoso Gomes' },
  { code: '2404101', name: 'Galinhos' },
  { code: '2404200', name: 'Goianinha' },
  { code: '2404309', name: 'Governador Dix-Sept Rosado' },
  { code: '2404408', name: 'Grossos' },
  { code: '2404507', name: 'Guamaré' },
  { code: '2404606', name: 'Ielmo Marinho' },
  { code: '2404705', name: 'Ipanguaçu' },
  { code: '2404804', name: 'Ipueira' },
  { code: '2404853', name: 'Itajá' },
  { code: '2404903', name: 'Itaú' },
  { code: '2405009', name: 'Jaçanã' },
  { code: '2405108', name: 'Jandaíra' },
  { code: '2405207', name: 'Janduís' },
  { code: '2405306', name: 'Januário Cicco' },
  { code: '2405405', name: 'Japi' },
  { code: '2405504', name: 'Jardim de Angicos' },
  { code: '2405603', name: 'Jardim de Piranhas' },
  { code: '2405702', name: 'Jardim do Seridó' },
  { code: '2405801', name: 'João Câmara' },
  { code: '2405900', name: 'João Dias' },
  { code: '2406007', name: 'José da Penha' },
  { code: '2406106', name: 'Jucurutu' },
  { code: '2406155', name: 'Jundiá' },
  { code: '2406205', name: "Lagoa d'Anta" },
  { code: '2406304', name: 'Lagoa de Pedras' },
  { code: '2406403', name: 'Lagoa de Velhos' },
  { code: '2406502', name: 'Lagoa Nova' },
  { code: '2406601', name: 'Lagoa Salgada' },
  { code: '2406700', name: 'Lajes' },
  { code: '2406809', name: 'Lajes Pintadas' },
  { code: '2406908', name: 'Lucrécia' },
  { code: '2407005', name: 'Luís Gomes' },
  { code: '2407104', name: 'Macaíba' },
  { code: '2407203', name: 'Macau' },
  { code: '2407252', name: 'Major Sales' },
  { code: '2407302', name: 'Marcelino Vieira' },
  { code: '2407401', name: 'Martins' },
  { code: '2407500', name: 'Maxaranguape' },
  { code: '2407609', name: 'Messias Targino' },
  { code: '2407708', name: 'Montanhas' },
  { code: '2407807', name: 'Monte Alegre' },
  { code: '2407906', name: 'Monte das Gameleiras' },
  { code: '2408003', name: 'Mossoró' },
  { code: '2408102', name: 'Natal' },
  { code: '2408201', name: 'Nísia Floresta' },
  { code: '2408300', name: 'Nova Cruz' },
  { code: '2408409', name: "Olho-d'Água do Borges" },
  { code: '2408508', name: 'Ouro Branco' },
  { code: '2408607', name: 'Paraná' },
  { code: '2408706', name: 'Paraú' },
  { code: '2408805', name: 'Parazinho' },
  { code: '2408904', name: 'Parelhas' },
  { code: '2408953', name: 'Rio do Fogo' },
  { code: '2409100', name: 'Passa e Fica' },
  { code: '2409209', name: 'Passagem' },
  { code: '2409308', name: 'Patu' },
  { code: '2409332', name: 'Santa Maria' },
  { code: '2409407', name: 'Pau dos Ferros' },
  { code: '2409506', name: 'Pedra Grande' },
  { code: '2409605', name: 'Pedra Preta' },
  { code: '2409704', name: 'Pedro Avelino' },
  { code: '2409803', name: 'Pedro Velho' },
  { code: '2409902', name: 'Pendências' },
  { code: '2410009', name: 'Pilões' },
  { code: '2410108', name: 'Poço Branco' },
  { code: '2410207', name: 'Portalegre' },
  { code: '2410256', name: 'Porto do Mangue' },
  { code: '2410306', name: 'Presidente Juscelino' },
  { code: '2410405', name: 'Pureza' },
  { code: '2410504', name: 'Rafael Fernandes' },
  { code: '2410603', name: 'Rafael Godeiro' },
  { code: '2410702', name: 'Riacho da Cruz' },
  { code: '2410801', name: 'Riacho de Santana' },
  { code: '2410900', name: 'Riachuelo' },
  { code: '2411007', name: 'Rodolfo Fernandes' },
  { code: '2411056', name: 'Tibau' },
  { code: '2411106', name: 'Ruy Barbosa' },
  { code: '2411205', name: 'Santa Cruz' },
  { code: '2411403', name: 'Santana do Matos' },
  { code: '2411429', name: 'Santana do Seridó' },
  { code: '2411502', name: 'Santo Antônio' },
  { code: '2411601', name: 'São Bento do Norte' },
  { code: '2411700', name: 'São Bento do Trairí' },
  { code: '2411809', name: 'São Fernando' },
  { code: '2411908', name: 'São Francisco do Oeste' },
  { code: '2412005', name: 'São Gonçalo do Amarante' },
  { code: '2412104', name: 'São João do Sabugi' },
  { code: '2412203', name: 'São José de Mipibu' },
  { code: '2412302', name: 'São José do Campestre' },
  { code: '2412401', name: 'São José do Seridó' },
  { code: '2412500', name: 'São Miguel' },
  { code: '2412559', name: 'São Miguel do Gostoso' },
  { code: '2412609', name: 'São Paulo do Potengi' },
  { code: '2412708', name: 'São Pedro' },
  { code: '2412807', name: 'São Rafael' },
  { code: '2412906', name: 'São Tomé' },
  { code: '2413003', name: 'São Vicente' },
  { code: '2413102', name: 'Senador Elói de Souza' },
  { code: '2413201', name: 'Senador Georgino Avelino' },
  { code: '2413300', name: 'Serra de São Bento' },
  { code: '2413359', name: 'Serra do Mel' },
  { code: '2413409', name: 'Serra Negra do Norte' },
  { code: '2413508', name: 'Serrinha' },
  { code: '2413557', name: 'Serrinha dos Pintos' },
  { code: '2413607', name: 'Severiano Melo' },
  { code: '2413706', name: 'Sítio Novo' },
  { code: '2413805', name: 'Taboleiro Grande' },
  { code: '2413904', name: 'Taipu' },
  { code: '2414001', name: 'Tangará' },
  { code: '2414100', name: 'Tenente Ananias' },
  { code: '2414159', name: 'Tenente Laurentino Cruz' },
  { code: '2414209', name: 'Tibau do Sul' },
  { code: '2414308', name: 'Timbaúba dos Batistas' },
  { code: '2414407', name: 'Touros' },
  { code: '2414456', name: 'Triunfo Potiguar' },
  { code: '2414506', name: 'Umarizal' },
  { code: '2414605', name: 'Upanema' },
  { code: '2414704', name: 'Várzea' },
  { code: '2414753', name: 'Venha-Ver' },
  { code: '2414803', name: 'Vera Cruz' },
  { code: '2414902', name: 'Viçosa' },
  { code: '2415008', name: 'Vila Flor' },
  { code: '2500106', name: 'Água Branca' },
  { code: '2500205', name: 'Aguiar' },
  { code: '2500304', name: 'Alagoa Grande' },
  { code: '2500403', name: 'Alagoa Nova' },
  { code: '2500502', name: 'Alagoinha' },
  { code: '2500536', name: 'Alcantil' },
  { code: '2500577', name: 'Algodão de Jandaíra' },
  { code: '2500601', name: 'Alhandra' },
  { code: '2500700', name: 'São João do Rio do Peixe' },
  { code: '2500734', name: 'Amparo' },
  { code: '2500775', name: 'Aparecida' },
  { code: '2500809', name: 'Araçagi' },
  { code: '2500908', name: 'Arara' },
  { code: '2501005', name: 'Araruna' },
  { code: '2501104', name: 'Areia' },
  { code: '2501153', name: 'Areia de Baraúnas' },
  { code: '2501203', name: 'Areial' },
  { code: '2501302', name: 'Aroeiras' },
  { code: '2501351', name: 'Assunção' },
  { code: '2501401', name: 'Baía da Traição' },
  { code: '2501500', name: 'Bananeiras' },
  { code: '2501534', name: 'Baraúna' },
  { code: '2501575', name: 'Barra de Santana' },
  { code: '2501609', name: 'Barra de Santa Rosa' },
  { code: '2501708', name: 'Barra de São Miguel' },
  { code: '2501807', name: 'Bayeux' },
  { code: '2501906', name: 'Belém' },
  { code: '2502003', name: 'Belém do Brejo do Cruz' },
  { code: '2502052', name: 'Bernardino Batista' },
  { code: '2502102', name: 'Boa Ventura' },
  { code: '2502151', name: 'Boa Vista' },
  { code: '2502201', name: 'Bom Jesus' },
  { code: '2502300', name: 'Bom Sucesso' },
  { code: '2502409', name: 'Bonito de Santa Fé' },
  { code: '2502508', name: 'Boqueirão' },
  { code: '2502607', name: 'Igaracy' },
  { code: '2502706', name: 'Borborema' },
  { code: '2502805', name: 'Brejo do Cruz' },
  { code: '2502904', name: 'Brejo dos Santos' },
  { code: '2503001', name: 'Caaporã' },
  { code: '2503100', name: 'Cabaceiras' },
  { code: '2503209', name: 'Cabedelo' },
  { code: '2503308', name: 'Cachoeira dos Índios' },
  { code: '2503407', name: 'Cacimba de Areia' },
  { code: '2503506', name: 'Cacimba de Dentro' },
  { code: '2503555', name: 'Cacimbas' },
  { code: '2503605', name: 'Caiçara' },
  { code: '2503704', name: 'Cajazeiras' },
  { code: '2503753', name: 'Cajazeirinhas' },
  { code: '2503803', name: 'Caldas Brandão' },
  { code: '2503902', name: 'Camalaú' },
  { code: '2504009', name: 'Campina Grande' },
  { code: '2504033', name: 'Capim' },
  { code: '2504074', name: 'Caraúbas' },
  { code: '2504108', name: 'Carrapateira' },
  { code: '2504157', name: 'Casserengue' },
  { code: '2504207', name: 'Catingueira' },
  { code: '2504306', name: 'Catolé do Rocha' },
  { code: '2504355', name: 'Caturité' },
  { code: '2504405', name: 'Conceição' },
  { code: '2504504', name: 'Condado' },
  { code: '2504603', name: 'Conde' },
  { code: '2504702', name: 'Congo' },
  { code: '2504801', name: 'Coremas' },
  { code: '2504850', name: 'Coxixola' },
  { code: '2504900', name: 'Cruz do Espírito Santo' },
  { code: '2505006', name: 'Cubati' },
  { code: '2505105', name: 'Cuité' },
  { code: '2505204', name: 'Cuitegi' },
  { code: '2505238', name: 'Cuité de Mamanguape' },
  { code: '2505279', name: 'Curral de Cima' },
  { code: '2505303', name: 'Curral Velho' },
  { code: '2505352', name: 'Damião' },
  { code: '2505402', name: 'Desterro' },
  { code: '2505501', name: 'Vista Serrana' },
  { code: '2505600', name: 'Diamante' },
  { code: '2505709', name: 'Dona Inês' },
  { code: '2505808', name: 'Duas Estradas' },
  { code: '2505907', name: 'Emas' },
  { code: '2506004', name: 'Esperança' },
  { code: '2506103', name: 'Fagundes' },
  { code: '2506202', name: 'Frei Martinho' },
  { code: '2506251', name: 'Gado Bravo' },
  { code: '2506301', name: 'Guarabira' },
  { code: '2506400', name: 'Gurinhém' },
  { code: '2506509', name: 'Gurjão' },
  { code: '2506608', name: 'Ibiara' },
  { code: '2506707', name: 'Imaculada' },
  { code: '2506806', name: 'Ingá' },
  { code: '2506905', name: 'Itabaiana' },
  { code: '2507002', name: 'Itaporanga' },
  { code: '2507101', name: 'Itapororoca' },
  { code: '2507200', name: 'Itatuba' },
  { code: '2507309', name: 'Jacaraú' },
  { code: '2507408', name: 'Jericó' },
  { code: '2507507', name: 'João Pessoa' },
  { code: '2507606', name: 'Juarez Távora' },
  { code: '2507705', name: 'Juazeirinho' },
  { code: '2507804', name: 'Junco do Seridó' },
  { code: '2507903', name: 'Juripiranga' },
  { code: '2508000', name: 'Juru' },
  { code: '2508109', name: 'Lagoa' },
  { code: '2508208', name: 'Lagoa de Dentro' },
  { code: '2508307', name: 'Lagoa Seca' },
  { code: '2508406', name: 'Lastro' },
  { code: '2508505', name: 'Livramento' },
  { code: '2508554', name: 'Logradouro' },
  { code: '2508604', name: 'Lucena' },
  { code: '2508703', name: "Mãe d'Água" },
  { code: '2508802', name: 'Malta' },
  { code: '2508901', name: 'Mamanguape' },
  { code: '2509008', name: 'Manaíra' },
  { code: '2509057', name: 'Marcação' },
  { code: '2509107', name: 'Mari' },
  { code: '2509156', name: 'Marizópolis' },
  { code: '2509206', name: 'Massaranduba' },
  { code: '2509305', name: 'Mataraca' },
  { code: '2509339', name: 'Matinhas' },
  { code: '2509370', name: 'Mato Grosso' },
  { code: '2509396', name: 'Maturéia' },
  { code: '2509404', name: 'Mogeiro' },
  { code: '2509503', name: 'Montadas' },
  { code: '2509602', name: 'Monte Horebe' },
  { code: '2509701', name: 'Monteiro' },
  { code: '2509800', name: 'Mulungu' },
  { code: '2509909', name: 'Natuba' },
  { code: '2510006', name: 'Nazarezinho' },
  { code: '2510105', name: 'Nova Floresta' },
  { code: '2510204', name: 'Nova Olinda' },
  { code: '2510303', name: 'Nova Palmeira' },
  { code: '2510402', name: "Olho d'Água" },
  { code: '2510501', name: 'Olivedos' },
  { code: '2510600', name: 'Ouro Velho' },
  { code: '2510659', name: 'Parari' },
  { code: '2510709', name: 'Passagem' },
  { code: '2510808', name: 'Patos' },
  { code: '2510907', name: 'Paulista' },
  { code: '2511004', name: 'Pedra Branca' },
  { code: '2511103', name: 'Pedra Lavrada' },
  { code: '2511202', name: 'Pedras de Fogo' },
  { code: '2511301', name: 'Piancó' },
  { code: '2511400', name: 'Picuí' },
  { code: '2511509', name: 'Pilar' },
  { code: '2511608', name: 'Pilões' },
  { code: '2511707', name: 'Pilõezinhos' },
  { code: '2511806', name: 'Pirpirituba' },
  { code: '2511905', name: 'Pitimbu' },
  { code: '2512002', name: 'Pocinhos' },
  { code: '2512036', name: 'Poço Dantas' },
  { code: '2512077', name: 'Poço de José de Moura' },
  { code: '2512101', name: 'Pombal' },
  { code: '2512200', name: 'Prata' },
  { code: '2512309', name: 'Princesa Isabel' },
  { code: '2512408', name: 'Puxinanã' },
  { code: '2512507', name: 'Queimadas' },
  { code: '2512606', name: 'Quixabá' },
  { code: '2512705', name: 'Remígio' },
  { code: '2512721', name: 'Pedro Régis' },
  { code: '2512747', name: 'Riachão' },
  { code: '2512754', name: 'Riachão do Bacamarte' },
  { code: '2512762', name: 'Riachão do Poço' },
  { code: '2512788', name: 'Riacho de Santo Antônio' },
  { code: '2512804', name: 'Riacho dos Cavalos' },
  { code: '2512903', name: 'Rio Tinto' },
  { code: '2513000', name: 'Salgadinho' },
  { code: '2513109', name: 'Salgado de São Félix' },
  { code: '2513158', name: 'Santa Cecília' },
  { code: '2513208', name: 'Santa Cruz' },
  { code: '2513307', name: 'Santa Helena' },
  { code: '2513356', name: 'Santa Inês' },
  { code: '2513406', name: 'Santa Luzia' },
  { code: '2513505', name: 'Santana de Mangueira' },
  { code: '2513604', name: 'Santana dos Garrotes' },
  { code: '2513653', name: 'Santarém' },
  { code: '2513703', name: 'Santa Rita' },
  { code: '2513802', name: 'Santa Teresinha' },
  { code: '2513851', name: 'Santo André' },
  { code: '2513901', name: 'São Bento' },
  { code: '2513927', name: 'São Bentinho' },
  { code: '2513943', name: 'São Domingos do Cariri' },
  { code: '2513968', name: 'São Domingos de Pombal' },
  { code: '2513984', name: 'São Francisco' },
  { code: '2514008', name: 'São João do Cariri' },
  { code: '2514107', name: 'São João do Tigre' },
  { code: '2514206', name: 'São José da Lagoa Tapada' },
  { code: '2514305', name: 'São José de Caiana' },
  { code: '2514404', name: 'São José de Espinharas' },
  { code: '2514453', name: 'São José dos Ramos' },
  { code: '2514503', name: 'São José de Piranhas' },
  { code: '2514552', name: 'São José de Princesa' },
  { code: '2514602', name: 'São José do Bonfim' },
  { code: '2514651', name: 'São José do Brejo do Cruz' },
  { code: '2514701', name: 'São José do Sabugi' },
  { code: '2514800', name: 'São José dos Cordeiros' },
  { code: '2514909', name: 'São Mamede' },
  { code: '2515005', name: 'São Miguel de Taipu' },
  { code: '2515104', name: 'São Sebastião de Lagoa de Roça' },
  { code: '2515203', name: 'São Sebastião do Umbuzeiro' },
  { code: '2515302', name: 'Sapé' },
  { code: '2515401', name: 'Seridó' },
  { code: '2515500', name: 'Serra Branca' },
  { code: '2515609', name: 'Serra da Raiz' },
  { code: '2515708', name: 'Serra Grande' },
  { code: '2515807', name: 'Serra Redonda' },
  { code: '2515906', name: 'Serraria' },
  { code: '2515930', name: 'Sertãozinho' },
  { code: '2515971', name: 'Sobrado' },
  { code: '2516003', name: 'Solânea' },
  { code: '2516102', name: 'Soledade' },
  { code: '2516151', name: 'Sossêgo' },
  { code: '2516201', name: 'Sousa' },
  { code: '2516300', name: 'Sumé' },
  { code: '2516409', name: 'Campo de Santana' },
  { code: '2516508', name: 'Taperoá' },
  { code: '2516607', name: 'Tavares' },
  { code: '2516706', name: 'Teixeira' },
  { code: '2516755', name: 'Tenório' },
  { code: '2516805', name: 'Triunfo' },
  { code: '2516904', name: 'Uiraúna' },
  { code: '2517001', name: 'Umbuzeiro' },
  { code: '2517100', name: 'Várzea' },
  { code: '2517209', name: 'Vieirópolis' },
  { code: '2517407', name: 'Zabelê' },
  { code: '2600054', name: 'Abreu e Lima' },
  { code: '2600104', name: 'Afogados da Ingazeira' },
  { code: '2600203', name: 'Afrânio' },
  { code: '2600302', name: 'Agrestina' },
  { code: '2600401', name: 'Água Preta' },
  { code: '2600500', name: 'Águas Belas' },
  { code: '2600609', name: 'Alagoinha' },
  { code: '2600708', name: 'Aliança' },
  { code: '2600807', name: 'Altinho' },
  { code: '2600906', name: 'Amaraji' },
  { code: '2601003', name: 'Angelim' },
  { code: '2601052', name: 'Araçoiaba' },
  { code: '2601102', name: 'Araripina' },
  { code: '2601201', name: 'Arcoverde' },
  { code: '2601300', name: 'Barra de Guabiraba' },
  { code: '2601409', name: 'Barreiros' },
  { code: '2601508', name: 'Belém de Maria' },
  { code: '2601607', name: 'Belém de São Francisco' },
  { code: '2601706', name: 'Belo Jardim' },
  { code: '2601805', name: 'Betânia' },
  { code: '2601904', name: 'Bezerros' },
  { code: '2602001', name: 'Bodocó' },
  { code: '2602100', name: 'Bom Conselho' },
  { code: '2602209', name: 'Bom Jardim' },
  { code: '2602308', name: 'Bonito' },
  { code: '2602407', name: 'Brejão' },
  { code: '2602506', name: 'Brejinho' },
  { code: '2602605', name: 'Brejo da Madre de Deus' },
  { code: '2602704', name: 'Buenos Aires' },
  { code: '2602803', name: 'Buíque' },
  { code: '2602902', name: 'Cabo de Santo Agostinho' },
  { code: '2603009', name: 'Cabrobó' },
  { code: '2603108', name: 'Cachoeirinha' },
  { code: '2603207', name: 'Caetés' },
  { code: '2603306', name: 'Calçado' },
  { code: '2603405', name: 'Calumbi' },
  { code: '2603454', name: 'Camaragibe' },
  { code: '2603504', name: 'Camocim de São Félix' },
  { code: '2603603', name: 'Camutanga' },
  { code: '2603702', name: 'Canhotinho' },
  { code: '2603801', name: 'Capoeiras' },
  { code: '2603900', name: 'Carnaíba' },
  { code: '2603926', name: 'Carnaubeira da Penha' },
  { code: '2604007', name: 'Carpina' },
  { code: '2604106', name: 'Caruaru' },
  { code: '2604155', name: 'Casinhas' },
  { code: '2604205', name: 'Catende' },
  { code: '2604304', name: 'Cedro' },
  { code: '2604403', name: 'Chã de Alegria' },
  { code: '2604502', name: 'Chã Grande' },
  { code: '2604601', name: 'Condado' },
  { code: '2604700', name: 'Correntes' },
  { code: '2604809', name: 'Cortês' },
  { code: '2604908', name: 'Cumaru' },
  { code: '2605004', name: 'Cupira' },
  { code: '2605103', name: 'Custódia' },
  { code: '2605152', name: 'Dormentes' },
  { code: '2605202', name: 'Escada' },
  { code: '2605301', name: 'Exu' },
  { code: '2605400', name: 'Feira Nova' },
  { code: '2605459', name: 'Fernando de Noronha' },
  { code: '2605509', name: 'Ferreiros' },
  { code: '2605608', name: 'Flores' },
  { code: '2605707', name: 'Floresta' },
  { code: '2605806', name: 'Frei Miguelinho' },
  { code: '2605905', name: 'Gameleira' },
  { code: '2606002', name: 'Garanhuns' },
  { code: '2606101', name: 'Glória do Goitá' },
  { code: '2606200', name: 'Goiana' },
  { code: '2606309', name: 'Granito' },
  { code: '2606408', name: 'Gravatá' },
  { code: '2606507', name: 'Iati' },
  { code: '2606606', name: 'Ibimirim' },
  { code: '2606705', name: 'Ibirajuba' },
  { code: '2606804', name: 'Igarassu' },
  { code: '2606903', name: 'Iguaraci' },
  { code: '2607000', name: 'Inajá' },
  { code: '2607109', name: 'Ingazeira' },
  { code: '2607208', name: 'Ipojuca' },
  { code: '2607307', name: 'Ipubi' },
  { code: '2607406', name: 'Itacuruba' },
  { code: '2607505', name: 'Itaíba' },
  { code: '2607604', name: 'Ilha de Itamaracá' },
  { code: '2607653', name: 'Itambé' },
  { code: '2607703', name: 'Itapetim' },
  { code: '2607752', name: 'Itapissuma' },
  { code: '2607802', name: 'Itaquitinga' },
  { code: '2607901', name: 'Jaboatão dos Guararapes' },
  { code: '2607950', name: 'Jaqueira' },
  { code: '2608008', name: 'Jataúba' },
  { code: '2608057', name: 'Jatobá' },
  { code: '2608107', name: 'João Alfredo' },
  { code: '2608206', name: 'Joaquim Nabuco' },
  { code: '2608255', name: 'Jucati' },
  { code: '2608305', name: 'Jupi' },
  { code: '2608404', name: 'Jurema' },
  { code: '2608453', name: 'Lagoa do Carro' },
  { code: '2608503', name: 'Lagoa do Itaenga' },
  { code: '2608602', name: 'Lagoa do Ouro' },
  { code: '2608701', name: 'Lagoa dos Gatos' },
  { code: '2608750', name: 'Lagoa Grande' },
  { code: '2608800', name: 'Lajedo' },
  { code: '2608909', name: 'Limoeiro' },
  { code: '2609006', name: 'Macaparana' },
  { code: '2609105', name: 'Machados' },
  { code: '2609154', name: 'Manari' },
  { code: '2609204', name: 'Maraial' },
  { code: '2609303', name: 'Mirandiba' },
  { code: '2609402', name: 'Moreno' },
  { code: '2609501', name: 'Nazaré da Mata' },
  { code: '2609600', name: 'Olinda' },
  { code: '2609709', name: 'Orobó' },
  { code: '2609808', name: 'Orocó' },
  { code: '2609907', name: 'Ouricuri' },
  { code: '2610004', name: 'Palmares' },
  { code: '2610103', name: 'Palmeirina' },
  { code: '2610202', name: 'Panelas' },
  { code: '2610301', name: 'Paranatama' },
  { code: '2610400', name: 'Parnamirim' },
  { code: '2610509', name: 'Passira' },
  { code: '2610608', name: 'Paudalho' },
  { code: '2610707', name: 'Paulista' },
  { code: '2610806', name: 'Pedra' },
  { code: '2610905', name: 'Pesqueira' },
  { code: '2611002', name: 'Petrolândia' },
  { code: '2611101', name: 'Petrolina' },
  { code: '2611200', name: 'Poção' },
  { code: '2611309', name: 'Pombos' },
  { code: '2611408', name: 'Primavera' },
  { code: '2611507', name: 'Quipapá' },
  { code: '2611533', name: 'Quixaba' },
  { code: '2611606', name: 'Recife' },
  { code: '2611705', name: 'Riacho das Almas' },
  { code: '2611804', name: 'Ribeirão' },
  { code: '2611903', name: 'Rio Formoso' },
  { code: '2612000', name: 'Sairé' },
  { code: '2612109', name: 'Salgadinho' },
  { code: '2612208', name: 'Salgueiro' },
  { code: '2612307', name: 'Saloá' },
  { code: '2612406', name: 'Sanharó' },
  { code: '2612455', name: 'Santa Cruz' },
  { code: '2612471', name: 'Santa Cruz da Baixa Verde' },
  { code: '2612505', name: 'Santa Cruz do Capibaribe' },
  { code: '2612554', name: 'Santa Filomena' },
  { code: '2612604', name: 'Santa Maria da Boa Vista' },
  { code: '2612703', name: 'Santa Maria do Cambucá' },
  { code: '2612802', name: 'Santa Terezinha' },
  { code: '2612901', name: 'São Benedito do Sul' },
  { code: '2613008', name: 'São Bento do Una' },
  { code: '2613107', name: 'São Caitano' },
  { code: '2613206', name: 'São João' },
  { code: '2613305', name: 'São Joaquim do Monte' },
  { code: '2613404', name: 'São José da Coroa Grande' },
  { code: '2613503', name: 'São José do Belmonte' },
  { code: '2613602', name: 'São José do Egito' },
  { code: '2613701', name: 'São Lourenço da Mata' },
  { code: '2613800', name: 'São Vicente Ferrer' },
  { code: '2613909', name: 'Serra Talhada' },
  { code: '2614006', name: 'Serrita' },
  { code: '2614105', name: 'Sertânia' },
  { code: '2614204', name: 'Sirinhaém' },
  { code: '2614303', name: 'Moreilândia' },
  { code: '2614402', name: 'Solidão' },
  { code: '2614501', name: 'Surubim' },
  { code: '2614600', name: 'Tabira' },
  { code: '2614709', name: 'Tacaimbó' },
  { code: '2614808', name: 'Tacaratu' },
  { code: '2614857', name: 'Tamandaré' },
  { code: '2615003', name: 'Taquaritinga do Norte' },
  { code: '2615102', name: 'Terezinha' },
  { code: '2615201', name: 'Terra Nova' },
  { code: '2615300', name: 'Timbaúba' },
  { code: '2615409', name: 'Toritama' },
  { code: '2615508', name: 'Tracunhaém' },
  { code: '2615607', name: 'Trindade' },
  { code: '2615706', name: 'Triunfo' },
  { code: '2615805', name: 'Tupanatinga' },
  { code: '2615904', name: 'Tuparetama' },
  { code: '2616001', name: 'Venturosa' },
  { code: '2616100', name: 'Verdejante' },
  { code: '2616183', name: 'Vertente do Lério' },
  { code: '2616209', name: 'Vertentes' },
  { code: '2616308', name: 'Vicência' },
  { code: '2616407', name: 'Vitória de Santo Antão' },
  { code: '2616506', name: 'Xexéu' },
  { code: '2700102', name: 'Água Branca' },
  { code: '2700201', name: 'Anadia' },
  { code: '2700300', name: 'Arapiraca' },
  { code: '2700409', name: 'Atalaia' },
  { code: '2700508', name: 'Barra de Santo Antônio' },
  { code: '2700607', name: 'Barra de São Miguel' },
  { code: '2700706', name: 'Batalha' },
  { code: '2700805', name: 'Belém' },
  { code: '2700904', name: 'Belo Monte' },
  { code: '2701001', name: 'Boca da Mata' },
  { code: '2701100', name: 'Branquinha' },
  { code: '2701209', name: 'Cacimbinhas' },
  { code: '2701308', name: 'Cajueiro' },
  { code: '2701357', name: 'Campestre' },
  { code: '2701407', name: 'Campo Alegre' },
  { code: '2701506', name: 'Campo Grande' },
  { code: '2701605', name: 'Canapi' },
  { code: '2701704', name: 'Capela' },
  { code: '2701803', name: 'Carneiros' },
  { code: '2701902', name: 'Chã Preta' },
  { code: '2702009', name: 'Coité do Nóia' },
  { code: '2702108', name: 'Colônia Leopoldina' },
  { code: '2702207', name: 'Coqueiro Seco' },
  { code: '2702306', name: 'Coruripe' },
  { code: '2702355', name: 'Craíbas' },
  { code: '2702405', name: 'Delmiro Gouveia' },
  { code: '2702504', name: 'Dois Riachos' },
  { code: '2702553', name: 'Estrela de Alagoas' },
  { code: '2702603', name: 'Feira Grande' },
  { code: '2702702', name: 'Feliz Deserto' },
  { code: '2702801', name: 'Flexeiras' },
  { code: '2702900', name: 'Girau do Ponciano' },
  { code: '2703007', name: 'Ibateguara' },
  { code: '2703106', name: 'Igaci' },
  { code: '2703205', name: 'Igreja Nova' },
  { code: '2703304', name: 'Inhapi' },
  { code: '2703403', name: 'Jacaré dos Homens' },
  { code: '2703502', name: 'Jacuípe' },
  { code: '2703601', name: 'Japaratinga' },
  { code: '2703700', name: 'Jaramataia' },
  { code: '2703759', name: 'Jequiá da Praia' },
  { code: '2703809', name: 'Joaquim Gomes' },
  { code: '2703908', name: 'Jundiá' },
  { code: '2704005', name: 'Junqueiro' },
  { code: '2704104', name: 'Lagoa da Canoa' },
  { code: '2704203', name: 'Limoeiro de Anadia' },
  { code: '2704302', name: 'Maceió' },
  { code: '2704401', name: 'Major Isidoro' },
  { code: '2704500', name: 'Maragogi' },
  { code: '2704609', name: 'Maravilha' },
  { code: '2704708', name: 'Marechal Deodoro' },
  { code: '2704807', name: 'Maribondo' },
  { code: '2704906', name: 'Mar Vermelho' },
  { code: '2705002', name: 'Mata Grande' },
  { code: '2705101', name: 'Matriz de Camaragibe' },
  { code: '2705200', name: 'Messias' },
  { code: '2705309', name: 'Minador do Negrão' },
  { code: '2705408', name: 'Monteirópolis' },
  { code: '2705507', name: 'Murici' },
  { code: '2705606', name: 'Novo Lino' },
  { code: '2705705', name: "Olho d'Água das Flores" },
  { code: '2705804', name: "Olho d'Água do Casado" },
  { code: '2705903', name: "Olho d'Água Grande" },
  { code: '2706000', name: 'Olivença' },
  { code: '2706109', name: 'Ouro Branco' },
  { code: '2706208', name: 'Palestina' },
  { code: '2706307', name: 'Palmeira dos Índios' },
  { code: '2706406', name: 'Pão de Açúcar' },
  { code: '2706422', name: 'Pariconha' },
  { code: '2706448', name: 'Paripueira' },
  { code: '2706505', name: 'Passo de Camaragibe' },
  { code: '2706604', name: 'Paulo Jacinto' },
  { code: '2706703', name: 'Penedo' },
  { code: '2706802', name: 'Piaçabuçu' },
  { code: '2706901', name: 'Pilar' },
  { code: '2707008', name: 'Pindoba' },
  { code: '2707107', name: 'Piranhas' },
  { code: '2707206', name: 'Poço das Trincheiras' },
  { code: '2707305', name: 'Porto Calvo' },
  { code: '2707404', name: 'Porto de Pedras' },
  { code: '2707503', name: 'Porto Real do Colégio' },
  { code: '2707602', name: 'Quebrangulo' },
  { code: '2707701', name: 'Rio Largo' },
  { code: '2707800', name: 'Roteiro' },
  { code: '2707909', name: 'Santa Luzia do Norte' },
  { code: '2708006', name: 'Santana do Ipanema' },
  { code: '2708105', name: 'Santana do Mundaú' },
  { code: '2708204', name: 'São Brás' },
  { code: '2708303', name: 'São José da Laje' },
  { code: '2708402', name: 'São José da Tapera' },
  { code: '2708501', name: 'São Luís do Quitunde' },
  { code: '2708600', name: 'São Miguel dos Campos' },
  { code: '2708709', name: 'São Miguel dos Milagres' },
  { code: '2708808', name: 'São Sebastião' },
  { code: '2708907', name: 'Satuba' },
  { code: '2708956', name: 'Senador Rui Palmeira' },
  { code: '2709004', name: "Tanque d'Arca" },
  { code: '2709103', name: 'Taquarana' },
  { code: '2709152', name: 'Teotônio Vilela' },
  { code: '2709202', name: 'Traipu' },
  { code: '2709301', name: 'União dos Palmares' },
  { code: '2709400', name: 'Viçosa' },
  { code: '2800100', name: 'Amparo de São Francisco' },
  { code: '2800209', name: 'Aquidabã' },
  { code: '2800308', name: 'Aracaju' },
  { code: '2800407', name: 'Arauá' },
  { code: '2800506', name: 'Areia Branca' },
  { code: '2800605', name: 'Barra dos Coqueiros' },
  { code: '2800670', name: 'Boquim' },
  { code: '2800704', name: 'Brejo Grande' },
  { code: '2801009', name: 'Campo do Brito' },
  { code: '2801108', name: 'Canhoba' },
  { code: '2801207', name: 'Canindé de São Francisco' },
  { code: '2801306', name: 'Capela' },
  { code: '2801405', name: 'Carira' },
  { code: '2801504', name: 'Carmópolis' },
  { code: '2801603', name: 'Cedro de São João' },
  { code: '2801702', name: 'Cristinápolis' },
  { code: '2801900', name: 'Cumbe' },
  { code: '2802007', name: 'Divina Pastora' },
  { code: '2802106', name: 'Estância' },
  { code: '2802205', name: 'Feira Nova' },
  { code: '2802304', name: 'Frei Paulo' },
  { code: '2802403', name: 'Gararu' },
  { code: '2802502', name: 'General Maynard' },
  { code: '2802601', name: 'Gracho Cardoso' },
  { code: '2802700', name: 'Ilha das Flores' },
  { code: '2802809', name: 'Indiaroba' },
  { code: '2802908', name: 'Itabaiana' },
  { code: '2803005', name: 'Itabaianinha' },
  { code: '2803104', name: 'Itabi' },
  { code: '2803203', name: "Itaporanga d'Ajuda" },
  { code: '2803302', name: 'Japaratuba' },
  { code: '2803401', name: 'Japoatã' },
  { code: '2803500', name: 'Lagarto' },
  { code: '2803609', name: 'Laranjeiras' },
  { code: '2803708', name: 'Macambira' },
  { code: '2803807', name: 'Malhada dos Bois' },
  { code: '2803906', name: 'Malhador' },
  { code: '2804003', name: 'Maruim' },
  { code: '2804102', name: 'Moita Bonita' },
  { code: '2804201', name: 'Monte Alegre de Sergipe' },
  { code: '2804300', name: 'Muribeca' },
  { code: '2804409', name: 'Neópolis' },
  { code: '2804458', name: 'Nossa Senhora Aparecida' },
  { code: '2804508', name: 'Nossa Senhora da Glória' },
  { code: '2804607', name: 'Nossa Senhora das Dores' },
  { code: '2804706', name: 'Nossa Senhora de Lourdes' },
  { code: '2804805', name: 'Nossa Senhora do Socorro' },
  { code: '2804904', name: 'Pacatuba' },
  { code: '2805000', name: 'Pedra Mole' },
  { code: '2805109', name: 'Pedrinhas' },
  { code: '2805208', name: 'Pinhão' },
  { code: '2805307', name: 'Pirambu' },
  { code: '2805406', name: 'Poço Redondo' },
  { code: '2805505', name: 'Poço Verde' },
  { code: '2805604', name: 'Porto da Folha' },
  { code: '2805703', name: 'Propriá' },
  { code: '2805802', name: 'Riachão do Dantas' },
  { code: '2805901', name: 'Riachuelo' },
  { code: '2806008', name: 'Ribeirópolis' },
  { code: '2806107', name: 'Rosário do Catete' },
  { code: '2806206', name: 'Salgado' },
  { code: '2806305', name: 'Santa Luzia do Itanhy' },
  { code: '2806404', name: 'Santana do São Francisco' },
  { code: '2806503', name: 'Santa Rosa de Lima' },
  { code: '2806602', name: 'Santo Amaro das Brotas' },
  { code: '2806701', name: 'São Cristóvão' },
  { code: '2806800', name: 'São Domingos' },
  { code: '2806909', name: 'São Francisco' },
  { code: '2807006', name: 'São Miguel do Aleixo' },
  { code: '2807105', name: 'Simão Dias' },
  { code: '2807204', name: 'Siriri' },
  { code: '2807303', name: 'Telha' },
  { code: '2807402', name: 'Tobias Barreto' },
  { code: '2807501', name: 'Tomar do Geru' },
  { code: '2807600', name: 'Umbaúba' },
  { code: '2900108', name: 'Abaíra' },
  { code: '2900207', name: 'Abaré' },
  { code: '2900306', name: 'Acajutiba' },
  { code: '2900355', name: 'Adustina' },
  { code: '2900405', name: 'Água Fria' },
  { code: '2900504', name: 'Érico Cardoso' },
  { code: '2900603', name: 'Aiquara' },
  { code: '2900702', name: 'Alagoinhas' },
  { code: '2900801', name: 'Alcobaça' },
  { code: '2900900', name: 'Almadina' },
  { code: '2901007', name: 'Amargosa' },
  { code: '2901106', name: 'Amélia Rodrigues' },
  { code: '2901155', name: 'América Dourada' },
  { code: '2901205', name: 'Anagé' },
  { code: '2901304', name: 'Andaraí' },
  { code: '2901353', name: 'Andorinha' },
  { code: '2901403', name: 'Angical' },
  { code: '2901502', name: 'Anguera' },
  { code: '2901601', name: 'Antas' },
  { code: '2901700', name: 'Antônio Cardoso' },
  { code: '2901809', name: 'Antônio Gonçalves' },
  { code: '2901908', name: 'Aporá' },
  { code: '2901957', name: 'Apuarema' },
  { code: '2902005', name: 'Aracatu' },
  { code: '2902054', name: 'Araças' },
  { code: '2902104', name: 'Araci' },
  { code: '2902203', name: 'Aramari' },
  { code: '2902252', name: 'Arataca' },
  { code: '2902302', name: 'Aratuípe' },
  { code: '2902401', name: 'Aurelino Leal' },
  { code: '2902500', name: 'Baianópolis' },
  { code: '2902609', name: 'Baixa Grande' },
  { code: '2902658', name: 'Banzaê' },
  { code: '2902708', name: 'Barra' },
  { code: '2902807', name: 'Barra da Estiva' },
  { code: '2902906', name: 'Barra do Choça' },
  { code: '2903003', name: 'Barra do Mendes' },
  { code: '2903102', name: 'Barra do Rocha' },
  { code: '2903201', name: 'Barreiras' },
  { code: '2903235', name: 'Barro Alto' },
  { code: '2903276', name: 'Barrocas' },
  { code: '2903300', name: 'Barro Preto' },
  { code: '2903409', name: 'Belmonte' },
  { code: '2903508', name: 'Belo Campo' },
  { code: '2903607', name: 'Biritinga' },
  { code: '2903706', name: 'Boa Nova' },
  { code: '2903805', name: 'Boa Vista do Tupim' },
  { code: '2903904', name: 'Bom Jesus da Lapa' },
  { code: '2903953', name: 'Bom Jesus da Serra' },
  { code: '2904001', name: 'Boninal' },
  { code: '2904050', name: 'Bonito' },
  { code: '2904100', name: 'Boquira' },
  { code: '2904209', name: 'Botuporã' },
  { code: '2904308', name: 'Brejões' },
  { code: '2904407', name: 'Brejolândia' },
  { code: '2904506', name: 'Brotas de Macaúbas' },
  { code: '2904605', name: 'Brumado' },
  { code: '2904704', name: 'Buerarema' },
  { code: '2904753', name: 'Buritirama' },
  { code: '2904803', name: 'Caatiba' },
  { code: '2904852', name: 'Cabaceiras do Paraguaçu' },
  { code: '2904902', name: 'Cachoeira' },
  { code: '2905008', name: 'Caculé' },
  { code: '2905107', name: 'Caém' },
  { code: '2905156', name: 'Caetanos' },
  { code: '2905206', name: 'Caetité' },
  { code: '2905305', name: 'Cafarnaum' },
  { code: '2905404', name: 'Cairu' },
  { code: '2905503', name: 'Caldeirão Grande' },
  { code: '2905602', name: 'Camacan' },
  { code: '2905701', name: 'Camaçari' },
  { code: '2905800', name: 'Camamu' },
  { code: '2905909', name: 'Campo Alegre de Lourdes' },
  { code: '2906006', name: 'Campo Formoso' },
  { code: '2906105', name: 'Canápolis' },
  { code: '2906204', name: 'Canarana' },
  { code: '2906303', name: 'Canavieiras' },
  { code: '2906402', name: 'Candeal' },
  { code: '2906501', name: 'Candeias' },
  { code: '2906600', name: 'Candiba' },
  { code: '2906709', name: 'Cândido Sales' },
  { code: '2906808', name: 'Cansanção' },
  { code: '2906824', name: 'Canudos' },
  { code: '2906857', name: 'Capela do Alto Alegre' },
  { code: '2906873', name: 'Capim Grosso' },
  { code: '2906899', name: 'Caraíbas' },
  { code: '2906907', name: 'Caravelas' },
  { code: '2907004', name: 'Cardeal da Silva' },
  { code: '2907103', name: 'Carinhanha' },
  { code: '2907202', name: 'Casa Nova' },
  { code: '2907301', name: 'Castro Alves' },
  { code: '2907400', name: 'Catolândia' },
  { code: '2907509', name: 'Catu' },
  { code: '2907558', name: 'Caturama' },
  { code: '2907608', name: 'Central' },
  { code: '2907707', name: 'Chorrochó' },
  { code: '2907806', name: 'Cícero Dantas' },
  { code: '2907905', name: 'Cipó' },
  { code: '2908002', name: 'Coaraci' },
  { code: '2908101', name: 'Cocos' },
  { code: '2908200', name: 'Conceição da Feira' },
  { code: '2908309', name: 'Conceição do Almeida' },
  { code: '2908408', name: 'Conceição do Coité' },
  { code: '2908507', name: 'Conceição do Jacuípe' },
  { code: '2908606', name: 'Conde' },
  { code: '2908705', name: 'Condeúba' },
  { code: '2908804', name: 'Contendas do Sincorá' },
  { code: '2908903', name: 'Coração de Maria' },
  { code: '2909000', name: 'Cordeiros' },
  { code: '2909109', name: 'Coribe' },
  { code: '2909208', name: 'Coronel João Sá' },
  { code: '2909307', name: 'Correntina' },
  { code: '2909406', name: 'Cotegipe' },
  { code: '2909505', name: 'Cravolândia' },
  { code: '2909604', name: 'Crisópolis' },
  { code: '2909703', name: 'Cristópolis' },
  { code: '2909802', name: 'Cruz das Almas' },
  { code: '2909901', name: 'Curaçá' },
  { code: '2910008', name: 'Dário Meira' },
  { code: '2910057', name: "Dias d'Ávila" },
  { code: '2910107', name: 'Dom Basílio' },
  { code: '2910206', name: 'Dom Macedo Costa' },
  { code: '2910305', name: 'Elísio Medrado' },
  { code: '2910404', name: 'Encruzilhada' },
  { code: '2910503', name: 'Entre Rios' },
  { code: '2910602', name: 'Esplanada' },
  { code: '2910701', name: 'Euclides da Cunha' },
  { code: '2910727', name: 'Eunápolis' },
  { code: '2910750', name: 'Fátima' },
  { code: '2910776', name: 'Feira da Mata' },
  { code: '2910800', name: 'Feira de Santana' },
  { code: '2910859', name: 'Filadélfia' },
  { code: '2910909', name: 'Firmino Alves' },
  { code: '2911006', name: 'Floresta Azul' },
  { code: '2911105', name: 'Formosa do Rio Preto' },
  { code: '2911204', name: 'Gandu' },
  { code: '2911253', name: 'Gavião' },
  { code: '2911303', name: 'Gentio do Ouro' },
  { code: '2911402', name: 'Glória' },
  { code: '2911501', name: 'Gongogi' },
  { code: '2911600', name: 'Governador Mangabeira' },
  { code: '2911659', name: 'Guajeru' },
  { code: '2911709', name: 'Guanambi' },
  { code: '2911808', name: 'Guaratinga' },
  { code: '2911857', name: 'Heliópolis' },
  { code: '2911907', name: 'Iaçu' },
  { code: '2912004', name: 'Ibiassucê' },
  { code: '2912103', name: 'Ibicaraí' },
  { code: '2912202', name: 'Ibicoara' },
  { code: '2912301', name: 'Ibicuí' },
  { code: '2912400', name: 'Ibipeba' },
  { code: '2912509', name: 'Ibipitanga' },
  { code: '2912608', name: 'Ibiquera' },
  { code: '2912707', name: 'Ibirapitanga' },
  { code: '2912806', name: 'Ibirapuã' },
  { code: '2912905', name: 'Ibirataia' },
  { code: '2913002', name: 'Ibitiara' },
  { code: '2913101', name: 'Ibititá' },
  { code: '2913200', name: 'Ibotirama' },
  { code: '2913309', name: 'Ichu' },
  { code: '2913408', name: 'Igaporã' },
  { code: '2913457', name: 'Igrapiúna' },
  { code: '2913507', name: 'Iguaí' },
  { code: '2913606', name: 'Ilhéus' },
  { code: '2913705', name: 'Inhambupe' },
  { code: '2913804', name: 'Ipecaetá' },
  { code: '2913903', name: 'Ipiaú' },
  { code: '2914000', name: 'Ipirá' },
  { code: '2914109', name: 'Ipupiara' },
  { code: '2914208', name: 'Irajuba' },
  { code: '2914307', name: 'Iramaia' },
  { code: '2914406', name: 'Iraquara' },
  { code: '2914505', name: 'Irará' },
  { code: '2914604', name: 'Irecê' },
  { code: '2914653', name: 'Itabela' },
  { code: '2914703', name: 'Itaberaba' },
  { code: '2914802', name: 'Itabuna' },
  { code: '2914901', name: 'Itacaré' },
  { code: '2915007', name: 'Itaeté' },
  { code: '2915106', name: 'Itagi' },
  { code: '2915205', name: 'Itagibá' },
  { code: '2915304', name: 'Itagimirim' },
  { code: '2915353', name: 'Itaguaçu da Bahia' },
  { code: '2915403', name: 'Itaju do Colônia' },
  { code: '2915502', name: 'Itajuípe' },
  { code: '2915601', name: 'Itamaraju' },
  { code: '2915700', name: 'Itamari' },
  { code: '2915809', name: 'Itambé' },
  { code: '2915908', name: 'Itanagra' },
  { code: '2916005', name: 'Itanhém' },
  { code: '2916104', name: 'Itaparica' },
  { code: '2916203', name: 'Itapé' },
  { code: '2916302', name: 'Itapebi' },
  { code: '2916401', name: 'Itapetinga' },
  { code: '2916500', name: 'Itapicuru' },
  { code: '2916609', name: 'Itapitanga' },
  { code: '2916708', name: 'Itaquara' },
  { code: '2916807', name: 'Itarantim' },
  { code: '2916856', name: 'Itatim' },
  { code: '2916906', name: 'Itiruçu' },
  { code: '2917003', name: 'Itiúba' },
  { code: '2917102', name: 'Itororó' },
  { code: '2917201', name: 'Ituaçu' },
  { code: '2917300', name: 'Ituberá' },
  { code: '2917334', name: 'Iuiú' },
  { code: '2917359', name: 'Jaborandi' },
  { code: '2917409', name: 'Jacaraci' },
  { code: '2917508', name: 'Jacobina' },
  { code: '2917607', name: 'Jaguaquara' },
  { code: '2917706', name: 'Jaguarari' },
  { code: '2917805', name: 'Jaguaripe' },
  { code: '2917904', name: 'Jandaíra' },
  { code: '2918001', name: 'Jequié' },
  { code: '2918100', name: 'Jeremoabo' },
  { code: '2918209', name: 'Jiquiriçá' },
  { code: '2918308', name: 'Jitaúna' },
  { code: '2918357', name: 'João Dourado' },
  { code: '2918407', name: 'Juazeiro' },
  { code: '2918456', name: 'Jucuruçu' },
  { code: '2918506', name: 'Jussara' },
  { code: '2918555', name: 'Jussari' },
  { code: '2918605', name: 'Jussiape' },
  { code: '2918704', name: 'Lafaiete Coutinho' },
  { code: '2918753', name: 'Lagoa Real' },
  { code: '2918803', name: 'Laje' },
  { code: '2918902', name: 'Lajedão' },
  { code: '2919009', name: 'Lajedinho' },
  { code: '2919058', name: 'Lajedo do Tabocal' },
  { code: '2919108', name: 'Lamarão' },
  { code: '2919157', name: 'Lapão' },
  { code: '2919207', name: 'Lauro de Freitas' },
  { code: '2919306', name: 'Lençóis' },
  { code: '2919405', name: 'Licínio de Almeida' },
  { code: '2919504', name: 'Livramento de Nossa Senhora' },
  { code: '2919553', name: 'Luís Eduardo Magalhães' },
  { code: '2919603', name: 'Macajuba' },
  { code: '2919702', name: 'Macarani' },
  { code: '2919801', name: 'Macaúbas' },
  { code: '2919900', name: 'Macururé' },
  { code: '2919926', name: 'Madre de Deus' },
  { code: '2919959', name: 'Maetinga' },
  { code: '2920007', name: 'Maiquinique' },
  { code: '2920106', name: 'Mairi' },
  { code: '2920205', name: 'Malhada' },
  { code: '2920304', name: 'Malhada de Pedras' },
  { code: '2920403', name: 'Manoel Vitorino' },
  { code: '2920452', name: 'Mansidão' },
  { code: '2920502', name: 'Maracás' },
  { code: '2920601', name: 'Maragogipe' },
  { code: '2920700', name: 'Maraú' },
  { code: '2920809', name: 'Marcionílio Souza' },
  { code: '2920908', name: 'Mascote' },
  { code: '2921005', name: 'Mata de São João' },
  { code: '2921054', name: 'Matina' },
  { code: '2921104', name: 'Medeiros Neto' },
  { code: '2921203', name: 'Miguel Calmon' },
  { code: '2921302', name: 'Milagres' },
  { code: '2921401', name: 'Mirangaba' },
  { code: '2921450', name: 'Mirante' },
  { code: '2921500', name: 'Monte Santo' },
  { code: '2921609', name: 'Morpará' },
  { code: '2921708', name: 'Morro do Chapéu' },
  { code: '2921807', name: 'Mortugaba' },
  { code: '2921906', name: 'Mucugê' },
  { code: '2922003', name: 'Mucuri' },
  { code: '2922052', name: 'Mulungu do Morro' },
  { code: '2922102', name: 'Mundo Novo' },
  { code: '2922201', name: 'Muniz Ferreira' },
  { code: '2922250', name: 'Muquém de São Francisco' },
  { code: '2922300', name: 'Muritiba' },
  { code: '2922409', name: 'Mutuípe' },
  { code: '2922508', name: 'Nazaré' },
  { code: '2922607', name: 'Nilo Peçanha' },
  { code: '2922656', name: 'Nordestina' },
  { code: '2922706', name: 'Nova Canaã' },
  { code: '2922730', name: 'Nova Fátima' },
  { code: '2922755', name: 'Nova Ibiá' },
  { code: '2922805', name: 'Nova Itarana' },
  { code: '2922854', name: 'Nova Redenção' },
  { code: '2922904', name: 'Nova Soure' },
  { code: '2923001', name: 'Nova Viçosa' },
  { code: '2923035', name: 'Novo Horizonte' },
  { code: '2923050', name: 'Novo Triunfo' },
  { code: '2923100', name: 'Olindina' },
  { code: '2923209', name: 'Oliveira dos Brejinhos' },
  { code: '2923308', name: 'Ouriçangas' },
  { code: '2923357', name: 'Ourolândia' },
  { code: '2923407', name: 'Palmas de Monte Alto' },
  { code: '2923506', name: 'Palmeiras' },
  { code: '2923605', name: 'Paramirim' },
  { code: '2923704', name: 'Paratinga' },
  { code: '2923803', name: 'Paripiranga' },
  { code: '2923902', name: 'Pau Brasil' },
  { code: '2924009', name: 'Paulo Afonso' },
  { code: '2924058', name: 'Pé de Serra' },
  { code: '2924108', name: 'Pedrão' },
  { code: '2924207', name: 'Pedro Alexandre' },
  { code: '2924306', name: 'Piatã' },
  { code: '2924405', name: 'Pilão Arcado' },
  { code: '2924504', name: 'Pindaí' },
  { code: '2924603', name: 'Pindobaçu' },
  { code: '2924652', name: 'Pintadas' },
  { code: '2924678', name: 'Piraí do Norte' },
  { code: '2924702', name: 'Piripá' },
  { code: '2924801', name: 'Piritiba' },
  { code: '2924900', name: 'Planaltino' },
  { code: '2925006', name: 'Planalto' },
  { code: '2925105', name: 'Poções' },
  { code: '2925204', name: 'Pojuca' },
  { code: '2925253', name: 'Ponto Novo' },
  { code: '2925303', name: 'Porto Seguro' },
  { code: '2925402', name: 'Potiraguá' },
  { code: '2925501', name: 'Prado' },
  { code: '2925600', name: 'Presidente Dutra' },
  { code: '2925709', name: 'Presidente Jânio Quadros' },
  { code: '2925758', name: 'Presidente Tancredo Neves' },
  { code: '2925808', name: 'Queimadas' },
  { code: '2925907', name: 'Quijingue' },
  { code: '2925931', name: 'Quixabeira' },
  { code: '2925956', name: 'Rafael Jambeiro' },
  { code: '2926004', name: 'Remanso' },
  { code: '2926103', name: 'Retirolândia' },
  { code: '2926202', name: 'Riachão das Neves' },
  { code: '2926301', name: 'Riachão do Jacuípe' },
  { code: '2926400', name: 'Riacho de Santana' },
  { code: '2926509', name: 'Ribeira do Amparo' },
  { code: '2926608', name: 'Ribeira do Pombal' },
  { code: '2926657', name: 'Ribeirão do Largo' },
  { code: '2926707', name: 'Rio de Contas' },
  { code: '2926806', name: 'Rio do Antônio' },
  { code: '2926905', name: 'Rio do Pires' },
  { code: '2927002', name: 'Rio Real' },
  { code: '2927101', name: 'Rodelas' },
  { code: '2927200', name: 'Ruy Barbosa' },
  { code: '2927309', name: 'Salinas da Margarida' },
  { code: '2927408', name: 'Salvador' },
  { code: '2927507', name: 'Santa Bárbara' },
  { code: '2927606', name: 'Santa Brígida' },
  { code: '2927705', name: 'Santa Cruz Cabrália' },
  { code: '2927804', name: 'Santa Cruz da Vitória' },
  { code: '2927903', name: 'Santa Inês' },
  { code: '2928000', name: 'Santaluz' },
  { code: '2928059', name: 'Santa Luzia' },
  { code: '2928109', name: 'Santa Maria da Vitória' },
  { code: '2928208', name: 'Santana' },
  { code: '2928307', name: 'Santanópolis' },
  { code: '2928406', name: 'Santa Rita de Cássia' },
  { code: '2928505', name: 'Santa Teresinha' },
  { code: '2928604', name: 'Santo Amaro' },
  { code: '2928703', name: 'Santo Antônio de Jesus' },
  { code: '2928802', name: 'Santo Estêvão' },
  { code: '2928901', name: 'São Desidério' },
  { code: '2928950', name: 'São Domingos' },
  { code: '2929008', name: 'São Félix' },
  { code: '2929057', name: 'São Félix do Coribe' },
  { code: '2929107', name: 'São Felipe' },
  { code: '2929206', name: 'São Francisco do Conde' },
  { code: '2929255', name: 'São Gabriel' },
  { code: '2929305', name: 'São Gonçalo dos Campos' },
  { code: '2929354', name: 'São José da Vitória' },
  { code: '2929370', name: 'São José do Jacuípe' },
  { code: '2929404', name: 'São Miguel das Matas' },
  { code: '2929503', name: 'São Sebastião do Passé' },
  { code: '2929602', name: 'Sapeaçu' },
  { code: '2929701', name: 'Sátiro Dias' },
  { code: '2929750', name: 'Saubara' },
  { code: '2929800', name: 'Saúde' },
  { code: '2929909', name: 'Seabra' },
  { code: '2930006', name: 'Sebastião Laranjeiras' },
  { code: '2930105', name: 'Senhor do Bonfim' },
  { code: '2930154', name: 'Serra do Ramalho' },
  { code: '2930204', name: 'Sento Sé' },
  { code: '2930303', name: 'Serra Dourada' },
  { code: '2930402', name: 'Serra Preta' },
  { code: '2930501', name: 'Serrinha' },
  { code: '2930600', name: 'Serrolândia' },
  { code: '2930709', name: 'Simões Filho' },
  { code: '2930758', name: 'Sítio do Mato' },
  { code: '2930766', name: 'Sítio do Quinto' },
  { code: '2930774', name: 'Sobradinho' },
  { code: '2930808', name: 'Souto Soares' },
  { code: '2930907', name: 'Tabocas do Brejo Velho' },
  { code: '2931004', name: 'Tanhaçu' },
  { code: '2931053', name: 'Tanque Novo' },
  { code: '2931103', name: 'Tanquinho' },
  { code: '2931202', name: 'Taperoá' },
  { code: '2931301', name: 'Tapiramutá' },
  { code: '2931350', name: 'Teixeira de Freitas' },
  { code: '2931400', name: 'Teodoro Sampaio' },
  { code: '2931509', name: 'Teofilândia' },
  { code: '2931608', name: 'Teolândia' },
  { code: '2931707', name: 'Terra Nova' },
  { code: '2931806', name: 'Tremedal' },
  { code: '2931905', name: 'Tucano' },
  { code: '2932002', name: 'Uauá' },
  { code: '2932101', name: 'Ubaíra' },
  { code: '2932200', name: 'Ubaitaba' },
  { code: '2932309', name: 'Ubatã' },
  { code: '2932408', name: 'Uibaí' },
  { code: '2932457', name: 'Umburanas' },
  { code: '2932507', name: 'Una' },
  { code: '2932606', name: 'Urandi' },
  { code: '2932705', name: 'Uruçuca' },
  { code: '2932804', name: 'Utinga' },
  { code: '2932903', name: 'Valença' },
  { code: '2933000', name: 'Valente' },
  { code: '2933059', name: 'Várzea da Roça' },
  { code: '2933109', name: 'Várzea do Poço' },
  { code: '2933158', name: 'Várzea Nova' },
  { code: '2933174', name: 'Varzedo' },
  { code: '2933208', name: 'Vera Cruz' },
  { code: '2933257', name: 'Vereda' },
  { code: '2933307', name: 'Vitória da Conquista' },
  { code: '2933406', name: 'Wagner' },
  { code: '2933455', name: 'Wanderley' },
  { code: '2933505', name: 'Wenceslau Guimarães' },
  { code: '2933604', name: 'Xique-Xique' },
  { code: '3100104', name: 'Abadia dos Dourados' },
  { code: '3100203', name: 'Abaeté' },
  { code: '3100302', name: 'Abre Campo' },
  { code: '3100401', name: 'Acaiaca' },
  { code: '3100500', name: 'Açucena' },
  { code: '3100609', name: 'Água Boa' },
  { code: '3100708', name: 'Água Comprida' },
  { code: '3100807', name: 'Aguanil' },
  { code: '3100906', name: 'Águas Formosas' },
  { code: '3101003', name: 'Águas Vermelhas' },
  { code: '3101102', name: 'Aimorés' },
  { code: '3101201', name: 'Aiuruoca' },
  { code: '3101300', name: 'Alagoa' },
  { code: '3101409', name: 'Albertina' },
  { code: '3101508', name: 'Além Paraíba' },
  { code: '3101607', name: 'Alfenas' },
  { code: '3101631', name: 'Alfredo Vasconcelos' },
  { code: '3101706', name: 'Almenara' },
  { code: '3101805', name: 'Alpercata' },
  { code: '3101904', name: 'Alpinópolis' },
  { code: '3102001', name: 'Alterosa' },
  { code: '3102050', name: 'Alto Caparaó' },
  { code: '3102100', name: 'Alto Rio Doce' },
  { code: '3102209', name: 'Alvarenga' },
  { code: '3102308', name: 'Alvinópolis' },
  { code: '3102407', name: 'Alvorada de Minas' },
  { code: '3102506', name: 'Amparo do Serra' },
  { code: '3102605', name: 'Andradas' },
  { code: '3102704', name: 'Cachoeira de Pajeú' },
  { code: '3102803', name: 'Andrelândia' },
  { code: '3102852', name: 'Angelândia' },
  { code: '3102902', name: 'Antônio Carlos' },
  { code: '3103009', name: 'Antônio Dias' },
  { code: '3103108', name: 'Antônio Prado de Minas' },
  { code: '3103207', name: 'Araçaí' },
  { code: '3103306', name: 'Aracitaba' },
  { code: '3103405', name: 'Araçuaí' },
  { code: '3103504', name: 'Araguari' },
  { code: '3103603', name: 'Arantina' },
  { code: '3103702', name: 'Araponga' },
  { code: '3103751', name: 'Araporã' },
  { code: '3103801', name: 'Arapuá' },
  { code: '3103900', name: 'Araújos' },
  { code: '3104007', name: 'Araxá' },
  { code: '3104106', name: 'Arceburgo' },
  { code: '3104205', name: 'Arcos' },
  { code: '3104304', name: 'Areado' },
  { code: '3104403', name: 'Argirita' },
  { code: '3104452', name: 'Aricanduva' },
  { code: '3104502', name: 'Arinos' },
  { code: '3104601', name: 'Astolfo Dutra' },
  { code: '3104700', name: 'Ataléia' },
  { code: '3104809', name: 'Augusto de Lima' },
  { code: '3104908', name: 'Baependi' },
  { code: '3105004', name: 'Baldim' },
  { code: '3105103', name: 'Bambuí' },
  { code: '3105202', name: 'Bandeira' },
  { code: '3105301', name: 'Bandeira do Sul' },
  { code: '3105400', name: 'Barão de Cocais' },
  { code: '3105509', name: 'Barão de Monte Alto' },
  { code: '3105608', name: 'Barbacena' },
  { code: '3105707', name: 'Barra Longa' },
  { code: '3105905', name: 'Barroso' },
  { code: '3106002', name: 'Bela Vista de Minas' },
  { code: '3106101', name: 'Belmiro Braga' },
  { code: '3106200', name: 'Belo Horizonte' },
  { code: '3106309', name: 'Belo Oriente' },
  { code: '3106408', name: 'Belo Vale' },
  { code: '3106507', name: 'Berilo' },
  { code: '3106606', name: 'Bertópolis' },
  { code: '3106655', name: 'Berizal' },
  { code: '3106705', name: 'Betim' },
  { code: '3106804', name: 'Bias Fortes' },
  { code: '3106903', name: 'Bicas' },
  { code: '3107000', name: 'Biquinhas' },
  { code: '3107109', name: 'Boa Esperança' },
  { code: '3107208', name: 'Bocaina de Minas' },
  { code: '3107307', name: 'Bocaiúva' },
  { code: '3107406', name: 'Bom Despacho' },
  { code: '3107505', name: 'Bom Jardim de Minas' },
  { code: '3107604', name: 'Bom Jesus da Penha' },
  { code: '3107703', name: 'Bom Jesus do Amparo' },
  { code: '3107802', name: 'Bom Jesus do Galho' },
  { code: '3107901', name: 'Bom Repouso' },
  { code: '3108008', name: 'Bom Sucesso' },
  { code: '3108107', name: 'Bonfim' },
  { code: '3108206', name: 'Bonfinópolis de Minas' },
  { code: '3108255', name: 'Bonito de Minas' },
  { code: '3108305', name: 'Borda da Mata' },
  { code: '3108404', name: 'Botelhos' },
  { code: '3108503', name: 'Botumirim' },
  { code: '3108552', name: 'Brasilândia de Minas' },
  { code: '3108602', name: 'Brasília de Minas' },
  { code: '3108701', name: 'Brás Pires' },
  { code: '3108800', name: 'Braúnas' },
  { code: '3108909', name: 'Brasópolis' },
  { code: '3109006', name: 'Brumadinho' },
  { code: '3109105', name: 'Bueno Brandão' },
  { code: '3109204', name: 'Buenópolis' },
  { code: '3109253', name: 'Bugre' },
  { code: '3109303', name: 'Buritis' },
  { code: '3109402', name: 'Buritizeiro' },
  { code: '3109451', name: 'Cabeceira Grande' },
  { code: '3109501', name: 'Cabo Verde' },
  { code: '3109600', name: 'Cachoeira da Prata' },
  { code: '3109709', name: 'Cachoeira de Minas' },
  { code: '3109808', name: 'Cachoeira Dourada' },
  { code: '3109907', name: 'Caetanópolis' },
  { code: '3110004', name: 'Caeté' },
  { code: '3110103', name: 'Caiana' },
  { code: '3110202', name: 'Cajuri' },
  { code: '3110301', name: 'Caldas' },
  { code: '3110400', name: 'Camacho' },
  { code: '3110509', name: 'Camanducaia' },
  { code: '3110608', name: 'Cambuí' },
  { code: '3110707', name: 'Cambuquira' },
  { code: '3110806', name: 'Campanário' },
  { code: '3110905', name: 'Campanha' },
  { code: '3111002', name: 'Campestre' },
  { code: '3111101', name: 'Campina Verde' },
  { code: '3111150', name: 'Campo Azul' },
  { code: '3111200', name: 'Campo Belo' },
  { code: '3111309', name: 'Campo do Meio' },
  { code: '3111408', name: 'Campo Florido' },
  { code: '3111507', name: 'Campos Altos' },
  { code: '3111606', name: 'Campos Gerais' },
  { code: '3111705', name: 'Canaã' },
  { code: '3111804', name: 'Canápolis' },
  { code: '3111903', name: 'Cana Verde' },
  { code: '3112000', name: 'Candeias' },
  { code: '3112059', name: 'Cantagalo' },
  { code: '3112109', name: 'Caparaó' },
  { code: '3112208', name: 'Capela Nova' },
  { code: '3112307', name: 'Capelinha' },
  { code: '3112406', name: 'Capetinga' },
  { code: '3112505', name: 'Capim Branco' },
  { code: '3112604', name: 'Capinópolis' },
  { code: '3112653', name: 'Capitão Andrade' },
  { code: '3112703', name: 'Capitão Enéas' },
  { code: '3112802', name: 'Capitólio' },
  { code: '3112901', name: 'Caputira' },
  { code: '3113008', name: 'Caraí' },
  { code: '3113107', name: 'Caranaíba' },
  { code: '3113206', name: 'Carandaí' },
  { code: '3113305', name: 'Carangola' },
  { code: '3113404', name: 'Caratinga' },
  { code: '3113503', name: 'Carbonita' },
  { code: '3113602', name: 'Careaçu' },
  { code: '3113701', name: 'Carlos Chagas' },
  { code: '3113800', name: 'Carmésia' },
  { code: '3113909', name: 'Carmo da Cachoeira' },
  { code: '3114006', name: 'Carmo da Mata' },
  { code: '3114105', name: 'Carmo de Minas' },
  { code: '3114204', name: 'Carmo do Cajuru' },
  { code: '3114303', name: 'Carmo do Paranaíba' },
  { code: '3114402', name: 'Carmo do Rio Claro' },
  { code: '3114501', name: 'Carmópolis de Minas' },
  { code: '3114550', name: 'Carneirinho' },
  { code: '3114600', name: 'Carrancas' },
  { code: '3114709', name: 'Carvalhópolis' },
  { code: '3114808', name: 'Carvalhos' },
  { code: '3114907', name: 'Casa Grande' },
  { code: '3115003', name: 'Cascalho Rico' },
  { code: '3115102', name: 'Cássia' },
  { code: '3115201', name: 'Conceição da Barra de Minas' },
  { code: '3115300', name: 'Cataguases' },
  { code: '3115359', name: 'Catas Altas' },
  { code: '3115409', name: 'Catas Altas da Noruega' },
  { code: '3115458', name: 'Catuji' },
  { code: '3115474', name: 'Catuti' },
  { code: '3115508', name: 'Caxambu' },
  { code: '3115607', name: 'Cedro do Abaeté' },
  { code: '3115706', name: 'Central de Minas' },
  { code: '3115805', name: 'Centralina' },
  { code: '3115904', name: 'Chácara' },
  { code: '3116001', name: 'Chalé' },
  { code: '3116100', name: 'Chapada do Norte' },
  { code: '3116159', name: 'Chapada Gaúcha' },
  { code: '3116209', name: 'Chiador' },
  { code: '3116308', name: 'Cipotânea' },
  { code: '3116407', name: 'Claraval' },
  { code: '3116506', name: 'Claro dos Poções' },
  { code: '3116605', name: 'Cláudio' },
  { code: '3116704', name: 'Coimbra' },
  { code: '3116803', name: 'Coluna' },
  { code: '3116902', name: 'Comendador Gomes' },
  { code: '3117009', name: 'Comercinho' },
  { code: '3117108', name: 'Conceição da Aparecida' },
  { code: '3117207', name: 'Conceição das Pedras' },
  { code: '3117306', name: 'Conceição das Alagoas' },
  { code: '3117405', name: 'Conceição de Ipanema' },
  { code: '3117504', name: 'Conceição do Mato Dentro' },
  { code: '3117603', name: 'Conceição do Pará' },
  { code: '3117702', name: 'Conceição do Rio Verde' },
  { code: '3117801', name: 'Conceição dos Ouros' },
  { code: '3117836', name: 'Cônego Marinho' },
  { code: '3117876', name: 'Confins' },
  { code: '3117900', name: 'Congonhal' },
  { code: '3118007', name: 'Congonhas' },
  { code: '3118106', name: 'Congonhas do Norte' },
  { code: '3118205', name: 'Conquista' },
  { code: '3118304', name: 'Conselheiro Lafaiete' },
  { code: '3118403', name: 'Conselheiro Pena' },
  { code: '3118502', name: 'Consolação' },
  { code: '3118601', name: 'Contagem' },
  { code: '3118700', name: 'Coqueiral' },
  { code: '3118809', name: 'Coração de Jesus' },
  { code: '3118908', name: 'Cordisburgo' },
  { code: '3119005', name: 'Cordislândia' },
  { code: '3119104', name: 'Corinto' },
  { code: '3119203', name: 'Coroaci' },
  { code: '3119302', name: 'Coromandel' },
  { code: '3119401', name: 'Coronel Fabriciano' },
  { code: '3119500', name: 'Coronel Murta' },
  { code: '3119609', name: 'Coronel Pacheco' },
  { code: '3119708', name: 'Coronel Xavier Chaves' },
  { code: '3119807', name: 'Córrego Danta' },
  { code: '3119906', name: 'Córrego do Bom Jesus' },
  { code: '3119955', name: 'Córrego Fundo' },
  { code: '3120003', name: 'Córrego Novo' },
  { code: '3120102', name: 'Couto de Magalhães de Minas' },
  { code: '3120151', name: 'Crisólita' },
  { code: '3120201', name: 'Cristais' },
  { code: '3120300', name: 'Cristália' },
  { code: '3120409', name: 'Cristiano Otoni' },
  { code: '3120508', name: 'Cristina' },
  { code: '3120607', name: 'Crucilândia' },
  { code: '3120706', name: 'Cruzeiro da Fortaleza' },
  { code: '3120805', name: 'Cruzília' },
  { code: '3120839', name: 'Cuparaque' },
  { code: '3120870', name: 'Curral de Dentro' },
  { code: '3120904', name: 'Curvelo' },
  { code: '3121001', name: 'Datas' },
  { code: '3121100', name: 'Delfim Moreira' },
  { code: '3121209', name: 'Delfinópolis' },
  { code: '3121258', name: 'Delta' },
  { code: '3121308', name: 'Descoberto' },
  { code: '3121407', name: 'Desterro de Entre Rios' },
  { code: '3121506', name: 'Desterro do Melo' },
  { code: '3121605', name: 'Diamantina' },
  { code: '3121704', name: 'Diogo de Vasconcelos' },
  { code: '3121803', name: 'Dionísio' },
  { code: '3121902', name: 'Divinésia' },
  { code: '3122009', name: 'Divino' },
  { code: '3122108', name: 'Divino das Laranjeiras' },
  { code: '3122207', name: 'Divinolândia de Minas' },
  { code: '3122306', name: 'Divinópolis' },
  { code: '3122355', name: 'Divisa Alegre' },
  { code: '3122405', name: 'Divisa Nova' },
  { code: '3122454', name: 'Divisópolis' },
  { code: '3122470', name: 'Dom Bosco' },
  { code: '3122504', name: 'Dom Cavati' },
  { code: '3122603', name: 'Dom Joaquim' },
  { code: '3122702', name: 'Dom Silvério' },
  { code: '3122801', name: 'Dom Viçoso' },
  { code: '3122900', name: 'Dona Eusébia' },
  { code: '3123007', name: 'Dores de Campos' },
  { code: '3123106', name: 'Dores de Guanhães' },
  { code: '3123205', name: 'Dores do Indaiá' },
  { code: '3123304', name: 'Dores do Turvo' },
  { code: '3123403', name: 'Doresópolis' },
  { code: '3123502', name: 'Douradoquara' },
  { code: '3123528', name: 'Durandé' },
  { code: '3123601', name: 'Elói Mendes' },
  { code: '3123700', name: 'Engenheiro Caldas' },
  { code: '3123809', name: 'Engenheiro Navarro' },
  { code: '3123858', name: 'Entre Folhas' },
  { code: '3123908', name: 'Entre Rios de Minas' },
  { code: '3124005', name: 'Ervália' },
  { code: '3124104', name: 'Esmeraldas' },
  { code: '3124203', name: 'Espera Feliz' },
  { code: '3124302', name: 'Espinosa' },
  { code: '3124401', name: 'Espírito Santo do Dourado' },
  { code: '3124500', name: 'Estiva' },
  { code: '3124609', name: 'Estrela Dalva' },
  { code: '3124708', name: 'Estrela do Indaiá' },
  { code: '3124807', name: 'Estrela do Sul' },
  { code: '3124906', name: 'Eugenópolis' },
  { code: '3125002', name: 'Ewbank da Câmara' },
  { code: '3125101', name: 'Extrema' },
  { code: '3125200', name: 'Fama' },
  { code: '3125309', name: 'Faria Lemos' },
  { code: '3125408', name: 'Felício dos Santos' },
  { code: '3125507', name: 'São Gonçalo do Rio Preto' },
  { code: '3125606', name: 'Felisburgo' },
  { code: '3125705', name: 'Felixlândia' },
  { code: '3125804', name: 'Fernandes Tourinho' },
  { code: '3125903', name: 'Ferros' },
  { code: '3125952', name: 'Fervedouro' },
  { code: '3126000', name: 'Florestal' },
  { code: '3126109', name: 'Formiga' },
  { code: '3126208', name: 'Formoso' },
  { code: '3126307', name: 'Fortaleza de Minas' },
  { code: '3126406', name: 'Fortuna de Minas' },
  { code: '3126505', name: 'Francisco Badaró' },
  { code: '3126604', name: 'Francisco Dumont' },
  { code: '3126703', name: 'Francisco Sá' },
  { code: '3126752', name: 'Franciscópolis' },
  { code: '3126802', name: 'Frei Gaspar' },
  { code: '3126901', name: 'Frei Inocêncio' },
  { code: '3126950', name: 'Frei Lagonegro' },
  { code: '3127008', name: 'Fronteira' },
  { code: '3127057', name: 'Fronteira dos Vales' },
  { code: '3127073', name: 'Fruta de Leite' },
  { code: '3127107', name: 'Frutal' },
  { code: '3127206', name: 'Funilândia' },
  { code: '3127305', name: 'Galiléia' },
  { code: '3127339', name: 'Gameleiras' },
  { code: '3127354', name: 'Glaucilândia' },
  { code: '3127370', name: 'Goiabeira' },
  { code: '3127388', name: 'Goianá' },
  { code: '3127404', name: 'Gonçalves' },
  { code: '3127503', name: 'Gonzaga' },
  { code: '3127602', name: 'Gouveia' },
  { code: '3127701', name: 'Governador Valadares' },
  { code: '3127800', name: 'Grão Mogol' },
  { code: '3127909', name: 'Grupiara' },
  { code: '3128006', name: 'Guanhães' },
  { code: '3128105', name: 'Guapé' },
  { code: '3128204', name: 'Guaraciaba' },
  { code: '3128253', name: 'Guaraciama' },
  { code: '3128303', name: 'Guaranésia' },
  { code: '3128402', name: 'Guarani' },
  { code: '3128501', name: 'Guarará' },
  { code: '3128600', name: 'Guarda-Mor' },
  { code: '3128709', name: 'Guaxupé' },
  { code: '3128808', name: 'Guidoval' },
  { code: '3128907', name: 'Guimarânia' },
  { code: '3129004', name: 'Guiricema' },
  { code: '3129103', name: 'Gurinhatã' },
  { code: '3129202', name: 'Heliodora' },
  { code: '3129301', name: 'Iapu' },
  { code: '3129400', name: 'Ibertioga' },
  { code: '3129509', name: 'Ibiá' },
  { code: '3129608', name: 'Ibiaí' },
  { code: '3129657', name: 'Ibiracatu' },
  { code: '3129707', name: 'Ibiraci' },
  { code: '3129806', name: 'Ibirité' },
  { code: '3129905', name: 'Ibitiúra de Minas' },
  { code: '3130002', name: 'Ibituruna' },
  { code: '3130051', name: 'Icaraí de Minas' },
  { code: '3130101', name: 'Igarapé' },
  { code: '3130200', name: 'Igaratinga' },
  { code: '3130309', name: 'Iguatama' },
  { code: '3130408', name: 'Ijaci' },
  { code: '3130507', name: 'Ilicínea' },
  { code: '3130556', name: 'Imbé de Minas' },
  { code: '3130606', name: 'Inconfidentes' },
  { code: '3130655', name: 'Indaiabira' },
  { code: '3130705', name: 'Indianópolis' },
  { code: '3130804', name: 'Ingaí' },
  { code: '3130903', name: 'Inhapim' },
  { code: '3131000', name: 'Inhaúma' },
  { code: '3131109', name: 'Inimutaba' },
  { code: '3131158', name: 'Ipaba' },
  { code: '3131208', name: 'Ipanema' },
  { code: '3131307', name: 'Ipatinga' },
  { code: '3131406', name: 'Ipiaçu' },
  { code: '3131505', name: 'Ipuiúna' },
  { code: '3131604', name: 'Iraí de Minas' },
  { code: '3131703', name: 'Itabira' },
  { code: '3131802', name: 'Itabirinha' },
  { code: '3131901', name: 'Itabirito' },
  { code: '3132008', name: 'Itacambira' },
  { code: '3132107', name: 'Itacarambi' },
  { code: '3132206', name: 'Itaguara' },
  { code: '3132305', name: 'Itaipé' },
  { code: '3132404', name: 'Itajubá' },
  { code: '3132503', name: 'Itamarandiba' },
  { code: '3132602', name: 'Itamarati de Minas' },
  { code: '3132701', name: 'Itambacuri' },
  { code: '3132800', name: 'Itambé do Mato Dentro' },
  { code: '3132909', name: 'Itamogi' },
  { code: '3133006', name: 'Itamonte' },
  { code: '3133105', name: 'Itanhandu' },
  { code: '3133204', name: 'Itanhomi' },
  { code: '3133303', name: 'Itaobim' },
  { code: '3133402', name: 'Itapagipe' },
  { code: '3133501', name: 'Itapecerica' },
  { code: '3133600', name: 'Itapeva' },
  { code: '3133709', name: 'Itatiaiuçu' },
  { code: '3133758', name: 'Itaú de Minas' },
  { code: '3133808', name: 'Itaúna' },
  { code: '3133907', name: 'Itaverava' },
  { code: '3134004', name: 'Itinga' },
  { code: '3134103', name: 'Itueta' },
  { code: '3134202', name: 'Ituiutaba' },
  { code: '3134301', name: 'Itumirim' },
  { code: '3134400', name: 'Iturama' },
  { code: '3134509', name: 'Itutinga' },
  { code: '3134608', name: 'Jaboticatubas' },
  { code: '3134707', name: 'Jacinto' },
  { code: '3134806', name: 'Jacuí' },
  { code: '3134905', name: 'Jacutinga' },
  { code: '3135001', name: 'Jaguaraçu' },
  { code: '3135050', name: 'Jaíba' },
  { code: '3135076', name: 'Jampruca' },
  { code: '3135100', name: 'Janaúba' },
  { code: '3135209', name: 'Januária' },
  { code: '3135308', name: 'Japaraíba' },
  { code: '3135357', name: 'Japonvar' },
  { code: '3135407', name: 'Jeceaba' },
  { code: '3135456', name: 'Jenipapo de Minas' },
  { code: '3135506', name: 'Jequeri' },
  { code: '3135605', name: 'Jequitaí' },
  { code: '3135704', name: 'Jequitibá' },
  { code: '3135803', name: 'Jequitinhonha' },
  { code: '3135902', name: 'Jesuânia' },
  { code: '3136009', name: 'Joaíma' },
  { code: '3136108', name: 'Joanésia' },
  { code: '3136207', name: 'João Monlevade' },
  { code: '3136306', name: 'João Pinheiro' },
  { code: '3136405', name: 'Joaquim Felício' },
  { code: '3136504', name: 'Jordânia' },
  { code: '3136520', name: 'José Gonçalves de Minas' },
  { code: '3136553', name: 'José Raydan' },
  { code: '3136579', name: 'Josenópolis' },
  { code: '3136603', name: 'Nova União' },
  { code: '3136652', name: 'Juatuba' },
  { code: '3136702', name: 'Juiz de Fora' },
  { code: '3136801', name: 'Juramento' },
  { code: '3136900', name: 'Juruaia' },
  { code: '3136959', name: 'Juvenília' },
  { code: '3137007', name: 'Ladainha' },
  { code: '3137106', name: 'Lagamar' },
  { code: '3137205', name: 'Lagoa da Prata' },
  { code: '3137304', name: 'Lagoa dos Patos' },
  { code: '3137403', name: 'Lagoa Dourada' },
  { code: '3137502', name: 'Lagoa Formosa' },
  { code: '3137536', name: 'Lagoa Grande' },
  { code: '3137601', name: 'Lagoa Santa' },
  { code: '3137700', name: 'Lajinha' },
  { code: '3137809', name: 'Lambari' },
  { code: '3137908', name: 'Lamim' },
  { code: '3138005', name: 'Laranjal' },
  { code: '3138104', name: 'Lassance' },
  { code: '3138203', name: 'Lavras' },
  { code: '3138302', name: 'Leandro Ferreira' },
  { code: '3138351', name: 'Leme do Prado' },
  { code: '3138401', name: 'Leopoldina' },
  { code: '3138500', name: 'Liberdade' },
  { code: '3138609', name: 'Lima Duarte' },
  { code: '3138625', name: 'Limeira do Oeste' },
  { code: '3138658', name: 'Lontra' },
  { code: '3138674', name: 'Luisburgo' },
  { code: '3138682', name: 'Luislândia' },
  { code: '3138708', name: 'Luminárias' },
  { code: '3138807', name: 'Luz' },
  { code: '3138906', name: 'Machacalis' },
  { code: '3139003', name: 'Machado' },
  { code: '3139102', name: 'Madre de Deus de Minas' },
  { code: '3139201', name: 'Malacacheta' },
  { code: '3139250', name: 'Mamonas' },
  { code: '3139300', name: 'Manga' },
  { code: '3139409', name: 'Manhuaçu' },
  { code: '3139508', name: 'Manhumirim' },
  { code: '3139607', name: 'Mantena' },
  { code: '3139706', name: 'Maravilhas' },
  { code: '3139805', name: 'Mar de Espanha' },
  { code: '3139904', name: 'Maria da Fé' },
  { code: '3140001', name: 'Mariana' },
  { code: '3140100', name: 'Marilac' },
  { code: '3140159', name: 'Mário Campos' },
  { code: '3140209', name: 'Maripá de Minas' },
  { code: '3140308', name: 'Marliéria' },
  { code: '3140407', name: 'Marmelópolis' },
  { code: '3140506', name: 'Martinho Campos' },
  { code: '3140530', name: 'Martins Soares' },
  { code: '3140555', name: 'Mata Verde' },
  { code: '3140605', name: 'Materlândia' },
  { code: '3140704', name: 'Mateus Leme' },
  { code: '3140803', name: 'Matias Barbosa' },
  { code: '3140852', name: 'Matias Cardoso' },
  { code: '3140902', name: 'Matipó' },
  { code: '3141009', name: 'Mato Verde' },
  { code: '3141108', name: 'Matozinhos' },
  { code: '3141207', name: 'Matutina' },
  { code: '3141306', name: 'Medeiros' },
  { code: '3141405', name: 'Medina' },
  { code: '3141504', name: 'Mendes Pimentel' },
  { code: '3141603', name: 'Mercês' },
  { code: '3141702', name: 'Mesquita' },
  { code: '3141801', name: 'Minas Novas' },
  { code: '3141900', name: 'Minduri' },
  { code: '3142007', name: 'Mirabela' },
  { code: '3142106', name: 'Miradouro' },
  { code: '3142205', name: 'Miraí' },
  { code: '3142254', name: 'Miravânia' },
  { code: '3142304', name: 'Moeda' },
  { code: '3142403', name: 'Moema' },
  { code: '3142502', name: 'Monjolos' },
  { code: '3142601', name: 'Monsenhor Paulo' },
  { code: '3142700', name: 'Montalvânia' },
  { code: '3142809', name: 'Monte Alegre de Minas' },
  { code: '3142908', name: 'Monte Azul' },
  { code: '3143005', name: 'Monte Belo' },
  { code: '3143104', name: 'Monte Carmelo' },
  { code: '3143153', name: 'Monte Formoso' },
  { code: '3143203', name: 'Monte Santo de Minas' },
  { code: '3143302', name: 'Montes Claros' },
  { code: '3143401', name: 'Monte Sião' },
  { code: '3143450', name: 'Montezuma' },
  { code: '3143500', name: 'Morada Nova de Minas' },
  { code: '3143609', name: 'Morro da Garça' },
  { code: '3143708', name: 'Morro do Pilar' },
  { code: '3143807', name: 'Munhoz' },
  { code: '3143906', name: 'Muriaé' },
  { code: '3144003', name: 'Mutum' },
  { code: '3144102', name: 'Muzambinho' },
  { code: '3144201', name: 'Nacip Raydan' },
  { code: '3144300', name: 'Nanuque' },
  { code: '3144359', name: 'Naque' },
  { code: '3144375', name: 'Natalândia' },
  { code: '3144409', name: 'Natércia' },
  { code: '3144508', name: 'Nazareno' },
  { code: '3144607', name: 'Nepomuceno' },
  { code: '3144656', name: 'Ninheira' },
  { code: '3144672', name: 'Nova Belém' },
  { code: '3144706', name: 'Nova Era' },
  { code: '3144805', name: 'Nova Lima' },
  { code: '3144904', name: 'Nova Módica' },
  { code: '3145000', name: 'Nova Ponte' },
  { code: '3145059', name: 'Nova Porteirinha' },
  { code: '3145109', name: 'Nova Resende' },
  { code: '3145208', name: 'Nova Serrana' },
  { code: '3145307', name: 'Novo Cruzeiro' },
  { code: '3145356', name: 'Novo Oriente de Minas' },
  { code: '3145372', name: 'Novorizonte' },
  { code: '3145406', name: 'Olaria' },
  { code: '3145455', name: "Olhos-d'Água" },
  { code: '3145505', name: 'Olímpio Noronha' },
  { code: '3145604', name: 'Oliveira' },
  { code: '3145703', name: 'Oliveira Fortes' },
  { code: '3145802', name: 'Onça de Pitangui' },
  { code: '3145851', name: 'Oratórios' },
  { code: '3145877', name: 'Orizânia' },
  { code: '3145901', name: 'Ouro Branco' },
  { code: '3146008', name: 'Ouro Fino' },
  { code: '3146107', name: 'Ouro Preto' },
  { code: '3146206', name: 'Ouro Verde de Minas' },
  { code: '3146255', name: 'Padre Carvalho' },
  { code: '3146305', name: 'Padre Paraíso' },
  { code: '3146404', name: 'Paineiras' },
  { code: '3146503', name: 'Pains' },
  { code: '3146552', name: 'Pai Pedro' },
  { code: '3146602', name: 'Paiva' },
  { code: '3146701', name: 'Palma' },
  { code: '3146750', name: 'Palmópolis' },
  { code: '3146909', name: 'Papagaios' },
  { code: '3147006', name: 'Paracatu' },
  { code: '3147105', name: 'Pará de Minas' },
  { code: '3147204', name: 'Paraguaçu' },
  { code: '3147303', name: 'Paraisópolis' },
  { code: '3147402', name: 'Paraopeba' },
  { code: '3147501', name: 'Passabém' },
  { code: '3147600', name: 'Passa Quatro' },
  { code: '3147709', name: 'Passa Tempo' },
  { code: '3147808', name: 'Passa-Vinte' },
  { code: '3147907', name: 'Passos' },
  { code: '3147956', name: 'Patis' },
  { code: '3148004', name: 'Patos de Minas' },
  { code: '3148103', name: 'Patrocínio' },
  { code: '3148202', name: 'Patrocínio do Muriaé' },
  { code: '3148301', name: 'Paula Cândido' },
  { code: '3148400', name: 'Paulistas' },
  { code: '3148509', name: 'Pavão' },
  { code: '3148608', name: 'Peçanha' },
  { code: '3148707', name: 'Pedra Azul' },
  { code: '3148756', name: 'Pedra Bonita' },
  { code: '3148806', name: 'Pedra do Anta' },
  { code: '3148905', name: 'Pedra do Indaiá' },
  { code: '3149002', name: 'Pedra Dourada' },
  { code: '3149101', name: 'Pedralva' },
  { code: '3149150', name: 'Pedras de Maria da Cruz' },
  { code: '3149200', name: 'Pedrinópolis' },
  { code: '3149309', name: 'Pedro Leopoldo' },
  { code: '3149408', name: 'Pedro Teixeira' },
  { code: '3149507', name: 'Pequeri' },
  { code: '3149606', name: 'Pequi' },
  { code: '3149705', name: 'Perdigão' },
  { code: '3149804', name: 'Perdizes' },
  { code: '3149903', name: 'Perdões' },
  { code: '3149952', name: 'Periquito' },
  { code: '3150000', name: 'Pescador' },
  { code: '3150109', name: 'Piau' },
  { code: '3150158', name: 'Piedade de Caratinga' },
  { code: '3150208', name: 'Piedade de Ponte Nova' },
  { code: '3150307', name: 'Piedade do Rio Grande' },
  { code: '3150406', name: 'Piedade dos Gerais' },
  { code: '3150505', name: 'Pimenta' },
  { code: '3150539', name: "Pingo-d'Água" },
  { code: '3150570', name: 'Pintópolis' },
  { code: '3150604', name: 'Piracema' },
  { code: '3150703', name: 'Pirajuba' },
  { code: '3150802', name: 'Piranga' },
  { code: '3150901', name: 'Piranguçu' },
  { code: '3151008', name: 'Piranguinho' },
  { code: '3151107', name: 'Pirapetinga' },
  { code: '3151206', name: 'Pirapora' },
  { code: '3151305', name: 'Piraúba' },
  { code: '3151404', name: 'Pitangui' },
  { code: '3151503', name: 'Piumhi' },
  { code: '3151602', name: 'Planura' },
  { code: '3151701', name: 'Poço Fundo' },
  { code: '3151800', name: 'Poços de Caldas' },
  { code: '3151909', name: 'Pocrane' },
  { code: '3152006', name: 'Pompéu' },
  { code: '3152105', name: 'Ponte Nova' },
  { code: '3152131', name: 'Ponto Chique' },
  { code: '3152170', name: 'Ponto dos Volantes' },
  { code: '3152204', name: 'Porteirinha' },
  { code: '3152303', name: 'Porto Firme' },
  { code: '3152402', name: 'Poté' },
  { code: '3152501', name: 'Pouso Alegre' },
  { code: '3152600', name: 'Pouso Alto' },
  { code: '3152709', name: 'Prados' },
  { code: '3152808', name: 'Prata' },
  { code: '3152907', name: 'Pratápolis' },
  { code: '3153004', name: 'Pratinha' },
  { code: '3153103', name: 'Presidente Bernardes' },
  { code: '3153202', name: 'Presidente Juscelino' },
  { code: '3153301', name: 'Presidente Kubitschek' },
  { code: '3153400', name: 'Presidente Olegário' },
  { code: '3153509', name: 'Alto Jequitibá' },
  { code: '3153608', name: 'Prudente de Morais' },
  { code: '3153707', name: 'Quartel Geral' },
  { code: '3153806', name: 'Queluzito' },
  { code: '3153905', name: 'Raposos' },
  { code: '3154002', name: 'Raul Soares' },
  { code: '3154101', name: 'Recreio' },
  { code: '3154150', name: 'Reduto' },
  { code: '3154200', name: 'Resende Costa' },
  { code: '3154309', name: 'Resplendor' },
  { code: '3154408', name: 'Ressaquinha' },
  { code: '3154457', name: 'Riachinho' },
  { code: '3154507', name: 'Riacho dos Machados' },
  { code: '3154606', name: 'Ribeirão das Neves' },
  { code: '3154705', name: 'Ribeirão Vermelho' },
  { code: '3154804', name: 'Rio Acima' },
  { code: '3154903', name: 'Rio Casca' },
  { code: '3155009', name: 'Rio Doce' },
  { code: '3155108', name: 'Rio do Prado' },
  { code: '3155207', name: 'Rio Espera' },
  { code: '3155306', name: 'Rio Manso' },
  { code: '3155405', name: 'Rio Novo' },
  { code: '3155504', name: 'Rio Paranaíba' },
  { code: '3155603', name: 'Rio Pardo de Minas' },
  { code: '3155702', name: 'Rio Piracicaba' },
  { code: '3155801', name: 'Rio Pomba' },
  { code: '3155900', name: 'Rio Preto' },
  { code: '3156007', name: 'Rio Vermelho' },
  { code: '3156106', name: 'Ritápolis' },
  { code: '3156205', name: 'Rochedo de Minas' },
  { code: '3156304', name: 'Rodeiro' },
  { code: '3156403', name: 'Romaria' },
  { code: '3156452', name: 'Rosário da Limeira' },
  { code: '3156502', name: 'Rubelita' },
  { code: '3156601', name: 'Rubim' },
  { code: '3156700', name: 'Sabará' },
  { code: '3156809', name: 'Sabinópolis' },
  { code: '3156908', name: 'Sacramento' },
  { code: '3157005', name: 'Salinas' },
  { code: '3157104', name: 'Salto da Divisa' },
  { code: '3157203', name: 'Santa Bárbara' },
  { code: '3157252', name: 'Santa Bárbara do Leste' },
  { code: '3157278', name: 'Santa Bárbara do Monte Verde' },
  { code: '3157302', name: 'Santa Bárbara do Tugúrio' },
  { code: '3157336', name: 'Santa Cruz de Minas' },
  { code: '3157377', name: 'Santa Cruz de Salinas' },
  { code: '3157401', name: 'Santa Cruz do Escalvado' },
  { code: '3157500', name: 'Santa Efigênia de Minas' },
  { code: '3157609', name: 'Santa Fé de Minas' },
  { code: '3157658', name: 'Santa Helena de Minas' },
  { code: '3157708', name: 'Santa Juliana' },
  { code: '3157807', name: 'Santa Luzia' },
  { code: '3157906', name: 'Santa Margarida' },
  { code: '3158003', name: 'Santa Maria de Itabira' },
  { code: '3158102', name: 'Santa Maria do Salto' },
  { code: '3158201', name: 'Santa Maria do Suaçuí' },
  { code: '3158300', name: 'Santana da Vargem' },
  { code: '3158409', name: 'Santana de Cataguases' },
  { code: '3158508', name: 'Santana de Pirapama' },
  { code: '3158607', name: 'Santana do Deserto' },
  { code: '3158706', name: 'Santana do Garambéu' },
  { code: '3158805', name: 'Santana do Jacaré' },
  { code: '3158904', name: 'Santana do Manhuaçu' },
  { code: '3158953', name: 'Santana do Paraíso' },
  { code: '3159001', name: 'Santana do Riacho' },
  { code: '3159100', name: 'Santana dos Montes' },
  { code: '3159209', name: 'Santa Rita de Caldas' },
  { code: '3159308', name: 'Santa Rita de Jacutinga' },
  { code: '3159357', name: 'Santa Rita de Minas' },
  { code: '3159407', name: 'Santa Rita de Ibitipoca' },
  { code: '3159506', name: 'Santa Rita do Itueto' },
  { code: '3159605', name: 'Santa Rita do Sapucaí' },
  { code: '3159704', name: 'Santa Rosa da Serra' },
  { code: '3159803', name: 'Santa Vitória' },
  { code: '3159902', name: 'Santo Antônio do Amparo' },
  { code: '3160009', name: 'Santo Antônio do Aventureiro' },
  { code: '3160108', name: 'Santo Antônio do Grama' },
  { code: '3160207', name: 'Santo Antônio do Itambé' },
  { code: '3160306', name: 'Santo Antônio do Jacinto' },
  { code: '3160405', name: 'Santo Antônio do Monte' },
  { code: '3160454', name: 'Santo Antônio do Retiro' },
  { code: '3160504', name: 'Santo Antônio do Rio Abaixo' },
  { code: '3160603', name: 'Santo Hipólito' },
  { code: '3160702', name: 'Santos Dumont' },
  { code: '3160801', name: 'São Bento Abade' },
  { code: '3160900', name: 'São Brás do Suaçuí' },
  { code: '3160959', name: 'São Domingos das Dores' },
  { code: '3161007', name: 'São Domingos do Prata' },
  { code: '3161056', name: 'São Félix de Minas' },
  { code: '3161106', name: 'São Francisco' },
  { code: '3161205', name: 'São Francisco de Paula' },
  { code: '3161304', name: 'São Francisco de Sales' },
  { code: '3161403', name: 'São Francisco do Glória' },
  { code: '3161502', name: 'São Geraldo' },
  { code: '3161601', name: 'São Geraldo da Piedade' },
  { code: '3161650', name: 'São Geraldo do Baixio' },
  { code: '3161700', name: 'São Gonçalo do Abaeté' },
  { code: '3161809', name: 'São Gonçalo do Pará' },
  { code: '3161908', name: 'São Gonçalo do Rio Abaixo' },
  { code: '3162005', name: 'São Gonçalo do Sapucaí' },
  { code: '3162104', name: 'São Gotardo' },
  { code: '3162203', name: 'São João Batista do Glória' },
  { code: '3162252', name: 'São João da Lagoa' },
  { code: '3162302', name: 'São João da Mata' },
  { code: '3162401', name: 'São João da Ponte' },
  { code: '3162450', name: 'São João das Missões' },
  { code: '3162500', name: 'São João del Rei' },
  { code: '3162559', name: 'São João do Manhuaçu' },
  { code: '3162575', name: 'São João do Manteninha' },
  { code: '3162609', name: 'São João do Oriente' },
  { code: '3162658', name: 'São João do Pacuí' },
  { code: '3162708', name: 'São João do Paraíso' },
  { code: '3162807', name: 'São João Evangelista' },
  { code: '3162906', name: 'São João Nepomuceno' },
  { code: '3162922', name: 'São Joaquim de Bicas' },
  { code: '3162948', name: 'São José da Barra' },
  { code: '3162955', name: 'São José da Lapa' },
  { code: '3163003', name: 'São José da Safira' },
  { code: '3163102', name: 'São José da Varginha' },
  { code: '3163201', name: 'São José do Alegre' },
  { code: '3163300', name: 'São José do Divino' },
  { code: '3163409', name: 'São José do Goiabal' },
  { code: '3163508', name: 'São José do Jacuri' },
  { code: '3163607', name: 'São José do Mantimento' },
  { code: '3163706', name: 'São Lourenço' },
  { code: '3163805', name: 'São Miguel do Anta' },
  { code: '3163904', name: 'São Pedro da União' },
  { code: '3164001', name: 'São Pedro dos Ferros' },
  { code: '3164100', name: 'São Pedro do Suaçuí' },
  { code: '3164209', name: 'São Romão' },
  { code: '3164308', name: 'São Roque de Minas' },
  { code: '3164407', name: 'São Sebastião da Bela Vista' },
  { code: '3164431', name: 'São Sebastião da Vargem Alegre' },
  { code: '3164472', name: 'São Sebastião do Anta' },
  { code: '3164506', name: 'São Sebastião do Maranhão' },
  { code: '3164605', name: 'São Sebastião do Oeste' },
  { code: '3164704', name: 'São Sebastião do Paraíso' },
  { code: '3164803', name: 'São Sebastião do Rio Preto' },
  { code: '3164902', name: 'São Sebastião do Rio Verde' },
  { code: '3165008', name: 'São Tiago' },
  { code: '3165107', name: 'São Tomás de Aquino' },
  { code: '3165206', name: 'São Thomé das Letras' },
  { code: '3165305', name: 'São Vicente de Minas' },
  { code: '3165404', name: 'Sapucaí-Mirim' },
  { code: '3165503', name: 'Sardoá' },
  { code: '3165537', name: 'Sarzedo' },
  { code: '3165552', name: 'Setubinha' },
  { code: '3165560', name: 'Sem-Peixe' },
  { code: '3165578', name: 'Senador Amaral' },
  { code: '3165602', name: 'Senador Cortes' },
  { code: '3165701', name: 'Senador Firmino' },
  { code: '3165800', name: 'Senador José Bento' },
  { code: '3165909', name: 'Senador Modestino Gonçalves' },
  { code: '3166006', name: 'Senhora de Oliveira' },
  { code: '3166105', name: 'Senhora do Porto' },
  { code: '3166204', name: 'Senhora dos Remédios' },
  { code: '3166303', name: 'Sericita' },
  { code: '3166402', name: 'Seritinga' },
  { code: '3166501', name: 'Serra Azul de Minas' },
  { code: '3166600', name: 'Serra da Saudade' },
  { code: '3166709', name: 'Serra dos Aimorés' },
  { code: '3166808', name: 'Serra do Salitre' },
  { code: '3166907', name: 'Serrania' },
  { code: '3166956', name: 'Serranópolis de Minas' },
  { code: '3167004', name: 'Serranos' },
  { code: '3167103', name: 'Serro' },
  { code: '3167202', name: 'Sete Lagoas' },
  { code: '3167301', name: 'Silveirânia' },
  { code: '3167400', name: 'Silvianópolis' },
  { code: '3167509', name: 'Simão Pereira' },
  { code: '3167608', name: 'Simonésia' },
  { code: '3167707', name: 'Sobrália' },
  { code: '3167806', name: 'Soledade de Minas' },
  { code: '3167905', name: 'Tabuleiro' },
  { code: '3168002', name: 'Taiobeiras' },
  { code: '3168051', name: 'Taparuba' },
  { code: '3168101', name: 'Tapira' },
  { code: '3168200', name: 'Tapiraí' },
  { code: '3168309', name: 'Taquaraçu de Minas' },
  { code: '3168408', name: 'Tarumirim' },
  { code: '3168507', name: 'Teixeiras' },
  { code: '3168606', name: 'Teófilo Otoni' },
  { code: '3168705', name: 'Timóteo' },
  { code: '3168804', name: 'Tiradentes' },
  { code: '3168903', name: 'Tiros' },
  { code: '3169000', name: 'Tocantins' },
  { code: '3169059', name: 'Tocos do Moji' },
  { code: '3169109', name: 'Toledo' },
  { code: '3169208', name: 'Tombos' },
  { code: '3169307', name: 'Três Corações' },
  { code: '3169356', name: 'Três Marias' },
  { code: '3169406', name: 'Três Pontas' },
  { code: '3169505', name: 'Tumiritinga' },
  { code: '3169604', name: 'Tupaciguara' },
  { code: '3169703', name: 'Turmalina' },
  { code: '3169802', name: 'Turvolândia' },
  { code: '3169901', name: 'Ubá' },
  { code: '3170008', name: 'Ubaí' },
  { code: '3170057', name: 'Ubaporanga' },
  { code: '3170107', name: 'Uberaba' },
  { code: '3170206', name: 'Uberlândia' },
  { code: '3170305', name: 'Umburatiba' },
  { code: '3170404', name: 'Unaí' },
  { code: '3170438', name: 'União de Minas' },
  { code: '3170479', name: 'Uruana de Minas' },
  { code: '3170503', name: 'Urucânia' },
  { code: '3170529', name: 'Urucuia' },
  { code: '3170578', name: 'Vargem Alegre' },
  { code: '3170602', name: 'Vargem Bonita' },
  { code: '3170651', name: 'Vargem Grande do Rio Pardo' },
  { code: '3170701', name: 'Varginha' },
  { code: '3170750', name: 'Varjão de Minas' },
  { code: '3170800', name: 'Várzea da Palma' },
  { code: '3170909', name: 'Varzelândia' },
  { code: '3171006', name: 'Vazante' },
  { code: '3171030', name: 'Verdelândia' },
  { code: '3171071', name: 'Veredinha' },
  { code: '3171105', name: 'Veríssimo' },
  { code: '3171154', name: 'Vermelho Novo' },
  { code: '3171204', name: 'Vespasiano' },
  { code: '3171303', name: 'Viçosa' },
  { code: '3171402', name: 'Vieiras' },
  { code: '3171501', name: 'Mathias Lobato' },
  { code: '3171600', name: 'Virgem da Lapa' },
  { code: '3171709', name: 'Virgínia' },
  { code: '3171808', name: 'Virginópolis' },
  { code: '3171907', name: 'Virgolândia' },
  { code: '3172004', name: 'Visconde do Rio Branco' },
  { code: '3172103', name: 'Volta Grande' },
  { code: '3172202', name: 'Wenceslau Braz' },
  { code: '3200102', name: 'Afonso Cláudio' },
  { code: '3200136', name: 'Águia Branca' },
  { code: '3200169', name: 'Água Doce do Norte' },
  { code: '3200201', name: 'Alegre' },
  { code: '3200300', name: 'Alfredo Chaves' },
  { code: '3200359', name: 'Alto Rio Novo' },
  { code: '3200409', name: 'Anchieta' },
  { code: '3200508', name: 'Apiacá' },
  { code: '3200607', name: 'Aracruz' },
  { code: '3200706', name: 'Atilio Vivacqua' },
  { code: '3200805', name: 'Baixo Guandu' },
  { code: '3200904', name: 'Barra de São Francisco' },
  { code: '3201001', name: 'Boa Esperança' },
  { code: '3201100', name: 'Bom Jesus do Norte' },
  { code: '3201159', name: 'Brejetuba' },
  { code: '3201209', name: 'Cachoeiro de Itapemirim' },
  { code: '3201308', name: 'Cariacica' },
  { code: '3201407', name: 'Castelo' },
  { code: '3201506', name: 'Colatina' },
  { code: '3201605', name: 'Conceição da Barra' },
  { code: '3201704', name: 'Conceição do Castelo' },
  { code: '3201803', name: 'Divino de São Lourenço' },
  { code: '3201902', name: 'Domingos Martins' },
  { code: '3202009', name: 'Dores do Rio Preto' },
  { code: '3202108', name: 'Ecoporanga' },
  { code: '3202207', name: 'Fundão' },
  { code: '3202256', name: 'Governador Lindenberg' },
  { code: '3202306', name: 'Guaçuí' },
  { code: '3202405', name: 'Guarapari' },
  { code: '3202454', name: 'Ibatiba' },
  { code: '3202504', name: 'Ibiraçu' },
  { code: '3202553', name: 'Ibitirama' },
  { code: '3202603', name: 'Iconha' },
  { code: '3202652', name: 'Irupi' },
  { code: '3202702', name: 'Itaguaçu' },
  { code: '3202801', name: 'Itapemirim' },
  { code: '3202900', name: 'Itarana' },
  { code: '3203007', name: 'Iúna' },
  { code: '3203056', name: 'Jaguaré' },
  { code: '3203106', name: 'Jerônimo Monteiro' },
  { code: '3203130', name: 'João Neiva' },
  { code: '3203163', name: 'Laranja da Terra' },
  { code: '3203205', name: 'Linhares' },
  { code: '3203304', name: 'Mantenópolis' },
  { code: '3203320', name: 'Marataízes' },
  { code: '3203346', name: 'Marechal Floriano' },
  { code: '3203353', name: 'Marilândia' },
  { code: '3203403', name: 'Mimoso do Sul' },
  { code: '3203502', name: 'Montanha' },
  { code: '3203601', name: 'Mucurici' },
  { code: '3203700', name: 'Muniz Freire' },
  { code: '3203809', name: 'Muqui' },
  { code: '3203908', name: 'Nova Venécia' },
  { code: '3204005', name: 'Pancas' },
  { code: '3204054', name: 'Pedro Canário' },
  { code: '3204104', name: 'Pinheiros' },
  { code: '3204203', name: 'Piúma' },
  { code: '3204252', name: 'Ponto Belo' },
  { code: '3204302', name: 'Presidente Kennedy' },
  { code: '3204351', name: 'Rio Bananal' },
  { code: '3204401', name: 'Rio Novo do Sul' },
  { code: '3204500', name: 'Santa Leopoldina' },
  { code: '3204559', name: 'Santa Maria de Jetibá' },
  { code: '3204609', name: 'Santa Teresa' },
  { code: '3204658', name: 'São Domingos do Norte' },
  { code: '3204708', name: 'São Gabriel da Palha' },
  { code: '3204807', name: 'São José do Calçado' },
  { code: '3204906', name: 'São Mateus' },
  { code: '3204955', name: 'São Roque do Canaã' },
  { code: '3205002', name: 'Serra' },
  { code: '3205010', name: 'Sooretama' },
  { code: '3205036', name: 'Vargem Alta' },
  { code: '3205069', name: 'Venda Nova do Imigrante' },
  { code: '3205101', name: 'Viana' },
  { code: '3205150', name: 'Vila Pavão' },
  { code: '3205176', name: 'Vila Valério' },
  { code: '3205200', name: 'Vila Velha' },
  { code: '3205309', name: 'Vitória' },
  { code: '3300100', name: 'Angra dos Reis' },
  { code: '3300159', name: 'Aperibé' },
  { code: '3300209', name: 'Araruama' },
  { code: '3300225', name: 'Areal' },
  { code: '3300233', name: 'Armação dos Búzios' },
  { code: '3300258', name: 'Arraial do Cabo' },
  { code: '3300308', name: 'Barra do Piraí' },
  { code: '3300407', name: 'Barra Mansa' },
  { code: '3300456', name: 'Belford Roxo' },
  { code: '3300506', name: 'Bom Jardim' },
  { code: '3300605', name: 'Bom Jesus do Itabapoana' },
  { code: '3300704', name: 'Cabo Frio' },
  { code: '3300803', name: 'Cachoeiras de Macacu' },
  { code: '3300902', name: 'Cambuci' },
  { code: '3300936', name: 'Carapebus' },
  { code: '3300951', name: 'Comendador Levy Gasparian' },
  { code: '3301009', name: 'Campos dos Goytacazes' },
  { code: '3301108', name: 'Cantagalo' },
  { code: '3301157', name: 'Cardoso Moreira' },
  { code: '3301207', name: 'Carmo' },
  { code: '3301306', name: 'Casimiro de Abreu' },
  { code: '3301405', name: 'Conceição de Macabu' },
  { code: '3301504', name: 'Cordeiro' },
  { code: '3301603', name: 'Duas Barras' },
  { code: '3301702', name: 'Duque de Caxias' },
  { code: '3301801', name: 'Engenheiro Paulo de Frontin' },
  { code: '3301850', name: 'Guapimirim' },
  { code: '3301876', name: 'Iguaba Grande' },
  { code: '3301900', name: 'Itaboraí' },
  { code: '3302007', name: 'Itaguaí' },
  { code: '3302056', name: 'Italva' },
  { code: '3302106', name: 'Itaocara' },
  { code: '3302205', name: 'Itaperuna' },
  { code: '3302254', name: 'Itatiaia' },
  { code: '3302270', name: 'Japeri' },
  { code: '3302304', name: 'Laje do Muriaé' },
  { code: '3302403', name: 'Macaé' },
  { code: '3302452', name: 'Macuco' },
  { code: '3302502', name: 'Magé' },
  { code: '3302601', name: 'Mangaratiba' },
  { code: '3302700', name: 'Maricá' },
  { code: '3302809', name: 'Mendes' },
  { code: '3302858', name: 'Mesquita' },
  { code: '3302908', name: 'Miguel Pereira' },
  { code: '3303005', name: 'Miracema' },
  { code: '3303104', name: 'Natividade' },
  { code: '3303203', name: 'Nilópolis' },
  { code: '3303302', name: 'Niterói' },
  { code: '3303401', name: 'Nova Friburgo' },
  { code: '3303500', name: 'Nova Iguaçu' },
  { code: '3303609', name: 'Paracambi' },
  { code: '3303708', name: 'Paraíba do Sul' },
  { code: '3303807', name: 'Parati' },
  { code: '3303856', name: 'Paty do Alferes' },
  { code: '3303906', name: 'Petrópolis' },
  { code: '3303955', name: 'Pinheiral' },
  { code: '3304003', name: 'Piraí' },
  { code: '3304102', name: 'Porciúncula' },
  { code: '3304110', name: 'Porto Real' },
  { code: '3304128', name: 'Quatis' },
  { code: '3304144', name: 'Queimados' },
  { code: '3304151', name: 'Quissamã' },
  { code: '3304201', name: 'Resende' },
  { code: '3304300', name: 'Rio Bonito' },
  { code: '3304409', name: 'Rio Claro' },
  { code: '3304508', name: 'Rio das Flores' },
  { code: '3304524', name: 'Rio das Ostras' },
  { code: '3304557', name: 'Rio de Janeiro' },
  { code: '3304607', name: 'Santa Maria Madalena' },
  { code: '3304706', name: 'Santo Antônio de Pádua' },
  { code: '3304755', name: 'São Francisco de Itabapoana' },
  { code: '3304805', name: 'São Fidélis' },
  { code: '3304904', name: 'São Gonçalo' },
  { code: '3305000', name: 'São João da Barra' },
  { code: '3305109', name: 'São João de Meriti' },
  { code: '3305133', name: 'São José de Ubá' },
  { code: '3305158', name: 'São José do Vale do Rio Preto' },
  { code: '3305208', name: 'São Pedro da Aldeia' },
  { code: '3305307', name: 'São Sebastião do Alto' },
  { code: '3305406', name: 'Sapucaia' },
  { code: '3305505', name: 'Saquarema' },
  { code: '3305554', name: 'Seropédica' },
  { code: '3305604', name: 'Silva Jardim' },
  { code: '3305703', name: 'Sumidouro' },
  { code: '3305752', name: 'Tanguá' },
  { code: '3305802', name: 'Teresópolis' },
  { code: '3305901', name: 'Trajano de Morais' },
  { code: '3306008', name: 'Três Rios' },
  { code: '3306107', name: 'Valença' },
  { code: '3306156', name: 'Varre-Sai' },
  { code: '3306206', name: 'Vassouras' },
  { code: '3306305', name: 'Volta Redonda' },
  { code: '3500105', name: 'Adamantina' },
  { code: '3500204', name: 'Adolfo' },
  { code: '3500303', name: 'Aguaí' },
  { code: '3500402', name: 'Águas da Prata' },
  { code: '3500501', name: 'Águas de Lindóia' },
  { code: '3500550', name: 'Águas de Santa Bárbara' },
  { code: '3500600', name: 'Águas de São Pedro' },
  { code: '3500709', name: 'Agudos' },
  { code: '3500758', name: 'Alambari' },
  { code: '3500808', name: 'Alfredo Marcondes' },
  { code: '3500907', name: 'Altair' },
  { code: '3501004', name: 'Altinópolis' },
  { code: '3501103', name: 'Alto Alegre' },
  { code: '3501152', name: 'Alumínio' },
  { code: '3501202', name: 'Álvares Florence' },
  { code: '3501301', name: 'Álvares Machado' },
  { code: '3501400', name: 'Álvaro de Carvalho' },
  { code: '3501509', name: 'Alvinlândia' },
  { code: '3501608', name: 'Americana' },
  { code: '3501707', name: 'Américo Brasiliense' },
  { code: '3501806', name: 'Américo de Campos' },
  { code: '3501905', name: 'Amparo' },
  { code: '3502002', name: 'Analândia' },
  { code: '3502101', name: 'Andradina' },
  { code: '3502200', name: 'Angatuba' },
  { code: '3502309', name: 'Anhembi' },
  { code: '3502408', name: 'Anhumas' },
  { code: '3502507', name: 'Aparecida' },
  { code: '3502606', name: "Aparecida d'Oeste" },
  { code: '3502705', name: 'Apiaí' },
  { code: '3502754', name: 'Araçariguama' },
  { code: '3502804', name: 'Araçatuba' },
  { code: '3502903', name: 'Araçoiaba da Serra' },
  { code: '3503000', name: 'Aramina' },
  { code: '3503109', name: 'Arandu' },
  { code: '3503158', name: 'Arapeí' },
  { code: '3503208', name: 'Araraquara' },
  { code: '3503307', name: 'Araras' },
  { code: '3503356', name: 'Arco-Íris' },
  { code: '3503406', name: 'Arealva' },
  { code: '3503505', name: 'Areias' },
  { code: '3503604', name: 'Areiópolis' },
  { code: '3503703', name: 'Ariranha' },
  { code: '3503802', name: 'Artur Nogueira' },
  { code: '3503901', name: 'Arujá' },
  { code: '3503950', name: 'Aspásia' },
  { code: '3504008', name: 'Assis' },
  { code: '3504107', name: 'Atibaia' },
  { code: '3504206', name: 'Auriflama' },
  { code: '3504305', name: 'Avaí' },
  { code: '3504404', name: 'Avanhandava' },
  { code: '3504503', name: 'Avaré' },
  { code: '3504602', name: 'Bady Bassitt' },
  { code: '3504701', name: 'Balbinos' },
  { code: '3504800', name: 'Bálsamo' },
  { code: '3504909', name: 'Bananal' },
  { code: '3505005', name: 'Barão de Antonina' },
  { code: '3505104', name: 'Barbosa' },
  { code: '3505203', name: 'Bariri' },
  { code: '3505302', name: 'Barra Bonita' },
  { code: '3505351', name: 'Barra do Chapéu' },
  { code: '3505401', name: 'Barra do Turvo' },
  { code: '3505500', name: 'Barretos' },
  { code: '3505609', name: 'Barrinha' },
  { code: '3505708', name: 'Barueri' },
  { code: '3505807', name: 'Bastos' },
  { code: '3505906', name: 'Batatais' },
  { code: '3506003', name: 'Bauru' },
  { code: '3506102', name: 'Bebedouro' },
  { code: '3506201', name: 'Bento de Abreu' },
  { code: '3506300', name: 'Bernardino de Campos' },
  { code: '3506359', name: 'Bertioga' },
  { code: '3506409', name: 'Bilac' },
  { code: '3506508', name: 'Birigui' },
  { code: '3506607', name: 'Biritiba-Mirim' },
  { code: '3506706', name: 'Boa Esperança do Sul' },
  { code: '3506805', name: 'Bocaina' },
  { code: '3506904', name: 'Bofete' },
  { code: '3507001', name: 'Boituva' },
  { code: '3507100', name: 'Bom Jesus dos Perdões' },
  { code: '3507159', name: 'Bom Sucesso de Itararé' },
  { code: '3507209', name: 'Borá' },
  { code: '3507308', name: 'Boracéia' },
  { code: '3507407', name: 'Borborema' },
  { code: '3507456', name: 'Borebi' },
  { code: '3507506', name: 'Botucatu' },
  { code: '3507605', name: 'Bragança Paulista' },
  { code: '3507704', name: 'Braúna' },
  { code: '3507753', name: 'Brejo Alegre' },
  { code: '3507803', name: 'Brodowski' },
  { code: '3507902', name: 'Brotas' },
  { code: '3508009', name: 'Buri' },
  { code: '3508108', name: 'Buritama' },
  { code: '3508207', name: 'Buritizal' },
  { code: '3508306', name: 'Cabrália Paulista' },
  { code: '3508405', name: 'Cabreúva' },
  { code: '3508504', name: 'Caçapava' },
  { code: '3508603', name: 'Cachoeira Paulista' },
  { code: '3508702', name: 'Caconde' },
  { code: '3508801', name: 'Cafelândia' },
  { code: '3508900', name: 'Caiabu' },
  { code: '3509007', name: 'Caieiras' },
  { code: '3509106', name: 'Caiuá' },
  { code: '3509205', name: 'Cajamar' },
  { code: '3509254', name: 'Cajati' },
  { code: '3509304', name: 'Cajobi' },
  { code: '3509403', name: 'Cajuru' },
  { code: '3509452', name: 'Campina do Monte Alegre' },
  { code: '3509502', name: 'Campinas' },
  { code: '3509601', name: 'Campo Limpo Paulista' },
  { code: '3509700', name: 'Campos do Jordão' },
  { code: '3509809', name: 'Campos Novos Paulista' },
  { code: '3509908', name: 'Cananéia' },
  { code: '3509957', name: 'Canas' },
  { code: '3510005', name: 'Cândido Mota' },
  { code: '3510104', name: 'Cândido Rodrigues' },
  { code: '3510153', name: 'Canitar' },
  { code: '3510203', name: 'Capão Bonito' },
  { code: '3510302', name: 'Capela do Alto' },
  { code: '3510401', name: 'Capivari' },
  { code: '3510500', name: 'Caraguatatuba' },
  { code: '3510609', name: 'Carapicuíba' },
  { code: '3510708', name: 'Cardoso' },
  { code: '3510807', name: 'Casa Branca' },
  { code: '3510906', name: 'Cássia dos Coqueiros' },
  { code: '3511003', name: 'Castilho' },
  { code: '3511102', name: 'Catanduva' },
  { code: '3511201', name: 'Catiguá' },
  { code: '3511300', name: 'Cedral' },
  { code: '3511409', name: 'Cerqueira César' },
  { code: '3511508', name: 'Cerquilho' },
  { code: '3511607', name: 'Cesário Lange' },
  { code: '3511706', name: 'Charqueada' },
  { code: '3511904', name: 'Clementina' },
  { code: '3512001', name: 'Colina' },
  { code: '3512100', name: 'Colômbia' },
  { code: '3512209', name: 'Conchal' },
  { code: '3512308', name: 'Conchas' },
  { code: '3512407', name: 'Cordeirópolis' },
  { code: '3512506', name: 'Coroados' },
  { code: '3512605', name: 'Coronel Macedo' },
  { code: '3512704', name: 'Corumbataí' },
  { code: '3512803', name: 'Cosmópolis' },
  { code: '3512902', name: 'Cosmorama' },
  { code: '3513009', name: 'Cotia' },
  { code: '3513108', name: 'Cravinhos' },
  { code: '3513207', name: 'Cristais Paulista' },
  { code: '3513306', name: 'Cruzália' },
  { code: '3513405', name: 'Cruzeiro' },
  { code: '3513504', name: 'Cubatão' },
  { code: '3513603', name: 'Cunha' },
  { code: '3513702', name: 'Descalvado' },
  { code: '3513801', name: 'Diadema' },
  { code: '3513850', name: 'Dirce Reis' },
  { code: '3513900', name: 'Divinolândia' },
  { code: '3514007', name: 'Dobrada' },
  { code: '3514106', name: 'Dois Córregos' },
  { code: '3514205', name: 'Dolcinópolis' },
  { code: '3514304', name: 'Dourado' },
  { code: '3514403', name: 'Dracena' },
  { code: '3514502', name: 'Duartina' },
  { code: '3514601', name: 'Dumont' },
  { code: '3514700', name: 'Echaporã' },
  { code: '3514809', name: 'Eldorado' },
  { code: '3514908', name: 'Elias Fausto' },
  { code: '3514924', name: 'Elisiário' },
  { code: '3514957', name: 'Embaúba' },
  { code: '3515004', name: 'Embu' },
  { code: '3515103', name: 'Embu-Guaçu' },
  { code: '3515129', name: 'Emilianópolis' },
  { code: '3515152', name: 'Engenheiro Coelho' },
  { code: '3515186', name: 'Espírito Santo do Pinhal' },
  { code: '3515194', name: 'Espírito Santo do Turvo' },
  { code: '3515202', name: "Estrela d'Oeste" },
  { code: '3515301', name: 'Estrela do Norte' },
  { code: '3515350', name: 'Euclides da Cunha Paulista' },
  { code: '3515400', name: 'Fartura' },
  { code: '3515509', name: 'Fernandópolis' },
  { code: '3515608', name: 'Fernando Prestes' },
  { code: '3515657', name: 'Fernão' },
  { code: '3515707', name: 'Ferraz de Vasconcelos' },
  { code: '3515806', name: 'Flora Rica' },
  { code: '3515905', name: 'Floreal' },
  { code: '3516002', name: 'Flórida Paulista' },
  { code: '3516101', name: 'Florínia' },
  { code: '3516200', name: 'Franca' },
  { code: '3516309', name: 'Francisco Morato' },
  { code: '3516408', name: 'Franco da Rocha' },
  { code: '3516507', name: 'Gabriel Monteiro' },
  { code: '3516606', name: 'Gália' },
  { code: '3516705', name: 'Garça' },
  { code: '3516804', name: 'Gastão Vidigal' },
  { code: '3516853', name: 'Gavião Peixoto' },
  { code: '3516903', name: 'General Salgado' },
  { code: '3517000', name: 'Getulina' },
  { code: '3517109', name: 'Glicério' },
  { code: '3517208', name: 'Guaiçara' },
  { code: '3517307', name: 'Guaimbê' },
  { code: '3517406', name: 'Guaíra' },
  { code: '3517505', name: 'Guapiaçu' },
  { code: '3517604', name: 'Guapiara' },
  { code: '3517703', name: 'Guará' },
  { code: '3517802', name: 'Guaraçaí' },
  { code: '3517901', name: 'Guaraci' },
  { code: '3518008', name: "Guarani d'Oeste" },
  { code: '3518107', name: 'Guarantã' },
  { code: '3518206', name: 'Guararapes' },
  { code: '3518305', name: 'Guararema' },
  { code: '3518404', name: 'Guaratinguetá' },
  { code: '3518503', name: 'Guareí' },
  { code: '3518602', name: 'Guariba' },
  { code: '3518701', name: 'Guarujá' },
  { code: '3518800', name: 'Guarulhos' },
  { code: '3518859', name: 'Guatapará' },
  { code: '3518909', name: 'Guzolândia' },
  { code: '3519006', name: 'Herculândia' },
  { code: '3519055', name: 'Holambra' },
  { code: '3519071', name: 'Hortolândia' },
  { code: '3519105', name: 'Iacanga' },
  { code: '3519204', name: 'Iacri' },
  { code: '3519253', name: 'Iaras' },
  { code: '3519303', name: 'Ibaté' },
  { code: '3519402', name: 'Ibirá' },
  { code: '3519501', name: 'Ibirarema' },
  { code: '3519600', name: 'Ibitinga' },
  { code: '3519709', name: 'Ibiúna' },
  { code: '3519808', name: 'Icém' },
  { code: '3519907', name: 'Iepê' },
  { code: '3520004', name: 'Igaraçu do Tietê' },
  { code: '3520103', name: 'Igarapava' },
  { code: '3520202', name: 'Igaratá' },
  { code: '3520301', name: 'Iguape' },
  { code: '3520400', name: 'Ilhabela' },
  { code: '3520426', name: 'Ilha Comprida' },
  { code: '3520442', name: 'Ilha Solteira' },
  { code: '3520509', name: 'Indaiatuba' },
  { code: '3520608', name: 'Indiana' },
  { code: '3520707', name: 'Indiaporã' },
  { code: '3520806', name: 'Inúbia Paulista' },
  { code: '3520905', name: 'Ipaussu' },
  { code: '3521002', name: 'Iperó' },
  { code: '3521101', name: 'Ipeúna' },
  { code: '3521150', name: 'Ipiguá' },
  { code: '3521200', name: 'Iporanga' },
  { code: '3521309', name: 'Ipuã' },
  { code: '3521408', name: 'Iracemápolis' },
  { code: '3521507', name: 'Irapuã' },
  { code: '3521606', name: 'Irapuru' },
  { code: '3521705', name: 'Itaberá' },
  { code: '3521804', name: 'Itaí' },
  { code: '3521903', name: 'Itajobi' },
  { code: '3522000', name: 'Itaju' },
  { code: '3522109', name: 'Itanhaém' },
  { code: '3522158', name: 'Itaóca' },
  { code: '3522208', name: 'Itapecerica da Serra' },
  { code: '3522307', name: 'Itapetininga' },
  { code: '3522406', name: 'Itapeva' },
  { code: '3522505', name: 'Itapevi' },
  { code: '3522604', name: 'Itapira' },
  { code: '3522653', name: 'Itapirapuã Paulista' },
  { code: '3522703', name: 'Itápolis' },
  { code: '3522802', name: 'Itaporanga' },
  { code: '3522901', name: 'Itapuí' },
  { code: '3523008', name: 'Itapura' },
  { code: '3523107', name: 'Itaquaquecetuba' },
  { code: '3523206', name: 'Itararé' },
  { code: '3523305', name: 'Itariri' },
  { code: '3523404', name: 'Itatiba' },
  { code: '3523503', name: 'Itatinga' },
  { code: '3523602', name: 'Itirapina' },
  { code: '3523701', name: 'Itirapuã' },
  { code: '3523800', name: 'Itobi' },
  { code: '3523909', name: 'Itu' },
  { code: '3524006', name: 'Itupeva' },
  { code: '3524105', name: 'Ituverava' },
  { code: '3524204', name: 'Jaborandi' },
  { code: '3524303', name: 'Jaboticabal' },
  { code: '3524402', name: 'Jacareí' },
  { code: '3524501', name: 'Jaci' },
  { code: '3524600', name: 'Jacupiranga' },
  { code: '3524709', name: 'Jaguariúna' },
  { code: '3524808', name: 'Jales' },
  { code: '3524907', name: 'Jambeiro' },
  { code: '3525003', name: 'Jandira' },
  { code: '3525102', name: 'Jardinópolis' },
  { code: '3525201', name: 'Jarinu' },
  { code: '3525300', name: 'Jaú' },
  { code: '3525409', name: 'Jeriquara' },
  { code: '3525508', name: 'Joanópolis' },
  { code: '3525607', name: 'João Ramalho' },
  { code: '3525706', name: 'José Bonifácio' },
  { code: '3525805', name: 'Júlio Mesquita' },
  { code: '3525854', name: 'Jumirim' },
  { code: '3525904', name: 'Jundiaí' },
  { code: '3526001', name: 'Junqueirópolis' },
  { code: '3526100', name: 'Juquiá' },
  { code: '3526209', name: 'Juquitiba' },
  { code: '3526308', name: 'Lagoinha' },
  { code: '3526407', name: 'Laranjal Paulista' },
  { code: '3526506', name: 'Lavínia' },
  { code: '3526605', name: 'Lavrinhas' },
  { code: '3526704', name: 'Leme' },
  { code: '3526803', name: 'Lençóis Paulista' },
  { code: '3526902', name: 'Limeira' },
  { code: '3527009', name: 'Lindóia' },
  { code: '3527108', name: 'Lins' },
  { code: '3527207', name: 'Lorena' },
  { code: '3527256', name: 'Lourdes' },
  { code: '3527306', name: 'Louveira' },
  { code: '3527405', name: 'Lucélia' },
  { code: '3527504', name: 'Lucianópolis' },
  { code: '3527603', name: 'Luís Antônio' },
  { code: '3527702', name: 'Luiziânia' },
  { code: '3527801', name: 'Lupércio' },
  { code: '3527900', name: 'Lutécia' },
  { code: '3528007', name: 'Macatuba' },
  { code: '3528106', name: 'Macaubal' },
  { code: '3528205', name: 'Macedônia' },
  { code: '3528304', name: 'Magda' },
  { code: '3528403', name: 'Mairinque' },
  { code: '3528502', name: 'Mairiporã' },
  { code: '3528601', name: 'Manduri' },
  { code: '3528700', name: 'Marabá Paulista' },
  { code: '3528809', name: 'Maracaí' },
  { code: '3528858', name: 'Marapoama' },
  { code: '3528908', name: 'Mariápolis' },
  { code: '3529005', name: 'Marília' },
  { code: '3529104', name: 'Marinópolis' },
  { code: '3529203', name: 'Martinópolis' },
  { code: '3529302', name: 'Matão' },
  { code: '3529401', name: 'Mauá' },
  { code: '3529500', name: 'Mendonça' },
  { code: '3529609', name: 'Meridiano' },
  { code: '3529658', name: 'Mesópolis' },
  { code: '3529708', name: 'Miguelópolis' },
  { code: '3529807', name: 'Mineiros do Tietê' },
  { code: '3529906', name: 'Miracatu' },
  { code: '3530003', name: 'Mira Estrela' },
  { code: '3530102', name: 'Mirandópolis' },
  { code: '3530201', name: 'Mirante do Paranapanema' },
  { code: '3530300', name: 'Mirassol' },
  { code: '3530409', name: 'Mirassolândia' },
  { code: '3530508', name: 'Mococa' },
  { code: '3530607', name: 'Mogi das Cruzes' },
  { code: '3530706', name: 'Mogi Guaçu' },
  { code: '3530805', name: 'Moji Mirim' },
  { code: '3530904', name: 'Mombuca' },
  { code: '3531001', name: 'Monções' },
  { code: '3531100', name: 'Mongaguá' },
  { code: '3531209', name: 'Monte Alegre do Sul' },
  { code: '3531308', name: 'Monte Alto' },
  { code: '3531407', name: 'Monte Aprazível' },
  { code: '3531506', name: 'Monte Azul Paulista' },
  { code: '3531605', name: 'Monte Castelo' },
  { code: '3531704', name: 'Monteiro Lobato' },
  { code: '3531803', name: 'Monte Mor' },
  { code: '3531902', name: 'Morro Agudo' },
  { code: '3532009', name: 'Morungaba' },
  { code: '3532058', name: 'Motuca' },
  { code: '3532108', name: 'Murutinga do Sul' },
  { code: '3532157', name: 'Nantes' },
  { code: '3532207', name: 'Narandiba' },
  { code: '3532306', name: 'Natividade da Serra' },
  { code: '3532405', name: 'Nazaré Paulista' },
  { code: '3532504', name: 'Neves Paulista' },
  { code: '3532603', name: 'Nhandeara' },
  { code: '3532702', name: 'Nipoã' },
  { code: '3532801', name: 'Nova Aliança' },
  { code: '3532827', name: 'Nova Campina' },
  { code: '3532843', name: 'Nova Canaã Paulista' },
  { code: '3532868', name: 'Nova Castilho' },
  { code: '3532900', name: 'Nova Europa' },
  { code: '3533007', name: 'Nova Granada' },
  { code: '3533106', name: 'Nova Guataporanga' },
  { code: '3533205', name: 'Nova Independência' },
  { code: '3533254', name: 'Novais' },
  { code: '3533304', name: 'Nova Luzitânia' },
  { code: '3533403', name: 'Nova Odessa' },
  { code: '3533502', name: 'Novo Horizonte' },
  { code: '3533601', name: 'Nuporanga' },
  { code: '3533700', name: 'Ocauçu' },
  { code: '3533809', name: 'Óleo' },
  { code: '3533908', name: 'Olímpia' },
  { code: '3534005', name: 'Onda Verde' },
  { code: '3534104', name: 'Oriente' },
  { code: '3534203', name: 'Orindiúva' },
  { code: '3534302', name: 'Orlândia' },
  { code: '3534401', name: 'Osasco' },
  { code: '3534500', name: 'Oscar Bressane' },
  { code: '3534609', name: 'Osvaldo Cruz' },
  { code: '3534708', name: 'Ourinhos' },
  { code: '3534757', name: 'Ouroeste' },
  { code: '3534807', name: 'Ouro Verde' },
  { code: '3534906', name: 'Pacaembu' },
  { code: '3535002', name: 'Palestina' },
  { code: '3535101', name: 'Palmares Paulista' },
  { code: '3535200', name: "Palmeira d'Oeste" },
  { code: '3535309', name: 'Palmital' },
  { code: '3535408', name: 'Panorama' },
  { code: '3535507', name: 'Paraguaçu Paulista' },
  { code: '3535606', name: 'Paraibuna' },
  { code: '3535705', name: 'Paraíso' },
  { code: '3535804', name: 'Paranapanema' },
  { code: '3535903', name: 'Paranapuã' },
  { code: '3536000', name: 'Parapuã' },
  { code: '3536109', name: 'Pardinho' },
  { code: '3536208', name: 'Pariquera-Açu' },
  { code: '3536257', name: 'Parisi' },
  { code: '3536307', name: 'Patrocínio Paulista' },
  { code: '3536406', name: 'Paulicéia' },
  { code: '3536505', name: 'Paulínia' },
  { code: '3536570', name: 'Paulistânia' },
  { code: '3536604', name: 'Paulo de Faria' },
  { code: '3536703', name: 'Pederneiras' },
  { code: '3536802', name: 'Pedra Bela' },
  { code: '3536901', name: 'Pedranópolis' },
  { code: '3537008', name: 'Pedregulho' },
  { code: '3537107', name: 'Pedreira' },
  { code: '3537156', name: 'Pedrinhas Paulista' },
  { code: '3537206', name: 'Pedro de Toledo' },
  { code: '3537305', name: 'Penápolis' },
  { code: '3537404', name: 'Pereira Barreto' },
  { code: '3537503', name: 'Pereiras' },
  { code: '3537602', name: 'Peruíbe' },
  { code: '3537701', name: 'Piacatu' },
  { code: '3537800', name: 'Piedade' },
  { code: '3537909', name: 'Pilar do Sul' },
  { code: '3538006', name: 'Pindamonhangaba' },
  { code: '3538105', name: 'Pindorama' },
  { code: '3538204', name: 'Pinhalzinho' },
  { code: '3538303', name: 'Piquerobi' },
  { code: '3538501', name: 'Piquete' },
  { code: '3538600', name: 'Piracaia' },
  { code: '3538709', name: 'Piracicaba' },
  { code: '3538808', name: 'Piraju' },
  { code: '3538907', name: 'Pirajuí' },
  { code: '3539004', name: 'Pirangi' },
  { code: '3539103', name: 'Pirapora do Bom Jesus' },
  { code: '3539202', name: 'Pirapozinho' },
  { code: '3539301', name: 'Pirassununga' },
  { code: '3539400', name: 'Piratininga' },
  { code: '3539509', name: 'Pitangueiras' },
  { code: '3539608', name: 'Planalto' },
  { code: '3539707', name: 'Platina' },
  { code: '3539806', name: 'Poá' },
  { code: '3539905', name: 'Poloni' },
  { code: '3540002', name: 'Pompéia' },
  { code: '3540101', name: 'Pongaí' },
  { code: '3540200', name: 'Pontal' },
  { code: '3540259', name: 'Pontalinda' },
  { code: '3540309', name: 'Pontes Gestal' },
  { code: '3540408', name: 'Populina' },
  { code: '3540507', name: 'Porangaba' },
  { code: '3540606', name: 'Porto Feliz' },
  { code: '3540705', name: 'Porto Ferreira' },
  { code: '3540754', name: 'Potim' },
  { code: '3540804', name: 'Potirendaba' },
  { code: '3540853', name: 'Pracinha' },
  { code: '3540903', name: 'Pradópolis' },
  { code: '3541000', name: 'Praia Grande' },
  { code: '3541059', name: 'Pratânia' },
  { code: '3541109', name: 'Presidente Alves' },
  { code: '3541208', name: 'Presidente Bernardes' },
  { code: '3541307', name: 'Presidente Epitácio' },
  { code: '3541406', name: 'Presidente Prudente' },
  { code: '3541505', name: 'Presidente Venceslau' },
  { code: '3541604', name: 'Promissão' },
  { code: '3541653', name: 'Quadra' },
  { code: '3541703', name: 'Quatá' },
  { code: '3541802', name: 'Queiroz' },
  { code: '3541901', name: 'Queluz' },
  { code: '3542008', name: 'Quintana' },
  { code: '3542107', name: 'Rafard' },
  { code: '3542206', name: 'Rancharia' },
  { code: '3542305', name: 'Redenção da Serra' },
  { code: '3542404', name: 'Regente Feijó' },
  { code: '3542503', name: 'Reginópolis' },
  { code: '3542602', name: 'Registro' },
  { code: '3542701', name: 'Restinga' },
  { code: '3542800', name: 'Ribeira' },
  { code: '3542909', name: 'Ribeirão Bonito' },
  { code: '3543006', name: 'Ribeirão Branco' },
  { code: '3543105', name: 'Ribeirão Corrente' },
  { code: '3543204', name: 'Ribeirão do Sul' },
  { code: '3543238', name: 'Ribeirão dos Índios' },
  { code: '3543253', name: 'Ribeirão Grande' },
  { code: '3543303', name: 'Ribeirão Pires' },
  { code: '3543402', name: 'Ribeirão Preto' },
  { code: '3543501', name: 'Riversul' },
  { code: '3543600', name: 'Rifaina' },
  { code: '3543709', name: 'Rincão' },
  { code: '3543808', name: 'Rinópolis' },
  { code: '3543907', name: 'Rio Claro' },
  { code: '3544004', name: 'Rio das Pedras' },
  { code: '3544103', name: 'Rio Grande da Serra' },
  { code: '3544202', name: 'Riolândia' },
  { code: '3544251', name: 'Rosana' },
  { code: '3544301', name: 'Roseira' },
  { code: '3544400', name: 'Rubiácea' },
  { code: '3544509', name: 'Rubinéia' },
  { code: '3544608', name: 'Sabino' },
  { code: '3544707', name: 'Sagres' },
  { code: '3544806', name: 'Sales' },
  { code: '3544905', name: 'Sales Oliveira' },
  { code: '3545001', name: 'Salesópolis' },
  { code: '3545100', name: 'Salmourão' },
  { code: '3545159', name: 'Saltinho' },
  { code: '3545209', name: 'Salto' },
  { code: '3545308', name: 'Salto de Pirapora' },
  { code: '3545407', name: 'Salto Grande' },
  { code: '3545506', name: 'Sandovalina' },
  { code: '3545605', name: 'Santa Adélia' },
  { code: '3545704', name: 'Santa Albertina' },
  { code: '3545803', name: "Santa Bárbara d'Oeste" },
  { code: '3546009', name: 'Santa Branca' },
  { code: '3546108', name: "Santa Clara d'Oeste" },
  { code: '3546207', name: 'Santa Cruz da Conceição' },
  { code: '3546256', name: 'Santa Cruz da Esperança' },
  { code: '3546306', name: 'Santa Cruz das Palmeiras' },
  { code: '3546405', name: 'Santa Cruz do Rio Pardo' },
  { code: '3546504', name: 'Santa Ernestina' },
  { code: '3546603', name: 'Santa Fé do Sul' },
  { code: '3546702', name: 'Santa Gertrudes' },
  { code: '3546801', name: 'Santa Isabel' },
  { code: '3546900', name: 'Santa Lúcia' },
  { code: '3547007', name: 'Santa Maria da Serra' },
  { code: '3547106', name: 'Santa Mercedes' },
  { code: '3547205', name: 'Santana da Ponte Pensa' },
  { code: '3547304', name: 'Santana de Parnaíba' },
  { code: '3547403', name: "Santa Rita d'Oeste" },
  { code: '3547502', name: 'Santa Rita do Passa Quatro' },
  { code: '3547601', name: 'Santa Rosa de Viterbo' },
  { code: '3547650', name: 'Santa Salete' },
  { code: '3547700', name: 'Santo Anastácio' },
  { code: '3547809', name: 'Santo André' },
  { code: '3547908', name: 'Santo Antônio da Alegria' },
  { code: '3548005', name: 'Santo Antônio de Posse' },
  { code: '3548054', name: 'Santo Antônio do Aracanguá' },
  { code: '3548104', name: 'Santo Antônio do Jardim' },
  { code: '3548203', name: 'Santo Antônio do Pinhal' },
  { code: '3548302', name: 'Santo Expedito' },
  { code: '3548401', name: 'Santópolis do Aguapeí' },
  { code: '3548500', name: 'Santos' },
  { code: '3548609', name: 'São Bento do Sapucaí' },
  { code: '3548708', name: 'São Bernardo do Campo' },
  { code: '3548807', name: 'São Caetano do Sul' },
  { code: '3548906', name: 'São Carlos' },
  { code: '3549003', name: 'São Francisco' },
  { code: '3549102', name: 'São João da Boa Vista' },
  { code: '3549201', name: 'São João das Duas Pontes' },
  { code: '3549250', name: 'São João de Iracema' },
  { code: '3549300', name: "São João do Pau d'Alho" },
  { code: '3549409', name: 'São Joaquim da Barra' },
  { code: '3549508', name: 'São José da Bela Vista' },
  { code: '3549607', name: 'São José do Barreiro' },
  { code: '3549706', name: 'São José do Rio Pardo' },
  { code: '3549805', name: 'São José do Rio Preto' },
  { code: '3549904', name: 'São José dos Campos' },
  { code: '3549953', name: 'São Lourenço da Serra' },
  { code: '3550001', name: 'São Luís do Paraitinga' },
  { code: '3550100', name: 'São Manuel' },
  { code: '3550209', name: 'São Miguel Arcanjo' },
  { code: '3550308', name: 'São Paulo' },
  { code: '3550407', name: 'São Pedro' },
  { code: '3550506', name: 'São Pedro do Turvo' },
  { code: '3550605', name: 'São Roque' },
  { code: '3550704', name: 'São Sebastião' },
  { code: '3550803', name: 'São Sebastião da Grama' },
  { code: '3550902', name: 'São Simão' },
  { code: '3551009', name: 'São Vicente' },
  { code: '3551108', name: 'Sarapuí' },
  { code: '3551207', name: 'Sarutaiá' },
  { code: '3551306', name: 'Sebastianópolis do Sul' },
  { code: '3551405', name: 'Serra Azul' },
  { code: '3551504', name: 'Serrana' },
  { code: '3551603', name: 'Serra Negra' },
  { code: '3551702', name: 'Sertãozinho' },
  { code: '3551801', name: 'Sete Barras' },
  { code: '3551900', name: 'Severínia' },
  { code: '3552007', name: 'Silveiras' },
  { code: '3552106', name: 'Socorro' },
  { code: '3552205', name: 'Sorocaba' },
  { code: '3552304', name: 'Sud Mennucci' },
  { code: '3552403', name: 'Sumaré' },
  { code: '3552502', name: 'Suzano' },
  { code: '3552551', name: 'Suzanápolis' },
  { code: '3552601', name: 'Tabapuã' },
  { code: '3552700', name: 'Tabatinga' },
  { code: '3552809', name: 'Taboão da Serra' },
  { code: '3552908', name: 'Taciba' },
  { code: '3553005', name: 'Taguaí' },
  { code: '3553104', name: 'Taiaçu' },
  { code: '3553203', name: 'Taiúva' },
  { code: '3553302', name: 'Tambaú' },
  { code: '3553401', name: 'Tanabi' },
  { code: '3553500', name: 'Tapiraí' },
  { code: '3553609', name: 'Tapiratiba' },
  { code: '3553658', name: 'Taquaral' },
  { code: '3553708', name: 'Taquaritinga' },
  { code: '3553807', name: 'Taquarituba' },
  { code: '3553856', name: 'Taquarivaí' },
  { code: '3553906', name: 'Tarabai' },
  { code: '3553955', name: 'Tarumã' },
  { code: '3554003', name: 'Tatuí' },
  { code: '3554102', name: 'Taubaté' },
  { code: '3554201', name: 'Tejupá' },
  { code: '3554300', name: 'Teodoro Sampaio' },
  { code: '3554409', name: 'Terra Roxa' },
  { code: '3554508', name: 'Tietê' },
  { code: '3554607', name: 'Timburi' },
  { code: '3554656', name: 'Torre de Pedra' },
  { code: '3554706', name: 'Torrinha' },
  { code: '3554755', name: 'Trabiju' },
  { code: '3554805', name: 'Tremembé' },
  { code: '3554904', name: 'Três Fronteiras' },
  { code: '3554953', name: 'Tuiuti' },
  { code: '3555000', name: 'Tupã' },
  { code: '3555109', name: 'Tupi Paulista' },
  { code: '3555208', name: 'Turiúba' },
  { code: '3555307', name: 'Turmalina' },
  { code: '3555356', name: 'Ubarana' },
  { code: '3555406', name: 'Ubatuba' },
  { code: '3555505', name: 'Ubirajara' },
  { code: '3555604', name: 'Uchoa' },
  { code: '3555703', name: 'União Paulista' },
  { code: '3555802', name: 'Urânia' },
  { code: '3555901', name: 'Uru' },
  { code: '3556008', name: 'Urupês' },
  { code: '3556107', name: 'Valentim Gentil' },
  { code: '3556206', name: 'Valinhos' },
  { code: '3556305', name: 'Valparaíso' },
  { code: '3556354', name: 'Vargem' },
  { code: '3556404', name: 'Vargem Grande do Sul' },
  { code: '3556453', name: 'Vargem Grande Paulista' },
  { code: '3556503', name: 'Várzea Paulista' },
  { code: '3556602', name: 'Vera Cruz' },
  { code: '3556701', name: 'Vinhedo' },
  { code: '3556800', name: 'Viradouro' },
  { code: '3556909', name: 'Vista Alegre do Alto' },
  { code: '3556958', name: 'Vitória Brasil' },
  { code: '3557006', name: 'Votorantim' },
  { code: '3557105', name: 'Votuporanga' },
  { code: '3557154', name: 'Zacarias' },
  { code: '3557204', name: 'Chavantes' },
  { code: '3557303', name: 'Estiva Gerbi' },
  { code: '4100103', name: 'Abatiá' },
  { code: '4100202', name: 'Adrianópolis' },
  { code: '4100301', name: 'Agudos do Sul' },
  { code: '4100400', name: 'Almirante Tamandaré' },
  { code: '4100459', name: 'Altamira do Paraná' },
  { code: '4100509', name: 'Altônia' },
  { code: '4100608', name: 'Alto Paraná' },
  { code: '4100707', name: 'Alto Piquiri' },
  { code: '4100806', name: 'Alvorada do Sul' },
  { code: '4100905', name: 'Amaporã' },
  { code: '4101002', name: 'Ampére' },
  { code: '4101051', name: 'Anahy' },
  { code: '4101101', name: 'Andirá' },
  { code: '4101150', name: 'Ângulo' },
  { code: '4101200', name: 'Antonina' },
  { code: '4101309', name: 'Antônio Olinto' },
  { code: '4101408', name: 'Apucarana' },
  { code: '4101507', name: 'Arapongas' },
  { code: '4101606', name: 'Arapoti' },
  { code: '4101655', name: 'Arapuã' },
  { code: '4101705', name: 'Araruna' },
  { code: '4101804', name: 'Araucária' },
  { code: '4101853', name: 'Ariranha do Ivaí' },
  { code: '4101903', name: 'Assaí' },
  { code: '4102000', name: 'Assis Chateaubriand' },
  { code: '4102109', name: 'Astorga' },
  { code: '4102208', name: 'Atalaia' },
  { code: '4102307', name: 'Balsa Nova' },
  { code: '4102406', name: 'Bandeirantes' },
  { code: '4102505', name: 'Barbosa Ferraz' },
  { code: '4102604', name: 'Barracão' },
  { code: '4102703', name: 'Barra do Jacaré' },
  { code: '4102752', name: 'Bela Vista da Caroba' },
  { code: '4102802', name: 'Bela Vista do Paraíso' },
  { code: '4102901', name: 'Bituruna' },
  { code: '4103008', name: 'Boa Esperança' },
  { code: '4103024', name: 'Boa Esperança do Iguaçu' },
  { code: '4103040', name: 'Boa Ventura de São Roque' },
  { code: '4103057', name: 'Boa Vista da Aparecida' },
  { code: '4103107', name: 'Bocaiúva do Sul' },
  { code: '4103156', name: 'Bom Jesus do Sul' },
  { code: '4103206', name: 'Bom Sucesso' },
  { code: '4103222', name: 'Bom Sucesso do Sul' },
  { code: '4103305', name: 'Borrazópolis' },
  { code: '4103354', name: 'Braganey' },
  { code: '4103370', name: 'Brasilândia do Sul' },
  { code: '4103404', name: 'Cafeara' },
  { code: '4103453', name: 'Cafelândia' },
  { code: '4103479', name: 'Cafezal do Sul' },
  { code: '4103503', name: 'Califórnia' },
  { code: '4103602', name: 'Cambará' },
  { code: '4103701', name: 'Cambé' },
  { code: '4103800', name: 'Cambira' },
  { code: '4103909', name: 'Campina da Lagoa' },
  { code: '4103958', name: 'Campina do Simão' },
  { code: '4104006', name: 'Campina Grande do Sul' },
  { code: '4104055', name: 'Campo Bonito' },
  { code: '4104105', name: 'Campo do Tenente' },
  { code: '4104204', name: 'Campo Largo' },
  { code: '4104253', name: 'Campo Magro' },
  { code: '4104303', name: 'Campo Mourão' },
  { code: '4104402', name: 'Cândido de Abreu' },
  { code: '4104428', name: 'Candói' },
  { code: '4104451', name: 'Cantagalo' },
  { code: '4104501', name: 'Capanema' },
  { code: '4104600', name: 'Capitão Leônidas Marques' },
  { code: '4104659', name: 'Carambeí' },
  { code: '4104709', name: 'Carlópolis' },
  { code: '4104808', name: 'Cascavel' },
  { code: '4104907', name: 'Castro' },
  { code: '4105003', name: 'Catanduvas' },
  { code: '4105102', name: 'Centenário do Sul' },
  { code: '4105201', name: 'Cerro Azul' },
  { code: '4105300', name: 'Céu Azul' },
  { code: '4105409', name: 'Chopinzinho' },
  { code: '4105508', name: 'Cianorte' },
  { code: '4105607', name: 'Cidade Gaúcha' },
  { code: '4105706', name: 'Clevelândia' },
  { code: '4105805', name: 'Colombo' },
  { code: '4105904', name: 'Colorado' },
  { code: '4106001', name: 'Congonhinhas' },
  { code: '4106100', name: 'Conselheiro Mairinck' },
  { code: '4106209', name: 'Contenda' },
  { code: '4106308', name: 'Corbélia' },
  { code: '4106407', name: 'Cornélio Procópio' },
  { code: '4106456', name: 'Coronel Domingos Soares' },
  { code: '4106506', name: 'Coronel Vivida' },
  { code: '4106555', name: 'Corumbataí do Sul' },
  { code: '4106571', name: 'Cruzeiro do Iguaçu' },
  { code: '4106605', name: 'Cruzeiro do Oeste' },
  { code: '4106704', name: 'Cruzeiro do Sul' },
  { code: '4106803', name: 'Cruz Machado' },
  { code: '4106852', name: 'Cruzmaltina' },
  { code: '4106902', name: 'Curitiba' },
  { code: '4107009', name: 'Curiúva' },
  { code: '4107108', name: 'Diamante do Norte' },
  { code: '4107124', name: 'Diamante do Sul' },
  { code: '4107157', name: "Diamante D'Oeste" },
  { code: '4107207', name: 'Dois Vizinhos' },
  { code: '4107256', name: 'Douradina' },
  { code: '4107306', name: 'Doutor Camargo' },
  { code: '4107405', name: 'Enéas Marques' },
  { code: '4107504', name: 'Engenheiro Beltrão' },
  { code: '4107520', name: 'Esperança Nova' },
  { code: '4107538', name: 'Entre Rios do Oeste' },
  { code: '4107546', name: 'Espigão Alto do Iguaçu' },
  { code: '4107553', name: 'Farol' },
  { code: '4107603', name: 'Faxinal' },
  { code: '4107652', name: 'Fazenda Rio Grande' },
  { code: '4107702', name: 'Fênix' },
  { code: '4107736', name: 'Fernandes Pinheiro' },
  { code: '4107751', name: 'Figueira' },
  { code: '4107801', name: 'Floraí' },
  { code: '4107850', name: 'Flor da Serra do Sul' },
  { code: '4107900', name: 'Floresta' },
  { code: '4108007', name: 'Florestópolis' },
  { code: '4108106', name: 'Flórida' },
  { code: '4108205', name: 'Formosa do Oeste' },
  { code: '4108304', name: 'Foz do Iguaçu' },
  { code: '4108320', name: 'Francisco Alves' },
  { code: '4108403', name: 'Francisco Beltrão' },
  { code: '4108452', name: 'Foz do Jordão' },
  { code: '4108502', name: 'General Carneiro' },
  { code: '4108551', name: 'Godoy Moreira' },
  { code: '4108601', name: 'Goioerê' },
  { code: '4108650', name: 'Goioxim' },
  { code: '4108700', name: 'Grandes Rios' },
  { code: '4108809', name: 'Guaíra' },
  { code: '4108908', name: 'Guairaçá' },
  { code: '4108957', name: 'Guamiranga' },
  { code: '4109005', name: 'Guapirama' },
  { code: '4109104', name: 'Guaporema' },
  { code: '4109203', name: 'Guaraci' },
  { code: '4109302', name: 'Guaraniaçu' },
  { code: '4109401', name: 'Guarapuava' },
  { code: '4109500', name: 'Guaraqueçaba' },
  { code: '4109609', name: 'Guaratuba' },
  { code: '4109658', name: 'Honório Serpa' },
  { code: '4109708', name: 'Ibaiti' },
  { code: '4109757', name: 'Ibema' },
  { code: '4109807', name: 'Ibiporã' },
  { code: '4109906', name: 'Icaraíma' },
  { code: '4110003', name: 'Iguaraçu' },
  { code: '4110052', name: 'Iguatu' },
  { code: '4110078', name: 'Imbaú' },
  { code: '4110102', name: 'Imbituva' },
  { code: '4110201', name: 'Inácio Martins' },
  { code: '4110300', name: 'Inajá' },
  { code: '4110409', name: 'Indianópolis' },
  { code: '4110508', name: 'Ipiranga' },
  { code: '4110607', name: 'Iporã' },
  { code: '4110656', name: 'Iracema do Oeste' },
  { code: '4110706', name: 'Irati' },
  { code: '4110805', name: 'Iretama' },
  { code: '4110904', name: 'Itaguajé' },
  { code: '4110953', name: 'Itaipulândia' },
  { code: '4111001', name: 'Itambaracá' },
  { code: '4111100', name: 'Itambé' },
  { code: '4111209', name: "Itapejara d'Oeste" },
  { code: '4111258', name: 'Itaperuçu' },
  { code: '4111308', name: 'Itaúna do Sul' },
  { code: '4111407', name: 'Ivaí' },
  { code: '4111506', name: 'Ivaiporã' },
  { code: '4111555', name: 'Ivaté' },
  { code: '4111605', name: 'Ivatuba' },
  { code: '4111704', name: 'Jaboti' },
  { code: '4111803', name: 'Jacarezinho' },
  { code: '4111902', name: 'Jaguapitã' },
  { code: '4112009', name: 'Jaguariaíva' },
  { code: '4112108', name: 'Jandaia do Sul' },
  { code: '4112207', name: 'Janiópolis' },
  { code: '4112306', name: 'Japira' },
  { code: '4112405', name: 'Japurá' },
  { code: '4112504', name: 'Jardim Alegre' },
  { code: '4112603', name: 'Jardim Olinda' },
  { code: '4112702', name: 'Jataizinho' },
  { code: '4112751', name: 'Jesuítas' },
  { code: '4112801', name: 'Joaquim Távora' },
  { code: '4112900', name: 'Jundiaí do Sul' },
  { code: '4112959', name: 'Juranda' },
  { code: '4113007', name: 'Jussara' },
  { code: '4113106', name: 'Kaloré' },
  { code: '4113205', name: 'Lapa' },
  { code: '4113254', name: 'Laranjal' },
  { code: '4113304', name: 'Laranjeiras do Sul' },
  { code: '4113403', name: 'Leópolis' },
  { code: '4113429', name: 'Lidianópolis' },
  { code: '4113452', name: 'Lindoeste' },
  { code: '4113502', name: 'Loanda' },
  { code: '4113601', name: 'Lobato' },
  { code: '4113700', name: 'Londrina' },
  { code: '4113734', name: 'Luiziana' },
  { code: '4113759', name: 'Lunardelli' },
  { code: '4113809', name: 'Lupionópolis' },
  { code: '4113908', name: 'Mallet' },
  { code: '4114005', name: 'Mamborê' },
  { code: '4114104', name: 'Mandaguaçu' },
  { code: '4114203', name: 'Mandaguari' },
  { code: '4114302', name: 'Mandirituba' },
  { code: '4114351', name: 'Manfrinópolis' },
  { code: '4114401', name: 'Mangueirinha' },
  { code: '4114500', name: 'Manoel Ribas' },
  { code: '4114609', name: 'Marechal Cândido Rondon' },
  { code: '4114708', name: 'Maria Helena' },
  { code: '4114807', name: 'Marialva' },
  { code: '4114906', name: 'Marilândia do Sul' },
  { code: '4115002', name: 'Marilena' },
  { code: '4115101', name: 'Mariluz' },
  { code: '4115200', name: 'Maringá' },
  { code: '4115309', name: 'Mariópolis' },
  { code: '4115358', name: 'Maripá' },
  { code: '4115408', name: 'Marmeleiro' },
  { code: '4115457', name: 'Marquinho' },
  { code: '4115507', name: 'Marumbi' },
  { code: '4115606', name: 'Matelândia' },
  { code: '4115705', name: 'Matinhos' },
  { code: '4115739', name: 'Mato Rico' },
  { code: '4115754', name: 'Mauá da Serra' },
  { code: '4115804', name: 'Medianeira' },
  { code: '4115853', name: 'Mercedes' },
  { code: '4115903', name: 'Mirador' },
  { code: '4116000', name: 'Miraselva' },
  { code: '4116059', name: 'Missal' },
  { code: '4116109', name: 'Moreira Sales' },
  { code: '4116208', name: 'Morretes' },
  { code: '4116307', name: 'Munhoz de Melo' },
  { code: '4116406', name: 'Nossa Senhora das Graças' },
  { code: '4116505', name: 'Nova Aliança do Ivaí' },
  { code: '4116604', name: 'Nova América da Colina' },
  { code: '4116703', name: 'Nova Aurora' },
  { code: '4116802', name: 'Nova Cantu' },
  { code: '4116901', name: 'Nova Esperança' },
  { code: '4116950', name: 'Nova Esperança do Sudoeste' },
  { code: '4117008', name: 'Nova Fátima' },
  { code: '4117057', name: 'Nova Laranjeiras' },
  { code: '4117107', name: 'Nova Londrina' },
  { code: '4117206', name: 'Nova Olímpia' },
  { code: '4117214', name: 'Nova Santa Bárbara' },
  { code: '4117222', name: 'Nova Santa Rosa' },
  { code: '4117255', name: 'Nova Prata do Iguaçu' },
  { code: '4117271', name: 'Nova Tebas' },
  { code: '4117297', name: 'Novo Itacolomi' },
  { code: '4117305', name: 'Ortigueira' },
  { code: '4117404', name: 'Ourizona' },
  { code: '4117453', name: 'Ouro Verde do Oeste' },
  { code: '4117503', name: 'Paiçandu' },
  { code: '4117602', name: 'Palmas' },
  { code: '4117701', name: 'Palmeira' },
  { code: '4117800', name: 'Palmital' },
  { code: '4117909', name: 'Palotina' },
  { code: '4118006', name: 'Paraíso do Norte' },
  { code: '4118105', name: 'Paranacity' },
  { code: '4118204', name: 'Paranaguá' },
  { code: '4118303', name: 'Paranapoema' },
  { code: '4118402', name: 'Paranavaí' },
  { code: '4118451', name: 'Pato Bragado' },
  { code: '4118501', name: 'Pato Branco' },
  { code: '4118600', name: 'Paula Freitas' },
  { code: '4118709', name: 'Paulo Frontin' },
  { code: '4118808', name: 'Peabiru' },
  { code: '4118857', name: 'Perobal' },
  { code: '4118907', name: 'Pérola' },
  { code: '4119004', name: "Pérola d'Oeste" },
  { code: '4119103', name: 'Piên' },
  { code: '4119152', name: 'Pinhais' },
  { code: '4119202', name: 'Pinhalão' },
  { code: '4119251', name: 'Pinhal de São Bento' },
  { code: '4119301', name: 'Pinhão' },
  { code: '4119400', name: 'Piraí do Sul' },
  { code: '4119509', name: 'Piraquara' },
  { code: '4119608', name: 'Pitanga' },
  { code: '4119657', name: 'Pitangueiras' },
  { code: '4119707', name: 'Planaltina do Paraná' },
  { code: '4119806', name: 'Planalto' },
  { code: '4119905', name: 'Ponta Grossa' },
  { code: '4119954', name: 'Pontal do Paraná' },
  { code: '4120002', name: 'Porecatu' },
  { code: '4120101', name: 'Porto Amazonas' },
  { code: '4120150', name: 'Porto Barreiro' },
  { code: '4120200', name: 'Porto Rico' },
  { code: '4120309', name: 'Porto Vitória' },
  { code: '4120333', name: 'Prado Ferreira' },
  { code: '4120358', name: 'Pranchita' },
  { code: '4120408', name: 'Presidente Castelo Branco' },
  { code: '4120507', name: 'Primeiro de Maio' },
  { code: '4120606', name: 'Prudentópolis' },
  { code: '4120655', name: 'Quarto Centenário' },
  { code: '4120705', name: 'Quatiguá' },
  { code: '4120804', name: 'Quatro Barras' },
  { code: '4120853', name: 'Quatro Pontes' },
  { code: '4120903', name: 'Quedas do Iguaçu' },
  { code: '4121000', name: 'Querência do Norte' },
  { code: '4121109', name: 'Quinta do Sol' },
  { code: '4121208', name: 'Quitandinha' },
  { code: '4121257', name: 'Ramilândia' },
  { code: '4121307', name: 'Rancho Alegre' },
  { code: '4121356', name: "Rancho Alegre D'Oeste" },
  { code: '4121406', name: 'Realeza' },
  { code: '4121505', name: 'Rebouças' },
  { code: '4121604', name: 'Renascença' },
  { code: '4121703', name: 'Reserva' },
  { code: '4121752', name: 'Reserva do Iguaçu' },
  { code: '4121802', name: 'Ribeirão Claro' },
  { code: '4121901', name: 'Ribeirão do Pinhal' },
  { code: '4122008', name: 'Rio Azul' },
  { code: '4122107', name: 'Rio Bom' },
  { code: '4122156', name: 'Rio Bonito do Iguaçu' },
  { code: '4122172', name: 'Rio Branco do Ivaí' },
  { code: '4122206', name: 'Rio Branco do Sul' },
  { code: '4122305', name: 'Rio Negro' },
  { code: '4122404', name: 'Rolândia' },
  { code: '4122503', name: 'Roncador' },
  { code: '4122602', name: 'Rondon' },
  { code: '4122651', name: 'Rosário do Ivaí' },
  { code: '4122701', name: 'Sabáudia' },
  { code: '4122800', name: 'Salgado Filho' },
  { code: '4122909', name: 'Salto do Itararé' },
  { code: '4123006', name: 'Salto do Lontra' },
  { code: '4123105', name: 'Santa Amélia' },
  { code: '4123204', name: 'Santa Cecília do Pavão' },
  { code: '4123303', name: 'Santa Cruz de Monte Castelo' },
  { code: '4123402', name: 'Santa Fé' },
  { code: '4123501', name: 'Santa Helena' },
  { code: '4123600', name: 'Santa Inês' },
  { code: '4123709', name: 'Santa Isabel do Ivaí' },
  { code: '4123808', name: 'Santa Izabel do Oeste' },
  { code: '4123824', name: 'Santa Lúcia' },
  { code: '4123857', name: 'Santa Maria do Oeste' },
  { code: '4123907', name: 'Santa Mariana' },
  { code: '4123956', name: 'Santa Mônica' },
  { code: '4124004', name: 'Santana do Itararé' },
  { code: '4124020', name: 'Santa Tereza do Oeste' },
  { code: '4124053', name: 'Santa Terezinha de Itaipu' },
  { code: '4124103', name: 'Santo Antônio da Platina' },
  { code: '4124202', name: 'Santo Antônio do Caiuá' },
  { code: '4124301', name: 'Santo Antônio do Paraíso' },
  { code: '4124400', name: 'Santo Antônio do Sudoeste' },
  { code: '4124509', name: 'Santo Inácio' },
  { code: '4124608', name: 'São Carlos do Ivaí' },
  { code: '4124707', name: 'São Jerônimo da Serra' },
  { code: '4124806', name: 'São João' },
  { code: '4124905', name: 'São João do Caiuá' },
  { code: '4125001', name: 'São João do Ivaí' },
  { code: '4125100', name: 'São João do Triunfo' },
  { code: '4125209', name: "São Jorge d'Oeste" },
  { code: '4125308', name: 'São Jorge do Ivaí' },
  { code: '4125357', name: 'São Jorge do Patrocínio' },
  { code: '4125407', name: 'São José da Boa Vista' },
  { code: '4125456', name: 'São José das Palmeiras' },
  { code: '4125506', name: 'São José dos Pinhais' },
  { code: '4125555', name: 'São Manoel do Paraná' },
  { code: '4125605', name: 'São Mateus do Sul' },
  { code: '4125704', name: 'São Miguel do Iguaçu' },
  { code: '4125753', name: 'São Pedro do Iguaçu' },
  { code: '4125803', name: 'São Pedro do Ivaí' },
  { code: '4125902', name: 'São Pedro do Paraná' },
  { code: '4126009', name: 'São Sebastião da Amoreira' },
  { code: '4126108', name: 'São Tomé' },
  { code: '4126207', name: 'Sapopema' },
  { code: '4126256', name: 'Sarandi' },
  { code: '4126272', name: 'Saudade do Iguaçu' },
  { code: '4126306', name: 'Sengés' },
  { code: '4126355', name: 'Serranópolis do Iguaçu' },
  { code: '4126405', name: 'Sertaneja' },
  { code: '4126504', name: 'Sertanópolis' },
  { code: '4126603', name: 'Siqueira Campos' },
  { code: '4126652', name: 'Sulina' },
  { code: '4126678', name: 'Tamarana' },
  { code: '4126702', name: 'Tamboara' },
  { code: '4126801', name: 'Tapejara' },
  { code: '4126900', name: 'Tapira' },
  { code: '4127007', name: 'Teixeira Soares' },
  { code: '4127106', name: 'Telêmaco Borba' },
  { code: '4127205', name: 'Terra Boa' },
  { code: '4127304', name: 'Terra Rica' },
  { code: '4127403', name: 'Terra Roxa' },
  { code: '4127502', name: 'Tibagi' },
  { code: '4127601', name: 'Tijucas do Sul' },
  { code: '4127700', name: 'Toledo' },
  { code: '4127809', name: 'Tomazina' },
  { code: '4127858', name: 'Três Barras do Paraná' },
  { code: '4127882', name: 'Tunas do Paraná' },
  { code: '4127908', name: 'Tuneiras do Oeste' },
  { code: '4127957', name: 'Tupãssi' },
  { code: '4127965', name: 'Turvo' },
  { code: '4128005', name: 'Ubiratã' },
  { code: '4128104', name: 'Umuarama' },
  { code: '4128203', name: 'União da Vitória' },
  { code: '4128302', name: 'Uniflor' },
  { code: '4128401', name: 'Uraí' },
  { code: '4128500', name: 'Wenceslau Braz' },
  { code: '4128534', name: 'Ventania' },
  { code: '4128559', name: 'Vera Cruz do Oeste' },
  { code: '4128609', name: 'Verê' },
  { code: '4128625', name: 'Alto Paraíso' },
  { code: '4128633', name: 'Doutor Ulysses' },
  { code: '4128658', name: 'Virmond' },
  { code: '4128708', name: 'Vitorino' },
  { code: '4128807', name: 'Xambrê' },
  { code: '4200051', name: 'Abdon Batista' },
  { code: '4200101', name: 'Abelardo Luz' },
  { code: '4200200', name: 'Agrolândia' },
  { code: '4200309', name: 'Agronômica' },
  { code: '4200408', name: 'Água Doce' },
  { code: '4200507', name: 'Águas de Chapecó' },
  { code: '4200556', name: 'Águas Frias' },
  { code: '4200606', name: 'Águas Mornas' },
  { code: '4200705', name: 'Alfredo Wagner' },
  { code: '4200754', name: 'Alto Bela Vista' },
  { code: '4200804', name: 'Anchieta' },
  { code: '4200903', name: 'Angelina' },
  { code: '4201000', name: 'Anita Garibaldi' },
  { code: '4201109', name: 'Anitápolis' },
  { code: '4201208', name: 'Antônio Carlos' },
  { code: '4201257', name: 'Apiúna' },
  { code: '4201273', name: 'Arabutã' },
  { code: '4201307', name: 'Araquari' },
  { code: '4201406', name: 'Araranguá' },
  { code: '4201505', name: 'Armazém' },
  { code: '4201604', name: 'Arroio Trinta' },
  { code: '4201653', name: 'Arvoredo' },
  { code: '4201703', name: 'Ascurra' },
  { code: '4201802', name: 'Atalanta' },
  { code: '4201901', name: 'Aurora' },
  { code: '4201950', name: 'Balneário Arroio do Silva' },
  { code: '4202008', name: 'Balneário Camboriú' },
  { code: '4202057', name: 'Balneário Barra do Sul' },
  { code: '4202073', name: 'Balneário Gaivota' },
  { code: '4202081', name: 'Bandeirante' },
  { code: '4202099', name: 'Barra Bonita' },
  { code: '4202107', name: 'Barra Velha' },
  { code: '4202131', name: 'Bela Vista do Toldo' },
  { code: '4202156', name: 'Belmonte' },
  { code: '4202206', name: 'Benedito Novo' },
  { code: '4202305', name: 'Biguaçu' },
  { code: '4202404', name: 'Blumenau' },
  { code: '4202438', name: 'Bocaina do Sul' },
  { code: '4202453', name: 'Bombinhas' },
  { code: '4202503', name: 'Bom Jardim da Serra' },
  { code: '4202537', name: 'Bom Jesus' },
  { code: '4202578', name: 'Bom Jesus do Oeste' },
  { code: '4202602', name: 'Bom Retiro' },
  { code: '4202701', name: 'Botuverá' },
  { code: '4202800', name: 'Braço do Norte' },
  { code: '4202859', name: 'Braço do Trombudo' },
  { code: '4202875', name: 'Brunópolis' },
  { code: '4202909', name: 'Brusque' },
  { code: '4203006', name: 'Caçador' },
  { code: '4203105', name: 'Caibi' },
  { code: '4203154', name: 'Calmon' },
  { code: '4203204', name: 'Camboriú' },
  { code: '4203253', name: 'Capão Alto' },
  { code: '4203303', name: 'Campo Alegre' },
  { code: '4203402', name: 'Campo Belo do Sul' },
  { code: '4203501', name: 'Campo Erê' },
  { code: '4203600', name: 'Campos Novos' },
  { code: '4203709', name: 'Canelinha' },
  { code: '4203808', name: 'Canoinhas' },
  { code: '4203907', name: 'Capinzal' },
  { code: '4203956', name: 'Capivari de Baixo' },
  { code: '4204004', name: 'Catanduvas' },
  { code: '4204103', name: 'Caxambu do Sul' },
  { code: '4204152', name: 'Celso Ramos' },
  { code: '4204178', name: 'Cerro Negro' },
  { code: '4204194', name: 'Chapadão do Lageado' },
  { code: '4204202', name: 'Chapecó' },
  { code: '4204251', name: 'Cocal do Sul' },
  { code: '4204301', name: 'Concórdia' },
  { code: '4204350', name: 'Cordilheira Alta' },
  { code: '4204400', name: 'Coronel Freitas' },
  { code: '4204459', name: 'Coronel Martins' },
  { code: '4204509', name: 'Corupá' },
  { code: '4204558', name: 'Correia Pinto' },
  { code: '4204608', name: 'Criciúma' },
  { code: '4204707', name: 'Cunha Porã' },
  { code: '4204756', name: 'Cunhataí' },
  { code: '4204806', name: 'Curitibanos' },
  { code: '4204905', name: 'Descanso' },
  { code: '4205001', name: 'Dionísio Cerqueira' },
  { code: '4205100', name: 'Dona Emma' },
  { code: '4205159', name: 'Doutor Pedrinho' },
  { code: '4205175', name: 'Entre Rios' },
  { code: '4205191', name: 'Ermo' },
  { code: '4205209', name: 'Erval Velho' },
  { code: '4205308', name: 'Faxinal dos Guedes' },
  { code: '4205357', name: 'Flor do Sertão' },
  { code: '4205407', name: 'Florianópolis' },
  { code: '4205431', name: 'Formosa do Sul' },
  { code: '4205456', name: 'Forquilhinha' },
  { code: '4205506', name: 'Fraiburgo' },
  { code: '4205555', name: 'Frei Rogério' },
  { code: '4205605', name: 'Galvão' },
  { code: '4205704', name: 'Garopaba' },
  { code: '4205803', name: 'Garuva' },
  { code: '4205902', name: 'Gaspar' },
  { code: '4206009', name: 'Governador Celso Ramos' },
  { code: '4206108', name: 'Grão Pará' },
  { code: '4206207', name: 'Gravatal' },
  { code: '4206306', name: 'Guabiruba' },
  { code: '4206405', name: 'Guaraciaba' },
  { code: '4206504', name: 'Guaramirim' },
  { code: '4206603', name: 'Guarujá do Sul' },
  { code: '4206652', name: 'Guatambú' },
  { code: '4206702', name: "Herval d'Oeste" },
  { code: '4206751', name: 'Ibiam' },
  { code: '4206801', name: 'Ibicaré' },
  { code: '4206900', name: 'Ibirama' },
  { code: '4207007', name: 'Içara' },
  { code: '4207106', name: 'Ilhota' },
  { code: '4207205', name: 'Imaruí' },
  { code: '4207304', name: 'Imbituba' },
  { code: '4207403', name: 'Imbuia' },
  { code: '4207502', name: 'Indaial' },
  { code: '4207577', name: 'Iomerê' },
  { code: '4207601', name: 'Ipira' },
  { code: '4207650', name: 'Iporã do Oeste' },
  { code: '4207684', name: 'Ipuaçu' },
  { code: '4207700', name: 'Ipumirim' },
  { code: '4207759', name: 'Iraceminha' },
  { code: '4207809', name: 'Irani' },
  { code: '4207858', name: 'Irati' },
  { code: '4207908', name: 'Irineópolis' },
  { code: '4208005', name: 'Itá' },
  { code: '4208104', name: 'Itaiópolis' },
  { code: '4208203', name: 'Itajaí' },
  { code: '4208302', name: 'Itapema' },
  { code: '4208401', name: 'Itapiranga' },
  { code: '4208450', name: 'Itapoá' },
  { code: '4208500', name: 'Ituporanga' },
  { code: '4208609', name: 'Jaborá' },
  { code: '4208708', name: 'Jacinto Machado' },
  { code: '4208807', name: 'Jaguaruna' },
  { code: '4208906', name: 'Jaraguá do Sul' },
  { code: '4208955', name: 'Jardinópolis' },
  { code: '4209003', name: 'Joaçaba' },
  { code: '4209102', name: 'Joinville' },
  { code: '4209151', name: 'José Boiteux' },
  { code: '4209177', name: 'Jupiá' },
  { code: '4209201', name: 'Lacerdópolis' },
  { code: '4209300', name: 'Lages' },
  { code: '4209409', name: 'Laguna' },
  { code: '4209458', name: 'Lajeado Grande' },
  { code: '4209508', name: 'Laurentino' },
  { code: '4209607', name: 'Lauro Muller' },
  { code: '4209706', name: 'Lebon Régis' },
  { code: '4209805', name: 'Leoberto Leal' },
  { code: '4209854', name: 'Lindóia do Sul' },
  { code: '4209904', name: 'Lontras' },
  { code: '4210001', name: 'Luiz Alves' },
  { code: '4210035', name: 'Luzerna' },
  { code: '4210050', name: 'Macieira' },
  { code: '4210100', name: 'Mafra' },
  { code: '4210209', name: 'Major Gercino' },
  { code: '4210308', name: 'Major Vieira' },
  { code: '4210407', name: 'Maracajá' },
  { code: '4210506', name: 'Maravilha' },
  { code: '4210555', name: 'Marema' },
  { code: '4210605', name: 'Massaranduba' },
  { code: '4210704', name: 'Matos Costa' },
  { code: '4210803', name: 'Meleiro' },
  { code: '4210852', name: 'Mirim Doce' },
  { code: '4210902', name: 'Modelo' },
  { code: '4211009', name: 'Mondaí' },
  { code: '4211058', name: 'Monte Carlo' },
  { code: '4211108', name: 'Monte Castelo' },
  { code: '4211207', name: 'Morro da Fumaça' },
  { code: '4211256', name: 'Morro Grande' },
  { code: '4211306', name: 'Navegantes' },
  { code: '4211405', name: 'Nova Erechim' },
  { code: '4211454', name: 'Nova Itaberaba' },
  { code: '4211504', name: 'Nova Trento' },
  { code: '4211603', name: 'Nova Veneza' },
  { code: '4211652', name: 'Novo Horizonte' },
  { code: '4211702', name: 'Orleans' },
  { code: '4211751', name: 'Otacílio Costa' },
  { code: '4211801', name: 'Ouro' },
  { code: '4211850', name: 'Ouro Verde' },
  { code: '4211876', name: 'Paial' },
  { code: '4211892', name: 'Painel' },
  { code: '4211900', name: 'Palhoça' },
  { code: '4212007', name: 'Palma Sola' },
  { code: '4212056', name: 'Palmeira' },
  { code: '4212106', name: 'Palmitos' },
  { code: '4212205', name: 'Papanduva' },
  { code: '4212239', name: 'Paraíso' },
  { code: '4212254', name: 'Passo de Torres' },
  { code: '4212270', name: 'Passos Maia' },
  { code: '4212304', name: 'Paulo Lopes' },
  { code: '4212403', name: 'Pedras Grandes' },
  { code: '4212502', name: 'Penha' },
  { code: '4212601', name: 'Peritiba' },
  { code: '4212650', name: 'Pescaria Brava' },
  { code: '4212700', name: 'Petrolândia' },
  { code: '4212809', name: 'Balneário Piçarras' },
  { code: '4212908', name: 'Pinhalzinho' },
  { code: '4213005', name: 'Pinheiro Preto' },
  { code: '4213104', name: 'Piratuba' },
  { code: '4213153', name: 'Planalto Alegre' },
  { code: '4213203', name: 'Pomerode' },
  { code: '4213302', name: 'Ponte Alta' },
  { code: '4213351', name: 'Ponte Alta do Norte' },
  { code: '4213401', name: 'Ponte Serrada' },
  { code: '4213500', name: 'Porto Belo' },
  { code: '4213609', name: 'Porto União' },
  { code: '4213708', name: 'Pouso Redondo' },
  { code: '4213807', name: 'Praia Grande' },
  { code: '4213906', name: 'Presidente Castello Branco' },
  { code: '4214003', name: 'Presidente Getúlio' },
  { code: '4214102', name: 'Presidente Nereu' },
  { code: '4214151', name: 'Princesa' },
  { code: '4214201', name: 'Quilombo' },
  { code: '4214300', name: 'Rancho Queimado' },
  { code: '4214409', name: 'Rio das Antas' },
  { code: '4214508', name: 'Rio do Campo' },
  { code: '4214607', name: 'Rio do Oeste' },
  { code: '4214706', name: 'Rio dos Cedros' },
  { code: '4214805', name: 'Rio do Sul' },
  { code: '4214904', name: 'Rio Fortuna' },
  { code: '4215000', name: 'Rio Negrinho' },
  { code: '4215059', name: 'Rio Rufino' },
  { code: '4215075', name: 'Riqueza' },
  { code: '4215109', name: 'Rodeio' },
  { code: '4215208', name: 'Romelândia' },
  { code: '4215307', name: 'Salete' },
  { code: '4215356', name: 'Saltinho' },
  { code: '4215406', name: 'Salto Veloso' },
  { code: '4215455', name: 'Sangão' },
  { code: '4215505', name: 'Santa Cecília' },
  { code: '4215554', name: 'Santa Helena' },
  { code: '4215604', name: 'Santa Rosa de Lima' },
  { code: '4215653', name: 'Santa Rosa do Sul' },
  { code: '4215679', name: 'Santa Terezinha' },
  { code: '4215687', name: 'Santa Terezinha do Progresso' },
  { code: '4215695', name: 'Santiago do Sul' },
  { code: '4215703', name: 'Santo Amaro da Imperatriz' },
  { code: '4215752', name: 'São Bernardino' },
  { code: '4215802', name: 'São Bento do Sul' },
  { code: '4215901', name: 'São Bonifácio' },
  { code: '4216008', name: 'São Carlos' },
  { code: '4216057', name: 'São Cristovão do Sul' },
  { code: '4216107', name: 'São Domingos' },
  { code: '4216206', name: 'São Francisco do Sul' },
  { code: '4216255', name: 'São João do Oeste' },
  { code: '4216305', name: 'São João Batista' },
  { code: '4216354', name: 'São João do Itaperiú' },
  { code: '4216404', name: 'São João do Sul' },
  { code: '4216503', name: 'São Joaquim' },
  { code: '4216602', name: 'São José' },
  { code: '4216701', name: 'São José do Cedro' },
  { code: '4216800', name: 'São José do Cerrito' },
  { code: '4216909', name: 'São Lourenço do Oeste' },
  { code: '4217006', name: 'São Ludgero' },
  { code: '4217105', name: 'São Martinho' },
  { code: '4217154', name: 'São Miguel da Boa Vista' },
  { code: '4217204', name: 'São Miguel do Oeste' },
  { code: '4217253', name: 'São Pedro de Alcântara' },
  { code: '4217303', name: 'Saudades' },
  { code: '4217402', name: 'Schroeder' },
  { code: '4217501', name: 'Seara' },
  { code: '4217550', name: 'Serra Alta' },
  { code: '4217600', name: 'Siderópolis' },
  { code: '4217709', name: 'Sombrio' },
  { code: '4217758', name: 'Sul Brasil' },
  { code: '4217808', name: 'Taió' },
  { code: '4217907', name: 'Tangará' },
  { code: '4217956', name: 'Tigrinhos' },
  { code: '4218004', name: 'Tijucas' },
  { code: '4218103', name: 'Timbé do Sul' },
  { code: '4218202', name: 'Timbó' },
  { code: '4218251', name: 'Timbó Grande' },
  { code: '4218301', name: 'Três Barras' },
  { code: '4218350', name: 'Treviso' },
  { code: '4218400', name: 'Treze de Maio' },
  { code: '4218509', name: 'Treze Tílias' },
  { code: '4218608', name: 'Trombudo Central' },
  { code: '4218707', name: 'Tubarão' },
  { code: '4218756', name: 'Tunápolis' },
  { code: '4218806', name: 'Turvo' },
  { code: '4218855', name: 'União do Oeste' },
  { code: '4218905', name: 'Urubici' },
  { code: '4218954', name: 'Urupema' },
  { code: '4219002', name: 'Urussanga' },
  { code: '4219101', name: 'Vargeão' },
  { code: '4219150', name: 'Vargem' },
  { code: '4219176', name: 'Vargem Bonita' },
  { code: '4219200', name: 'Vidal Ramos' },
  { code: '4219309', name: 'Videira' },
  { code: '4219358', name: 'Vitor Meireles' },
  { code: '4219408', name: 'Witmarsum' },
  { code: '4219507', name: 'Xanxerê' },
  { code: '4219606', name: 'Xavantina' },
  { code: '4219705', name: 'Xaxim' },
  { code: '4219853', name: 'Zortéa' },
  { code: '4220000', name: 'Balneário Rincão' },
  { code: '4300034', name: 'Aceguá' },
  { code: '4300059', name: 'Água Santa' },
  { code: '4300109', name: 'Agudo' },
  { code: '4300208', name: 'Ajuricaba' },
  { code: '4300307', name: 'Alecrim' },
  { code: '4300406', name: 'Alegrete' },
  { code: '4300455', name: 'Alegria' },
  { code: '4300471', name: 'Almirante Tamandaré do Sul' },
  { code: '4300505', name: 'Alpestre' },
  { code: '4300554', name: 'Alto Alegre' },
  { code: '4300570', name: 'Alto Feliz' },
  { code: '4300604', name: 'Alvorada' },
  { code: '4300638', name: 'Amaral Ferrador' },
  { code: '4300646', name: 'Ametista do Sul' },
  { code: '4300661', name: 'André da Rocha' },
  { code: '4300703', name: 'Anta Gorda' },
  { code: '4300802', name: 'Antônio Prado' },
  { code: '4300851', name: 'Arambaré' },
  { code: '4300877', name: 'Araricá' },
  { code: '4300901', name: 'Aratiba' },
  { code: '4301008', name: 'Arroio do Meio' },
  { code: '4301057', name: 'Arroio do Sal' },
  { code: '4301073', name: 'Arroio do Padre' },
  { code: '4301107', name: 'Arroio dos Ratos' },
  { code: '4301206', name: 'Arroio do Tigre' },
  { code: '4301305', name: 'Arroio Grande' },
  { code: '4301404', name: 'Arvorezinha' },
  { code: '4301503', name: 'Augusto Pestana' },
  { code: '4301552', name: 'Áurea' },
  { code: '4301602', name: 'Bagé' },
  { code: '4301636', name: 'Balneário Pinhal' },
  { code: '4301651', name: 'Barão' },
  { code: '4301701', name: 'Barão de Cotegipe' },
  { code: '4301750', name: 'Barão do Triunfo' },
  { code: '4301800', name: 'Barracão' },
  { code: '4301859', name: 'Barra do Guarita' },
  { code: '4301875', name: 'Barra do Quaraí' },
  { code: '4301909', name: 'Barra do Ribeiro' },
  { code: '4301925', name: 'Barra do Rio Azul' },
  { code: '4301958', name: 'Barra Funda' },
  { code: '4302006', name: 'Barros Cassal' },
  { code: '4302055', name: 'Benjamin Constant do Sul' },
  { code: '4302105', name: 'Bento Gonçalves' },
  { code: '4302154', name: 'Boa Vista das Missões' },
  { code: '4302204', name: 'Boa Vista do Buricá' },
  { code: '4302220', name: 'Boa Vista do Cadeado' },
  { code: '4302238', name: 'Boa Vista do Incra' },
  { code: '4302253', name: 'Boa Vista do Sul' },
  { code: '4302303', name: 'Bom Jesus' },
  { code: '4302352', name: 'Bom Princípio' },
  { code: '4302378', name: 'Bom Progresso' },
  { code: '4302402', name: 'Bom Retiro do Sul' },
  { code: '4302451', name: 'Boqueirão do Leão' },
  { code: '4302501', name: 'Bossoroca' },
  { code: '4302584', name: 'Bozano' },
  { code: '4302600', name: 'Braga' },
  { code: '4302659', name: 'Brochier' },
  { code: '4302709', name: 'Butiá' },
  { code: '4302808', name: 'Caçapava do Sul' },
  { code: '4302907', name: 'Cacequi' },
  { code: '4303004', name: 'Cachoeira do Sul' },
  { code: '4303103', name: 'Cachoeirinha' },
  { code: '4303202', name: 'Cacique Doble' },
  { code: '4303301', name: 'Caibaté' },
  { code: '4303400', name: 'Caiçara' },
  { code: '4303509', name: 'Camaquã' },
  { code: '4303558', name: 'Camargo' },
  { code: '4303608', name: 'Cambará do Sul' },
  { code: '4303673', name: 'Campestre da Serra' },
  { code: '4303707', name: 'Campina das Missões' },
  { code: '4303806', name: 'Campinas do Sul' },
  { code: '4303905', name: 'Campo Bom' },
  { code: '4304002', name: 'Campo Novo' },
  { code: '4304101', name: 'Campos Borges' },
  { code: '4304200', name: 'Candelária' },
  { code: '4304309', name: 'Cândido Godói' },
  { code: '4304358', name: 'Candiota' },
  { code: '4304408', name: 'Canela' },
  { code: '4304507', name: 'Canguçu' },
  { code: '4304606', name: 'Canoas' },
  { code: '4304614', name: 'Canudos do Vale' },
  { code: '4304622', name: 'Capão Bonito do Sul' },
  { code: '4304630', name: 'Capão da Canoa' },
  { code: '4304655', name: 'Capão do Cipó' },
  { code: '4304663', name: 'Capão do Leão' },
  { code: '4304671', name: 'Capivari do Sul' },
  { code: '4304689', name: 'Capela de Santana' },
  { code: '4304697', name: 'Capitão' },
  { code: '4304705', name: 'Carazinho' },
  { code: '4304713', name: 'Caraá' },
  { code: '4304804', name: 'Carlos Barbosa' },
  { code: '4304853', name: 'Carlos Gomes' },
  { code: '4304903', name: 'Casca' },
  { code: '4304952', name: 'Caseiros' },
  { code: '4305009', name: 'Catuípe' },
  { code: '4305108', name: 'Caxias do Sul' },
  { code: '4305116', name: 'Centenário' },
  { code: '4305124', name: 'Cerrito' },
  { code: '4305132', name: 'Cerro Branco' },
  { code: '4305157', name: 'Cerro Grande' },
  { code: '4305173', name: 'Cerro Grande do Sul' },
  { code: '4305207', name: 'Cerro Largo' },
  { code: '4305306', name: 'Chapada' },
  { code: '4305355', name: 'Charqueadas' },
  { code: '4305371', name: 'Charrua' },
  { code: '4305405', name: 'Chiapetta' },
  { code: '4305439', name: 'Chuí' },
  { code: '4305447', name: 'Chuvisca' },
  { code: '4305454', name: 'Cidreira' },
  { code: '4305504', name: 'Ciríaco' },
  { code: '4305587', name: 'Colinas' },
  { code: '4305603', name: 'Colorado' },
  { code: '4305702', name: 'Condor' },
  { code: '4305801', name: 'Constantina' },
  { code: '4305835', name: 'Coqueiro Baixo' },
  { code: '4305850', name: 'Coqueiros do Sul' },
  { code: '4305871', name: 'Coronel Barros' },
  { code: '4305900', name: 'Coronel Bicaco' },
  { code: '4305934', name: 'Coronel Pilar' },
  { code: '4305959', name: 'Cotiporã' },
  { code: '4305975', name: 'Coxilha' },
  { code: '4306007', name: 'Crissiumal' },
  { code: '4306056', name: 'Cristal' },
  { code: '4306072', name: 'Cristal do Sul' },
  { code: '4306106', name: 'Cruz Alta' },
  { code: '4306130', name: 'Cruzaltense' },
  { code: '4306205', name: 'Cruzeiro do Sul' },
  { code: '4306304', name: 'David Canabarro' },
  { code: '4306320', name: 'Derrubadas' },
  { code: '4306353', name: 'Dezesseis de Novembro' },
  { code: '4306379', name: 'Dilermando de Aguiar' },
  { code: '4306403', name: 'Dois Irmãos' },
  { code: '4306429', name: 'Dois Irmãos das Missões' },
  { code: '4306452', name: 'Dois Lajeados' },
  { code: '4306502', name: 'Dom Feliciano' },
  { code: '4306551', name: 'Dom Pedro de Alcântara' },
  { code: '4306601', name: 'Dom Pedrito' },
  { code: '4306700', name: 'Dona Francisca' },
  { code: '4306734', name: 'Doutor Maurício Cardoso' },
  { code: '4306759', name: 'Doutor Ricardo' },
  { code: '4306767', name: 'Eldorado do Sul' },
  { code: '4306809', name: 'Encantado' },
  { code: '4306908', name: 'Encruzilhada do Sul' },
  { code: '4306924', name: 'Engenho Velho' },
  { code: '4306932', name: 'Entre-Ijuís' },
  { code: '4306957', name: 'Entre Rios do Sul' },
  { code: '4306973', name: 'Erebango' },
  { code: '4307005', name: 'Erechim' },
  { code: '4307054', name: 'Ernestina' },
  { code: '4307104', name: 'Herval' },
  { code: '4307203', name: 'Erval Grande' },
  { code: '4307302', name: 'Erval Seco' },
  { code: '4307401', name: 'Esmeralda' },
  { code: '4307450', name: 'Esperança do Sul' },
  { code: '4307500', name: 'Espumoso' },
  { code: '4307559', name: 'Estação' },
  { code: '4307609', name: 'Estância Velha' },
  { code: '4307708', name: 'Esteio' },
  { code: '4307807', name: 'Estrela' },
  { code: '4307815', name: 'Estrela Velha' },
  { code: '4307831', name: 'Eugênio de Castro' },
  { code: '4307864', name: 'Fagundes Varela' },
  { code: '4307906', name: 'Farroupilha' },
  { code: '4308003', name: 'Faxinal do Soturno' },
  { code: '4308052', name: 'Faxinalzinho' },
  { code: '4308078', name: 'Fazenda Vilanova' },
  { code: '4308102', name: 'Feliz' },
  { code: '4308201', name: 'Flores da Cunha' },
  { code: '4308250', name: 'Floriano Peixoto' },
  { code: '4308300', name: 'Fontoura Xavier' },
  { code: '4308409', name: 'Formigueiro' },
  { code: '4308433', name: 'Forquetinha' },
  { code: '4308458', name: 'Fortaleza dos Valos' },
  { code: '4308508', name: 'Frederico Westphalen' },
  { code: '4308607', name: 'Garibaldi' },
  { code: '4308656', name: 'Garruchos' },
  { code: '4308706', name: 'Gaurama' },
  { code: '4308805', name: 'General Câmara' },
  { code: '4308854', name: 'Gentil' },
  { code: '4308904', name: 'Getúlio Vargas' },
  { code: '4309001', name: 'Giruá' },
  { code: '4309050', name: 'Glorinha' },
  { code: '4309100', name: 'Gramado' },
  { code: '4309126', name: 'Gramado dos Loureiros' },
  { code: '4309159', name: 'Gramado Xavier' },
  { code: '4309209', name: 'Gravataí' },
  { code: '4309258', name: 'Guabiju' },
  { code: '4309308', name: 'Guaíba' },
  { code: '4309407', name: 'Guaporé' },
  { code: '4309506', name: 'Guarani das Missões' },
  { code: '4309555', name: 'Harmonia' },
  { code: '4309571', name: 'Herveiras' },
  { code: '4309605', name: 'Horizontina' },
  { code: '4309654', name: 'Hulha Negra' },
  { code: '4309704', name: 'Humaitá' },
  { code: '4309753', name: 'Ibarama' },
  { code: '4309803', name: 'Ibiaçá' },
  { code: '4309902', name: 'Ibiraiaras' },
  { code: '4309951', name: 'Ibirapuitã' },
  { code: '4310009', name: 'Ibirubá' },
  { code: '4310108', name: 'Igrejinha' },
  { code: '4310207', name: 'Ijuí' },
  { code: '4310306', name: 'Ilópolis' },
  { code: '4310330', name: 'Imbé' },
  { code: '4310363', name: 'Imigrante' },
  { code: '4310405', name: 'Independência' },
  { code: '4310413', name: 'Inhacorá' },
  { code: '4310439', name: 'Ipê' },
  { code: '4310462', name: 'Ipiranga do Sul' },
  { code: '4310504', name: 'Iraí' },
  { code: '4310538', name: 'Itaara' },
  { code: '4310553', name: 'Itacurubi' },
  { code: '4310579', name: 'Itapuca' },
  { code: '4310603', name: 'Itaqui' },
  { code: '4310652', name: 'Itati' },
  { code: '4310702', name: 'Itatiba do Sul' },
  { code: '4310751', name: 'Ivorá' },
  { code: '4310801', name: 'Ivoti' },
  { code: '4310850', name: 'Jaboticaba' },
  { code: '4310876', name: 'Jacuizinho' },
  { code: '4310900', name: 'Jacutinga' },
  { code: '4311007', name: 'Jaguarão' },
  { code: '4311106', name: 'Jaguari' },
  { code: '4311122', name: 'Jaquirana' },
  { code: '4311130', name: 'Jari' },
  { code: '4311155', name: 'Jóia' },
  { code: '4311205', name: 'Júlio de Castilhos' },
  { code: '4311239', name: 'Lagoa Bonita do Sul' },
  { code: '4311254', name: 'Lagoão' },
  { code: '4311270', name: 'Lagoa dos Três Cantos' },
  { code: '4311304', name: 'Lagoa Vermelha' },
  { code: '4311403', name: 'Lajeado' },
  { code: '4311429', name: 'Lajeado do Bugre' },
  { code: '4311502', name: 'Lavras do Sul' },
  { code: '4311601', name: 'Liberato Salzano' },
  { code: '4311627', name: 'Lindolfo Collor' },
  { code: '4311643', name: 'Linha Nova' },
  { code: '4311700', name: 'Machadinho' },
  { code: '4311718', name: 'Maçambara' },
  { code: '4311734', name: 'Mampituba' },
  { code: '4311759', name: 'Manoel Viana' },
  { code: '4311775', name: 'Maquiné' },
  { code: '4311791', name: 'Maratá' },
  { code: '4311809', name: 'Marau' },
  { code: '4311908', name: 'Marcelino Ramos' },
  { code: '4311981', name: 'Mariana Pimentel' },
  { code: '4312005', name: 'Mariano Moro' },
  { code: '4312054', name: 'Marques de Souza' },
  { code: '4312104', name: 'Mata' },
  { code: '4312138', name: 'Mato Castelhano' },
  { code: '4312153', name: 'Mato Leitão' },
  { code: '4312179', name: 'Mato Queimado' },
  { code: '4312203', name: 'Maximiliano de Almeida' },
  { code: '4312252', name: 'Minas do Leão' },
  { code: '4312302', name: 'Miraguaí' },
  { code: '4312351', name: 'Montauri' },
  { code: '4312377', name: 'Monte Alegre dos Campos' },
  { code: '4312385', name: 'Monte Belo do Sul' },
  { code: '4312401', name: 'Montenegro' },
  { code: '4312427', name: 'Mormaço' },
  { code: '4312443', name: 'Morrinhos do Sul' },
  { code: '4312450', name: 'Morro Redondo' },
  { code: '4312476', name: 'Morro Reuter' },
  { code: '4312500', name: 'Mostardas' },
  { code: '4312609', name: 'Muçum' },
  { code: '4312617', name: 'Muitos Capões' },
  { code: '4312625', name: 'Muliterno' },
  { code: '4312658', name: 'Não-Me-Toque' },
  { code: '4312674', name: 'Nicolau Vergueiro' },
  { code: '4312708', name: 'Nonoai' },
  { code: '4312757', name: 'Nova Alvorada' },
  { code: '4312807', name: 'Nova Araçá' },
  { code: '4312906', name: 'Nova Bassano' },
  { code: '4312955', name: 'Nova Boa Vista' },
  { code: '4313003', name: 'Nova Bréscia' },
  { code: '4313011', name: 'Nova Candelária' },
  { code: '4313037', name: 'Nova Esperança do Sul' },
  { code: '4313060', name: 'Nova Hartz' },
  { code: '4313086', name: 'Nova Pádua' },
  { code: '4313102', name: 'Nova Palma' },
  { code: '4313201', name: 'Nova Petrópolis' },
  { code: '4313300', name: 'Nova Prata' },
  { code: '4313334', name: 'Nova Ramada' },
  { code: '4313359', name: 'Nova Roma do Sul' },
  { code: '4313375', name: 'Nova Santa Rita' },
  { code: '4313391', name: 'Novo Cabrais' },
  { code: '4313409', name: 'Novo Hamburgo' },
  { code: '4313425', name: 'Novo Machado' },
  { code: '4313441', name: 'Novo Tiradentes' },
  { code: '4313466', name: 'Novo Xingu' },
  { code: '4313490', name: 'Novo Barreiro' },
  { code: '4313508', name: 'Osório' },
  { code: '4313607', name: 'Paim Filho' },
  { code: '4313656', name: 'Palmares do Sul' },
  { code: '4313706', name: 'Palmeira das Missões' },
  { code: '4313805', name: 'Palmitinho' },
  { code: '4313904', name: 'Panambi' },
  { code: '4313953', name: 'Pantano Grande' },
  { code: '4314001', name: 'Paraí' },
  { code: '4314027', name: 'Paraíso do Sul' },
  { code: '4314035', name: 'Pareci Novo' },
  { code: '4314050', name: 'Parobé' },
  { code: '4314068', name: 'Passa Sete' },
  { code: '4314076', name: 'Passo do Sobrado' },
  { code: '4314100', name: 'Passo Fundo' },
  { code: '4314134', name: 'Paulo Bento' },
  { code: '4314159', name: 'Paverama' },
  { code: '4314175', name: 'Pedras Altas' },
  { code: '4314209', name: 'Pedro Osório' },
  { code: '4314308', name: 'Pejuçara' },
  { code: '4314407', name: 'Pelotas' },
  { code: '4314423', name: 'Picada Café' },
  { code: '4314456', name: 'Pinhal' },
  { code: '4314464', name: 'Pinhal da Serra' },
  { code: '4314472', name: 'Pinhal Grande' },
  { code: '4314498', name: 'Pinheirinho do Vale' },
  { code: '4314506', name: 'Pinheiro Machado' },
  { code: '4314548', name: 'Pinto Bandeira' },
  { code: '4314555', name: 'Pirapó' },
  { code: '4314605', name: 'Piratini' },
  { code: '4314704', name: 'Planalto' },
  { code: '4314753', name: 'Poço das Antas' },
  { code: '4314779', name: 'Pontão' },
  { code: '4314787', name: 'Ponte Preta' },
  { code: '4314803', name: 'Portão' },
  { code: '4314902', name: 'Porto Alegre' },
  { code: '4315008', name: 'Porto Lucena' },
  { code: '4315057', name: 'Porto Mauá' },
  { code: '4315073', name: 'Porto Vera Cruz' },
  { code: '4315107', name: 'Porto Xavier' },
  { code: '4315131', name: 'Pouso Novo' },
  { code: '4315149', name: 'Presidente Lucena' },
  { code: '4315156', name: 'Progresso' },
  { code: '4315172', name: 'Protásio Alves' },
  { code: '4315206', name: 'Putinga' },
  { code: '4315305', name: 'Quaraí' },
  { code: '4315313', name: 'Quatro Irmãos' },
  { code: '4315321', name: 'Quevedos' },
  { code: '4315354', name: 'Quinze de Novembro' },
  { code: '4315404', name: 'Redentora' },
  { code: '4315453', name: 'Relvado' },
  { code: '4315503', name: 'Restinga Seca' },
  { code: '4315552', name: 'Rio dos Índios' },
  { code: '4315602', name: 'Rio Grande' },
  { code: '4315701', name: 'Rio Pardo' },
  { code: '4315750', name: 'Riozinho' },
  { code: '4315800', name: 'Roca Sales' },
  { code: '4315909', name: 'Rodeio Bonito' },
  { code: '4315958', name: 'Rolador' },
  { code: '4316006', name: 'Rolante' },
  { code: '4316105', name: 'Ronda Alta' },
  { code: '4316204', name: 'Rondinha' },
  { code: '4316303', name: 'Roque Gonzales' },
  { code: '4316402', name: 'Rosário do Sul' },
  { code: '4316428', name: 'Sagrada Família' },
  { code: '4316436', name: 'Saldanha Marinho' },
  { code: '4316451', name: 'Salto do Jacuí' },
  { code: '4316477', name: 'Salvador das Missões' },
  { code: '4316501', name: 'Salvador do Sul' },
  { code: '4316600', name: 'Sananduva' },
  { code: '4316709', name: 'Santa Bárbara do Sul' },
  { code: '4316733', name: 'Santa Cecília do Sul' },
  { code: '4316758', name: 'Santa Clara do Sul' },
  { code: '4316808', name: 'Santa Cruz do Sul' },
  { code: '4316907', name: 'Santa Maria' },
  { code: '4316956', name: 'Santa Maria do Herval' },
  { code: '4316972', name: 'Santa Margarida do Sul' },
  { code: '4317004', name: 'Santana da Boa Vista' },
  { code: '4317103', name: 'Santana do Livramento' },
  { code: '4317202', name: 'Santa Rosa' },
  { code: '4317251', name: 'Santa Tereza' },
  { code: '4317301', name: 'Santa Vitória do Palmar' },
  { code: '4317400', name: 'Santiago' },
  { code: '4317509', name: 'Santo Ângelo' },
  { code: '4317558', name: 'Santo Antônio do Palma' },
  { code: '4317608', name: 'Santo Antônio da Patrulha' },
  { code: '4317707', name: 'Santo Antônio das Missões' },
  { code: '4317756', name: 'Santo Antônio do Planalto' },
  { code: '4317806', name: 'Santo Augusto' },
  { code: '4317905', name: 'Santo Cristo' },
  { code: '4317954', name: 'Santo Expedito do Sul' },
  { code: '4318002', name: 'São Borja' },
  { code: '4318051', name: 'São Domingos do Sul' },
  { code: '4318101', name: 'São Francisco de Assis' },
  { code: '4318200', name: 'São Francisco de Paula' },
  { code: '4318309', name: 'São Gabriel' },
  { code: '4318408', name: 'São Jerônimo' },
  { code: '4318424', name: 'São João da Urtiga' },
  { code: '4318432', name: 'São João do Polêsine' },
  { code: '4318440', name: 'São Jorge' },
  { code: '4318457', name: 'São José das Missões' },
  { code: '4318465', name: 'São José do Herval' },
  { code: '4318481', name: 'São José do Hortêncio' },
  { code: '4318499', name: 'São José do Inhacorá' },
  { code: '4318507', name: 'São José do Norte' },
  { code: '4318606', name: 'São José do Ouro' },
  { code: '4318614', name: 'São José do Sul' },
  { code: '4318622', name: 'São José dos Ausentes' },
  { code: '4318705', name: 'São Leopoldo' },
  { code: '4318804', name: 'São Lourenço do Sul' },
  { code: '4318903', name: 'São Luiz Gonzaga' },
  { code: '4319000', name: 'São Marcos' },
  { code: '4319109', name: 'São Martinho' },
  { code: '4319125', name: 'São Martinho da Serra' },
  { code: '4319158', name: 'São Miguel das Missões' },
  { code: '4319208', name: 'São Nicolau' },
  { code: '4319307', name: 'São Paulo das Missões' },
  { code: '4319356', name: 'São Pedro da Serra' },
  { code: '4319364', name: 'São Pedro das Missões' },
  { code: '4319372', name: 'São Pedro do Butiá' },
  { code: '4319406', name: 'São Pedro do Sul' },
  { code: '4319505', name: 'São Sebastião do Caí' },
  { code: '4319604', name: 'São Sepé' },
  { code: '4319703', name: 'São Valentim' },
  { code: '4319711', name: 'São Valentim do Sul' },
  { code: '4319737', name: 'São Valério do Sul' },
  { code: '4319752', name: 'São Vendelino' },
  { code: '4319802', name: 'São Vicente do Sul' },
  { code: '4319901', name: 'Sapiranga' },
  { code: '4320008', name: 'Sapucaia do Sul' },
  { code: '4320107', name: 'Sarandi' },
  { code: '4320206', name: 'Seberi' },
  { code: '4320230', name: 'Sede Nova' },
  { code: '4320263', name: 'Segredo' },
  { code: '4320305', name: 'Selbach' },
  { code: '4320321', name: 'Senador Salgado Filho' },
  { code: '4320354', name: 'Sentinela do Sul' },
  { code: '4320404', name: 'Serafina Corrêa' },
  { code: '4320453', name: 'Sério' },
  { code: '4320503', name: 'Sertão' },
  { code: '4320552', name: 'Sertão Santana' },
  { code: '4320578', name: 'Sete de Setembro' },
  { code: '4320602', name: 'Severiano de Almeida' },
  { code: '4320651', name: 'Silveira Martins' },
  { code: '4320677', name: 'Sinimbu' },
  { code: '4320701', name: 'Sobradinho' },
  { code: '4320800', name: 'Soledade' },
  { code: '4320859', name: 'Tabaí' },
  { code: '4320909', name: 'Tapejara' },
  { code: '4321006', name: 'Tapera' },
  { code: '4321105', name: 'Tapes' },
  { code: '4321204', name: 'Taquara' },
  { code: '4321303', name: 'Taquari' },
  { code: '4321329', name: 'Taquaruçu do Sul' },
  { code: '4321352', name: 'Tavares' },
  { code: '4321402', name: 'Tenente Portela' },
  { code: '4321436', name: 'Terra de Areia' },
  { code: '4321451', name: 'Teutônia' },
  { code: '4321469', name: 'Tio Hugo' },
  { code: '4321477', name: 'Tiradentes do Sul' },
  { code: '4321493', name: 'Toropi' },
  { code: '4321501', name: 'Torres' },
  { code: '4321600', name: 'Tramandaí' },
  { code: '4321626', name: 'Travesseiro' },
  { code: '4321634', name: 'Três Arroios' },
  { code: '4321667', name: 'Três Cachoeiras' },
  { code: '4321709', name: 'Três Coroas' },
  { code: '4321808', name: 'Três de Maio' },
  { code: '4321832', name: 'Três Forquilhas' },
  { code: '4321857', name: 'Três Palmeiras' },
  { code: '4321907', name: 'Três Passos' },
  { code: '4321956', name: 'Trindade do Sul' },
  { code: '4322004', name: 'Triunfo' },
  { code: '4322103', name: 'Tucunduva' },
  { code: '4322152', name: 'Tunas' },
  { code: '4322186', name: 'Tupanci do Sul' },
  { code: '4322202', name: 'Tupanciretã' },
  { code: '4322251', name: 'Tupandi' },
  { code: '4322301', name: 'Tuparendi' },
  { code: '4322327', name: 'Turuçu' },
  { code: '4322343', name: 'Ubiretama' },
  { code: '4322350', name: 'União da Serra' },
  { code: '4322376', name: 'Unistalda' },
  { code: '4322400', name: 'Uruguaiana' },
  { code: '4322509', name: 'Vacaria' },
  { code: '4322525', name: 'Vale Verde' },
  { code: '4322533', name: 'Vale do Sol' },
  { code: '4322541', name: 'Vale Real' },
  { code: '4322558', name: 'Vanini' },
  { code: '4322608', name: 'Venâncio Aires' },
  { code: '4322707', name: 'Vera Cruz' },
  { code: '4322806', name: 'Veranópolis' },
  { code: '4322855', name: 'Vespasiano Correa' },
  { code: '4322905', name: 'Viadutos' },
  { code: '4323002', name: 'Viamão' },
  { code: '4323101', name: 'Vicente Dutra' },
  { code: '4323200', name: 'Victor Graeff' },
  { code: '4323309', name: 'Vila Flores' },
  { code: '4323358', name: 'Vila Lângaro' },
  { code: '4323408', name: 'Vila Maria' },
  { code: '4323457', name: 'Vila Nova do Sul' },
  { code: '4323507', name: 'Vista Alegre' },
  { code: '4323606', name: 'Vista Alegre do Prata' },
  { code: '4323705', name: 'Vista Gaúcha' },
  { code: '4323754', name: 'Vitória das Missões' },
  { code: '4323770', name: 'Westfalia' },
  { code: '4323804', name: 'Xangri-lá' },
  { code: '5000203', name: 'Água Clara' },
  { code: '5000252', name: 'Alcinópolis' },
  { code: '5000609', name: 'Amambaí' },
  { code: '5000708', name: 'Anastácio' },
  { code: '5000807', name: 'Anaurilândia' },
  { code: '5000856', name: 'Angélica' },
  { code: '5000906', name: 'Antônio João' },
  { code: '5001003', name: 'Aparecida do Taboado' },
  { code: '5001102', name: 'Aquidauana' },
  { code: '5001243', name: 'Aral Moreira' },
  { code: '5001508', name: 'Bandeirantes' },
  { code: '5001904', name: 'Bataguassu' },
  { code: '5002001', name: 'Batayporã' },
  { code: '5002100', name: 'Bela Vista' },
  { code: '5002159', name: 'Bodoquena' },
  { code: '5002209', name: 'Bonito' },
  { code: '5002308', name: 'Brasilândia' },
  { code: '5002407', name: 'Caarapó' },
  { code: '5002605', name: 'Camapuã' },
  { code: '5002704', name: 'Campo Grande' },
  { code: '5002803', name: 'Caracol' },
  { code: '5002902', name: 'Cassilândia' },
  { code: '5002951', name: 'Chapadão do Sul' },
  { code: '5003108', name: 'Corguinho' },
  { code: '5003157', name: 'Coronel Sapucaia' },
  { code: '5003207', name: 'Corumbá' },
  { code: '5003256', name: 'Costa Rica' },
  { code: '5003306', name: 'Coxim' },
  { code: '5003454', name: 'Deodápolis' },
  { code: '5003488', name: 'Dois Irmãos do Buriti' },
  { code: '5003504', name: 'Douradina' },
  { code: '5003702', name: 'Dourados' },
  { code: '5003751', name: 'Eldorado' },
  { code: '5003801', name: 'Fátima do Sul' },
  { code: '5003900', name: 'Figueirão' },
  { code: '5004007', name: 'Glória de Dourados' },
  { code: '5004106', name: 'Guia Lopes da Laguna' },
  { code: '5004304', name: 'Iguatemi' },
  { code: '5004403', name: 'Inocência' },
  { code: '5004502', name: 'Itaporã' },
  { code: '5004601', name: 'Itaquiraí' },
  { code: '5004700', name: 'Ivinhema' },
  { code: '5004809', name: 'Japorã' },
  { code: '5004908', name: 'Jaraguari' },
  { code: '5005004', name: 'Jardim' },
  { code: '5005103', name: 'Jateí' },
  { code: '5005152', name: 'Juti' },
  { code: '5005202', name: 'Ladário' },
  { code: '5005251', name: 'Laguna Carapã' },
  { code: '5005400', name: 'Maracaju' },
  { code: '5005608', name: 'Miranda' },
  { code: '5005681', name: 'Mundo Novo' },
  { code: '5005707', name: 'Naviraí' },
  { code: '5005806', name: 'Nioaque' },
  { code: '5006002', name: 'Nova Alvorada do Sul' },
  { code: '5006200', name: 'Nova Andradina' },
  { code: '5006259', name: 'Novo Horizonte do Sul' },
  { code: '5006275', name: 'Paraíso das Águas' },
  { code: '5006309', name: 'Paranaíba' },
  { code: '5006358', name: 'Paranhos' },
  { code: '5006408', name: 'Pedro Gomes' },
  { code: '5006606', name: 'Ponta Porã' },
  { code: '5006903', name: 'Porto Murtinho' },
  { code: '5007109', name: 'Ribas do Rio Pardo' },
  { code: '5007208', name: 'Rio Brilhante' },
  { code: '5007307', name: 'Rio Negro' },
  { code: '5007406', name: 'Rio Verde de Mato Grosso' },
  { code: '5007505', name: 'Rochedo' },
  { code: '5007554', name: 'Santa Rita do Pardo' },
  { code: '5007695', name: 'São Gabriel do Oeste' },
  { code: '5007703', name: 'Sete Quedas' },
  { code: '5007802', name: 'Selvíria' },
  { code: '5007901', name: 'Sidrolândia' },
  { code: '5007935', name: 'Sonora' },
  { code: '5007950', name: 'Tacuru' },
  { code: '5007976', name: 'Taquarussu' },
  { code: '5008008', name: 'Terenos' },
  { code: '5008305', name: 'Três Lagoas' },
  { code: '5008404', name: 'Vicentina' },
  { code: '5100102', name: 'Acorizal' },
  { code: '5100201', name: 'Água Boa' },
  { code: '5100250', name: 'Alta Floresta' },
  { code: '5100300', name: 'Alto Araguaia' },
  { code: '5100359', name: 'Alto Boa Vista' },
  { code: '5100409', name: 'Alto Garças' },
  { code: '5100508', name: 'Alto Paraguai' },
  { code: '5100607', name: 'Alto Taquari' },
  { code: '5100805', name: 'Apiacás' },
  { code: '5101001', name: 'Araguaiana' },
  { code: '5101209', name: 'Araguainha' },
  { code: '5101258', name: 'Araputanga' },
  { code: '5101308', name: 'Arenápolis' },
  { code: '5101407', name: 'Aripuanã' },
  { code: '5101605', name: 'Barão de Melgaço' },
  { code: '5101704', name: 'Barra do Bugres' },
  { code: '5101803', name: 'Barra do Garças' },
  { code: '5101852', name: 'Bom Jesus do Araguaia' },
  { code: '5101902', name: 'Brasnorte' },
  { code: '5102504', name: 'Cáceres' },
  { code: '5102603', name: 'Campinápolis' },
  { code: '5102637', name: 'Campo Novo do Parecis' },
  { code: '5102678', name: 'Campo Verde' },
  { code: '5102686', name: 'Campos de Júlio' },
  { code: '5102694', name: 'Canabrava do Norte' },
  { code: '5102702', name: 'Canarana' },
  { code: '5102793', name: 'Carlinda' },
  { code: '5102850', name: 'Castanheira' },
  { code: '5103007', name: 'Chapada dos Guimarães' },
  { code: '5103056', name: 'Cláudia' },
  { code: '5103106', name: 'Cocalinho' },
  { code: '5103205', name: 'Colíder' },
  { code: '5103254', name: 'Colniza' },
  { code: '5103304', name: 'Comodoro' },
  { code: '5103353', name: 'Confresa' },
  { code: '5103361', name: "Conquista D'Oeste" },
  { code: '5103379', name: 'Cotriguaçu' },
  { code: '5103403', name: 'Cuiabá' },
  { code: '5103437', name: 'Curvelândia' },
  { code: '5103452', name: 'Denise' },
  { code: '5103502', name: 'Diamantino' },
  { code: '5103601', name: 'Dom Aquino' },
  { code: '5103700', name: 'Feliz Natal' },
  { code: '5103809', name: "Figueirópolis D'Oeste" },
  { code: '5103858', name: 'Gaúcha do Norte' },
  { code: '5103908', name: 'General Carneiro' },
  { code: '5103957', name: "Glória D'Oeste" },
  { code: '5104104', name: 'Guarantã do Norte' },
  { code: '5104203', name: 'Guiratinga' },
  { code: '5104500', name: 'Indiavaí' },
  { code: '5104526', name: 'Ipiranga do Norte' },
  { code: '5104542', name: 'Itanhangá' },
  { code: '5104559', name: 'Itaúba' },
  { code: '5104609', name: 'Itiquira' },
  { code: '5104807', name: 'Jaciara' },
  { code: '5104906', name: 'Jangada' },
  { code: '5105002', name: 'Jauru' },
  { code: '5105101', name: 'Juara' },
  { code: '5105150', name: 'Juína' },
  { code: '5105176', name: 'Juruena' },
  { code: '5105200', name: 'Juscimeira' },
  { code: '5105234', name: "Lambari D'Oeste" },
  { code: '5105259', name: 'Lucas do Rio Verde' },
  { code: '5105309', name: 'Luciára' },
  { code: '5105507', name: 'Vila Bela da Santíssima Trindade' },
  { code: '5105580', name: 'Marcelândia' },
  { code: '5105606', name: 'Matupá' },
  { code: '5105622', name: "Mirassol d'Oeste" },
  { code: '5105903', name: 'Nobres' },
  { code: '5106000', name: 'Nortelândia' },
  { code: '5106109', name: 'Nossa Senhora do Livramento' },
  { code: '5106158', name: 'Nova Bandeirantes' },
  { code: '5106174', name: 'Nova Nazaré' },
  { code: '5106182', name: 'Nova Lacerda' },
  { code: '5106190', name: 'Nova Santa Helena' },
  { code: '5106208', name: 'Nova Brasilândia' },
  { code: '5106216', name: 'Nova Canaã do Norte' },
  { code: '5106224', name: 'Nova Mutum' },
  { code: '5106232', name: 'Nova Olímpia' },
  { code: '5106240', name: 'Nova Ubiratã' },
  { code: '5106257', name: 'Nova Xavantina' },
  { code: '5106265', name: 'Novo Mundo' },
  { code: '5106273', name: 'Novo Horizonte do Norte' },
  { code: '5106281', name: 'Novo São Joaquim' },
  { code: '5106299', name: 'Paranaíta' },
  { code: '5106307', name: 'Paranatinga' },
  { code: '5106315', name: 'Novo Santo Antônio' },
  { code: '5106372', name: 'Pedra Preta' },
  { code: '5106422', name: 'Peixoto de Azevedo' },
  { code: '5106455', name: 'Planalto da Serra' },
  { code: '5106505', name: 'Poconé' },
  { code: '5106653', name: 'Pontal do Araguaia' },
  { code: '5106703', name: 'Ponte Branca' },
  { code: '5106752', name: 'Pontes e Lacerda' },
  { code: '5106778', name: 'Porto Alegre do Norte' },
  { code: '5106802', name: 'Porto dos Gaúchos' },
  { code: '5106828', name: 'Porto Esperidião' },
  { code: '5106851', name: 'Porto Estrela' },
  { code: '5107008', name: 'Poxoréo' },
  { code: '5107040', name: 'Primavera do Leste' },
  { code: '5107065', name: 'Querência' },
  { code: '5107107', name: 'São José dos Quatro Marcos' },
  { code: '5107156', name: 'Reserva do Cabaçal' },
  { code: '5107180', name: 'Ribeirão Cascalheira' },
  { code: '5107198', name: 'Ribeirãozinho' },
  { code: '5107206', name: 'Rio Branco' },
  { code: '5107248', name: 'Santa Carmem' },
  { code: '5107263', name: 'Santo Afonso' },
  { code: '5107297', name: 'São José do Povo' },
  { code: '5107305', name: 'São José do Rio Claro' },
  { code: '5107354', name: 'São José do Xingu' },
  { code: '5107404', name: 'São Pedro da Cipa' },
  { code: '5107578', name: 'Rondolândia' },
  { code: '5107602', name: 'Rondonópolis' },
  { code: '5107701', name: 'Rosário Oeste' },
  { code: '5107743', name: 'Santa Cruz do Xingu' },
  { code: '5107750', name: 'Salto do Céu' },
  { code: '5107768', name: 'Santa Rita do Trivelato' },
  { code: '5107776', name: 'Santa Terezinha' },
  { code: '5107792', name: 'Santo Antônio do Leste' },
  { code: '5107800', name: 'Santo Antônio do Leverger' },
  { code: '5107859', name: 'São Félix do Araguaia' },
  { code: '5107875', name: 'Sapezal' },
  { code: '5107883', name: 'Serra Nova Dourada' },
  { code: '5107909', name: 'Sinop' },
  { code: '5107925', name: 'Sorriso' },
  { code: '5107941', name: 'Tabaporã' },
  { code: '5107958', name: 'Tangará da Serra' },
  { code: '5108006', name: 'Tapurah' },
  { code: '5108055', name: 'Terra Nova do Norte' },
  { code: '5108105', name: 'Tesouro' },
  { code: '5108204', name: 'Torixoréu' },
  { code: '5108303', name: 'União do Sul' },
  { code: '5108352', name: 'Vale de São Domingos' },
  { code: '5108402', name: 'Várzea Grande' },
  { code: '5108501', name: 'Vera' },
  { code: '5108600', name: 'Vila Rica' },
  { code: '5108808', name: 'Nova Guarita' },
  { code: '5108857', name: 'Nova Marilândia' },
  { code: '5108907', name: 'Nova Maringá' },
  { code: '5108956', name: 'Nova Monte Verde' },
  { code: '5200050', name: 'Abadia de Goiás' },
  { code: '5200100', name: 'Abadiânia' },
  { code: '5200134', name: 'Acreúna' },
  { code: '5200159', name: 'Adelândia' },
  { code: '5200175', name: 'Água Fria de Goiás' },
  { code: '5200209', name: 'Água Limpa' },
  { code: '5200258', name: 'Águas Lindas de Goiás' },
  { code: '5200308', name: 'Alexânia' },
  { code: '5200506', name: 'Aloândia' },
  { code: '5200555', name: 'Alto Horizonte' },
  { code: '5200605', name: 'Alto Paraíso de Goiás' },
  { code: '5200803', name: 'Alvorada do Norte' },
  { code: '5200829', name: 'Amaralina' },
  { code: '5200852', name: 'Americano do Brasil' },
  { code: '5200902', name: 'Amorinópolis' },
  { code: '5201108', name: 'Anápolis' },
  { code: '5201207', name: 'Anhanguera' },
  { code: '5201306', name: 'Anicuns' },
  { code: '5201405', name: 'Aparecida de Goiânia' },
  { code: '5201454', name: 'Aparecida do Rio Doce' },
  { code: '5201504', name: 'Aporé' },
  { code: '5201603', name: 'Araçu' },
  { code: '5201702', name: 'Aragarças' },
  { code: '5201801', name: 'Aragoiânia' },
  { code: '5202155', name: 'Araguapaz' },
  { code: '5202353', name: 'Arenópolis' },
  { code: '5202502', name: 'Aruanã' },
  { code: '5202601', name: 'Aurilândia' },
  { code: '5202809', name: 'Avelinópolis' },
  { code: '5203104', name: 'Baliza' },
  { code: '5203203', name: 'Barro Alto' },
  { code: '5203302', name: 'Bela Vista de Goiás' },
  { code: '5203401', name: 'Bom Jardim de Goiás' },
  { code: '5203500', name: 'Bom Jesus de Goiás' },
  { code: '5203559', name: 'Bonfinópolis' },
  { code: '5203575', name: 'Bonópolis' },
  { code: '5203609', name: 'Brazabrantes' },
  { code: '5203807', name: 'Britânia' },
  { code: '5203906', name: 'Buriti Alegre' },
  { code: '5203939', name: 'Buriti de Goiás' },
  { code: '5203962', name: 'Buritinópolis' },
  { code: '5204003', name: 'Cabeceiras' },
  { code: '5204102', name: 'Cachoeira Alta' },
  { code: '5204201', name: 'Cachoeira de Goiás' },
  { code: '5204250', name: 'Cachoeira Dourada' },
  { code: '5204300', name: 'Caçu' },
  { code: '5204409', name: 'Caiapônia' },
  { code: '5204508', name: 'Caldas Novas' },
  { code: '5204557', name: 'Caldazinha' },
  { code: '5204607', name: 'Campestre de Goiás' },
  { code: '5204656', name: 'Campinaçu' },
  { code: '5204706', name: 'Campinorte' },
  { code: '5204805', name: 'Campo Alegre de Goiás' },
  { code: '5204854', name: 'Campo Limpo de Goiás' },
  { code: '5204904', name: 'Campos Belos' },
  { code: '5204953', name: 'Campos Verdes' },
  { code: '5205000', name: 'Carmo do Rio Verde' },
  { code: '5205059', name: 'Castelândia' },
  { code: '5205109', name: 'Catalão' },
  { code: '5205208', name: 'Caturaí' },
  { code: '5205307', name: 'Cavalcante' },
  { code: '5205406', name: 'Ceres' },
  { code: '5205455', name: 'Cezarina' },
  { code: '5205471', name: 'Chapadão do Céu' },
  { code: '5205497', name: 'Cidade Ocidental' },
  { code: '5205513', name: 'Cocalzinho de Goiás' },
  { code: '5205521', name: 'Colinas do Sul' },
  { code: '5205703', name: 'Córrego do Ouro' },
  { code: '5205802', name: 'Corumbá de Goiás' },
  { code: '5205901', name: 'Corumbaíba' },
  { code: '5206206', name: 'Cristalina' },
  { code: '5206305', name: 'Cristianópolis' },
  { code: '5206404', name: 'Crixás' },
  { code: '5206503', name: 'Cromínia' },
  { code: '5206602', name: 'Cumari' },
  { code: '5206701', name: 'Damianópolis' },
  { code: '5206800', name: 'Damolândia' },
  { code: '5206909', name: 'Davinópolis' },
  { code: '5207105', name: 'Diorama' },
  { code: '5207253', name: 'Doverlândia' },
  { code: '5207352', name: 'Edealina' },
  { code: '5207402', name: 'Edéia' },
  { code: '5207501', name: 'Estrela do Norte' },
  { code: '5207535', name: 'Faina' },
  { code: '5207600', name: 'Fazenda Nova' },
  { code: '5207808', name: 'Firminópolis' },
  { code: '5207907', name: 'Flores de Goiás' },
  { code: '5208004', name: 'Formosa' },
  { code: '5208103', name: 'Formoso' },
  { code: '5208152', name: 'Gameleira de Goiás' },
  { code: '5208301', name: 'Divinópolis de Goiás' },
  { code: '5208400', name: 'Goianápolis' },
  { code: '5208509', name: 'Goiandira' },
  { code: '5208608', name: 'Goianésia' },
  { code: '5208707', name: 'Goiânia' },
  { code: '5208806', name: 'Goianira' },
  { code: '5208905', name: 'Goiás' },
  { code: '5209101', name: 'Goiatuba' },
  { code: '5209150', name: 'Gouvelândia' },
  { code: '5209200', name: 'Guapó' },
  { code: '5209291', name: 'Guaraíta' },
  { code: '5209408', name: 'Guarani de Goiás' },
  { code: '5209457', name: 'Guarinos' },
  { code: '5209606', name: 'Heitoraí' },
  { code: '5209705', name: 'Hidrolândia' },
  { code: '5209804', name: 'Hidrolina' },
  { code: '5209903', name: 'Iaciara' },
  { code: '5209937', name: 'Inaciolândia' },
  { code: '5209952', name: 'Indiara' },
  { code: '5210000', name: 'Inhumas' },
  { code: '5210109', name: 'Ipameri' },
  { code: '5210158', name: 'Ipiranga de Goiás' },
  { code: '5210208', name: 'Iporá' },
  { code: '5210307', name: 'Israelândia' },
  { code: '5210406', name: 'Itaberaí' },
  { code: '5210562', name: 'Itaguari' },
  { code: '5210604', name: 'Itaguaru' },
  { code: '5210802', name: 'Itajá' },
  { code: '5210901', name: 'Itapaci' },
  { code: '5211008', name: 'Itapirapuã' },
  { code: '5211206', name: 'Itapuranga' },
  { code: '5211305', name: 'Itarumã' },
  { code: '5211404', name: 'Itauçu' },
  { code: '5211503', name: 'Itumbiara' },
  { code: '5211602', name: 'Ivolândia' },
  { code: '5211701', name: 'Jandaia' },
  { code: '5211800', name: 'Jaraguá' },
  { code: '5211909', name: 'Jataí' },
  { code: '5212006', name: 'Jaupaci' },
  { code: '5212055', name: 'Jesúpolis' },
  { code: '5212105', name: 'Joviânia' },
  { code: '5212204', name: 'Jussara' },
  { code: '5212253', name: 'Lagoa Santa' },
  { code: '5212303', name: 'Leopoldo de Bulhões' },
  { code: '5212501', name: 'Luziânia' },
  { code: '5212600', name: 'Mairipotaba' },
  { code: '5212709', name: 'Mambaí' },
  { code: '5212808', name: 'Mara Rosa' },
  { code: '5212907', name: 'Marzagão' },
  { code: '5212956', name: 'Matrinchã' },
  { code: '5213004', name: 'Maurilândia' },
  { code: '5213053', name: 'Mimoso de Goiás' },
  { code: '5213087', name: 'Minaçu' },
  { code: '5213103', name: 'Mineiros' },
  { code: '5213400', name: 'Moiporá' },
  { code: '5213509', name: 'Monte Alegre de Goiás' },
  { code: '5213707', name: 'Montes Claros de Goiás' },
  { code: '5213756', name: 'Montividiu' },
  { code: '5213772', name: 'Montividiu do Norte' },
  { code: '5213806', name: 'Morrinhos' },
  { code: '5213855', name: 'Morro Agudo de Goiás' },
  { code: '5213905', name: 'Mossâmedes' },
  { code: '5214002', name: 'Mozarlândia' },
  { code: '5214051', name: 'Mundo Novo' },
  { code: '5214101', name: 'Mutunópolis' },
  { code: '5214408', name: 'Nazário' },
  { code: '5214507', name: 'Nerópolis' },
  { code: '5214606', name: 'Niquelândia' },
  { code: '5214705', name: 'Nova América' },
  { code: '5214804', name: 'Nova Aurora' },
  { code: '5214838', name: 'Nova Crixás' },
  { code: '5214861', name: 'Nova Glória' },
  { code: '5214879', name: 'Nova Iguaçu de Goiás' },
  { code: '5214903', name: 'Nova Roma' },
  { code: '5215009', name: 'Nova Veneza' },
  { code: '5215207', name: 'Novo Brasil' },
  { code: '5215231', name: 'Novo Gama' },
  { code: '5215256', name: 'Novo Planalto' },
  { code: '5215306', name: 'Orizona' },
  { code: '5215405', name: 'Ouro Verde de Goiás' },
  { code: '5215504', name: 'Ouvidor' },
  { code: '5215603', name: 'Padre Bernardo' },
  { code: '5215652', name: 'Palestina de Goiás' },
  { code: '5215702', name: 'Palmeiras de Goiás' },
  { code: '5215801', name: 'Palmelo' },
  { code: '5215900', name: 'Palminópolis' },
  { code: '5216007', name: 'Panamá' },
  { code: '5216304', name: 'Paranaiguara' },
  { code: '5216403', name: 'Paraúna' },
  { code: '5216452', name: 'Perolândia' },
  { code: '5216809', name: 'Petrolina de Goiás' },
  { code: '5216908', name: 'Pilar de Goiás' },
  { code: '5217104', name: 'Piracanjuba' },
  { code: '5217203', name: 'Piranhas' },
  { code: '5217302', name: 'Pirenópolis' },
  { code: '5217401', name: 'Pires do Rio' },
  { code: '5217609', name: 'Planaltina' },
  { code: '5217708', name: 'Pontalina' },
  { code: '5218003', name: 'Porangatu' },
  { code: '5218052', name: 'Porteirão' },
  { code: '5218102', name: 'Portelândia' },
  { code: '5218300', name: 'Posse' },
  { code: '5218391', name: 'Professor Jamil' },
  { code: '5218508', name: 'Quirinópolis' },
  { code: '5218607', name: 'Rialma' },
  { code: '5218706', name: 'Rianápolis' },
  { code: '5218789', name: 'Rio Quente' },
  { code: '5218805', name: 'Rio Verde' },
  { code: '5218904', name: 'Rubiataba' },
  { code: '5219001', name: 'Sanclerlândia' },
  { code: '5219100', name: 'Santa Bárbara de Goiás' },
  { code: '5219209', name: 'Santa Cruz de Goiás' },
  { code: '5219258', name: 'Santa Fé de Goiás' },
  { code: '5219308', name: 'Santa Helena de Goiás' },
  { code: '5219357', name: 'Santa Isabel' },
  { code: '5219407', name: 'Santa Rita do Araguaia' },
  { code: '5219456', name: 'Santa Rita do Novo Destino' },
  { code: '5219506', name: 'Santa Rosa de Goiás' },
  { code: '5219605', name: 'Santa Tereza de Goiás' },
  { code: '5219704', name: 'Santa Terezinha de Goiás' },
  { code: '5219712', name: 'Santo Antônio da Barra' },
  { code: '5219738', name: 'Santo Antônio de Goiás' },
  { code: '5219753', name: 'Santo Antônio do Descoberto' },
  { code: '5219803', name: 'São Domingos' },
  { code: '5219902', name: 'São Francisco de Goiás' },
  { code: '5220009', name: "São João d'Aliança" },
  { code: '5220058', name: 'São João da Paraúna' },
  { code: '5220108', name: 'São Luís de Montes Belos' },
  { code: '5220157', name: 'São Luíz do Norte' },
  { code: '5220207', name: 'São Miguel do Araguaia' },
  { code: '5220264', name: 'São Miguel do Passa Quatro' },
  { code: '5220280', name: 'São Patrício' },
  { code: '5220405', name: 'São Simão' },
  { code: '5220454', name: 'Senador Canedo' },
  { code: '5220504', name: 'Serranópolis' },
  { code: '5220603', name: 'Silvânia' },
  { code: '5220686', name: 'Simolândia' },
  { code: '5220702', name: "Sítio d'Abadia" },
  { code: '5221007', name: 'Taquaral de Goiás' },
  { code: '5221080', name: 'Teresina de Goiás' },
  { code: '5221197', name: 'Terezópolis de Goiás' },
  { code: '5221304', name: 'Três Ranchos' },
  { code: '5221403', name: 'Trindade' },
  { code: '5221452', name: 'Trombas' },
  { code: '5221502', name: 'Turvânia' },
  { code: '5221551', name: 'Turvelândia' },
  { code: '5221577', name: 'Uirapuru' },
  { code: '5221601', name: 'Uruaçu' },
  { code: '5221700', name: 'Uruana' },
  { code: '5221809', name: 'Urutaí' },
  { code: '5221858', name: 'Valparaíso de Goiás' },
  { code: '5221908', name: 'Varjão' },
  { code: '5222005', name: 'Vianópolis' },
  { code: '5222054', name: 'Vicentinópolis' },
  { code: '5222203', name: 'Vila Boa' },
  { code: '5222302', name: 'Vila Propício' },
  { code: '9999999', name: 'Exterior' }
]
 export default citycodes;