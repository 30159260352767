import React, { useCallback, useEffect } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import Card from 'components/card';
import Text from 'components/text';
import { Title } from 'components/title/Title';
import { useReports } from 'context/reports';
import { AddMasks } from 'utils/Masks';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Replay } from '@material-ui/icons';
import { ReportsPeriod } from 'utils/types/reports';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    value: {
      color: theme.palette.primary.dark,
      fontSize: '2.5em',
    },
    title: {
      color: theme.palette.primary.dark,
      marginBottom: theme.spacing(1),
    },
    gridValue: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gridButton: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-end',
      },
    },
    description: {
      fontSize: '1em',
    },
    iconContainer: {
      position: 'absolute',
      right: 30,
    },
    icon: {
      fontSize: 20,
    },
  })
);

interface SalesProps {
  type: ReportsPeriod;
  title: string;
}

function Sales({ type, title }: SalesProps) {
  const classes = useStyles();
  const { getDashboardSalesByPeriod, dashboardSales } = useReports();

  const getSales = useCallback(() => {
    getDashboardSalesByPeriod(type);
  }, [getDashboardSalesByPeriod, type]);

  useEffect(() => {
    getSales();
  }, [getSales]);

  const values = dashboardSales[type];
  if (!values) return null;
  return (
    <>
      <Title style={classes.title}>{title}</Title>
      <IconButton
        size="small"
        className={classes.iconContainer}
        onClick={getSales}
      >
        <Replay className={classes.icon} />
      </IconButton>
      <Card>
        <Grid container>
          <Grid item className={classes.gridValue} xs={12} sm={4}>
            <Text style={classes.value}>{values.quantity}</Text>
            <Text style={classes.description}>Vendas Realizadas</Text>
          </Grid>
          <Grid item className={classes.gridValue} xs={12} sm={4}>
            <Text style={classes.value}>{AddMasks.money(values.total)}</Text>
            <Text style={classes.description}>Total em Vendas</Text>
          </Grid>
          <Grid item className={classes.gridValue} xs={12} sm={4}>
            <Text style={classes.value}>{AddMasks.money(values.ticket)}</Text>
            <Text style={classes.description}>Ticket Médio</Text>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default Sales;
