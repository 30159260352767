import Uris from 'constants/endpoints';
import { isAdmin } from 'constants/endpoints/Uris';
import api from 'services/config/api';

export const ChangePassword = {
  changePasswordSendEmail: (email) => {
    return api.post(Uris.changePasswordSendEmail, {
      email,
      type: isAdmin ? 'user' : 'client',
    });
  },
  resetPassword: (password, token) => {
    return api.patch(Uris.resetPassword, { password, token });
  },
};
