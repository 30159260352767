import Uris from 'constants/endpoints';
import api from 'services/config/api';
import { ResponsePagination, SimpleResponse } from 'utils/types/response';
import {
  Product,
  TopSellings,
  TopSellingsStore,
  TypeUnit,
} from 'utils/types/product';

export const ProductsApi = {
  getProductList: (query: string) =>
    api.get<ResponsePagination<Product>>(`${Uris.getProdutos}${query}`),
  topSellings: () =>
    api.get<Array<TopSellings>>(`/admin/reports/products/top-selling`),
  topSellingsStore: (store_id: string) =>
    api.get<Array<TopSellingsStore>>(
      `/admin/shopkeeper/store/${store_id}/reports/products/top-selling`
    ),
  getTypesUnit: (params: string = '') =>
    api.get<SimpleResponse<Array<TypeUnit>>>(
      `/admin/products/type-unit${params}`
    ),
};
