import React from 'react';
import { Grid } from '@material-ui/core';
import { Title } from 'components/title/Title';
import { Texts } from 'constants/strings';
import DragAndDrop from './DragAndDrop';

function Fotos() {
  return (
    <>
      <Grid item xs={12}>
        <Title>{Texts.novoProduto.sessions.fotos}</Title>
      </Grid>
      <DragAndDrop />
    </>
  );
}

export default Fotos;
