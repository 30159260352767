import { status_order } from 'constants/config/Config';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportsApi } from 'services/api/ReportsApi';
import { NotificationActions } from 'store/ducks/notification';
import { StatusSale } from 'utils/enum/order.enum';
import { TYPES_PAYMENT_ID } from 'utils/enum/payments.enum';
import { Helpers } from 'utils/helpers';
import { AddMasks } from 'utils/Masks';
import queryCreator from 'utils/queryCreator';
import {
  Cancelleds,
  ReportsPeriod,
  SalesTotal,
  QtdStatus,
  SimpleSales,
  Comission,
} from 'utils/types/reports';

interface ReportProps {
  children: React.ReactNode;
}

interface DashboardSales {
  [key: string]: {
    quantity: number;
    total: number;
    ticket: string;
  };
}
interface Reports {
  getDashboardSalesByPeriod: (typeSale: ReportsPeriod) => void;
  dashboardSales: DashboardSales;
  getCancelledOrders: () => Promise<Cancelleds | undefined>;
  getTotalSales: (params: string, storeId?: number) => Promise<SalesTotal>;
  getComissions: (params: string) => Promise<number>;
  getOpenOrders: (storeId: number) => Promise<number>;
}

const ReportContext = createContext<Reports | null>(null);

const ReportProvider = ({ children }: ReportProps) => {
  const [dashboardSales, setDashboardSales] = useState<DashboardSales>({});
  const dispatch = useDispatch();

  const getDashboardSalesByPeriod = useCallback((period: ReportsPeriod) => {
    ReportsApi.getSalesByPeriod(period)
      .then(({ data: sales }) => {
        let quantitySales = 0;
        let total = 0;

        sales.forEach((sale) => {
          if (sale.constant === StatusSale.Delivered) {
            quantitySales += sale.orders.length;
            total += parseFloat(sale.total);
          }
        });

        const media =
          quantitySales !== 0 && total !== 0 ? total / quantitySales : 0;
        setDashboardSales((state) => ({
          ...state,
          [period]: {
            quantity: quantitySales,
            total,
            ticket: media.toFixed(2),
          },
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getCancelledOrders = useCallback(async (): Promise<
    Cancelleds | undefined
  > => {
    try {
      // MONTH
      const queries = [];
      queries.push(queryCreator.equal('constant', 'ORDER_CANCELED'));
      const intervalMonth = Helpers.thisMonth();
      let end = intervalMonth.end.format('YYYY-MM-DD');
      let start = intervalMonth.start.format('YYYY-MM-DD');
      queries.push(queryCreator.interval('created_at', start, end));
      let query = queryCreator.formatter({
        queries,
      });
      const { data: months } = await ReportsApi.getSales(query);
      // WEEK
      const intervalWeek = Helpers.thisMonth();
      end = intervalWeek.end.format('YYYY-MM-DD');
      start = intervalWeek.start.format('YYYY-MM-DD');
      queries[1] = queryCreator.interval('created_at', start, end);
      query = queryCreator.formatter({
        queries,
      });
      const { data: week } = await ReportsApi.getSales(query);
      let monthSales = 0;
      months.forEach((sale) => {
        monthSales += parseFloat(sale.subtotal);
      });
      const weekSales = 0;
      week.forEach((sale) => {
        monthSales += parseFloat(sale.subtotal);
      });
      return {
        month: {
          total: AddMasks.money(monthSales),
          quantity: months.length,
        },
        week: {
          total: AddMasks.money(weekSales),
          quantity: week.length,
        },
      };
    } catch (error) {
      return undefined;
    }
  }, []);

  const getComissions = useCallback(
    async (params: string = '', storeId?: number): Promise<number> => {
      try {
        let comissions: Array<Comission> = [];
        if (storeId) {
          const { data } = await ReportsApi.getComissionsShopkeeper(
            params,
            storeId
          );
          comissions = data;
        } else {
          const { data } = await ReportsApi.getComissions(params);
          comissions = data;
        }
        const totalComission = comissions.reduce((total, current) => {
          const value = current?.subtotal_commission
            ? parseFloat(String(current.subtotal_commission))
            : 0;
          return total + value;
        }, 0);
        return totalComission;
      } catch (error) {
        return 0;
      }
    },
    []
  );

  const getTotalSales = useCallback(
    async (params: string = '', storeId?: number): Promise<SalesTotal> => {
      try {
        dispatch(NotificationActions.info('Buscando, aguarde...'));
        let result: Array<SimpleSales> = [];
        if (storeId) {
          const { data } = await ReportsApi.getSalesShopkeeper(params, storeId);
          result = data;
        } else {
          const { data } = await ReportsApi.getSales(params);
          result = data;
        }
        let quantitySales = 0;
        let totalCancelled = 0;
        let qtdCancelled = 0;
        let totalCreditCard = 0;
        let qtdCreditCard = 0;
        let totalInStore = 0;
        let qtdInStore = 0;
        if (result.length > 0) {
          const sumSales: number = result.reduce((total, current) => {
            const totalSale = parseFloat(current.subtotal);
            if (
              [
                status_order.PAYMENT_CANCELED,
                status_order.PAYMENT_REFUSED,
              ].includes(current.constant)
            ) {
              totalCancelled += totalSale;
              qtdCancelled += 1;
              return total;
            }
            if (current.type_payment_id === TYPES_PAYMENT_ID.CreditCard) {
              totalCreditCard += totalSale;
              qtdCreditCard += 1;
            }
            if (current.type_payment_id === TYPES_PAYMENT_ID.EmLoja) {
              totalInStore += totalSale;
              qtdInStore += 1;
            }

            if (
              totalSale &&
              [
                status_order.PAYMENT_CONFIRMED,
                status_order.ORDER_DELIVERED,
              ].includes(current.constant)
            ) {
              quantitySales += 1;
              return total + totalSale;
            }
            return total;
          }, 0);
          const ticket = (sumSales / result.length).toFixed(2);
          const total = sumSales.toFixed(2);
          const typesPayment: { [key: string]: number } = {};

          const quantStatus: QtdStatus = {
            ORDER_CANCELED: 0,
            REQUESTED_CANCELLATION: 0,
            ORDER_DELIVERED: 0,
            PAYMENT_CONFIRMED: 0,
            AWAITING_PAYMENT_CONFIRMATION: 0,
            PAYMENT_REFUSED: 0,
          };
          result.forEach((item) => {
            quantStatus[item.constant] += 1;
            if (item.card_brand) {
              const countBrands = typesPayment[item.card_brand] || 0;
              typesPayment[item.card_brand] = countBrands + 1;
            }
          });

          const commission = await getComissions(params, storeId);
          dispatch(NotificationActions.dismiss());
          return {
            total,
            totalCurrency: AddMasks.money(total),
            quantitySales,
            ticket,
            ticketCurrency: AddMasks.money(ticket),
            qtdStatus: quantStatus,
            totalCancelled: AddMasks.money(totalCancelled),
            qtdCancelled,
            totalCreditCard: AddMasks.money(totalCreditCard),
            qtdCreditCard,
            totalInStore: AddMasks.money(totalInStore),
            commission: AddMasks.money(commission),
            qtdInStore,
            typesPayment: Object.entries(typesPayment).map((i) => ({
              name: i[0],
              qtd: i[1],
            })),
          };
        }
        throw new Error();
      } catch (error) {
        console.log(error);
        dispatch(NotificationActions.dismiss());
        return {
          total: '0',
          totalCurrency: AddMasks.money(0),
          quantitySales: 0,
          ticket: '0',
          ticketCurrency: AddMasks.money(0),
          totalCancelled: AddMasks.money(0),
          qtdCancelled: 0,
          qtdStatus: {
            ORDER_CANCELED: 0,
            REQUESTED_CANCELLATION: 0,
            ORDER_DELIVERED: 0,
            PAYMENT_CONFIRMED: 0,
            AWAITING_PAYMENT_CONFIRMATION: 0,
            PAYMENT_REFUSED: 0,
          },
          typesPayment: [],
          totalCreditCard: AddMasks.money(0),
          totalInStore: AddMasks.money(0),
          qtdInStore: 0,
          qtdCreditCard: 0,
          commission: AddMasks.money(0),
        };
      }
    },
    [dispatch, getComissions]
  );

  const getOpenOrders = useCallback(
    async (storeId: number): Promise<number> => {
      try {
        const { data: sales } = await ReportsApi.getOpenOrders(storeId);
        return sales.data.length;
      } catch (error) {
        return 0;
      }
    },
    []
  );

  return (
    <ReportContext.Provider
      value={{
        getDashboardSalesByPeriod,
        dashboardSales,
        getCancelledOrders,
        getTotalSales,
        getComissions,
        getOpenOrders,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

const useReports = () => {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error('useReports must be user within an ReportProvider');
  }
  return context;
};

export { ReportProvider, useReports };
