import { Texts } from 'constants/strings';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import ForgotPassword from './ForgotPassword';
import Input from './Input';
import { Content } from './Styled';

function Fields({ email, password, setEmail, setPassword }) {
  const { emailMessage, passwordMessage } = useSelector((state) => state.auth);
  return (
    <Content>
      <Input
        value={email}
        onChange={setEmail}
        label={Texts.login.labelEmail}
        error={emailMessage}
      />
      <Input
        isPass
        onChange={setPassword}
        label={Texts.login.labelPassword}
        value={password}
        error={passwordMessage}
      />
      <ForgotPassword />
    </Content>
  );
}

Fields.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  setEmail: PropTypes.func,
  setPassword: PropTypes.func,
};

Fields.defaultProps = {
  email: '',
  password: '',
  setEmail: () => {},
  setPassword: () => {},
};

export default Fields;
