import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportsApi } from 'services/api/ReportsApi';
import { NotificationActions } from 'store/ducks/notification';
import { StockMain, StockStore } from 'utils/types/stock';

interface StockProps {
  children: React.ReactNode;
}

interface Stocks {
  main: Array<StockMain>;
  store: StockStore | null;
  getMainStock: (params: string) => void;
  getStoreStock: (params: string) => void;
}

const StockContext = createContext<Stocks | null>(null);

const MovesStockProvider = ({ children }: StockProps) => {
  const dispatch = useDispatch();
  const [mainStock, setMainStock] = useState<Array<StockMain>>([]);
  const [storeStock, setStoreStock] = useState<StockStore | null>(null);

  const getMainStock = useCallback(async (params: string = '') => {
    dispatch(NotificationActions.info('Buscando as movimentações, aguarde...'));
    const typeReport = 'typeReport=default';
    setMainStock([]);
    try {
      const { data } = await ReportsApi.movMainStock(
        params ? `${params}&${typeReport}` : `?${typeReport}`
      );
      setMainStock(data);
      dispatch(NotificationActions.dismiss());
    } catch (error) {
      dispatch(NotificationActions.error(error));
      setMainStock([]);
    }
  }, [dispatch]);

  const getStoreStock = useCallback(async (params: string = '') => {
    dispatch(NotificationActions.info('Buscando as movimentações, aguarde...'));
    setStoreStock(null);
    try {
      const { data } = await ReportsApi.movStockStore(params);
      setStoreStock(data[0]);
      dispatch(NotificationActions.dismiss());
    } catch (error) {
      dispatch(NotificationActions.error(error));
      setStoreStock(null);
    }
  }, [dispatch]);

  return (
    <StockContext.Provider
      value={{
        main: mainStock,
        store: storeStock,
        getMainStock,
        getStoreStock,
      }}
    >
      {children}
    </StockContext.Provider>
  );
};

const useMovesWessex = () => {
  const context = useContext(StockContext);
  if (!context) {
    throw new Error('useMovesWessex must be user within an StockProvider');
  }
  const { main, getMainStock } = context;
  return { main, getMainStock };
};

const useMovesStores = () => {
  const context = useContext(StockContext);
  if (!context) {
    throw new Error('useMovesStores must be user within an StockProvider');
  }
  const { store, getStoreStock } = context;
  return { store, getStoreStock };
};

export { MovesStockProvider, useMovesStores, useMovesWessex };
