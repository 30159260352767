import { put, call, select } from 'redux-saga/effects';
import { OrderApi } from 'services/api/OrderApi';
import queryCreator from 'utils/queryCreator';
import { OrderDetailsActions } from 'store/ducks/order-details';
import moment from 'moment';
import { OrdersActions } from '../../ducks/orders';
import { NotificationActions } from '../../ducks/notification';

export function* getOrders({ payload: page }) {
  try {
    const { actualStore } = yield select((state) => state.userStores);
    const { order_id, status, pagamento, dateOrder } = yield select(
      (state) => state.orders
    );
    const queries = [];
    if (order_id.trim()) {
      queries.push(queryCreator.equal('order_id', order_id));
    }
    if (status) {
      queries.push(queryCreator.equal('currentStatus.status.constant', status));
    }
    if (pagamento) {
      queries.push(queryCreator.equal('typePayment.constant', pagamento));
    }
    if (dateOrder) {
      const date = moment(dateOrder);
      const start = date.format('YYYY-MM-DD');
      const end = date.format('YYYY-MM-DD');
      queries.push(queryCreator.interval('created_at', start, end));
    }
    const { data } = yield call(
      OrderApi.getOrders,
      actualStore.store_id,
      queryCreator.formatter({
        queries,
        field: 'created_at',
        sort: 'desc',
        page,
      })
    );
    yield put(OrdersActions.getOrdersResult(data));
  } catch (error) {
    yield put(
      NotificationActions.error('Não foi possível carregar os pedidos')
    );
    yield put(OrdersActions.getOrdersResult([], true));
  }
}

export function* getRequestCancellations() {
  try {
    const query = queryCreator.equal('currentStatus.status.order_status_id', 1);
    const { data } = yield call(OrderApi.getAllOrders, `?query=${query}`);
    yield put(OrdersActions.resultRequestCancellations(data.data, false));
  } catch (error) {
    yield put(OrdersActions.resultRequestCancellations([], true));
  }
}

export function* confirmOrderCancel({ payload: orderId }) {
  try {
    yield call(OrderApi.cancelOrder, orderId);
    yield put(OrdersActions.getRequestsCancellatios());
    yield put(NotificationActions.success('Compra cancelada com sucesso'));
  } catch (error) {
    yield put(NotificationActions.error('Não foi possível cancelar o pedido'));
  }
}

export function* confirmOrder({ payload: orderId }) {
  try {
    yield put(NotificationActions.info('Confirmando Pagamento, aguarde...'));
    const { actualStore } = yield select((state) => state.userStores);
    yield call(OrderApi.confirmOrder, actualStore.store_id, orderId);
    yield put(NotificationActions.success('Compra confirmada com sucesso'));
    yield put(OrderDetailsActions.getOrder(orderId));
  } catch (error) {
    yield put(
      NotificationActions.error(
        error?.response?.data?.data || 'Não foi possível confirmar o pedido'
      )
    );
  }
}

export function* shopkeeperRequestCancel({ payload }) {
  try {
    const { reason, orderId } = payload;
    const { actualStore } = yield select((state) => state.userStores);
    yield call(
      OrderApi.shopkeeperRequestCancellation,
      actualStore.store_id,
      orderId,
      reason
    );
    yield put(OrdersActions.shopkeeperResultRequestCancellation());
    yield put(
      NotificationActions.success('Solicitação de cancelamento confirmada')
    );
    yield put(OrderDetailsActions.getOrder(orderId));
  } catch (error) {
    yield put(OrdersActions.shopkeeperResultRequestCancellation());
    yield put(
      NotificationActions.error(
        error?.response?.data?.data ||
          'Não foi possível solicitar o cancelamento do pedido'
      )
    );
  }
}
