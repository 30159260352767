import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  select: {
    '&:before': {
      // normal
      borderBottom: '1px solid orange',
    },
    '&:after': {
      // focused
      borderBottom: `3px solid green`,
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      // hover
      borderBottom: `2px solid purple`,
    },
  },
  label: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.common.white,
    '&$focused': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      paddingRight: '5px',
    },
  },
  focused: {},
  icon: {
    color: theme.palette.common.white,
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
}));

function Selecionar({
  label,
  value,
  onChange,
  error,
  formStyle,
  disabled,
  required,
  options,
  id,
  allowEmpty,
}) {
  const classes = useStyles();

  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      className={clsx(classes.form, formStyle)}
    >
      <InputLabel
        required={required}
        classes={{
          root: classes.label,
          focused: classes.focused,
        }}
      >
        {label}
      </InputLabel>
      <Select
        value={value}
        onChange={(event) => onChange({ id, value: event.target.value })}
        name={id}
        className={classes.select}
      >
        {allowEmpty && <MenuItem value="">Nenhum</MenuItem>}
        {options.map((option) => (
          <MenuItem key={option.name} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText>
          <Typography className={classes.error}>{error}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
}

Selecionar.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formStyle: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  allowEmpty: PropTypes.bool,
};

Selecionar.defaultProps = {
  error: '',
  id: null,
  label: '',
  onChange: () => {},
  value: '',
  formStyle: '',
  disabled: false,
  options: [],
  required: false,
  allowEmpty: true,
};

export { Selecionar };
